import CreditScoreIcon from "@mui/icons-material/CreditScore";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { MOBILE_MEDIA_QUERY } from "constants/mediaQueries";
import React from "react";

import TabbedPanels from "../../../../tabbedPanels";
import ReceiptLines from "./receiptLines/receiptLines";
import ReceiptTransactions from "./receiptTransactions/receiptTransactions";

const ReceiptTransactionsLines = () => {
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    return (
        <Box sx={{ width: "100%" }}>
            {/* TODO - 569779 - This previously had the tabs set to fixed height of 55px.
            Now using the generic component, make sure it still works. */}
            <TabbedPanels
                title="Receipt transactions lines"
                tabs={[
                    {
                        name: `${isDesktop ? "Receipt " : ""}Transactions`,
                        icon: <CreditScoreIcon />,
                        id: "receiptTransactions",
                        content: <ReceiptTransactions />,
                    },
                    {
                        name: `${isDesktop ? "Receipt " : ""}Lines`,
                        icon: <ReceiptIcon />,
                        id: "receiptLines",
                        content: <ReceiptLines />,
                    },
                ]}
            />
        </Box>
    );
};

export default ReceiptTransactionsLines;
