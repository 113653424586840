import { v4 as uuidv4 } from "uuid";

import {
    BY_AMOUNT_UOM_DESC_AND_CODE,
    CORPORATE_RATE,
    CPA,
    DIRECT,
    EDIT_NON_CAT_CART_ITEM,
    EDIT_NON_CAT_REQ_LINE,
    NEW_NON_CAT_ITEM,
    NEW_SMART_FORM_ITEM,
    NON_CATALOGUE_ITEM,
    SERVICE,
    STOCK,
    SUPPLIER,
    TYPE_OPTIONS,
} from "../constants/generalConstants";
import * as constants from "../constants/snackbarMessage";
import {
    nonCatItemBase,
    nonCatReqLineTranslater,
} from "../schemas/nonCatalogueCartItemBaseSchema";
import {
    getSupplierContactDetails,
    getSupplierSiteDetails,
} from "../services/supplier/supplierAPIs";
import {
    setShowNonCatalogueDialogue,
    setShowSmartFormsDialogue,
} from "./../redux/actions/generalActions";
import {
    setInformationTemplatesLoading,
    setNonCatContext,
    setNonCatMode,
    setPage,
    setProduct,
    setReqLineIndex,
    setSupplierContactDetails,
    setSupplierSites,
} from "./../redux/actions/nonCatFormActions";
import store from "./../redux/store";
import { getOuBaseCurrency } from "./currencyUtils";
import { newSmartFormItem } from "./smartFormsUtils";

/**
 * Loads blank non-catalogue item into redux
 * @param {Function} dispatch - redux dispatcher
 * @param {string} activeOU - browsing OU in which item is being created
 * @param {[{ORG_NAME, BASE_CURRENCY}]} baseCurrencies - base currency mappings
 * @param {String} poLineId - optional UUID for non-catalogue item
 *
 * Note - `PO_LINE_ID` is a unique ID for the non-catalogue item
 */
export const newNonCatItem = (
    dispatch,
    activeOU,
    baseCurrencies,
    poLineId = null
) => {
    dispatch(
        setProduct({
            ...nonCatItemBase,
            PO_LINE_ID: poLineId ? poLineId : uuidv4(), // Give non-cat item a local UUID
            ITEM_TYPE: TYPE_OPTIONS[0],
            itemType: NON_CATALOGUE_ITEM,
            IBUY_CART_ITEM_OU: activeOU,
            CURRENCY_CODE: getOuBaseCurrency(activeOU, baseCurrencies),
        })
    );
};

export const openNonCatDialogue = (
    dispatch,
    mode,
    cartItem = null,
    reqLine = null,
    index = null,
    reqHeaderOU = null,
    smartForm = null
) => {
    /*
    Arguments
    ---------
    dispatch : func
    mode : str
    cartItem : obj
    reqLine : obj
    index: int
    reqHeaderOU : str
    smartForm : obj

    Notes
    -----
    Non catalogue form can be opened in three contexts: creating a 
    new non catalogue item, editing a non catalogue cart item and
    editing a non catalogue requisition line item. This function wraps
    all logic to launch the form under these contexts. It should always be
    used ot launch the form.

    Mode: the in line mode in which the non cat form is currently being used.
    This can be changed whilst the form is open (e.g. by pressing the reset button)

    Context: the context in which the form was opened. This cannot be amended
    without closing the form and reopening in another context. Used to set 
    titles and action buttons.
    */
    dispatch(setNonCatMode(mode));
    dispatch(setNonCatContext(mode));
    dispatch(setReqLineIndex(index));
    switch (mode) {
        case NEW_NON_CAT_ITEM:
            dispatch(setPage(0));
            dispatch(setSupplierContactDetails([]));
            dispatch(setSupplierSites([]));
            newNonCatItem(
                dispatch,
                store.getState().auth.activeOU,
                store.getState().filterPanelLookups.BASE_CURRENCIES
            );
            dispatch(setShowNonCatalogueDialogue(true));
            return undefined;
        case EDIT_NON_CAT_CART_ITEM:
            dispatch(setPage(1));
            presetSupplierSiteOptions(
                dispatch,
                cartItem?.SUPPLIER_ID,
                cartItem?.IBUY_CART_ITEM_OU
            );
            presetSupplierContactOptions(dispatch, cartItem?.SUPPLIER_SITE_ID);
            editNonCatCartItem(dispatch, cartItem);
            dispatch(setShowNonCatalogueDialogue(true));
            return undefined;
        case EDIT_NON_CAT_REQ_LINE:
            dispatch(setPage(1));
            presetSupplierSiteOptions(
                dispatch,
                reqLine?.SUPPLIER_ID,
                reqHeaderOU
            );
            presetSupplierContactOptions(dispatch, reqLine?.SUPPLIER_SITE_ID);
            editNonCatReqLineItem(dispatch, reqLine, reqHeaderOU);
            dispatch(setShowNonCatalogueDialogue(true));
            return undefined;
        case NEW_SMART_FORM_ITEM:
            dispatch(setInformationTemplatesLoading(true));
            presetSupplierSiteOptions(
                dispatch,
                smartForm?.SUPPLIER_ID,
                reqHeaderOU
            );
            presetSupplierContactOptions(dispatch, smartForm?.SUPPLIER_SITE_ID);
            newSmartFormItem(dispatch, reqHeaderOU, smartForm);
            dispatch(setShowSmartFormsDialogue(true));
            return undefined;
        default:
            console.error(
                "Invalid non catalogue mode provided to openNonCatDialogue utility"
            );
            return undefined;
    }
};

export const closeNonCatDialogue = (dispatch) => {
    /*
    Arguments
    ---------
    dispatch : func

    Notes
    -----
    This function does not clear out product. Product should be
    reset from scratch when the form is launched
    */
    dispatch(setShowNonCatalogueDialogue(false));
};

export const clearForm = (
    dispatch,
    clearFormKey,
    setClearFormKey,
    poLineId
) => {
    /*
    Arguments
    ---------
    dispatch : func
    clearFormKey :  bool
    setClearFormKey : func
    poLineId : int
    */
    const OU = store.getState().nonCatForm.product?.IBUY_CART_ITEM_OU;
    const baseCurrencies = store.getState().filterPanelLookups.BASE_CURRENCIES;
    dispatch(setNonCatMode(NEW_NON_CAT_ITEM));
    newNonCatItem(dispatch, OU, baseCurrencies, poLineId);
    setClearFormKey(!clearFormKey);
};

// If user selects a service non catalogue item,
// these fields must be defaulted
export const nonCatProductServiceSettings = {
    ITEM_TYPE: TYPE_OPTIONS[2],
    UNIT_OF_MEASURE: BY_AMOUNT_UOM_DESC_AND_CODE,
    UNIT_MEAS_LOOKUP_CODE: BY_AMOUNT_UOM_DESC_AND_CODE,
    UNIT_PRICE: "1",
    LINE_TYPE: SERVICE,
};

export const onNonCatItemTypeChange = (
    dispatch,
    setValue,
    setUnitOfMeasureValue,
    nonCatItemType,
    product
) => {
    /*
    Arguments
    ---------
    dispatch : func
    setValue :  func
    setUnitOfMeasureValue : func
    nonCatItemType : str
    product : obj
    */
    if (nonCatItemType == TYPE_OPTIONS[2]) {
        dispatch(
            setProduct({
                ...product,
                ...nonCatProductServiceSettings,
            })
        );
        // Preset form validators
        setValue("uom", BY_AMOUNT_UOM_DESC_AND_CODE);
        setValue("unitPrice", "1");
    } else {
        dispatch(
            setProduct({
                ...product,
                ITEM_TYPE: nonCatItemType,
                UNIT_MEAS_LOOKUP_CODE: null,
                UNIT_OF_MEASURE: null,
                LINE_TYPE: nonCatItemType == TYPE_OPTIONS[0] ? STOCK : DIRECT,
            })
        );
        setValue("uom", null);
        setUnitOfMeasureValue(null);
    }
};

export const editNonCatCartItem = (dispatch, cartItem) => {
    /*
    Arguments
    ---------
    dispatch : func
    cartItem : obj
    */
    dispatch(setProduct(cartItem));
};

export const editNonCatReqLineItem = (dispatch, reqLine, reqHeaderOU) => {
    /*
    Arguments
    ---------
    dispatch : func
    reqLine : obj
    reqHeaderOU : str
    */
    const product = Object.keys(nonCatItemBase).reduce((acc, nonCatField) => {
        return {
            ...acc,
            [nonCatField]: reqLine[nonCatReqLineTranslater[nonCatField]],
        };
    }, {});
    const productSupplierOrCPA = setSupplierCPAFields(product);
    dispatch(
        setProduct({ ...productSupplierOrCPA, IBUY_CART_ITEM_OU: reqHeaderOU })
    );
};

export const presetSupplierSiteOptions = async (dispatch, supplierId, OU) => {
    /*
    Arguments
    ---------
    dispatch : func
    supplierId : string
    OU : str
    */
    supplierId
        ? getSupplierSiteDetails(dispatch, OU, supplierId, null, null, true)
        : dispatch(setSupplierSites([]));
};

export const presetSupplierContactOptions = (dispatch, supplierSiteId) => {
    /*
    Arguments
    ---------
    dispatch : func
    supplierSiteId : string
    */
    supplierSiteId
        ? getSupplierContactDetails(dispatch, supplierSiteId, null, null, true)
        : dispatch(setSupplierContactDetails([]));
};

export const injectNonCatItemToReqLine = (reqLine, product) => {
    /*
    Arguments
    ---------
    reqLine : obj
    product : obj
    */
    return Object.keys(nonCatReqLineTranslater).reduce(
        (acc, productKey) => {
            return {
                ...acc,
                [nonCatReqLineTranslater[productKey]]: product[productKey],
            };
        },
        { ...reqLine }
    );
};

/**
 * Determines if updated npn-catalogue item can be injected into requisition
 * on pressing the edit requisition line button.
 * @param {*} product - updated item beng added to req
 * @param {*} req  - req to which the updated item is being added
 * @returns {[boolean, string | undefined]} - index 0: flag for if item can be added to req line.
 * index 1: flag for if it is a cpa req
 */
export const validNonCatItemInjection = (product, req) => {
    let isValid = true;
    let errorMessage = undefined;

    if (req.requisitionLines.length === 1) return [isValid, errorMessage];

    const isCpaReq = !!req?.requisitionLines[0]?.CPA_NUMBER;

    if (isCpaReq) {
        isValid = req.requisitionLines.every(
            (reqLine) => reqLine?.CPA_NUMBER == product?.CPA_NUMBER
        );

        if (!isValid) errorMessage = constants.PAYLOAD_SUBMIT_REQ_MULTIPLE_CPAS;
    } else {
        const isSupplierValid = product.SUPPLIER_NAME === req.supplierName;
        const isSupplierSiteValid =
            product?.SUPPLIER_NAME && !!product?.SUPPLIER_SITE_ID;
        const isCurrencyValid = req.requisitionLines.every(
            (reqLine) => reqLine.CURRENCY_CODE === product.CURRENCY_CODE
        );

        isValid = isSupplierValid && isCurrencyValid && isSupplierSiteValid;

        if (!isSupplierValid)
            errorMessage = constants.PAYLOAD_SUBMIT_REQ_MULTIPLE_SUPPLIERS;
        if (!isSupplierSiteValid)
            errorMessage = constants.PAYLOAD_SUBMIT_REQ_NO_SUPPLIERSITE;
        if (!isCurrencyValid)
            errorMessage = constants.PAYLOAD_SUBMIT_REQ_MULTIPLE_CURRENCIES;
    }

    return [isValid, errorMessage];
};

export const supplierDefined = (product) => {
    /*
    Arguments
    ----------
    product : obj
    */
    return !!product?.SUPPLIER_ID && !!product?.SUPPLIER_NUMBER_NAME;
};

export const cpaDefined = (product) => {
    /*
    Arguments
    ----------
    product : obj
    */
    return !!product?.CPA_NUMBER && !!product?.CPA_NUM_NAME;
};

export const supplierOrCpa = (product) => {
    /*
    Arguments
    ----------
    product : obj
    */
    switch (true) {
        case cpaDefined(product):
            return CPA;
        case supplierDefined(product):
            return SUPPLIER;
        default:
            return null;
    }
};

export const setSupplierCPAFields = (product) => {
    /*
    Arguments
    ---------
    product : obj

    Returns
    -------
    obj
    */
    switch (supplierOrCpa(product)) {
        case SUPPLIER:
            return {
                ...product,
                CPA_NUMBER: null,
                CPA_NUM_NAME: null,
            };

        case CPA:
            return {
                ...product,
                SUPPLIER_NAME: null,
                SUPPLIER_NUMBER_NAME: null,
                SUPPLIER_ID: null,
                SUPPLIER_SITE_CODE: null,
                SUPPLIER_SITE_ID: null,
                SUGGESTED_VENDOR_CONTACT_ID: null,
                SUGGESTED_VENDOR_PHONE: null,
            };
        default:
            return product;
    }
};

/**
 * Defaults rate information to corporate when a user selects
 * a non-base currency on the non-catalogue form
 * @returns {{RATE: string, RATE_TYPE: string, RATE_DATE: Date}}
 */
export const nonCatDefaultRateValues = () => ({
    RATE: "",
    RATE_TYPE: CORPORATE_RATE,
    RATE_DATE: new Date(),
});
