import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";

import Logo from "../logo/logo";
import { screenSX } from "./../../styled/styledSXProperties/styledBoxSX";

export const UnauthenticatedScreen = ({ loading }) => {
    const darkMode = useSelector((state) => state.general.darkMode);
    return (
        <Box
            sx={{
                ...screenSX(darkMode),
                backgroundImage: darkMode
                    ? "linear-gradient(to bottom right, #26262d, #26262dCC)"
                    : "linear-gradient(to bottom right, #F0F2F5, #F0F2F5)",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box display="flex" fontSize="50px" alignItems="center" gap={1}>
                <Logo sx={{ fontSize: "70px" }} color="iconButton" />
                <Typography fontSize="inherit" color="text.primary">
                    iBuy
                </Typography>
            </Box>
            <Box sx={{ width: "25%", mt: "25px" }}>
                {loading ? (
                    <LinearProgress color="secondary" />
                ) : (
                    <Typography
                        align="center"
                        variant="h5"
                        fontWeight="normal"
                        color="text.primary"
                    >
                        Unable to sign you in! Please try again and contact iBuy
                        administrators for support if this issue persists
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

UnauthenticatedScreen.propTypes = {
    loading: PropTypes.bool,
};

export default UnauthenticatedScreen;
