import { useMsal } from "@azure/msal-react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { readLocalStorageParams } from "utils/general";

import withAppInsights from "./appInsights";
import { msalInstance } from "./authProvider";
import AlertSnackbar from "./components/alerts/snackBars/alertSnackbar";
import CancelRequisitionDialogue from "./components/modals/cancellation/cancelRequisitionDialogue";
import AddToNewNamedCartDialogue from "./components/modals/cartManagement/addToNewNamedCartDialogue";
import CreateNamedCartDialogue from "./components/modals/cartManagement/createNamedCartDialogue";
import NonCatalogueDialogue from "./components/modals/nonCatalogueForm/nonCatalogueDialogue";
import CreateReceiptDialogue from "./components/modals/receiptCreation/createReceiptDialogue";
import ReceiptSummaryDialogue from "./components/modals/receiptSummary/receiptSummaryDialogue";
import RequisitionDialog from "./components/modals/requisition/requisitionDialog";
import SmartFormDialogue from "./components/modals/smartForms/smartFormDialogue";
import PageWrapper from "./pageWrapper";
import { setDefaultCartUserId } from "./redux/actions/shoppingCartActions";
import { getCarts } from "./services/cart/cartAPIs";
import {
    refreshApprovalsCounter,
    refreshReceiptsCounter,
} from "./services/requisition/requisitionAPIs";
import {
    getFilterPanelLookUps,
    getHomePageCategories,
} from "./services/search/searchAPIs";
import { screenSX } from "./styled/styledSXProperties/styledBoxSX";
import { getUserInfo } from "./utils/authUtils";

const App = () => {
    // Set active msal auth account
    const msalAccounts = msalInstance.getAllAccounts();
    if (msalAccounts.length > 0) {
        msalInstance.setActiveAccount(msalAccounts[0]);
    }

    // Collect states and state dispatchers
    const darkMode = useSelector((state) => state.general.darkMode);
    const userId = useSelector((state) => state.auth.samAccountName);
    const dispatch = useDispatch();

    const [isPrompted, setIsPrompted] = useState(false);

    const { accounts } = useMsal();

    const onIdle = async () => {
        const logoutRequest = {
            account: msalInstance.getAccountByHomeId(accounts[0].homeAccountId),
            postLogoutRedirectUri:
                msalInstance.getConfiguration().auth.redirectUri,
        };
        await msalInstance.logoutRedirect(logoutRequest);
    };

    const onPrompt = () => {
        setIsPrompted(true);
    };

    const resetIdle = () => {
        setIsPrompted(false);
        reset();
    };

    const { reset } = useIdleTimer({
        onIdle,
        timeout: 14.5 * 60 * 1000,
        promptTimeout: 30 * 1000,
        onPrompt,
    });

    // Get user info on app load
    useEffect(() => {
        // Retrieve User info
        getUserInfo(dispatch);
        readLocalStorageParams(dispatch);
        dispatch(setDefaultCartUserId(userId));

        // Initial Data Fetches
        getHomePageCategories(dispatch);
        getCarts(dispatch);
        getFilterPanelLookUps(dispatch);
    }, []);

    // Get user notifications on app load
    useEffect(() => {
        // Notification Fetches
        refreshApprovalsCounter(dispatch, userId);
        refreshReceiptsCounter(dispatch, userId);
    }, [userId]);

    return (
        <BrowserRouter>
            <Box sx={screenSX(darkMode)}>
                <PageWrapper />
            </Box>
            <AddToNewNamedCartDialogue />
            <AlertSnackbar />
            <CancelRequisitionDialogue />
            <CreateNamedCartDialogue />
            <CreateReceiptDialogue />
            <NonCatalogueDialogue />
            <RequisitionDialog />
            <SmartFormDialogue />
            <ReceiptSummaryDialogue />
            <Dialog
                open={isPrompted}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    size="24"
                    color="secondary"
                    id="alert-dialog-title"
                >
                    {"Idle Session Detected"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        color="text.primary"
                    >
                        You will be logged out in 30 seconds, press continue to
                        stay signed in.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={resetIdle}
                        color="secondary"
                        variant="contained"
                    >
                        Continue Session
                    </Button>
                </DialogActions>
            </Dialog>
        </BrowserRouter>
    );
};

export default withAppInsights(App);
