import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import { useState } from "react";

const TabbedPanels = ({ title, tabs }) => {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const handleTabChange = (_event, newTabIndex) => {
        setCurrentTabIndex(newTabIndex);
    };

    return (
        <Stack spacing={3}>
            <Box
                sx={{
                    borderBottom: 0,
                    borderColor: "divider",
                    borderTop: 0,
                }}
            >
                <Tabs
                    centered
                    value={currentTabIndex}
                    onChange={handleTabChange}
                    aria-label={`${title} tabbed panels`}
                    data-testid="tabbedPanels"
                    variant="fullWidth"
                    textColor="secondary"
                    indicatorColor="secondary"
                    sx={{
                        minHeight: "35px",
                        ".MuiTab-labelIcon": {
                            minHeight: "35px",
                        },
                    }}
                >
                    {tabs.map((tab) => {
                        return (
                            <Tab
                                key={`tab-control-${tab.name}`}
                                label={tab.name}
                                icon={tab.icon}
                                iconPosition="end"
                                id={`${tab.id}Tab`}
                                data-testid={`${tab.id}Tab`}
                                aria-controls={`${tab.id}TabPanel`}
                                sx={{ p: 0.5, mb: 0 }}
                            />
                        );
                    })}
                </Tabs>
            </Box>
            {tabs.map((tab, index) => {
                return (
                    <Box
                        key={`tab-panel-${tab.name}`}
                        role="tabpanel"
                        hidden={currentTabIndex !== index}
                        id={`${tab.id}TabPanel`}
                        aria-labelledby={`${tab.id}Tab`}
                    >
                        {currentTabIndex === index && <Box>{tab.content}</Box>}
                    </Box>
                );
            })}
        </Stack>
    );
};

TabbedPanels.propTypes = {
    title: PropTypes.string.isRequired,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            icon: PropTypes.element,
            id: PropTypes.string.isRequired,
            content: PropTypes.element.isRequired,
        })
    ).isRequired,
};

export default TabbedPanels;
