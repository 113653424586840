import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import DarkLightModeSwitch from "../../styled/styledComponents/darkLightModeSwitch";
import Logo from "../logo/logo";
import UserPopper from "../modals/userInfoPopper/userPopper";
import { BLANK_CART, DESKTOP } from "./../../constants/generalConstants";
import { HOMEPAGE } from "./../../constants/routes";
import {
    setDarkMode,
    setShowCreateNameCartDialogue,
    setShowShoppingCartSummaryPopper,
    setShowUserInfo,
} from "./../../redux/actions/generalActions";
import { totalQuantity } from "./../../utils/shoppingCartUtils";
import ShoppingCartSummaryPopper from "./../modals/cartSummaryPopper/shoppingCartSummaryPopper";
import IBuySearch from "./iBuySearch";
import SearchBarBackground from "./searchBarBackground";

const SearchBar = () => {
    // Collect states and state dispatchers
    const activeCart = useSelector((state) => state.general.activeCart);
    const darkMode = useSelector((state) => state.general.darkMode);
    const activeOU = useSelector((state) => state.auth.activeOU);
    const carts = useSelector((state) => state.shoppingCart);
    const showShoppingCartSummaryPopper = useSelector(
        (state) => state.general.showShoppingCartSummaryPopper
    );
    const showUserInfo = useSelector((state) => state.general.showUserInfo);
    const userName = useSelector((state) => state.auth.userName);
    const dispatch = useDispatch();

    const shoppingCartIconRef = useRef(null);
    const userIconRef = useRef(null);
    const iconSX = { color: "primary.contrastText" };

    const createBlankCart = () => {
        dispatch(
            setShowCreateNameCartDialogue({ open: true, mode: BLANK_CART })
        );
    };

    const nameLabel = () => {
        const name = userName ? userName : "User";
        const OU = activeOU ? ` - ${activeOU}` : "";
        return name + OU;
    };

    const toggleTheme = (event) => {
        const isChecked = event.target.checked;

        dispatch(setDarkMode(isChecked));
        localStorage.setItem("darkMode", isChecked);
    };

    return (
        <SearchBarBackground>
            <Link to={HOMEPAGE}>
                <IconButton aria-label="sseLogo">
                    <Logo htmlColor="white" fontSize="large" />
                </IconButton>
            </Link>
            <IBuySearch mode={DESKTOP} />
            <Divider
                orientation="vertical"
                sx={{ height: 28, m: 0.5, bgcolor: "primary.contrastText" }}
            />
            <Tooltip title="View Current Cart">
                <IconButton
                    aria-label="cartLogo"
                    onClick={() =>
                        dispatch(
                            setShowShoppingCartSummaryPopper(
                                !showShoppingCartSummaryPopper
                            )
                        )
                    }
                    ref={shoppingCartIconRef}
                >
                    <Badge
                        badgeContent={totalQuantity(carts[activeCart].items)}
                        color="error"
                    >
                        <ShoppingCartIcon sx={iconSX} />
                    </Badge>
                </IconButton>
            </Tooltip>
            <ShoppingCartSummaryPopper
                shoppingCartIconRef={shoppingCartIconRef}
            />
            <Tooltip title="Create New Cart">
                <IconButton
                    aria-label="createBlankCart"
                    onClick={createBlankCart}
                >
                    <AddShoppingCartIcon sx={iconSX} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Toggle between dark and light mode">
                <DarkLightModeSwitch
                    aria-label="iBuy Theme Toggle"
                    onClick={toggleTheme}
                    checked={darkMode}
                />
            </Tooltip>
            <IconButton
                aria-label="userName"
                onClick={() => dispatch(setShowUserInfo(!showUserInfo))}
                ref={userIconRef}
            >
                <Tooltip title="Click to change your browsing operating unit">
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography
                            sx={{
                                color: "primary.contrastText",
                                fontSize: 16,
                                fontWeight: 700,
                                pr: "2px",
                            }}
                        >
                            {nameLabel()}
                        </Typography>
                        <KeyboardArrowDownIcon sx={iconSX} />
                    </Box>
                </Tooltip>
            </IconButton>
            <UserPopper userIconRef={userIconRef} />
        </SearchBarBackground>
    );
};
export default SearchBar;
