import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { NAV_BAR_FADE_TIMEOUT } from "../../constants/transitions";
import GradientDivider from "../../styled/styledComponents/GradientDivider";
import Logo from "../logo/logo";
import { toggleNavigationBarWidth } from "./../../redux/actions/generalActions";
import { navBarSX } from "./../../styled/styledSXProperties/styledPaperSX";
import NavigationButton from "./navigationButton";
import navigationItems from "./navigationItems";

const COLLAPSED_WIDTH = "180px";
const EXPANDED_WIDTH = "60px";
// eslint-disable-next-line no-undef
export const VERSION = GLOBAL_CONFIG.version;
// eslint-disable-next-line no-undef
export const RELEASE_DATE = GLOBAL_CONFIG.releaseDate;

const NavigationBar = () => {
    // Collect states and state dispatchers
    const activeCart = useSelector((state) => state.general.activeCart);
    const collapseNavBar = useSelector((state) => state.general.collapseNavBar);
    const approvalsNotificationCount = useSelector(
        (state) => state.general.approvalsNotificationCount
    );
    const darkMode = useSelector((state) => state.general.darkMode);
    const receiptsNotificationCount = useSelector(
        (state) => state.general.receiptsNotificationCount
    );
    const dispatch = useDispatch();

    useEffect(() => {
        function handleResize() {
            window.innerWidth < 1500 &&
                dispatch(toggleNavigationBarWidth(true));
        }
        window.addEventListener("resize", handleResize);
    });

    const navItems = navigationItems(
        activeCart,
        approvalsNotificationCount,
        receiptsNotificationCount
    );

    return (
        <Paper
            sx={{
                ...navBarSX(darkMode),
                flexBasis: collapseNavBar ? EXPANDED_WIDTH : COLLAPSED_WIDTH,
            }}
        >
            <IconButton
                onClick={() =>
                    dispatch(toggleNavigationBarWidth(!collapseNavBar))
                }
                aria-label="collapseNavBarIcon"
            >
                {collapseNavBar ? (
                    <KeyboardArrowRightIcon
                        color="iconButton"
                        sx={{ marginTop: "9px" }}
                    />
                ) : (
                    <MenuOpenIcon
                        color="iconButton"
                        sx={{
                            marginTop: "9px",
                        }}
                    />
                )}
            </IconButton>
            <GradientDivider />
            {navItems.map((item) => (
                <NavigationButton
                    key={item.key}
                    icon={item.icon}
                    buttonLabel={item.key}
                    tooltip={item.tooltip}
                    navigate={item.route ? item.route : null}
                    notificationCount={
                        item.notificationCount ? item.notificationCount : null
                    }
                    customClick={item.action ? item.action : null}
                />
            ))}
            <Box sx={{ flex: 4 }} />
            <IconButton disabled aria-label="sseLogodisabled">
                <Logo color="iconButton" fontSize="large" />
            </IconButton>
            {!collapseNavBar && (
                <Fade in={true} timeout={NAV_BAR_FADE_TIMEOUT}>
                    <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                        <Typography align="center" fontSize="body1">
                            iBuy Version
                        </Typography>
                        <Typography
                            align="center"
                            color="text.secondary"
                            fontSize="body1"
                        >
                            {VERSION}
                        </Typography>
                        <Typography align="center" fontSize="body1">
                            Release Date
                        </Typography>
                        <Typography
                            align="center"
                            color="text.secondary"
                            fontSize="body1"
                        >
                            {RELEASE_DATE}
                        </Typography>
                    </Box>
                </Fade>
            )}
        </Paper>
    );
};

export default NavigationBar;
