import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";

import { ITEMS_PER_PAGE } from "../../constants/generalConstants";
import {
    searchCatalogueItems,
    searchSmartFormItems,
} from "../../services/search/searchAPIs";
import ProductCardSkeleton from "../skeletons/productCardSkeleton";
import ProductCard from "./productCard";
import SmartFormCard from "./smartFormCard";

const InnerList = ({ results, isLoading, isDesktop, showSmartForms }) => {
    return (
        <>
            {results.map((item, index) => {
                return (
                    <Grid
                        key={index}
                        item
                        xs={12}
                        md={6}
                        lg={3}
                        xl={showSmartForms ? 3 : 2.4}
                    >
                        {isLoading ? (
                            <ProductCardSkeleton isDesktop={isDesktop} />
                        ) : showSmartForms ? (
                            <SmartFormCard
                                smartForm={item}
                                isLoading={isLoading}
                            />
                        ) : (
                            <ProductCard product={item} isDesktop={isDesktop} />
                        )}
                    </Grid>
                );
            })}
        </>
    );
};

const ProductResultsGrid = ({
    isLoading,
    page,
    results,
    searchParams,
    setPage,
    setResults,
    totalResults,
    isDesktop,
    showSmartForms,
}) => {
    // Collect states and state dispatchers
    const activeOU = useSelector((state) => state.auth.activeOU);

    // Local states
    const [pageLoading, setPageLoading] = useState(false);

    // Note, pagination offset params are not appended to search params URL
    // Refer to iBuy wikki: Azure iBuy application > iBuy features > 3.5
    const getNextPage = async () => {
        setPageLoading(true);
        searchParams.set("offset", page * ITEMS_PER_PAGE);
        let response;
        if (showSmartForms) {
            response = await searchSmartFormItems(
                searchParams.toString(),
                activeOU
            );
        } else {
            response = await searchCatalogueItems(
                searchParams.toString(),
                activeOU
            );
        }
        searchParams.delete("offset"); // Offset params not permanently stored in URL.
        const nextPage = response.data ? response.data : [];
        setResults([...results, ...nextPage]);
        setPage(page + 1);
        setPageLoading(false);
    };

    return (
        <Grid container item spacing={2}>
            {!isDesktop && (
                <InfiniteScroll
                    style={{ width: "100%" }}
                    dataLength={results.length} //This is important field to render the next data
                    next={getNextPage}
                    scrollThreshold="75%"
                    hasMore={page < Math.ceil(totalResults / ITEMS_PER_PAGE)}
                    loader={
                        <Box
                            display="flex"
                            justifyContent="center"
                            width="100%"
                            my={2}
                        >
                            <CircularProgress
                                id="product-details-circular-progress"
                                color="secondary"
                            />
                        </Box>
                    }
                    endMessage={
                        <Box
                            display="flex"
                            justifyContent="center"
                            width="100%"
                            my={2}
                        >
                            <Typography
                                variant="h5"
                                color="primary.contrastText"
                                textAlign="center"
                            >
                                End of Results
                            </Typography>
                        </Box>
                    }
                    scrollableTarget="scrollableBox"
                >
                    <InnerList
                        results={results}
                        isLoading={isLoading}
                        isDesktop={isDesktop}
                        showSmartForms={showSmartForms}
                    />
                </InfiniteScroll>
            )}
            {isDesktop && (
                <InnerList
                    results={results}
                    isLoading={isLoading}
                    isDesktop={isDesktop}
                    showSmartForms={showSmartForms}
                />
            )}
            {/* If there are pages to scroll through, render see more button */}
            {isDesktop && page < Math.ceil(totalResults / ITEMS_PER_PAGE) && (
                <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <LoadingButton
                        color="secondary"
                        endIcon={<ExpandMoreIcon />}
                        loading={pageLoading}
                        loadingPosition="end"
                        onClick={getNextPage}
                        sx={{ width: "20%" }}
                    >
                        See More
                    </LoadingButton>
                </Grid>
            )}
        </Grid>
    );
};

ProductResultsGrid.propTypes = {
    isLoading: PropTypes.bool,
    page: PropTypes.number,
    results: PropTypes.array,
    searchParams: PropTypes.object,
    setPage: PropTypes.func,
    setResults: PropTypes.func,
    totalResults: PropTypes.number,
    showSmartForms: PropTypes.bool,
};

export default ProductResultsGrid;
