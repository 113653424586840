import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

import { MOBILE_MEDIA_QUERY } from "../../../constants/mediaQueries";
import styledDialogueSX from "../../../styled/styledSXProperties/styledDialogueSX";
import { subTextStyle } from "../../../styled/styledSXProperties/styledSubText";

const GenericModalContainer = ({
    title,
    closeModal,
    isOpen,
    children,
    subHeader,
}) => {
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    return (
        <Dialog
            open={isOpen}
            onClose={() => {
                closeModal();
            }}
            fullScreen={!isDesktop}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogContent sx={styledDialogueSX}>
                <Grid container spacing={2} marginBottom="16px">
                    <Grid item xs={11}>
                        <Typography variant="h3" marginBottom={"4px"}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={1}
                        justifyContent="flex-end"
                        alignContent="center"
                    >
                        <IconButton
                            onClick={() => {
                                closeModal();
                            }}
                            aria-label="closeApprovalsModal"
                            sx={{ mt: "-25px" }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                {subHeader && (
                    <Typography {...subTextStyle}>{subHeader}</Typography>
                )}
                {children}
            </DialogContent>
        </Dialog>
    );
};

GenericModalContainer.propTypes = {
    title: PropTypes.string,
    closeModal: PropTypes.func,
};

export default GenericModalContainer;
