import { initialState } from "../initialState";
import {
    SET_CONTACTS_INITIAL_LOADING,
    SET_INFORMATION_TEMPLATES_LOADING,
    SET_NON_CAT_CONTEXT,
    SET_NON_CAT_MODE,
    SET_PAGE,
    SET_PRODUCT,
    SET_PRODUCT_RATE,
    SET_REQ_LINE_INDEX,
    SET_SITES_INITIAL_LOADING,
    SET_SMART_FORMS_DATA_LOADING,
    SET_SUPPLIER_CONTACT_DETAILS,
    SET_SUPPLIER_SITES,
} from "./../actionTypes";

const nonCatFormReducer = (state = initialState.nonCatForm, action) => {
    switch (action.type) {
        case SET_NON_CAT_CONTEXT:
            return { ...state, context: action.payload };
        case SET_NON_CAT_MODE:
            return { ...state, mode: action.payload };
        case SET_PAGE:
            return { ...state, page: action.payload };
        case SET_PRODUCT:
            return { ...state, product: action.payload };
        case SET_PRODUCT_RATE:
            return {
                ...state,
                product: { ...state.product, RATE: action.payload },
            };
        case SET_REQ_LINE_INDEX:
            return { ...state, reqLineIndex: action.payload };
        case SET_SUPPLIER_CONTACT_DETAILS:
            return { ...state, supplierContactDetails: action.payload };
        case SET_SUPPLIER_SITES:
            return { ...state, supplierSites: action.payload };
        case SET_SITES_INITIAL_LOADING:
            return { ...state, sitesInitialLoading: action.payload };
        case SET_CONTACTS_INITIAL_LOADING:
            return { ...state, contactsInitialLoading: action.payload };
        case SET_SMART_FORMS_DATA_LOADING:
            return { ...state, smartFormsDataLoading: action.payload };
        case SET_INFORMATION_TEMPLATES_LOADING:
            return {
                ...state,
                informationTemplatesLoading: action.payload,
            };
        default:
            return state;
    }
};

export default nonCatFormReducer;
