import { useMsal } from "@azure/msal-react";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HomeIcon from "@mui/icons-material/Home";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LogoutIcon from "@mui/icons-material/Logout";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { useDispatch, useSelector } from "react-redux";

import {
    IBUY_FAQ_SHAREPOINT_URL,
    IS_PROC_MANAGER_REDUX_ROLE,
    NEW_NON_CAT_ITEM,
} from "../../constants/generalConstants";
import { logOutUser } from "../../utils/authUtils";
import { openNonCatDialogue } from "../../utils/nonCatFormUtils";
import * as routes from "./../../constants/routes";

const goToFAQs = () => {
    window.open(IBUY_FAQ_SHAREPOINT_URL, "_blank").focus();
};

const navigationItems = (
    activeCart,
    approvalsNotificationCount,
    receiptsNotificationCount
) => {
    const isProcManager = useSelector((state) =>
        IS_PROC_MANAGER_REDUX_ROLE.includes(state.auth.role)
    );
    const dispatch = useDispatch();
    const { accounts } = useMsal();

    return [
        {
            key: "Home",
            tooltip: "Homepage",
            icon: <HomeIcon color="iconButton" />,
            iconSpeedDial: <HomeIcon color="white" />,
            route: routes.HOMEPAGE,
        },
        {
            key: "Product List",
            tooltip: "Product List",
            icon: <LocalOfferIcon color="iconButton" />,
            iconSpeedDial: <LocalOfferIcon color="white" />,
            route: routes.PRODUCT_RESULTS,
        },
        {
            key: "Shopping Cart",
            tooltip: "Shopping Cart",
            icon: <ShoppingCartIcon color="iconButton" />,
            iconSpeedDial: <ShoppingCartIcon color="white" />,
            route: routes.CART_PREFIX + activeCart,
        },
        {
            key: "Requisitions",
            tooltip: "Requisitions",
            icon: <StorefrontIcon color="iconButton" />,
            iconSpeedDial: <StorefrontIcon color="white" />,
            route: routes.REQUISITIONS,
        },
        {
            key: "My Approvals",
            tooltip:
                "Approvals" +
                (approvalsNotificationCount > 0
                    ? " - " +
                      approvalsNotificationCount +
                      " requiring your approval."
                    : ""),
            icon: <HowToRegIcon color="iconButton" />,
            iconSpeedDial: <HowToRegIcon color="white" />,
            route: routes.APPROVALS,
            notificationCount: approvalsNotificationCount,
        },
        {
            key: "Receipting",
            tooltip:
                "Receipting" +
                (receiptsNotificationCount > 0
                    ? " - " +
                      receiptsNotificationCount +
                      " requiring your attention."
                    : ""),
            icon: <ReceiptIcon color="iconButton" />,
            iconSpeedDial: <ReceiptIcon color="white" />,
            route: routes.RECEIPTS_PRIORITISED(receiptsNotificationCount),
            notificationCount: receiptsNotificationCount,
        },
        {
            key: "Non-Catalogue",
            tooltip: "Non-Catalogue",
            icon: <DriveFileRenameOutlineOutlinedIcon color="iconButton" />,
            iconSpeedDial: <DriveFileRenameOutlineOutlinedIcon color="white" />,
            action: () => openNonCatDialogue(dispatch, NEW_NON_CAT_ITEM),
        },
        {
            key: "Support",
            tooltip: "Support",
            icon: <HelpOutlineIcon color="iconButton" />,
            iconSpeedDial: <HelpOutlineIcon color="#000000" />,
            action: goToFAQs,
        },
        {
            key: "Administrator",
            tooltip: "Procurement Administrator Settings",
            icon: <AdminPanelSettingsIcon color="iconButton" />,
            iconSpeedDial: <AdminPanelSettingsIcon color="#000000" />,
            route: routes.PROCUREMENT_MANAGER_ADMIN,
            exclude: isProcManager !== true,
        },
        {
            key: "Logout",
            tooltip: "Logout",
            icon: <LogoutIcon color="iconButton" />,
            iconSpeedDial: <LogoutIcon color="white" />,
            action: () => logOutUser(accounts),
        },
    ].filter((item) => !item?.exclude);
};

export default navigationItems;
