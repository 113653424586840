/**
 * Returns base currency of specified operating unit by looking
 * up `BASE_CURRENCIES` redux lookup state.
 * @param {string} operatingUnit - operating unit name (eg. SSE)
 * @param {[{ORG_NAME, BASE_CURRENCY}]} baseCurrencyMappings - base currency mappings
 * @returns {string} - the base currency of `operatingUnit`. Empty
 * string returned if no match.
 */
export const getOuBaseCurrency = (operatingUnit, baseCurrencyMappings) => {
    const baseCurrencyMappingsCleansed = Array.isArray(baseCurrencyMappings)
        ? baseCurrencyMappings
        : [];
    return (
        baseCurrencyMappingsCleansed.find(
            (mapping) => mapping?.ORG_NAME === operatingUnit
        )?.BASE_CURRENCY ?? ""
    );
};
