import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { MOBILE_MEDIA_QUERY } from "constants/mediaQueries";
import React from "react";

const CreateReceiptSkeleton = () => {
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    return (
        <Grid container spacing={2}>
            {/* Summary details */}
            <Grid item xs={12}>
                <Skeleton
                    aria-label="summary-details"
                    height={isDesktop ? 30 : 80}
                    sx={{
                        borderRadius: "10px",
                        boxSizing: "border-box",
                    }}
                />
            </Grid>

            {/* Header information */}
            <Grid item container xs={12}>
                <Grid item xs={12} md={2}>
                    <Skeleton
                        aria-label="header-information-title"
                        height={25}
                        sx={{
                            borderRadius: "10px",
                            boxSizing: "border-box",
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12} spacing={1}>
                <Grid item xs={12} md={4}>
                    <Skeleton
                        aria-label="reference-number"
                        height={50}
                        sx={{
                            borderRadius: "10px",
                            boxSizing: "border-box",
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Skeleton
                        aria-label="receipt-date"
                        height={50}
                        sx={{
                            borderRadius: "10px",
                            boxSizing: "border-box",
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Skeleton
                        aria-label="comments"
                        height={50}
                        sx={{
                            borderRadius: "10px",
                            boxSizing: "border-box",
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton
                        aria-label="check-all-receipts"
                        height={50}
                        sx={{
                            borderRadius: "10px",
                            boxSizing: "border-box",
                        }}
                    />
                </Grid>
            </Grid>

            {/* Req lines */}
            <Grid item xs={12}>
                <Skeleton
                    aria-label="req-lines-title"
                    height={25}
                    width={isDesktop ? "40%" : "auto"}
                    sx={{
                        borderRadius: "10px",
                        boxSizing: "border-box",
                    }}
                />
            </Grid>
            {[...Array(2).keys()].map((v) => {
                return (
                    <Grid item xs={12} key={v}>
                        <Skeleton
                            aria-label="req-line-accordion"
                            height={65}
                            variant="rectangular"
                            sx={{
                                borderRadius: "10px",
                                boxSizing: "border-box",
                            }}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};
export default CreateReceiptSkeleton;
