import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

const PreFlightCheckInvalidAlert = () => {
    const darkMode = useSelector((state) => state.general.darkMode);
    return (
        <Alert severity="warning" variant={darkMode ? "outlined" : "standard"}>
            <AlertTitle>Invalid Details</AlertTitle>
            <Typography variant="body2">
                The entered information is not valid -
                <strong>please enter different information</strong>
            </Typography>
        </Alert>
    );
};

export default PreFlightCheckInvalidAlert;
