import FilterListIcon from "@mui/icons-material/FilterList";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const OpenFiltersButton = ({ setIsOpen }) => {
    return (
        <Button
            onClick={() => setIsOpen(true)}
            variant="contained"
            color="secondary"
            data-testid="openFiltersButton"
            startIcon={<FilterListIcon />}
        >
            Filter
        </Button>
    );
};

OpenFiltersButton.propTypes = {
    setIsOpen: PropTypes.func.isRequired,
};

export default OpenFiltersButton;
