import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorIcon from "@mui/icons-material/Error";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RuleIcon from "@mui/icons-material/Rule";
import { LoadingButton } from "@mui/lab";
import { Button, CardContent, CardHeader, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CopyTypography from "components/displays/copyTypography";
import IBuyCard from "components/displays/iBuyCard";
import ProcurementCard from "components/displays/procurementCard";
import ProcurementHeader from "components/displays/procurementHeader";
import TabbedPanels from "components/tabbedPanels";
import OnDesktop from "components/wrappers/onDesktop";
import OnMobile from "components/wrappers/onMobile";
import { MOBILE_MEDIA_QUERY } from "constants/mediaQueries";
import {
    TOOLTIP_INVOICE_DOCUMENT_BUTTON_AVAILABLE,
    TOOLTIP_INVOICE_DOCUMENT_BUTTON_NA,
} from "constants/tooltipText";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setShowDisputeDialogue } from "redux/actions/generalActions";

import axiosApiInstance from "../../services/middleware/axios";
import { GET_INVOICE_BY_ID } from "../../services/procurement/urls";
import { getDispute } from "../../services/receipting/receiptingAPIs";
import { contentBoxL2 } from "../../styled/styledSXProperties/styledBoxSX";
import alertDispatcher from "../../utils/alertsUtils";
import CurrencyFigure from "../displays/currencyFigure";
import FormatNameValue from "../displays/formatNameValue";
import IBuyPaper from "../displays/iBuyPaper";
import PageNotFound from "../pageNotFound/pageNotFound";
import RequisitionSummarySkeleton from "../skeletons/requisitionSummarySkeleton";
import DisputesDialogue from "./disputes/disputesDialogue";
import InvoiceSummaryTabs from "./invoiceSummaryTabs";

// Styles for typography components
const headerStyle = { color: "text.subheader", variant: "h4" };
const subTextStyle = { color: "text.primary", variant: "body1" };

const subHeaderStyle = {
    color: "text.subheader",
    fontWeight: "bold",
    variant: "h5",
};

const convertOU = {
    105: "SSE",
    121: "GEN",
    1256: "IRE",
    203: "RENUK",
    4461: "GENIRE",
};

/**
 * information object
 * @param {object} invoice
 * @returns
 */
const information = (invoice) => [
    {
        title: "General",
        data: [
            { name: "Description", value: invoice?.DESCRIPTION },
            {
                name: "Operating Unit",
                value: convertOU[invoice?.ORG_ID] ?? invoice?.ORG_ID,
            },
            { name: "Invoice Source", value: invoice?.SOURCE },
            { name: "Approval Status", value: invoice?.APPROVAL_STATUS },
            {
                name: "Approval Description",
                value: invoice?.APPROVAL_DESCRIPTION,
            },
        ],
    },
    {
        title: "Accounting",
        data: [
            { name: "Currency", value: invoice?.INVOICE_CURRENCY_CODE },
            { name: "Invoice Type", value: invoice?.INVOICE_TYPE_LOOKUP_CODE },
            {
                name: "Payment Method",
                value: invoice?.PAYMENT_METHOD_CODE,
            },
            { name: "Supplier Number", value: invoice?.SUPPLIER_SITE_ID },
            { name: "Supplier Site", value: invoice?.SUPPLIER_NAME },
        ],
    },
    {
        title: "Supplier",
        data: [
            { name: "Supplier Name", value: invoice?.SUPPLIER_NAME },
            { name: "Supplier Site Code", value: invoice?.SUPPLIER_SITE_CODE },
        ],
    },
    {
        title: "Terms",
        data: [
            { name: "Terms Name", value: invoice?.TERMS_NAME },
            { name: "Terms Date", value: invoice?.TERMS_DATE },
        ],
    },
];

/**
 * Invoice status chips
 * @param {object} invoice
 * @param {object} dispute
 * @param {boolean} isDesktop
 * @returns
 */
const InvoiceStatusChips = ({ invoice, dispute, isDesktop }) => {
    const disputeTooltip = () =>
        `This invoice was placed in dispute by user ${
            dispute?.USER_ID ? dispute?.USER_ID : ""
        } on  ${
            dispute?.DISPUTE_DATE ? dispute?.DISPUTE_DATE : ""
        }. It was placed in dispute for the following reason: ${
            dispute?.DISPUTE_REASON ? dispute?.DISPUTE_REASON : ""
        }`;

    return (
        <>
            <Chip
                sx={{ mr: isDesktop ? 2 : 1 }}
                label={
                    invoice?.PAYMENT_STATUS_FLAG == "Y" ? "PAID" : "NOT PAID"
                }
                size="small"
                color={
                    invoice?.PAYMENT_STATUS_FLAG == "Y" ? "success" : "warning"
                }
                icon={<CheckCircleOutlineIcon />}
                variant="filled"
            />
            <Chip
                sx={{ mr: isDesktop ? 2 : 1 }}
                label={`APPROVAL ${isDesktop ? "STATUS" : ""}: ${
                    invoice?.APPROVAL_STATUS ?? "N/A"
                }`}
                size="small"
                color={invoice?.APPROVAL_STATUS ? "success" : "warning"}
                variant="filled"
            />
            {dispute && (
                <Tooltip title={disputeTooltip()}>
                    <Chip
                        label="IN DISPUTE"
                        size="small"
                        color="error"
                        icon={<ErrorIcon />}
                        variant="filled"
                    />
                </Tooltip>
            )}
        </>
    );
};
InvoiceStatusChips.propTypes = {
    invoice: PropTypes.object,
    dispute: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    isDesktop: PropTypes.bool,
};

const InvoiceSummaryPage = () => {
    // Collect states and dispatchers
    const dispatch = useDispatch();

    // Local states
    const [disputeSubmitKey, setDisputeSubmitKey] = useState(true);
    const [invoice, setInvoice] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);

    // Dispute local states
    const [isDisputeLoading, setIsDisputeLoading] = useState(true);
    const [dispute, setDispute] = useState(false);
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    const { invoiceId } = useParams();

    useEffect(() => {
        setIsLoading(true);
        setNotFound(false);
        axiosApiInstance
            .get(GET_INVOICE_BY_ID(invoiceId))
            .then((response) => {
                setInvoice(response.data);
                setIsLoading(false);
            })
            .catch((e) => {
                alertDispatcher("invoice", e?.response?.status, dispatch);
                setNotFound(true);
                setIsLoading(false);
            });
    }, [invoiceId]);

    // Used to trigger the useEffect responsible for retrieving the dispute status of the invoice.
    // This function is called when an invoice is successfully placed into dispute, refiring
    // the get disputes api
    const toggleDisputeSubmitKey = () => setDisputeSubmitKey(!disputeSubmitKey);

    useEffect(() => {
        getDispute(invoiceId, setIsDisputeLoading, setDispute, dispatch);
    }, [disputeSubmitKey]);

    const goToInvoice = () => {
        if (invoice?.DOCUMENT_URL) {
            window.open(invoice.DOCUMENT_URL);
        }
    };

    return (
        <Box sx={contentBoxL2}>
            {notFound ? (
                <PageNotFound />
            ) : !isLoading ? (
                <>
                    <Grid
                        container
                        spacing={3}
                        alignContent="flex-start"
                        alignItems="start"
                        data-testid="InvoiceSummaryHeader"
                    >
                        {/* Title Bar */}
                        <ProcurementHeader
                            Header={
                                <>
                                    <Typography {...headerStyle}>
                                        Invoice ID:&nbsp;
                                    </Typography>
                                    <CopyTypography {...headerStyle}>
                                        {invoice?.INVOICE_ID}
                                    </CopyTypography>
                                </>
                            }
                            Chips={
                                <InvoiceStatusChips
                                    isDesktop={isDesktop}
                                    invoice={invoice}
                                    dispute={dispute}
                                />
                            }
                            Buttons={
                                <>
                                    <LoadingButton
                                        endIcon={<RuleIcon />}
                                        loading={isDisputeLoading}
                                        disabled={
                                            !isDisputeLoading &&
                                            dispute !== false
                                        }
                                        loadingPosition="end"
                                        onClick={() =>
                                            dispatch(
                                                setShowDisputeDialogue(true)
                                            )
                                        }
                                        variant="contained"
                                        size={isDesktop ? "medium" : "small"}
                                        color="secondary"
                                        sx={{ mr: "24px" }}
                                    >
                                        Dispute Invoice
                                    </LoadingButton>
                                    <Tooltip
                                        title={
                                            invoice?.DOCUMENT_URL
                                                ? TOOLTIP_INVOICE_DOCUMENT_BUTTON_AVAILABLE
                                                : TOOLTIP_INVOICE_DOCUMENT_BUTTON_NA
                                        }
                                        followCursor={true}
                                    >
                                        <span>
                                            <Button
                                                disabled={
                                                    !invoice?.DOCUMENT_URL
                                                }
                                                endIcon={<OpenInNewIcon />}
                                                onClick={goToInvoice}
                                                variant="contained"
                                                color="secondary"
                                                size={
                                                    isDesktop
                                                        ? "medium"
                                                        : "small"
                                                }
                                            >
                                                View Invoice
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </>
                            }
                            SubHeader={
                                <>
                                    <FormatNameValue
                                        name="Invoice Number"
                                        value={invoice?.INVOICE_NUM}
                                        variant={subTextStyle.variant}
                                        sx={{ mr: "16px" }}
                                    />
                                    <OnDesktop>
                                        <Typography
                                            {...subTextStyle}
                                            sx={{ mr: "16px" }}
                                        >
                                            |
                                        </Typography>
                                    </OnDesktop>
                                    <FormatNameValue
                                        name="Supplier"
                                        value={invoice?.SUPPLIER_NAME}
                                        variant={subTextStyle.variant}
                                        sx={{ mr: "16px" }}
                                    />
                                    <OnDesktop>
                                        <Typography
                                            {...subTextStyle}
                                            sx={{ mr: "16px" }}
                                        >
                                            |
                                        </Typography>
                                    </OnDesktop>
                                    <FormatNameValue
                                        name="Invoice Date"
                                        value={invoice?.INVOICE_DATE}
                                        variant={subTextStyle.variant}
                                        sx={{ mr: "16px" }}
                                    />
                                    <OnDesktop>
                                        <Typography
                                            {...subTextStyle}
                                            sx={{ mr: "16px" }}
                                        >
                                            |
                                        </Typography>
                                    </OnDesktop>
                                    <FormatNameValue
                                        name="Operating Unit"
                                        value={
                                            convertOU[invoice?.ORG_ID] ??
                                            invoice?.ORG_ID
                                        }
                                        variant={subTextStyle.variant}
                                        sx={{ mr: "16px" }}
                                    />
                                </>
                            }
                        />
                        {/* Row 2: approvals and line item summary */}
                        <Grid item container xs={12} spacing={3}>
                            {invoice && (
                                <>
                                    <OnDesktop>
                                        <Grid
                                            item
                                            container
                                            xs={12}
                                            lg={8}
                                            spacing={3}
                                            order={isDesktop ? 1 : 2}
                                            pt={0}
                                        >
                                            {information(invoice).map(
                                                (card, idx) => {
                                                    return (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            xl={6}
                                                            key={idx}
                                                            mb={0}
                                                        >
                                                            <ProcurementCard
                                                                data={card}
                                                                key={idx}
                                                                isDesktop={
                                                                    isDesktop
                                                                }
                                                            />
                                                        </Grid>
                                                    );
                                                }
                                            )}
                                        </Grid>
                                    </OnDesktop>
                                    <OnMobile>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{ mt: 0 }}
                                            order={isDesktop ? 1 : 2}
                                        >
                                            <IBuyPaper
                                                additionalSx={{ padding: 0 }}
                                            >
                                                <TabbedPanels
                                                    title="Invoice summary tabs"
                                                    tabs={information(
                                                        invoice
                                                    ).map((card, idx) => {
                                                        return {
                                                            name: card.title,
                                                            id: card.title,
                                                            content: (
                                                                <ProcurementCard
                                                                    data={card}
                                                                    key={idx}
                                                                />
                                                            ),
                                                        };
                                                    })}
                                                />
                                            </IBuyPaper>
                                        </Grid>
                                    </OnMobile>
                                </>
                            )}
                            <Grid item xs={12} lg={4} order={isDesktop ? 2 : 1}>
                                <IBuyCard additionalSx={{ mb: 0 }}>
                                    <CardHeader
                                        sx={{ pb: 0 }}
                                        title={
                                            <Grid
                                                container
                                                justifyContent="center"
                                            >
                                                <Typography {...subHeaderStyle}>
                                                    Invoice Summary
                                                </Typography>
                                            </Grid>
                                        }
                                        titleTypographyProps={{}}
                                    />
                                    <CardContent>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Grid
                                                container
                                                spacing={0}
                                                justifyContent={
                                                    isDesktop
                                                        ? "space-between"
                                                        : "center"
                                                }
                                            >
                                                <Grid
                                                    container
                                                    flexDirection="column"
                                                    item
                                                    xs={6}
                                                    alignItems="center"
                                                >
                                                    <Typography
                                                        align="right"
                                                        color="text.secondary"
                                                        variant={"body1"}
                                                        mt={1}
                                                    >
                                                        Invoice Amount
                                                    </Typography>
                                                    <Typography
                                                        variant={
                                                            isDesktop
                                                                ? "h1"
                                                                : "h3"
                                                        }
                                                        align="right"
                                                        color="subheader"
                                                    >
                                                        <CurrencyFigure
                                                            value={
                                                                invoice?.INVOICE_AMOUNT
                                                            }
                                                            code={
                                                                invoice?.INVOICE_CURRENCY_CODE
                                                            }
                                                        />
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    container
                                                    flexDirection="column"
                                                    item
                                                    xs={6}
                                                    alignItems="center"
                                                >
                                                    <Typography
                                                        align="right"
                                                        color="text.secondary"
                                                        variant={"body1"}
                                                        mt={1}
                                                    >
                                                        Amount Paid
                                                    </Typography>
                                                    <Typography
                                                        variant={
                                                            isDesktop
                                                                ? "h1"
                                                                : "h3"
                                                        }
                                                        align="right"
                                                    >
                                                        <CurrencyFigure
                                                            value={
                                                                invoice?.AMOUNT_PAID
                                                            }
                                                            code={
                                                                invoice?.INVOICE_CURRENCY_CODE
                                                            }
                                                        />
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    container
                                                    flexDirection="column"
                                                    item
                                                    xs={6}
                                                    alignItems="center"
                                                >
                                                    <Typography
                                                        align="right"
                                                        color="text.secondary"
                                                        variant={"body1"}
                                                        mt={isDesktop ? 3 : 1}
                                                    >
                                                        Discount Taken
                                                    </Typography>
                                                    <Typography
                                                        variant={
                                                            isDesktop
                                                                ? "h1"
                                                                : "h3"
                                                        }
                                                        align="right"
                                                    >
                                                        <CurrencyFigure
                                                            value={
                                                                invoice?.DISCOUNT_AMOUNT_TAKEN
                                                            }
                                                            code={
                                                                invoice?.INVOICE_CURRENCY_CODE
                                                            }
                                                        />
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    container
                                                    flexDirection="column"
                                                    item
                                                    xs={6}
                                                    alignItems="center"
                                                >
                                                    <Typography
                                                        align="right"
                                                        color="text.secondary"
                                                        variant={"body1"}
                                                        mt={isDesktop ? 3 : 1}
                                                    >
                                                        Tax Amount
                                                    </Typography>
                                                    <Typography
                                                        variant={
                                                            isDesktop
                                                                ? "h1"
                                                                : "h3"
                                                        }
                                                        align="right"
                                                    >
                                                        <CurrencyFigure
                                                            value={
                                                                invoice?.TOTAL_TAX_AMOUNT
                                                            }
                                                            code={
                                                                invoice?.INVOICE_CURRENCY_CODE
                                                            }
                                                        />
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </CardContent>
                                </IBuyCard>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={3}>
                            <Grid
                                item
                                xs={12}
                                sx={{ mt: 0 }}
                                order={isDesktop ? 1 : 2}
                            >
                                <IBuyCard
                                    additionalSx={{
                                        padding: 0,
                                        justifyContent: "center",
                                    }}
                                >
                                    <InvoiceSummaryTabs invoice={invoice} />
                                </IBuyCard>
                            </Grid>
                        </Grid>
                    </Grid>
                    <DisputesDialogue
                        invoiceId={invoiceId}
                        toggleDisputeSubmitKey={toggleDisputeSubmitKey}
                        isDesktop={isDesktop}
                    />
                </>
            ) : (
                <RequisitionSummarySkeleton />
            )}
        </Box>
    );
};

export default InvoiceSummaryPage;
