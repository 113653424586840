import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import OutlinedChip from "components/displays/outlinedChip";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    EDIT_NON_CAT_CART_ITEM,
    EDIT_NON_CAT_REQ_LINE,
    NEW_NON_CAT_ITEM,
    NEW_SMART_FORM_ITEM,
} from "../../../constants/generalConstants";
import { MOBILE_MEDIA_QUERY } from "../../../constants/mediaQueries";
import styledDialogueSX from "../../../styled/styledSXProperties/styledDialogueSX";
import { clearForm, closeNonCatDialogue } from "../../../utils/nonCatFormUtils";
import YesNoPrompt from "./../prompts/yesNoPrompt";
import NonCatalogueForm from "./nonCatalogueForm";
import NonCataloguePrompt from "./nonCataloguePrompt";

export const titleDispatcher = {
    [NEW_NON_CAT_ITEM]: "Add Non-Catalogue Item to Cart",
    [NEW_SMART_FORM_ITEM]: "Add Smart Form Item to Cart",
    [EDIT_NON_CAT_CART_ITEM]: "Edit Non-Catalogue Cart Item",
    [EDIT_NON_CAT_REQ_LINE]: "Edit Non-Catalogue Requisition Line Item",
};

export const subtitleDispatcher = {
    [NEW_NON_CAT_ITEM]:
        "Complete this form to add your non-catalogue item to your cart",
    [NEW_SMART_FORM_ITEM]:
        "Complete this form to add your smart form item to your cart",
    [EDIT_NON_CAT_CART_ITEM]:
        "Complete this form to edit your non-catalogue cart item",
    [EDIT_NON_CAT_REQ_LINE]:
        "Complete this form to edit your non-catalogue requisition line item",
};

const OUHelperText =
    "The active operating unit (OU) in which you are browsing. This limits the suppliers and CPAs available for selection. If you are a member of multiple OUs, you can switch by clicking your name on the search bar";

const NonCatalogueDialogue = () => {
    // Collect states and dispatchers
    const open = useSelector((state) => state.general.showNonCatalogueDialogue);
    const context = useSelector((state) => state.nonCatForm.context);
    const page = useSelector((state) => state.nonCatForm.page);
    const product = useSelector((state) => state.nonCatForm.product);
    const dispatch = useDispatch();

    // Local states
    const [closeFormPromptOpen, setCloseFormPromptOpen] = useState(false);
    const [clearFormKey, setClearFormKey] = useState(1);

    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    const onClickClose = (e, reason) => {
        if (page === 1 && reason && reason == "backdropClick") return;
        page === 0
            ? closeNonCatDialogue(dispatch)
            : setCloseFormPromptOpen(true);
    };

    // Utilize react key property to clear the form
    const resetForm = () =>
        clearForm(dispatch, clearFormKey, setClearFormKey, product.PO_LINE_ID);

    return (
        <Dialog
            maxWidth="xl"
            onClose={onClickClose}
            open={open}
            fullScreen={!isDesktop}
        >
            <DialogContent sx={styledDialogueSX}>
                <Grid container spacing={2} marginBottom="16px">
                    <Grid
                        item
                        xs={12}
                        sm={7}
                        md={8}
                        lg={9.5}
                        xl={10}
                        order={{ xs: 2, sm: 1 }}
                    >
                        <Box display="flex" flexDirection="row">
                            <Typography variant="h3" marginBottom={"4px"}>
                                {titleDispatcher[context]}
                            </Typography>
                            {page == 1 && (
                                <Tooltip
                                    title={OUHelperText}
                                    placement="top-start"
                                >
                                    <>
                                        <OutlinedChip
                                            value={`Operating Unit: ${product?.IBUY_CART_ITEM_OU}`}
                                            size="small"
                                            marginLeft
                                        />
                                    </>
                                </Tooltip>
                            )}
                        </Box>
                        <Typography
                            variant="body1"
                            fontWeight="normal"
                            color="text.secondaryLight"
                        >
                            {subtitleDispatcher[context]}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        sm={5}
                        md={4}
                        lg={2.5}
                        xl={2}
                        order={{ xs: 1, sm: 2 }}
                        justifyContent="flex-end"
                        alignContent="center"
                    >
                        {page == 1 && (
                            <Button
                                color="secondary"
                                endIcon={<RestartAltIcon />}
                                onClick={resetForm}
                                sx={{ mr: "16px" }}
                                variant="contained"
                            >
                                Reset Inputs
                            </Button>
                        )}
                        <Tooltip title="Close form">
                            <IconButton
                                onClick={onClickClose}
                                aria-label="closeNonCatModal"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                {page == 0 ? (
                    <NonCataloguePrompt />
                ) : (
                    <NonCatalogueForm key={clearFormKey} copyReq={false} />
                )}
            </DialogContent>
            <YesNoPrompt
                title="Close non-catalogue form?"
                subtitle="Closing this form prior to clicking 'Add to Cart' will result in all entered data being lost. Are you sure you wish to close this form?"
                promptOpen={closeFormPromptOpen}
                setPromptOpen={setCloseFormPromptOpen}
                onClickYes={() => closeNonCatDialogue(dispatch)}
            />
        </Dialog>
    );
};

export default NonCatalogueDialogue;
