import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOuBaseCurrency } from "utils/currencyUtils";

import {
    CORPORATE_RATE,
    ITEM_TYPES_USING_NON_CAT_FORM,
    NON_CATALOGUE_ITEM,
    NON_PROJECT_REQ,
    REQUISITION_HEDGING_THRESHOLD,
    USER_RATE,
} from "../../../../constants/generalConstants";
import { setHedgingCheckPassed } from "../../../../redux/actions/createRequisitionActions";
import useProcurement from "../../../../services/apiUtils/hooks/useProcurement";
import {
    isNeedByDateAmended,
    isQtyAmended,
} from "../../../../utils/createRequisitionUtils";
import { arrayOnCondition, dateFormat } from "../../../../utils/general";
import CurrencyFigure from "../../../displays/currencyFigure";
import DetailListVertical from "../../../displays/detailListVertical";
import { ReqLineImage } from "../../../displays/reqLineImage";
import PostRequisitionSkeleton from "../../../skeletons/postRequisitionSkeleton";
import OnDesktop from "../../../wrappers/onDesktop";
import OnMobile from "../../../wrappers/onMobile";
import HedgingWarning from "./hedgingWarning";

const gridSpacing = 3;

export const getNameFromLookup = (array, lookupKey, lookupVal, returnKey) => {
    try {
        return lookupVal
            ? array.find((item) => item[lookupKey] == lookupVal)[returnKey]
            : "Not Input";
    } catch {
        return "Not Input";
    }
};
export const ifDefined = (obj, property) =>
    obj[property] ? obj[property] : "Not Input";

const ReqLineAccountingSection = ({ headerForm, lookups, reqLine }) => {
    return (
        <>
            <Typography variant="h6" fontWeight="bold" color="text.subheader">
                Accounting
            </Typography>
            {headerForm.ACCOUNTED_AGAINST === NON_PROJECT_REQ ? (
                <DetailListVertical
                    condensed={true}
                    details={[
                        {
                            header: "BSV Company Code",
                            content: reqLine.SEGMENT1_NAME,
                            hasError: !reqLine.SEGMENT1_NAME,
                        },
                        {
                            header: "Cost Centre",
                            content: reqLine.SEGMENT2_NAME,
                            hasError: !reqLine.SEGMENT2_NAME,
                        },
                        {
                            header: "Account",
                            content: reqLine.SEGMENT3_NAME,
                            hasError: !reqLine.SEGMENT3_NAME,
                        },
                    ]}
                />
            ) : (
                <DetailListVertical
                    condensed={true}
                    details={[
                        {
                            header: "Project Task",
                            content:
                                headerForm.ACCOUNTED_AGAINST == NON_PROJECT_REQ
                                    ? "Not Required"
                                    : getNameFromLookup(
                                          lookups.tasks,
                                          "TASK_ID",
                                          reqLine.TASK_ID,
                                          "TASK_UNIQUE_NAME"
                                      ),
                            hasError:
                                headerForm.ACCOUNTED_AGAINST == NON_PROJECT_REQ
                                    ? false
                                    : !reqLine.TASK_ID,
                        },
                        {
                            header: "Expenditure Type",
                            content:
                                headerForm.ACCOUNTED_AGAINST == NON_PROJECT_REQ
                                    ? "Not Required"
                                    : ifDefined(reqLine, "EXPENDITURE_TYPE"),
                            hasError:
                                headerForm.ACCOUNTED_AGAINST == NON_PROJECT_REQ
                                    ? false
                                    : !reqLine.EXPENDITURE_TYPE,
                        },
                    ]}
                />
            )}
        </>
    );
};

ReqLineAccountingSection.propTypes = {
    headerForm: PropTypes.any,
    lookups: PropTypes.any,
    reqLine: PropTypes.object,
};

const ReqLineDeliverySection = ({ reqLine }) => {
    return (
        <>
            <Typography variant="h6" fontWeight="bold" color="text.subheader">
                Delivery
            </Typography>
            <DetailListVertical
                condensed={true}
                details={[
                    {
                        header: "Delivery Location",
                        content: reqLine?.LOCATION_CODE || "Not Input",
                        hasError: !reqLine.DELIVER_TO_LOCATION_ID,
                    },
                    {
                        header: "Note to Receiver",
                        content: ifDefined(reqLine, "NOTE_TO_RECEIVER"),
                        hasError: !reqLine.NOTE_TO_RECEIVER,
                    },
                ]}
            />
        </>
    );
};

ReqLineDeliverySection.propTypes = {
    reqLine: PropTypes.object,
};

export const ReqSummaryAccordion = ({ reqLines, isReqCurrencyNotOuBase }) => {
    const lookups = useSelector((state) => state.formLookups);
    const headerForm = useSelector(
        (state) => state.createRequisition.iBuyRequisition.requisitionHeaderForm
    );
    const isPostPoReq = useSelector(
        (state) => state.createRequisition.isPostPoReq
    );
    const postPOMetadata = useSelector(
        (state) => state.createRequisition.postPOMetadata
    );

    // TODO - DRY
    const poUnderEdit = useSelector((state) => {
        if (!isPostPoReq) {
            return null;
        }
        const postPOMetadata = state.createRequisition.postPOMetadata;
        const reqIdEdited = Object.keys(postPOMetadata).find(
            (reqLineId) => postPOMetadata[reqLineId]?.isEdited == true
        );
        return postPOMetadata[reqIdEdited]?.poNumber;
    });

    return (
        <>
            {reqLines
                .filter((reqLine) => {
                    return reqLine?.CHANGE_ACTION !== "DELETED";
                })
                .map((reqLine, index) => (
                    <Accordion
                        key={index}
                        sx={{ backgroundColor: "background.accordion" }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="req-line-item-accordion-controls"
                            id="req-line-item-accordion"
                            sx={{
                                "&.Mui-focusVisible": {
                                    backgroundColor: "#2E3037B3",
                                    backgroundImage: "#2E3037B3",
                                },
                                "&.MuiAccordion-root:before": {
                                    backgroundColor: "#00000000",
                                },
                            }}
                        >
                            <Box
                                sx={(theme) => {
                                    return {
                                        display: "grid",
                                        gap: theme.spacing(2),
                                        gridTemplateAreas:
                                            "'image description' 'details details' 'price price'",
                                        gridTemplateColumns: "1fr 2fr",
                                        [theme.breakpoints.up("md")]: {
                                            gridTemplateAreas:
                                                "'image description description description description' 'image details price accounting delivery'",
                                            gridTemplateColumns:
                                                "1fr 2fr 2fr 4fr 4fr",
                                            gridTemplateRows: "auto 1fr",
                                        },
                                    };
                                }}
                            >
                                <Box gridArea={"image"}>
                                    <ReqLineImage
                                        ITEM_IMAGE_URL={reqLine.ITEM_IMAGE_URL}
                                    />
                                </Box>
                                <Box gridArea={"description"}>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        color="text.subheader"
                                    >
                                        {reqLine.ITEM_DESCRIPTION}
                                    </Typography>
                                </Box>
                                <Box gridArea={"details"}>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        color="text.subheader"
                                    >
                                        Details
                                    </Typography>
                                    <DetailListVertical
                                        condensed={true}
                                        details={[
                                            {
                                                header: "Category",
                                                content: ifDefined(
                                                    reqLine,
                                                    "CATEGORY"
                                                ),
                                                hasError: !reqLine.CATEGORY,
                                            },
                                            {
                                                header: "Currency Code",
                                                content: ifDefined(
                                                    reqLine,
                                                    "CURRENCY_CODE"
                                                ),
                                                hasError:
                                                    !reqLine.CURRENCY_CODE,
                                            },
                                            ...arrayOnCondition(
                                                isReqCurrencyNotOuBase,
                                                {
                                                    header: "Exchange Rate",
                                                    content:
                                                        reqLine.itemType ===
                                                        NON_CATALOGUE_ITEM
                                                            ? reqLine?.RATE_TYPE
                                                            : CORPORATE_RATE,
                                                }
                                            ),
                                        ]}
                                    />
                                </Box>
                                <Box gridArea={"price"}>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        color="text.subheader"
                                    >
                                        Price
                                    </Typography>
                                    <DetailListVertical
                                        condensed={true}
                                        details={[
                                            {
                                                header: "Unit Price",
                                                content: (
                                                    <CurrencyFigure
                                                        value={
                                                            reqLine.UNIT_PRICE
                                                        }
                                                        code={
                                                            reqLine?.CURRENCY_CODE
                                                        }
                                                    />
                                                ),
                                                hasError: !reqLine.UNIT_PRICE,
                                            },
                                            {
                                                header: "Quantity",
                                                content: ifDefined(
                                                    reqLine,
                                                    "QUANTITY"
                                                ),
                                                hasError: !reqLine.QUANTITY,
                                                subContent: isQtyAmended(
                                                    reqLine.LINE_ATTRIBUTE5,
                                                    reqLine,
                                                    poUnderEdit,
                                                    isPostPoReq,
                                                    postPOMetadata
                                                ) && (
                                                    <Typography
                                                        color="#ed6c02"
                                                        variant="body2"
                                                        marginTop="2px"
                                                    >
                                                        {`Was: ${
                                                            postPOMetadata[
                                                                reqLine
                                                                    .LINE_ATTRIBUTE5
                                                            ]?.originalQty
                                                        }`}
                                                    </Typography>
                                                ),
                                            },
                                            {
                                                header: "Unit Of Measure",
                                                content: ifDefined(
                                                    reqLine,
                                                    "UNIT_OF_MEASURE"
                                                ),
                                                hasError:
                                                    !reqLine.UNIT_OF_MEASURE,
                                            },
                                        ]}
                                    />
                                </Box>
                                <OnDesktop>
                                    <Box gridArea={"accounting"}>
                                        <ReqLineAccountingSection
                                            headerForm={headerForm}
                                            lookups={lookups}
                                            reqLine={reqLine}
                                        />
                                    </Box>
                                    <Box gridArea={"delivery"}>
                                        <ReqLineDeliverySection
                                            reqLine={reqLine}
                                        />
                                    </Box>
                                </OnDesktop>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12} md={4}>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        color="text.subheader"
                                    >
                                        General
                                    </Typography>
                                    <DetailListVertical
                                        condensed={true}
                                        details={[
                                            {
                                                header: "Need By Date",
                                                content: reqLine.NEED_BY_DATE
                                                    ? dateFormat(
                                                          reqLine.NEED_BY_DATE
                                                      )
                                                    : "Not Input",

                                                hasError: !reqLine.NEED_BY_DATE,
                                                subContent: isNeedByDateAmended(
                                                    reqLine.LINE_ATTRIBUTE5,
                                                    reqLine,
                                                    poUnderEdit,
                                                    isPostPoReq,
                                                    postPOMetadata
                                                ) && (
                                                    <Typography
                                                        color="#ed6c02"
                                                        variant="body2"
                                                    >
                                                        {`Was: ${
                                                            postPOMetadata[
                                                                reqLine
                                                                    .LINE_ATTRIBUTE5
                                                            ]
                                                                ?.originalNeedByDate
                                                                ? dateFormat(
                                                                      postPOMetadata[
                                                                          reqLine
                                                                              .LINE_ATTRIBUTE5
                                                                      ]
                                                                          ?.originalNeedByDate
                                                                  )
                                                                : "N/A"
                                                        }`}
                                                    </Typography>
                                                ),
                                            },
                                            {
                                                header: "Note to Approver",
                                                content: ifDefined(
                                                    reqLine,
                                                    "NOTE_TO_APPROVER"
                                                ),
                                                hasError:
                                                    !reqLine.NOTE_TO_APPROVER,
                                            },
                                        ]}
                                    />
                                </Grid>

                                <OnMobile>
                                    <Grid item xs={12}>
                                        <ReqLineDeliverySection
                                            reqLine={reqLine}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ReqLineAccountingSection
                                            headerForm={headerForm}
                                            lookups={lookups}
                                            reqLine={reqLine}
                                        />
                                    </Grid>
                                </OnMobile>

                                <Grid item xs={12} md={4}>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        color="text.subheader"
                                    >
                                        Other Accounting
                                    </Typography>
                                    <DetailListVertical
                                        condensed={true}
                                        details={[
                                            {
                                                header: "Expenditure Organisation",
                                                content:
                                                    headerForm.ACCOUNTED_AGAINST ===
                                                    NON_PROJECT_REQ
                                                        ? "Not Required"
                                                        : reqLine?.EXPENDITURE_ORGANIZATION_NAME ||
                                                          "Not Input",
                                                hasError:
                                                    headerForm.ACCOUNTED_AGAINST ===
                                                    NON_PROJECT_REQ
                                                        ? false
                                                        : !reqLine.EXPENDITURE_ORGANIZATION_NAME,
                                            },
                                            {
                                                header: "Expenditure Item Date",
                                                content:
                                                    headerForm.ACCOUNTED_AGAINST ===
                                                    NON_PROJECT_REQ
                                                        ? "Not Required"
                                                        : reqLine.EXPENDITURE_ITEM_DATE
                                                        ? dateFormat(
                                                              reqLine.EXPENDITURE_ITEM_DATE
                                                          )
                                                        : "Not Input",
                                                hasError:
                                                    headerForm.ACCOUNTED_AGAINST ===
                                                    NON_PROJECT_REQ
                                                        ? false
                                                        : !reqLine.EXPENDITURE_ITEM_DATE,
                                            },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        color="text.subheader"
                                    >
                                        Other
                                    </Typography>
                                    <DetailListVertical
                                        condensed={true}
                                        details={[
                                            {
                                                header: "Note to Buyer",
                                                content: ifDefined(
                                                    reqLine,
                                                    "NOTE_TO_BUYER"
                                                ),
                                                hasError:
                                                    !reqLine.NOTE_TO_BUYER,
                                            },
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                ))}
        </>
    );
};

const Review = ({ reqHeader, reqLines }) => {
    // Hooks
    const dispatch = useDispatch();
    const { getExchangeRate } = useProcurement();

    // Local states
    const [isShowingHedgingWarning, setIsShowingHedgingWarning] =
        useState(false);

    // Collect states and state dispatchers
    const submitLoading = useSelector(
        (state) => state.createRequisition.submitLoading
    );
    const baseCurrencies = useSelector(
        (state) => state.filterPanelLookups.BASE_CURRENCIES
    );
    const isPostPoReq = useSelector(
        (state) => state.createRequisition.isPostPoReq
    );

    const reqCurrency = reqLines[0]?.CURRENCY_CODE;
    const reqOuBaseCurrency = getOuBaseCurrency(
        reqHeader?.ORG_NAME,
        baseCurrencies
    );
    const isReqCurrencyNotOuBase = reqCurrency !== reqOuBaseCurrency;

    //region Hedging warning
    const foreignNonCatLines = reqLines.filter(
        (reqLine) =>
            ITEM_TYPES_USING_NON_CAT_FORM.includes(reqLine.itemType) &&
            !reqLine.CPA_NUMBER &&
            isReqCurrencyNotOuBase
    );

    /**
     * @returns {Promise<{shouldShowHedgingWarning: boolean, hedgingCheckPassed: boolean}>}
     */
    const getHedgingProperties = async () => {
        if (foreignNonCatLines.length === 0 || isPostPoReq)
            return {
                shouldShowHedgingWarning: false,
                hedgingCheckPassed: true,
            };

        const exchangeRate = await getExchangeRate(
            reqCurrency,
            "GBP", // We don't care about the operating unit - all hedging checks are done against GBP
            new Date()
        );

        if (!exchangeRate)
            return {
                shouldShowHedgingWarning: false,
                hedgingCheckPassed: false,
            };

        const foreignNonCatLinesCost = foreignNonCatLines.reduce(
            (acc, reqLine) => acc + reqLine.UNIT_PRICE * reqLine.QUANTITY,
            0
        );

        const shouldShowHedgingWarning =
            foreignNonCatLinesCost * exchangeRate >
            REQUISITION_HEDGING_THRESHOLD;

        return {
            shouldShowHedgingWarning: shouldShowHedgingWarning,
            hedgingCheckPassed: !shouldShowHedgingWarning,
        };
    };

    useEffect(() => {
        (async () => {
            const hedgingProperties = await getHedgingProperties();

            setIsShowingHedgingWarning(
                hedgingProperties.shouldShowHedgingWarning
            );
            dispatch(
                setHedgingCheckPassed(hedgingProperties.hedgingCheckPassed)
            );
        })();
    }, []);
    //endregion

    return (
        <>
            {submitLoading ? (
                <PostRequisitionSkeleton numLines={reqLines.length} />
            ) : (
                <Grid
                    container
                    spacing={3}
                    alignContent="flex-start"
                    alignItems="start"
                >
                    <Grid item xs={12} md={4}>
                        <Typography
                            variant="h5"
                            color="text.subheader"
                            fontWeight="bold"
                        >
                            Header Information
                        </Typography>
                        <DetailListVertical
                            condensed={true}
                            details={[
                                {
                                    header: "Project",
                                    content: (() => {
                                        if (
                                            reqHeader.ACCOUNTED_AGAINST ===
                                            NON_PROJECT_REQ
                                        ) {
                                            return "Not required";
                                        }

                                        if (
                                            reqHeader.SEGMENT4_ID &&
                                            reqHeader.SEGMENT4_NAME
                                        ) {
                                            return `${reqHeader.SEGMENT4_ID} - ${reqHeader.SEGMENT4_NAME}`;
                                        }

                                        return "Not Input";
                                    })(),
                                    hasError:
                                        reqHeader.ACCOUNTED_AGAINST ===
                                        NON_PROJECT_REQ
                                            ? false
                                            : !reqHeader.PROJECT_ID,
                                },
                                {
                                    header: "Need By Date",
                                    content: reqHeader.NEED_BY_DATE
                                        ? dateFormat(reqHeader.NEED_BY_DATE)
                                        : "Not Input",
                                    hasError: !reqHeader.NEED_BY_DATE,
                                },
                                {
                                    header: "Operating Unit",
                                    content: ifDefined(reqHeader, "ORG_NAME"),
                                    hasError: !reqHeader.ORG_NAME,
                                },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography
                            variant="h5"
                            color="text.subheader"
                            fontWeight="bold"
                        >
                            Summary Notes
                        </Typography>
                        <DetailListVertical
                            condensed={true}
                            details={[
                                {
                                    header: "Description",
                                    content: ifDefined(
                                        reqHeader,
                                        "HEADER_DESCRIPTION"
                                    ),
                                    hasError: !reqHeader.HEADER_DESCRIPTION,
                                },
                                {
                                    header: "Justification",
                                    content: ifDefined(
                                        reqHeader,
                                        "JUSTIFICATION"
                                    ),
                                    hasError: !reqHeader.JUSTIFICATION,
                                },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography
                            variant="h5"
                            color="text.subheader"
                            fontWeight="bold"
                        >
                            Notes to Key Stakeholders
                        </Typography>
                        <DetailListVertical
                            condensed={true}
                            details={[
                                {
                                    header: "Note to Buyer",
                                    content: ifDefined(
                                        reqHeader,
                                        "NOTE_TO_BUYER"
                                    ),
                                    hasError: !reqHeader.NOTE_TO_BUYER,
                                },
                                {
                                    header: "Note to Receiver",
                                    content: ifDefined(
                                        reqHeader,
                                        "NOTE_TO_RECEIVER"
                                    ),
                                    hasError: !reqHeader.NOTE_TO_RECEIVER,
                                },
                                {
                                    header: "Note to Approver",
                                    content: ifDefined(
                                        reqHeader,
                                        "NOTE_TO_APPROVER"
                                    ),
                                    hasError: !reqHeader.NOTE_TO_APPROVER,
                                },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ReqSummaryAccordion
                            reqLines={reqLines}
                            isReqCurrencyNotOuBase={isReqCurrencyNotOuBase}
                        />
                    </Grid>
                    {isShowingHedgingWarning && (
                        <Grid item xs={12}>
                            <HedgingWarning
                                shouldShowCheckbox={foreignNonCatLines.every(
                                    (nonCatLine) =>
                                        nonCatLine.RATE_TYPE === USER_RATE
                                )}
                            />
                        </Grid>
                    )}
                </Grid>
            )}
        </>
    );
};

export default Review;
