import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import useIsMount from "hooks/useIsMount";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AUTOCOMPLETE_API_MIN_CHARS } from "../../../constants/generalConstants";
import useThrottle from "../../../hooks/useThrottle";
import { deliveryLocAutocompleteGet } from "../../../services/requisition/requisitionAPIs";
import { freeTextFormProps } from "../../../styled/props/textFieldProps";
import {
    DELIVERY_LOC_INPUT_LABEL,
    DELIVERY_LOC_INPUT_PLACEHOLDER,
    MIN_3_CHARACTERS_TEXT,
    NO_OPTIONS_TEXT,
    START_NEW_SEARCH_TEXT,
} from "./labels";

const DeliveryLocSearchAsYouType = ({
    selectedDeliveryLoc,
    onSelectDeliveryLoc,
}) => {
    // Collect states and state dispatchers
    const isPostPoReq = useSelector(
        (state) => state.createRequisition.isPostPoReq
    );
    const dispatch = useDispatch();

    // Local states
    const [noOptionsText, setNoOptionsText] = useState(
        selectedDeliveryLoc?.LOCATION_CODE
            ? START_NEW_SEARCH_TEXT
            : MIN_3_CHARACTERS_TEXT
    );
    const [deliveryLocLoading, setDeliveryLocLoading] = useState(false);
    const [deliveryLocOptions, setDeliveryLocOptions] = useState([]);
    const [deliveryLocSearchTerm, setDeliveryLocSearchTerm] = useState(
        selectedDeliveryLoc?.LOCATION_CODE || ""
    );

    // Instantiate hooks required for search as you type
    const throttleFunction = useThrottle();
    const isFirstRender = useIsMount();

    useEffect(() => {
        // If no input, provided, or less than 3 characters input, API not called
        if (!isFirstRender) {
            throttleFunction(() => {
                if (
                    deliveryLocSearchTerm === "" ||
                    deliveryLocSearchTerm.length < AUTOCOMPLETE_API_MIN_CHARS
                ) {
                    setDeliveryLocOptions([]);
                    setNoOptionsText(MIN_3_CHARACTERS_TEXT);
                    return;
                }

                // Else, call get delivery location API
                selectedDeliveryLoc
                    ? setNoOptionsText(START_NEW_SEARCH_TEXT)
                    : setNoOptionsText(NO_OPTIONS_TEXT);

                deliveryLocAutocompleteGet(
                    dispatch,
                    deliveryLocSearchTerm,
                    setDeliveryLocOptions,
                    selectedDeliveryLoc,
                    setDeliveryLocLoading
                );
            });
        }
    }, [deliveryLocSearchTerm]);

    // Helper functions for the MUI autocomplete
    const onTypeSearchTerm = (_event, newInputValue) =>
        setDeliveryLocSearchTerm(newInputValue);

    const getOptionLabel = (option) => option.LOCATION_CODE;

    const onClickDeliveryLoc = (e, value) => {
        /* Called when a delivery location is selected by a user, writing the selection
        to the create requisition application state.

        Parameters
        ----------
        value : obj
            The obj returned from the `onChange` method on the
            autocomplete {"LOCATION_ID": 1001, "LOCATION_CODE": "Location Code"}
         */
        onSelectDeliveryLoc(value);
    };
    const checkIfSelectedValueIsAnOption = (option, value) => {
        /* Called as part of autocomplete `isOptionEqualToValue` prop.

        Parameters
        ----------
        option : obj
            The structure of the autocomplete options.
        value : obj
            The obj returned from the `onChange` method on the
            autocomplete 

        Notes
        ----
        Both `option` and `value` are structured the same:
            {"LOCATION_ID": 1001, "LOCATION_CODE": "Location Code"}
         */
        return option.id == value.id;
    };
    return (
        <Autocomplete
            autoComplete
            disabled={isPostPoReq}
            loading={deliveryLocLoading}
            filterOptions={(x) => x}
            filterSelectedOptions
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={checkIfSelectedValueIsAnOption}
            noOptionsText={noOptionsText}
            options={deliveryLocOptions}
            onChange={onClickDeliveryLoc}
            onInputChange={onTypeSearchTerm}
            value={selectedDeliveryLoc}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...freeTextFormProps}
                    label={DELIVERY_LOC_INPUT_LABEL}
                    placeholder={DELIVERY_LOC_INPUT_PLACEHOLDER}
                />
            )}
        />
    );
};

DeliveryLocSearchAsYouType.propTypes = {
    onSelectDeliveryLoc: PropTypes.func,
    selectedDeliveryLoc: PropTypes.shape({
        LOCATION_ID: PropTypes.number,
        LOCATION_CODE: PropTypes.string,
    }),
};

export default DeliveryLocSearchAsYouType;
