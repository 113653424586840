import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setHeaderField } from "../../../../../../redux/actions/createRequisitionActions";
import { getTaskAndChargeAccountsByProject } from "../../../../../../services/requisition/requisitionAPIs";
import { freeTextFormProps } from "../../../../../../styled/props/textFieldProps";
import { updateDate } from "../../../../../../utils/createRequisitionUtils";
import InfoLine from "../../../../../displays/infoLine";
import ExpOrgSearchAsYouType from "../../../../../inputs/searchAsYouType/expOrgSearchAsYouType";
import ProjectSearchAsYouType from "../../../../../inputs/searchAsYouType/projectSearchAsYouType";
import ValidatedAutocomplete from "../../../../../inputs/validatedAutoComplete";
import ValidateChargeAccountButtonContainer from "../buttons/validateChargeAccountButtonContainer";
import CompanyCodeHeaderField from "../fields/companyCodeHeaderField";
import CostCentreHeaderField from "../fields/costCentreHeaderField";

const SSE_INVENTORY_CLEARING_ACCOUNT = {
    id: "8901504",
    name: "SSE Inventory Clearing Account",
};

const AccountingProjectInputs = ({
    setTasksDisabled,
    tasksDisabled,
    updateHeader,
}) => {
    // Collect states and state dispatchers
    const headerForm = useSelector(
        (state) => state.createRequisition.iBuyRequisition.requisitionHeaderForm
    );
    const lookups = useSelector((state) => state.formLookups);
    const isPostPoReq = useSelector(
        (state) => state.createRequisition.isPostPoReq
    );
    const isInternalReq = useSelector(
        (state) => state.createRequisition.isInternalReq
    );
    const dispatch = useDispatch();

    // On component load, modify states of co-dependant dropdown lists
    useEffect(() => {
        // If ORG_NAME and PROJECT_ID defined, enable charge account and tasks dropdowns
        if (headerForm.PROJECT_ID && headerForm.ORG_NAME) {
            setTasksDisabled(false);
        }
    }, []);

    /**
     * Called when a project is selected by a user, writing the selection
     * to the create requisition application state.
     * @param {ProjectResponse | null} value
     */
    const onSelectProject = (value) => {
        // Parse output values from `value`
        const projId = value?.id || null;
        const projNum = value?.PROJECT_NUMBER || null;
        const projNumName = value?.PROJECT_NAME_NUM || null;

        // Set requisition state
        updateHeader(projId, "PROJECT_ID");
        updateHeader(projNum, "SEGMENT4_ID");
        updateHeader(projNumName, "SEGMENT4_NAME");

        // For internal requisitions, any project change
        // should prompt user to revalidate
        isInternalReq && updateHeader(false, "IS_VALIDATED");
    };

    // Automatically set segment 3 for internal reqs
    useEffect(() => {
        if (isInternalReq) {
            updateHeader(SSE_INVENTORY_CLEARING_ACCOUNT.id, "SEGMENT3_ID");
            updateHeader(SSE_INVENTORY_CLEARING_ACCOUNT.name, "SEGMENT3_NAME");
        } else {
            updateHeader(null, "SEGMENT3_ID");
            updateHeader(null, "SEGMENT3_NAME");
        }
    }, []);

    useEffect(() => {
        if (headerForm.PROJECT_ID) {
            // Collect data
            getTaskAndChargeAccountsByProject(
                headerForm.PROJECT_ID,
                dispatch
            ).then(() => {
                // Enable task dropdown
                setTasksDisabled(false);
            });
        } else {
            // Reset value of task dropdown
            dispatch(setHeaderField("TASK_ID", null));
            // Disable task dropdown
            setTasksDisabled(true);
        }
    }, [headerForm.PROJECT_ID]);

    const selectedProj =
        headerForm.SEGMENT4_ID && headerForm.SEGMENT4_NAME
            ? {
                  PROJECT_NUMBER: headerForm.SEGMENT4_ID,
                  PROJECT_NAME_NUM: headerForm.SEGMENT4_NAME,
              }
            : null;

    const onSelectExpOrg = (value) => {
        /* Called when an expenditure organisation is selected/cleared by user.
        
        Parameters
        ----------
        value : obj
            The obj returned from the `onChange` method on the autocomplete
            {
                "EXPENDITURE_ORGANIZATION_ID": 123,
                "EXPENDITURE_ORGANIZATION_NAME": "Name"
            }
            */
        const expOrgName = value?.EXPENDITURE_ORGANIZATION_NAME || null;
        const expOrgId = value?.EXPENDITURE_ORGANIZATION_ID || null;
        // Update form lookups
        updateHeader(expOrgId, "EXPENDITURE_ORGANIZATION_ID");
        updateHeader(expOrgName, "EXPENDITURE_ORGANIZATION_NAME");
    };

    const selectedExpOrg = () =>
        headerForm?.EXPENDITURE_ORGANIZATION_ID &&
        headerForm?.EXPENDITURE_ORGANIZATION_NAME
            ? {
                  EXPENDITURE_ORGANIZATION_ID:
                      headerForm.EXPENDITURE_ORGANIZATION_ID,
                  EXPENDITURE_ORGANIZATION_NAME:
                      headerForm?.EXPENDITURE_ORGANIZATION_NAME,
              }
            : null;

    return (
        <>
            <Grid item xs={12}>
                <ProjectSearchAsYouType
                    disabled={isPostPoReq}
                    selectedValue={selectedProj}
                    setSelectedValue={onSelectProject}
                />
            </Grid>
            <Grid item xs={12}>
                <ValidatedAutocomplete
                    disabled={tasksDisabled || isPostPoReq}
                    label={`Project Task${
                        !headerForm.PROJECT_ID
                            ? " - select a project to activate"
                            : ""
                    }`}
                    placeholder="Select Project Task..."
                    onChange={(e, value) => {
                        updateHeader(value.TASK_ID, "TASK_ID");
                    }}
                    options={lookups.tasks}
                    value={headerForm.TASK_ID}
                    IDName={{
                        id: "TASK_ID",
                        name: "TASK_UNIQUE_NAME",
                    }}
                    inputValue={headerForm.TASK_ID}
                />
            </Grid>
            <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label="Expenditure Item Date"
                        inputFormat="dd/MM/yyyy"
                        onChange={(date) =>
                            updateHeader(
                                updateDate(date),
                                "EXPENDITURE_ITEM_DATE"
                            )
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                {...freeTextFormProps}
                                fullWidth={false}
                            />
                        )}
                        value={headerForm.EXPENDITURE_ITEM_DATE}
                        disabled={isPostPoReq}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={8}>
                <ValidatedAutocomplete
                    label="Expenditure Type"
                    placeholder="Select Expenditure Type..."
                    onChange={(e, value) =>
                        updateHeader(value, "EXPENDITURE_TYPE")
                    }
                    options={lookups.expenditureTypes}
                    value={headerForm.EXPENDITURE_TYPE}
                    disabled={isPostPoReq}
                />
            </Grid>
            <Grid item xs={12}>
                <ExpOrgSearchAsYouType
                    onSelectExpOrg={onSelectExpOrg}
                    selectedExpOrg={selectedExpOrg()}
                />
            </Grid>
            {isInternalReq ? (
                <>
                    <Grid item xs={12}>
                        <CompanyCodeHeaderField
                            disabled={isPostPoReq}
                            updateHeader={updateHeader}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CostCentreHeaderField
                            disabled={isPostPoReq}
                            updateHeader={updateHeader}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ValidateChargeAccountButtonContainer
                            isValidated={headerForm.IS_VALIDATED}
                            isDisabled={
                                !headerForm.SEGMENT1_ID ||
                                !headerForm.SEGMENT2_ID ||
                                !headerForm.SEGMENT4_ID
                            }
                            segmentIds={{
                                SEGMENT1_ID: headerForm.SEGMENT1_ID,
                                SEGMENT2_ID: headerForm.SEGMENT2_ID,
                                SEGMENT3_ID: headerForm.SEGMENT3_ID,
                                SEGMENT4_ID: headerForm.SEGMENT4_ID,
                            }}
                            operatingUnit={headerForm.ORG_NAME}
                            updateHeader={updateHeader}
                            nonProjectMode={false}
                        />
                    </Grid>
                </>
            ) : (
                <Grid item xs={12}>
                    <InfoLine>
                        A charge account will be generated automatically for
                        this requisition. This is because you have selected to
                        raise it against a project and because it contains
                        externally sourced items.
                    </InfoLine>
                </Grid>
            )}
        </>
    );
};

AccountingProjectInputs.propTypes = {
    setTasksDisabled: PropTypes.func,
    tasksDisabled: PropTypes.bool,
    updateHeader: PropTypes.func,
};

export default AccountingProjectInputs;
