// Shopping cart constants
export const DEFAULT_CART_ID = "default";
export const DEFAULT_CART_NAME = "My Cart";
export const FAVOURITES_CART = "favourites";
export const FAVOURITES_CART_NAME = "favourites";
export const DISALLOWED_CART_NAMES = [DEFAULT_CART_NAME, FAVOURITES_CART_NAME];
export const INVALID_CART_NAME_MSG = `The following cart names are invalid: "${DEFAULT_CART_NAME}", "${FAVOURITES_CART_NAME}" and any pre-existing cart name`;

// Product types
export const STOCK = "STOCK";
export const DIRECT = "DIRECT";
export const SERVICE = "SERVICE";

// Homepage carousel constants
export const HOMEPAGE_CARD = "HOMEPAGE_CARD";
export const PRODUCT_CARD = "PRODUCT_CARD";

// IBUY Azure AD roles
export const PROC_MANAGER = "ProcurementManager";
export const REQUESTER = "Requester";

// Product List
export const ITEMS_PER_PAGE = 20;

// Create named cart modes
export const BLANK_CART = "BLANK_CART";
export const FROM_DEFAULT = "FROM_DEFAULT";
// Post requisition status
export const SAVE = "SAVE";
export const SUBMIT = "SUBMIT";

// Requisition order by defaults
export const DEFAULT_ORDER_KEY = "Sort By: Date Raised - Latest to Earliest";
export const DEFAULT_ORDER_VALUE = "submittedDate DESC"; // Exported to routes

// Requisition filter panel settings
export const MIN_PRICE = 0;
export const MAX_PRICE = 100000;

// Product results filter panel settings
export const MAX_UNIT_PRICE = 5000;
export const MIN_UNIT_PRICE = 0;
export const MAX_LEAD_TIME = 50;

// Show cancelled filter panel param
export const SHOW_CANCELLED_PARAM = "ALL";

// Approval Delegations
export const MAX_DELEGATIONS = 10;

// Non catalogue form item TYPES
export const TYPE_OPTIONS = [
    "Goods. I can provide description, item price and quantity",
    "Services. I can provide description, rate and quantity",
    "Goods or Services. I can provide description and Total Amount",
];

// Non catalogue form use modes
export const NEW_NON_CAT_ITEM = "NEW_NON_CAT_ITEM";
export const NEW_SMART_FORM_ITEM = "NEW_SMART_FORM_ITEM";
export const EDIT_NON_CAT_CART_ITEM = "EDIT_NON_CAT_CART_ITEM";
export const EDIT_NON_CAT_REQ_LINE = "EDIT_NON_CAT_REQ_LINE";

export const BY_AMOUNT_UOM_DESC_AND_CODE = "N/A";

// Current rate types
export const USER_RATE = "User";
export const CORPORATE_RATE = "Corporate";

// Requisition accounting modes
export const PROJECT_REQ = "PROJECT_REQ";
export const NON_PROJECT_REQ = "NON_PROJECT_REQ";

// Attachment configuration
export const ATTACHMENT_CONSTANTS = {
    maxLength: 30,
    minSize: 1 * 1024, // 1KB
    maxSize: 35 * 1000 * 1024, // 35MB
    filenameRegex: /^[\w,\s-]{1,50}\.[A-Za-z]{1,5}$/,
    maxAttachments: 3,
};

// Item types
export const CATALOGUE_ITEM = "CATALOGUE";
export const NON_CATALOGUE_ITEM = "NON_CATALOGUE";
export const SMART_FORM = "SMART_FORM";
export const ITEM_TYPES_USING_NON_CAT_FORM = [NON_CATALOGUE_ITEM, SMART_FORM];

// Receipt statuses
export const FULLY_RECEIPTED = "FULLY RECEIPTED";
export const RECEIPTING_NOT_REQUIRED = "NOT REQUIRED";
export const ATTENTION_REQUIRED = "ATTENTION REQUIRED";
export const NOT_RECEIPTED = "NOT RECEIPTED";
export const PARTIALLY_RECEIPTED = "PARTIALLY RECEIPTED";
export const RECEIPTING_IN_FLIGHT = "IN FLIGHT";
export const PO_NOT_AVAILABLE = "PO NOT AVAILABLE";

// Requisition statuses
export const APPROVED = "APPROVED";
export const AWAITING_APPROVAL = "AWAITING APPROVAL";
export const CANCELLED = "CANCELLED";
export const CANCELLATION_SUBMITTED = "CANCELLATION SUBMITTED";
export const CHANGING_OWNER = "CHANGING OWNER";
export const CREATED = "CREATED";
export const INCOMPLETE = "INCOMPLETE";
export const INVALID_REQUISITION = "INVALID REQUISITION";
export const IN_PROCESS = "IN PROCESS";
export const PRE_APPROVED = "PRE-APPROVED";
export const PO_APPROVAL_PENDING = "PO APPROVAL PENDING";
export const PENDING = "PENDING";
export const SUBMITTED = "SUBMITTED";
export const AMENDMENT_SUBMITTED = "AMENDMENT SUBMITTED";
export const FAILED = "FAILED";
export const RETURNED = "RETURNED";
export const REJECTED = "REJECTED";
export const UNKNOWN_STATUS = "UNKNOWN";

// Requisition summary component modes
export const REQ_SUMMARY_TABLE_RECEIPT = "REQ_SUMMARY_TABLE_RECEIPT";
export const REQ_SUMMARY_TABLE = "REQ_SUMMARY_TABLE";

// Change of preparer
export const VALID_OWNER_CHANGE_STATUSES = [
    APPROVED,
    CREATED,
    INCOMPLETE,
    IN_PROCESS,
    PRE_APPROVED,
    RETURNED,
    REJECTED,
];

// Receipting constants
export const CREATE_RECEIPT = "CREATE RECEIPT";
export const CORRECT_RECEIPT = "CORRECT RECEIPT";
export const RETURN_RECEIPT = "RETURN_RECEIPT";
export const RETURN_TO_VENDOR = "RETURN TO VENDOR";

// Status codes
export const INVALID_REQ_LINE_STATUS_CODE = 400;
export const FAILED_REQ_LINE_STATUS_CODE = 500;

// Requisition sourcing types
export const INTERNAL_REQUISITION = "Internal";
export const PURCHASE_REQUISITION = "Purchase";

// Misc
export const SUPPLIER = "SUPPLIER";
export const SMARTFORMS_MODE = "Y";
export const LOCAL_BUYER_CPA_TRUE = "Y";
export const CPA = "CPA";
export const API_AUTOCOMPLETE_COOLDOWN_MS = 500;
export const SEARCH_API_DELIMITER = "|";
export const REQUISITION_SUBMIT_CONFIRM_MESSAGE =
    "If you continue, the requisition will be removed from the approval or order creation process so that you can make changes. Once you complete the checkout for the requisition, it is resubmitted for approval.";
export const REQUISITION_HEDGING_THRESHOLD = 250000;

// PO Line types
export const PO_LINE_BY_QTY = "STOCK";

// PO Statuses
export const PO_APPROVED = "APPROVED";
export const PO_IN_PROCESS = "IN PROCESS";
export const PO_INCOMPLETE = "INCOMPLETE";
export const PO_CLOSED = "CLOSED";
export const PO_OPEN = "OPEN";
export const PO_CANCEL_FLAG_TRUE = "Y";

// FAQs and help links
export const IBUY_FAQ_SHAREPOINT_URL =
    "https://ssecom.sharepoint.com/teams/corporate-pandl-procurementops/PC-Engagement/iBuy";
export const IBUY_CHARGE_ACCOUNT_SHAREPOINT_URL =
    "https://ssecom.sharepoint.com.mcas.ms/teams/corporate-finst/SitePages/Harmony_Finance_Portal.aspx";
export const IBUY_PRODUCT_CATEGORY_SHAREPOINT_URL =
    "https://ssecom.sharepoint.com/teams/corporate-pandl-procurementops/PC-Engagement/Lists/Category%20Tree/Category%20Team%20View.aspx";

// Media query keywords
export const MOBILE = "MOBILE";
export const DESKTOP = "DESKTOP";

// Autocomplete constants
export const AUTOCOMPLETE_API_MIN_CHARS = 3;

// Requisition table action button types
export const RECEIPT = "RECEIPT";
export const AMEND = "AMEND";
export const COPY = "COPY";
export const CANCEL = "CANCEL";

// PO Chip Types
export const MINIMAL_PO_CHIP = "minimal";
export const DETAILED_PO_CHIP = "detailed";
export const REQUISITION_PO_CHIP = "requisition";

// Notification Timer (milliseconds) - Approval/Receipt updates are refreshed this often - use with caution!
export const NOTIFICATION_TIMER = 150000;

// Button designs
export const BUTTON_OUTLINED = "outlined";
export const BUTTON_ICON = "icon";

// Theme modes
export const DARK_MODE = "dark";
export const LIGHT_MODE = "light";

// Cancel status
export const CANCELLED_FLAG = "Y";
const CANCEL_PENDING_FLAG = "PENDING";
export const CANCELLATION_FLAG_STATUSES = [CANCELLED_FLAG, CANCEL_PENDING_FLAG];

// Invoice Summary Tabs
export const INVOICE_LINES = "Line";
export const INVOICE_PAYMENTS = "Payment";
export const INVOICE_HOLDS = "Hold";

// Rate values to be applied to a non-catalogue
// item when its CURRENCY CODE matches the base
// currency of the OU the item belongs to
export const NON_CAT_BASE_RATE_VALUES = {
    RATE: "",
    RATE_TYPE: "",
    RATE_DATE: "",
};

// Redux auth role types
export const PROC_MANAGER_REDUX_ROLE = "Procurement Manager";
export const REQUESTOR_REDUX_ROLE = "Requester";
export const PROC_MANAGER_AND_REQUESTOR_REDUX_ROLE =
    "Procurement Manager & Requester";
export const NOT_ASSIGNED_REDUX_ROLE = "No role assigned";
export const IS_PROC_MANAGER_REDUX_ROLE = [
    PROC_MANAGER_REDUX_ROLE,
    PROC_MANAGER_AND_REQUESTOR_REDUX_ROLE,
];
