import { Divider, Grid, Stack } from "@mui/material";
import DetailListVertical from "components/displays/detailListVertical";
import OpenReceiptSummaryButton from "components/receiptSummary/buttons/openReceiptSummaryButton";
import PropTypes from "prop-types";
import React from "react";

const ReceiptLine = ({ receiptLine }) => {
    const received = receiptLine?.ERROR
        ? "-"
        : `${receiptLine?.QUANTITY_RECEIVED} x ${receiptLine?.UNIT_OF_MEASURE_DESCRIPTION}`;

    return (
        <Grid container spacing={1} sx={{ paddingLeft: 2 }}>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <DetailListVertical
                    details={[
                        {
                            header: "Receipt Number",
                            content: receiptLine?.ERROR
                                ? "-"
                                : receiptLine?.RECEIPT_NUM,
                        },
                        {
                            header: "iBuy Receipt Number",
                            content: receiptLine?.IBUY_RECEIPT_ID
                                ? receiptLine?.IBUY_RECEIPT_ID
                                : "-",
                        },
                        {
                            header: "Created By",
                            content: receiptLine?.ERROR
                                ? "-"
                                : receiptLine?.created_by,
                        },
                        {
                            header: "Total Received",
                            content: received,
                        },
                    ]}
                />
            </Grid>

            <Grid item xs={12}>
                <Stack justifyContent="right" direction="row">
                    <OpenReceiptSummaryButton
                        receiptLine={receiptLine}
                        isDesktop={false}
                    />
                </Stack>
            </Grid>
        </Grid>
    );
};

ReceiptLine.propTypes = {
    receiptLine: PropTypes.object.isRequired,
};

export default ReceiptLine;
