import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import AppsIcon from "@mui/icons-material/Apps";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import CloseIcon from "@mui/icons-material/Close";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { isTrue } from "utils/general";

import { BLANK_CART } from "../../constants/generalConstants";
import {
    setDarkMode,
    setShowCreateNameCartDialogue,
    setShowUserInfo,
    toggleSpeedDialPlacement,
} from "../../redux/actions/generalActions";
import { useSpeedDialConstants } from "../../styled/constants/speedDial";
import UserPopper from "../modals/userInfoPopper/userPopper";
import navigationItems from "./navigationItems";
import NotificationBadge from "./notificationBadge";

const DISABLED_MOBILE_NAVIGATION = ["Non-Catalogue"];

const MobileSpeedDial = () => {
    // Hooks
    const speedDialConstants = useSpeedDialConstants();
    // Collect states and state dispatchers
    const userIconRef = useRef(null);
    const darkMode = useSelector((state) => state.general.darkMode);
    const showUserInfo = useSelector((state) => state.general.showUserInfo);
    const speedDialRight = useSelector((state) => state.general.speedDialRight);
    const dispatch = useDispatch();

    const changeSpeedDial = () => {
        dispatch(toggleSpeedDialPlacement(!speedDialRight));
    };

    useEffect(() => {
        if (
            speedDialRight !== null &&
            isTrue(localStorage.getItem("speedDial")) !== speedDialRight
        ) {
            localStorage.setItem("speedDial", speedDialRight);
        }
    }, [speedDialRight]);

    const [open, setOpen] = useState(false);
    const navigateTo = useNavigate();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleClick = (route, action) => {
        handleClose();
        if (action) {
            action();
        } else {
            navigateTo(route);
        }
    };

    const customIcon = (icon, notificationCount) => {
        return (
            <NotificationBadge notificationCount={notificationCount}>
                {icon}
            </NotificationBadge>
        );
    };

    const createBlankCart = () => {
        dispatch(
            setShowCreateNameCartDialogue({ open: true, mode: BLANK_CART })
        );
    };

    const triggerShowUserInfo = () => {
        dispatch(setShowUserInfo(!showUserInfo));
    };

    const activeCart = useSelector((state) => state.general.activeCart);
    const approvalsNotificationCount = useSelector(
        (state) => state.general.approvalsNotificationCount
    );
    const receiptsNotificationCount = useSelector(
        (state) => state.general.receiptsNotificationCount
    );
    const navItems = navigationItems(
        activeCart,
        approvalsNotificationCount,
        receiptsNotificationCount
    );

    const theme = () => {
        const isChecked = !darkMode;
        dispatch(setDarkMode(isChecked));
        localStorage.setItem("darkMode", isChecked);
    };

    const mobileActionButtons = [
        {
            key: "User",
            icon: <ManageAccountsIcon />,
            action: triggerShowUserInfo,
            ref: userIconRef,
        },
        {
            key: "Add Cart",
            icon: <AddShoppingCartIcon />,
            action: createBlankCart,
        },
        {
            key: "Change Theme",
            icon: darkMode ? <LightModeIcon /> : <DarkModeIcon />,
            action: theme,
        },
        {
            key: "SpeedDial Placement",
            icon: speedDialRight ? (
                <ArrowCircleLeftIcon />
            ) : (
                <ArrowCircleRightIcon />
            ),
            action: () => changeSpeedDial(),
        },
    ];

    return (
        <Box
            sx={(theme) => {
                return { zIndex: theme.zIndex.speedDial };
            }}
        >
            <Backdrop
                sx={{
                    backdropFilter: "blur(5px)",
                }}
                open={open}
            />
            <SpeedDial
                direction={speedDialRight ? "left" : "right"}
                ariaLabel="Action speed dial"
                sx={{
                    position: "absolute",
                    bottom: speedDialConstants.inset,
                    right: speedDialRight ? speedDialConstants.inset : null,
                    left: !speedDialRight ? speedDialConstants.inset : null,
                }}
                FabProps={{
                    color: "secondary",
                    sx: {
                        width: speedDialConstants.diameter,
                        height: speedDialConstants.diameter,
                    },
                }}
                icon={
                    <SpeedDialIcon
                        icon={<AppsIcon />}
                        openIcon={<CloseIcon />}
                    />
                }
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
            >
                {mobileActionButtons.map((item) => (
                    <SpeedDialAction
                        key={item.key}
                        tooltipTitle={item.key}
                        FabProps={{
                            sx: {
                                backgroundColor: "secondary",
                                color: "text.primary",
                                width: "42.5px",
                                height: "42.5px",
                                mb: "1px",
                            },
                        }}
                        icon={
                            item.notificationCount
                                ? customIcon(item.icon, item.notificationCount)
                                : item.icon
                        }
                        ref={item.ref ? item.ref : null}
                        disabled={item.disabled ? item.disabled : false}
                        sx={{
                            ".MuiSpeedDialAction-staticTooltipLabel": {
                                whiteSpace: "nowrap",
                                textAlign: "right",
                                fontSize: "16px",
                            },
                        }}
                        onClick={() =>
                            handleClick(
                                item.route ? item.route : null,
                                item.action ? item.action : null
                            )
                        }
                    />
                ))}
            </SpeedDial>
            <SpeedDial
                ariaLabel="Navigation speed dial"
                sx={{
                    position: "absolute",
                    bottom: speedDialConstants.inset,
                    right: speedDialRight ? speedDialConstants.inset : null,
                    left: !speedDialRight ? speedDialConstants.inset : null,
                }}
                FabProps={{
                    color: "secondary",
                    sx: {
                        width: speedDialConstants.diameter,
                        height: speedDialConstants.diameter,
                    },
                }}
                icon={
                    <NotificationBadge
                        notificationCount={
                            navItems
                                ? navItems.reduce(
                                      (a, b) =>
                                          b.notificationCount
                                              ? a + b.notificationCount
                                              : a,
                                      0
                                  )
                                : null
                        }
                    >
                        <SpeedDialIcon
                            icon={<AppsIcon />}
                            openIcon={<CloseIcon />}
                        />
                    </NotificationBadge>
                }
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
            >
                {navItems
                    .filter(
                        (navItem) =>
                            !DISABLED_MOBILE_NAVIGATION.includes(navItem.key)
                    )
                    .map((item) => (
                        <SpeedDialAction
                            key={item.key}
                            FabProps={{
                                sx: {
                                    backgroundColor: "secondary.main",
                                    color: "white",
                                    width: "42.5px",
                                    height: "42.5px",
                                    mb: "1px",
                                },
                            }}
                            icon={
                                item.notificationCount
                                    ? customIcon(
                                          item.iconSpeedDial,
                                          item.notificationCount
                                      )
                                    : item.iconSpeedDial
                            }
                            tooltipTitle={item.key}
                            tooltipOpen
                            tooltipPlacement={speedDialRight ? "left" : "right"}
                            sx={{
                                ".MuiSpeedDialAction-staticTooltipLabel": {
                                    whiteSpace: "nowrap",
                                    textAlign: "right",
                                    fontSize: "16px",
                                    color: "text.primary",
                                },
                            }}
                            onClick={() =>
                                handleClick(
                                    item.route ? item.route : null,
                                    item.action ? item.action : null
                                )
                            }
                        />
                    ))}
            </SpeedDial>
            <UserPopper userIconRef={userIconRef} />
        </Box>
    );
};

export default MobileSpeedDial;
