// Returns action creators for each form lookup action type
import { SET_CHARGE_ACCOUNTS, SET_TASKS } from "../actionTypes";

// Action creators for form lookup slice
export const setChargeAccounts = (accounts) => {
    return { type: SET_CHARGE_ACCOUNTS, payload: accounts };
};

export const setTasks = (tasks) => {
    return { type: SET_TASKS, payload: tasks };
};
