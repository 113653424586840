import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import useIsMount from "hooks/useIsMount";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { AUTOCOMPLETE_API_MIN_CHARS } from "../../../constants/generalConstants";
import useThrottle from "../../../hooks/useThrottle";
import { freeTextFormProps } from "../../../styled/props/textFieldProps";
import {
    inputPlaceholder,
    MIN_3_CHARACTERS_TEXT,
    NO_OPTIONS_TEXT,
    START_NEW_SEARCH_TEXT,
} from "./labels";

const SearchAsYouType = ({
    selectedValue,
    onSelectValue,
    isDisabled,
    autocompleteGet,
    searchPrompt,
    label,
    optionKeyMapping,
    required = false,
}) => {
    const optionIdKey = optionKeyMapping?.["id"] || "id";
    const optionLabelKey = optionKeyMapping?.["label"] || "label";

    // Local states
    const [noOptionsText, setNoOptionsText] = useState(
        selectedValue?.[optionLabelKey]
            ? START_NEW_SEARCH_TEXT
            : MIN_3_CHARACTERS_TEXT
    );
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState(
        selectedValue?.[optionLabelKey] || ""
    );

    // Instantiate hooks required for search as you type
    const throttleFunction = useThrottle();
    const isFirstRender = useIsMount();

    useEffect(() => {
        // If no input, provided, or less than 3 characters input, API not called
        if (!isFirstRender) {
            throttleFunction(() => {
                if (
                    searchTerm === "" ||
                    searchTerm.length < AUTOCOMPLETE_API_MIN_CHARS
                ) {
                    setOptions([]);
                    setNoOptionsText(MIN_3_CHARACTERS_TEXT);
                    return;
                }

                // Else, call autocomplete get API
                selectedValue
                    ? setNoOptionsText(START_NEW_SEARCH_TEXT)
                    : setNoOptionsText(NO_OPTIONS_TEXT);
                autocompleteGet(searchTerm, setOptions, setIsLoading);
            });
        }
    }, [searchTerm]);

    //region Helper functions for the MUI autocomplete
    /**
     * @param {Object} option
     * @returns {string}
     */
    const getOptionLabel = (option) => {
        return option[optionLabelKey];
    };

    /**
     * Called when an option is selected by a user.
     * @param e
     * @param {Object} newValue
     */
    const onChange = (e, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        onSelectValue(newValue);
    };

    const onInputChange = (_event, newInputValue) => {
        setSearchTerm(newInputValue);
    };

    /**
     * Called as part of autocomplete `isOptionEqualToValue` prop.
     * @param {Object} option The structure of the autocomplete options
     * @param {Object} value The obj returned from the `onChange` method on the autocomplete
     * @returns {boolean}
     */
    const isOptionEqualToValue = (option, value) => {
        return option[optionIdKey] === value[optionIdKey];
    };

    return (
        <Autocomplete
            data-testid="searchAsYouTypeField"
            getOptionLabel={getOptionLabel}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            filterSelectedOptions
            value={selectedValue}
            noOptionsText={noOptionsText}
            onChange={onChange}
            onInputChange={onInputChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...freeTextFormProps}
                    label={label}
                    placeholder={inputPlaceholder(searchPrompt)}
                    required={required}
                />
            )}
            disabled={isDisabled}
            loading={isLoading}
            isOptionEqualToValue={isOptionEqualToValue}
        />
    );
};

SearchAsYouType.propTypes = {
    selectedValue: PropTypes.object,
    onSelectValue: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    autocompleteGet: PropTypes.func.isRequired,
    searchPrompt: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    optionKeyMapping: PropTypes.object,
    required: PropTypes.bool,
};

export default SearchAsYouType;
