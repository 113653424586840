import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

import CopyWrapper from "./copyWrapper";

const CopyTypography = ({ children, customMsg, ...typographyProps }) => {
    return (
        <CopyWrapper
            customMsg={customMsg}
            value={children}
            content={<Typography {...typographyProps}>{children}</Typography>}
        />
    );
};

CopyTypography.propTypes = {
    text: PropTypes.string,
    customMsg: PropTypes.string,
};

export default CopyTypography;
