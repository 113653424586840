import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import { Button, IconButton, Tooltip } from "@mui/material";
import RequisitionStatusBadge from "components/displays/status/requisitionStatusBadge";
import { INVALID_REQUISITION } from "constants/generalConstants";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { openReceiptSummaryDialogue } from "utils/receiptSummaryUtils";

const OpenReceiptSummaryButton = ({ receiptLine, isDesktop }) => {
    const dispatch = useDispatch();

    /**
     * @param {string} errorMsg
     * @returns {string}
     */
    const errorTooltip = (errorMsg) => {
        const reason = errorMsg ? `Error message: ${errorMsg}` : "";
        return (
            "Invalid receipt. Please contact iBuy administrators for assistance." +
            reason
        );
    };

    const handleClick = () =>
        openReceiptSummaryDialogue(receiptLine.RECEIPT_NUM, dispatch);

    if (receiptLine.ERROR)
        return (
            <RequisitionStatusBadge
                status={INVALID_REQUISITION}
                customTooltip={errorTooltip(receiptLine?.ERROR_MESSAGE)}
            />
        );

    return isDesktop ? (
        <Tooltip title="Open receipt management pop-up">
            <IconButton aria-label="goToReceiptSummary" onClick={handleClick}>
                <ContentPasteGoIcon />
            </IconButton>
        </Tooltip>
    ) : (
        <Button
            aria-label="goToReceiptSummary"
            variant="outlined"
            color="secondary"
            endIcon={<ContentPasteGoIcon />}
            onClick={handleClick}
        >
            Summary
        </Button>
    );
};

OpenReceiptSummaryButton.propTypes = {
    receiptLine: PropTypes.object.isRequired,
    isDesktop: PropTypes.bool.isRequired,
};

export default OpenReceiptSummaryButton;
