export const poShipmentsByReqId = (reqLineId, posForReqLine) => {
    /* Parse the output of the GET_POS_BY_REQ_LINE_ID (src.services/procurement/urls)
    api to produce a distinct list of PO shipments per req line ID

    Arguments
    ---------
    reqLineId : string
    posForReqLine : array

    Returns
    -------
    array
    */
    const posForReqLineCleansed = posForReqLine || [];
    // Loop through all POs
    const shipments = posForReqLineCleansed.reduce((acc, po) => {
        const poLines = po?.poLines || [];
        // Loop through all PO lines
        const poShipments = poLines.reduce(
            (associatedShipmentsPerLine, poLine) => {
                const poShipments = poLine?.poLocations || [];
                // Loop through all PO shipments
                const associatedShipments = poShipments.filter((poShipment) => {
                    const poDists = poShipment?.poDist || [];
                    // Loop through all distributions, retrieving only those tagged to `reqLineId`
                    const doesDistArrayContainReqLineId = poDists.some(
                        (dist) => dist?.IBUY_REQ_LINE_REF == reqLineId
                    );
                    return doesDistArrayContainReqLineId;
                });
                return [...associatedShipmentsPerLine, ...associatedShipments];
            },
            []
        );
        return [...acc, ...poShipments];
    }, []);
    // Take distinct shipments only
    const uniqueShipments = [...new Set(shipments)];
    return uniqueShipments;
};

export const getReqsAssociatedWithPO = (poData) => {
    /* Extract distinct iBuy requisition IDs from a nested PO payload

    Arguments
    ---------
    poData : obj
        A nested PO object, including lines, locations and distributions.

    Returns
    -------
    array   
        Distinct list of iBuy requisitions tagged in the input PO

    */
    const poLines = poData?.poLines || [];
    // Loop through all PO lines
    const reqIdsPerPO = poLines.reduce((acc, poLine) => {
        const poLocations = poLine?.poLocations || [];
        // Loop through all PO locations (shipments)
        const reqIdsPerLine = poLocations.reduce((acc2, poLocation) => {
            const poDists = poLocation?.poDist || [];
            // Loop through all PO distributions
            const reqIdsPerLocation = poDists.reduce((acc3, poDist) => {
                const reqLineId = poDist?.IBUY_REQ_LINE_REF || "_";
                const reqId = reqLineId.split("_", 1)[0];
                const appendReqIds = reqId ? [...acc3, reqId] : acc3;
                return appendReqIds;
            }, acc2);

            return [...acc2, ...reqIdsPerLocation];
        }, acc);

        return [...acc, ...reqIdsPerLine];
    }, []);
    // Take distinct req Ids only
    const uniqueReqIds = [...new Set(reqIdsPerPO)];
    return uniqueReqIds;
};
