import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { useMediaQuery } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    CATALOGUE_ITEM,
    DIRECT,
    EDIT_NON_CAT_CART_ITEM,
    NON_CATALOGUE_ITEM,
    SERVICE,
    SMART_FORM,
    STOCK,
} from "../../constants/generalConstants";
import { MOBILE_MEDIA_QUERY } from "../../constants/mediaQueries";
import {
    TOOLTIP_DELETE_CART,
    TOOLTIP_EDIT_NON_CAT_ITEM,
} from "../../constants/tooltipText";
import { updateCartItems } from "../../services/cart/cartAPIs";
import { getImg } from "../../services/search/searchAPIs";
import { autoCompleteProps } from "../../styled/props/autocompleteProps";
import { freeTextFormProps } from "../../styled/props/textFieldProps";
import { openNonCatDialogue } from "../../utils/nonCatFormUtils";
import {
    amendedCartItems,
    quantityOptions,
} from "../../utils/shoppingCartUtils";
import FavouriteButton from "../buttons/favouriteButton";
import CurrencyFigure from "../displays/currencyFigure";
import IBuyPaper from "../displays/iBuyPaper";
import OnDesktop from "../wrappers/onDesktop";
import noImage from "./../../assets/noImage.png";
import FormatNameValue from "./../displays/formatNameValue";

const lineTypeDispatcher = {
    [STOCK]: "Unit Cost",
    [DIRECT]: "Rate",
    [SERVICE]: "Amount",
};

const itemTypeDispatcher = {
    [CATALOGUE_ITEM]: "Catalogue",
    [NON_CATALOGUE_ITEM]: "Non-Catalogue",
    [SMART_FORM]: "Smart-Form",
};

const ShoppingCartItem = ({ product }) => {
    // Collect states and state dispatchers
    const dispatch = useDispatch();
    const activeCart = useSelector((state) => state.general.activeCart);
    const carts = useSelector((state) => state.shoppingCart);

    const [imgUrl, setImgUrl] = useState(noImage);

    useEffect(() => {
        getImg(product.ITEM_IMAGE_URL, setImgUrl);
    }, [product]);

    const handleItemQuantityChange = (event, value) => {
        const newItems = amendedCartItems(
            carts[activeCart].items,
            "UPDATE_QUANTITY",
            {
                poLineItemToUpdate: product.PO_LINE_ID,
                newQuantity: parseFloat(value),
            }
        );
        updateCartItems(activeCart, dispatch, newItems);
    };

    const handleDeleteCartItem = () => {
        const newItems = amendedCartItems(
            carts[activeCart].items,
            "REMOVE",
            product.PO_LINE_ID
        );
        updateCartItems(activeCart, dispatch, newItems);
    };

    const editNonCatItem = () => {
        openNonCatDialogue(dispatch, EDIT_NON_CAT_CART_ITEM, product);
    };

    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    return (
        <IBuyPaper additionalSx={{ mb: "24px" }}>
            <Grid container spacing={2}>
                <Grid item xs={4} lg={2}>
                    <CardMedia
                        alt="Product Image Thumbnail"
                        component="img"
                        src={imgUrl}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = noImage;
                        }}
                        sx={{ borderRadius: "10px" }}
                    />
                </Grid>
                <Grid item xs={12} lg={7} order={{ xs: 3, lg: 2 }}>
                    <Typography
                        align="left"
                        sx={{
                            fontSize: 16,
                            fontWeight: "bold",
                        }}
                    >
                        {product.ITEM_DESCRIPTION}
                    </Typography>
                    <FormatNameValue
                        name="Line Type"
                        value={product.LINE_TYPE}
                        variant="body1"
                    />
                    {product?.CPA_NUM_NAME ? (
                        <FormatNameValue
                            name="CPA Number - Name"
                            value={product.CPA_NUM_NAME}
                            variant="body1"
                        />
                    ) : (
                        <FormatNameValue
                            name="Supplier"
                            value={
                                product.SUPPLIER_NAME
                                    ? product.SUPPLIER_NAME
                                    : "Not Specified"
                            }
                            variant="body1"
                        />
                    )}

                    <FormatNameValue
                        name="Unit of Measure"
                        value={
                            product?.LINE_TYPE == SERVICE
                                ? "N/A - service ordered by amount"
                                : product.UNIT_OF_MEASURE
                        }
                        variant="body1"
                    />
                    <FormControl
                        fullWidth
                        sx={{
                            marginTop: "10px",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <Autocomplete
                            {...autoCompleteProps}
                            id="Cart item quantity"
                            autoSelect
                            value={product.QUANTITY.toString()}
                            options={quantityOptions(1, 500)}
                            sx={{
                                width: isDesktop ? "20%" : "35%",
                                margin: "10px 0px 0px 0px",
                            }}
                            onChange={handleItemQuantityChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...freeTextFormProps}
                                    label={
                                        product?.LINE_TYPE == SERVICE
                                            ? "Amount"
                                            : "Qty"
                                    }
                                    variant="outlined"
                                />
                            )}
                        />
                        <Chip
                            label={
                                itemTypeDispatcher[product?.itemType] ||
                                "Catalogue"
                            }
                            size="small"
                            sx={{
                                color: "text.subheader",
                                margin: "10px 0px 0px 10px",
                                borderColor: "text.subheader",
                            }}
                            variant="outlined"
                        />
                        <Chip
                            label={product?.CATEGORY || ""}
                            size="small"
                            sx={{
                                margin: "10px 0px 0px 10px",
                                color: "text.subheader",
                                borderColor: "text.subheader",
                            }}
                            variant="outlined"
                        />
                        <OnDesktop>
                            <Chip
                                label={product?.LINE_TYPE || ""}
                                size="small"
                                sx={{
                                    margin: "10px 0px 0px 10px",
                                    color: "text.subheader",
                                    borderColor: "text.subheader",
                                }}
                                variant="outlined"
                            />
                        </OnDesktop>
                        {product?.CPA_NUM_NAME && (
                            <Chip
                                label="CPA"
                                size="small"
                                sx={{
                                    margin: "10px 0px 0px 10px",
                                    color: "text.subheader",
                                    borderColor: "text.subheader",
                                }}
                                variant="outlined"
                            />
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={8} lg={3} order={{ xs: 2, lg: 3 }}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        sx={{ height: "100%" }}
                    >
                        <Grid item>
                            <Typography
                                align="right"
                                sx={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                }}
                            >
                                {product?.LINE_TYPE
                                    ? lineTypeDispatcher[product?.LINE_TYPE]
                                    : "Unit Cost"}
                            </Typography>
                            <Typography
                                align="right"
                                color="text.subheader"
                                variant="h2"
                                fontWeight="normal"
                            >
                                <CurrencyFigure
                                    value={
                                        product?.LINE_TYPE == SERVICE
                                            ? product?.QUANTITY
                                            : product?.UNIT_PRICE
                                    }
                                    code={product?.CURRENCY_CODE}
                                />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                {product.itemType == NON_CATALOGUE_ITEM && (
                                    <Tooltip title={TOOLTIP_EDIT_NON_CAT_ITEM}>
                                        <IconButton
                                            onClick={editNonCatItem}
                                            aria-label="editNonCatItem"
                                        >
                                            <EditIcon color="iconButton" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <FavouriteButton
                                    aria-label="Favourite Button"
                                    product={product}
                                    white
                                />
                                <Tooltip title={TOOLTIP_DELETE_CART}>
                                    <IconButton
                                        onClick={handleDeleteCartItem}
                                        aria-label="Delete Cart Item"
                                    >
                                        <DeleteForeverOutlinedIcon color="iconButton" />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </IBuyPaper>
    );
};

ShoppingCartItem.propTypes = {
    product: PropTypes.object,
};

export default ShoppingCartItem;
