import BlockIcon from "@mui/icons-material/Block";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PendingIcon from "@mui/icons-material/Pending";
import Chip from "@mui/material/Chip";
import { PO_CANCEL_FLAG_TRUE } from "constants/generalConstants";
import PropTypes from "prop-types";

/**
 * Reusable chip component for PO line and shipments statuses
 * displayed in iBuy. If the line / shipment is cancelled,
 * this status will take precedent over closed status
 * @component
 */

const CANCELLED_STATUS = "CANCELLED";
const OPEN_STATUS = "OPEN";
const CLOSED_STATUS = "CLOSED";
const CLOSED_FOR_RECEIVING_STATUS = "CLOSED FOR RECEIVING";
const CLOSED_FOR_INVOICE_STATUS = "CLOSED FOR INVOICE";
const FINALLY_CLOSED_STATUS = "FINALLY CLOSED";
const STATUS_NOT_RECOGNISED = "STATUS_NOT_RECOGNISED";

const poShipmentLineStatusChipDispatcher = {
    [CANCELLED_STATUS]: {
        label: "CANCELLED",
        color: "error",
        icon: <CancelIcon />,
    },
    [OPEN_STATUS]: {
        label: "OPEN",
        color: "success",
        icon: <CheckCircleOutlineIcon />,
    },
    [CLOSED_STATUS]: {
        label: "CLOSED",
        color: "warning",
        icon: <BlockIcon />,
    },
    [CLOSED_FOR_RECEIVING_STATUS]: {
        label: "CLOSED FOR RECEIVING",
        color: "warning",
        icon: <BlockIcon />,
    },
    [CLOSED_FOR_INVOICE_STATUS]: {
        label: "CLOSED FOR INVOICE",
        color: "warning",
        icon: <BlockIcon />,
    },
    [FINALLY_CLOSED_STATUS]: {
        label: "CLOSED",
        color: "warning",
        icon: <BlockIcon />,
    },
    [STATUS_NOT_RECOGNISED]: {
        color: "info",
        icon: <PendingIcon />,
    },
};

const PoShipmentLineStatusChip = ({ closedCode, cancelFlag }) => {
    const isCancelled = cancelFlag == PO_CANCEL_FLAG_TRUE;
    const identifier = isCancelled ? CANCELLED_STATUS : closedCode;
    return (
        <Chip
            label={
                poShipmentLineStatusChipDispatcher[identifier]?.label ||
                identifier
            }
            size="small"
            color={
                poShipmentLineStatusChipDispatcher[identifier]?.color ||
                poShipmentLineStatusChipDispatcher[STATUS_NOT_RECOGNISED]?.color
            }
            icon={
                poShipmentLineStatusChipDispatcher[identifier]?.icon ||
                poShipmentLineStatusChipDispatcher[STATUS_NOT_RECOGNISED]?.icon
            }
        />
    );
};

PoShipmentLineStatusChip.propTypes = {
    closedCode: PropTypes.string,
    cancelFlag: PropTypes.string,
};

export default PoShipmentLineStatusChip;
