import { Drawer, SwipeableDrawer } from "@mui/material";
import OnDesktop from "components/wrappers/onDesktop";
import OnMobile from "components/wrappers/onMobile";
import PropTypes from "prop-types";

const CustomDrawer = (props) => {
    const { children, onOpen, ...rest } = props;

    return (
        <>
            <OnDesktop>
                <Drawer {...rest}>{children}</Drawer>
            </OnDesktop>
            <OnMobile>
                <SwipeableDrawer onOpen={onOpen} {...rest}>
                    {children}
                </SwipeableDrawer>
            </OnMobile>
        </>
    );
};

CustomDrawer.propTypes = {
    anchor: PropTypes.string,
    transitionDuration: PropTypes.number,
    open: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    children: PropTypes.node,
};

export default CustomDrawer;
