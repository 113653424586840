import { createStore } from "redux";

import { initialState } from "./initialState";
import rootReducer from "./reducers/rootReducer";

const enhancer =
    // eslint-disable-next-line no-undef
    GLOBAL_CONFIG.REACT_APP_ENVIRONMENT.toLowerCase() === "dev"
        ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__({
              trace: true,
              traceLimit: 25,
          })
        : undefined;

const store = createStore(rootReducer, initialState, enhancer);

export default store;
