// eslint-disable-next-line no-undef
export const BASE_BACKEND_URL = GLOBAL_CONFIG.REACT_APP_APIM_URL;
// eslint-disable-next-line no-undef
export const ENVIRONMENT = GLOBAL_CONFIG.REACT_APP_ENVIRONMENT;

// Base shopping cart URL
export const SEARCH_API_URL = `${BASE_BACKEND_URL}/ibuy-${ENVIRONMENT}-search`;
// export const SEARCH_API_URL = `http://localhost:9019/api`;

export const LOOKUPS = `${SEARCH_API_URL}/lookups`;
export const GET_CATEGORIES = `${SEARCH_API_URL}/categories/category-list`;
export const SEARCH_CATALOGUE_ITEMS = `${SEARCH_API_URL}/items`;
export const SEARCH_CATALOGUE_IMAGES = `${SEARCH_API_URL}/images`;
export const SEGMENT_LOOKUPS = `${SEARCH_API_URL}/segment-lookups`;
export const PRODUCT_DESCRIPTION_LONG = `${SEARCH_API_URL}/product-detail`;
export const CPA_AUTOCOMPLETE = `${SEARCH_API_URL}/cpas`;

export const SEARCH_CATALOGUE_IMAGES_GET = (imageUrl) => {
    // image url encode is required as url contains special characters like &, (, ) If url contains &, it will split the url as multiple query parameters if not encoded and will fail to load the image
    const encodedImageUrl = encodeURIComponent(imageUrl);

    return `${SEARCH_CATALOGUE_IMAGES}?image_uri=${encodedImageUrl}`;
};

export const PRODUCT_DETAILS_GET = (productId, OU) => {
    // Ensure product ID is always an INT for security reasons
    productId = parseInt(productId);
    return `${SEARCH_CATALOGUE_ITEMS}/?po_line_id=${productId}&OU=${OU}`;
};

export const PRODUCT_DESCRIPTION_LONG_GET = (itemId) => {
    return `${PRODUCT_DESCRIPTION_LONG}/${itemId}`;
};

export const CPA_AUTOCOMPLETE_GET = (cpa, OU, searchById = false) => {
    return `${CPA_AUTOCOMPLETE}/${cpa}?OU=${OU}${
        searchById ? "&search_by_id=Y" : ""
    }`;
};

export const SMART_FORM_SEARCH = `${SEARCH_API_URL}/smart-forms`; // different route to usual parent
export const SMART_FORM_SEARCH_BY_ID = (smartFormId, OU) =>
    `${SEARCH_API_URL}/smart-forms?OU=${OU}&smart_form_id=${smartFormId}`; // different route to usual parent

export const SMART_FORMS = `${SEARCH_API_URL}/smart_form`;
export const GET_INFORMATION_TEMPLATE = (smartFormId) => {
    return `${SMART_FORMS}/${smartFormId}`;
};
