import { ATTENTION_REQUIRED } from "./generalConstants";

// Defines routes for all iBuy webpages
export const APPROVALS = "/approvals";
export const CART_PREFIX = "/cart/";
export const CART_ROUTE_SETTER = "/cart/:cartKey";
export const HOMEPAGE = "/";
export const INVOICE_SUMMARY = "/invoice";
export const INVOICE_SUMMARY_SETTER = "/invoice/:invoiceId";
export const PAGE_NOT_FOUND = "*";
export const PO_SUMMARY = "/po-summary";
export const PO_SUMMARY_SETTER = "/po-summary/:poNumber";
export const PROCUREMENT_MANAGER_ADMIN = "/procurementAdmin";
export const PRODUCT_RESULTS = "/product-results";
export const PRODUCT_DETAILS = "/product-details/:id";
export const RECEIPTS = "/receipts";
export const REQUISITIONS = "/requisitions";
export const REQUISITION_SUMMARY = "/requisition-summary/:requisitionNumber";
export const SMART_FORM_DETAILS = "/smart-form-details/:id";

export const INVOICE_SUMMARY_PAGE = (invoiceId) =>
    `${INVOICE_SUMMARY}/${invoiceId}`;
export const PRODUCT_DETAILS_PAGE = (id) => `/product-details/${id}`;
export const PO_SUMMARY_PAGE = (poNumber) => `${PO_SUMMARY}/${poNumber}`;
export const RECEIPTS_PRIORITISED = (receiptsNotificationCount) =>
    `${RECEIPTS}${
        receiptsNotificationCount > 0
            ? `?receiptReqStatus=${ATTENTION_REQUIRED}`
            : ""
    }`;
export const REQ_RECEIPT_SUMMARY = (id) =>
    `${RECEIPTS}?mode=ALL_PERMITTED&&requisitionId=${id}`;
export const REQUISITION_SUMMARY_PAGE = (requisitionNumber) =>
    `/requisition-summary/${requisitionNumber}`;
export const SMART_FORM_DETAILS_PAGE = (id) => `/smart-form-details/${id}`;
