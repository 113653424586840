import { Box, SwipeableDrawer } from "@mui/material";
import SwipeToClose from "components/displays/swipeToClose";
import PropTypes from "prop-types";
import React from "react";

const CustomSwipeableDrawer = ({ children, isOpen, onClose }) => {
    return (
        <SwipeableDrawer
            anchor={"right"}
            transitionDuration={200}
            open={isOpen}
            onOpen={() => {}}
            onClose={onClose}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "100%",
                    padding: "20px",
                }}
            >
                {children}
                <SwipeToClose direction="right" />
            </Box>
        </SwipeableDrawer>
    );
};

CustomSwipeableDrawer.propTypes = {
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CustomSwipeableDrawer;
