import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField, useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import UserSearchAsYouType from "components/inputs/searchAsYouType/userSearchAsYouType";
import { MOBILE_MEDIA_QUERY } from "constants/mediaQueries";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowBatchChangeReqOwnerDialogue } from "redux/actions/generalActions";
import { freeTextFormProps } from "styled/props/textFieldProps";
import { objectOnCondition } from "utils/general";

import useUser from "../../../services/apiUtils/hooks/useUser";
import { styledDialogChildSX } from "../../../styled/styledSXProperties/styledDialogueSX";
import { baseStyledPaperSX } from "../../../styled/styledSXProperties/styledPaperSX";
import CustomDialogTitle from "../../customDialog/customDialogTitle";
import { reqFilterFormatDate } from "../../requisitions/requisitionListPage/reqFilterPanel";
import PreFlightCheckInfoAlert from "./preFlightCheckInfoAlert";
import PreFlightCheckLoadingAlert from "./preFlightCheckLoadingAlert";

const SUBTITLE =
    "This form enables you to transfer ownership of a specified user's requisitions, over an optionally specified date range. You can change up to 500 requisitions at a time.";

const BatchChangeReqOwnerDialogueContentDispatcher = () => {
    // Collect states and state dispatchers
    const dispatch = useDispatch();
    const {
        batchReqOwnerUpdate,
        isPreflightCheckLoading,
        isBatchReqReqOwnerUpdateLoading,
    } = useUser();

    // Define local states
    const [fromOwner, setFromOwner] = useState(null);
    const [toOwner, setToOwner] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [preflightBody, setPreflightBody] = useState({});

    const handleClose = () => {
        dispatch(setShowBatchChangeReqOwnerDialogue(false));
    };

    const onDateSelect = (date, action) => {
        if (date && moment(date).isValid()) {
            action(reqFilterFormatDate(date));
        }
    };

    // Trigger preflight check when from and to owner are defined
    useEffect(() => {
        if (fromOwner && toOwner && !isPreflightCheckLoading) {
            batchReqOwnerUpdate(
                fromOwner?.id,
                toOwner?.id,
                fromDate,
                toDate,
                true
            ).then((res) => setPreflightBody(res));
        }
    }, [fromOwner, toOwner, fromDate, toDate]);

    const handleSubmit = () => {
        batchReqOwnerUpdate(
            fromOwner?.id,
            toOwner?.id,
            fromDate,
            toDate,
            false
        ).then((res) => res === 200 && handleClose());
    };

    return (
        <>
            <CustomDialogTitle
                onClose={handleClose}
                tooltip="Close batch change owner form"
                title="Batch Requisition Owner Change"
                subtitle={SUBTITLE}
            />
            <DialogContent
                sx={{
                    styledDialogChildSX,
                    ...objectOnCondition(isBatchReqReqOwnerUpdateLoading, {
                        pointerEvents: "none",
                        opacity: 0.5,
                    }),
                }}
            >
                <Stack spacing={2} alignContent="stretch">
                    <UserSearchAsYouType
                        selectedValue={fromOwner}
                        setSelectedValue={setFromOwner}
                        label="Transfer from"
                    />
                    <UserSearchAsYouType
                        selectedValue={toOwner}
                        setSelectedValue={setToOwner}
                        label="Transfer to"
                    />
                    <Stack spacing={3} direction="row">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="From date"
                                inputFormat="dd/MM/yyyy"
                                renderInput={(params) => (
                                    <TextField
                                        label="From date"
                                        {...params}
                                        {...freeTextFormProps}
                                    />
                                )}
                                onChange={(date) =>
                                    onDateSelect(date, setFromDate)
                                }
                                value={fromDate}
                                disableFuture
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="To date"
                                inputFormat="dd/MM/yyyy"
                                renderInput={(params) => (
                                    <TextField
                                        label="To date"
                                        {...params}
                                        {...freeTextFormProps}
                                    />
                                )}
                                onChange={(date) =>
                                    onDateSelect(date, setToDate)
                                }
                                value={toDate}
                                disableFuture
                            />
                        </LocalizationProvider>
                    </Stack>
                    {isPreflightCheckLoading ? (
                        <PreFlightCheckLoadingAlert />
                    ) : (
                        <PreFlightCheckInfoAlert
                            preflightBody={preflightBody}
                            fromOwner={fromOwner}
                            toOwner={toOwner}
                        />
                    )}
                </Stack>
            </DialogContent>
            <DialogActions sx={styledDialogChildSX}>
                <LoadingButton
                    color="success"
                    loading={isBatchReqReqOwnerUpdateLoading}
                    loadingPosition="end"
                    endIcon={<SendIcon />}
                    onClick={handleSubmit}
                    disabled={!fromOwner || !toOwner || isPreflightCheckLoading}
                    variant="contained"
                >
                    Submit
                </LoadingButton>
            </DialogActions>
        </>
    );
};

const BatchChangeReqOwnerDialog = () => {
    // Collect states and state dispatchers
    const isOpen = useSelector(
        (state) => state.general.showBatchChangeReqOwnerDialogue
    );
    const darkMode = useSelector((state) => state.general.darkMode);
    const dispatch = useDispatch();

    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    const handleClose = (e_, reason) => {
        if (reason && reason === "backdropClick") return;
        dispatch(setShowBatchChangeReqOwnerDialogue(false));
    };

    return (
        <Dialog
            fullWidth
            onClose={handleClose}
            open={isOpen}
            fullScreen={!isDesktop}
            PaperProps={{ sx: baseStyledPaperSX(darkMode) }}
        >
            <BatchChangeReqOwnerDialogueContentDispatcher />
        </Dialog>
    );
};

export default BatchChangeReqOwnerDialog;
