import { Box, Slider } from "@mui/material";
import React from "react";

// This component is required because MaterialUI's default slider will overflow the grid unless constrained
const CustomSlider = (props) => {
    return (
        <Box sx={{ px: 1 }}>
            <Slider {...props} />
        </Box>
    );
};

export default CustomSlider;
