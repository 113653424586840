import PropTypes from "prop-types";
import React from "react";

import CostCentreSearchAsYouType from "../../../../../inputs/searchAsYouType/chargeAccount/costCentreSearchAsYouType";
import SegmentHeaderField from "./segmentHeaderField";

const CostCentreHeaderField = ({ disabled, updateHeader }) => {
    return (
        <SegmentHeaderField
            disabled={disabled}
            updateHeader={updateHeader}
            segmentIdStateKey="SEGMENT2_ID"
            segmentNameStateKey="SEGMENT2_NAME"
            SegmentSearchAsYouType={CostCentreSearchAsYouType}
        />
    );
};

CostCentreHeaderField.propTypes = {
    disabled: PropTypes.any,
    updateHeader: PropTypes.func,
};

export default CostCentreHeaderField;
