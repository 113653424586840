import Typography from "@mui/material/Typography";
import DetailListVertical from "components/displays/detailListVertical";
import PropTypes from "prop-types";
import React from "react";

import {
    NON_CATALOGUE_ITEM,
    SERVICE,
} from "../../../../../constants/generalConstants";

const ItemDetails = ({ reqLine }) => {
    let itemDetails =
        reqLine?.LINE_TYPE != SERVICE
            ? [
                  {
                      header: "Unit of Measure",
                      content: reqLine.UNIT_OF_MEASURE,
                  },
              ]
            : [];

    if (reqLine.itemType === NON_CATALOGUE_ITEM) {
        !reqLine?.CPA_NUMBER &&
            itemDetails.push({ header: "Item", content: "Non Catalogue" });
        itemDetails.push({ header: "Type", content: reqLine.ITEM_TYPE });
        reqLine?.CPA_NUMBER &&
            itemDetails.push({
                header: "CPA",
                content: reqLine?.CPA_NUMBER,
            });
    } else {
        itemDetails = [
            ...itemDetails,
            { header: "BPA", content: reqLine.BPA_NUMBER },
            { header: "Item Number", content: reqLine.ITEM },
        ];
    }

    return (
        <>
            <Typography
                variant="body1"
                color="text.subheader"
                fontWeight="bold"
            >
                Item Details
            </Typography>
            <DetailListVertical condensed={true} details={itemDetails} />
        </>
    );
};

ItemDetails.propTypes = {
    reqLine: PropTypes.object,
};

export default ItemDetails;
