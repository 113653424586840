import {
    NON_CAT_BASE_RATE_VALUES,
    NON_CATALOGUE_ITEM,
    SMART_FORM,
    STOCK,
    TYPE_OPTIONS,
} from "../constants/generalConstants";

export const nonCatItemBase = {
    ...NON_CAT_BASE_RATE_VALUES,
    itemType: NON_CATALOGUE_ITEM,
    ITEM_TYPE: TYPE_OPTIONS[0],
    ITEM_DESCRIPTION: undefined,
    UNIT_PRICE: "",
    SUPPLIER_NAME: undefined,
    SUPPLIER_NUMBER_NAME: undefined,
    CPA_NUMBER: undefined,
    CPA_NUM_NAME: undefined,
    SUPPLIER_ID: undefined,
    SUPPLIER_SITE_CODE: undefined,
    SUPPLIER_SITE_ID: undefined,
    CATEGORY: undefined,
    CATEGORY_DISPLAY_NAME: undefined,
    CATEGORY_ID: undefined,
    CURRENCY_CODE: "",
    UNIT_MEAS_LOOKUP_CODE: undefined,
    UNIT_OF_MEASURE: undefined,
    QUANTITY: "",
    SUGGESTED_VENDOR_CONTACT_ID: undefined,
    SUGGESTED_VENDOR_PHONE: undefined,
    supplierItem: undefined,
    LINE_TYPE: STOCK,
    PO_LINE_ID: undefined, // Cart item ID
    ITEM_IMAGE_URL: null,
    IBUY_CART_ITEM_OU: undefined,
};

export const smartFormItemBase = {
    ...nonCatItemBase,
    itemType: SMART_FORM,
    SMART_FORM_ID: null,
    INFORMATION_TEMPLATES: [],
};

// Object showing equivalence of non catalogue cart item and req line fields. Keys
// represent fields in the non catalogue cart item schema. Values represent their
// field name in an iBuy requisition line item. All fields for non-catalogue
// items and smartforms should be listed below
export const nonCatReqLineTranslater = {
    itemType: "itemType",
    ITEM_TYPE: "ITEM_TYPE",
    ITEM_DESCRIPTION: "ITEM_DESCRIPTION",
    UNIT_PRICE: "UNIT_PRICE",
    SUPPLIER_NAME: "SUPPLIER_NAME",
    SUPPLIER_NUMBER_NAME: "SUPPLIER_NUMBER_NAME",
    SUPPLIER_ID: "SUPPLIER_ID",
    CPA_NUMBER: "CPA_NUMBER",
    CPA_NUM_NAME: "CPA_NUM_NAME",
    SUPPLIER_SITE_CODE: "SUPPLIER_SITE_CODE",
    SUPPLIER_SITE_ID: "SUPPLIER_SITE_ID",
    CATEGORY: "CATEGORY",
    CATEGORY_DISPLAY_NAME: "CATEGORY_DISPLAY_NAME",
    CATEGORY_ID: "CATEGORY_ID",
    CURRENCY_CODE: "CURRENCY_CODE",
    UNIT_MEAS_LOOKUP_CODE: "UNIT_MEAS_LOOKUP_CODE",
    UNIT_OF_MEASURE: "UNIT_OF_MEASURE",
    QUANTITY: "QUANTITY",
    RATE: "RATE",
    RATE_DATE: "RATE_DATE",
    RATE_TYPE: "RATE_TYPE",
    SUGGESTED_VENDOR_CONTACT_ID: "SUGGESTED_VENDOR_CONTACT_ID",
    SUGGESTED_VENDOR_PHONE: "SUGGESTED_VENDOR_PHONE",
    supplierItem: undefined,
    LINE_TYPE: "LINE_TYPE",
    PO_LINE_ID: "PO_LINE_ID", // Cart item ID
    ITEM_IMAGE_URL: "ITEM_IMAGE_URL",
    SMART_FORM_ID: "SMART_FORM_ID",
    INFORMATION_TEMPLATES: "INFORMATION_TEMPLATES",
};
