import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";

import { MOBILE_MEDIA_QUERY } from "../../constants/mediaQueries";
import { PRODUCT_RESULTS } from "../../constants/routes";
import { FADE_TIMEOUT } from "../../constants/transitions";
import { totalQuantity } from "../../utils/shoppingCartUtils";
import IBuyPaper from "../displays/iBuyPaper";
import { updateActiveCart } from "./../../redux/actions/generalActions";
import { contentBoxL2 } from "./../../styled/styledSXProperties/styledBoxSX";
import PageNotFound from "./../pageNotFound/pageNotFound";
import ShoppingCartItem from "./shoppingCartItem";
import ShoppingCartSummary from "./shoppingCartSummary";

const ShoppingCart = () => {
    // Collect states and state dispatchers
    const activeCart = useSelector((state) => state.general.activeCart);
    const carts = useSelector((state) => state.shoppingCart);
    const items = useSelector((state) => state.shoppingCart[activeCart].items);
    const dispatch = useDispatch();
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    // Instantiate local states used only within component
    const [pageNotFound, setPageNotFound] = useState(false);

    const { cartKey } = useParams();

    // Validate url parameters
    useEffect(() => {
        if (Object.keys(carts).includes(cartKey)) {
            setPageNotFound(false);
            dispatch(updateActiveCart(cartKey));
        } else {
            setPageNotFound(true);
        }
    }, [carts]);

    return (
        <Fade in={true} timeout={FADE_TIMEOUT}>
            <Box sx={{ ...contentBoxL2, display: "flex" }}>
                {pageNotFound ? (
                    <PageNotFound />
                ) : (
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={8} order={{ xs: 2, lg: 1 }}>
                            {totalQuantity(items) == 0 ? (
                                <IBuyPaper
                                    additionalSx={{
                                        flexDirection: "column",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Current selected shopping cart is empty.
                                    </Typography>
                                    <Link
                                        to={PRODUCT_RESULTS}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <Button
                                            variant="contained"
                                            sx={{
                                                bgcolor: "primary.main",
                                                margin: "10px 0px 0px 0px",
                                            }}
                                        >
                                            Go to Product List
                                        </Button>
                                    </Link>
                                </IBuyPaper>
                            ) : (
                                <TransitionGroup>
                                    {items.map((product, index) => (
                                        <Collapse key={index}>
                                            <ShoppingCartItem
                                                product={product}
                                            />
                                        </Collapse>
                                    ))}
                                </TransitionGroup>
                            )}
                        </Grid>
                        <Grid item xs={12} lg={4} order={{ xs: 1, lg: 2 }}>
                            <ShoppingCartSummary isDesktop={isDesktop} />
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Fade>
    );
};

export default ShoppingCart;
