// Action Types
import {
    CLEAR_POST_PO_METADATA,
    DELETE_REQ_LINE,
    DUPLICATE_LINE,
    SET_ALL_LINES,
    SET_AMENDED_REQ,
    SET_COPY_REQ,
    SET_CREATE_REQ_FORM_LOADING,
    SET_HEADER_FIELD,
    SET_HEDGING_CHECK_PASSED,
    SET_HR_ORGS_LOADING,
    SET_IS_INTERNAL_REQ,
    SET_LINE,
    SET_PAGE_NUM,
    SET_POST_PO_REQ,
    SET_REQUISITION,
    SET_SUBMIT_LOADING,
    SET_SUPPLIER_NAME,
    SET_SUPPLIER_SITE,
    SET_VALIDATED,
    SET_WHOLE_LINE,
    TOGGLE_REQ_LIST_API_REFRESH,
    UPDATE_POST_PO_METADATA,
} from "../actionTypes";
import { initialState } from "../initialState";

const createRequisitionReducer = (
    state = initialState.createRequisition,
    action
) => {
    switch (action.type) {
        case SET_ALL_LINES:
            return {
                ...state,
                iBuyRequisition: {
                    ...state.iBuyRequisition,
                    requisitionLines:
                        state.iBuyRequisition.requisitionLines.reduce(
                            (acc, line) => [
                                ...acc,
                                {
                                    ...line,
                                    [action.payload.field]:
                                        action.payload.value,
                                },
                            ],
                            []
                        ),
                },
            };
        case CLEAR_POST_PO_METADATA:
            return {
                ...state,
                postPOMetadata: [],
            };
        case DUPLICATE_LINE: {
            const duplicatedResult = {
                ...state.iBuyRequisition.requisitionLines[action.payload.index],
            };

            if (action.payload.isAmendedReq) {
                duplicatedResult.HARMONY_REQUISITION_LINE_ID = null;
                duplicatedResult.CHANGE_ACTION = "NEW";
            }

            return {
                ...state,
                iBuyRequisition: {
                    ...state.iBuyRequisition,
                    requisitionLines: [
                        ...state.iBuyRequisition.requisitionLines,
                        duplicatedResult,
                    ],
                },
            };
        }
        case SET_COPY_REQ:
            return {
                ...state,
                copyReq: action.payload,
            };
        case SET_AMENDED_REQ:
            return {
                ...state,
                amendedReq: action.payload,
            };
        case SET_POST_PO_REQ:
            return {
                ...state,
                isPostPoReq: action.payload,
            };
        case SET_HEADER_FIELD:
            return {
                ...state,
                iBuyRequisition: {
                    ...state.iBuyRequisition,
                    requisitionHeaderForm: {
                        ...state.iBuyRequisition.requisitionHeaderForm,
                        [action.payload.field]: action.payload.value,
                    },
                },
            };
        case SET_LINE:
            return {
                ...state,
                iBuyRequisition: {
                    ...state.iBuyRequisition,
                    requisitionLines:
                        state.iBuyRequisition.requisitionLines.map(
                            (line, index) =>
                                index == action.payload.index
                                    ? {
                                          ...line,
                                          [action.payload.field]:
                                              action.payload.value,
                                      }
                                    : line
                        ),
                },
            };
        case SET_PAGE_NUM:
            return {
                ...state,
                pageNum: action.payload,
            };
        case SET_REQUISITION:
            return {
                ...state,
                iBuyRequisition: action.payload,
            };
        case SET_SUBMIT_LOADING:
            return {
                ...state,
                submitLoading: action.payload,
            };
        case SET_VALIDATED:
            return {
                ...state,
                validatedPages: action.payload,
            };
        case DELETE_REQ_LINE:
            var newReqLines = [
                ...state.iBuyRequisition.requisitionLines.slice(
                    0,
                    action.payload
                ),
                ...state.iBuyRequisition.requisitionLines.slice(
                    action.payload + 1
                ),
            ];
            return {
                ...state,
                iBuyRequisition: {
                    ...state.iBuyRequisition,
                    requisitionLines: newReqLines,
                },
            };
        case SET_WHOLE_LINE:
            return {
                ...state,
                iBuyRequisition: {
                    ...state.iBuyRequisition,
                    requisitionLines:
                        state.iBuyRequisition.requisitionLines.map(
                            (line, index) =>
                                index == action.payload.index
                                    ? {
                                          ...action.payload.newLine,
                                      }
                                    : line
                        ),
                },
            };
        case SET_SUPPLIER_NAME:
            return {
                ...state,
                iBuyRequisition: {
                    ...state.iBuyRequisition,
                    supplierName: action.payload,
                },
            };
        case SET_SUPPLIER_SITE:
            return {
                ...state,
                iBuyRequisition: {
                    ...state.iBuyRequisition,
                    supplierSite: action.payload,
                },
            };
        case SET_HR_ORGS_LOADING:
            return {
                ...state,
                hrOrgsLoading: action.payload,
            };
        case SET_IS_INTERNAL_REQ:
            return {
                ...state,
                isInternalReq: action.payload,
            };
        case TOGGLE_REQ_LIST_API_REFRESH:
            return {
                ...state,
                reqListAPIRefreshKey: !state.reqListAPIRefreshKey,
            };
        case SET_CREATE_REQ_FORM_LOADING:
            return {
                ...state,
                formLoading: action.payload,
            };
        case UPDATE_POST_PO_METADATA:
            return {
                ...state,
                postPOMetadata: {
                    ...state.postPOMetadata,
                    [action.payload.key]: action.payload.value,
                },
            };
        case SET_HEDGING_CHECK_PASSED:
            return {
                ...state,
                hedgingCheckPassed: action.payload,
            };
        default:
            return state;
    }
};

export default createRequisitionReducer;
