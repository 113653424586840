import { SET_CHARGE_ACCOUNTS, SET_TASKS } from "../actionTypes";
import { initialState } from "../initialState";

const formLookupsReducer = (state = initialState.formLookups, action) => {
    switch (action.type) {
        case SET_CHARGE_ACCOUNTS: {
            return {
                ...state,
                chargeAccounts: action.payload,
            };
        }
        case SET_TASKS: {
            return {
                ...state,
                tasks: action.payload,
            };
        }
        default:
            return state;
    }
};

export default formLookupsReducer;
