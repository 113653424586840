import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import {
    TOOLTIP_HEADER_FORM_NEXT_BUTTON,
    TOOLTIP_LINE_FORM_NEXT_BUTTON,
} from "constants/tooltipText";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import alertDispatcher from "utils/alertsUtils";

import { setPageNum } from "../../../redux/actions/createRequisitionActions";

const steps = [
    "Header Information",
    "Line Item Information",
    "Review and Submit",
];

const displayMapping = {
    PROJECT_ID: "Project Number",
    TASK_ID: "Project Task",
    EXPENDITURE_ORGANIZATION_ID: "Expenditure Organisation",
    EXPENDITURE_ITEM_DATE: "Expenditure Item Date",
    EXPENDITURE_TYPE: "Expenditure Type",
};

const RequisitionDialogActions = ({
    lineFormHandleSubmit,
    isNextButtonDisabled,
    setSubmitConfirmOpen,
    handleComplete,
}) => {
    // Collect states and state dispatchers
    const pageNum = useSelector((state) => state.createRequisition.pageNum);
    const submitLoading = useSelector(
        (state) => state.createRequisition.submitLoading
    );
    const validatedPages = useSelector(
        (state) => state.createRequisition.validatedPages
    );
    const isAmendedReq = useSelector(
        (state) => state.createRequisition.amendedReq
    );
    const hedgingCheckPassed = useSelector(
        (state) => state.createRequisition.hedgingCheckPassed
    );

    const headerForm = useSelector(
        (state) => state.createRequisition.iBuyRequisition.requisitionHeaderForm
    );
    const reqLines = useSelector(
        (state) => state.createRequisition.iBuyRequisition.requisitionLines
    );

    const isPostPoReq = useSelector(
        (state) => state.createRequisition.isPostPoReq
    );

    const dispatch = useDispatch();

    const totValidatedPages = () => {
        return Object.keys(validatedPages).length;
    };

    const isLastStep = () => {
        return pageNum === steps.length - 1;
    };

    const fullyValidated = () => {
        return totValidatedPages() === steps.length;
    };

    const validateHeaderFields = () => {
        const invalidHeaders = [];
        let relevantProperties = [
            "PROJECT_ID",
            "TASK_ID",
            "EXPENDITURE_ITEM_DATE",
            "EXPENDITURE_TYPE",
            "EXPENDITURE_ORGANIZATION_ID",
        ];

        if (headerForm.ACCOUNTED_AGAINST === "PROJECT_REQ") {
            relevantProperties.forEach((property) => {
                if (
                    headerForm[property] == null ||
                    headerForm[property] == undefined
                ) {
                    invalidHeaders.push(displayMapping[property]);
                }
            });
            if (headerForm["EXPENDITURE_ITEM_DATE"] == "Invalid date") {
                invalidHeaders.push(displayMapping["EXPENDITURE_ITEM_DATE"]);
            }
        }
        return invalidHeaders;
    };

    const validateReqLines = () => {
        const invalidHeaders = [];
        let relevantProperties = [
            "TASK_ID",
            "EXPENDITURE_ITEM_DATE",
            "EXPENDITURE_TYPE",
            "EXPENDITURE_ORGANIZATION_ID",
        ];

        if (headerForm.ACCOUNTED_AGAINST === "PROJECT_REQ") {
            reqLines.forEach((line) => {
                relevantProperties.forEach((property) => {
                    if (line[property] == null || line[property] == undefined) {
                        invalidHeaders.push(displayMapping[property]);
                    }
                });
                if (line["EXPENDITURE_ITEM_DATE"] == "Invalid date") {
                    invalidHeaders.push(
                        displayMapping["EXPENDITURE_ITEM_DATE"]
                    );
                }
            });
        }
        return invalidHeaders;
    };

    const handleNextValidate = (e) => {
        e.preventDefault();
        let validateFields = [];

        if (pageNum === 0 && !isPostPoReq) {
            validateFields = validateHeaderFields();
        }
        if (pageNum === 1 && !isPostPoReq) {
            validateFields = validateReqLines();
            /// fix when target ES2015 to [...new Set(validateFields)]
            validateFields =
                reqLines.length == 1
                    ? validateFields
                    : validateFields.reduce((acc, value) => {
                          if (!acc.includes(value)) {
                              acc.push(value);
                          }
                          return acc;
                      }, []);
        }

        if (validateFields.length != 0) {
            alertDispatcher(
                "handleNextValidate",
                400,
                dispatch,
                `Please enter all project accounting information for the following fields: ${validateFields.join(
                    ", "
                )}`
            );
        } else {
            pageNum == 1
                ? lineFormHandleSubmit(() => handleNext())(e)
                : handleNext();
        }
    };

    const handleNext = () => {
        const newPageNum =
            isLastStep() && !fullyValidated()
                ? // It's the last step, but not all steps have been validatedPages,
                  // find the first step that has been validatedPages
                  steps.findIndex((step, i) => !(i in validatedPages))
                : pageNum + 1;
        dispatch(setPageNum(newPageNum));
    };

    const handleBack = () => {
        dispatch(setPageNum(pageNum - 1));
    };

    return (
        <>
            <Button
                color="secondary"
                disabled={pageNum === 0}
                startIcon={<NavigateBeforeIcon />}
                onClick={handleBack}
                sx={{ marginRight: "12px" }}
                size="medium"
                variant="outlined"
            >
                Back
            </Button>
            {pageNum === 2 ? (
                <LoadingButton
                    color="success"
                    loading={submitLoading}
                    loadingPosition="end"
                    endIcon={<SendIcon />}
                    onClick={
                        pageNum === 2
                            ? () => {
                                  isAmendedReq
                                      ? setSubmitConfirmOpen(true)
                                      : handleComplete();
                              }
                            : null
                    }
                    disabled={!hedgingCheckPassed}
                    variant="contained"
                >
                    Submit
                </LoadingButton>
            ) : (
                <Tooltip
                    title={
                        isNextButtonDisabled
                            ? pageNum == 1
                                ? TOOLTIP_LINE_FORM_NEXT_BUTTON
                                : TOOLTIP_HEADER_FORM_NEXT_BUTTON
                            : ""
                    }
                    disableInteractive
                >
                    <span>
                        <Button
                            disabled={isNextButtonDisabled}
                            color="secondary"
                            endIcon={<NavigateNextIcon />}
                            onClick={handleNextValidate}
                            size="medium"
                            variant="outlined"
                            sx={{ mr: "12px" }}
                        >
                            Next
                        </Button>
                    </span>
                </Tooltip>
            )}
        </>
    );
};

RequisitionDialogActions.propTypes = {
    lineFormHandleSubmit: PropTypes.func,
    isNextButtonDisabled: PropTypes.bool,
    setSubmitConfirmOpen: PropTypes.func,
    handleComplete: PropTypes.func,
};

export default RequisitionDialogActions;
