import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MOBILE_MEDIA_QUERY } from "../../../constants/mediaQueries";
import { setSnackbarQueue } from "../../../redux/actions/alertsActions";
import { useSnackbarConstants } from "../../../styled/constants/snackbar";
import { useSpeedDialConstants } from "../../../styled/constants/speedDial";

// Generate alert component for use in snackbar
const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertSnackbar = () => {
    // Hooks
    const snackbarConstants = useSnackbarConstants();
    const speedDialConstants = useSpeedDialConstants();

    // Collect states and dispatchers
    const snackbarQueue = useSelector((state) => state.alerts.snackbarQueue);
    const speedDialRight = useSelector((state) => state.general.speedDialRight);

    const dispatch = useDispatch();

    // Local states
    const [open, setOpen] = useState(false);
    const [messageInfo, setMessageInfo] = useState(undefined);

    useEffect(() => {
        if (snackbarQueue.length && !messageInfo) {
            // Set a new snack when we don't have an active one
            setMessageInfo({ ...snackbarQueue[0] });
            dispatch(setSnackbarQueue(snackbarQueue.slice(1)));
            setOpen(true);
        } else if (snackbarQueue.length && messageInfo && open) {
            // Close an active snack when a new one is added
            setOpen(false);
        }
    }, [snackbarQueue, messageInfo, open]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleExited = () => {
        setMessageInfo(undefined);
    };

    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    // If snackbar action is added to state management, a button is added to alert
    const actionButton = (
        <>
            {messageInfo?.action && (
                <Button
                    size="small"
                    onClick={messageInfo?.action?.action}
                    color="primary"
                    variant="outlined"
                    sx={{ mr: "6px" }}
                >
                    {messageInfo?.action?.text}
                </Button>
            )}
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    const speedDialDiameter = speedDialConstants.diameter;
    const speedDialInset = speedDialConstants.inset;
    const snackbarInset = snackbarConstants.inset;
    const margin = `calc(${speedDialInset} * 2 + ${speedDialDiameter} - ${snackbarInset})`;

    return (
        <Snackbar
            open={open}
            key={messageInfo ? messageInfo?.key : undefined}
            autoHideDuration={6000}
            onClose={handleClose}
            TransitionProps={{ onExited: handleExited }}
            anchorOrigin={{
                horizontal: !isDesktop && !speedDialRight ? "right" : "left",
                vertical: "bottom",
            }}
            sx={{
                marginLeft: !isDesktop && !speedDialRight ? margin : 0,
                marginRight: !isDesktop && speedDialRight ? margin : 0,
                pointerEvents: "none",
                "> *": {
                    pointerEvents: "auto",
                },
            }}
        >
            <Alert
                severity={messageInfo ? messageInfo?.severity : "success"}
                onClose={handleClose}
                action={actionButton}
            >
                <Typography>
                    {messageInfo ? messageInfo?.message : ""}{" "}
                </Typography>
            </Alert>
        </Snackbar>
    );
};

export default AlertSnackbar;
