// Tooltip text constants across iBuy

// Requisition Summary Table Headers
export const TOOLTIP_IBUY_REQUISITION_ID =
    "Reference number assigned by iBuy to the Requisition (Harmony allocates a corresponding requisition number).";
export const TOOLTIP_PREPARER = "The person creating the requisition in iBuy.";
export const TOOLTIP_DATE_CREATED =
    "The date on which the requisition was originally submitted in iBuy.";
export const TOOLTIP_HEADER_DESCRIPTION =
    "Summarises what the iBuy requisition is for.";
export const TOOLTIP_OU =
    "Operating Unit - the Harmony organisation that the requisition belongs to.";
export const TOOLTIP_PO_NUMBER =
    "The purchase order(s) associated with this requisition";
export const TOOLTIP_TOTAL_AMOUNT =
    "The total value of all lines on a requisition.";
export const TOOLTIP_SUPPLIER =
    "The company that the Purchase Order will be sent to.";
export const TOOLTIP_STATUS =
    "Indicates where the iBuy requisition is in the process.";
export const TOOLTIP_ACTIONS =
    "Ways that the requisition can be interacted with.";

// Receipting Summary Page
export const TOOLTIP_RECEIPTING_STATUS =
    "Indicates where the receipt is in the process.";

// PO Summary Page
export const TOOLTIP_PO_PDF_BUTTON_AVAILABLE = "Download PO PDF";
export const TOOLTIP_PO_PDF_BUTTON_NA =
    "PO not yet available to download. Either the PO approval is in process, or the PDF is still being generated.";
export const TOOLTIP_PO_SHIPMENT_DESCRIPTION =
    "A Purchase Order Shipment specifies the quantity, ship-to organisation and location, and date you want your supplier to deliver the items on a PO line.";
export const TOOLTIP_PO_DISTRIBUTION_DESCRIPTION =
    "A Purchase Order Distribution is used to allocate costs for a PO Shipment to one or multiple charge accounts, and specifies the deliver-to person.";

// Invoice Summary Page
export const TOOLTIP_INVOICE_DOCUMENT_BUTTON_NA =
    "No Invoice document linked - please view this invoice in iSupplier";
export const TOOLTIP_INVOICE_DOCUMENT_BUTTON_AVAILABLE =
    "TradEx link for Invoice";

// Requisition summary page
export const TOOLTIP_INTERNAL_REQ_DEFINITION =
    "This requisition is internally sourced, meaning all items will be supplied from stock in one of SSE's warehouses";
export const TOOLTIP_PURCHASE_REQ_DEFINITION =
    "This requisition is externally sourced, meaning all items will be supplied from a single external supplier";
export const TOOLTIP_INTERNAL_REQ_PO_COLUMN_REQ_LIST_PAGE =
    "This requisition is internally sourced, meaning all items will be supplied from stock in one of SSE's warehouses. Internal requisitions do not generate purchase orders. Instead, they generate internal sales orders - these are not managed in iBuy";
export const TOOLTIP_BASE_CURRENCY_TOGGLE = (reqCurrency, baseCurrency, ou) =>
    `This requisition was created in ${reqCurrency}. Use this switch to convert prices on this page to ${baseCurrency}, the base currency for the ${ou} operating unit`;

// Post PO amendments
export const TOOLTIP_ONE_PO_CHANGE_PER_AMENDMENT =
    "This requisition line sits on a different purchase order to the requisition line already edited. It is not possible to submit requisition amendments for multiple purchase orders at the same time. If you wish to edit this requisition line, please submit as part of a subsequent amendment after this amendment has been fully approved.";
export const TOOLTIP_UNAPPROVED_PO_AMENDMENT =
    "This requisition line cannot be amended as it is associated with an unapproved purchase order.";
export const TOOLTIP_MULTIPLE_PO_SHIPMENTS_PER_REQ_LINE =
    "This requisition line cannot be amended as it is referenced on more than one purchase order shipment";
export const TOOLTIP_PO_CLOSED =
    "This requisition line cannot be amended as its associated purchase order shipment is closed";

// Create requisition form
export const TOOLTIP_POST_PO_AMEND_HEADER_NEED_BY_DATE = (isPostPoReq) =>
    isPostPoReq
        ? "Need by date can be edited for each requisition line on page 2 - line item information. Press `Next` to navigate to this page"
        : "";
export const TOOLTIP_HEADER_FORM_NEXT_BUTTON =
    "You cannot progress until you have entered a valid charge account. To do this, click the Validate button";
export const TOOLTIP_LINE_FORM_NEXT_BUTTON =
    "You cannot progress until you have entered a valid charge account for each requisition line. To do this, expand each line by clicking the down arrow, enter an account and click `Validate`.";
export const TOOLTIP_VALIDATE_CHARGE_ACCOUNT_DISABLED_NON_PROJECT =
    "Enter a company code, cost centre and account to activate";
export const TOOLTIP_VALIDATE_CHARGE_ACCOUNT_DISABLED_PROJECT_INTERNAL =
    "Enter a project, company code and cost centre to activate";
export const TOOLTIP_VALIDATE_BUTTON_HELP_HEADER_NON_PROJECT =
    "Company code, cost centre and account combined makeup a charge account. Before submitting your order, you must confirm your selected charge account is valid by clicking 'Validate'. You can enter existing and new charge accounts through this interface. For more information, please click this icon.";
export const TOOLTIP_VALIDATE_BUTTON_HELP_HEADER_PROJECT_INTERNAL =
    "Company code, cost centre, account and project combined makeup a charge account. You are not required to select an account code for this requisition as it contains items available in SSE's warehouses (internally sourced items). Before submitting your order, you must confirm your selected charge account is valid by clicking 'Validate'. You can enter existing and new charge accounts through this interface. For more information, please click this icon.";
export const TOOLTIP_VALIDATE_BUTTON_HELP_LINE =
    "Company code, cost centre (selected on page 1) and account combined makeup a charge account. All requisition lines must be assigned the same company code and cost centre, but can have different accounts. Before submitting your order, you must confirm your selected charge account for this line is valid by clicking 'Validate'. You can enter existing and new charge accounts through this interface. For more information, please click this icon.";
// Status badge tooltips
export const TOOLTIP_APPROVED = "Requisition has been fully approved";
export const TOOLTIP_AWAITING_APPROVAL = "";
export const TOOLTIP_CANCELLED =
    "This requisition has been cancelled. No further actions can be taken on this order. See single requisition summary page for further details.";
export const TOOLTIP_CANCELLATION_SUBMITTED =
    "A cancellation has been submitted to Harmony for one or more lines of this requisition. The status of this requisition will update once the cancellation has been verified.";
export const TOOLTIP_CREATED =
    "Requisition has been successfully created in Harmony";
export const TOOLTIP_IN_PROCESS =
    "Requisition has been successfully created and is undergoing approvals";
export const TOOLTIP_INCOMPLETE = "";
export const TOOLTIP_INVALID_REQUISITION = "Invalid"; // Used for receipts and requisitions - use customTooltip prop of RequisitionStatusBadge to display Harmony error message
export const TOOLTIP_PO_APPROVAL_PENDING =
    "Requisition amendment is awaiting PO approval in Harmony";
export const TOOLTIP_PENDING =
    "Not all requisition lines have been processed by Harmony. Please check back in a few minutes"; // This status is only occurs when harmony send back fewer 201s than the total number of req lines when creating a requisition
export const TOOLTIP_SUBMITTED =
    "Requisition has been submitted to Harmony for validation. Please check back in a few minutes to confirm its status";
export const TOOLTIP_AMENDMENT_SUBMITTED =
    "Requisition amendment has been submitted to Harmony for validation";
export const TOOLTIP_FAILED =
    "Harmony unavailable, please contact iBuy administrators for support";
export const TOOLTIP_FULLY_RECEIPTED =
    "All items/services have been received in full";
export const TOOLTIP_RECEIPTING_NOT_REQUIRED =
    "This requisition is internally sourced and so does not require receipting";
export const TOOLTIP_NOT_RECEIPTED = "No items/services have been received";
export const TOOLTIP_PARTIALLY_RECEIPTED =
    "Some items/services have been received for this requisition line";
export const TOOLTIP_RECEIPTING_IN_FLIGHT =
    "Some items/services have been received for this requisition";
export const TOOLTIP_REJECTED =
    "This requisition has been rejected during its approval process. To progress this order, please amend the requisition and resubmit";
export const TOOLTIP_RETURNED =
    "This requisition has been returned. To progress this order, please review any approval notes, amend the requisition and resubmit";
export const TOOLTIP_PO_NOT_AVAILABLE =
    "A PO has not yet been generated for this requisition";
export const TOOLTIP_ATTENTION_REQUIRED = "";
export const TOOLTIP_UNKNOWN_STATUS = "";
export const TOOLTIP_CANNOT_RECEIPT_NO_PO =
    "This requisition does not have a purchase order, meaning it cannot be receipted.";

// Cart tooltips
export const TOOLTIP_DELETE_CART = "Delete item from cart";
export const TOOLTIP_EDIT_NON_CAT_ITEM = "Edit non-catalogue item";

// Cancel requisition line tooltips
export const TOOLTIP_CANNOT_CANCEL_GENERIC =
    "This requisition line cannot currently be cancelled";
export const TOOLTIP_CANNOT_CANCEL_MORE_THAN_ONE_SHIPMENT =
    "This requisition line cannot be cancelled as it sits on multiple purchase order shipments";
export const TOOLTIP_CANNOT_CANCEL_UNAPPROVED_PO =
    "This requisition line cannot be cancelled as it is on an unapproved purchase order";
export const TOOLTIP_CANNOT_CANCEL_CLOSED_LINE =
    "This requisition line cannot be cancelled as it is on a closed purchase order line";
export const TOOLTIP_CANNOT_CANCEL_CLOSED_SHIPMENT =
    "This requisition line cannot be cancelled as it is on a closed purchase order shipment";
export const TOOLTIP_CANNOT_CANCEL_LOCKED_FOR_RECEIVING =
    "This requisition line cannot be cancelled as it is currently being receipted - please try again in a few minutes. Note requisition lines which have been part invoiced or received cannot be cancelled. Any remaining balance on this line can be removed by closing this form and clicking 'Amend'";
export const TOOLTIP_CANNOT_CANCEL_MORE_THAN_ONE_DIST =
    "This requisition line cannot be cancelled as it sits on a purchase order with an invalid structure (it has a shipment that has multiple distributions)";
export const TOOLTIP_CANNOT_CANCEL_PART_RECEIVED =
    "This requisition line cannot be cancelled as some of its items have been received. Any remaining balance on this line can be removed by closing this form and clicking 'Amend'";
export const TOOLTIP_CANNOT_CANCEL_PART_INVOICED =
    "This requisition line cannot be cancelled as some of its items have been invoiced. Any remaining balance on this line can be removed by closing this form and clicking 'Amend'";

// Approval Chips
export const TOOLTIP_APPROVAL_CHIP_AMENDED =
    "This is an approval for a requisition amendment";
export const TOOLTIP_APPROVAL_CHIP_DELEGATED =
    "This is an approval that has been delegated to you";

// Receipting tooltips
export const TOOLTIP_WHAT_IS_CORRECTION =
    "What is a correction? A correction allows you to make changes to your receipt should you have entered the wrong value when creating it. You can increase or decrease the quantity received using a correction. A correction will trigger an update to the associated purchase order";

export const TOOLTIP_WHAT_IS_RETURN =
    "What is a return? A return should be used to return faulty goods back to a supplier. You can only return goods that have already been received. A return will also trigger an update to the associated purchase order";
