import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import MobileSpeedDial from "components/navigation/speedDial";
import SearchBarMobile from "components/searchBar/searchBarMobile.mobile";
import OnDesktop from "components/wrappers/onDesktop";
import OnMobile from "components/wrappers/onMobile";
import { MOBILE_MEDIA_QUERY } from "constants/mediaQueries";
import React from "react";
import { useDispatch } from "react-redux";

import NavigationBar from "./components/navigation/navigationBar";
import SearchBar from "./components/searchBar/searchBar";
import IBuyRouter from "./IBuyRouter";
import { setIsScrolling } from "./redux/actions/generalActions";
import {
    contentBoxL1,
    contentBoxL1Mobile,
} from "./styled/styledSXProperties/styledBoxSX";

const PageWrapper = () => {
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    // Collect states and state dispatchers
    const dispatch = useDispatch();

    const catchScroll = (e) =>
        e.target.scrollTop > 14
            ? dispatch(setIsScrolling(true))
            : dispatch(setIsScrolling(false));

    return (
        <>
            <OnDesktop>
                <NavigationBar />
            </OnDesktop>
            <OnMobile>
                <MobileSpeedDial />
            </OnMobile>

            <Box
                id="scrollableBox"
                onScrollCapture={catchScroll}
                sx={
                    isDesktop
                        ? contentBoxL1
                        : { ...contentBoxL1Mobile, overflowY: "auto" }
                }
            >
                <OnDesktop>
                    <SearchBar />
                </OnDesktop>
                <OnMobile>
                    <SearchBarMobile />
                </OnMobile>

                <IBuyRouter />
            </Box>
        </>
    );
};

export default PageWrapper;
