import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { freeTextFormProps } from "../../../../styled/props/textFieldProps";
import AlertBanner from "../../../displays/alertBanner";

const PostPoBanner = ({ onChange }) => {
    // Collect states and state dispatchers
    const headerForm = useSelector(
        (state) => state.createRequisition.iBuyRequisition.requisitionHeaderForm
    );

    return (
        <AlertBanner
            severity={"warning"}
            title={
                "A purchase order has already been approved for this requisition."
            }
            description={
                "Only a limited amount of fields may be amended and a reason MUST be provided. Amendments can be made to need by dates and quantities (or amounts). Press `Next` to navigate to page 2 to make amendments."
            }
            content={
                <TextField
                    {...freeTextFormProps}
                    label="Amendment Reason"
                    multiline
                    onChange={onChange}
                    placeholder="Amendment Reason"
                    value={headerForm?.REASON ? headerForm?.REASON : ""}
                />
            }
        />
    );
};

PostPoBanner.propTypes = {
    onChange: PropTypes.func,
};

export default PostPoBanner;
