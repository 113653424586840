import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useIsMount from "hooks/useIsMount";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getOuBaseCurrency } from "utils/currencyUtils";

import {
    AUTOCOMPLETE_API_MIN_CHARS,
    CORPORATE_RATE,
    EDIT_NON_CAT_CART_ITEM,
    EDIT_NON_CAT_REQ_LINE,
    NEW_NON_CAT_ITEM,
    NEW_SMART_FORM_ITEM,
    NON_CAT_BASE_RATE_VALUES,
    NON_CATALOGUE_ITEM,
    SMART_FORM,
    TYPE_OPTIONS,
} from "../../../constants/generalConstants";
import { decimalNumberRegex } from "../../../constants/patterns";
import { EditInformationTemplateContext } from "../../../hooks/contexts";
import useThrottle from "../../../hooks/useThrottle";
import { setProduct } from "../../../redux/actions/nonCatFormActions";
import { cpaAutocompleteGet } from "../../../services/search/searchAPIs";
import {
    getSupplierContactDetails,
    getSupplierSiteDetails,
    supplierAutocompleteGet,
} from "../../../services/supplier/supplierAPIs";
import { onNonCatItemTypeChange } from "../../../utils/nonCatFormUtils";
import AddToCartButton from "../../buttons/addToCartButton";
import UpdateCartItemButton from "../../buttons/updateCartItemButton";
import UpdateRequisitionLineButton from "../../buttons/updateRequisitionLineButton";
import AttachmentsUpload from "../../inputs/attachmentsUpload";
import ValidatedAutocomplete from "../../inputs/validatedAutoComplete";
import { gridColumnProps } from "../../layouts/gridLayout";
import NonCatalogueFormSkeleton from "../../skeletons/nonCatalogueFormSkeleton";
import InformationTemplates from "../smartForms/informationTemplate/informationTemplates";
import { autoCompleteProps } from "./../../../styled/props/autocompleteProps";
import { freeTextFormProps } from "./../../../styled/props/textFieldProps";
import {
    initialCategoryInfo,
    initialContactDisabled,
    initialContactInfo,
    initialCpasDisabled,
    initialCpasLoading,
    initialCpasNoOptionsText,
    initialCpasOptions,
    initialCpasTextInput,
    initialCpasValue,
    initialItemType,
    initialSitesDisabled,
    initialSupplierNameOptions,
    initialSupplierNamesDisabled,
    initialSupplierNamesLoading,
    initialSupplierNameTextInput,
    initialSupplierNameValue,
    initialSupplierNoOptionsText,
    initialSupplierSiteValue,
    initialUOMValue,
} from "./formStateSetters";
import NonCatalogueCurrencySelection from "./nonCatalogueCurrencySelection";

const MAX_CATEGORIES = 100;

export const supplierHelpText = `Search for a supplier by typing the supplier's name or number. As you type, options will be generated to select from. A maximum of 20 options will be displayed at once - if you don't see the supplier you want, keep typing to further truncate the options! A minimum of ${AUTOCOMPLETE_API_MIN_CHARS} characters are required to start the search`;
export const cpaHelperText =
    "Search for a CPA by typing the either the CPA Number or the supplier's name. As you type, options will be generated to select from. A maximum of 30 options will be displayed at once - if you don't see the supplier you want, keep typing to further truncate the options! A minimum of 3 characters are required to start the search";
const noOptionsText = "No options matching your search";
export const min3CharactersText =
    "Enter a minimum of 3 characters to begin search";
export const startNewSearchText = "Clear input to start a new search";

export const nonCatFormGridColumnProps = {
    ...gridColumnProps,
    sm: 12,
    md: 6,
    lg: 6,
};

const qtyLabelDispatcher = {
    [TYPE_OPTIONS[0]]: "Qty",
    [TYPE_OPTIONS[1]]: "Quantity",
    [TYPE_OPTIONS[2]]: "Total Amount",
};

const qtyPlaceholderDispatcher = {
    [TYPE_OPTIONS[0]]: "Enter quantity...",
    [TYPE_OPTIONS[1]]: "Enter number of units...",
    [TYPE_OPTIONS[2]]: "Enter total amount...",
};

const unitPriceLabelDispatcher = {
    [TYPE_OPTIONS[0]]: "Unit Price",
    [TYPE_OPTIONS[1]]: "Rate Per Unit",
    [TYPE_OPTIONS[2]]: "",
};

const unitPricePlaceholderDispatcher = {
    [TYPE_OPTIONS[0]]: "Enter unit price...",
    [TYPE_OPTIONS[1]]: "Enter rate per unit...",
    [TYPE_OPTIONS[2]]: "",
};

const NonCatalogueForm = ({ copyReq, informationTemplate }) => {
    // ***************** State Instantiation *****************
    // Collect states and state dispatchers. Local use states should not be set
    // in this component. They must be set is /nonCatalogueForm/formStateSetters
    const dispatch = useDispatch();
    const throttleFunction = useThrottle();
    const [attachments, setAttachments] = useState([]);
    const activeOU = useSelector((state) => state.auth.activeOU);
    // Temporarily remove GEN/GENIRE categories, which are not required for MVP
    const categories = useSelector((state) =>
        state.filterPanelLookups.CATEGORY_DISPLAY.filter(
            (cat) => cat.CATEGORY_DISPLAY_NAME[0] != "G"
        )
    );

    const product = useSelector((state) => state.nonCatForm.product);

    const ouBaseCurrency = useSelector((state) =>
        getOuBaseCurrency(
            product?.IBUY_CART_ITEM_OU,
            state.filterPanelLookups.BASE_CURRENCIES
        )
    );

    const isAmendedReq = useSelector(
        (state) => state.createRequisition.amendedReq
    );
    const unitOfMeasures = useSelector(
        (state) => state.filterPanelLookups.UNIT_OF_MEASURES
    );
    const operatingMode = useSelector((state) => state.nonCatForm.mode);
    const context = useSelector((state) => state.nonCatForm.context);
    const supplierSites = useSelector(
        (state) => state.nonCatForm.supplierSites
    );
    const reqLineIndex = useSelector((state) => state.nonCatForm.reqLineIndex);
    const supplierContactDetails = useSelector(
        (state) => state.nonCatForm.supplierContactDetails
    );
    const sitesInitialLoading = useSelector(
        (state) => state.nonCatForm.sitesInitialLoading
    );
    const contactsInitialLoading = useSelector(
        (state) => state.nonCatForm.contactsInitialLoading
    );
    const informationTemplatesLoading = useSelector(
        (state) => state.nonCatForm.informationTemplatesLoading
    );
    const activeSmartForm = useSelector(
        (state) => state.general.activeSmartForm
    );
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
    } = useForm({
        mode: "onChange",
    });

    // Local states for inputs. Each has its own predefined setter stored in
    // "./formStateSetters.js" responsible for setting the initial state,
    // based on the the mode in which the non-catalogue form is being used.
    // When adding a new local state, create a new setter function and ensure
    // all operating modes are accounted for.
    const [categoryInfo, setCategoryInfo] = useState(
        initialCategoryInfo(operatingMode, product, setValue)
    );
    const [unitOfMeasureValue, setUnitOfMeasureValue] = useState(
        initialUOMValue(operatingMode, product, setValue)
    );
    const [contactDisabled, setContactDisabled] = useState(
        initialContactDisabled(operatingMode, product)
    );
    const [contactInfo, setContactInfo] = useState(
        initialContactInfo(operatingMode, product, supplierContactDetails)
    );
    const [nonCatItemType, setNonCatItemType] = useState(
        initialItemType(operatingMode, product)
    );
    const [sitesDisabled, setSitesDisabled] = useState(
        initialSitesDisabled(operatingMode, product)
    );

    const [supplierNamesDisabled, setSupplierNamesDisabled] = useState(
        initialSupplierNamesDisabled(operatingMode, product)
    );

    const [supplierNamesLoading, setSupplierNamesLoading] = useState(
        initialSupplierNamesLoading(operatingMode)
    );
    const [supplierNameOptions, setSupplierNameOptions] = useState(
        initialSupplierNameOptions(operatingMode, product)
    );
    const [supplierNameTextInput, setSupplierNameTextInput] = useState(
        initialSupplierNameTextInput(operatingMode, product)
    );
    const [supplierNoOptionsText, setSupplierNoOptionsText] = useState(
        initialSupplierNoOptionsText(operatingMode, product)
    );

    const [cpasLoading, setCpasLoading] = useState(
        initialCpasLoading(operatingMode)
    );

    const [cpasOptions, setCpasOptions] = useState(
        initialCpasOptions(operatingMode, product)
    );
    const [cpasTextInput, setCpasTextInput] = useState(
        initialCpasTextInput(operatingMode, product)
    );
    const [cpasValue, setCpasValue] = useState(
        initialCpasValue(operatingMode, product)
    );
    const [cpasNoOptionsText, setCpasNoOptionsText] = useState(
        initialCpasNoOptionsText(operatingMode, product)
    );

    const [cpasDisabled, setCpasDisabled] = useState(
        initialCpasDisabled(operatingMode, product)
    );

    const [supplierNameValue, setSupplierNameValue] = useState(
        initialSupplierNameValue(operatingMode, product, supplierNameOptions)
    );
    const [supplierSiteValue, setSupplierSiteValue] = useState(
        initialSupplierSiteValue(operatingMode, product)
    );

    const isFirstRender = useIsMount();

    // Preset unit of measure and qty for goods and services request by total amount
    useEffect(() => {
        !isFirstRender &&
            onNonCatItemTypeChange(
                dispatch,
                setValue,
                setUnitOfMeasureValue,
                nonCatItemType,
                product
            );
    }, [nonCatItemType]);

    // Description Ref
    const { ref: desRef, ...descInputProps } = register("description", {
        onChange: (e) => updateField(e.target.value, "ITEM_DESCRIPTION"),
        required: "Description is required",
    });
    // Supplier Site Ref
    const { ref: supplierSiteIdRef, ...supplierSiteInputProps } = register(
        "suppliersite",
        {
            // onChange: (e) => updateField(e.target.value, "SUPPLIER_SITE_ID"),
            required:
                !sitesDisabled &&
                supplierNameValue?.SUPPLIER_NUMBER_NAME &&
                !supplierSiteValue?.SUPPLIER_SITE_ID
                    ? "Supplier site is required"
                    : null,
        }
    );
    const { ref: supplierIdRef, ...supplierInputProps } = register(
        "supplierId",
        {
            onChange: (e) => updateField(e.target.value, "SUPPLIER_SITE_ID"),
            required:
                !sitesDisabled &&
                supplierNameValue?.SUPPLIER_NUMBER_NAME &&
                !supplierSiteValue?.SUPPLIER_SITE_ID
                    ? "Supplier site is required"
                    : null,
        }
    );
    // Quantity
    const { ref: qtyRef, ...qtyInputProps } = register("qty", {
        onChange: (e) => updateField(e.target.value, "QUANTITY"),
        required: `${
            nonCatItemType == TYPE_OPTIONS[2] ? "Amount" : "Quantity"
        } is required`,
        pattern: {
            value: decimalNumberRegex,
            message: "Invalid Number Input",
        },
    });
    // Unit of measure
    const { ref: catRef, ...catInputProps } = register("cat", {
        required: "Category is required",
    });
    // // Category
    const { ref: uomRef, ...uomInputProps } = register("uom", {
        required:
            nonCatItemType == TYPE_OPTIONS[2]
                ? false
                : "Unit of Measure is required",
    });
    // Unit price
    const { ref: unitPriceRef, ...unitPriceInputProps } = register(
        "unitPrice",
        {
            onChange: (e) => updateField(e.target.value, "UNIT_PRICE"),
            required: `${
                nonCatItemType == TYPE_OPTIONS[1] ? "Unit rate" : "Unit price"
            } is required`,
            pattern: {
                value: decimalNumberRegex,
                message: "Invalid Number Input",
            },
        }
    );
    // Contact number
    const { ref: phoneRef, ...phoneInputProps } = register("phone", {
        onChange: (e) =>
            setContactInfo({
                ...contactInfo,
                title: contactInfo?.title ? contactInfo.title : null,
                PHONE: e.target.value,
            }),
        pattern: {
            value: /[0-9 ]{5,15}$/,
            message: "Invalid Phone Number Input - min 5 digits required",
        },
    });

    // On page load, the default quantity value is 1. This must be preset in the form register.
    // TODO - determine if this can be read automatically like free text fields
    useEffect(() => {
        setValue("description", product?.ITEM_DESCRIPTION);
        setValue("unitPrice", product?.UNIT_PRICE);
    }, []);

    // Update attachments
    useEffect(() => {
        dispatch(
            setProduct({
                ...product,
                INFORMATION_TEMPLATES: attachments,
            })
        );
    }, [attachments]);

    useEffect(() => {
        // If no input, provided, or less than 3 characters input, API not called
        if (!isFirstRender) {
            throttleFunction(() => {
                if (
                    supplierNameTextInput === "" ||
                    supplierNameTextInput.length < AUTOCOMPLETE_API_MIN_CHARS
                ) {
                    setSupplierNameOptions([]);
                    setSupplierNoOptionsText(min3CharactersText);
                    return undefined;
                }

                // Else, call supplier name API
                supplierNameValue
                    ? setSupplierNoOptionsText(startNewSearchText)
                    : setSupplierNoOptionsText(noOptionsText);
                supplierAutocompleteGet(
                    dispatch,
                    supplierNameTextInput,
                    setSupplierNameOptions,
                    supplierNameValue,
                    setSupplierNamesLoading,
                    activeSmartForm?.USE_APPROVED_SUPPLIERS,
                    {
                        OU: product ? product.IBUY_CART_ITEM_OU : activeOU,
                        CATRGORY_ID: product?.CATEGORY_ID,
                    }
                );
            });
        }
    }, [supplierNameValue, supplierNameTextInput]);

    // Manage calling of CPA autocomplete API
    useEffect(() => {
        if (!isFirstRender) {
            throttleFunction(() => {
                if (
                    cpasTextInput === "" ||
                    cpasTextInput.length < AUTOCOMPLETE_API_MIN_CHARS
                ) {
                    setCpasOptions([]);
                    setCpasNoOptionsText(min3CharactersText);
                    return undefined;
                }

                cpasValue
                    ? setCpasNoOptionsText(startNewSearchText)
                    : setCpasNoOptionsText(noOptionsText);
                cpaAutocompleteGet(
                    dispatch,
                    product?.IBUY_CART_ITEM_OU,
                    cpasTextInput,
                    setCpasOptions,
                    cpasValue,
                    setCpasLoading
                );
            });
        }
    }, [cpasValue, cpasTextInput]);

    // Filter options to improve performance of category selection
    const defaultFilterOptions = createFilterOptions();
    const catFilterOptions = (options, state) => {
        return defaultFilterOptions(options, state).slice(0, MAX_CATEGORIES);
    };

    const selectCategory = (_event, value) => {
        /*
        Arguments
        ---------
        event : obj
        value : string
        */
        setCategoryInfo(value);
        dispatch(
            setProduct({
                ...product,
                CATEGORY: value.CATEGORY,
                CATEGORY_ID: value.CATEGORY_ID,
                CATEGORY_DISPLAY_NAME: value.CATEGORY_DISPLAY_NAME,
            })
        );
    };

    const selectUnitOfMeasure = (_event, value) => {
        /*
        Arguments
        ---------
        event : obj
        value : string
        */
        setUnitOfMeasureValue(value);
        dispatch(
            setProduct({
                ...product,
                UNIT_OF_MEASURE: value.UNIT_OF_MEASURE,
                UNIT_MEAS_LOOKUP_CODE: value.UNIT_MEAS_LOOKUP_CODE,
            })
        );
    };

    const selectSupplierName = (_event, newValue) => {
        /*
        Arguments
        ---------
        event : obj
        newValue : string

        Notes
        -----
        It is assumed newValue is null if the user has opted to clear the autocomplete.
        */
        // Update options list to include the selection. Nullify if newValue is null
        setSupplierNameOptions(
            newValue ? [newValue, ...supplierNameOptions] : []
        );
        // Update value and set product states
        // TODO - refactor management of supplier naming
        setSupplierNameValue(newValue);
        if (!newValue) {
            dispatch(
                setProduct({
                    ...product,
                    SUPPLIER_NAME: null,
                    SUPPLIER_NUMBER_NAME: null,
                    SUPPLIER_ID: null,
                })
            );
        } else {
            dispatch(
                setProduct({
                    ...product,
                    SUPPLIER_NAME: newValue?.SUPPLIER_NUMBER_NAME,
                    SUPPLIER_NUMBER_NAME: newValue?.SUPPLIER_NUMBER_NAME,
                    SUPPLIER_ID: newValue?.SUPPLIER_ID,
                })
            );
        }
        // Nullify CPA input
        setCpasValue(null);
        if (newValue == null) {
            // If a clear event
            // Enable CPAs
            setCpasDisabled(false);
        } else {
            // If not a clear event
            // Call supplier site API if this is not a clear event
            getSupplierSiteDetails(
                dispatch,
                product?.IBUY_CART_ITEM_OU,
                newValue?.SUPPLIER_ID,
                setSitesDisabled,
                setContactDisabled
            );
            // Disable CPAs
            setCpasDisabled(true);
        }
    };

    const selectSupplierSite = (_event, value) => {
        /*
        Arguments
        ---------
        event : obj
        value : string
        */
        // Update states
        getSupplierContactDetails(
            dispatch,
            value.SUPPLIER_SITE_ID,
            setContactDisabled,
            setContactInfo
        );
        // Update site alue state and non-cat product state
        setSupplierSiteValue(value);
        dispatch(
            setProduct({
                ...product,
                ...value,
            })
        );
    };

    const selectSupplierContact = (_event, value) => {
        /*Test if user defined name, or preselected. `value` is an
        object is the user selected a pre-defined name

        Three entry routes:
        supplierContactDetails.length > 0 :  there are named supplier contacts. User is forced
        to select one of them. Value is an object. Contact info is set to value.

        value === "string" : the input is in freetext mode as  supplierContactDetails.length == 0.
        User is allowed to input a supplier name

        supplierContactDetails.length == 0 && !value :  the input is in freetext mode as
        upplierContactDetails.length == 0. The user has cleared the supplier name input.
        Clearing is handled different by MUI

        */
        typeof value === "string" ||
        (supplierContactDetails.length == 0 && !value)
            ? setContactInfo({
                  ...contactInfo,
                  title: value,
                  SUPPLIER_CONTACT_ID: null,
              })
            : setContactInfo(value);
    };

    /**
     * Handles logic applied when a user selects / clears a CPA.
     * If the currency of the CPA is not equal to OU base currency,
     * then rate metadata is injected into the  `product` payload.
     * It is assumed newValue is null if the user has opted to clear
     * the autocomplete.
     * @param {Object} _event
     * @param {{id, CPA_NUM_NAME,CURRENCY_CODE}} newValue - value selected in autocomplete
     */
    const selectCpa = (_event, newValue) => {
        // Update options list to include the selection. Nullify if newValue is null
        setCpasOptions(newValue ? [newValue, ...cpasOptions] : []);

        // If newValue is null, this is a clear event
        const isClearEvent = !newValue;

        // Update value and set product states. If it is a clear event, the
        // currency state is reset back to base for the form
        setCpasValue(newValue);
        const cpaCurrencyEqOUBase = newValue?.CURRENCY_CODE === ouBaseCurrency;
        const newRateDate =
            cpaCurrencyEqOUBase || isClearEvent
                ? NON_CAT_BASE_RATE_VALUES.RATE_DATE
                : new Date();
        dispatch(
            setProduct({
                ...product,
                CPA_NUMBER: newValue?.id,
                CPA_NUM_NAME: newValue?.CPA_NUM_NAME,
                CURRENCY_CODE: newValue?.CURRENCY_CODE
                    ? newValue?.CURRENCY_CODE
                    : ouBaseCurrency,
                RATE_TYPE:
                    cpaCurrencyEqOUBase || isClearEvent
                        ? NON_CAT_BASE_RATE_VALUES.RATE_TYPE
                        : CORPORATE_RATE,
                RATE_DATE: newRateDate,
            })
        );
        setValue("rateDate", newRateDate);

        // Nullify supplier name value
        setSupplierNameValue(null);
        // If a clear event, enable supplier name input
        isClearEvent
            ? setSupplierNamesDisabled(false)
            : setSupplierNamesDisabled(true);
    };

    useEffect(() => {
        if (!isFirstRender) {
            // On supplier name change, nullify site input and states
            setSupplierSiteValue(null);
            setSitesDisabled(true);
            setContactDisabled(true);
            setContactInfo(null);
            dispatch(
                setProduct({
                    ...product,
                    SUPPLIER_SITE_CODE: null,
                    SUPPLIER_SITE_ID: null,
                })
            );
        }
    }, [supplierNameValue]);

    useEffect(() => {
        // On supplier site change, disable contact related states
        !isFirstRender && setContactDisabled(true);
    }, [supplierSiteValue]);

    useEffect(() => {
        // On contact info change, update non-cat product state
        !isFirstRender &&
            dispatch(
                setProduct({
                    ...product,
                    SUGGESTED_VENDOR_PHONE: contactInfo?.PHONE,
                    SUGGESTED_VENDOR_CONTACT_ID:
                        contactInfo?.SUPPLIER_CONTACT_ID,
                    CONTACT_NAME: contactInfo?.title,
                })
            );
    }, [contactInfo]);

    const updateField = (value, field) => {
        /*
        Arguments
        ---------
        value : string
        field : string
        */
        dispatch(
            setProduct({
                ...product,
                [field]: value,
            })
        );
    };

    const isFormPreLoading = () =>
        [
            EDIT_NON_CAT_CART_ITEM,
            EDIT_NON_CAT_REQ_LINE,
            NEW_SMART_FORM_ITEM,
        ].includes(context) &&
        (!!sitesInitialLoading ||
            !!informationTemplatesLoading ||
            !!contactsInitialLoading);

    // Contact info is the only local state relying on async page
    // preloading API calls. Once contacts have been loaded, this
    // use effect is load to update the state.
    useEffect(() => {
        if (
            context == EDIT_NON_CAT_REQ_LINE ||
            context == NEW_SMART_FORM_ITEM
        ) {
            setContactInfo(
                initialContactInfo(
                    operatingMode,
                    product,
                    supplierContactDetails
                )
            );
        }
    }, [contactsInitialLoading]);

    /**
     * If the form is being used in smart-forms mode, tests
     * if a given inoput field should be disabled.
     * @param {*} fieldName - field being disabled
     * @returns
     */
    const isSmartFormDisabled = (fieldName) => {
        return (
            context == NEW_SMART_FORM_ITEM &&
            activeSmartForm &&
            activeSmartForm?.[`${fieldName}_EDITABLE_FLAG`] &&
            activeSmartForm[`${fieldName}_EDITABLE_FLAG`].toUpperCase() == "NO"
        );
    };

    /**
     * If approved supplier list is to be used, enforces that a user
     * can only select a supplier if category ID has been input
     */
    const isSupplierNameDisabled = () => {
        if (activeSmartForm?.USE_APPROVED_SUPPLIERS) {
            return isSmartFormDisabled("SUPPLIER") && product?.CATEGORY_ID;
        } else {
            return isSmartFormDisabled("SUPPLIER");
        }
    };

    const updateFileAttachments = (attachments) => {
        setAttachments((oldAttachments) => {
            const nonFileAttachments = [
                ...oldAttachments.filter((attachment) => !attachment?.FILENAME),
            ];
            return [...nonFileAttachments, ...attachments];
        });
    };

    const updateInformationTemplateField = (attachmentField) => {
        setAttachments((oldAttachments) => {
            let hasUpdated = false;
            let newAttachments = [...oldAttachments];
            for (var i = 0; i < newAttachments.length; i++) {
                if (
                    JSON.stringify({
                        ...newAttachments[i],
                        ATTACHMENT_TEXT: null,
                    }) ===
                    JSON.stringify({
                        ...attachmentField,
                        ATTACHMENT_TEXT: null,
                    })
                ) {
                    newAttachments[i] = attachmentField;
                    hasUpdated = true;
                    break;
                }
            }

            if (!hasUpdated) {
                newAttachments.push(attachmentField);
            }
            return newAttachments;
        });
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Grid container spacing={2}>
                {isFormPreLoading() ? (
                    <NonCatalogueFormSkeleton />
                ) : (
                    <>
                        <Grid {...nonCatFormGridColumnProps}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="text.subheader">
                                    General Details
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ValidatedAutocomplete
                                    label="Item Type"
                                    placeholder="Select Type..."
                                    onChange={(_e, v) => setNonCatItemType(v)}
                                    options={TYPE_OPTIONS}
                                    value={nonCatItemType}
                                    disabled={isAmendedReq ? true : false}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    {...freeTextFormProps}
                                    label="Item Description (Max 240 characters)"
                                    multiline
                                    placeholder="Description..."
                                    required
                                    value={product.ITEM_DESCRIPTION}
                                    inputRef={desRef}
                                    {...descInputProps}
                                    error={!!errors.description}
                                    helperText={errors?.description?.message}
                                    disabled={isSmartFormDisabled("DESC")}
                                    inputProps={{ maxLength: 240 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    {...autoCompleteProps}
                                    onChange={selectCategory}
                                    getOptionLabel={(option) =>
                                        option.CATEGORY_DISPLAY_NAME
                                    }
                                    options={categories}
                                    value={categoryInfo}
                                    filterOptions={catFilterOptions}
                                    isOptionEqualToValue={(option, value) =>
                                        option.CATEGORY_ID == value.CATEGORY_ID
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            {...freeTextFormProps}
                                            label="Category"
                                            placeholder="Type to search for category..."
                                            required
                                            {...catInputProps}
                                            inputRef={catRef}
                                            error={!!errors.cat}
                                            helperText={errors?.cat?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            {!copyReq && (
                                <Grid item xs={6}>
                                    <TextField
                                        {...freeTextFormProps}
                                        label={
                                            qtyLabelDispatcher[nonCatItemType]
                                        }
                                        placeholder={
                                            qtyPlaceholderDispatcher[
                                                nonCatItemType
                                            ]
                                        }
                                        required
                                        value={`${
                                            product?.QUANTITY
                                                ? product?.QUANTITY
                                                : ""
                                        }`}
                                        {...qtyInputProps}
                                        inputRef={qtyRef}
                                        error={!!errors.qty}
                                        helperText={errors?.qty?.message}
                                    />
                                </Grid>
                            )}
                            {nonCatItemType != TYPE_OPTIONS[2] && (
                                <>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            {...autoCompleteProps}
                                            onChange={selectUnitOfMeasure}
                                            getOptionLabel={(option) =>
                                                option.UNIT_OF_MEASURE
                                            }
                                            options={unitOfMeasures}
                                            value={unitOfMeasureValue}
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) =>
                                                option.UNIT_MEAS_LOOKUP_CODE ==
                                                value.UNIT_MEAS_LOOKUP_CODE
                                            }
                                            disabled={isSmartFormDisabled(
                                                "UOM"
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    {...freeTextFormProps}
                                                    label="Unit of Measure"
                                                    placeholder="Type Unit of Measure..."
                                                    required
                                                    {...uomInputProps}
                                                    inputRef={uomRef}
                                                    error={!!errors.uom}
                                                    helperText={
                                                        errors?.uom?.message
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...freeTextFormProps}
                                            label={
                                                unitPriceLabelDispatcher[
                                                    nonCatItemType
                                                ]
                                            }
                                            placeholder={
                                                unitPricePlaceholderDispatcher[
                                                    nonCatItemType
                                                ]
                                            }
                                            required
                                            value={product.UNIT_PRICE}
                                            inputRef={unitPriceRef}
                                            {...unitPriceInputProps}
                                            error={!!errors.unitPrice}
                                            helperText={
                                                errors?.unitPrice?.message
                                            }
                                            disabled={isSmartFormDisabled(
                                                "PRICE_EDITABLE_FLAG"
                                            )}
                                        />
                                    </Grid>
                                </>
                            )}
                            <NonCatalogueCurrencySelection
                                cpaSelected={!!product?.CPA_NUMBER}
                                ouBaseCurrency={ouBaseCurrency}
                                register={register}
                                errors={errors}
                                control={control}
                                setValue={setValue}
                            />
                            <Grid item xs={12}>
                                <AttachmentsUpload
                                    setStateCallback={updateFileAttachments}
                                />
                            </Grid>
                        </Grid>
                        <Grid {...nonCatFormGridColumnProps}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="text.subheader">
                                    Enter CPA or Supplier Search
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    Note: You can enter either a CPA Number or
                                    Supplier Details below, but not both. Either
                                    field will be disabled if the other has been
                                    filled with a value.
                                </Typography>
                            </Grid>
                            <Grid item xs={11}>
                                <Autocomplete
                                    autoComplete
                                    disabled={
                                        isSmartFormDisabled("SUPPLIER") ||
                                        cpasDisabled
                                    }
                                    loading={cpasLoading}
                                    filterSelectedOptions
                                    getOptionLabel={(option) =>
                                        option.CPA_NUM_NAME
                                    }
                                    noOptionsText={cpasNoOptionsText}
                                    options={cpasOptions}
                                    onChange={selectCpa}
                                    onInputChange={(_event, newInputValue) => {
                                        setCpasTextInput(newInputValue);
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                        option.CPA_NUM_NAME ==
                                        value.CPA_NUM_NAME
                                    }
                                    value={cpasValue}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            {...freeTextFormProps}
                                            label="CPA Number - Supplier Name"
                                            placeholder={
                                                cpasDisabled &&
                                                !isSmartFormDisabled("SUPPLIER")
                                                    ? "Clear supplier name input to enable"
                                                    : "Search on CPA number or supplier name - 3 characters minimum required..."
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip
                                    title={cpaHelperText}
                                    placement="top-start"
                                >
                                    <IconButton
                                        onClick={null}
                                        sx={{ ml: "-8px" }}
                                    >
                                        <HelpOutlineRoundedIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={11}>
                                <Autocomplete
                                    autoComplete
                                    disabled={
                                        isSupplierNameDisabled() ||
                                        supplierNamesDisabled
                                    }
                                    loading={supplierNamesLoading}
                                    filterSelectedOptions
                                    getOptionLabel={(option) =>
                                        option.SUPPLIER_NUMBER_NAME
                                    }
                                    noOptionsText={supplierNoOptionsText}
                                    options={supplierNameOptions}
                                    onChange={selectSupplierName}
                                    onInputChange={(_event, newInputValue) => {
                                        setSupplierNameTextInput(newInputValue);
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                        option.SUPPLIER_NUMBER_NAME ==
                                        value.SUPPLIER_NUMBER_NAME
                                    }
                                    value={supplierNameValue}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            {...freeTextFormProps}
                                            inputRef={supplierIdRef}
                                            label="Supplier Number - Name"
                                            placeholder={
                                                supplierNamesDisabled
                                                    ? "Clear CPA input to enable"
                                                    : "Search on supplier number or name - 3 characters minimum required..."
                                            }
                                            {...supplierInputProps}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip
                                    title={supplierHelpText}
                                    placement="top-start"
                                >
                                    <IconButton
                                        onClick={null}
                                        sx={{ ml: "-8px" }}
                                    >
                                        <HelpOutlineRoundedIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    {...autoCompleteProps}
                                    disabled={
                                        isSmartFormDisabled("SUPPLIER") ||
                                        sitesDisabled
                                    }
                                    onChange={selectSupplierSite}
                                    getOptionLabel={(option) =>
                                        `${option.SUPPLIER_SITE_CODE} - ${option.SUPPLIER_SITE_ID}`
                                    }
                                    noOptionsText="No supplier sites available for selected supplier"
                                    options={supplierSites}
                                    isOptionEqualToValue={(option, value) =>
                                        option.SUPPLIER_SITE_ID ==
                                        value.SUPPLIER_SITE_ID
                                    }
                                    value={supplierSiteValue}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            {...freeTextFormProps}
                                            label={
                                                sitesDisabled
                                                    ? "Supplier Site - Select Supplier to Activate"
                                                    : "Supplier Site"
                                            }
                                            placeholder={`Select Supplier Site...`}
                                            inputRef={supplierSiteIdRef}
                                            error={
                                                supplierNameValue
                                                    ? !!errors.suppliersite
                                                    : false
                                            }
                                            helperText={
                                                supplierNameValue
                                                    ? errors?.suppliersite
                                                          ?.message
                                                    : ""
                                            }
                                            required={
                                                supplierNameValue ? true : false
                                            }
                                            {...supplierSiteInputProps}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    {...autoCompleteProps}
                                    disabled={
                                        isSmartFormDisabled("SUPPLIER") ||
                                        contactDisabled
                                    }
                                    selectOnFocus
                                    disableClearable={
                                        !supplierContactDetails.length == 0
                                    }
                                    clearIcon={false}
                                    freeSolo={
                                        supplierContactDetails.length == 0
                                    } // If no contact details, make free text
                                    onChange={selectSupplierContact}
                                    getOptionLabel={(option) =>
                                        // If selected from a pre-defined list from database, option == object
                                        typeof option === "object"
                                            ? option.title
                                                ? // Return empty string if title not defined to avoid MUI errors
                                                  option.title
                                                : ""
                                            : option
                                    }
                                    noOptionsText="No supplier contacts available - if known, type in name of contact"
                                    options={supplierContactDetails}
                                    isOptionEqualToValue={(option, value) =>
                                        // Match if titles match or there is no title input
                                        option.title == value.title ||
                                        !value.title
                                    }
                                    value={
                                        contactInfo
                                            ? contactInfo
                                            : supplierContactDetails.length == 0
                                            ? ""
                                            : null
                                    } // Value set conditionally based on configuration
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            {...freeTextFormProps}
                                            placeholder={
                                                supplierContactDetails.length ==
                                                0
                                                    ? "No contacts available for selected site - if known, enter name of a contact"
                                                    : "Select Contact..."
                                            }
                                            label={
                                                contactDisabled
                                                    ? "Supplier Contact Name - Select Supplier Site to Activate"
                                                    : "Supplier Contact Name"
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    {...freeTextFormProps}
                                    disabled={
                                        isSmartFormDisabled("SUPPLIER") ||
                                        contactDisabled
                                    }
                                    label={
                                        contactDisabled
                                            ? "Supplier Contact Phone - Select Supplier Site to Activate"
                                            : "Supplier Contact Phone"
                                    }
                                    placeholder="No number available for selected contact - if known, enter number for a contact"
                                    value={
                                        contactInfo?.PHONE
                                            ? contactInfo.PHONE
                                            : ""
                                    }
                                    inputRef={phoneRef}
                                    {...phoneInputProps}
                                    error={!!errors.phone}
                                    helperText={errors?.phone?.message}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    {...freeTextFormProps}
                                    label="Supplier Item"
                                    multiline
                                    disabled={isSmartFormDisabled("SUPPLIER")}
                                    onChange={(e) =>
                                        updateField(
                                            e.target.value,
                                            "supplierItem"
                                        )
                                    }
                                    placeholder="Enter Item..."
                                    value={product.supplierItem}
                                />
                            </Grid>
                        </Grid>
                        {context == NEW_SMART_FORM_ITEM && (
                            <Grid item xs={12} container spacing={3}>
                                <EditInformationTemplateContext.Provider
                                    value={{
                                        updateInfomationTemplateField:
                                            updateInformationTemplateField,
                                        register: register,
                                        errors: errors,
                                    }}
                                >
                                    <InformationTemplates
                                        ifts={informationTemplate}
                                    />
                                </EditInformationTemplateContext.Provider>
                            </Grid>
                        )}
                        <Grid item xs={12} container justifyContent="flex-end">
                            {[NEW_NON_CAT_ITEM, NEW_SMART_FORM_ITEM].includes(
                                context
                            ) && (
                                <AddToCartButton
                                    nonCat={
                                        context == NEW_SMART_FORM_ITEM
                                            ? SMART_FORM
                                            : NON_CATALOGUE_ITEM
                                    }
                                    product={product}
                                    qtyIncrement={product.QUANTITY}
                                    nonCatFormValidator={handleSubmit}
                                />
                            )}
                            {context == EDIT_NON_CAT_REQ_LINE && (
                                <UpdateRequisitionLineButton
                                    product={product}
                                    reqLineIndex={reqLineIndex}
                                    nonCatFormValidator={handleSubmit}
                                />
                            )}
                            {context == EDIT_NON_CAT_CART_ITEM && (
                                <UpdateCartItemButton
                                    product={product}
                                    nonCatFormValidator={handleSubmit}
                                />
                            )}
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
};

export default NonCatalogueForm;
