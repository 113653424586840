import { Alert, AlertTitle, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

import { gridSpacing } from "../layouts/gridLayout";

const AlertBanner = ({ severity, title, description, content }) => {
    return (
        <Alert severity={severity} variant="outlined">
            <Stack
                direction={{
                    xs: "column",
                    md: "row",
                }}
                spacing={gridSpacing}
            >
                <Box flex={1}>
                    <AlertTitle sx={{ fontWeight: "bold" }}>{title}</AlertTitle>
                    <Typography>{description}</Typography>
                </Box>
                {content && (
                    <Box flex={1} alignSelf="center">
                        {content}
                    </Box>
                )}
            </Stack>
        </Alert>
    );
};

AlertBanner.propTypes = {
    severity: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    content: PropTypes.element,
};

export default AlertBanner;
