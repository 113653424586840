import { NEW_SMART_FORM_ITEM, TYPE_OPTIONS } from "constants/generalConstants";
import {
    setActiveSmartForm,
    setShowSmartFormsDialogue,
} from "redux/actions/generalActions";
import {
    setProduct,
    setSmartFormsDataLoading,
} from "redux/actions/nonCatFormActions";
import store from "redux/store";
import { smartFormItemBase } from "schemas/nonCatalogueCartItemBaseSchema";
import { getCpaById } from "services/search/searchAPIs";
import { v4 as uuidv4 } from "uuid";

import {
    nonCatProductServiceSettings,
    openNonCatDialogue,
} from "./nonCatFormUtils";

const RESTRICT_SUPPLIER_TYPE_ASL_VALUE = "Category ASL";
const TOTAL_AMOUNT_KW = "TOTAL_AMOUNT";
export const SMART_FORM_NON_CAT_ITEM_TYPE_DISPATCHER = {
    GOODS: TYPE_OPTIONS[0],
    SERVICES: TYPE_OPTIONS[1],
    [TOTAL_AMOUNT_KW]: TYPE_OPTIONS[2],
    DEFAULT: TYPE_OPTIONS[0],
};

// Use designated opener function
export const openSmartFormModal = (smartForm, dispatch, browsingOU) => {
    // TODO - async get smart-form by id
    dispatch(setSmartFormsDataLoading(true));
    openNonCatDialogue(
        dispatch,
        NEW_SMART_FORM_ITEM,
        null,
        null,
        null,
        browsingOU,
        smartForm
    );
};

/**
 *
 * @param {*} smartForm
 * @returns
 */
export const setUseApprovedSupplierList = (smartForm) => {
    const useAsl =
        smartForm?.RESTRICT_SUPPLIER_TYPE === RESTRICT_SUPPLIER_TYPE_ASL_VALUE;
    return { ...smartForm, USE_APPROVED_SUPPLIERS: useAsl };
};

/**
 * Loads blank smart-form item into redux
 * @param {Function} dispatch - redux dispatcher
 * @param {string} activeOU - browsing OU in which item is being created
 *
 * Note - `PO_LINE_ID` is a unique ID for a non-catalogue item
 */
export const newSmartFormItem = async (dispatch, activeOU, smartForm) => {
    const augmentedSmartForm = await getSmartFormSupplementingInformation(
        smartForm,
        activeOU,
        dispatch
    );
    dispatch(
        setActiveSmartForm(setUseApprovedSupplierList(augmentedSmartForm))
    );
    dispatch(
        setProduct({
            ...smartFormItemBase,
            PO_LINE_ID: uuidv4(), // Give smart-form item a local UUID
            IBUY_CART_ITEM_OU: activeOU,
            ...parseNonCatProductFromSmartForm(augmentedSmartForm),
        })
    );
    dispatch(setSmartFormsDataLoading(false));
};

/**
 * Makes async calls to retrieve data to support loading the
 * smartform page
 * @param {Object} smartForm - smartform being loaded
 * @param {string} browsingOU - OU in which smartform is being loaded
 * @param {Function} dispatch - redux dispatcher function
 */
export const getSmartFormSupplementingInformation = async (
    smartForm,
    browsingOU,
    dispatch
) => {
    // TODO - get IFT
    const cpa = getCpaById(smartForm?.CONTRACT_ID, browsingOU, dispatch);
    let augmentedSmartForm;
    await Promise.all([cpa])
        .then((values) => {
            augmentedSmartForm = { ...smartForm, ...values[0] };
        })
        .catch(() => console.log("TODO - handle error"));
    return augmentedSmartForm;
};

/**
 * Handles closing of smart-forms dialogue. Note - this function
 * does not clear out product. Product should be reset from
 * scratch when the form is launched
 * @param {Function} dispatch - redux dispatcher function
 */
export const closeSmartFormsDialogue = (dispatch) => {
    dispatch(setShowSmartFormsDialogue(false));
};

/**
 * Parses a non-catalogue item redux state object from a smart-form
 * @param {object} activeSmartForm - smart-form being loaded
 * @returns {object} - object of non-catalogue redux field mapped
 * to values specified in the smart-form being opened
 */
export const parseNonCatProductFromSmartForm = (activeSmartForm) => ({
    ITEM_TYPE:
        SMART_FORM_NON_CAT_ITEM_TYPE_DISPATCHER[activeSmartForm?.ITEM_TYPE] ||
        SMART_FORM_NON_CAT_ITEM_TYPE_DISPATCHER["DEFAULT"],
    SMART_FORM_ID: activeSmartForm?.TEMPLATE_ID,
    ITEM_DESCRIPTION: activeSmartForm?.ITEM_DESCRIPTION,
    title: activeSmartForm?.SUPPLIER_CONTACT_NAME,
    UNIT_OF_MEASURE: activeSmartForm?.UNIT_OF_MEASURE,
    UNIT_MEAS_LOOKUP_CODE: activeSmartForm?.UNIT_OF_MEASURE,
    CPA_NUMBER: activeSmartForm?.CONTRACT,
    CPA_NUM_NAME: activeSmartForm?.CPA_NUM_NAME,
    ...parseSmartFormCategoryFromLookups(activeSmartForm),
    ...parseFromSmartFormSupplierInfo(activeSmartForm),
    ...(activeSmartForm?.ITEM_TYPE === TOTAL_AMOUNT_KW
        ? nonCatProductServiceSettings
        : {}),
});

/**
 * Parses supplier (non-cpa) information when a smart-form is being
 * loaded into the iBuy non-catalogue form. If the smart-form is a
 * CPA smart-form, an empty object is returned; in this case, supplier
 * related fields should be null, handled by `smartFormItemBase`. If
 * it is a non-CPA smart-form, then an object with all supplier
 * fields required to be loaded into redux is returned (overwriting
 * empty values in `smartFormItemBase`)
 * @param {*} activeSmartForm - smart-form being loaded
 * @returns {object} supplier information to be injected into smart-form
 */
export const parseFromSmartFormSupplierInfo = (activeSmartForm) => {
    const isCpaSmartForm = activeSmartForm?.CONTRACT_ID;
    return isCpaSmartForm
        ? {}
        : {
              SUPPLIER_ID: activeSmartForm?.SUPPLIER_ID,
              SUPPLIER_NUMBER_NAME: `${activeSmartForm?.SUPPLIER_ID} - ${activeSmartForm?.SUPPLIER_NAME}`,
              SUPPLIER_SITE_ID: activeSmartForm?.SUPPLIER_SITE_ID,
              SUPPLIER_SITE_CODE: activeSmartForm?.SUPPLIER_SITE_CODE,
              SUGGESTED_VENDOR_PHONE: activeSmartForm?.SUPPLIER_CONTACT_PHONE,
              SUGGESTED_VENDOR_CONTACT_ID: activeSmartForm?.SUPPLIER_CONTACT_ID,
          };
};

/**
 * Extracts category from smart-form and parses into redux non-catalogue
 * format using categories in lookups redux
 * @param {object} activeSmartForm - smart-form being loaded
 * lookups store
 * @returns {Object} - category values to be injected into non-catalogue form
 */
export const parseSmartFormCategoryFromLookups = (activeSmartForm) => {
    const reduxLookupsCategories =
        store.getState().filterPanelLookups.CATEGORY_DISPLAY || [];
    const selectedCategory = reduxLookupsCategories.filter((category) => {
        return (
            activeSmartForm?.PURCHASING_CATEGORY &&
            activeSmartForm?.PURCHASING_CATEGORY == category?.CATEGORY
        );
    });

    const defaultCategoryValues = {
        CATEGORY_ID: null,
        CATEGORY: null,
        CATEGORY_DISPLAY_NAME: null,
    };

    if (selectedCategory.length > 0) {
        return {
            CATEGORY_ID: selectedCategory[0]?.CATEGORY_ID,
            CATEGORY: selectedCategory[0]?.CATEGORY,
            CATEGORY_DISPLAY_NAME: selectedCategory[0]?.CATEGORY_DISPLAY_NAME,
        };
    }
    return defaultCategoryValues;
};
