import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    NON_PROJECT_REQ,
    PROJECT_REQ,
} from "../../../../../constants/generalConstants";
import { setHeaderField } from "../../../../../redux/actions/createRequisitionActions";
import { headerFormGridColumnProps } from "../headerFormGridLayout";
import AccountingNonProjectInputs from "./accountingNonProjectInputs/accountingNonProjectInputs";
import AccountingProjectInputs from "./accountingProjectInputs/accountingProjectInputs";

const Section2Accounting = ({
    disabled,
    updateHeader,
    setIsNextButtonDisabled,
}) => {
    // Collect states and state dispatchers
    const headerForm = useSelector(
        (state) => state.createRequisition.iBuyRequisition.requisitionHeaderForm
    );
    const isInternalReq = useSelector(
        (state) => state.createRequisition.isInternalReq
    );
    const dispatch = useDispatch();

    // Local states defining state of project / charge account / tasks dropdowns
    const [tasksDisabled, setTasksDisabled] = useState(true);

    // On component load, modify states of co-dependant dropdown lists
    useEffect(() => {
        // If ORG_NAME and PROJECT_ID defined, enable charge account and tasks dropdowns
        if (headerForm.PROJECT_ID && headerForm.ORG_NAME) {
            setTasksDisabled(false);
        }
    }, []);

    useEffect(() => {
        if (headerForm.ACCOUNTED_AGAINST === "PROJECT_REQ" && !isInternalReq) {
            setIsNextButtonDisabled(false);
        } else {
            setIsNextButtonDisabled(!headerForm.IS_VALIDATED);
        }
    }, [headerForm.ACCOUNTED_AGAINST, headerForm.IS_VALIDATED]);

    const updateAccountingMode = (e, value) => {
        // Nullify all affected fields - this button resets the form
        updateHeader(null, "PROJECT_ID");
        updateHeader(null, "CHARGE_ACCOUNT_ID");
        updateHeader(null, "TASK_ID");
        updateHeader(null, "SEGMENT1_ID");
        updateHeader(null, "SEGMENT1_NAME");
        updateHeader(null, "SEGMENT2_ID");
        updateHeader(null, "SEGMENT2_NAME");
        updateHeader(null, "SEGMENT3_ID");
        updateHeader(null, "SEGMENT3_NAME");
        updateHeader(null, "SEGMENT4_ID");
        updateHeader(null, "SEGMENT4_NAME");
        updateHeader(false, "IS_VALIDATED");
        updateHeader(null, "EXPENDITURE_ITEM_DATE");
        updateHeader(null, "EXPENDITURE_TYPE");
        updateHeader(null, "EXPENDITURE_ORGANIZATION_ID");
        updateHeader(null, "EXPENDITURE_ORGANIZATION_NAME");
        // Reset input disables
        setTasksDisabled(true);
        // Update header state
        dispatch(setHeaderField("ACCOUNTED_AGAINST", value));
    };
    return (
        <Grid {...headerFormGridColumnProps}>
            <Grid item xs={12}>
                <Typography variant="h5" color="text.subheader">
                    Accounting
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <FormControl
                    sx={{
                        mb: "-16px",
                    }}
                    disabled={disabled}
                >
                    <FormLabel sx={{ fontSize: "11px" }}>
                        Create Requisition Under
                    </FormLabel>
                    <RadioGroup
                        value={headerForm.ACCOUNTED_AGAINST}
                        onChange={updateAccountingMode}
                        row
                    >
                        <FormControlLabel
                            control={<Radio color="secondary" />}
                            label="Project"
                            value={PROJECT_REQ}
                        />
                        <FormControlLabel
                            control={<Radio color="secondary" />}
                            label="Non-Project"
                            value={NON_PROJECT_REQ}
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
            {headerForm.ACCOUNTED_AGAINST == PROJECT_REQ ? (
                <AccountingProjectInputs
                    tasksDisabled={tasksDisabled}
                    setTasksDisabled={setTasksDisabled}
                    updateHeader={updateHeader}
                />
            ) : (
                <AccountingNonProjectInputs updateHeader={updateHeader} />
            )}
        </Grid>
    );
};

Section2Accounting.propTypes = {
    disabled: PropTypes.any,
    updateHeader: PropTypes.func,
    setIsNextButtonDisabled: PropTypes.func,
};

export default Section2Accounting;
