// Generic labels
export const MIN_3_CHARACTERS_TEXT =
    "Enter a minimum of 3 characters to begin search";
export const START_NEW_SEARCH_TEXT = "Clear input to start a new search";
export const NO_OPTIONS_TEXT = "No options matching your search";

// Search as you type input labels
export const inputPlaceholder = (prompt) =>
    `${prompt} - 3 chars minimum required...`;

// Company code search as you type input labels
export const COMPANY_CODE_LABEL = "BSV Company Code";
export const COMPANY_CODE_INPUT_PLACEHOLDER = "Search for BSV company code";

// Cost centre search as you type input labels
export const COST_CENTRE_LABEL = "Cost Centre";
export const COST_CENTRE_INPUT_PLACEHOLDER = "Search for cost centre";

// Account search as you type input labels
export const ACCOUNT_LABEL = "Account";
export const ACCOUNT_INPUT_PLACEHOLDER = "Search for account code";

// Project-specific search as you type input labels
export const PROJ_INPUT_LABEL = "Project Number - Name";
export const PROJ_INPUT_PLACEHOLDER =
    "Search on project number or name - 3 chars minimum required...";

// Delivery location-specific search as you type input labels
export const DELIVERY_LOC_INPUT_LABEL = "Delivery Location";
export const DELIVERY_LOC_INPUT_PLACEHOLDER =
    "Search for delivery location - 3 chars minimum required...";

// Exepnditure organisation-location-specific search as you type input labels
export const EXP_ORG_INPUT_LABEL = "Expenditure Organisation";
export const EXP_ORG_INPUT_PLACEHOLDER =
    "Search for expenditure organisation - 3 chars minimum required...";
