/* This component has two use cases across the application: 
the receipt summary table; and, the requisition summary table.
These are identified by the mode prop. This prop should also be
passed to the "./reqFilterPanel.js"
 */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingButton from "@mui/lab/LoadingButton";
import { Stack, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import OpenFiltersButton from "components/buttons/openFiltersButton";
import CustomSwipeableDrawer from "components/customSwipeableDrawer";
import ReceiptSummaryList from "components/receiptSummary/receiptSummaryList/index.js";
import OnMobile from "components/wrappers/onMobile";
import { MOBILE_MEDIA_QUERY } from "constants/mediaQueries";
import useThrottle from "hooks/useThrottle";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
    DEFAULT_ORDER_KEY,
    DEFAULT_ORDER_VALUE,
    MAX_PRICE,
    MIN_PRICE,
    REQ_SUMMARY_TABLE,
    REQ_SUMMARY_TABLE_RECEIPT,
    SEARCH_API_DELIMITER,
    SHOW_CANCELLED_PARAM,
} from "../../../constants/generalConstants";
import {
    setDateGTE,
    setDateLTE,
    setMode,
    setOU,
    setPONumberSearch,
    setPriceRange,
    setProjectSearch,
    setReceiptStatus,
    setReceiptYear,
    setReqReceiptStatus,
    setRequesterSearch,
    setRequisitionNumberSearch,
    setShowCancelled,
    setStatus,
    setSupplierSearch,
} from "../../../redux/actions/reqFilterPanelActions";
import { listRequisitions } from "../../../services/requisition/requisitionAPIs";
import { contentBoxL2 } from "../../../styled/styledSXProperties/styledBoxSX";
import IBuyPaper from "../../displays/iBuyPaper";
import ValidatedAutocomplete from "../../inputs/validatedAutoComplete";
import ChangeRequisitionOwnerDialog from "../../modals/changeRequisitionOwner/changeRequisitionOwnerDialog";
import AllRequisitionsSkeleton from "../../skeletons/allRequisitionsSkeleton";
import ReqFilterPanel from "./reqFilterPanel";
import { RequisitionsSummaryContext } from "./requisitionsSummaryContext";
import RequisitionSummaryList from "./requisitionSummaryList";

const REQS_PER_PAGE = 12;

// Keys are display values to user. Values are url search params
const sortQueryDispatcher = {
    "Sort By: Date Raised - Earliest to Latest": "submittedDate",
    [DEFAULT_ORDER_KEY]: DEFAULT_ORDER_VALUE,
    "Sort By: Description - A to Z": "requisitionHeaderForm.HEADER_DESCRIPTION",
    "Sort By: Description - Z to A":
        "requisitionHeaderForm.HEADER_DESCRIPTION DESC",
    "Sort By: Price - low to high": "totalPrice",
    "Sort By: Price - high to low": "totalPrice DESC",
    "Sort By: Requisition ID - Low to High": "id",
    "Sort By: Requisition ID - High to Low": "id DESC",
    "Sort By: Status - A to Z": "status",
    "Sort By: Status - Z to A": "status DESC",
};

// Index 0 == default
export const modeFilterDispatcher = ["MY", "ALL_PERMITTED"];
export const receiptStatusDispatcher = ["Y", "N"];

/* Note all API search terms are directed via the webpage URL.
This component reads the URL with-in the useEffect, calls
the get req api with any search params encoded into the URL
and sets the filter panel state using them.*/
const RequisitionsSummary = ({ mode }) => {
    // Collect states and state dispatchers
    const darkMode = useSelector((state) => state.general.darkMode);
    const reqListAPIRefreshKey = useSelector(
        (state) => state.createRequisition.reqListAPIRefreshKey
    );
    const dispatch = useDispatch();
    const { reqs, setReqs, currentReqId, setCurrentReqId } = useContext(
        RequisitionsSummaryContext
    );

    // Local states
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageLoading, setPageLoading] = useState(false);
    const [sort, setSort] = useState();
    const [totalResults, setTotalResults] = useState(0);
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        readAndSetDateGTEParams();
        readAndSetDateLTEParams();
    }, []);

    const throttleFunction = useThrottle();

    useEffect(() => {
        readSearchParamsAndQueryReqListApi();
    }, [searchParams, reqListAPIRefreshKey]);

    const readSearchParamsAndQueryReqListApi = () => {
        // Read URL and set state
        readAndSetModeParams();
        readAndSetPriorityParams();
        readAndSetReceiptStatusParams();
        readAndSetOUParams();
        readAndSetPriceParams();
        readAndSetProjectParams();
        readAndSetRequisitionNumberParams();
        readAndSetPONumberParams();
        readAndSetRequesterParams();
        readAndSetStatusParams();
        readAndSetSortParams();
        readAndSetSupplierParams();
        readAndSetShowCancelledParams();
        readAndSetYearParams();
        // Call get reqs list API
        throttleFunction(() => {
            getReqs();
            setPage(1);
        });
    };

    const getReqs = async () => {
        const res = await listRequisitions(
            dispatch,
            searchParams.toString(),
            setIsLoading,
            setTotalResults
        );
        setReqs(res);
    };

    const readAndSetModeParams = () => {
        !searchParams.has("mode") &&
            searchParams.set("mode", modeFilterDispatcher[0]);
        dispatch(setMode(searchParams.get("mode")));
    };
    const readAndSetPriorityParams = () => {
        if (mode == REQ_SUMMARY_TABLE_RECEIPT) {
            searchParams.has("receiptReqStatus")
                ? dispatch(
                      setReqReceiptStatus(
                          searchParams
                              .get("receiptReqStatus")
                              .split(SEARCH_API_DELIMITER)
                      )
                  )
                : dispatch(setReqReceiptStatus([]));
        } else {
            searchParams.delete("receiptReqStatus");
            dispatch(setReqReceiptStatus([]));
        }
    };

    const readAndSetReceiptStatusParams = () => {
        // Only fired if used in the receipt summary page
        if (mode == REQ_SUMMARY_TABLE_RECEIPT) {
            !searchParams.has("receiptStatus") &&
                searchParams.set("receiptStatus", receiptStatusDispatcher[0]);
            dispatch(setReceiptStatus(searchParams.get("receiptStatus")));
        } else {
            searchParams.delete("receiptStatus");
            dispatch(setReceiptStatus(receiptStatusDispatcher[0]));
        }
    };

    const readAndSetPriceParams = () => {
        let lb;
        let ub;
        searchParams.has("price__gte")
            ? (lb = parseFloat(searchParams.get("price__gte")))
            : (lb = MIN_PRICE);
        searchParams.has("price__lte")
            ? (ub = parseFloat(searchParams.get("price__lte")))
            : (ub = MAX_PRICE);
        dispatch(setPriceRange([lb, ub]));
    };

    const readAndSetStatusParams = () => {
        // Only fired if used in the req summary page
        if (mode == REQ_SUMMARY_TABLE) {
            searchParams.has("status")
                ? dispatch(setStatus(searchParams.get("status")))
                : dispatch(setStatus(null));
        } else {
            searchParams.delete("status");
            dispatch(setStatus(null));
        }
    };

    const readAndSetDateGTEParams = () => {
        if (!searchParams.has("date__gte")) return;
        const dateGte = searchParams.get("date__gte");
        if (!moment(dateGte).isValid()) return;
        dispatch(setDateGTE(new Date(dateGte)));
    };

    const readAndSetDateLTEParams = () => {
        if (!searchParams.has("date__lte")) return;
        const dateLte = searchParams.get("date__lte");
        if (!moment(dateLte).isValid()) return;
        dispatch(setDateLTE(new Date(dateLte)));
    };

    const readAndSetOUParams = () => {
        searchParams.has("org_name")
            ? dispatch(setOU(searchParams.get("org_name")))
            : dispatch(setOU(null));
    };

    const readAndSetRequisitionNumberParams = () => {
        searchParams.has("requisitionId")
            ? dispatch(
                  setRequisitionNumberSearch(searchParams.get("requisitionId"))
              )
            : dispatch(setRequisitionNumberSearch(""));
    };

    const readAndSetPONumberParams = () => {
        searchParams.has("po_id")
            ? dispatch(setPONumberSearch(searchParams.get("po_id")))
            : dispatch(setPONumberSearch(""));
    };

    const readAndSetProjectParams = () => {
        searchParams.has("projectNumber")
            ? dispatch(setProjectSearch(searchParams.get("projectNumber")))
            : dispatch(setProjectSearch(""));
    };

    const readAndSetSupplierParams = () => {
        searchParams.has("supplierName")
            ? dispatch(setSupplierSearch(searchParams.get("supplierName")))
            : dispatch(setSupplierSearch(""));
    };

    const readAndSetYearParams = () => {
        // Only fired if used in the receipt summary page
        if (mode == REQ_SUMMARY_TABLE_RECEIPT) {
            // If not specified, default to current year
            const yearParam = searchParams.has("year")
                ? searchParams.get("year")
                : "All Years";
            !searchParams.has("year") && searchParams.set("year", yearParam);
            dispatch(setReceiptYear(yearParam));
        } else {
            searchParams.delete("year");
            dispatch(setReceiptYear(null));
        }
    };

    const readAndSetRequesterParams = () => {
        searchParams.has("requesterId")
            ? dispatch(setRequesterSearch(searchParams.get("requesterId")))
            : dispatch(setRequesterSearch(""));
    };

    const readAndSetSortParams = () => {
        !searchParams.has("order_by") &&
            searchParams.set("order_by", DEFAULT_ORDER_VALUE);
        setSort(getSortDisplayValue(searchParams.get("order_by")));
    };

    const readAndSetShowCancelledParams = () => {
        if (mode == REQ_SUMMARY_TABLE) {
            searchParams.has("cancelled")
                ? dispatch(
                      setShowCancelled(
                          searchParams.get("cancelled") == SHOW_CANCELLED_PARAM
                      )
                  )
                : dispatch(setShowCancelled(false));
        } else {
            searchParams.delete("cancelled");
            dispatch(setShowCancelled(false));
        }
    };

    // Called in the sort select drop-down
    const setSortParams = (_event, value) => {
        searchParams.set("order_by", sortQueryDispatcher[value]);
        setSearchParams(searchParams);
    };

    const getSortDisplayValue = (param) =>
        Object.keys(sortQueryDispatcher).find(
            (key) => sortQueryDispatcher[key] === param
        );

    // Note, pagination offset params are not appended to search params URL
    // Refer to iBuy wikki: Azure iBuy application > iBuy features > 3.5
    const getNextPage = async () => {
        setPageLoading(true);
        searchParams.set("offset", page * REQS_PER_PAGE);
        const res = await listRequisitions(
            dispatch,
            searchParams.toString(),
            setIsLoading,
            setTotalResults,
            true
        );
        searchParams.delete("offset"); // Offset params not permanently stored in URL.
        const nextPage = res ? res : [];
        setReqs([...reqs, ...nextPage]);
        setPage(page + 1);
        setPageLoading(false);
    };

    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    const tableBackgroundSX =
        !darkMode && isDesktop
            ? {
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  boxShadow: "1",
              }
            : {};
    return (
        <>
            <Box sx={contentBoxL2}>
                <Grid container spacing={3}>
                    {/* Filter Panel */}
                    {isDesktop ? (
                        <Grid item xs={12} md={4} lg={2.25}>
                            <IBuyPaper>
                                <ReqFilterPanel
                                    searchParams={searchParams}
                                    setSearchParams={setSearchParams}
                                    panelMode={mode}
                                />
                            </IBuyPaper>
                        </Grid>
                    ) : (
                        <CustomSwipeableDrawer
                            isOpen={isFilterDrawerOpen}
                            onClose={() => setIsFilterDrawerOpen(false)}
                        >
                            <ReqFilterPanel
                                searchParams={searchParams}
                                setSearchParams={setSearchParams}
                                panelMode={mode}
                            />
                        </CustomSwipeableDrawer>
                    )}
                    {/* Content panel */}
                    <Grid
                        container
                        item
                        xs={12}
                        md={8}
                        lg={9.75}
                        spacing={2}
                        alignContent="flex-start"
                        alignItems="start"
                    >
                        {/* List helpers */}
                        <Grid item xs={12} md={8}>
                            <Box display="flex" justifyContent="flex-start">
                                <Typography
                                    variant="h6"
                                    color="text.primary"
                                    sx={{
                                        mt: "12px",
                                        ml: "10px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {`Total Requisitions: ${totalResults.toLocaleString()}`}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack direction="row" spacing={2}>
                                <OnMobile>
                                    <OpenFiltersButton
                                        setIsOpen={setIsFilterDrawerOpen}
                                    />
                                </OnMobile>
                                <Box sx={{ flexGrow: 1 }}>
                                    <ValidatedAutocomplete
                                        label="Sort Results"
                                        placeholder="Select sorting rule"
                                        onChange={setSortParams}
                                        options={Object.keys(
                                            sortQueryDispatcher
                                        )}
                                        value={sort}
                                    />
                                </Box>
                            </Stack>
                        </Grid>

                        {isLoading ? (
                            // Data loading - show skelton
                            <Grid item xs={12}>
                                <AllRequisitionsSkeleton />
                            </Grid>
                        ) : reqs.length == 0 ? (
                            // No results available
                            <Grid item xs={12}>
                                <IBuyPaper>
                                    <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                    >
                                        No Requisitions matching the filter
                                        criteria
                                    </Typography>
                                </IBuyPaper>
                            </Grid>
                        ) : (
                            // Results available
                            <>
                                {/* Summary table */}
                                <Grid item xs={12}>
                                    <Box sx={tableBackgroundSX}>
                                        <Grid item xs={12}>
                                            {mode == REQ_SUMMARY_TABLE && (
                                                <RequisitionSummaryList
                                                    reqs={reqs}
                                                />
                                            )}
                                            {mode ==
                                                REQ_SUMMARY_TABLE_RECEIPT && (
                                                <ReceiptSummaryList
                                                    reqs={reqs}
                                                />
                                            )}
                                        </Grid>
                                        {/* If there are pages to scroll through, render see more button */}
                                        {page <
                                            Math.ceil(
                                                totalResults / REQS_PER_PAGE
                                            ) && (
                                            <Grid
                                                item
                                                xs={12}
                                                display="flex"
                                                flexDirection="column"
                                                alignItems="center"
                                            >
                                                <LoadingButton
                                                    color="secondary"
                                                    endIcon={<ExpandMoreIcon />}
                                                    loading={pageLoading}
                                                    loadingPosition="end"
                                                    onClick={getNextPage}
                                                    sx={{
                                                        width: "20%",
                                                        mt: "6px",
                                                    }}
                                                >
                                                    See More
                                                </LoadingButton>
                                            </Grid>
                                        )}
                                    </Box>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <ChangeRequisitionOwnerDialog
                req={reqs.find((req) => req.id === currentReqId)}
                isOpen={!!currentReqId}
                onClose={() => setCurrentReqId(undefined)}
                onSuccess={() => readSearchParamsAndQueryReqListApi()}
            />
        </>
    );
};

RequisitionsSummary.propTypes = {
    mode: PropTypes.oneOf([REQ_SUMMARY_TABLE, REQ_SUMMARY_TABLE_RECEIPT]),
};

export default RequisitionsSummary;
