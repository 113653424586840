import ApprovalIcon from "@mui/icons-material/Approval";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import BlockIcon from "@mui/icons-material/Block";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorIcon from "@mui/icons-material/Error";
import FlakyIcon from "@mui/icons-material/Flaky";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PendingIcon from "@mui/icons-material/Pending";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import PublishIcon from "@mui/icons-material/Publish";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

import {
    AMENDMENT_SUBMITTED,
    APPROVED,
    ATTENTION_REQUIRED,
    AWAITING_APPROVAL,
    CANCELLATION_SUBMITTED,
    CANCELLED,
    CREATED,
    FAILED,
    FULLY_RECEIPTED,
    IN_PROCESS,
    INCOMPLETE,
    INVALID_REQUISITION,
    NOT_RECEIPTED,
    PARTIALLY_RECEIPTED,
    PENDING,
    PO_APPROVAL_PENDING,
    PO_NOT_AVAILABLE,
    PRE_APPROVED,
    RECEIPTING_IN_FLIGHT,
    RECEIPTING_NOT_REQUIRED,
    REJECTED,
    RETURNED,
    SUBMITTED,
    UNKNOWN_STATUS,
} from "../../../constants/generalConstants";
import {
    TOOLTIP_AMENDMENT_SUBMITTED,
    TOOLTIP_APPROVED,
    TOOLTIP_ATTENTION_REQUIRED,
    TOOLTIP_AWAITING_APPROVAL,
    TOOLTIP_CANCELLATION_SUBMITTED,
    TOOLTIP_CANCELLED,
    TOOLTIP_CREATED,
    TOOLTIP_FAILED,
    TOOLTIP_FULLY_RECEIPTED,
    TOOLTIP_IN_PROCESS,
    TOOLTIP_INCOMPLETE,
    TOOLTIP_INVALID_REQUISITION,
    TOOLTIP_NOT_RECEIPTED,
    TOOLTIP_PARTIALLY_RECEIPTED,
    TOOLTIP_PENDING,
    TOOLTIP_PO_APPROVAL_PENDING,
    TOOLTIP_PO_NOT_AVAILABLE,
    TOOLTIP_RECEIPTING_IN_FLIGHT,
    TOOLTIP_RECEIPTING_NOT_REQUIRED,
    TOOLTIP_REJECTED,
    TOOLTIP_RETURNED,
    TOOLTIP_SUBMITTED,
    TOOLTIP_UNKNOWN_STATUS,
} from "../../../constants/tooltipText";

export const BADGE_DISPATCHER = {
    [APPROVED]: {
        color: "success",
        icon: <ThumbUpIcon aria-label="approvedReq" />,
        label: APPROVED,
        tooltip: TOOLTIP_APPROVED,
    },
    [AWAITING_APPROVAL]: {
        color: "success",
        icon: <ApprovalIcon aria-label="awaitingApproval" />,
        label: AWAITING_APPROVAL,
        tooltip: TOOLTIP_AWAITING_APPROVAL,
    },
    [CANCELLED]: {
        color: "error",
        icon: <CancelIcon aria-label="cancelledReq" />,
        label: CANCELLED,
        tooltip: TOOLTIP_CANCELLED,
    },
    [CANCELLATION_SUBMITTED]: {
        color: "error",
        icon: <PublishIcon aria-label="cancelSubmit" />,
        label: CANCELLATION_SUBMITTED,
        tooltip: TOOLTIP_CANCELLATION_SUBMITTED,
    },
    [CREATED]: {
        color: "success",
        icon: <CheckCircleOutlineIcon aria-label="created" />,
        label: CREATED,
        tooltip: TOOLTIP_CREATED,
    },
    [INCOMPLETE]: {
        color: "primary",
        icon: <FlakyIcon aria-label="incompleteReq" />,
        label: INCOMPLETE,
        tooltip: TOOLTIP_INCOMPLETE,
    },
    [IN_PROCESS]: {
        color: "secondary",
        icon: <MoreHorizIcon aria-label="inProcessReq" />,
        label: IN_PROCESS,
        tooltip: TOOLTIP_IN_PROCESS,
    },
    [INVALID_REQUISITION]: {
        color: "error",
        icon: <ErrorIcon aria-label="invalid" />,
        label: "INVALID",
        tooltip: TOOLTIP_INVALID_REQUISITION,
    },
    [PO_APPROVAL_PENDING]: {
        color: "warning",
        icon: <PendingActionsIcon aria-label="pending" />,
        label: PO_APPROVAL_PENDING,
        tooltip: TOOLTIP_PO_APPROVAL_PENDING,
    },
    [PENDING]: {
        color: "warning",
        icon: <PendingActionsIcon aria-label="pending" />,
        label: PENDING,
        tooltip: TOOLTIP_PENDING,
    },
    [SUBMITTED]: {
        color: "secondary",
        icon: <PublishIcon aria-label="submitted" />,
        label: SUBMITTED,
        tooltip: TOOLTIP_SUBMITTED,
    },
    [AMENDMENT_SUBMITTED]: {
        color: "secondary",
        icon: <PublishIcon aria-label="amendmentSubmitted" />,
        label: AMENDMENT_SUBMITTED,
        tooltip: TOOLTIP_AMENDMENT_SUBMITTED,
    },
    [FAILED]: {
        color: "error",
        icon: <ErrorIcon aria-label="failed" />,
        label: FAILED,
        tooltip: TOOLTIP_FAILED,
    },
    [FULLY_RECEIPTED]: {
        color: "success",
        icon: <CheckCircleOutlineIcon aria-label="fullyReceipted" />,
        label: FULLY_RECEIPTED,
        tooltip: TOOLTIP_FULLY_RECEIPTED,
    },
    [RECEIPTING_NOT_REQUIRED]: {
        color: "primary",
        icon: <BlockIcon aria-label="receiptingNotRequired" />,
        label: RECEIPTING_NOT_REQUIRED,
        tooltip: TOOLTIP_RECEIPTING_NOT_REQUIRED,
    },
    [PRE_APPROVED]: {
        color: "success",
        icon: <ThumbUpIcon aria-label="preApprovedReq" />,
        label: PRE_APPROVED,
        tooltip: TOOLTIP_APPROVED,
    },
    [PO_NOT_AVAILABLE]: {
        color: "secondary",
        icon: <BlockIcon aria-label="poNotAvailable" />,
        label: PO_NOT_AVAILABLE,
        tooltip: TOOLTIP_PO_NOT_AVAILABLE,
    },
    [NOT_RECEIPTED]: {
        color: "secondary",
        icon: <RemoveCircleOutlineIcon aria-label="notReceipted" />,
        label: NOT_RECEIPTED,
        tooltip: TOOLTIP_NOT_RECEIPTED,
    },
    [PARTIALLY_RECEIPTED]: {
        color: "warning",
        icon: <HorizontalRuleIcon aria-label="partiallyReceipted" />,
        label: PARTIALLY_RECEIPTED,
        tooltip: TOOLTIP_PARTIALLY_RECEIPTED,
    },
    [RECEIPTING_IN_FLIGHT]: {
        color: "warning",
        icon: <PendingIcon aria-label="receiptingInFlight" />,
        label: RECEIPTING_IN_FLIGHT,
        tooltip: TOOLTIP_RECEIPTING_IN_FLIGHT,
    },
    [ATTENTION_REQUIRED]: {
        color: "error",
        icon: <PriorityHighIcon aria-label="attentionRequired" />,
        label: ATTENTION_REQUIRED,
        tooltip: TOOLTIP_ATTENTION_REQUIRED,
    },
    [RETURNED]: {
        color: "warning",
        icon: <AssignmentReturnIcon aria-label="returnedReq" />,
        label: RETURNED,
        tooltip: TOOLTIP_RETURNED,
    },
    [REJECTED]: {
        color: "error",
        icon: <ThumbDownIcon aria-label="rejectedReq" />,
        label: REJECTED,
        tooltip: TOOLTIP_REJECTED,
    },
    [UNKNOWN_STATUS]: {
        color: "secondary",
        icon: <LinearScaleIcon aria-label="unknownStatus" />,
        tooltip: TOOLTIP_UNKNOWN_STATUS,
    },
};
