import Badge from "@mui/material/Badge";
import PropTypes from "prop-types";

const NotificationBadge = ({ notificationCount, children, sx = null }) => {
    return (
        <>
            <Badge
                badgeContent={notificationCount > 9 ? "9+" : notificationCount}
                color="error"
                sx={sx}
            >
                {children}
            </Badge>
        </>
    );
};

NotificationBadge.defaultProps = {
    sx: null,
};

NotificationBadge.propTypes = {
    notificationCount: PropTypes.number,
    children: PropTypes.node,
    sx: PropTypes.object,
};

export default NotificationBadge;
