// Returns action creators for each cart action type
import {
    ADD_NEW_CART,
    ADD_TO_CART,
    CLEAR_CART,
    CREATE_NAMED_CART,
    DELETE_CART,
    LOAD_NAMED_CARTS,
    RENAME_CART,
    SET_CART_ITEMS,
    SET_DEFAULT_CART_USER_ID,
} from "../actionTypes";

// Action creators for shopping cart slice
export const addToCart = (cartKey, item) => {
    return { type: ADD_TO_CART, payload: { cartKey, item } };
};

export const addNewCart = (newCartKey, newCart) => {
    return { type: ADD_NEW_CART, payload: { newCartKey, newCart } };
};

export const clearCart = (cartKey) => {
    return { type: CLEAR_CART, payload: cartKey };
};

export const deleteCartFromState = (cartKey) => {
    return { type: DELETE_CART, payload: cartKey };
};

export const createNamedCart = (name, newCartKey, baseCartKey) => {
    return {
        type: CREATE_NAMED_CART,
        payload: { name, newCartKey, baseCartKey },
    };
};

export const loadNamedCarts = (cartsArray) => {
    return {
        type: LOAD_NAMED_CARTS,
        payload: cartsArray,
    };
};

export const renameCart = (cartKey, newName) => {
    return { type: RENAME_CART, payload: { cartKey, newName } };
};

export const setCartItems = (cartKey, newItems) => {
    return { type: SET_CART_ITEMS, payload: { cartKey, newItems } };
};

export const setDefaultCartUserId = (userId) => {
    return { type: SET_DEFAULT_CART_USER_ID, payload: userId };
};
