import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";

const GradientDivider = styled(Divider)(({ theme }) => {
    return {
        height: "0.6px",
        margin: "10px 5px",
        backgroundImage: theme.palette.background.divider,
        opacity: 0.4,
    };
});

export default GradientDivider;
