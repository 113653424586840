import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { NAV_BAR_FADE_TIMEOUT } from "../../constants/transitions";
import NotificationBadge from "./notificationBadge";

const buttonHeight = "50px";

const ExpandedButton = ({ icon, buttonLabel, onClick, notificationCount }) => {
    return (
        <Button
            onClick={onClick}
            startIcon={icon}
            sx={{
                minHeight: buttonHeight,
                justifyContent: "flex-start",
                paddingLeft: "20px",
                width: "100%",
                overflow: "clip",
                color: "iconButton.main",
            }}
        >
            <Fade in={true} timeout={NAV_BAR_FADE_TIMEOUT}>
                <Typography
                    align="left"
                    fontWeight="bold"
                    variant="body2"
                    noWrap
                    sx={{
                        animation: "fadeIn 5s",
                    }}
                >
                    {buttonLabel}
                </Typography>
            </Fade>
            <NotificationBadge
                notificationCount={notificationCount}
                sx={{ ml: "20px" }}
            />
        </Button>
    );
};

const CollapsedButton = ({
    icon,
    buttonLabel,
    onClick,
    tooltip,
    notificationCount,
}) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                textDecoration: "none",
            }}
        >
            <Tooltip title={tooltip} placement="top" followCursor>
                <IconButton
                    aria-label={buttonLabel}
                    onClick={onClick}
                    sx={{
                        height: buttonHeight,
                    }}
                >
                    <NotificationBadge notificationCount={notificationCount}>
                        {icon}
                    </NotificationBadge>
                </IconButton>
            </Tooltip>
        </Box>
    );
};

const NavigationButton = ({
    navigate,
    icon,
    buttonLabel,
    customClick = null,
    notificationCount = null,
    tooltip,
}) => {
    // Collect states and state dispatchers
    const collapseNavBar = useSelector((state) => state.general.collapseNavBar);

    const navigateTo = useNavigate();
    const handleClick = () => {
        customClick ? customClick() : navigateTo(navigate);
    };

    return (
        <>
            {!collapseNavBar ? (
                <ExpandedButton
                    icon={icon}
                    buttonLabel={buttonLabel}
                    onClick={handleClick}
                    notificationCount={notificationCount}
                />
            ) : (
                <CollapsedButton
                    icon={icon}
                    buttonLabel={buttonLabel}
                    onClick={handleClick}
                    tooltip={tooltip}
                    notificationCount={notificationCount}
                />
            )}
        </>
    );
};

NavigationButton.defaultProps = {
    customClick: null,
    notificationCount: null,
};

NavigationButton.propTypes = {
    navigate: PropTypes.string,
    icon: PropTypes.element,
    buttonLabel: PropTypes.string,
    customClick: PropTypes.func,
    notificationCount: PropTypes.number,
    tooltip: PropTypes.string,
};

export default NavigationButton;
