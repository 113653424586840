import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReceiptSummaryPageNum } from "redux/actions/receiptSummaryActions";
import { primaryButtonSx } from "styled/styledSXProperties/styledButtonSX";
import {
    showCorrectionNotAvailableSnackbar,
    showReturnNotAvailableSnackbar,
} from "utils/receiptSummaryUtils";

import {
    CORRECTION_PAGE_NUM,
    RECEIPT_SUMMARY_PAGE_NUM,
    RETURN_PAGE_NUM,
} from "../receiptSummaryDialogue";

const ReceiptSummaryDialogActions = ({
    pageNum,
    receiptLines,
    handleSubmit,
    setConfirmCorrectionSubmitOpen,
    setConfirmReturnSubmitOpen,
}) => {
    const isLoading = useSelector((state) => state.receiptSummary.isLoading);
    const dispatch = useDispatch();

    const navigateToCorrectionPage = () => {
        dispatch(setReceiptSummaryPageNum(CORRECTION_PAGE_NUM));
        showCorrectionNotAvailableSnackbar(
            receiptLines.some((line) => line?.AVAILABLE_FOR_CORRECTION),
            dispatch
        );
    };

    const navigateToReturns = () => {
        dispatch(setReceiptSummaryPageNum(RETURN_PAGE_NUM));
        showReturnNotAvailableSnackbar(
            receiptLines.some((line) => line?.AVAILABLE_FOR_RETURN),
            receiptLines.some((line) => line?.QTY_RECEIVED_GTR_ZERO),
            dispatch
        );
    };

    const isCorrectionSubmitButtonDisabled = () =>
        !receiptLines.some(
            (line) => line.checked && line?.AVAILABLE_FOR_CORRECTION
        );

    const isReturnSubmitButtonDisabled = () =>
        !receiptLines.some(
            (line) =>
                line.checkedReturn &&
                line?.AVAILABLE_FOR_RETURN &&
                line?.QTY_RECEIVED_GTR_ZERO
        );

    const onClickSubmitCorrection = (e) =>
        handleSubmit(() => setConfirmCorrectionSubmitOpen(true))(e);

    const onClickSubmitReturn = (e) =>
        handleSubmit(() => setConfirmReturnSubmitOpen(true))(e);

    if (pageNum == RECEIPT_SUMMARY_PAGE_NUM)
        return (
            <>
                <Button
                    variant="contained"
                    endIcon={<ReceiptIcon />}
                    onClick={navigateToCorrectionPage}
                    sx={primaryButtonSx}
                >
                    Correct Receipt
                </Button>
                <Button
                    variant="contained"
                    endIcon={<AssignmentReturnIcon />}
                    onClick={navigateToReturns}
                    sx={primaryButtonSx}
                >
                    Return Receipt
                </Button>
            </>
        );

    if (pageNum == CORRECTION_PAGE_NUM)
        return (
            <>
                <Button
                    color="secondary"
                    disabled={isLoading}
                    variant="outlined"
                    startIcon={<NavigateBeforeIcon />}
                    sx={{ mr: "16px" }}
                    onClick={() =>
                        dispatch(
                            setReceiptSummaryPageNum(RECEIPT_SUMMARY_PAGE_NUM)
                        )
                    }
                >
                    Back
                </Button>
                <LoadingButton
                    color="success"
                    disabled={isCorrectionSubmitButtonDisabled()}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<SendIcon />}
                    onClick={onClickSubmitCorrection}
                    variant="contained"
                >
                    Submit Correction
                </LoadingButton>
            </>
        );

    if (pageNum == RETURN_PAGE_NUM)
        return (
            <>
                <Button
                    color="secondary"
                    disabled={isLoading}
                    variant="outlined"
                    startIcon={<NavigateBeforeIcon />}
                    sx={{ mr: "16px" }}
                    onClick={() =>
                        dispatch(
                            setReceiptSummaryPageNum(RECEIPT_SUMMARY_PAGE_NUM)
                        )
                    }
                >
                    Back
                </Button>
                <LoadingButton
                    color="success"
                    disabled={isReturnSubmitButtonDisabled()}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<SendIcon />}
                    onClick={onClickSubmitReturn}
                    variant="contained"
                >
                    Submit Return
                </LoadingButton>
            </>
        );
};

ReceiptSummaryDialogActions.propTypes = {
    pageNum: PropTypes.number.isRequired,
    receiptLines: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setConfirmCorrectionSubmitOpen: PropTypes.func.isRequired,
    setConfirmReturnSubmitOpen: PropTypes.func.isRequired,
};

export default ReceiptSummaryDialogActions;
