import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { projAutocompleteGet } from "../../../services/requisition/requisitionAPIs";
import { ProjectType } from "../../../types/propTypes";
import alertDispatcher from "../../../utils/alertsUtils";
import { PROJ_INPUT_LABEL, PROJ_INPUT_PLACEHOLDER } from "./labels";
import SearchAsYouType from "./searchAsYouType";

const ProjectSearchAsYouType = ({
    disabled,
    selectedValue,
    setSelectedValue,
}) => {
    // Collect states and state dispatchers
    const reqOU = useSelector(
        (state) =>
            state.createRequisition.iBuyRequisition.requisitionHeaderForm
                .ORG_NAME
    );
    const dispatch = useDispatch();

    const autocompleteGet = (searchTerm, setOptions, setIsLoading) => {
        let newOptions = [];

        if (selectedValue) {
            newOptions = [selectedValue];
        }

        setIsLoading(true);

        projAutocompleteGet(searchTerm, reqOU)
            .then((results) => {
                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                setOptions(newOptions);
            })
            .catch((error) => {
                alertDispatcher(
                    "projAutocompleteGet",
                    error?.response?.status,
                    dispatch
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <SearchAsYouType
            selectedValue={selectedValue}
            onSelectValue={setSelectedValue}
            isDisabled={disabled}
            autocompleteGet={autocompleteGet}
            searchPrompt={PROJ_INPUT_PLACEHOLDER}
            label={PROJ_INPUT_LABEL}
            optionKeyMapping={{
                id: "PROJECT_NUMBER",
                label: "PROJECT_NAME_NUM",
            }}
        />
    );
};

ProjectSearchAsYouType.propTypes = {
    disabled: PropTypes.bool,
    selectedValue: ProjectType,
    setSelectedValue: PropTypes.func,
};

export default ProjectSearchAsYouType;
