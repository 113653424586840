import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React from "react";

import { tableHeaderProps } from "../../../../../../styled/props/tableProps";
import PoLink from "./poLink";

const ReceiptLinesTable = ({ receiptLines }) => {
    return (
        <TableContainer data-testid="ReceiptLinesTable">
            <Table
                size="small"
                sx={{
                    [`& .${tableCellClasses.root}`]: {
                        borderColor: "#b1c0cb",
                    },
                    "&:nth-of-type(even)": {
                        backgroundColor: "#676c7b",
                    },
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography
                                {...{
                                    ...tableHeaderProps,
                                    align: "center",
                                }}
                            >
                                Line Number
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography {...tableHeaderProps}>
                                Item Description
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography {...tableHeaderProps}>
                                Quantity Received
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography {...tableHeaderProps}>
                                Unit of Measure
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography {...tableHeaderProps}>
                                Shipping Location
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography {...tableHeaderProps}>
                                Purchase Order
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {receiptLines.map((line, index) => (
                        <TableRow
                            key={index}
                            hover
                            sx={{ "& > *": { borderBottom: "unset" } }}
                        >
                            <TableCell align="center">
                                {line?.LINE_NUM}
                            </TableCell>
                            <TableCell align="center">
                                {line?.ITEM_DESCRIPTION}
                            </TableCell>
                            <TableCell align="center">
                                {line?.QUANTITY_RECEIVED}
                            </TableCell>
                            <TableCell align="center">
                                {line?.UNIT_OF_MEASURE_DESCRIPTION}
                            </TableCell>
                            <TableCell align="center">
                                {line?.SHIP_TO_LOCATION_CODE}
                            </TableCell>
                            <TableCell align="center">
                                <PoLink poNumber={line?.PO_NUMBER} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ReceiptLinesTable;
