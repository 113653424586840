import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { useSelector } from "react-redux";

import { homepageSmartFormBoxSX } from "../../styled/styledSXProperties/styledBoxSX";
import { homepageSmartFormCardSX } from "../../styled/styledSXProperties/styledCardSX";

const HomepageCardSkeleton = () => {
    const darkMode = useSelector((state) => state.general.darkMode);
    return (
        <Card sx={homepageSmartFormCardSX(darkMode)}>
            <Grid container spacing={1}>
                <Grid item xs={8.5}>
                    <Skeleton
                        variant="rectangular"
                        sx={homepageSmartFormBoxSX(darkMode)}
                    />
                </Grid>
                <Grid item xs={3.5}>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton variant="text" height="45px" width="80%" />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton variant="text" height="25px" width="60%" />
                </Grid>
            </Grid>
        </Card>
    );
};

export default HomepageCardSkeleton;
