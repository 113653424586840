import Grid from "@mui/material/Grid";
import DetailListPiped from "components/displays/detailListPiped";
import DetailListVertical from "components/displays/detailListVertical";
import OnDesktop from "components/wrappers/onDesktop";
import OnMobile from "components/wrappers/onMobile";
import React from "react";
import { useSelector } from "react-redux";

const ReceiptSummaryDetails = () => {
    // Collect states and state dispatchers
    const receipt = useSelector((state) => state.receiptSummary.receipt);

    const summaryDetails = [
        {
            header: "Operating Unit",
            content: receipt?.OPERATING_UNIT,
        },
        {
            header: "Created By",
            content: receipt?.CREATED_BY,
        },
        {
            header: "Created Date",
            content: receipt?.CREATION_DATE,
        },
    ];

    return (
        <Grid container spacing={2} marginBottom="16px">
            <Grid item xs={12}>
                <OnDesktop>
                    <DetailListPiped details={summaryDetails} />
                </OnDesktop>
                <OnMobile>
                    <DetailListVertical condensed details={summaryDetails} />
                </OnMobile>
            </Grid>
        </Grid>
    );
};

ReceiptSummaryDetails.propTypes = {};

export default ReceiptSummaryDetails;
