import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import IBuyCard from "components/displays/iBuyCard";

const ApprovalsCardSkeleton = () => {
    return (
        <Box sx={{ marginTop: "24px" }}>
            <IBuyCard
                additionalSx={{
                    margin: "0px",
                    overflow: "visible",
                }}
            >
                <Stack spacing={1.5} sx={{ padding: "16px" }}>
                    <Stack spacing={3} direction="row">
                        <Skeleton
                            aria-label="cardSkeleton"
                            variant="rectangular"
                            width="40%"
                            height={30}
                            sx={{ borderRadius: "10px" }}
                        />
                        <Skeleton
                            aria-label="cardSkeleton"
                            variant="rectangular"
                            width="50%"
                            height={35}
                            sx={{ borderRadius: "10px" }}
                        />
                    </Stack>
                    {[...Array(6).keys()].map((v) => {
                        return (
                            <Stack spacing={3} direction="row" key={v}>
                                <Skeleton
                                    aria-label="cardSkeleton"
                                    variant="rectangular"
                                    width="40%"
                                    height={15}
                                    sx={{ borderRadius: "10px" }}
                                />
                                <Skeleton
                                    aria-label="cardSkeleton"
                                    variant="rectangular"
                                    width="50%"
                                    height={15}
                                    sx={{ borderRadius: "10px" }}
                                />
                            </Stack>
                        );
                    })}
                </Stack>
                <Stack
                    justifyContent="space-evenly"
                    spacing={1.5}
                    direction="row"
                    sx={{ padding: "16px", mt: "16px" }}
                >
                    <Skeleton
                        variant="rectangular"
                        width="30%"
                        height={30}
                        sx={{ borderRadius: "10px" }}
                    />
                    <Skeleton
                        variant="rectangular"
                        width="30%"
                        height={30}
                        sx={{ borderRadius: "10px" }}
                    />
                    <Skeleton
                        variant="rectangular"
                        width="30%"
                        height={30}
                        sx={{ borderRadius: "10px" }}
                    />
                </Stack>
            </IBuyCard>
        </Box>
    );
};

export default ApprovalsCardSkeleton;
