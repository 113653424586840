import { LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    AMENDMENT_SUBMITTED,
    APPROVED,
    CANCELLED,
    FAILED,
    INVALID_REQUISITION,
    SUBMITTED,
} from "../../../../constants/generalConstants";
import { DecrementApprovalsCount } from "../../../../redux/actions/generalActions";
import axiosApiInstance from "../../../../services/middleware/axios";
import { GET_REQUISITIONS_FOR_APPROVAL } from "../../../../services/requisition/urls";
import {
    stepIconSX,
    stepLabelSX,
} from "../../../../styled/styledSXProperties/styledStepperSX";
import alertDispatcher from "../../../../utils/alertsUtils";
import ApprovalsActionButtons from "../../../approvals/approvalsActionButtons";
import StatusSwitchButton from "../../../buttons/statusSwitchButton";
import FormatNameValue from "../../../displays/formatNameValue";
import OutlinedChip from "../../../displays/outlinedChip";

const DEFAULT = "DEFAULT";

const NO_TREE_MSG_DISPATCHER = {
    [AMENDMENT_SUBMITTED]: "An amended approvals tree is being generated",
    [INVALID_REQUISITION]:
        "Approvals tree unavailable as requisition is invalid",
    [FAILED]: "Approvals tree unavailable as requisition is in a failed state",
    [CANCELLED]: "Approvals tree unavailable as requisition is cancelled",
    [SUBMITTED]: "Approvals tree will be available shortly!",
    [DEFAULT]: "Approvals tree not yet generated",
};

const ApprovalsTree = ({ requisition }) => {
    // Collect states and state dispatchers
    const samAccountName = useSelector((state) => state.auth?.samAccountName);
    const darkMode = useSelector((state) => state.general.darkMode);
    const dispatch = useDispatch();

    // Local states
    const [activeStep, setActiveStep] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [delegatedUsers, setDelegatedUsers] = useState([]);
    const [steps, setSteps] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {}, [steps]); // re-render if steps are changed

    useEffect(() => {
        // Use this to default active step on most recent
        axiosApiInstance
            // TODO
            .get(GET_REQUISITIONS_FOR_APPROVAL + `${requisition.id}`)
            .then((response) => {
                const data = response.data;
                setActiveStep(getActiveStep(data.approvers, data.currentIndex));
                setCurrentIndex(data.currentIndex);
                setSteps(data.approvers);
                setDelegatedUsers(
                    data.delegatedUsers ? data.delegatedUsers : []
                );
                setIsLoading(false);
            })
            .catch((error) => {
                const statusCode = error?.response?.status;
                if (statusCode == 404) {
                    requisition.status == APPROVED && setAutoApprovedTree();
                } else {
                    alertDispatcher(
                        "getApprovalsTree",
                        error?.response?.status,
                        dispatch
                    );
                }
                setIsLoading(false);
            });
    }, []);

    const setAutoApprovedTree = () => {
        setActiveStep(0);
        setCurrentIndex(1);
        setSteps(createAutoApprovedTree());
    };

    const createAutoApprovedTree = () => [
        {
            sequence: 1,
            action: "APPROVE",
            notes: "auto-approved by requester",
            name: requisition?.submittedByName,
        },
    ];

    const getActiveStep = (steps, desiredSequence) => {
        // Sequence can be out of order so can't rely solely on currentIndex
        for (const [index, value] of steps.entries()) {
            if (value.sequence == desiredSequence) {
                return index;
            }
        }
        return 0; // if value can't be fonud just return first result
    };

    const handleApproveReject = (reqId, action) => {
        setSteps((currentSteps) => {
            return currentSteps.map((s) => {
                if (s.sequence == currentIndex) {
                    s.action = action;
                }
                return s;
            });
        });
        dispatch(DecrementApprovalsCount());
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const allowApprovalAction = (step) => {
        return (
            step?.sequence === currentIndex &&
            (samAccountName?.toUpperCase() === step.user?.toUpperCase() ||
                delegatedUsers.includes(samAccountName)) &&
            (!step?.action || step?.action == "")
        );
    };

    return (
        <>
            {isLoading ? (
                <>
                    <Typography marginTop={"0.5rem"} marginBottom={"0.5rem"}>
                        Approvals Tree Loading ...
                    </Typography>
                    <LinearProgress />
                </>
            ) : (
                <>
                    {steps.length > 0 &&
                    requisition?.status != AMENDMENT_SUBMITTED ? (
                        <Stepper
                            activeStep={activeStep}
                            nonLinear
                            orientation="vertical"
                            sx={{ maxHeight: "400px", overflowY: "auto" }}
                        >
                            {steps.map((step, index) => (
                                <Step
                                    key={"approvalsTree" + index}
                                    completed={step?.action == "APPROVE"}
                                >
                                    <StepLabel
                                        StepIconProps={{
                                            sx:
                                                step?.action != "REJECT"
                                                    ? stepIconSX(darkMode)
                                                    : {},
                                            error: step?.action == "REJECT",
                                        }}
                                        sx={stepLabelSX}
                                    >
                                        {step.name}
                                    </StepLabel>
                                    <StepContent>
                                        {allowApprovalAction(step) ? (
                                            <ApprovalsActionButtons
                                                requisition={requisition}
                                                showApproveReject={true}
                                                callbackFunc={
                                                    handleApproveReject
                                                }
                                            />
                                        ) : (
                                            <>
                                                <StatusSwitchButton
                                                    action={step?.action}
                                                />

                                                {step.notes && (
                                                    <Box sx={{ mt: 2 }}>
                                                        <FormatNameValue
                                                            name="Notes"
                                                            value={step.notes}
                                                            variant="body1"
                                                        />
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                        <Box sx={{ mb: 2, mt: 2 }}>
                                            <Button
                                                id={`approvalsBack${index}`}
                                                variant="outlined"
                                                color="secondary"
                                                disabled={index === 0}
                                                onClick={handleBack}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                id={`approvalsNext${index}`}
                                                variant="contained"
                                                onClick={handleNext}
                                                sx={{ mt: 1, mr: 1 }}
                                                disabled={
                                                    index === steps.length - 1
                                                }
                                            >
                                                Next
                                            </Button>
                                        </Box>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    ) : (
                        <OutlinedChip
                            value={
                                NO_TREE_MSG_DISPATCHER[requisition.status] ||
                                NO_TREE_MSG_DISPATCHER[DEFAULT]
                            }
                            size="medium"
                        />
                    )}
                </>
            )}
        </>
    );
};

export default ApprovalsTree;
