import { CardActions, CardHeader, Chip, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import RequisitionDetailsButton from "components/buttons/requisitionDetailsButton";
import IBuyCard from "components/displays/iBuyCard";
import {
    TOOLTIP_APPROVAL_CHIP_AMENDED,
    TOOLTIP_APPROVAL_CHIP_DELEGATED,
} from "constants/tooltipText";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import { datetimeFormat } from "utils/general";

import StatusSwitchButton from "../buttons/statusSwitchButton";
import CurrencyFigure from "../displays/currencyFigure";
import ApprovalsActionButtons from "./approvalsActionButtons";

const ApprovalsCard = ({ requisition, popFromResults }) => {
    // const darkMode = useSelector((state) => state.general.darkMode);
    const samAccountName = useSelector((state) => state.auth.samAccountName);
    const rows = [
        {
            label: "Description",
            value: requisition.requisitionHeaderForm.HEADER_DESCRIPTION,
        },
        { label: "Supplier", value: requisition.supplierName },
        {
            label: "Creation Date",
            value: datetimeFormat(requisition.submittedDate),
        },
        {
            label: "Requester",
            value: requisition.submittedByName
                ? requisition.submittedByName
                : "Unknown",
        },
        { label: "Notes", value: requisition.approvals_info?.notes },
    ];

    const IS_DELEGATED =
        requisition.approvals_info?.DELEGATED_FLAG == "IS_DELEGATED";

    let isCurrentApprover =
        samAccountName?.toUpperCase() ==
        requisition.approvals_info?.currentApprover?.toUpperCase();

    let isCurrentRecord =
        requisition.approvals_info?.currentIndex ==
        requisition.approvals_info?.sequence;

    const approvalsAction = requisition.approvals_info?.action;

    let requiresUserAction =
        (isCurrentApprover || IS_DELEGATED) &&
        isCurrentRecord &&
        (approvalsAction == "" || !approvalsAction);

    return (
        <Box sx={{ marginBottom: "8px", marginTop: "0px" }}>
            <IBuyCard additionalSx={{ margin: "0px" }}>
                <CardHeader
                    titleTypographyProps={{
                        color: "text.subheader",
                        textAlign: "center",
                        marginBottom: 1,
                    }}
                    sx={{ paddingBottom: 1 }}
                    title={`Requisition: ${requisition.id}`}
                    subheader={
                        <Grid container justifyContent="center">
                            {IS_DELEGATED && !isCurrentApprover && (
                                <Tooltip
                                    title={TOOLTIP_APPROVAL_CHIP_DELEGATED}
                                >
                                    <Chip
                                        label="Delegated Approval"
                                        size="small"
                                        color="info"
                                        variant="outlined"
                                        sx={{ mr: 1 }}
                                    />
                                </Tooltip>
                            )}
                            {requisition.amended && (
                                <Tooltip title={TOOLTIP_APPROVAL_CHIP_AMENDED}>
                                    <Chip
                                        label="Requisition Amendment"
                                        size="small"
                                        color="warning"
                                        variant="outlined"
                                    />
                                </Tooltip>
                            )}
                        </Grid>
                    }
                ></CardHeader>
                <CardContent sx={{ pt: 0, pb: 0 }}>
                    <Table
                        size="small"
                        sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                            },
                            display: "block",
                        }}
                    >
                        <colgroup>
                            <col style={{ width: "40%" }} />
                            <col style={{ width: "60%" }} />
                        </colgroup>
                        <TableBody>
                            {rows
                                .filter((row) => {
                                    return row.value && row.value != "";
                                })
                                .map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell
                                            align="left"
                                            style={{ fontWeight: "bold" }}
                                        >
                                            {row.label}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.value}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            <TableRow key="total-amount">
                                <TableCell
                                    align="left"
                                    style={{ fontWeight: "bold" }}
                                >
                                    Total Price
                                </TableCell>
                                <TableCell>
                                    <CurrencyFigure
                                        value={
                                            requisition?.totalPriceBaseCurrency
                                        }
                                        code={requisition?.ouBaseCurrency}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    {requisition.amended && (
                        <Grid
                            container
                            sx={{ justifyContent: "center", mt: 1, mb: 2 }}
                        >
                            <Chip
                                label="REQUISITION AMENDMENT"
                                size="small"
                                color="info"
                                variant="outlined"
                            />
                        </Grid>
                    )}
                </CardContent>
                <CardActions
                    sx={{
                        paddingBottom: 2,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {requiresUserAction ? (
                        <ApprovalsActionButtons
                            requisition={requisition}
                            showApproveReject={true}
                            callbackFunc={popFromResults}
                        />
                    ) : (
                        <>
                            <StatusSwitchButton action={approvalsAction} />
                            <ApprovalsActionButtons
                                requisition={requisition}
                                showApproveReject={false}
                                callbackFunc={popFromResults}
                            />
                        </>
                    )}
                    <Grid item>
                        <RequisitionDetailsButton
                            requisitionId={requisition.id}
                        />
                    </Grid>
                </CardActions>
            </IBuyCard>
        </Box>
    );
};

ApprovalsCard.propTypes = {
    requisition: PropTypes.object,
};

export default ApprovalsCard;
