import moment from "moment";

import {
    IBUY_CHARGE_ACCOUNT_SHAREPOINT_URL,
    NON_CATALOGUE_ITEM,
    PO_APPROVED,
} from "../constants/generalConstants";
import {
    clearPostPOMetadata,
    setAmendedReq,
    setCopyReq,
    setHedgingCheckPassed,
    setIsInternalReq,
    setPageNum,
    setPostPOReq,
    setRequisition,
    setValidated,
    updatePostPOMetadata,
} from "../redux/actions/createRequisitionActions";
import { setChargeAccounts } from "../redux/actions/formLookupsActions";
import { setShowRequisitionDialogue } from "../redux/actions/generalActions";
import axiosApiInstance from "../services/middleware/axios";
import { GET_POS_BY_REQ_LINE_ID } from "../services/procurement/urls";
import {
    iBuyRequisitionBase,
    iBuyRequisitionLineBase,
} from "./../schemas/iBuyRequisition";
import { applyTodaysCorporateRate } from "./copyRequisitionUtils";
import { poShipmentsByReqId } from "./procurementUtils";

/**
 * @param {Function} dispatch
 */
export const resetCreateRequisitionState = (dispatch) => {
    dispatch(setPageNum(0));
    dispatch(setValidated({}));
};

/**
 * Generates req schema on launch of the create requisition dialogue. Loads schema into state. Sets requisition modal
 * page number to 0 and clears any existing validation settings.
 * @param {Object} cart
 * @param {Function} dispatch
 */
export const prepareReqFromCart = (cart, dispatch) => {
    resetCreateRequisitionState(dispatch);
    const suppliers = [
        ...new Set(cart.items.map((item) => item.DEFAULT_SO_SOURCE_TYPE)),
    ];
    const supplier_name = suppliers.every((item) => item === "INTERNAL")
        ? "SSE Stores"
        : cart.items[0].SUPPLIER_NAME;

    const requisitionState = {
        ...iBuyRequisitionBase,
        supplierName: supplier_name,
        cartName: cart.name,
        requisitionHeaderForm: {
            ...iBuyRequisitionBase.requisitionHeaderForm,
            ORG_NAME: cart.items[0].IBUY_CART_ITEM_OU,
        },
        requisitionLines: cart.items.map(
            (item) =>
                // ----
                Object.keys(iBuyRequisitionLineBase).reduce(
                    (acc, key) => {
                        return {
                            ...acc,
                            [key]: item[key]
                                ? item[key]
                                : iBuyRequisitionLineBase[key],
                        };
                    },
                    { ...iBuyRequisitionLineBase }
                )
            // ----
        ),
    };
    dispatch(setCopyReq(false));
    dispatch(setAmendedReq(false));
    dispatch(setPostPOReq(false));
    dispatch(setRequisition(requisitionState));
};

/**
 * Loads selected requisition into redux store; prepares redux
 * payload and opens form.
 * @param {Object} req
 * @param {Function} dispatch
 */
export const copyRequisition = async (req, dispatch) => {
    const updateReq = { ...req };

    // Clear out note to approver (see defect 671100 for details)
    const clearedNoteToApprover = "";
    updateReq["requisitionHeaderForm"]["NOTE_TO_APPROVER"] =
        clearedNoteToApprover;

    // Remove Req Line fields that are not needed
    updateReq.requisitionLines = req.requisitionLines.map((line) => {
        return {
            ...line,
            attachment: [],
            CHANGE_ACTION: undefined,
            HARMONY_REQUISITION_LINE_ID: undefined,
            NOTE_TO_APPROVER: clearedNoteToApprover,
        };
    });

    updateReq.requisitionLines = await applyTodaysCorporateRate(
        updateReq,
        dispatch
    );

    dispatch(setCopyReq(true));
    dispatch(setRequisition(updateReq));
    dispatch(setPageNum(0));
};

/**
 * @param {Object} req
 * @param {Function} dispatch
 */
export const amendRequisition = (req, dispatch) => {
    const updateReq = { ...req };
    updateReq["status"] = "AMENDED";
    const note_to_approver = req["requisitionHeaderForm"]["NOTE_TO_APPROVER"];
    if (note_to_approver.includes("IBUY REQUISITION :")) {
        const res = note_to_approver.substring(32, note_to_approver.length);
        updateReq["requisitionHeaderForm"]["NOTE_TO_APPROVER"] = res;
    }

    req?.requisitionLines.map((line) => {
        const reqLineId = line?.LINE_ATTRIBUTE5;
        axiosApiInstance
            .get(GET_POS_BY_REQ_LINE_ID(reqLineId))
            .then((response) => {
                dispatch(setPostPOReq(true));
                dispatch(
                    updatePostPOMetadata(line?.LINE_ATTRIBUTE5, {
                        poNumber: response.data[0]?.PO_NUMBER,
                        isEdited: false,
                        originalQty: line?.QUANTITY,
                        originalNeedByDate: line?.NEED_BY_DATE,
                        canBeEdited:
                            response.data[0]?.AUTHORIZATION_STATUS ===
                            PO_APPROVED,
                        associatedPOShipments: poShipmentsByReqId(
                            reqLineId,
                            response.data
                        ),
                    })
                );
            })
            .catch(() => {});
    });

    dispatch(setAmendedReq(true));
    dispatch(setRequisition(updateReq));
    dispatch(setPageNum(0));
};

/**
 * This function prevents multiple requisition lines from being placed under the same PO shipment, by ensuring all
 * lines are different (excluding the quantity from comparison).
 * @param {Object[]} reqLines
 * @returns {boolean}
 */
export const allReqLinesDifferent = (reqLines) => {
    // Remove prettier QUANTITY unused variable assignment
    /*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
    const reqLinesNoQty = reqLines.map((line) => {
        const { QUANTITY, ...reqLinesNoQty } = line;
        return reqLinesNoQty;
    });
    const isNonCatalogue = reqLines.some(
        (line) => line.itemType == NON_CATALOGUE_ITEM
    );
    const uniqueLines = new Set(reqLinesNoQty.map(JSON.stringify));
    const hasDuplicates = uniqueLines.size < reqLinesNoQty.length;
    var hasDuplicateItemDesc = false;
    if (reqLines.length > 1 && isNonCatalogue) {
        var valueArr = reqLinesNoQty.map(function (item) {
            return item.ITEM_DESCRIPTION;
        });
        valueArr = valueArr.map((value) => value.toLowerCase());
        hasDuplicateItemDesc = new Set(valueArr).size !== valueArr.length;
    }
    return !hasDuplicates && !hasDuplicateItemDesc;
};

/**
 * @param {Date} date
 * @returns {string}
 */
export const updateDate = (date) => {
    const newDate = moment(date).utc().add(1, "hour").format();
    return newDate;
};

/**
 * @param {Object} postPOMetadata
 * @returns {boolean}
 */
export const someReqLinesAmended = (postPOMetadata) => {
    return Object.keys(postPOMetadata).some(
        (reqLineId) => postPOMetadata[reqLineId]?.isEdited == true
    );
};

/**
 * @param {string} reqLineId
 * @param {Object} reqLine
 * @param {boolean} poUnderEdit
 * @param {boolean} isPostPoReq
 * @param {Object} postPOMetadata
 * @returns {boolean}
 */
export const isQtyAmended = (
    reqLineId,
    reqLine,
    poUnderEdit,
    isPostPoReq,
    postPOMetadata
) =>
    poUnderEdit &&
    isPostPoReq &&
    postPOMetadata[reqLineId]?.originalQty != reqLine?.QUANTITY;

/**
 * @param {string} reqLineId
 * @param {Object} reqLine
 * @param {boolean} poUnderEdit
 * @param {boolean} isPostPoReq
 * @param {Object} postPOMetadata
 * @returns {boolean}
 */
export const isNeedByDateAmended = (
    reqLineId,
    reqLine,
    poUnderEdit,
    isPostPoReq,
    postPOMetadata
) => {
    const newDate = new Date(reqLine?.NEED_BY_DATE);
    const originalDate = new Date(
        postPOMetadata[reqLineId]?.originalNeedByDate
    );
    return isPostPoReq &&
        poUnderEdit &&
        originalDate.getTime() != newDate.getTime()
        ? true
        : false;
};

/**
 * This should only be called if postPOMetadata is defined (when isPostPoReq is true)
 * @param postPOMetadata
 * @param reqLineId
 * @returns {{msg: string, min: (number|number)}|{msg: string, min: number}}
 */
export const postPOAmendQtyRestrictions = (postPOMetadata, reqLineId) => {
    if (!postPOMetadata || !reqLineId) {
        return { min: 0, msg: "" };
    }
    const poShipment =
        postPOMetadata[reqLineId]?.associatedPOShipments[0] || {};
    const qtyRecieved = parseFloat(poShipment?.QUANTITY_RECEIVED) || 0;
    const qtyBilled = parseFloat(poShipment?.QUANTITY_BILLED) || 0;
    const billedHigher = qtyBilled >= qtyRecieved;
    return {
        min: billedHigher ? qtyBilled : qtyRecieved,
        msg: `Cannot amend below ${billedHigher ? qtyBilled : qtyRecieved} as ${
            billedHigher ? "an invoice" : "a receipt"
        } has been issued for this value`,
    };
};

/**
 * @param {Function} dispatch
 */
export const closeCreateReqForm = (dispatch) => {
    // Tears down create req form state
    dispatch(clearPostPOMetadata());
    dispatch(setShowRequisitionDialogue(false));
    dispatch(setCopyReq(false));
    dispatch(setAmendedReq(false));
    dispatch(setPostPOReq(false));
    dispatch(setChargeAccounts([]));
    dispatch(setIsInternalReq(null));
    dispatch(setHedgingCheckPassed(false));
};

/**
 * Hyperlinks user to SSE charge account sharepoint help page
 */
export const navigateToChargeAccountSharepoint = () =>
    window.open(IBUY_CHARGE_ACCOUNT_SHAREPOINT_URL, "_blank").focus();
