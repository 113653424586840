import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

const SegmentHeaderField = ({
    disabled,
    updateHeader,
    segmentIdStateKey,
    segmentNameStateKey,
    SegmentSearchAsYouType,
}) => {
    // Collect states and state dispatchers
    const headerForm = useSelector(
        (state) => state.createRequisition.iBuyRequisition.requisitionHeaderForm
    );

    const onSegmentChange = (newValue) => {
        updateHeader(newValue[segmentIdStateKey], segmentIdStateKey);
        updateHeader(newValue[segmentNameStateKey], segmentNameStateKey);
        updateHeader(false, "IS_VALIDATED");
    };

    const selectedSegment =
        headerForm[segmentIdStateKey] && headerForm[segmentNameStateKey]
            ? {
                  [segmentIdStateKey]: headerForm[segmentIdStateKey],
                  [segmentNameStateKey]: headerForm[segmentNameStateKey],
              }
            : null;

    return (
        <SegmentSearchAsYouType
            disabled={disabled}
            selectedValue={selectedSegment}
            setSelectedValue={onSegmentChange}
        />
    );
};

SegmentHeaderField.propTypes = {
    disabled: PropTypes.any,
    updateHeader: PropTypes.func,
    segmentIdStateKey: PropTypes.string,
    segmentNameStateKey: PropTypes.string,
    SegmentSearchAsYouType: PropTypes.func,
};

export default SegmentHeaderField;
