import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LoadingButton from "@mui/lab/LoadingButton";
import { CardContent, CardHeader, Tooltip, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import PurchaseOrderChip from "components/buttons/purchaseOrderChip";
import IBuyCard from "components/displays/iBuyCard";
import ProcurementCard from "components/displays/procurementCard";
import ProcurementHeader from "components/displays/procurementHeader";
import TabbedPanels from "components/tabbedPanels";
import OnDesktop from "components/wrappers/onDesktop";
import OnMobile from "components/wrappers/onMobile";
import { MOBILE_MEDIA_QUERY } from "constants/mediaQueries";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import { PO_APPROVED } from "../../constants/generalConstants";
import { REQUISITION_SUMMARY_PAGE } from "../../constants/routes";
import {
    TOOLTIP_PO_PDF_BUTTON_AVAILABLE,
    TOOLTIP_PO_PDF_BUTTON_NA,
} from "../../constants/tooltipText";
import axiosApiInstance from "../../services/middleware/axios";
import { getPoPdf } from "../../services/procurement/procurementAPIs";
import { GET_PO_BY_ID } from "../../services/procurement/urls";
import { contentBoxL2 } from "../../styled/styledSXProperties/styledBoxSX";
import alertDispatcher from "../../utils/alertsUtils";
import { getReqsAssociatedWithPO } from "../../utils/procurementUtils";
import CopyTypography from "../displays/copyTypography";
import CurrencyFigure from "../displays/currencyFigure";
import IBuyPaper from "../displays/iBuyPaper";
import PageNotFound from "../pageNotFound/pageNotFound";
import RequisitionSummarySkeleton from "../skeletons/requisitionSummarySkeleton";
import FormatNameValue from "./../displays/formatNameValue";
import PoShipmentLineStatusChip from "./poShipmentLineStatusChip";
import PoLinesTable from "./poSummaryTable";

// Styles for typography components
const headerStyle = { color: "text.subheader", variant: "h4" };
const subHeaderStyle = {
    color: "text.subheader",
    textAlign: "center",
    fontWeight: "bold",
    variant: "h5",
    pb: 1,
};
const textStyle = { color: "text.primary", variant: "body1" };

const convertOU = {
    105: "SSE",
    121: "GEN",
    1256: "IRE",
    203: "RENUK",
    4461: "GENIRE",
};

/**
 * Purchase Order Card Constructor
 * @param {object} purchaseOrder
 * @param {Array} associatedReqs
 * @param {object} summaryData
 * @param {function} navigate
 * @returns
 */
const poCardData = (purchaseOrder, associatedReqs, summaryData, navigate) => [
    {
        title: "General",
        data: [
            { name: "Buyer", value: purchaseOrder?.AGENT_NAME },
            {
                name: "Order Date",
                value: purchaseOrder?.CREATION_DATE,
            },
            { name: "Date Approved", value: purchaseOrder?.APPROVED_DATE },
            {
                name: "Associated Requisitions",
                component: (
                    <>
                        {associatedReqs.length > 0 ? (
                            associatedReqs.map((reqId, index) => (
                                <Link
                                    key={index}
                                    component="button"
                                    color="secondary"
                                    variant="body1"
                                    sx={{ mr: "8px" }}
                                    onClick={() =>
                                        navigate(
                                            REQUISITION_SUMMARY_PAGE(reqId)
                                        )
                                    }
                                >
                                    {reqId}
                                </Link>
                            ))
                        ) : (
                            <Typography component={"span"} variant="body1">
                                Unable to display associated requisitions
                            </Typography>
                        )}
                    </>
                ),
            },
        ],
    },
    {
        title: "Supplier",
        data: [
            { name: "Supplier Name", value: purchaseOrder?.SUPPLIER_NAME },
            { name: "Supplier Number", value: purchaseOrder?.SUPPLIER_NUMBER },
            { name: "Supplier Site", value: purchaseOrder?.SUPPLIER_SITE_CODE },
            {
                name: "Supplier Contact",
                value:
                    purchaseOrder?.SUPPLIER_CONTACT_NAME &&
                    purchaseOrder?.SUPPLIER_CONTACT_NAME !== " "
                        ? purchaseOrder?.SUPPLIER_CONTACT_NAME
                        : "-",
            },
        ],
    },
    {
        title: "Delivery",
        data: [
            {
                name: "Deliver to Location Code",
                value: summaryData?.deliverToLocCode,
            },
            {
                name: "Ship to Location Code",
                value: purchaseOrder?.SHIP_TO_LOCATION_CODE,
            },
            { name: "Order date", value: purchaseOrder?.CREATION_DATE },
            { name: "FOB Description", value: purchaseOrder?.FOB_DESCRIPTION },
        ],
    },
    {
        title: "Notes",
        data: [
            {
                name: "Note to Authoriser",
                value: purchaseOrder?.NOTE_TO_AUTHORIZER,
            },
            { name: "Note to Vendor", value: purchaseOrder?.NOTE_TO_VENDOR },
            {
                name: "Note to Receiver",
                value: purchaseOrder?.NOTE_TO_RECEIVER,
            },
            { name: "Additional Comments", value: purchaseOrder?.COMMENTS },
        ],
    },
];

/**
 * Purchase Order Summary Page
 */
const PoSummaryPage = () => {
    // Collect states and state dispatchers
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Define local states
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isPdfDownloading, setIsPdfDownloading] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [url, setURL] = useState("");
    const [summaryData, setSummaryData] = useState({});

    const { poNumber } = useParams();

    useEffect(() => {
        setIsLoading(true);
        setNotFound(false);
        axiosApiInstance
            .get(GET_PO_BY_ID(poNumber))
            .then((response) => {
                const data = response.data;
                setData(data);
                calculateTotals(data);
                setIsLoading(false);
            })
            .catch((e) => {
                alertDispatcher("purchaseOrder", e?.response?.status, dispatch);
                setNotFound(true);
                setIsLoading(false);
            });
    }, [poNumber]);

    const downloadPoPdf = () => {
        axiosApiInstance
            .get(getPoPdf(poNumber, setIsPdfDownloading, setURL, dispatch))
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        // Create "a" element with download url and name, click it, then remove it.
        if (url != "") {
            const downloadLink = document.createElement("a");
            downloadLink.href = url;
            downloadLink.download = `${poNumber}.pdf`;
            downloadLink.click();
            downloadLink.remove();
            setURL("");
            setIsPdfDownloading(false);
        }
    }, [url]);

    /**
     * Generates total ordered, invoiced and received
     * quantities using GET po API response
     * @param {Object} data - GET po API response
     */
    const calculateTotals = (data) => {
        if (data) {
            // Total Ordered = Quantity Ordered - Quantity Cancelled
            var totalOrdered = 0;
            var totalReceived = 0;
            var totalInvoiced = 0;
            var totalOrderedAmount = 0;
            var totalReceivedAmount = 0;
            var totalInvoicedAmount = 0;
            var deliverToLocCode =
                data.poLines?.[0]?.poLocations?.[0]?.poDist?.[0]
                    ?.DELIVER_TO_LOCATION_CODE;
            for (let line of data.poLines) {
                var unitPrice = line.UNIT_PRICE;
                for (let loc of line.poLocations) {
                    totalOrdered += loc.QUANTITY - loc.QUANTITY_CANCELLED;
                    totalReceived += loc.QUANTITY_RECEIVED;
                    totalInvoiced += loc.QUANTITY_BILLED;
                    totalOrderedAmount +=
                        (loc.QUANTITY - loc.QUANTITY_CANCELLED) * unitPrice;
                    totalReceivedAmount += loc.QUANTITY_RECEIVED * unitPrice;
                    totalInvoicedAmount += loc.QUANTITY_BILLED * unitPrice;
                }
            }

            setSummaryData({
                totalOrdered,
                totalReceived,
                totalInvoiced,
                deliverToLocCode,
                totalOrderedAmount,
                totalReceivedAmount,
                totalInvoicedAmount,
            });

            // Total Received = Quantity Received
            // Invoiced = Billed Amount
        }
    };

    const associatedReqs = getReqsAssociatedWithPO(data);
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    return (
        <Box sx={contentBoxL2}>
            {notFound ? (
                <PageNotFound />
            ) : !isLoading ? (
                <Grid
                    container
                    spacing={3}
                    alignContent="flex-start"
                    alignItems="start"
                >
                    {/* Title Bar */}
                    <Grid item xs={12}>
                        <ProcurementHeader
                            Header={
                                <>
                                    <Typography {...headerStyle}>
                                        Purchase Order Number:&nbsp;
                                    </Typography>
                                    <CopyTypography
                                        {...headerStyle}
                                        sx={{ mr: 2, cursor: "pointer" }}
                                    >
                                        {data?.PO_NUMBER}
                                    </CopyTypography>
                                    <OnDesktop>
                                        <PurchaseOrderChip
                                            data={data}
                                            variant="detailed"
                                        />
                                    </OnDesktop>
                                </>
                            }
                            Buttons={
                                <>
                                    <OnMobile>
                                        <PurchaseOrderChip
                                            data={data}
                                            variant="detailed"
                                        />
                                    </OnMobile>
                                    <Tooltip
                                        title={
                                            data?.AUTHORIZATION_STATUS ==
                                            PO_APPROVED
                                                ? TOOLTIP_PO_PDF_BUTTON_AVAILABLE
                                                : TOOLTIP_PO_PDF_BUTTON_NA
                                        }
                                        placement="top"
                                        followCursor={true}
                                    >
                                        <span>
                                            <LoadingButton
                                                disabled={
                                                    data?.AUTHORIZATION_STATUS !==
                                                    PO_APPROVED
                                                }
                                                endIcon={<PictureAsPdfIcon />}
                                                loading={isPdfDownloading}
                                                onClick={downloadPoPdf}
                                                size={
                                                    isDesktop
                                                        ? "medium"
                                                        : "small"
                                                }
                                                variant="contained"
                                                color="secondary"
                                                sx={{ mr: "24px" }}
                                            >
                                                Download PO
                                            </LoadingButton>
                                        </span>
                                    </Tooltip>
                                </>
                            }
                            SubHeader={
                                <>
                                    <FormatNameValue
                                        name="Supplier"
                                        value={data?.SUPPLIER_NAME}
                                        variant={textStyle.variant}
                                        sx={{ mr: "16px" }}
                                    />
                                    <OnDesktop>
                                        <Typography
                                            {...textStyle}
                                            sx={{ mr: "16px" }}
                                        >
                                            |
                                        </Typography>
                                    </OnDesktop>
                                    <FormatNameValue
                                        name="Created Date"
                                        value={data?.CREATION_DATE}
                                        variant={textStyle.variant}
                                        sx={{ mr: "16px" }}
                                    />
                                    <OnDesktop>
                                        <Typography
                                            {...textStyle}
                                            sx={{ mr: "16px" }}
                                        >
                                            |
                                        </Typography>
                                    </OnDesktop>
                                    <FormatNameValue
                                        name="Operating Unit"
                                        value={
                                            convertOU[data?.ORG_ID] ??
                                            data?.ORG_ID
                                        }
                                        variant={textStyle.variant}
                                        sx={{ mr: "16px" }}
                                    />
                                </>
                            }
                        />
                    </Grid>
                    {/* Row 2: approvals and line item summary */}
                    <Grid item container xs={12} spacing={3}>
                        {data && (
                            <>
                                <OnDesktop>
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        lg={8}
                                        spacing={3}
                                        order={isDesktop ? 1 : 2}
                                        pt={0}
                                    >
                                        {poCardData(
                                            data,
                                            associatedReqs,
                                            summaryData,
                                            navigate
                                        ).map((card, idx) => {
                                            return (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    xl={6}
                                                    key={idx}
                                                    mb={0}
                                                >
                                                    <ProcurementCard
                                                        data={card}
                                                        key={idx}
                                                        isDesktop={isDesktop}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </OnDesktop>
                                <OnMobile>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ mt: 0 }}
                                        order={isDesktop ? 1 : 2}
                                    >
                                        <IBuyPaper
                                            additionalSx={{ padding: 0 }}
                                        >
                                            <TabbedPanels
                                                title="Invoice summary tabs"
                                                tabs={poCardData(
                                                    data,
                                                    associatedReqs,
                                                    summaryData,
                                                    navigate
                                                ).map((card, idx) => {
                                                    return {
                                                        name: card.title,
                                                        id: card.title,
                                                        content: (
                                                            <ProcurementCard
                                                                data={card}
                                                                key={idx}
                                                                colWidth={40}
                                                            />
                                                        ),
                                                    };
                                                })}
                                            />
                                        </IBuyPaper>
                                    </Grid>
                                </OnMobile>
                            </>
                        )}
                        <Grid item xs={12} lg={4} order={isDesktop ? 2 : 1}>
                            <IBuyCard additionalSx={{ mb: 0 }}>
                                <CardHeader
                                    sx={{ pb: 0 }}
                                    title={
                                        <Grid container justifyContent="center">
                                            <Typography {...subHeaderStyle}>
                                                PO Summary
                                            </Typography>
                                        </Grid>
                                    }
                                />
                                <CardContent>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid
                                            container
                                            spacing={0}
                                            justifyContent={
                                                isDesktop
                                                    ? "space-between"
                                                    : "center"
                                            }
                                        >
                                            <Grid item xs={6}>
                                                <Typography
                                                    align="center"
                                                    color="text.secondary"
                                                    variant={"body1"}
                                                >
                                                    Total Ordered
                                                </Typography>
                                                <Typography
                                                    variant={
                                                        isDesktop ? "h1" : "h3"
                                                    }
                                                    align="center"
                                                    color="subheader"
                                                >
                                                    <NumberFormat
                                                        value={
                                                            summaryData
                                                                ? summaryData.totalOrdered
                                                                : 0
                                                        }
                                                        displayType={"text"}
                                                        decimalScale={2}
                                                    />
                                                </Typography>
                                                <Typography
                                                    align="center"
                                                    color="text.secondary"
                                                    variant={"body1"}
                                                >
                                                    Total Received
                                                </Typography>
                                                <Typography
                                                    variant={
                                                        isDesktop ? "h1" : "h3"
                                                    }
                                                    align="center"
                                                >
                                                    <NumberFormat
                                                        value={
                                                            summaryData
                                                                ? summaryData.totalReceived
                                                                : 0
                                                        }
                                                        displayType={"text"}
                                                        decimalScale={2}
                                                    />
                                                </Typography>
                                                <Typography
                                                    align="center"
                                                    color="text.secondary"
                                                    variant={"body1"}
                                                >
                                                    Total Invoiced
                                                </Typography>
                                                <Typography
                                                    variant={
                                                        isDesktop ? "h1" : "h3"
                                                    }
                                                    align="center"
                                                >
                                                    <NumberFormat
                                                        value={
                                                            summaryData
                                                                ? summaryData.totalInvoiced
                                                                : 0
                                                        }
                                                        displayType={"text"}
                                                        decimalScale={2}
                                                    />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    align="center"
                                                    color="text.secondary"
                                                    variant={"body1"}
                                                >
                                                    Amount Ordered
                                                </Typography>
                                                <Typography
                                                    variant={
                                                        isDesktop ? "h1" : "h3"
                                                    }
                                                    align="center"
                                                    color="subheader"
                                                >
                                                    <CurrencyFigure
                                                        value={
                                                            summaryData
                                                                ? summaryData.totalOrderedAmount
                                                                : 0
                                                        }
                                                        code={
                                                            data?.CURRENCY_CODE
                                                        }
                                                    />
                                                </Typography>
                                                <Typography
                                                    align="center"
                                                    color="text.secondary"
                                                    variant={"body1"}
                                                >
                                                    Amount Received
                                                </Typography>
                                                <Typography
                                                    variant={
                                                        isDesktop ? "h1" : "h3"
                                                    }
                                                    align="center"
                                                >
                                                    <CurrencyFigure
                                                        value={
                                                            summaryData
                                                                ? summaryData.totalReceivedAmount
                                                                : 0
                                                        }
                                                        code={
                                                            data?.CURRENCY_CODE
                                                        }
                                                    />
                                                </Typography>
                                                <Typography
                                                    align="center"
                                                    color="text.secondary"
                                                    variant={"body1"}
                                                >
                                                    Amount Invoiced
                                                </Typography>
                                                <Typography
                                                    variant={
                                                        isDesktop ? "h1" : "h3"
                                                    }
                                                    align="center"
                                                >
                                                    <CurrencyFigure
                                                        value={
                                                            summaryData
                                                                ? summaryData.totalInvoicedAmount
                                                                : 0
                                                        }
                                                        code={
                                                            data?.CURRENCY_CODE
                                                        }
                                                    />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </CardContent>
                            </IBuyCard>
                        </Grid>
                        <Grid item xs={12} xl={12} order={3}>
                            <IBuyCard>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <OnMobile>
                                            <Typography
                                                color="text.subheader"
                                                textAlign="center"
                                                fontWeight="bold"
                                                variant="h5"
                                                pt={1}
                                                pb={0}
                                            >
                                                Purchase Order Lines
                                            </Typography>
                                            {data.poLines.map((poLine, idx) => {
                                                return (
                                                    <IBuyCard
                                                        key={idx}
                                                        additionalSx={{
                                                            mb: 0,
                                                            mt: 1,
                                                        }}
                                                    >
                                                        <ProcurementCard
                                                            colWidth={40}
                                                            data={{
                                                                title: `Line ${poLine?.LINE_NUM}`,
                                                                data: [
                                                                    {
                                                                        name: "Line Type",
                                                                        value: poLine?.LINE_TYPE,
                                                                    },
                                                                    {
                                                                        name: "Item",
                                                                        value:
                                                                            poLine?.ITEM ??
                                                                            "-",
                                                                    },
                                                                    {
                                                                        name: "Item Description",
                                                                        value: poLine?.ITEM_DESCRIPTION,
                                                                    },
                                                                    {
                                                                        name: "Unit of Measure",
                                                                        value: poLine?.UNIT_MEAS_LOOKUP_CODE,
                                                                    },
                                                                    {
                                                                        name: "Quantity Ordered",
                                                                        value: poLine?.QUANTITY,
                                                                    },
                                                                    {
                                                                        name: "Price",
                                                                        value: (
                                                                            <CurrencyFigure
                                                                                value={
                                                                                    poLine?.UNIT_PRICE
                                                                                }
                                                                                code={
                                                                                    data.CURRENCY_CODE
                                                                                }
                                                                            />
                                                                        ),
                                                                    },
                                                                    {
                                                                        name: "Category",
                                                                        value: poLine?.CATEGORY,
                                                                    },
                                                                    {
                                                                        name: "Agreement Number",
                                                                        value: poLine.BPA
                                                                            ? poLine.BPA
                                                                            : poLine?.CONTRACT,
                                                                    },
                                                                    {
                                                                        name: "Status",
                                                                        value: (
                                                                            <PoShipmentLineStatusChip
                                                                                closedCode={
                                                                                    poLine?.CLOSED_CODE
                                                                                }
                                                                                cancelFlag={
                                                                                    poLine?.CANCEL_FLAG
                                                                                }
                                                                            />
                                                                        ),
                                                                    },
                                                                ],
                                                            }}
                                                            isDesktop={
                                                                isDesktop
                                                            }
                                                            cardTitle={true}
                                                        />
                                                    </IBuyCard>
                                                );
                                            })}
                                        </OnMobile>
                                        <OnDesktop>
                                            <Typography
                                                fontWeight="bold"
                                                variant="h5"
                                                color="text.subheader"
                                                px={2}
                                                py={1}
                                            >
                                                Purchase Order Lines
                                            </Typography>
                                            <PoLinesTable
                                                poLines={data.poLines}
                                                currency={data.CURRENCY_CODE}
                                            />
                                        </OnDesktop>
                                    </Grid>
                                </Grid>
                            </IBuyCard>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <RequisitionSummarySkeleton />
            )}
        </Box>
    );
};

export default PoSummaryPage;
