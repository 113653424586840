import { DEFAULT_CART_ID } from "../../constants/generalConstants";
// Action types
import {
    ADD_NEW_CART,
    CLEAR_CART,
    CREATE_NAMED_CART,
    DELETE_CART,
    LOAD_NAMED_CARTS,
    RENAME_CART,
    SET_CART_ITEMS,
    SET_DEFAULT_CART_USER_ID,
} from "../actionTypes";
import { initialState } from "../initialState";

const shoppingCartReducer = (state = initialState.shoppingCart, action) => {
    switch (action.type) {
        // Adds new cart
        case ADD_NEW_CART:
            return {
                ...state,
                [action.payload.newCartKey]: {
                    ...action.payload.newCart,
                    id: action.payload.newCartKey,
                },
            };
        // Clears general shopping cart
        case CLEAR_CART:
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    items: [],
                },
            };
        // Creates a new named cart a clears the base cart (in most cases the default cart)
        case CREATE_NAMED_CART:
            return {
                ...state,
                [action.payload.baseCartKey]: {
                    ...state[action.payload.baseCartKey],
                    items: [],
                },
                [action.payload.newCartKey]: {
                    ...state[action.payload.baseCartKey],
                    name: action.payload.name,
                    id: action.payload.newCartKey,
                },
            };
        // Set enabled status of specified cart to false
        case DELETE_CART:
            return {
                ...Object.keys(state)
                    .filter((cartId) => cartId != action.payload)
                    .reduce((acc, cartId) => {
                        return { ...acc, [cartId]: state[cartId] };
                    }, {}),
            };
        // Adds database named carts to state
        case LOAD_NAMED_CARTS:
            return {
                ...state,
                ...action.payload.reduce(
                    (acc, cart) => ({ ...acc, [cart.id]: cart }),
                    {}
                ),
            };
        // Renames cart
        case RENAME_CART:
            return {
                ...state,
                [action.payload.cartKey]: {
                    ...state[action.payload.cartKey],
                    name: action.payload.newName,
                },
            };
        // Update cart items property
        case SET_CART_ITEMS:
            return {
                ...state,
                [action.payload.cartKey]: {
                    ...state[action.payload.cartKey],
                    items: action.payload.newItems,
                },
            };
        // Add username to default cart
        case SET_DEFAULT_CART_USER_ID:
            return {
                ...state,
                [DEFAULT_CART_ID]: {
                    ...state[DEFAULT_CART_ID],
                    userId: action.payload,
                },
            };
        default:
            return state;
    }
};

export default shoppingCartReducer;
