import PropTypes from "prop-types";

import { USER_RATE } from "../../../constants/generalConstants";
import CurrencyFigure from "../../displays/currencyFigure";
import DetailListVertical from "../../displays/detailListVertical";
import InfoLine from "../../displays/infoLine";

const ConversionDisplay = ({ product, displayCurrency }) => {
    const requiredFields = [
        {
            name: "Quantity",
            isMissing: !product.QUANTITY,
        },
        {
            name: "Unit Price",
            isMissing: !product.UNIT_PRICE,
        },
        {
            name: "Rate Date",
            isMissing: !product.RATE_DATE,
        },
        {
            name: "Rate",
            isMissing: product.RATE_TYPE === USER_RATE && !product.RATE,
        },
    ];

    if (requiredFields.some((requiredField) => requiredField.isMissing))
        return (
            <InfoLine>
                Fill in the following fields to calculate the local currency
                conversion:
                {requiredFields.map((requiredField) => {
                    if (requiredField.isMissing)
                        return (
                            <li key={requiredField.name}>
                                {requiredField.name}
                            </li>
                        );
                })}
            </InfoLine>
        );

    return (
        <DetailListVertical
            condensed={true}
            details={[
                {
                    header: "Conversion",
                    content: `${product.CURRENCY_CODE} to ${displayCurrency}`,
                },
                {
                    header: "Exchange rate",
                    content: product?.RATE,
                },
                {
                    header: "Local price",
                    content: (
                        <CurrencyFigure
                            value={
                                product.UNIT_PRICE *
                                product.QUANTITY *
                                product.RATE
                            }
                            code={displayCurrency}
                        />
                    ),
                },
            ]}
        />
    );
};

export default ConversionDisplay;

ConversionDisplay.propTypes = {
    product: PropTypes.object.isRequired,
    displayCurrency: PropTypes.string.isRequired,
};
