import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Typography, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { MOBILE_MEDIA_QUERY } from "constants/mediaQueries";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { baseStyledPaperSX } from "styled/styledSXProperties/styledPaperSX";
import { objectOnCondition } from "utils/general";

const YesNoPrompt = ({
    title,
    subtitle,
    promptOpen,
    setPromptOpen,
    onClickYes,
}) => {
    const darkMode = useSelector((state) => state.general.darkMode);
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    return (
        <Dialog
            open={promptOpen}
            onClose={() => setPromptOpen(false)}
            aria-labelledby="confirm-dialog"
            fullScreen={!isDesktop}
            PaperProps={objectOnCondition(!isDesktop, {
                sx: {
                    ...baseStyledPaperSX(darkMode),
                    "&>.MuiDialogContent-root": {
                        flex: "none",
                    },
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                },
            })}
        >
            <DialogTitle id="confirm-dialog" variant="h3">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{subtitle}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    endIcon={<CancelIcon />}
                    variant="contained"
                    onClick={() => setPromptOpen(false)}
                    color="secondary"
                    sx={{ margin: "-16px 16px 16px 16px" }}
                >
                    <Typography>No</Typography>
                </Button>
                <Button
                    endIcon={<CheckCircleOutlineIcon />}
                    variant="contained"
                    onClick={() => {
                        setPromptOpen(false);
                        onClickYes();
                    }}
                    color="error"
                    sx={{ margin: "-16px 16px 16px 16px" }}
                >
                    <Typography>Yes</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

YesNoPrompt.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    promptOpen: PropTypes.bool,
    setPromptOpen: PropTypes.func,
    onClickYes: PropTypes.func,
};

export default YesNoPrompt;
