import { SERVER_SUPPLIER } from "../../constants/snackbarMessage";
import {
    setContactsInitialLoading,
    setSitesInitialLoading,
    setSupplierContactDetails,
    setSupplierSites,
} from "../../redux/actions/nonCatFormActions";
import alertDispatcher from "../../utils/alertsUtils";
import axiosApiInstance from "./../middleware/axios";
import {
    SUPPLIER_NAME_AUTOCOMPLETE_GET,
    SUPPLIER_NAME_AUTOCOMPLETE_GET_ASL,
    SUPPLIER_SITE_CONTACTS_GET,
    SUPPLIER_SITE_DETAILS_GET,
} from "./urls";

/**
 * Calls supplier list autocomplete API. If `isAsl` flag is
 * set to true, it queries the API in asl mode. In this mode,
 * only suppliers on the approved supplier list are returned.
 * @param {*} dispatch - redux dispatcher function
 * @param {*} supplierName - supplier name search term
 * @param {*} setOptions - setter function for MUI autocomplete options
 * @param {*} value - existing value selected in MUI autocomplete
 * @param {*} setSupplierNamesLoading - setter for loading state for API
 * @param {*} isAsl - flag for if approved supplier list should be called
 * @param {{OU: string, CATEGORY_ID: string}} aslParams - object of OU and
 * category ID params to be passed to ASL api.
 */
export const supplierAutocompleteGet = async (
    dispatch,
    supplierName,
    setOptions,
    value,
    setSupplierNamesLoading,
    isAsl,
    aslParams
) => {
    try {
        let newOptions = [];
        // If an input is selected, set it as the option
        if (value) {
            newOptions = [value];
        }
        setSupplierNamesLoading(true);
        let encodedSupplierName = encodeURIComponent(supplierName);
        if (supplierName.includes("/"))
            encodedSupplierName = encodeURIComponent(encodedSupplierName);
        const query = isAsl
            ? SUPPLIER_NAME_AUTOCOMPLETE_GET_ASL(
                  encodedSupplierName,
                  aslParams?.OU,
                  aslParams?.CATEGORY_ID
              )
            : SUPPLIER_NAME_AUTOCOMPLETE_GET(
                  encodedSupplierName,
                  aslParams?.OU
              );
        let response = await axiosApiInstance.get(query);
        const results = response.data;

        if (results) {
            newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
        setSupplierNamesLoading(false);
    } catch (error) {
        alertDispatcher(
            "supplier",
            error?.response?.status,
            dispatch,
            SERVER_SUPPLIER("supplier")
        );
    }
};

export const getSupplierSiteDetails = async (
    dispatch,
    OU,
    id,
    setSitesDisabled,
    setContactDisabled,
    formPreLoad = false
) => {
    try {
        formPreLoad && dispatch(setSitesInitialLoading(true));
        const response = await axiosApiInstance.get(
            SUPPLIER_SITE_DETAILS_GET(id) + `?OU=${OU}`
        );
        const sites = response.data.SUPPLIER_SITES;
        dispatch(setSupplierSites(sites));
        if (!formPreLoad) {
            setSitesDisabled(false);
            // If no site available, enable text boxes so user is not blocked
            sites.length == 0 && setContactDisabled(false);
        } else {
            dispatch(setSitesInitialLoading(false));
        }
    } catch (error) {
        alertDispatcher(
            "supplier",
            error?.response?.status,
            dispatch,
            SERVER_SUPPLIER("supplier site")
        );
        formPreLoad && dispatch(setSitesInitialLoading(false));
    }
};

export const getSupplierContactDetails = async (
    dispatch,
    site_id,
    setContactDisabled,
    setContactInfo,
    formPreLoad = false
) => {
    try {
        formPreLoad && dispatch(setContactsInitialLoading(true));
        const response = await axiosApiInstance.get(
            SUPPLIER_SITE_CONTACTS_GET(site_id)
        );
        const formattedResponse = response.data.reduce((acc, value) => {
            const { NAME, SUPPLIER_CONTACT_ID, PHONE } = value;
            return [...acc, { SUPPLIER_CONTACT_ID, PHONE, title: NAME }];
        }, []);
        dispatch(setSupplierContactDetails(formattedResponse));
        if (!formPreLoad) {
            // If only one contact return, pre-populate the value
            formattedResponse.length == 1
                ? setContactInfo(formattedResponse[0])
                : setContactInfo(null);
            setContactDisabled(false);
        } else {
            dispatch(setContactsInitialLoading(false));
        }
    } catch (error) {
        alertDispatcher(
            "supplier",
            error?.response?.status,
            dispatch,
            SERVER_SUPPLIER("supplier contact")
        );
        formPreLoad && dispatch(setContactsInitialLoading(false));
    }
};
