import PropTypes from "prop-types";
import React from "react";

import CompanyCodeSearchAsYouType from "../../../../../inputs/searchAsYouType/chargeAccount/companyCodeSearchAsYouType";
import SegmentHeaderField from "./segmentHeaderField";

const CompanyCodeHeaderField = ({ disabled, updateHeader }) => {
    return (
        <SegmentHeaderField
            disabled={disabled}
            updateHeader={updateHeader}
            segmentIdStateKey="SEGMENT1_ID"
            segmentNameStateKey="SEGMENT1_NAME"
            SegmentSearchAsYouType={CompanyCodeSearchAsYouType}
        />
    );
};

CompanyCodeHeaderField.propTypes = {
    disabled: PropTypes.any,
    updateHeader: PropTypes.func,
};

export default CompanyCodeHeaderField;
