import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";
import { DialogActions, useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { MOBILE_MEDIA_QUERY } from "constants/mediaQueries";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { styledDialogChildSX } from "styled/styledSXProperties/styledDialogueSX";
import { baseStyledPaperSX } from "styled/styledSXProperties/styledPaperSX";

import {
    closeCreateReceiptDialogue,
    submitReceiptCreation,
} from "../../../utils/createReceiptUtils";
import CustomDialogTitle from "../../customDialog/customDialogTitle";
import CreateReceiptSkeleton from "../../skeletons/createReceiptSkeleton";
import YesNoPrompt from "../prompts/yesNoPrompt";
import CreateReceiptLines from "./sections/createReceiptLines/createReceiptLines";
import HeaderInformation from "./sections/headerInformation";
import SummaryLine from "./sections/summaryLine";

// Split into separate component so the useForm hook
// re-renders the error state on form load

const CreateReceiptDialogueContentDispatcher = () => {
    // Collect states and state dispatchers
    const isLoading = useSelector((state) => state.createReceipt.isLoading);
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);
    const dispatch = useDispatch();
    // Req details
    const poShipments = useSelector((state) => state.createReceipt.poShipments);
    const headerInput = useSelector((state) => state.createReceipt.headerInput);
    const iBuyReqNumber = useSelector(
        (state) => state.createReceipt.iBuyReqNumber
    );
    const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);
    const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false);

    const onClickSubmit = (e) =>
        handleSubmit(() => setConfirmSubmitOpen(true))(e);

    const isSubmitButtonDisabled = () =>
        !poShipments.some(
            (shipment) =>
                shipment.AVAILABLE_FOR_RECEIPT &&
                shipment.PO_APPROVED &&
                shipment.checked &&
                !shipment.CLOSED_FOR_RECEIPT
        );

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    return (
        <>
            <CustomDialogTitle
                onClose={() => setConfirmCancelOpen(true)}
                title={`Create Receipt for${
                    isDesktop ? " Requisition" : ""
                }: ${iBuyReqNumber}`}
                tooltip="Close receipting form"
            />
            <DialogContent sx={styledDialogChildSX}>
                {isLoading ? (
                    <CreateReceiptSkeleton />
                ) : (
                    <>
                        <SummaryLine />
                        <HeaderInformation
                            errors={errors}
                            register={register}
                        />
                        <CreateReceiptLines
                            errors={errors}
                            register={register}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions sx={styledDialogChildSX}>
                <LoadingButton
                    color="success"
                    disabled={isSubmitButtonDisabled()}
                    loading={false}
                    loadingPosition="end"
                    endIcon={<SendIcon />}
                    onClick={onClickSubmit}
                    variant="contained"
                >
                    Submit
                </LoadingButton>
            </DialogActions>

            {/* Cancel creation form prompt */}
            <YesNoPrompt
                title="Close receipt creation form?"
                subtitle="Closing this form prior to submission will result in all entered data being lost. Are you sure you wish to close this form?"
                promptOpen={confirmCancelOpen}
                setPromptOpen={setConfirmCancelOpen}
                onClickYes={() => closeCreateReceiptDialogue(dispatch)}
            />

            {/* Submit creation form prompt */}
            <YesNoPrompt
                title="Submit Receipt?"
                subtitle="On submission, you will be unable to make corrections or further receipt against this requisition for a short period of time whilst it is validated by Harmony"
                promptOpen={confirmSubmitOpen}
                setPromptOpen={setConfirmSubmitOpen}
                onClickYes={() =>
                    submitReceiptCreation(
                        poShipments,
                        headerInput,
                        dispatch,
                        iBuyReqNumber
                    )
                }
            />
        </>
    );
};

const CreateReceiptDialogue = () => {
    // Collect states and dispatchers
    const isOpen = useSelector((state) => state.general.showReceiptingDialogue);
    const darkMode = useSelector((state) => state.general.darkMode);
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);
    const dispatch = useDispatch();

    const handleClose = (_event, reason) => {
        /*
        Arguments
        ---------
        _event : obj
        reason : string
        */
        if (reason && reason == "backdropClick") return;
        closeCreateReceiptDialogue(dispatch);
    };

    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            onClose={handleClose}
            open={isOpen}
            fullScreen={!isDesktop}
            PaperProps={{
                sx: baseStyledPaperSX(darkMode),
            }}
        >
            <CreateReceiptDialogueContentDispatcher />
        </Dialog>
    );
};

export default CreateReceiptDialogue;
