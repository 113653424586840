import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import ReceiptIcon from "@mui/icons-material/Receipt";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { MOBILE_MEDIA_QUERY } from "../../../constants/mediaQueries";
import { REQUISITION_SUMMARY_PAGE } from "../../../constants/routes";
import { openCreateReceiptDialogue } from "../../../utils/createReceiptUtils";
import {
    canReqBeReceipted,
    receiptReqButtonTooltip,
} from "../../../utils/requisitionActionButtonUtils";
import ButtonCollection from "../../buttons/buttonCollection/buttonCollection";

const ReceiptButtonCollection = ({ req }) => {
    const dispatch = useDispatch();
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);
    const navigate = useNavigate();

    const userOUs = useSelector((state) => state.auth.operatingUnits);
    const isReceiptFormLoading = useSelector(
        (state) => state.createReceipt.isLoading
    );
    const receiptLoadingLocal = useSelector(
        (state) => state.createReceipt.iBuyReqNumber == req.id
    );

    const goToReqSummary = (id) => navigate(REQUISITION_SUMMARY_PAGE(id));

    const buttons = [
        {
            disabled: !canReqBeReceipted(req, userOUs),
            tooltipText: receiptReqButtonTooltip(req, userOUs),
            isLoading: isReceiptFormLoading && receiptLoadingLocal,
            icon: <ReceiptIcon />,
            text: "Receipt",
            onClick: () => openCreateReceiptDialogue(req.id, dispatch),
            ariaLabel: "tableReceiptIcon",
        },
        {
            disabled: false,
            tooltipText: "Go to Requisition Summary",
            isLoading: false,
            icon: <ContentPasteGoIcon />,
            text: "Requisition",
            onClick: () => goToReqSummary(req.id),
            ariaLabel: "receiptTableGoToReqSummaryIcon",
        },
    ];
    return (
        <ButtonCollection
            type={isDesktop ? "iconGroup" : "popover"}
            buttons={buttons}
        />
    );
};

ReceiptButtonCollection.propTypes = {
    req: PropTypes.object.isRequired,
};

export default ReceiptButtonCollection;
