import { useMediaQuery } from "@mui/material";
import { MOBILE_MEDIA_QUERY } from "constants/mediaQueries";
import PropTypes from "prop-types";
import React from "react";

import ReceiptSummaryCards from "./receiptSummaryCards/receiptSummaryCards";
import ReceiptSummaryTable from "./receiptSummaryTable";

const ReceiptSummaryList = ({ reqs }) => {
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    return isDesktop ? (
        <ReceiptSummaryTable reqs={reqs} />
    ) : (
        <ReceiptSummaryCards reqs={reqs} />
    );
};

ReceiptSummaryList.propTypes = {
    reqs: PropTypes.array,
};

export default ReceiptSummaryList;
