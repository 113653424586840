import PropTypes from "prop-types";

import useUser from "../../../services/apiUtils/hooks/useUser";
import { ProjectType } from "../../../types/propTypes";
import SearchAsYouType from "./searchAsYouType";

const UserSearchAsYouType = ({
    disabled = false,
    selectedValue,
    setSelectedValue,
    label,
}) => {
    const { getUserAutocomplete } = useUser();

    const autocompleteGet = (searchTerm, setOptions, setIsLoading) => {
        let newOptions = [];

        if (selectedValue) {
            newOptions = [selectedValue];
        }

        setIsLoading(true);

        getUserAutocomplete(searchTerm)
            .then((results) => {
                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                setOptions(newOptions);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    return (
        <SearchAsYouType
            selectedValue={selectedValue}
            onSelectValue={setSelectedValue}
            isDisabled={disabled}
            autocompleteGet={autocompleteGet}
            searchPrompt={"Start typing to search for user's name or ID"}
            label={label}
            required
            optionKeyMapping={{
                label: "NAME",
            }}
        />
    );
};

UserSearchAsYouType.propTypes = {
    disabled: PropTypes.bool,
    selectedValue: ProjectType,
    setSelectedValue: PropTypes.func,
    label: PropTypes.string,
};

export default UserSearchAsYouType;
