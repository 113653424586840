// Returns action creators for each auth action type
import {
    SET_CATEGORY_FILTER,
    SET_CPA_NUMBER,
    SET_LEAD_TIME_LTE_FILTER,
    SET_LINE_TYPE_FILTER,
    SET_LOCAL_BUYER_ONLY,
    SET_SUPPLIER_FILTER,
    SET_UNIT_MEASURE_FILTER,
    SET_UNIT_PRICE_RANGE_FILTER,
} from "../actionTypes";

// Action creators for auth slice
export const setCategoryFilter = (categories) => {
    return { type: SET_CATEGORY_FILTER, payload: categories };
};

export const setLeadTimeLTEFilter = (leadTime) => {
    return { type: SET_LEAD_TIME_LTE_FILTER, payload: leadTime };
};

export const setUnitPriceRangeFilter = (range) => {
    return { type: SET_UNIT_PRICE_RANGE_FILTER, payload: range };
};

export const setLineTypeFilter = (lineTypes) => {
    return { type: SET_LINE_TYPE_FILTER, payload: lineTypes };
};

export const setUnitOfMeasureFilter = (uoms) => {
    return { type: SET_UNIT_MEASURE_FILTER, payload: uoms };
};

export const setSupplierFilter = (suppliers) => {
    return { type: SET_SUPPLIER_FILTER, payload: suppliers };
};

export const setCpaNumber = (cpaNumber) => {
    return { type: SET_CPA_NUMBER, payload: cpaNumber };
};

export const setLocalBuyerOnly = (islocalBuyerOnly) => {
    return { type: SET_LOCAL_BUYER_ONLY, payload: islocalBuyerOnly };
};
