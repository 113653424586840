import { useState } from "react";
import { useDispatch } from "react-redux";

import {
    loadReqForAmending,
    loadReqForCopying,
} from "../utils/copyRequisitionUtils";

function useCopyAmendReq(req) {
    // Collect states and state dispatchers
    const dispatch = useDispatch();
    const [isCopyFormLoading, setIsCopyFormLoading] = useState(false);
    const [isAmendFormLoading, setIsAmendFormLoading] = useState(false);

    const clickCopyReq = () => {
        setIsCopyFormLoading(true);
        loadReqForCopying(req.id, dispatch).finally(() => {
            setIsCopyFormLoading(false);
        });
    };

    const clickAmendReq = () => {
        setIsAmendFormLoading(true);
        loadReqForAmending(req.id, dispatch).finally(() =>
            setIsAmendFormLoading(false)
        );
    };

    return [clickCopyReq, clickAmendReq, isCopyFormLoading, isAmendFormLoading];
}

export default useCopyAmendReq;
