import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import { CardContent, Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IBuyCard from "components/displays/iBuyCard";
import BatchChangeReqOwnerDialog from "components/modals/batchRequisitionOwnerChange/batchReqOwnerChangeDialogue";
import OnDesktop from "components/wrappers/onDesktop";
import { MOBILE_MEDIA_QUERY } from "constants/mediaQueries";
import { useDispatch, useSelector } from "react-redux";
import { setShowBatchChangeReqOwnerDialogue } from "redux/actions/generalActions";

import {
    contentBoxL2,
    reqLineImageIconBoxSX,
} from "./../../styled/styledSXProperties/styledBoxSX";

const OFFSET = "30px";
const ProcurementAdmin = () => {
    // Collect states and dispatchers
    const darkMode = useSelector((state) => state.general.darkMode);
    const dispatch = useDispatch();
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);
    return (
        <Box sx={contentBoxL2}>
            <Grid container spacing={3}>
                <Grid container item xs={12}>
                    <Grid item xs={4}>
                        <Typography
                            variant="h3"
                            color="text.subheader"
                            gutterBottom
                        >
                            iBuy Administrator Portal
                        </Typography>
                        <Typography variant="body1" color="text.primary">
                            This page is only available to procurement manager
                            administrators. It contains functionality that can
                            only be actioned by those with the procurement
                            manager AD role
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                    <IBuyCard
                        additionalSx={{
                            display: "flex",
                            overflow: "visible",
                            ml: isDesktop ? `${OFFSET}` : "",
                        }}
                    >
                        <OnDesktop>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    minWidth: "150px",
                                    maxWidth: "200px",
                                    width: "50%",
                                }}
                            >
                                <Box
                                    sx={{
                                        ...reqLineImageIconBoxSX(darkMode),
                                        ...{ ml: `-${OFFSET}` },
                                        height: 0,
                                        width: "100%",
                                        paddingTop: "100%",
                                        borderRadius: "20px",
                                        backgroundImage: darkMode
                                            ? "linear-gradient(to right bottom, #00dbf6, 65%, #00535d)"
                                            : "linear-gradient(to right bottom, #0064fc, 20%, #001e4b)",
                                    }}
                                >
                                    <TransferWithinAStationIcon
                                        sx={{
                                            mt: "-100%",
                                            height: "80px",
                                            width: "80px",
                                            color: "#ffffff",
                                        }}
                                    />
                                </Box>
                            </Box>
                        </OnDesktop>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <CardContent>
                                <Typography
                                    variant="body1"
                                    color="text.secondaryLight"
                                    marginBottom="6px"
                                >
                                    Requisition Management
                                </Typography>
                                <Typography
                                    variant="h3"
                                    fontWeight="normal"
                                    marginBottom="8px"
                                >
                                    Batch Requisition Owner Update
                                </Typography>
                                <Typography>
                                    {
                                        "Use this to transfer ownership of someone's requisitions to another user"
                                    }
                                </Typography>
                                <Button
                                    onClick={() =>
                                        dispatch(
                                            setShowBatchChangeReqOwnerDialogue(
                                                true
                                            )
                                        )
                                    }
                                    color="secondary"
                                    variant="contained"
                                    sx={{ borderRadius: "20px", mt: "16px" }}
                                    endIcon={<ArrowForwardIcon />}
                                >
                                    Get Started
                                </Button>
                            </CardContent>
                        </Box>
                    </IBuyCard>
                </Grid>
                <Grid item xs={4} display="flex"></Grid>
            </Grid>
            <BatchChangeReqOwnerDialog />
        </Box>
    );
};

export default ProcurementAdmin;
