import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";

import TabbedPanels from "../../../tabbedPanels";
import OnDesktop from "../../../wrappers/onDesktop";
import OnMobile from "../../../wrappers/onMobile";
import AdditionalDetails from "./expandedTabs/additionalDetails";
import StagedRequisitionComparisonTable from "./expandedTabs/stagedRequisitionComparisonTable";
import SupplementingNotes from "./expandedTabs/supplementingNotes";

const RequisitionAccordionLinesExpanded = ({
    reqLine,
    stagedLine,
    hasApprovedPo,
}) => {
    const contentPanels = [
        {
            name: "Supplementing Notes",
            icon: <DriveFileRenameOutlineOutlinedIcon />,
            id: "supplementingNotes",
            content: <SupplementingNotes reqLine={reqLine} />,
        },
        {
            name: "Additional Details",
            icon: <InfoOutlinedIcon />,
            id: "additionalDetails",
            content: <AdditionalDetails reqLine={reqLine} />,
        },
        {
            name: "Change History",
            icon: <ChangeCircleIcon />,
            id: "changeHistory",
            content:
                stagedLine && hasApprovedPo ? (
                    <>
                        <StagedRequisitionComparisonTable
                            reqLine={reqLine}
                            stagedLine={stagedLine}
                        />
                    </>
                ) : (
                    <Typography variant="body1">
                        No changes have been submitted for this requisition
                        line. Please note only changes to requisitions with a
                        purchase order will be displayed here. If you wish to
                        make a change to this requisition, use the Amend button
                        at the top of this webpage.
                    </Typography>
                ),
        },
    ];

    return (
        <Box>
            <OnDesktop>
                <TabbedPanels
                    title="Requisition accordion lines expanded tabs"
                    tabs={contentPanels}
                />
            </OnDesktop>
            <OnMobile>
                {contentPanels.map((contentPanel) => (
                    <Accordion key={contentPanel.name}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="body1">
                                {contentPanel.name}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {contentPanel.content}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </OnMobile>
        </Box>
    );
};

RequisitionAccordionLinesExpanded.propTypes = {
    reqLine: PropTypes.object,
    stagedLine: PropTypes.object,
    hasApprovedPo: PropTypes.bool,
};

export default RequisitionAccordionLinesExpanded;
