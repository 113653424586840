import PeopleIcon from "@mui/icons-material/People";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import {
    NON_CATALOGUE_ITEM,
    TYPE_OPTIONS,
} from "../../../../../constants/generalConstants";
import { reqLineImageIconBoxSX } from "../../../../../styled/styledSXProperties/styledBoxSX";

// Non-catalogue line-type icon dispatcher
const iconSX = {
    mt: "-100%",
    height: "70px",
    width: "70px",
    color: "#ffffff",
};

const itemIcon = () => {
    return <ShoppingBagIcon sx={iconSX} />;
};

const rateIcon = () => {
    return <SubscriptionsIcon sx={iconSX} />;
};

const serviceIcon = () => {
    return <PeopleIcon sx={iconSX} />;
};

const homepageIconDispatcher = {
    [TYPE_OPTIONS[0]]: itemIcon,
    [TYPE_OPTIONS[1]]: rateIcon,
    [TYPE_OPTIONS[2]]: serviceIcon,
};

const ProductImage = ({ reqLine, imgUrl }) => {
    const darkMode = useSelector((state) => state.general.darkMode);
    return (
        <>
            {reqLine?.itemType != NON_CATALOGUE_ITEM ? (
                <CardMedia
                    component="img"
                    sx={{
                        borderRadius: "10px",
                    }}
                    src={imgUrl}
                    alt="Req Line Item Image Thumbnail"
                />
            ) : (
                <Box sx={reqLineImageIconBoxSX(darkMode)}>
                    {reqLine?.ITEM_TYPE
                        ? homepageIconDispatcher[reqLine?.ITEM_TYPE]()
                        : homepageIconDispatcher[TYPE_OPTIONS[0]]()}
                </Box>
            )}
        </>
    );
};

ProductImage.propTypes = {
    reqLine: PropTypes.object,
    imgUrl: PropTypes.string.isRequired,
};

export default ProductImage;
