import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";

import {
    TOOLTIP_VALIDATE_BUTTON_HELP_HEADER_NON_PROJECT,
    TOOLTIP_VALIDATE_BUTTON_HELP_HEADER_PROJECT_INTERNAL,
} from "../../../../../../constants/tooltipText";
import { SegmentIdsType } from "../../../../../../types/propTypes";
import { navigateToChargeAccountSharepoint } from "../../../../../../utils/createRequisitionUtils";
import ValidateChargeAccountButton from "../../../../../buttons/validateChargeAccountButton";

const ValidateChargeAccountButtonContainer = ({
    isValidated,
    isDisabled,
    segmentIds,
    operatingUnit,
    updateHeader,
    nonProjectMode = true,
}) => {
    return (
        <Stack direction="row" gap={2} alignItems="center">
            {isValidated ? (
                <>
                    <CheckCircleIcon color="success" />
                    <Typography variant="body1" color="text.secondary">
                        Charge account valid
                    </Typography>
                </>
            ) : (
                <>
                    <ValidateChargeAccountButton
                        disabled={isDisabled}
                        segmentIds={segmentIds}
                        operatingUnit={operatingUnit}
                        nonProjectMode={nonProjectMode}
                        onSuccess={(ccid) => {
                            updateHeader(true, "IS_VALIDATED");
                            updateHeader(ccid, "CHARGE_ACCOUNT_ID");
                        }}
                        onError={() => updateHeader(false, "IS_VALIDATED")}
                    />
                    <Tooltip
                        title={
                            nonProjectMode
                                ? TOOLTIP_VALIDATE_BUTTON_HELP_HEADER_NON_PROJECT
                                : TOOLTIP_VALIDATE_BUTTON_HELP_HEADER_PROJECT_INTERNAL
                        }
                        placement="top-start"
                        disableInteractive
                    >
                        <IconButton onClick={navigateToChargeAccountSharepoint}>
                            <HelpOutlineRoundedIcon />
                        </IconButton>
                    </Tooltip>
                </>
            )}
        </Stack>
    );
};

ValidateChargeAccountButtonContainer.defaultProps = {
    nonProjectMode: true,
};

ValidateChargeAccountButtonContainer.propTypes = {
    isValidated: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    segmentIds: SegmentIdsType.isRequired,
    operatingUnit: PropTypes.string.isRequired,
    updateHeader: PropTypes.func.isRequired,
    nonProjectMode: PropTypes.bool,
};

export default ValidateChargeAccountButtonContainer;
