import { useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { MOBILE_MEDIA_QUERY } from "../../../constants/mediaQueries";
import styledDialogueSX from "../../../styled/styledSXProperties/styledDialogueSX";
import { tearDownCancelRequisitionDialogue } from "../../../utils/cancelRequisitionUtils";
import CreateReceiptSkeleton from "../../skeletons/createReceiptSkeleton";
import CancelRequisitionForm from "./cancelRequisitionForm";

/**
 * Parent container for cancel requisition modal
 *
 * @component
 */
const CancelRequisitionDialogue = () => {
    // Collect states and state dispatchers
    const isOpen = useSelector(
        (state) => state.general.showCancelRequisitionDialogue
    );
    const isLoading = useSelector((state) => state.cancelRequisition.isLoading);
    const dispatch = useDispatch();

    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    /**
     * Calls close modal utility for non-backdrop clicks
     * @param   {object} _event Not used - click event object
     * @param   {string} reason Type of click event
     */
    const handleClose = (_event, reason) => {
        if (reason && reason == "backdropClick") return;
        tearDownCancelRequisitionDialogue(dispatch);
    };

    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            onClose={handleClose}
            open={isOpen}
            fullScreen={!isDesktop}
        >
            <DialogContent sx={styledDialogueSX}>
                {isLoading ? (
                    <CreateReceiptSkeleton />
                ) : (
                    <CancelRequisitionForm />
                )}
            </DialogContent>
        </Dialog>
    );
};

CancelRequisitionDialogue.propTypes = {};

export default CancelRequisitionDialogue;
