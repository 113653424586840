import { useTheme } from "@mui/material";

export const snackbarConstants = (theme) => {
    return {
        inset: theme.spacing(1),
    };
};

export const useSnackbarConstants = () => {
    const theme = useTheme();
    return snackbarConstants(theme);
};
