import Link from "@mui/material/Link";
import { PO_SUMMARY_PAGE } from "constants/routes";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { closeReceiptSummaryDialogue } from "utils/receiptSummaryUtils";

const PoLink = ({ poNumber }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const linkToPOSummary = () => {
        closeReceiptSummaryDialogue(dispatch);
        navigate(PO_SUMMARY_PAGE(poNumber));
    };

    return (
        <Link
            data-testid="poLinkButton"
            component="button"
            disabled={!poNumber}
            color="secondary"
            onClick={linkToPOSummary}
        >
            {poNumber}
        </Link>
    );
};

PoLink.propTypes = {
    poNumber: PropTypes.string,
};

export default PoLink;
