import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import PersonIcon from "@mui/icons-material/Person";
import ReceiptIcon from "@mui/icons-material/Receipt";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IS_PROC_MANAGER_REDUX_ROLE } from "constants/generalConstants";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { MOBILE_MEDIA_QUERY } from "../../../../constants/mediaQueries";
import { REQUISITION_SUMMARY_PAGE } from "../../../../constants/routes";
import useCopyAmendReq from "../../../../hooks/useCopyAmendReq";
import { openCancelRequisitionDialogue } from "../../../../utils/cancelRequisitionUtils";
import { openCreateReceiptDialogue } from "../../../../utils/createReceiptUtils";
import {
    amendReqButtonTooltip,
    cancelReqButtonTooltip,
    canChangeOwner,
    canReqBeAmended,
    canReqBeCancelled,
    canReqBeCopied,
    canReqBeReceipted,
    changeOwnerTooltip,
    copyReqButtonTooltip,
    receiptReqButtonTooltip,
} from "../../../../utils/requisitionActionButtonUtils";
import ButtonCollection from "../../../buttons/buttonCollection/buttonCollection";
import { RequisitionsSummaryContext } from "../requisitionsSummaryContext";

const getGotoReqButton = (navigate, reqId) => {
    return {
        disabled: false,
        tooltipText: "Go to Requisition Summary",
        isLoading: false,
        icon: <ContentPasteGoIcon />,
        text: "Requisition",
        onClick: () => navigate(REQUISITION_SUMMARY_PAGE(reqId)),
        ariaLabel: "tableGoToReqSummaryIcon",
    };
};

const RequisitionButtonCollection = ({ req }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    const receiptLoadingLocal = useSelector(
        (state) => state.createReceipt.iBuyReqNumber == req.id
    );
    const isReceiptFormLoading = useSelector(
        (state) => state.createReceipt.isLoading
    );
    const userOUs = useSelector((state) => state.auth.operatingUnits);
    const isCancelFormLoading = useSelector(
        (state) =>
            state.cancelRequisition.isLoading &&
            state.cancelRequisition.iBuyReqNumber == req?.id
    );
    const isUserProcurementManager = useSelector((state) =>
        IS_PROC_MANAGER_REDUX_ROLE.includes(state.auth.role)
    );
    const { setCurrentReqId } = useContext(RequisitionsSummaryContext);

    const [clickCopyReq, clickAmendReq, isCopyFormLoading, isAmendFormLoading] =
        useCopyAmendReq(req);

    const buttons = [
        {
            disabled: !canReqBeReceipted(req, userOUs),
            tooltipText: receiptReqButtonTooltip(req, userOUs),
            isLoading: isReceiptFormLoading && receiptLoadingLocal,
            icon: <ReceiptIcon />,
            text: "Receipt",
            onClick: () => openCreateReceiptDialogue(req.id, dispatch),
            ariaLabel: "tableReceiptIcon",
        },
        getGotoReqButton(navigate, req.id),
        {
            disabled: !canReqBeAmended(req, userOUs),
            tooltipText: amendReqButtonTooltip(req, userOUs),
            isLoading: isAmendFormLoading,
            icon: <ChangeCircleIcon />,
            text: "Amend",
            onClick: clickAmendReq,
            ariaLabel: "tableAmendReqIcon",
        },
        {
            disabled: !canChangeOwner(req, isUserProcurementManager),
            tooltipText: changeOwnerTooltip(req, isUserProcurementManager),
            isLoading: false,
            icon: <PersonIcon />,
            text: "Change owner",
            onClick: () => {
                setCurrentReqId(req.id);
            },
            ariaLabel: "tableChangeOwnerIcon",
        },
        {
            disabled: !canReqBeCopied(req, userOUs),
            tooltipText: copyReqButtonTooltip(req, userOUs),
            isLoading: isCopyFormLoading,
            icon: <ContentCopyIcon />,
            text: "Copy",
            onClick: clickCopyReq,
            ariaLabel: "tableCopyRequisition",
        },
        {
            disabled: !canReqBeCancelled(req, userOUs),
            tooltipText: cancelReqButtonTooltip(req, userOUs),
            isLoading: isCancelFormLoading,
            icon: <CancelIcon />,
            text: "Cancel",
            onClick: () => openCancelRequisitionDialogue(req.id, dispatch),
            ariaLabel: "tableCancelReqIcon",
        },
    ];

    return (
        <ButtonCollection
            type={isDesktop ? "iconGroup" : "popover"}
            buttons={buttons}
        />
    );
};

RequisitionButtonCollection.propTypes = {
    req: PropTypes.object.isRequired,
};

export default RequisitionButtonCollection;

export const StandaloneGotoReqButton = ({ req }) => {
    const navigate = useNavigate();

    return (
        <ButtonCollection
            type={"iconGroup"}
            buttons={[getGotoReqButton(navigate, req.id)]}
        />
    );
};

StandaloneGotoReqButton.propTypes = {
    req: PropTypes.object.isRequired,
};
