export const baseStyledPaperSX = (darkMode) => ({
    backgroundImage: darkMode
        ? "linear-gradient(to bottom right, #40434c, #2E3037)"
        : "linear-gradient(to bottom right, #FFFFFF, #FFFFFF)",
});

export const styledPaperSX = (darkMode) => ({
    ...baseStyledPaperSX(darkMode),
    padding: "16px",
    borderRadius: "10px",
    boxSizing: "border-box",
    boxShadow: "10",
    display: "flex",
    ":hover": {
        boxShadow: "24",
    },
});

export const navBarSX = (darkMode) => ({
    backgroundImage: darkMode
        ? "linear-gradient(to bottom,#40434c, #2E3037)"
        : "linear-gradient(to bottom,#FFFFFF, #FFFFFF)",
    borderRadius: "10px",
    boxShadow: 10,
    display: "flex",
    flexDirection: "column",
    flexGrow: 0,
    flexShrink: 0,
    margin: "16px 0px 16px 16px",
    overflowY: "auto",
    transition: "flex-basis 0.5s",
    transitionTimingFunction: "ease",
    ":hover": {
        boxShadow: "24",
    },
});

export const homepageTeamSX = (darkMode) => ({
    ...styledPaperSX(darkMode),
    flexGrow: 0,
    padding: "6px 16px",
    margin: "0px 0px 16px 0px",
    backgroundImage: "linear-gradient(to bottom right, #6b7585, #aeb4be)",
    width: "20%",
});

export const popperSX = {
    borderRadius: "10px",
    boxShadow: "20",
    ":hover": {
        boxShadow: "24",
    },
};
