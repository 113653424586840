import { snackbarConstants } from "../styled/constants/snackbar";
import { themeTypography } from "./themeTypography";

export const baseTheme = {
    palette: {
        primary: {
            contrastText: "#FFFFFF",
        },
        secondary: {
            contrastText: "#FFFFFF",
        },
        pink: {
            main: "#C63A70",
            contrastText: "#FFFFFF",
        },
        text: {
            secondary: "#8A93A0",
            black: "#000000",
            white: "#FFFFFF",
            placeholder: "#FFFFFF",
            errorLight: "#ef5350",
        },
        success: {
            contrastText: "#FFFFFF",
        },
        warning: {
            main: "#ed6c02",
            light: "#ff9800",
            dark: "#e65100",
            contrastText: "#FFFFFF",
        },
        info: {
            contrastText: "#FFFFFF",
        },
    },
    typography: themeTypography,
    // Overriding default values from this page:
    // https://mui.com/material-ui/customization/z-index/
    zIndex: {
        customPopper: 1000,
        // mobileStepper: 1000,
        // fab: 1050,
        appBar: 1050,
        speedDial: 1100,
        // drawer: 1200,
        // modal: 1300,
        // snackbar: 1400,
        // tooltip: 1500,
    },
    components: {
        MuiPopper: {
            styleOverrides: {
                root: ({ theme }) => ({
                    zIndex: theme.zIndex.customPopper,
                }),
            },
        },
        MuiAlert: {
            styleOverrides: {
                message: {
                    flex: 1,
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                root: ({ theme }) => ({
                    [theme.breakpoints.up("xs")]: {
                        left: snackbarConstants(theme).inset,
                        bottom: snackbarConstants(theme).inset,
                        right: snackbarConstants(theme).inset,
                    },
                }),
            },
        },
    },
};
