import { Hidden } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { receiptUniqueReference } from "../../../../constants/patterns";
import {
    setAllChecked,
    setHeaderInput,
} from "../../../../redux/actions/createReceiptActions";
import { freeTextFormProps } from "../../../../styled/props/textFieldProps";

const HeaderInformation = ({ errors, register }) => {
    // Collect states and state dispatchers
    const headerInput = useSelector((state) => state.createReceipt.headerInput);
    const dispatch = useDispatch();

    // Register inputs (unique reference and comments) with form validator
    const { ref: uniqueReferenceRef, ...uniqueReferenceProps } = register(
        "uniqueReference",
        {
            onChange: (e) => updateHeader("uniqueReference", e.target.value),
            required: "Required Field",
            pattern: {
                value: receiptUniqueReference,
                message: "Unique reference must be 25 characters or less",
            },
        }
    );

    const updateHeader = (field, value) => {
        /*
        Arguments
        ---------
        field : string
        value : string
        */
        dispatch(
            setHeaderInput({
                ...headerInput,
                [field]: value,
            })
        );
    };

    // Gets the minimum selectable date to the first day of the current month
    const getMinimumSelectableDate = () => {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), 1);
    };

    const checkAll = (event) => dispatch(setAllChecked(event.target.checked));

    const isSmallScreen = useMediaQuery("(max-width:820px)");

    return (
        <Grid container marginBottom="16px">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" color="text.subheader">
                        General Information
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        {...freeTextFormProps}
                        label="Unique Reference Number"
                        placeholder="Enter reference number..."
                        required
                        value={headerInput?.uniqueReference}
                        {...uniqueReferenceProps}
                        inputRef={uniqueReferenceRef}
                        error={!!errors.uniqueReference}
                        helperText={errors?.uniqueReference?.message}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            disableFuture
                            label="Receipt Date"
                            inputFormat="dd/MM/yyyy"
                            minDate={getMinimumSelectableDate()}
                            onChange={(date) =>
                                updateHeader("receiptDate", date)
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...freeTextFormProps}
                                    required
                                />
                            )}
                            value={headerInput?.receiptDate}
                        />
                    </LocalizationProvider>
                    {isSmallScreen && (
                        <Typography variant="inherit">
                            *You are only allowed to select dates within the
                            current calendar month
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        {...freeTextFormProps}
                        label="Comments"
                        placeholder="Enter comments..."
                        value={headerInput?.comments}
                        multiline
                        onChange={(e) =>
                            updateHeader("comments", e.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch onChange={checkAll} color="secondary" />
                            }
                            label="Check all Lines"
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid>
                <Hidden mdDown>
                    <Typography variant="inherit" marginTop="5px">
                        *You are only allowed to select dates within the current
                        calendar month.
                    </Typography>
                </Hidden>
            </Grid>
        </Grid>
    );
};

HeaderInformation.propTypes = {
    errors: PropTypes.object,
    register: PropTypes.func,
};
export default HeaderInformation;
