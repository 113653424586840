import { SMARTFORMS_MODE } from "constants/generalConstants";
import { setShowUserInfo } from "redux/actions/generalActions";

import { totalQuantity } from "./shoppingCartUtils";

const oneSupplierErrorMessage =
    "Carts can only contain items from one supplier";
const oneCpaErrorMessage =
    "Carts can only contain items from a single contract purchase agreement";
const oneOUErrorMessage = (cartOU, itemOU) =>
    `Carts can only contain items from a single operating unit. Current cart: ${cartOU}. Item: ${itemOU}`;

const oneCurrencyCodeErrorMessage = (cartCurrencyCode, itemCurrencyCode) =>
    `All items in a cart must have the same associated currency. Current cart: ${cartCurrencyCode}. Currency of item you tried to add: ${itemCurrencyCode}`;

/**
 * Parses error message for invalid add to cart action
 * @param {boolean} oneSupplierOrCpaRuleValid - flag for if add to cart is valid under one supplier/cpa per cart rule
 * @param {boolean} currencyCodeValid - flag for if add to cart is valid under the one currency code per cart rule
 * @param {boolean} isCPACart - flag for if it is a CPA cart
 * @param {boolean} OUValid - flag for if add to cart is valid under the one OU per cart rule
 * @param {string} cartOU - OU of the cart being added to
 * @param {string} itemOU - OU of item trying to be added to the cart
 * @param {string} cartCurrencyCode - currency code of the cart being added to
 * @param {string} itemCurrencyCode - currency code of item trying to be added to the cart
 * @returns
 */
const invalidCartErrorMessage = (
    oneSupplierOrCpaRuleValid,
    currencyCodeValid,
    isCPACart,
    OUValid,
    cartOU,
    itemOU,
    cartCurrencyCode,
    itemCurrencyCode
) => {
    switch (true) {
        case isCPACart && !oneSupplierOrCpaRuleValid:
            return oneCpaErrorMessage;
        case !oneSupplierOrCpaRuleValid:
            return oneSupplierErrorMessage;
        case !OUValid:
            return oneOUErrorMessage(cartOU, itemOU);
        case !currencyCodeValid:
            return oneCurrencyCodeErrorMessage(
                cartCurrencyCode,
                itemCurrencyCode
            );
        default:
            return null;
    }
};

/**
 * Validates if an item can be added to a cart
 * @param {*} cartItems - cart being added to
 * @param {*} item - item being added to cart
 * @returns {[boolean, string]} - bool: flag for if action is
 * valid; str - error message if action is invalid
 */
export const validateAddToCart = (cartItems, item) => {
    const isCPACart = !!cartItems[0]?.CPA_NUMBER;
    if (totalQuantity(cartItems) === 0) {
        return [true, null];
    } else {
        // If CPA cart, enforce new item matches cart CPA.
        // If non-CPA cart, enforce new item matches supplier CPA.

        const supplierValid = cartItems[0]?.SUPPLIER_ID == item?.SUPPLIER_ID;
        const cpaValid = cartItems[0]?.CPA_NUMBER == item?.CPA_NUMBER;
        let oneSupplierOrCpaRuleValid;

        if (
            !(
                cartItems[0]?.DEFAULT_SO_SOURCE_TYPE == "INTERNAL" &&
                item?.DEFAULT_SO_SOURCE_TYPE === "INTERNAL"
            )
        ) {
            oneSupplierOrCpaRuleValid = isCPACart ? cpaValid : supplierValid;
        } else {
            oneSupplierOrCpaRuleValid = true;
        }

        // Check item OU matches cart OU
        const cartOU = cartItems[0].IBUY_CART_ITEM_OU;
        const itemOU = item.IBUY_CART_ITEM_OU;
        const OUValid = cartOU === itemOU;

        // Check item currency matches cart currency
        const cartCurrencyCode = cartItems[0]?.CURRENCY_CODE;
        const itemCurrencyCode = item?.CURRENCY_CODE;
        const currencyCodeValid = cartCurrencyCode === itemCurrencyCode;

        // Parse error message
        const msg = invalidCartErrorMessage(
            oneSupplierOrCpaRuleValid,
            currencyCodeValid,
            isCPACart,
            OUValid,
            cartOU,
            itemOU,
            cartCurrencyCode,
            itemCurrencyCode
        );
        return [oneSupplierOrCpaRuleValid && OUValid && currencyCodeValid, msg];
    }
};

/**
 * When toggling between smartforms and non-smartforms,
 * some search params must be cleared down. These params
 * are those which are specific to smartforms only
 * or non-smartforms (catalogue items) only
 * @param {*} searchParams - smartforms url search params
 */
export const clearSearchParamsOnSmartformsToggle = (searchParams) => {
    // Smartform only params
    searchParams.delete("cpa_num");
    searchParams.delete("local_buyer_only");
    // Catalogue item (non-smartform) only params
    searchParams.delete("unit_price_le");
    searchParams.delete("unit_price_ge");
    searchParams.delete("lead_time");
    searchParams.delete("line_type");
    searchParams.delete("unit_measure");
    searchParams.delete("item_number");
    searchParams.delete("supplier_number");
};

/**
 * Sets webpage url when toggling smartforms switch
 * @param {boolean} isSmartForms - flag for if setting to smartforms mode
 * @param {object} searchParams - smartforms url search params
 * @param {Function} setSearchParams - react router url search params setter / refesh function
 */
export const onCheckSmartForms = (
    isSmartForms,
    searchParams,
    setSearchParams
) => {
    clearSearchParamsOnSmartformsToggle(searchParams);
    isSmartForms
        ? searchParams.set("smartforms", SMARTFORMS_MODE)
        : searchParams.delete("smartforms");
    setSearchParams(searchParams);
};

/**
 * Opens user info popper, called from an altert snackbar
 * which prompts a user to change their browsing
 * operating unit
 * @param {object} event - button click event object
 * @param {function} dispatch - redux dispatcher function
 */
export const openUserPopper = (event, dispatch) => {
    event.stopPropagation();
    dispatch(setShowUserInfo(true));
};
