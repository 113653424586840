import { useTheme } from "@mui/material";

export const speedDialConstants = (theme) => {
    return {
        diameter: theme.spacing(8),
        inset: theme.spacing(2),
    };
};

export const useSpeedDialConstants = () => {
    const theme = useTheme();
    return speedDialConstants(theme);
};
