import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";

import { CART_PREFIX } from "../../../constants/routes";
import { setShowShoppingCartSummaryPopper } from "../../../redux/actions/generalActions";
import GradientDivider from "../../../styled/styledComponents/GradientDivider";
import StyledPopper from "../../../styled/styledComponents/styledPopper";
import StyledPopperArrow from "../../../styled/styledComponents/styledPopperArrow";
import { createRequisition } from "../../../utils/shoppingCartUtils";
import CurrencyFigure from "../../displays/currencyFigure";
import { popperSX } from "./../../../styled/styledSXProperties/styledPaperSX";
import { clickAway, styledPopperModifiers } from "./../../../utils/popperUtils";
import { totalCost, totalQuantity } from "./../../../utils/shoppingCartUtils";
import ShoppingCartSummaryPopperItem from "./shoppingCartSummaryPopperItem";

const ShoppingCartSummaryPopper = ({ shoppingCartIconRef }) => {
    // Collect states and state dispatchers
    const showShoppingCartSummaryPopper = useSelector(
        (state) => state.general.showShoppingCartSummaryPopper
    );
    const activeCart = useSelector((state) => state.general.activeCart);
    const items = useSelector((state) => state.shoppingCart[activeCart].items);
    const reqFormLoading = useSelector(
        (state) => state.createRequisition.formLoading
    );
    const cartName = useSelector(
        (state) => state.shoppingCart[activeCart].name
    );
    const activeCartObj = useSelector(
        (state) => state.shoppingCart[activeCart]
    );

    const dispatch = useDispatch();

    const [arrowRef, setArrowRef] = useState(null);

    const handleClickCreateRequisition = () => {
        createRequisition(activeCartObj, dispatch);
        dispatch(setShowShoppingCartSummaryPopper(false));
    };

    return (
        <ClickAwayListener
            onClickAway={(event) =>
                clickAway(
                    event,
                    shoppingCartIconRef,
                    dispatch,
                    setShowShoppingCartSummaryPopper
                )
            }
        >
            <StyledPopper
                open={showShoppingCartSummaryPopper}
                arrow
                anchorEl={shoppingCartIconRef.current}
                placement="bottom-end"
                disablePortal={false}
                modifiers={styledPopperModifiers(arrowRef)}
            >
                <>
                    <StyledPopperArrow
                        ref={setArrowRef}
                        className="MuiPopper-arrow"
                    />
                    <Paper sx={{ ...popperSX, width: "400px" }}>
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: "bold",
                                padding: "10px",
                            }}
                        >
                            {`Current Shopping Cart: ${cartName}`}
                        </Typography>
                        <GradientDivider sx={{ margin: "2px" }} />
                        {totalQuantity(items) == 0 ? (
                            <>
                                <Typography
                                    align="center"
                                    sx={{
                                        fontSize: 14,
                                        padding: "24px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Current cart is empty
                                </Typography>
                                <GradientDivider sx={{ margin: "2px" }} />
                            </>
                        ) : (
                            <>
                                <TransitionGroup>
                                    {items.map((item, index) => (
                                        <Collapse key={index}>
                                            <ShoppingCartSummaryPopperItem
                                                product={item}
                                            />
                                        </Collapse>
                                    ))}
                                </TransitionGroup>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: "bold",
                                            padding: "10px",
                                        }}
                                    >
                                        Total
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: "bold",
                                            padding: "10px",
                                        }}
                                    >
                                        <CurrencyFigure
                                            value={totalCost(items)}
                                            code={items[0]?.CURRENCY_CODE}
                                        />
                                    </Typography>
                                </Box>
                            </>
                        )}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Link
                                to={CART_PREFIX + activeCart}
                                style={{ textDecoration: "none" }}
                            >
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    sx={{
                                        margin: "10px",
                                        flexGrow: "1",
                                        overflow: "clip",
                                    }}
                                    onClick={() =>
                                        dispatch(
                                            setShowShoppingCartSummaryPopper(
                                                false
                                            )
                                        )
                                    }
                                >
                                    View Cart
                                </Button>
                            </Link>
                            <LoadingButton
                                variant="contained"
                                color="secondary"
                                sx={{
                                    margin: "10px",
                                    flexGrow: "1",
                                    overflow: "clip",
                                }}
                                onClick={handleClickCreateRequisition}
                                loading={reqFormLoading}
                                disabled={
                                    totalQuantity(items) == 0 ? true : false
                                }
                            >
                                Create Requisition
                            </LoadingButton>
                        </Box>
                    </Paper>
                </>
            </StyledPopper>
        </ClickAwayListener>
    );
};

ShoppingCartSummaryPopper.propTypes = {
    shoppingCartIconRef: PropTypes.object,
};

export default ShoppingCartSummaryPopper;
