import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Popover, Stack, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import React, { useId, useState } from "react";
import { useSelector } from "react-redux";

import { ButtonListType } from "../../../types/propTypes";
import CustomButtonGroup from "./customButtonGroup";

const ButtonCollection = ({ buttons, type }) => {
    const darkMode = useSelector((state) => state.general.darkMode);

    const [anchorEl, setAnchorEl] = useState(null);
    const popoverId = useId();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? popoverId : undefined;

    let selectedButtonCollection;

    if (type === "popover") {
        selectedButtonCollection = (
            <>
                <IconButton aria-describedby={id} onClick={handleClick}>
                    <MoreHorizIcon />
                </IconButton>
                <Popover
                    id={id}
                    open={open}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "right",
                    }}
                >
                    <CustomButtonGroup
                        buttons={buttons}
                        orientation={"vertical"}
                    />
                </Popover>
            </>
        );
    }

    if (type === "iconGroup") {
        selectedButtonCollection = (
            <Stack direction="row">
                {buttons.map((button) => (
                    <Tooltip
                        key={button.text}
                        title={button.tooltipText}
                        disableInteractive
                    >
                        <span>
                            <IconButton
                                onClick={button.onClick}
                                aria-label={button.ariaLabel}
                                disabled={button.disabled || button.isLoading}
                                color={darkMode ? "default" : "primary"}
                            >
                                {button.isLoading ? (
                                    <CircularProgress size="1.5rem" />
                                ) : (
                                    button.icon
                                )}
                            </IconButton>
                        </span>
                    </Tooltip>
                ))}
            </Stack>
        );
    }

    if (type === "buttonGroup") {
        selectedButtonCollection = (
            <CustomButtonGroup buttons={buttons} orientation={"horizontal"} />
        );
    }

    return (
        <Box onClick={(event) => event.stopPropagation()}>
            {selectedButtonCollection}
        </Box>
    );
};

ButtonCollection.propTypes = {
    buttons: ButtonListType,
    type: PropTypes.oneOf(["popover", "iconGroup", "buttonGroup"]).isRequired,
};

export default ButtonCollection;
