import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { objectOnCondition } from "utils/general";
import { closeSmartFormsDialogue } from "utils/smartFormsUtils";

import {
    CATALOGUE_ITEM,
    FAVOURITES_CART_NAME,
    NON_CATALOGUE_ITEM,
    SMART_FORM,
} from "../../constants/generalConstants";
import { updateCartItems } from "../../services/cart/cartAPIs";
import { primaryButtonSx } from "../../styled/styledSXProperties/styledButtonSX";
import alertDispatcher from "../../utils/alertsUtils";
import { closeNonCatDialogue } from "../../utils/nonCatFormUtils";
import { amendedCartItems } from "../../utils/shoppingCartUtils";
import {
    setAddToNewNamedCartItem,
    setShowAddToNewNamedCartDialogue,
    updateActiveCart,
} from "./../../redux/actions/generalActions";
import { validateAddToCart } from "./../../utils/productListUtils";

const AddToCartButton = ({
    product,
    nonCat,
    qtyIncrement = 1,
    nonCatFormValidator = null,
}) => {
    // Collect states and state dispatchers
    const carts = useSelector((state) => state.shoppingCart);
    const darkMode = useSelector((state) => state.general.darkMode);
    const dispatch = useDispatch();
    const activeCart = useSelector((state) => state.general.activeCart);

    // Instantiate local states used only within component
    const [openPopper, setOpenPopper] = useState(false);

    const anchorRef = useRef(null);

    const handleMenuItemClick = (event, namedCart) => {
        /*
        Arguments
        ---------
        event : obj
        namedCart : "string"
        */
        event.preventDefault();
        dispatch(updateActiveCart(namedCart));
        setOpenPopper(false);
    };

    const openAddToNewNamedCartDialogue = (event) => {
        /*
        Arguments
        ---------
        event : obj
        */
        event.preventDefault();
        dispatch(setShowAddToNewNamedCartDialogue(true));
    };

    const clickAddToCart = (event, activeCart, product) => {
        nonCat
            ? nonCatFormValidator(() =>
                  addToCartAction(event, activeCart, product)
              )(event)
            : addToCartAction(event, activeCart, product);
    };

    const addToCartAction = (event, activeCart, product) => {
        /* Logic implemented when add to cart button click. Called via `clickAddToCart`.
        Two routes:
        1) User adds new item to cart
        2) User unable to add item to cart as supplier validation rules violated
        Arguments
        ---------
        activeCart : "string"
        event : obj
        product : obj
        */
        event.preventDefault();
        const [validCartItem, errorMsg] = validateAddToCart(
            carts[activeCart].items,
            product
        );
        const newProduct = {
            ...product,
            ...objectOnCondition(!nonCat, { itemType: CATALOGUE_ITEM }),
        };
        if (validCartItem) {
            // Route 1 (see above)
            const newItems = amendedCartItems(carts[activeCart].items, "ADD", {
                newItem: newProduct,
                qtyIncrement,
            });
            updateCartItems(activeCart, dispatch, newItems, true);
            nonCat == NON_CATALOGUE_ITEM && closeNonCatDialogue(dispatch);
            nonCat == SMART_FORM && closeSmartFormsDialogue(dispatch);
        } else {
            // Route 2 (see above)
            const action = {
                text: "Add to new cart",
                action: openAddToNewNamedCartDialogue,
            };
            alertDispatcher("addToCartAction", 400, dispatch, errorMsg, action);
            dispatch(
                setAddToNewNamedCartItem({
                    product: newProduct,
                    nonCatForm: nonCat,
                })
            );
        }
    };

    const handleToggleNamedCartList = (event) => {
        /*
        Arguments
        ---------
        event : obj
        */
        event.preventDefault();
        setOpenPopper((prevOpenPopper) => !prevOpenPopper);
    };

    const handleClosePopper = (event) => {
        /*
        Arguments
        ---------
        event : obj
        */
        event.preventDefault();
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenPopper(false);
    };
    return (
        <>
            <ButtonGroup variant="contained" ref={anchorRef} size="small">
                <Button
                    onClick={(event) =>
                        clickAddToCart(event, activeCart, product)
                    }
                    sx={primaryButtonSx(darkMode)}
                >{`Add to ${carts[activeCart].name}`}</Button>
                <Button
                    size="small"
                    onClick={handleToggleNamedCartList}
                    aria-label="addToCartButtonArrowIcon"
                    sx={{
                        backgroundImage: darkMode
                            ? "linear-gradient(to right bottom, #5a7386, #425563)"
                            : "linear-gradient(to right bottom, #002D72, #002D72)",
                    }}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                open={openPopper}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClosePopper}>
                                <MenuList autoFocusItem>
                                    {Object.keys(carts)
                                        .filter(
                                            (key) =>
                                                carts[key].name !=
                                                FAVOURITES_CART_NAME
                                        )
                                        .map((key) => (
                                            <MenuItem
                                                key={key}
                                                value={key}
                                                onClick={(e) =>
                                                    handleMenuItemClick(e, key)
                                                }
                                            >
                                                {carts[key].name}
                                            </MenuItem>
                                        ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

AddToCartButton.propTypes = {
    product: PropTypes.object,
    nonCatMode: PropTypes.oneOf([SMART_FORM, NON_CATALOGUE_ITEM, false]),
};

AddToCartButton.defaultProps = {
    qtyIncrement: 1,
    nonCatFormValidator: null,
};

export default AddToCartButton;
