import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useState } from "react";

import ApprovalsDialogue from "../modals/approvals/approvalsDialogue";

const ApprovalsActionButtons = ({
    requisition,
    showApproveReject,
    callbackFunc,
}) => {
    // Collect states and state dispatchers
    const [showModal, setShowModal] = useState(false);
    const [isApprove, setIsApprove] = useState(false);
    const closeModal = () => {
        setShowModal(false);
    };

    const populateModal = (approve) => {
        setShowModal(true);
        setIsApprove(approve);
    };

    return (
        <>
            {showApproveReject && (
                <>
                    <Grid item>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => populateModal(true)}
                            endIcon={<CheckCircleOutlineIcon />}
                            color="success"
                            sx={{
                                minWidth: "100px",
                                marginTop: "10px",
                                fontWeight: "bold",
                            }}
                        >
                            Approve
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => populateModal(false)}
                            endIcon={<CancelIcon />}
                            color="error"
                            sx={{
                                minWidth: "100px",
                                marginTop: "10px",
                                fontWeight: "bold",
                            }}
                        >
                            Reject
                        </Button>
                    </Grid>
                </>
            )}
            {showModal && (
                <ApprovalsDialogue
                    closeModal={closeModal}
                    isOpen={showModal}
                    reqId={requisition.id}
                    isApprove={isApprove}
                    callbackFunc={callbackFunc}
                />
            )}
        </>
    );
};

ApprovalsActionButtons.propTypes = {
    requisition: PropTypes.object,
};

export default ApprovalsActionButtons;
