import { Skeleton } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

const PreFlightCheckLoadingAlert = () => {
    const darkMode = useSelector((state) => state.general.darkMode);
    return (
        <Alert
            severity="info"
            variant={darkMode ? "outlined" : "standard"}
            icon={<CircularProgress color="secondary" size="1.2rem" />}
        >
            <AlertTitle>Change Details Loading</AlertTitle>
            <Skeleton width="25%" />
            <Skeleton width="50%" />
            <Skeleton width="55%" />
            <Skeleton width="60%" />
        </Alert>
    );
};

export default PreFlightCheckLoadingAlert;
