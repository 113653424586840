import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const SearchBarBackground = ({ children }) => {
    // Collect states and state dispatchers
    const darkMode = useSelector((state) => state.general.darkMode);
    const isScrolling = useSelector((state) => state.general.isScrolling);
    return (
        <Paper
            component="form"
            sx={(theme) => {
                return {
                    alignItems: "center",
                    backgroundImage: darkMode
                        ? "linear-gradient(to right bottom, #0097A9, #4CB6C2)"
                        : "linear-gradient(to right bottom, #003586, #002D72)",
                    borderRadius: "10px",
                    boxShadow: "10",
                    justifyContent: "space-between",
                    boxSizing: "border-box",
                    position: "sticky", // Anchors bar vertically, enabling "scroll under""
                    display: "flex",
                    height: "55px",
                    ":hover": {
                        boxShadow: "24",
                    },
                    margin: "16px 0px 0px 0px",
                    padding: "0px 4px",
                    opacity: isScrolling ? 0.55 : 1,
                    top: 15, // Anchors bar vertically, enabling "scroll under""
                    zIndex: theme.zIndex.appBar,
                };
            }}
        >
            {children}
        </Paper>
    );
};

SearchBarBackground.propTypes = {
    children: PropTypes.node,
};

export default SearchBarBackground;
