import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { useSelector } from "react-redux";

import { MOBILE_MEDIA_QUERY } from "../../constants/mediaQueries";
import GradientDivider from "../../styled/styledComponents/GradientDivider";
import IBuyPaper from "../displays/iBuyPaper";
import {
    HOMEPAGE_CARD,
    PRODUCT_CARD,
} from "./../../constants/generalConstants";
import { contentBoxL2 } from "./../../styled/styledSXProperties/styledBoxSX";
import CardCarousel from "./cardCarousel";
import TeamTitle from "./teamTitle";

const skeletonArray = (tiles) =>
    [...Array(tiles).keys()].reduce((acc) => [...acc, { L3: "", L6: [] }], []);

// Both arrays must match maximum number of carousel tiles shown when screen
// is it its widest configuration (to avoid CSS-related bugs).
export const skeletonCategories = {
    1: skeletonArray(8),
    2: skeletonArray(8),
    3: skeletonArray(4),
    4: skeletonArray(3),
};
export const skeletonFavourites = [...Array(8).keys()];

/**
 * Ibuy homepage favourites carousel
 * @component
 */
const FavouritesCarousel = () => {
    const favourites = useSelector((state) => {
        const id = state.general.favouritesId;
        return id ? state.shoppingCart[id].items : [];
    });
    const cartsLoading = useSelector((state) => state.general.cartsLoading);
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);
    return (
        <Box marginBottom="0px">
            {/* Favourites carousel */}
            {favourites.length == 0 && !cartsLoading ? (
                <IBuyPaper>
                    <Typography variant="h6">
                        You have no Favourite Items
                    </Typography>
                </IBuyPaper>
            ) : (
                <>
                    <TeamTitle title="My Favourites" isDesktop={isDesktop} />
                    <CardCarousel
                        tilesArray={
                            cartsLoading ? skeletonFavourites : favourites
                        }
                        tile={PRODUCT_CARD}
                        isLoading={cartsLoading}
                    />
                </>
            )}
        </Box>
    );
};

/**
 * Loading component for team category carousel. Displays
 * a 4 x skeleton carousels.
 * @component
 */
const CategoryLoadingCarousels = ({ isDesktop }) =>
    Object.keys(skeletonCategories).map((team, index) => {
        return (
            <React.Fragment key={index}>
                <TeamTitle title={team} isDesktop={isDesktop} />
                <CardCarousel
                    tilesArray={skeletonCategories[team]}
                    team={team}
                    tile={HOMEPAGE_CARD}
                    isLoading={true}
                />
            </React.Fragment>
        );
    });

/**
 * Displays team category carousels
 * @component
 */
const CategoryCarousels = ({ categories, isDesktop }) =>
    Object.keys(categories).map((team, index) => {
        return (
            <React.Fragment key={index}>
                <TeamTitle title={team} isDesktop={isDesktop} />
                <CardCarousel
                    tilesArray={categories[team]}
                    team={team}
                    tile={HOMEPAGE_CARD}
                    isLoading={false}
                />
            </React.Fragment>
        );
    });

const Homepage = () => {
    // Collect states and dispatchers
    const homepageCategoriesLoading = useSelector(
        (state) => state.general.homepageCategoriesLoading
    );
    const categories = useSelector((state) => state.homepageCategories);
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);
    return (
        <Box sx={contentBoxL2}>
            <FavouritesCarousel />
            <GradientDivider sx={{ margin: "24px 0px" }} />
            {!homepageCategoriesLoading && !categories ? (
                <IBuyPaper>
                    <Typography variant="h6">
                        No homepage tiles retrieved
                    </Typography>
                </IBuyPaper>
            ) : homepageCategoriesLoading ? (
                <CategoryLoadingCarousels isDesktop={isDesktop} />
            ) : (
                <CategoryCarousels
                    categories={categories}
                    isDesktop={isDesktop}
                />
            )}
        </Box>
    );
};

export default Homepage;
