/* These utility function control the copy requisition logic.
They should be called in useEffect clauses in the following
components:
1) src/components/requisitions/singleRequisitionSummary/index.js
2) src/components/requisitions/requisitionListPage/summaryTable.js

Care must be taken to ensure these logic sets are as DRY compliant
as possible.
*/
import { NOT_FOUND_EXCHANGE_RATE_DURING_COPY } from "constants/snackbarMessage";
import { setShowRequisitionDialogue } from "redux/actions/generalActions";
import { getApiExchangeRate } from "services/procurement/procurementAPIs";

import {
    CORPORATE_RATE,
    INTERNAL_REQUISITION,
    ITEM_TYPES_USING_NON_CAT_FORM,
    NON_PROJECT_REQ,
} from "../constants/generalConstants";
import {
    setIsInternalReq,
    updatePostPOMetadata,
} from "../redux/actions/createRequisitionActions";
import {
    getDeliveryLocById,
    getHrOrgById,
    getRequisitionById,
    getTaskAndChargeAccountsByProject,
} from "./../services/requisition/requisitionAPIs";
import alertDispatcher from "./alertsUtils";
import { isReqLineCancelled } from "./cancelRequisitionUtils";
import { amendRequisition, copyRequisition } from "./createRequisitionUtils";
import { dateFormat, objectOnCondition } from "./general";

export const loadReqForCopying = async (id, dispatch) => {
    /*
    Arguments
    ---------
    id : id
    setLoading : func
    dispatch : func
    */
    // Prepare state for copying
    const { req } = await getRequisitionById(id, dispatch);

    await copyRequisition(req, dispatch);

    const headerForm = req["requisitionHeaderForm"];
    const reqLines = req["requisitionLines"];
    const projID = req["requisitionHeaderForm"]["PROJECT_ID"];
    dispatch(setIsInternalReq(req?.SOURCING_TYPE === INTERNAL_REQUISITION));
    // Get Projects, delivery locations and and HR orgs by OU
    getAllDeliveryLocations(headerForm, reqLines, dispatch);
    if (req["requisitionHeaderForm"]["ACCOUNTED_AGAINST"] !== NON_PROJECT_REQ) {
        getAllExpOrgs(headerForm, reqLines, dispatch);
        await getTaskAndChargeAccountsByProject(projID, dispatch);
    }
    dispatch(setShowRequisitionDialogue(true));
};

export const loadReqForAmending = async (id, dispatch) => {
    /*
    Arguments
    ---------
    id : id
    setLoading : func
    dispatch : func
    
    */
    const { req } = await getRequisitionById(id, dispatch);

    // Filter out cancelled lines
    const reqFiltered = {
        ...req,
        requisitionLines: req.requisitionLines.filter(
            (line) => !isReqLineCancelled(line)
        ),
    };

    /* If all requisition lines are cancelled, do not open the form.
    Instead, raise warning message and reset form state(s). Any state
    set prior to this line must be reset below. User should not be able
    to open the form if all lines are cancelled; the button should be
    disabled based on header state. The below check is an additional safety
    check to prevent the app from crashing in the event of unforseen events
    not being caught by the header status checks.
    */
    if (reqFiltered.requisitionLines.length == 0) {
        alertDispatcher("copyAmendRequisition", 400, dispatch);
        return;
    }

    amendRequisition(reqFiltered, dispatch);

    // Copied from above logic
    const headerForm = reqFiltered["requisitionHeaderForm"];
    const reqLines = reqFiltered["requisitionLines"];
    const projID = reqFiltered["requisitionHeaderForm"]["PROJECT_ID"];
    dispatch(setIsInternalReq(req?.SOURCING_TYPE === INTERNAL_REQUISITION));
    getAllDeliveryLocations(headerForm, reqLines, dispatch);
    if (
        reqFiltered["requisitionHeaderForm"]["ACCOUNTED_AGAINST"] !==
        NON_PROJECT_REQ
    ) {
        getAllExpOrgs(headerForm, reqLines, dispatch);
        await getTaskAndChargeAccountsByProject(projID, dispatch);
    }
    dispatch(setShowRequisitionDialogue(true));
};

export const updatePostPOEditState = (
    edit,
    newValue,
    index,
    reqLines,
    postPOMetadata,
    field,
    dispatch
) => {
    /* Wites output of `hasReqLineBeenPostPOEdited` to 
    post PO metadata state.
    
    Arguments
    ---------
    edit : str
    newValue : str / date
    index int
    reqLines :  obj
    postPOMetadata : obj
    field :  func
    dispatch
    
    */
    (field == "NEED_BY_DATE" || field == "QUANTITY") &&
        dispatch(
            updatePostPOMetadata(reqLines[index].LINE_ATTRIBUTE5, {
                ...postPOMetadata[reqLines[index].LINE_ATTRIBUTE5],
                isEdited: hasReqLineBeenPostPOEdited(
                    field,
                    newValue,
                    index,
                    reqLines,
                    postPOMetadata
                ),
            })
        );
};

export const hasReqLineBeenPostPOEdited = (
    edit,
    newValue,
    index,
    reqLines,
    postPOMetadata
) => {
    /*
    Arguments
    ---------
    edit : str
    newValue : str / date
    index int
    reqLines :  obj
    postPOMetadata : obj

    Returns
    -------x
    bool

    Notes
    -----
    This function is called when a user attempts to update QUANTITY or
    NEED_BY_DATE on a requisition line during a post PO amendment. It
    confirms if the req line has been edited (ie if either of the
    aforementioned fields have been change) and returns a boolean
    accordingly.
    
    */
    const reqLineId = reqLines[index].LINE_ATTRIBUTE5;
    // Extract original values
    const originalNeedByDate = new Date(
        postPOMetadata[reqLineId].originalNeedByDate
    );
    const originalQty = postPOMetadata[reqLineId].originalQty;
    if (edit == "QUANTITY") {
        // Does new value represent a quantity change?
        const qtyEdited = newValue != originalQty;
        // Has the need by date changed?
        const needByDateEdited =
            originalNeedByDate.getTime() !=
            new Date(reqLines[index].NEED_BY_DATE).getTime();
        return qtyEdited || needByDateEdited;
    } else if (edit == "NEED_BY_DATE") {
        const newDate = new Date(newValue);
        // Does new value represent a need by date change change?
        const needByDateEdited =
            newDate.getTime() != originalNeedByDate.getTime();
        // Has the quantity been changed?
        const qtyEdited = originalQty != reqLines[index].QUANTITY;
        return qtyEdited || needByDateEdited;
    }
};

const getAllDeliveryLocations = (headerForm, reqLines, dispatch) => {
    /* Called when opening create req form to copy / amend a req. Loops
    through req header and all req lines, retrieves LOCATION_CODE for
    all DELIVER_TO_LOCATION_IDs

    /*
    Arguments
    ---------
    headerForm : obj
    reqLines : array
    dispatch func
    */
    // Header level queries
    const headerDeliverLocId = headerForm?.DELIVER_TO_LOCATION_ID;
    getDeliveryLocById(headerDeliverLocId, dispatch);
    // Line level queries
    reqLines.forEach((line, index) =>
        getDeliveryLocById(line?.DELIVER_TO_LOCATION_ID, dispatch, false, index)
    );
};

const getAllExpOrgs = (headerForm, reqLines, dispatch) => {
    /* Called when opening create req form to copy / amend a req. Loops
    through req header and all req lines, retrieves
    `EXPENDITURE_ORGANIZATION_NAME` for all `EXPENDITURE_ORGANIZATION_ID`

    /*
    Arguments
    ---------
    headerForm : obj
    reqLines : array
    dispatch func

    Notes
    -----
    expenditure organsiation == hr organisation
    */
    // Header level queries

    const headerDeliverLocId = headerForm?.EXPENDITURE_ORGANIZATION_ID;
    const ouName = headerForm?.ORG_NAME;
    getHrOrgById(headerDeliverLocId, ouName, dispatch);
    // Line level queries
    reqLines.forEach((line, index) =>
        getHrOrgById(
            line?.EXPENDITURE_ORGANIZATION_ID,
            ouName,
            dispatch,
            false,
            index
        )
    );
};

/**
 * Sets rate date to today's date for all corporate rate lines,
 * called when loading the copy requisition form, if the req
 * currency does not match its operating unit's base currency.
 * @param {Array} reqLines - array of requisition line objects
 * @param {Boolean} reqCurrencyEqOuBase - flag denoting if if the req
 * currency does matches its operating unit's base currency
 * @param {Number | string} todaysCorporateRate - value to load into
 * redux for today's corporate rate.
 * @returns {Array} - updated requisition lines array.
 */
export const setCorporateRateToToday = (
    reqLines,
    reqCurrencyEqOuBase,
    todaysCorporateRate
) => {
    if (reqCurrencyEqOuBase) {
        return reqLines;
    }
    return reqLines.map((line) => ({
        ...line,
        ...objectOnCondition(line?.RATE_TYPE === CORPORATE_RATE, {
            RATE_DATE: new Date(),
            RATE: todaysCorporateRate,
        }),
    }));
};

/**
 * Updates rate information on the requisition being copied.
 * Sets the rate date to today's date for all lines which
 * references a corporate user rate.
 * @param {*} req - iBuy requisition payload
 * @param {*} dispatch - redux dispatcher function
 * @returns
 */
export const applyTodaysCorporateRate = async (req, dispatch) => {
    // Sanitise requisitions lines field
    if (req.requisitionLines.constructor != Array) {
        return [];
    }

    // If requisition currency matches OU base currency then
    // no updates to rates are required.
    if (req?.reqCurrencyEqOuBase === true) {
        return req.requisitionLines;
    }

    // Determine if are any non catalogue or smart-form items a corporate rate
    const someNonCatItemsCorporateRate = req.requisitionLines.some(
        (line) =>
            line?.RATE_TYPE == CORPORATE_RATE &&
            ITEM_TYPES_USING_NON_CAT_FORM.includes(line?.itemType)
    );

    // If some lines are non-catalogue with a corporate rate,
    // then the frontend requires the updated rate date for
    // the copy requisition process. If not, the rate is updated
    // to a default string, which will be overwritten in the backend
    // on submission. To string is added to aid bug traceability in
    // the case the backend fails to overwrite this rate.
    let todaysCorporateRate = null;
    const from_currency = req.requisitionLines[0]?.CURRENCY_CODE;
    const to_currency = req?.ouBaseCurrency;
    if (someNonCatItemsCorporateRate) {
        try {
            const res = await getApiExchangeRate(
                from_currency,
                to_currency,
                dateFormat(new Date().toDateString(), "YYYY-MM-DD")
            );
            todaysCorporateRate = res.data.rate;
        } catch (error) {
            alertDispatcher(
                "getExchangeRateCopy",
                error?.response?.status,
                dispatch,
                NOT_FOUND_EXCHANGE_RATE_DURING_COPY(from_currency, to_currency)
            );
        }
    } else {
        todaysCorporateRate = "BACKEND MUST OVERWRITE";
    }

    return setCorporateRateToToday(
        req.requisitionLines,
        req?.reqCurrencyEqOuBase,
        todaysCorporateRate
    );
};
