import {
    SET_ALL_CANCEL_CHECKED,
    SET_CANCEL_FORM_LOADING,
    SET_CANCEL_HEADER_REASON,
    SET_CANCEL_REQ_HEADER_INFO,
    SET_CANCEL_REQ_ID,
    SET_CANCEL_REQ_LINES,
    SET_REQ_LINE_CANCEL_REASON,
    SET_REQ_LINE_CHECKED,
} from "../actionTypes";

export const setCancelReqId = (id) => ({
    type: SET_CANCEL_REQ_ID,
    payload: id,
});

export const setCancelFormLoading = (isLoading) => ({
    type: SET_CANCEL_FORM_LOADING,
    payload: isLoading,
});

export const setCancelReqHeaderInfo = (headerInfo) => ({
    type: SET_CANCEL_REQ_HEADER_INFO,
    payload: headerInfo,
});

export const setCancelReqLines = (lines) => ({
    type: SET_CANCEL_REQ_LINES,
    payload: lines,
});

export const setCancelHeaderReason = (reason) => ({
    type: SET_CANCEL_HEADER_REASON,
    payload: reason,
});

export const setReqLineChecked = (reqLineId, isChecked) => ({
    type: SET_REQ_LINE_CHECKED,
    payload: { reqLineId, isChecked },
});

export const setReqLineCancelReason = (reqLineId, reason) => ({
    type: SET_REQ_LINE_CANCEL_REASON,
    payload: { reqLineId, reason },
});

export const setAllCancelChecked = (isChecked) => ({
    type: SET_ALL_CANCEL_CHECKED,
    payload: isChecked,
});
