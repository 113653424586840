import { FormControlLabel, Switch, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import OpenFiltersButton from "components/buttons/openFiltersButton";
import CustomSwipeableDrawer from "components/customSwipeableDrawer";
import IBuyPaper from "components/displays/iBuyPaper";
import OnDesktop from "components/wrappers/onDesktop";
import OnMobile from "components/wrappers/onMobile";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { onCheckSmartForms } from "utils/productListUtils";

import {
    LOCAL_BUYER_CPA_TRUE,
    MAX_LEAD_TIME,
    MAX_UNIT_PRICE,
    MIN_UNIT_PRICE,
    SEARCH_API_DELIMITER,
    SMARTFORMS_MODE,
} from "../../constants/generalConstants";
import { MOBILE_MEDIA_QUERY } from "../../constants/mediaQueries";
import {
    setCategoryFilter,
    setLeadTimeLTEFilter,
    setLineTypeFilter,
    setLocalBuyerOnly,
    setSupplierFilter,
    setUnitOfMeasureFilter,
    setUnitPriceRangeFilter,
} from "../../redux/actions/filterPanelValues";
import {
    searchCatalogueItems,
    searchSmartFormItems,
} from "../../services/search/searchAPIs";
import { contentBoxL2 } from "../../styled/styledSXProperties/styledBoxSX";
import ValidatedAutocomplete from "../inputs/validatedAutoComplete";
import ProductsFilterPanelDispatcher from "./filterPanels/productsFilterPanelDispatcher";
import ProductResultsGrid from "./productResultsGrid";

export const sortQueryDispatcher = {
    "Sort By: Price - low to high": "UNIT_PRICE",
    "Sort By: Price - high to low": "UNIT_PRICE desc",
    "Sort By: Title - A to Z": "ITEM_DESCRIPTION",
    "Sort By: Title - Z to A": "ITEM_DESCRIPTION desc",
};

const ProductResults = () => {
    // Collect states and state dispatchers
    const activeOU = useSelector((state) => state.auth.activeOU);
    const userInfoLoading = useSelector(
        (state) => state.general.userInfoLoading
    );
    const categoryLookups = useSelector(
        (state) => state.filterPanelLookups.CATEGORY_DISPLAY
    );
    const unitsOfMeasure = useSelector(
        (state) => state.filterPanelLookups.UNIT_OF_MEASURES
    );
    const dispatch = useDispatch();
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    // Local states
    const [isLoading, setIsLoading] = useState(true);
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [results, setResults] = useState(Array(20).fill(null));
    const [searchParams, setSearchParams] = useSearchParams();
    const [sortOption, setSortOption] = useState("");
    const [showSmartForms, setShowSmartForms] = useState(false);
    const [totalResults, setTotalResults] = useState(0);

    // Listen for URL parameter changes
    useEffect(() => {
        /* Search API must only be called once a user's active OUs
        have been retrieved and saved in redux. Please NOTE - if
        add new search parameters for smartforms or catalogue items,
        please ensure they are added to the clear down utility
        function `clearSearchParamsOnSmartformsToggle`
        */
        if (!userInfoLoading) {
            readAndSetCategoryParams();
            readAndSetLeadTimeParams();
            readAndSetLocalBuyerParams();
            readAndSetUnitPriceParams();
            readAndSetLineTypeParams();
            readAndSetUOMParams();
            readAndSetSupplierParams();
            readAndSetSmartformsParams();
            getResults(
                searchParams.toString(),
                searchParams.get("smartforms") === SMARTFORMS_MODE
            );
            setPage(1); // Reset page counter
        }
    }, [searchParams, activeOU]);

    // Values dependent on lookups API should refresh when the lookups
    // data loads / reloads
    useEffect(() => {
        readAndSetCategoryParams();
    }, [categoryLookups]);
    useEffect(() => {
        readAndSetUOMParams();
    }, [unitsOfMeasure]);

    const getResults = async (params, isSmartForms) => {
        let response = [];
        setResults(Array(20).fill(null)); // Show skeletons
        setIsLoading(true);
        if (isSmartForms) {
            // Get smart forms response
            response = await searchSmartFormItems(params, activeOU);
        } else {
            // Get product page response
            response = await searchCatalogueItems(params, activeOU);
        }
        setResults(response.data ? response.data : []);
        setTotalResults(response.total_count ? response.total_count : 0);
        setIsLoading(false);
    };

    const readAndSetCategoryParams = () => {
        if (searchParams.has("category")) {
            const categoryIds = searchParams
                .get("category")
                .split(SEARCH_API_DELIMITER);
            const categories = categoryLookups.filter((x) =>
                categoryIds.includes(x.CATEGORY_ID)
            );
            dispatch(setCategoryFilter(categories));
        } else {
            dispatch(setCategoryFilter([]));
        }
    };

    const readAndSetSmartformsParams = () => {
        setShowSmartForms(searchParams.get("smartforms") === SMARTFORMS_MODE);
    };

    const readAndSetSupplierParams = () => {
        searchParams.has("supplier_name")
            ? dispatch(
                  setSupplierFilter(
                      searchParams
                          .get("supplier_name")
                          .split(SEARCH_API_DELIMITER)
                  )
              )
            : dispatch(setSupplierFilter([]));
    };

    const readAndSetUOMParams = () => {
        if (searchParams.has("unit_measure")) {
            const uomIds = searchParams
                .get("unit_measure")
                .split(SEARCH_API_DELIMITER);
            const uoms = unitsOfMeasure.filter((x) =>
                uomIds.includes(x.UNIT_MEAS_LOOKUP_CODE)
            );
            dispatch(setUnitOfMeasureFilter(uoms));
        } else {
            dispatch(setUnitOfMeasureFilter([]));
        }
    };

    const readAndSetUnitPriceParams = () => {
        let lb;
        let ub;
        searchParams.has("unit_price_ge")
            ? (lb = parseFloat(searchParams.get("unit_price_ge")))
            : (lb = MIN_UNIT_PRICE);
        searchParams.has("unit_price_le")
            ? (ub = parseFloat(searchParams.get("unit_price_le")))
            : (ub = MAX_UNIT_PRICE);
        dispatch(setUnitPriceRangeFilter([lb, ub]));
    };

    const readAndSetLeadTimeParams = () => {
        searchParams.has("lead_time")
            ? dispatch(
                  setLeadTimeLTEFilter(
                      parseFloat(searchParams.get("lead_time"))
                  )
              )
            : dispatch(setLeadTimeLTEFilter(MAX_LEAD_TIME));
    };

    const readAndSetLineTypeParams = () => {
        searchParams.has("line_type")
            ? dispatch(
                  setLineTypeFilter(
                      searchParams.get("line_type").split(SEARCH_API_DELIMITER)
                  )
              )
            : dispatch(setLineTypeFilter([]));
    };

    const readAndSetLocalBuyerParams = () => {
        searchParams.has("local_buyer_only")
            ? dispatch(
                  setLocalBuyerOnly(
                      searchParams.get("local_buyer_only") ===
                          LOCAL_BUYER_CPA_TRUE
                  )
              )
            : dispatch(setLocalBuyerOnly(false));
    };

    // Preset sort dropdown list based on URL params
    useEffect(() => {
        const selectedSort = Object.keys(sortQueryDispatcher).find(
            (sortOption) =>
                sortQueryDispatcher[sortOption] == searchParams.get("order_by")
        );
        setSortOption(selectedSort ? selectedSort : "");
    }, [searchParams]);

    // If valid sort selection entered, update url and re-run search
    const selectSortOption = (event, value) => {
        /*
        Arguments
        ---------
        event : obj
        value : obj
        */
        if (value) {
            setSortOption(value);
            searchParams.set("order_by", sortQueryDispatcher[value]);
            setSearchParams(searchParams);
            setPage(1); // Reset page counter
        }
    };

    return (
        <Box sx={{ ...contentBoxL2, display: "flex" }}>
            <Grid container spacing={3}>
                <OnDesktop>
                    <Grid item xs={12} md={4} lg={2.5}>
                        <IBuyPaper>
                            <ProductsFilterPanelDispatcher
                                searchParams={searchParams}
                                setSearchParams={setSearchParams}
                                isSmartForms={showSmartForms}
                            />
                        </IBuyPaper>
                    </Grid>
                </OnDesktop>
                <OnMobile>
                    <CustomSwipeableDrawer
                        isOpen={isFilterDrawerOpen}
                        onClose={() => setIsFilterDrawerOpen(false)}
                    >
                        <ProductsFilterPanelDispatcher
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                            isSmartForms={showSmartForms}
                        />
                    </CustomSwipeableDrawer>
                </OnMobile>

                <Grid item xs={12} md={8} lg={9.5}>
                    <Grid container spacing={3}>
                        <Grid
                            container
                            item
                            xs={12}
                            justifyContent={
                                isDesktop ? "flex-end" : "space-around"
                            }
                            flexWrap="wrap"
                            alignItems="center"
                        >
                            <Grid item order={!isDesktop && 4} xs={6} lg={2}>
                                <Typography
                                    variant="subtitle2"
                                    color="text.primary"
                                    textAlign="center"
                                >
                                    Total results:{" "}
                                    {totalResults.toLocaleString()}
                                </Typography>
                            </Grid>
                            <OnMobile>
                                <Grid order={3} item xs={6} lg={2}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={showSmartForms}
                                                onChange={(e) =>
                                                    onCheckSmartForms(
                                                        e.target.checked,
                                                        searchParams,
                                                        setSearchParams
                                                    )
                                                }
                                                disabled
                                            />
                                        }
                                        labelPlacement="start"
                                        label="Show Smart Forms"
                                    />
                                </Grid>
                            </OnMobile>
                            <OnMobile>
                                <Grid
                                    marginBottom={isDesktop ? 0 : 2}
                                    order={!isDesktop && 1}
                                    item
                                    xs={5.5}
                                    lg={2}
                                    textAlign="center"
                                >
                                    <OpenFiltersButton
                                        setIsOpen={setIsFilterDrawerOpen}
                                    />
                                </Grid>
                            </OnMobile>
                            <Grid
                                xs={5.5}
                                lg={2}
                                item
                                order={!isDesktop && 2}
                                marginBottom={isDesktop ? 0 : 2}
                                minWidth="30%"
                            >
                                <ValidatedAutocomplete
                                    label="Select sort by option...."
                                    labelId="sort-options"
                                    placeholder="Select sorting rule"
                                    onChange={selectSortOption}
                                    options={Object.keys(sortQueryDispatcher)}
                                    value={sortOption}
                                />
                            </Grid>
                        </Grid>
                        {results.length == 0 && !isLoading ? (
                            <Grid item xs={12}>
                                <IBuyPaper>
                                    <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                    >
                                        {`No ${
                                            showSmartForms
                                                ? "smart forms"
                                                : "catalogue items"
                                        } matching search
                                        criteria`}
                                    </Typography>
                                </IBuyPaper>
                            </Grid>
                        ) : (
                            <ProductResultsGrid
                                isLoading={isLoading}
                                page={page}
                                results={results}
                                searchParams={searchParams}
                                setPage={setPage}
                                setResults={setResults}
                                totalResults={totalResults}
                                isDesktop={isDesktop}
                                showSmartForms={showSmartForms}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ProductResults;
