import { useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { REQUISITION_SUBMIT_CONFIRM_MESSAGE } from "../../../constants/generalConstants";
import { MOBILE_MEDIA_QUERY } from "../../../constants/mediaQueries";
import { setPageNum } from "../../../redux/actions/createRequisitionActions";
import { setShowRequisitionDialogue } from "../../../redux/actions/generalActions";
import { postRequisition } from "../../../services/requisition/requisitionAPIs";
import { styledDialogChildSX } from "../../../styled/styledSXProperties/styledDialogueSX";
import { baseStyledPaperSX } from "../../../styled/styledSXProperties/styledPaperSX";
import alertDispatcher from "../../../utils/alertsUtils";
import {
    allReqLinesDifferent,
    closeCreateReqForm,
    someReqLinesAmended,
} from "../../../utils/createRequisitionUtils";
import { totalCost } from "../../../utils/shoppingCartUtils";
import CurrencyFigure from "../../displays/currencyFigure";
import DetailListPiped from "../../displays/detailListPiped";
import DetailListVertical from "../../displays/detailListVertical";
import YesNoPrompt from "../prompts/yesNoPrompt";
import HeaderForm from "./page1HeaderForm/headerForm";
import LineItemForm from "./page2LineItems/lineItemForm";
import Review from "./page3Review/review";
import RequisitionDialogActions from "./requisitionDialogActions";
import RequisitionDialogTitle from "./requisitionDialogTitle";

const RequisitionDialogueContentDispatcher = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    const activeCart = useSelector((state) => state.general.activeCart);
    const isInternalReq = useSelector(
        (state) => state.createRequisition.isInternalReq
    );
    const pageNum = useSelector((state) => state.createRequisition.pageNum);
    const reqLines = useSelector(
        (state) => state.createRequisition.iBuyRequisition.requisitionLines
    );
    const reqHeader = useSelector(
        (state) => state.createRequisition.iBuyRequisition.requisitionHeaderForm
    );
    const req = useSelector((state) => state.createRequisition.iBuyRequisition);
    const isPostPoReq = useSelector(
        (state) => state.createRequisition.isPostPoReq
    );
    const postPOMetadata = useSelector(
        (state) => state.createRequisition.postPOMetadata
    );
    const validatedPages = useSelector(
        (state) => state.createRequisition.validatedPages
    );
    const isAmendedReq = useSelector(
        (state) => state.createRequisition.amendedReq
    );

    // Local state
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
    // Modal closing functions
    const [cancelPromptOpen, setCancelPromptOpen] = useState(false);
    const [submitPromptOpen, setSubmitPromptOpen] = useState(false);

    const closeForm = () => {
        closeCreateReqForm(dispatch);
    };

    const totalValue = totalCost(reqLines);

    const handleComplete = () => {
        const newValidatedPages = validatedPages;
        newValidatedPages[pageNum] = false;

        // If a post PO amendment, block submission if no changes made
        if (isPostPoReq && !someReqLinesAmended(postPOMetadata)) {
            alertDispatcher("reqFormSubmit", 400, dispatch);
            return;
        }

        // Prevent submission is duplicate requisition lines present in payload.
        if (!allReqLinesDifferent(req.requisitionLines)) {
            alertDispatcher("reqFormSubmit", 409, dispatch);
            return;
        }

        for (const line of req.requisitionLines) {
            if (!line?.CHANGE_ACTION && isAmendedReq) {
                line.CHANGE_ACTION = "UNCHANGED";
            }
        }

        // Strip fields not required in database. Autocomplete inputs such as delivery location
        // store non-id information at req line/header level for display purposes. Only IDs
        // should be sent to and displayed in Cosmos.
        const headerForm = req.requisitionHeaderForm;
        /*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
        const {
            LOCATION_CODE,
            EXPENDITURE_ORGANIZATION_NAME,
            CONCATENATED_SEGMENTS,
            SEGMENT1_NAME,
            SEGMENT2_NAME,
            SEGMENT3_NAME,
            SEGMENT4_NAME,
            IS_VALIDATED,
            ...headerFormStripped
        } = headerForm;
        /*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
        const linesStripped = req.requisitionLines.map((line) => {
            const {
                LOCATION_CODE,
                EXPENDITURE_ORGANIZATION_NAME,
                CONCATENATED_SEGMENTS,
                SEGMENT1_NAME,
                SEGMENT2_NAME,
                SEGMENT3_NAME,
                SEGMENT4_NAME,
                status,
                statusCode,
                IS_VALIDATED,
                AUTOSOURCE_DOC_HEADER_ID,
                AUTOSOURCE_DOC_LINE_NUM,
                ...lineStripped
            } = line;
            return lineStripped;
        });

        const post = {
            requisitionLines: linesStripped,
            requisitionHeaderForm: headerFormStripped,
            approvalsRef: [],
            supplierName: req.supplierName,
            id: req.id,
        };

        if (isPostPoReq) {
            // if post po we have decided to strip all fields that aren't directly allowed by amendments
            post.requisitionLines = post.requisitionLines.map((line) => {
                return {
                    QUANTITY: parseFloat(line?.QUANTITY),
                    NEED_BY_DATE: line?.NEED_BY_DATE,
                    LINE_ATTRIBUTE5: line?.LINE_ATTRIBUTE5,
                    HARMONY_REQUISITION_LINE_ID:
                        line?.HARMONY_REQUISITION_LINE_ID,
                };
            });
        }

        postRequisition(
            post,
            dispatch,
            activeCart,
            navigate,
            newValidatedPages,
            isAmendedReq
        );
    };

    // Create form validator for line item form. Note - should you wish
    // to add a validator for the header form, this should be done via
    // a separate call to the useForm hook (there should be one validator
    // per form page).
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const isCpaReq = !!reqLines[0]?.CPA_NUMBER;

    const requisitionDetails = [
        {
            header: "Operating Unit",
            content: req.requisitionHeaderForm.ORG_NAME
                ? req.requisitionHeaderForm.ORG_NAME
                : "Not available",
            hasError: !req.requisitionHeaderForm.ORG_NAME,
        },
        {
            header: isCpaReq ? "Contract Number" : "Supplier",
            content: isCpaReq
                ? reqLines[0]?.CPA_NUMBER || "Not Specified"
                : req.supplierName || "Not Specified",
        },
        {
            header: "Total",
            content: totalValue ? (
                <CurrencyFigure
                    value={totalValue}
                    code={reqLines[0]?.CURRENCY_CODE}
                />
            ) : (
                "Invalid Input"
            ),
            hasError: !totalValue,
        },
        {
            header: "Sourcing Mode",
            content: isInternalReq ? "Internal" : "Purchase",
        },
    ];

    return (
        <>
            <RequisitionDialogTitle setConfirmOpen={setCancelPromptOpen} />
            <DialogContent sx={styledDialogChildSX}>
                <Grid container gap={2}>
                    <Grid item xs={12}>
                        {isDesktop ? (
                            <DetailListPiped details={requisitionDetails} />
                        ) : (
                            <DetailListVertical
                                condensed={true}
                                details={requisitionDetails}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {pageNum == 0 && (
                            <HeaderForm
                                setIsNextButtonDisabled={
                                    setIsNextButtonDisabled
                                }
                            />
                        )}
                        {pageNum == 1 && (
                            <LineItemForm
                                errors={errors}
                                register={register}
                                setIsNextButtonDisabled={
                                    setIsNextButtonDisabled
                                }
                            />
                        )}
                        {pageNum == 2 && (
                            <Review reqHeader={reqHeader} reqLines={reqLines} />
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={styledDialogChildSX}>
                <RequisitionDialogActions
                    lineFormHandleSubmit={handleSubmit}
                    isNextButtonDisabled={isNextButtonDisabled}
                    setSubmitConfirmOpen={setSubmitPromptOpen}
                    handleComplete={handleComplete}
                />
            </DialogActions>

            <YesNoPrompt
                title="Close requisition form?"
                subtitle="Closing this form prior to submission will result in all entered requisition data being lost. Are you sure you wish to close this form?"
                promptOpen={cancelPromptOpen}
                setPromptOpen={setCancelPromptOpen}
                onClickYes={closeForm}
            />

            <YesNoPrompt
                title="Submit Requisition?"
                subtitle={REQUISITION_SUBMIT_CONFIRM_MESSAGE}
                promptOpen={submitPromptOpen}
                setPromptOpen={setSubmitPromptOpen}
                onClickYes={handleComplete}
            />
        </>
    );
};

const RequisitionDialog = () => {
    // Collect states and state dispatchers
    const isOpen = useSelector(
        (state) => state.general.showRequisitionDialogue
    );
    const darkMode = useSelector((state) => state.general.darkMode);
    const dispatch = useDispatch();
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    const close = () => {
        dispatch(setPageNum(0));
        dispatch(setShowRequisitionDialogue(false));
    };

    const handleClose = (e_, reason) => {
        if (reason && reason == "backdropClick") return;

        close();
    };

    return (
        <Dialog
            fullWidth
            maxWidth="xl"
            onClose={handleClose}
            open={isOpen}
            fullScreen={!isDesktop}
            PaperProps={{ sx: baseStyledPaperSX(darkMode) }}
        >
            <RequisitionDialogueContentDispatcher />
        </Dialog>
    );
};

export default RequisitionDialog;
