import { Box, useMediaQuery } from "@mui/material";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { PropTypes } from "prop-types";
import { useState } from "react";

import { HOMEPAGE_CARD, PRODUCT_CARD } from "../../constants/generalConstants";
import { MOBILE_MEDIA_QUERY } from "../../constants/mediaQueries";
import ProductCard from "../productList/productCard";
import HomepageCardSkeleton from "../skeletons/homepageCardSkeleton";
import ProductCardSkeleton from "../skeletons/productCardSkeleton";
import "./carouselStyles.css";
import HomepageCard from "./homepageCard";

const responsive = (numTiles) => ({
    "(min-width: 0px)": {
        slides: { spacing: 24, perView: 1 },
        drag: numTiles <= 1 ? false : true,
    },
    "(min-width: 600px)": {
        slides: { spacing: 24, perView: 2 },
        drag: numTiles <= 2 ? false : true,
    },
    "(min-width: 850px)": {
        slides: { spacing: 24, perView: 3 },
        drag: numTiles <= 3 ? false : true,
    },
    "(min-width: 1100px)": {
        slides: { spacing: 24, perView: 4 },
        drag: numTiles <= 4 ? false : true,
    },
    "(min-width: 1450px)": {
        slides: { spacing: 24, perView: 5 },
        drag: numTiles <= 5 ? false : true,
    },
    "(min-width: 1750px)": {
        slides: { spacing: 24, perView: 6 },
        drag: numTiles <= 6 ? false : true,
    },
    "(min-width: 2000px)": {
        slides: { spacing: 24, perView: 7 },
        drag: numTiles <= 7 ? false : true,
    },
    "(min-width: 2400px)": {
        slides: { spacing: 24, perView: 8 },
        drag: numTiles <= 8 ? false : true,
    },
});

const ResizePlugin = (slider) => {
    const observer = new ResizeObserver(function () {
        slider.update();
    });

    slider.on("created", () => {
        observer.observe(slider.container);
    });
    slider.on("destroyed", () => {
        observer.unobserve(slider.container);
    });
};

const CardCarousel = ({ tilesArray, tile, isLoading = false, team = null }) => {
    // Collect states and dispatchers
    const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [perView, setPerView] = useState(5);
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);
    const [sliderRef, instanceRef] = useKeenSlider(
        {
            breakpoints: responsive(tilesArray.length),
            loop: true,
            mode: "free-snap",
            dragSpeed: 0.6,
            slideChanged(slider) {
                setCurrentSlide(slider.track.details.rel);
            },
            created() {
                setLoaded(true);
            },
            updated() {
                instanceRef?.current?.options?.slides?.perView &&
                    setPerView(instanceRef?.current?.options?.slides?.perView);
            },
        },
        [ResizePlugin]
    );

    return (
        <>
            <Box className="navigation-wrapper">
                <Box ref={sliderRef} className="keen-slider">
                    {tilesArray.map((tileObj, index) => {
                        return tile == HOMEPAGE_CARD ? (
                            <Box
                                key={index}
                                className="keen-slider__slide number-slide1"
                                style={{ overflow: "visible" }}
                            >
                                {isLoading ? (
                                    <HomepageCardSkeleton />
                                ) : (
                                    <HomepageCard
                                        name={tileObj.L3}
                                        categoriesIds={tileObj.L6}
                                        team={team}
                                        isDesktop={isDesktop}
                                    />
                                )}
                            </Box>
                        ) : (
                            <Box
                                key={index}
                                className={
                                    isDesktop
                                        ? "keen-slider__slide number-slide1"
                                        : "slider-mobile keen-slider__slide number-slide1"
                                }
                            >
                                {isLoading ? (
                                    <ProductCardSkeleton />
                                ) : (
                                    <ProductCard
                                        product={tileObj}
                                        isDesktop={isDesktop}
                                    />
                                )}
                            </Box>
                        );
                    })}
                </Box>
                {tilesArray.length > perView && loaded && instanceRef.current && (
                    <>
                        <Arrow
                            left
                            onClick={(e) =>
                                e.stopPropagation() ||
                                instanceRef.current?.prev()
                            }
                            disabled={currentSlide === 0}
                        />

                        <Arrow
                            onClick={(e) =>
                                e.stopPropagation() ||
                                instanceRef.current?.next()
                            }
                            disabled={
                                currentSlide ===
                                instanceRef.current.track.details.slides
                                    .length -
                                    1
                            }
                        />
                    </>
                )}
            </Box>
        </>
    );
};

const Arrow = (props) => {
    const disabled = props.disabled ? " arrow--disabled" : "";
    return (
        <svg
            aria-label={props.left ? "prevArrow" : "nextArrow"}
            className={`arrow ${
                props.left ? "arrow--left" : "arrow--right"
            } ${disabled}`}
            onClick={props.onClick}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            {props.left && (
                <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
            )}
            {!props.left && (
                <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
            )}
        </svg>
    );
};

CardCarousel.propTypes = {
    tilesArray: PropTypes.array,
    tile: PropTypes.oneOf([HOMEPAGE_CARD, PRODUCT_CARD]),
    // isLoading: false,
};

export default CardCarousel;
