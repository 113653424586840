import {
    Box,
    CardContent,
    CardHeader,
    Grid,
    useMediaQuery,
} from "@mui/material";
import { MOBILE_MEDIA_QUERY } from "constants/mediaQueries";
import PropTypes from "prop-types";
import React from "react";

import IBuyCard from "./iBuyCard";

/**
 * Procurement Header Banner
 * @param {node} Header
 * @param {node} Chips
 * @param {node} Buttons
 * @param {node} SubHeader
 * @returns
 */
const ProcurementHeader = ({ Header, Chips, Buttons, SubHeader }) => {
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    return (
        <Grid item xs={12}>
            <IBuyCard additionalSx={{ mb: 0 }}>
                <CardHeader
                    sx={{ pb: 0 }}
                    title={
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid
                                container
                                spacing={0}
                                justifyContent={
                                    isDesktop ? "space-between" : "center"
                                }
                            >
                                <Grid
                                    container
                                    item
                                    xs={"auto"}
                                    mb={isDesktop ? 0 : 2}
                                    alignItems="center"
                                    flexDirection="row"
                                    justifyContent={
                                        isDesktop ? "left" : "center"
                                    }
                                >
                                    {Header}
                                </Grid>
                                {Chips && (
                                    <Grid
                                        container
                                        item
                                        xs={"auto"}
                                        mb={isDesktop ? 0 : 2}
                                        alignContent="center"
                                        justifyContent="center"
                                    >
                                        {Chips}
                                    </Grid>
                                )}
                                <Grid
                                    container
                                    item
                                    xs={"auto"}
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    {Buttons}
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    titleTypographyProps={{
                        mb: isDesktop ? 0 : 2,
                    }}
                />
                <CardContent sx={{ pt: isDesktop ? 1 : 0, pb: 0, mb: 0 }}>
                    <Grid
                        container
                        flexDirection={isDesktop ? "row" : "column"}
                        item
                        sm={12}
                        xl={8}
                        order={{ sm: 1, xl: 2 }}
                    >
                        {SubHeader}
                    </Grid>
                </CardContent>
            </IBuyCard>
        </Grid>
    );
};

ProcurementHeader.propTypes = {
    Header: PropTypes.node,
    Chips: PropTypes.node,
    Buttons: PropTypes.node,
    SubHeader: PropTypes.node,
};

export default ProcurementHeader;
