import { AddCircle, Cancel, ChangeCircle } from "@mui/icons-material";
import {
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { sortAlphanumericObjectList } from "../../../../../utils/general";

const ALLOWED_FIELDS = [
    "NEED_BY_DATE",
    "QUANTITY",
    "NOTE_TO_BUYER",
    "NOTE_TO_APPROVER",
];

const StagedRequisitionComparisonTable = ({ reqLine, stagedLine }) => {
    const [comparisonValues, setComparisonValues] = useState([]);

    const tableHeaderStyle = { color: "text.subheader", fontWeight: "bold" };

    useEffect(() => {
        let changedValues = [];

        Object.entries(reqLine)
            .filter(([key]) => shouldShowField(key))
            .forEach(([key, newVal]) => {
                const prevVal = JSON.stringify(stagedLine?.[key]);
                newVal = JSON.stringify(newVal);

                if (prevVal) {
                    if (prevVal !== newVal) {
                        changedValues.push({
                            FIELD: key,
                            TYPE: "CHANGED",
                            NEW_VAL: newVal,
                            PREV_VAL: prevVal,
                        });
                    }
                } else {
                    if (newVal && newVal !== "null") {
                        // omit null values since they are removed by harmony or processors
                        changedValues.push({
                            FIELD: key,
                            TYPE: "ADDED",
                            NEW_VAL: newVal,
                        });
                    }
                }
            });

        Object.entries(stagedLine).forEach(([key, prevVal]) => {
            const newVal = JSON.stringify(reqLine?.[key]);
            prevVal = JSON.stringify(prevVal);

            if (!newVal && shouldShowField(key)) {
                // omit null values since they are removed by harmony or processors
                changedValues.push({
                    FIELD: key,
                    TYPE: "REMOVED",
                    PREV_VAL: prevVal,
                });
            }
        });

        // sort by field name
        changedValues = sortAlphanumericObjectList(changedValues, "FIELD");

        setComparisonValues(changedValues);
    }, []);

    const shouldShowField = (field) => {
        return ALLOWED_FIELDS.includes(field);
    };

    const getTypeIcon = (type) => {
        if (type === "ADDED") {
            return <AddCircle color="success" />;
        } else if (type === "REMOVED") {
            return <Cancel color="error" label="test" />;
        } else {
            return <ChangeCircle />;
        }
    };

    return (
        <TableContainer
            component={Paper}
            data-testid="stagedRequisitionComparisonTable"
        >
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow sx={{ fontWeight: "bold" }}>
                        <TableCell sx={tableHeaderStyle}>Field Name</TableCell>
                        <TableCell></TableCell>
                        <TableCell sx={tableHeaderStyle} align="right">
                            Previous Response
                        </TableCell>
                        <TableCell sx={tableHeaderStyle} align="right">
                            Amended Response
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {comparisonValues.map((row, index) => (
                        <TableRow
                            key={`changed-row-${index}`}
                            data-testid={`changed-row`}
                            sx={{
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                <Chip label={row.FIELD} size="small"></Chip>
                            </TableCell>
                            <TableCell>
                                <Grid
                                    container
                                    justify="flex-end"
                                    alignItems="center"
                                    wrap="nowrap"
                                    gap="5px"
                                >
                                    <Grid item>{getTypeIcon(row.TYPE)} </Grid>
                                    <Grid item>{row.TYPE}</Grid>
                                </Grid>
                            </TableCell>
                            <TableCell align="right">{row.PREV_VAL}</TableCell>
                            <TableCell align="right">{row.NEW_VAL}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

StagedRequisitionComparisonTable.propTypes = {
    reqLine: PropTypes.object,
    stagedLine: PropTypes.object,
};

export default StagedRequisitionComparisonTable;
