export const generateCart = (id, name) => {
    /*
    Arguments
    ---------
    id : number
    name : string

    Returns
    -------
    obj
    */
    return {
        id: id,
        name: name,
        userId: null,
        items: [],
        enabled: true,
    };
};
