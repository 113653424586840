import { Skeleton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import React from "react";
import { DetailListType } from "types/propTypes";

import FormatNameValue from "./formatNameValue";

const TypographyContentWrapper = ({ children }) => {
    return <Typography variant="body1">{children}</Typography>;
};

const FragmentContentWrapper = ({ children }) => <>{children}</>;

const DetailListVertical = ({ details, condensed = false }) => {
    return (
        <Grid container>
            {details.map((detail, index) => {
                const ContentWrapper =
                    typeof detail.content === "string" ||
                    typeof detail.content === "number"
                        ? TypographyContentWrapper
                        : FragmentContentWrapper;

                const SubContentWrapper =
                    typeof detail.subContent === "string" ||
                    typeof detail.subContent === "number"
                        ? TypographyContentWrapper
                        : FragmentContentWrapper;

                return (
                    <React.Fragment key={index}>
                        {condensed ? (
                            <Grid item xs={12}>
                                <FormatNameValue
                                    name={detail.header}
                                    value={detail.content}
                                    variant="body1"
                                    error={detail.hasError}
                                    disableCopy={detail.disableCopy}
                                />
                            </Grid>
                        ) : (
                            <>
                                {!detail.hideHeader && (
                                    <Grid item xs={6}>
                                        <Typography
                                            variant="body1"
                                            color="text.secondary"
                                        >
                                            {detail.header}:
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={detail.hideHeader ? 12 : 6}>
                                    {detail.isLoading ? (
                                        <Skeleton variant="text" />
                                    ) : (
                                        <ContentWrapper>
                                            {detail.content}
                                        </ContentWrapper>
                                    )}
                                </Grid>
                            </>
                        )}
                        {detail.subContent && (
                            <Grid item xs={12}>
                                <SubContentWrapper>
                                    {detail.subContent}
                                </SubContentWrapper>
                            </Grid>
                        )}
                    </React.Fragment>
                );
            })}
        </Grid>
    );
};

DetailListVertical.defaultProps = {
    condensed: false,
};

DetailListVertical.propTypes = {
    details: DetailListType.isRequired,
    condensed: PropTypes.bool,
};

export default DetailListVertical;
