import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { LIGHT_MODE } from "constants/generalConstants";

import { baseTheme } from "./baseTheme";
import { SSE_PRIMARY_DARK_BLUE } from "./colours";

export const lightTheme = createTheme(
    deepmerge(baseTheme, {
        name: "lightTheme",
        palette: {
            mode: LIGHT_MODE,
            primary: {
                main: SSE_PRIMARY_DARK_BLUE,
            },
            secondary: {
                main: SSE_PRIMARY_DARK_BLUE,
            },
            text: {
                primary: "#30404b",
                secondaryLight: "#a8aeb3",
                subheader: SSE_PRIMARY_DARK_BLUE,
            },
            background: {
                default: "#FFFFFF",
                paper: "#FFFFFF",
                divider: "linear-gradient(to right, #FFFFFF, #d3d3d3, #FFFFFF)",
                dialogue: "#FFFFFF",
                accordion: "#fafbfc",
            },
            success: {
                main: "#359039",
            },
            info: {
                main: SSE_PRIMARY_DARK_BLUE,
            },
            iconButton: {
                main: SSE_PRIMARY_DARK_BLUE,
            },
        },
    })
);
