import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Button from "@mui/material/Button";

const StatusSwitchButton = ({ action }) => {
    if (!action || action === "") {
        action = "PENDING";
    }
    action = action.toUpperCase();

    let displayedAction = action;
    let color = "info";
    let icon = null;

    if (action == "APPROVE") {
        displayedAction = "APPROVED";
        color = "success";
        icon = <CheckCircleOutlineIcon />;
    } else if (action == "REJECT") {
        displayedAction = "REJECTED";
        color = "error";
        icon = <CancelIcon />;
    }

    return (
        <Button
            name="statusButton"
            variant="outlined"
            size="small"
            color={color}
            endIcon={icon}
            sx={{
                minWidth: "110px",
                marginTop: "10px",
                mr: 2,
                fontWeight: "bold",
            }}
        >
            {displayedAction}
        </Button>
    );
};

export default StatusSwitchButton;
