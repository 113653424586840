// Returns action creators for each create receipt action type
import {
    SET_ALL_CHECKED,
    SET_CREATE_RECEIPT_LOADING,
    SET_HEADER_INPUT,
    SET_PO_SHIPMENT_CHECK,
    SET_PO_SHIPMENTS,
    SET_RECEIPT_OU,
    SET_RECEIPT_QUANTITY,
    SET_RECEIPT_REQ_NUM,
    SET_REQUESTER,
    SET_SUBMITTED_DATE,
} from "../actionTypes";

// Action creators for create receipt slice
export const setAllChecked = (checked) => {
    return { type: SET_ALL_CHECKED, payload: checked };
};

export const setHeaderInput = (newHeaderInput) => {
    return { type: SET_HEADER_INPUT, payload: newHeaderInput };
};

export const setPoShipmentCheck = (index, checked) => {
    return { type: SET_PO_SHIPMENT_CHECK, payload: { index, checked } };
};

export const setPoShipments = (poShipments) => {
    return { type: SET_PO_SHIPMENTS, payload: poShipments };
};

export const setReceiptQuantity = (index, quantity) => {
    return { type: SET_RECEIPT_QUANTITY, payload: { index, quantity } };
};

export const setReceiptReqNum = (iBuyReqNumber) => {
    return { type: SET_RECEIPT_REQ_NUM, payload: iBuyReqNumber };
};

export const setCreateReceiptLoading = (isLoading) => {
    return { type: SET_CREATE_RECEIPT_LOADING, payload: isLoading };
};

export const setRequester = (requesterName) => {
    return { type: SET_REQUESTER, payload: requesterName };
};

export const setReceiptOU = (OU) => {
    return { type: SET_RECEIPT_OU, payload: OU };
};

export const setSubmittedDate = (submittedDate) => {
    return { type: SET_SUBMITTED_DATE, payload: submittedDate };
};
