import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import OnDesktop from "components/wrappers/onDesktop";
import { TOOLTIP_WHAT_IS_CORRECTION } from "constants/tooltipText";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { SERVICE } from "../../../../constants/generalConstants";
import ReceiptCorrectionLine from "./receiptCorrectionLine";

const ReceiptCorrection = ({ errors, register }) => {
    const receiptLines = useSelector((state) => {
        const receiptLines = state.receiptSummary.receipt?.receiptLines;
        return receiptLines ? receiptLines : [];
    });
    return (
        <Grid container spacing={2} marginBottom="16px">
            <Grid item xs={12} display="flex" flexDirection="row">
                <Typography variant="h5" color="text.subheader">
                    Receipt Lines Available for Correction
                </Typography>
                <OnDesktop>
                    <Tooltip
                        title={TOOLTIP_WHAT_IS_CORRECTION}
                        placement="top-start"
                        disableInteractive
                    >
                        <IconButton onClick={null} sx={{ p: 0, ml: 2 }}>
                            <HelpOutlineRoundedIcon />
                        </IconButton>
                    </Tooltip>
                </OnDesktop>
            </Grid>
            {receiptLines.map((rcvLine, index) => {
                // Destructured to enable prop-type checking on key arguments
                const {
                    checked,
                    PAID_INVOICE_EXIST,
                    updatedQuantity,
                    reason,
                    PO_LINE_TYPE,
                    ...line
                } = rcvLine;
                return (
                    <Grid item xs={12} key={index}>
                        <ReceiptCorrectionLine
                            checked={checked}
                            updatedQuantity={updatedQuantity}
                            reason={reason}
                            lineIndex={index}
                            paidInvoiceExist={PAID_INVOICE_EXIST}
                            rcvLine={line}
                            errors={errors}
                            register={register}
                            byAmount={PO_LINE_TYPE == SERVICE ? true : false}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};

ReceiptCorrection.propTypes = {
    errors: PropTypes.object,
    register: PropTypes.func,
};

export default ReceiptCorrection;
