import { setInitialThemePreference } from "utils/general";

import { generateCart } from "../utils/reduxUtils";
import {
    DEFAULT_CART_ID,
    DEFAULT_CART_NAME,
    MAX_LEAD_TIME,
    MAX_PRICE,
    MAX_UNIT_PRICE,
    MIN_PRICE,
    MIN_UNIT_PRICE,
    NEW_NON_CAT_ITEM,
} from "./../constants/generalConstants";

// Define all initial states in the app
export const initialState = {
    shoppingCart: {
        [DEFAULT_CART_ID]: generateCart(DEFAULT_CART_ID, DEFAULT_CART_NAME),
    },
    auth: {
        userName: null,
        email: null,
        samAccountName: null,
        id_token: null,
        operatingUnits: [],
        role: null,
        activeOU: null,
    },
    general: {
        collapseNavBar: true,
        searchCategory: "All",
        cartsLoading: true,
        homepageCategoriesLoading: true,
        isScrolling: false,
        darkMode: setInitialThemePreference(),
        showAddToNewNamedCartDialogue: false,
        addToNewNamedCartItem: {},
        activeSmartForm: null,
        showCancelRequisitionDialogue: false,
        showShoppingCartSummaryPopper: false,
        showCreateNamedCartDialogue: { open: false, mode: null },
        showManageCartDialogue: false,
        showSmartFormsDialogue: false,
        showNonCatalogueDialogue: false,
        showApprovalsDialogue: { open: false, approve: null, reqId: null },
        showDisputeDialogue: false,
        showReceiptingDialogue: false,
        showReceiptSummaryDialogue: false,
        showRequisitionDialogue: false,
        showBatchChangeReqOwnerDialogue: false,
        showUserInfo: false,
        activeCart: DEFAULT_CART_ID,
        favouritesId: null,
        approvalsNotificationCount: 0,
        receiptsNotificationCount: 0,
        userInfoLoading: true,
        speedDialRight: null,
    },
    alerts: {
        snackbarQueue: [],
    },
    filterPanelLookups: {
        CATEGORY: [],
        CATEGORY_DISPLAY: [],
        LINE_TYPE: [],
        UNIT_OF_MEASURES: [],
        SUPPLIER_NAME: [],
        CURRENCIES: [],
        BASE_CURRENCIES: [],
    },
    filterPanelValues: {
        category: [],
        leadTimeLTE: MAX_LEAD_TIME,
        unitPriceRange: [MIN_UNIT_PRICE, MAX_UNIT_PRICE],
        lineTypes: [],
        unitOfMeasures: [],
        suppliers: [],
        cpaNumber: "",
        localBuyerOnly: false,
    },
    homepageCategories: null,
    createRequisition: {
        pageNum: 0,
        validatedPages: {},
        iBuyRequisition: {},
        submitLoading: false,
        copyReq: null,
        amendedReq: null,
        isPostPoReq: false,
        projectsLoading: false,
        hrOrgsLoading: false,
        reqListAPIRefreshKey: true,
        postPOMetadata: {},
        isInternalReq: null,
        formLoading: false,
        hedgingCheckPassed: false,
    },
    formLookups: {
        chargeAccounts: [],
        tasks: [],
        expenditureTypes: [
            "Contracts",
            "Design",
            "Materials",
            "Road Charges",
            "Services",
            "Subsidiaries SEC",
        ],
        expenditureOrgs: [],
        locationCode: null,
        orgs: [],
    },
    reqFilterPanel: {
        mode: "ALL",
        receiptStatus: "Y",
        OU: null,
        priceRange: [MIN_PRICE, MAX_PRICE],
        status: null,
        requisitionNumberSearch: "",
        poNumberSearch: "",
        projectSearch: "",
        supplierSearch: "",
        requesterSearch: "",
        dateLTE: null,
        dateGTE: null,
        receiptReqStatus: [],
        showCancelled: false,
        receiptYear: new Date().getFullYear(),
    },
    nonCatForm: {
        reqLineIndex: null,
        product: {},
        mode: NEW_NON_CAT_ITEM,
        context: NEW_NON_CAT_ITEM,
        page: 0,
        supplierContactDetails: [],
        supplierSites: [],
        sitesInitialLoading: false,
        contactsInitialLoading: false,
        smartFormsDataLoading: false,
        informationTemplatesLoading: false,
    },
    createReceipt: {
        iBuyReqNumber: null,
        isLoading: false,
        operatingUnit: null,
        requesterName: null,
        submittedDate: null,
        headerInput: {
            uniqueReference: "",
            receiptDate: null,
            comments: "",
        },
        poShipments: [],
    },
    receiptSummary: {
        receiptId: null,
        receipt: {},
        pageNum: 0,
        isLoading: false,
    },
    singleReqSummary: {
        invoices: [],
        POs: [],
        receipts: [],
        reqLinesLoadingState: {},
        displayInReqCurrency: true,
    },
    cancelRequisition: {
        iBuyReqNumber: null,
        headerReason: "",
        isLoading: false,
        requisitionHeaderInfo: {},
        requisitionLines: [],
    },
    changeRequisitionOwner: {
        requisition: undefined,
        toOwner: undefined,
    },
};
