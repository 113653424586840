import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

import {
    CREATED,
    FAILED_REQ_LINE_STATUS_CODE,
    INVALID_REQ_LINE_STATUS_CODE,
} from "../../../constants/generalConstants";

const CancelAlert = ({ reqLine, isCancellationPending }) => {
    const cancelledBy = reqLine?.REQ_CANCELLED_BY_FULL_NAME || "UNAVAILABLE";
    const date = reqLine?.REQ_LINE_CANCEL_DATE || "DATE UNAVAILABLE";
    const reason = reqLine?.REQUISITION_CANCEL_REASON || "NO REASON SUPPLIED";
    return (
        <AlertTitle>
            This requisition line was cancelled by {cancelledBy} on {date} for
            the following reason: {reason}
            {isCancellationPending &&
                ". The cancellation is in a pending state whilst being verified by Harmony."}
        </AlertTitle>
    );
};

const ErrorAlert = ({ lineStatus }) => {
    return (
        <>
            <AlertTitle>
                There is an error with your requisition line! Please see below
                for error message and contact the iBuy administrators for
                assistance if you are unable to resolve.
            </AlertTitle>
            {lineStatus || "Error message not available"}
        </>
    );
};

const RequisitionLineErrorMessage = ({
    headerStatus,
    reqLine,
    isCancelled,
    isCancellationPending = false,
}) => {
    const lineStatus = reqLine?.status;
    const lineStatusCode = reqLine?.statusCode;

    const displayErrorMessage = () => {
        const lineStatusCleansed = lineStatus || "";
        const isLineInvalid =
            lineStatusCode == INVALID_REQ_LINE_STATUS_CODE ||
            lineStatusCode == FAILED_REQ_LINE_STATUS_CODE;
        const isReverted = lineStatusCleansed.includes("REJECTED");
        const isHarmonyDown = lineStatusCleansed.includes(
            "Harmony unavailable"
        );
        return isReverted || isLineInvalid || isHarmonyDown || isCancelled;
    };

    return (
        <>
            {displayErrorMessage() && (
                <Grid item xs={12} display="flex">
                    <Alert
                        placement="top"
                        severity={headerStatus == CREATED ? "warning" : "error"}
                        variant="filled"
                        sx={{
                            width: "100%",
                        }}
                    >
                        {isCancelled ? (
                            <CancelAlert
                                reqLine={reqLine}
                                isCancellationPending={isCancellationPending}
                            />
                        ) : (
                            <ErrorAlert lineStatus={lineStatus} />
                        )}
                    </Alert>
                </Grid>
            )}
        </>
    );
};

RequisitionLineErrorMessage.defaultProps = {
    isCancellationPending: false,
};

RequisitionLineErrorMessage.propTypes = {
    headerStatus: PropTypes.string,
    reqLine: PropTypes.object,
    isCancelled: PropTypes.bool,
    isCancellationPending: PropTypes.bool,
};

export default RequisitionLineErrorMessage;
