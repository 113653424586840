import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import OutlinedChip from "components/displays/outlinedChip";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import GradientDivider from "styled/styledComponents/GradientDivider";
import { openUserPopper } from "utils/productListUtils";

import noImage from "../../assets/noImage.png";
import { IBUY_PRODUCT_CATEGORY_SHAREPOINT_URL } from "../../constants/generalConstants";
import { MOBILE_MEDIA_QUERY } from "../../constants/mediaQueries";
import axiosApiInstance from "../../services/middleware/axios";
import { getImg } from "../../services/search/searchAPIs";
import {
    PRODUCT_DESCRIPTION_LONG_GET,
    PRODUCT_DETAILS_GET,
} from "../../services/search/urls";
import alertDispatcher from "../../utils/alertsUtils";
import AddToCartButton from "../buttons/addToCartButton";
import FavouriteButton from "../buttons/favouriteButton";
import CurrencyFigure from "../displays/currencyFigure";
import IBuyPaper from "../displays/iBuyPaper";
import PageNotFound from "../pageNotFound/pageNotFound";
import ProductDetailsSkeleton from "../skeletons/productDetailsSkeleton";
import OnDesktop from "../wrappers/onDesktop";
import OnMobile from "../wrappers/onMobile";
import { contentBoxL2 } from "./../../styled/styledSXProperties/styledBoxSX";

const ProductDetails = () => {
    // Collect states and state dispatchers
    const activeOU = useSelector((state) => state.auth.activeOU);
    const userInfoLoading = useSelector(
        (state) => state.general.userInfoLoading
    );
    const { id } = useParams();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [product, setProduct] = useState({});

    const [imgUrl, setImgUrl] = useState(noImage);

    // Load image
    useEffect(() => {
        getImg(product.ITEM_IMAGE_URL, setImgUrl);
    }, [product]);

    useEffect(() => {
        // Search API must only be called once a user's actice OUs
        // have been retrieved and saved in redux
        if (!userInfoLoading) {
            setNotFound(false);
            // On page load, get the product data from the API
            axiosApiInstance
                .get(PRODUCT_DETAILS_GET(id, activeOU))
                .then((response) => {
                    const data = response.data.data;
                    if (data.length <= 0) {
                        const action = {
                            text: "Change Operating Unit",
                            action: (e) => openUserPopper(e, dispatch),
                        };
                        alertDispatcher(
                            "productDetails",
                            "404",
                            dispatch,
                            "",
                            action
                        );
                        setNotFound(true);
                        setIsLoading(false);
                    } else {
                        let result = data[0];
                        // Get the long description from seperate API
                        axiosApiInstance
                            .get(PRODUCT_DESCRIPTION_LONG_GET(result.ITEM_ID))
                            .then((response) => {
                                result.LONG_DESCRIPTION =
                                    response.data.LONG_DESCRIPTION;
                                setProduct(result);
                            })
                            .catch(() => {
                                result.LONG_DESCRIPTION =
                                    "Full description unavailable.";
                                setProduct(result);
                            });
                        setIsLoading(false);
                    }
                })
                .catch((e) => {
                    alertDispatcher(
                        "productDetails",
                        e?.response?.status,
                        dispatch
                    );
                    setNotFound(true);
                });
        }
    }, [activeOU]);

    // Go to category SharePoint page
    const goToCategoryTree = () => {
        window.open(IBUY_PRODUCT_CATEGORY_SHAREPOINT_URL, "_blank").focus();
    };

    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    return (
        <Box sx={{ contentBoxL2, marginTop: 2 }}>
            {notFound ? (
                <PageNotFound />
            ) : !isLoading ? (
                <IBuyPaper>
                    <Grid container spacing={4}>
                        <Grid
                            container
                            item
                            xs={12}
                            md={4}
                            lg={3}
                            direction="column"
                            justifyContent="center"
                            alignContent="center"
                        >
                            <Box
                                component="img"
                                aria-label={product?.ITEM_DESCRIPTION}
                                alt={product?.ITEM_DESCRIPTION}
                                src={imgUrl}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = noImage;
                                }}
                                sx={{
                                    borderRadius: "10px",
                                }}
                                width={isDesktop ? "100%" : "60%"}
                            />
                        </Grid>
                        <Grid container item xs={12} md={8} lg={9}>
                            <Grid container spacing={1}>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    spacing={isDesktop ? 2 : 0}
                                >
                                    <Grid item xs={10} justifyContent="center">
                                        <Typography
                                            variant={isDesktop ? "h4" : "h6"}
                                            fontWeight="bold"
                                            color="text.header"
                                            marginBottom={2}
                                            textAlign="left"
                                        >
                                            {product.ITEM_DESCRIPTION}
                                        </Typography>
                                        <OnDesktop>
                                            <Grid item xs={12}>
                                                <GradientDivider />
                                            </Grid>
                                        </OnDesktop>
                                    </Grid>
                                    <OnMobile>
                                        <Grid item xs={2}>
                                            <FavouriteButton
                                                product={product}
                                                white
                                            />
                                        </Grid>
                                    </OnMobile>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={3}
                                        lg={2}
                                        align="center"
                                        alignItems="center"
                                        flexDirection={
                                            isDesktop ? "column" : "row"
                                        }
                                    >
                                        <Typography
                                            variant={isDesktop ? "h6" : "h5"}
                                            color="text.primary"
                                            fontWeight="bold"
                                            mr={!isDesktop ? 2 : 0}
                                            my={0}
                                        >
                                            {"Unit Cost"}
                                        </Typography>
                                        <Typography
                                            color="text.subheader"
                                            variant={isDesktop ? "h2" : "h5"}
                                            fontWeight="bold"
                                        >
                                            <CurrencyFigure
                                                value={product?.UNIT_PRICE}
                                                code={product?.CURRENCY_CODE}
                                            />
                                        </Typography>
                                    </Grid>
                                    <OnMobile>
                                        <Grid item xs={12} paddingTop={1}>
                                            <GradientDivider />
                                        </Grid>
                                    </OnMobile>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid
                                        container
                                        item
                                        direction="column"
                                        xs={12}
                                        lg={3}
                                        mb={2}
                                        pt={0}
                                        alignContent={"left"}
                                    >
                                        <Typography
                                            variant="h5"
                                            color="text.subheader"
                                            marginBottom={1}
                                        >
                                            Product Detail
                                        </Typography>
                                        <Typography
                                            color="text.primary"
                                            variant="h6"
                                        >
                                            {"BPA No.: " + product?.BPA_NUMBER}
                                        </Typography>
                                        <Typography
                                            color="text.primary"
                                            variant="h6"
                                        >
                                            {"Item Number: " + product?.ITEM}
                                        </Typography>
                                        <Typography
                                            color="text.primary"
                                            variant="h6"
                                        >
                                            {"Unit of Measure: " +
                                                product?.UNIT_OF_MEASURE}
                                        </Typography>
                                        <Typography
                                            color="text.primary"
                                            variant="h6"
                                        >
                                            {"Lead Time (days): " +
                                                product?.LEAD_TIME_DAYS}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        direction="column"
                                        alignContent={
                                            isDesktop ? "center" : "left"
                                        }
                                        xs={12}
                                        lg={3}
                                    >
                                        <Typography
                                            variant="h5"
                                            color="text.subheader"
                                            marginBottom={1}
                                        >
                                            Supplier Detail
                                        </Typography>
                                        <Typography
                                            color="text.primary"
                                            variant="h6"
                                        >
                                            {"Name: " + product?.SUPPLIER_NAME}
                                        </Typography>
                                        <Typography
                                            color="text.primary"
                                            variant="h6"
                                        >
                                            {"Number: " +
                                                product?.SUPPLIER_NUMBER}
                                        </Typography>
                                        <Typography
                                            color="text.primary"
                                            variant="h6"
                                        >
                                            {"Site: " +
                                                product?.SUPPLIER_SITE_CODE}
                                        </Typography>
                                    </Grid>
                                    {isDesktop && <Grid item lg={6} />}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        color="text.primary"
                                        variant="h6"
                                    >
                                        {product?.LONG_DESCRIPTION}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    pb={2}
                                    lg={6}
                                    justifyItems="center"
                                    align={isDesktop ? "left" : "center"}
                                >
                                    <OutlinedChip
                                        value={product?.CATEGORY || ""}
                                        onClick={goToCategoryTree}
                                        clickable
                                    />
                                    <OutlinedChip
                                        value={product?.LINE_TYPE || ""}
                                        marginLeft
                                        onClick={goToCategoryTree}
                                        clickable
                                    />
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    mb={isDesktop ? 0 : 1}
                                >
                                    <Grid
                                        item
                                        xs={isDesktop ? 10 : 12}
                                        align={isDesktop ? "left" : "center"}
                                    >
                                        <AddToCartButton
                                            product={product}
                                            nonCat={false}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        align="center"
                                        justify="center"
                                    >
                                        <OnDesktop>
                                            <FavouriteButton
                                                product={product}
                                                white
                                            />
                                        </OnDesktop>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </IBuyPaper>
            ) : (
                <ProductDetailsSkeleton />
            )}
        </Box>
    );
};

export default ProductDetails;
