import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";

const ItemDescription = ({ reqLine }) => {
    return (
        <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ overflowWrap: "anywhere" }}
        >
            {reqLine?.ITEM_DESCRIPTION}
        </Typography>
    );
};

ItemDescription.propTypes = {
    reqLine: PropTypes.object,
};

export default ItemDescription;
