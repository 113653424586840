import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { REQUISITION_SUMMARY_PAGE } from "../../constants/routes";
import { primaryButtonSx } from "../../styled/styledSXProperties/styledButtonSX";

const RequisitionDetailsButton = ({ requisitionId }) => {
    const navigate = useNavigate();
    const darkMode = useSelector((state) => state.general.darkMode);
    const goToSummary = (id) => navigate(REQUISITION_SUMMARY_PAGE(id));

    return (
        <Button
            aria-label="approvalsGoToReqSummary"
            variant="contained"
            size="small"
            onClick={() => goToSummary(requisitionId)}
            sx={{
                minWidth: "100px",
                marginTop: "10px",
                fontWeight: "bold",
                ...primaryButtonSx(darkMode),
            }}
            endIcon={<KeyboardArrowRightIcon />}
        >
            Details
        </Button>
    );
};

export default RequisitionDetailsButton;
