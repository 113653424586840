import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import useIsMount from "hooks/useIsMount";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AUTOCOMPLETE_API_MIN_CHARS } from "../../../constants/generalConstants";
import useThrottle from "../../../hooks/useThrottle";
import { hrOrgAutocompleteGet } from "../../../services/requisition/requisitionAPIs";
import { freeTextFormProps } from "../../../styled/props/textFieldProps";
import {
    EXP_ORG_INPUT_LABEL,
    EXP_ORG_INPUT_PLACEHOLDER,
    MIN_3_CHARACTERS_TEXT,
    NO_OPTIONS_TEXT,
    START_NEW_SEARCH_TEXT,
} from "./labels";

const ExpOrgSearchAsYouType = ({ selectedExpOrg, onSelectExpOrg }) => {
    // Collect states and state dispatchers
    const isPostPoReq = useSelector(
        (state) => state.createRequisition.isPostPoReq
    );
    const reqOU = useSelector(
        (state) =>
            state.createRequisition.iBuyRequisition.requisitionHeaderForm
                .ORG_NAME
    );
    const dispatch = useDispatch();

    // Local states
    const [noOptionsText, setNoOptionsText] = useState(
        selectedExpOrg?.EXPENDITURE_ORGANIZATION_NAME
            ? START_NEW_SEARCH_TEXT
            : MIN_3_CHARACTERS_TEXT
    );
    const [expOrgLoading, setExpOrgLoading] = useState(false);
    const [expOrgOptions, setExpOrgOptions] = useState([]);
    const [expOrgSearchTerm, setExpOrgSearchTerm] = useState(
        selectedExpOrg?.EXPENDITURE_ORGANIZATION_NAME || ""
    );

    // Instantiate hooks required for search as you type
    const throttleFunction = useThrottle();
    const isFirstRender = useIsMount();

    useEffect(() => {
        // If no input, provided, or less than 3 characters input, API not called
        if (!isFirstRender) {
            throttleFunction(() => {
                if (
                    expOrgSearchTerm === "" ||
                    expOrgSearchTerm.length < AUTOCOMPLETE_API_MIN_CHARS
                ) {
                    setExpOrgOptions([]);
                    setNoOptionsText(MIN_3_CHARACTERS_TEXT);
                    return;
                }

                // Else, call hr organisation (expenditure orgs) API
                selectedExpOrg
                    ? setNoOptionsText(START_NEW_SEARCH_TEXT)
                    : setNoOptionsText(NO_OPTIONS_TEXT);

                hrOrgAutocompleteGet(
                    dispatch,
                    expOrgSearchTerm,
                    setExpOrgOptions,
                    selectedExpOrg,
                    setExpOrgLoading,
                    reqOU
                );
            });
        }
    }, [expOrgSearchTerm]);

    // Helper functions for the MUI autocomplete
    const onTypeSearchTerm = (_event, newInputValue) =>
        setExpOrgSearchTerm(newInputValue);

    const getOptionLabel = (option) => option.EXPENDITURE_ORGANIZATION_NAME;

    const onClickExpOrg = (e, value) => {
        /* Called when an expenditure organsiation  is selected by a user,
        writing the selection to the create requisition application state.

        Parameters
        ----------
        value : obj
            The obj returned from the `onChange` method on the autocomplete
            {"EXPENDITURE_ORGANIZATION_ID": 1001, "EXPENDITURE_ORGANIZATION_NAME": "Location Code"}
         */
        onSelectExpOrg(value);
    };
    const checkIfSelectedValueIsAnOption = (option, value) => {
        /* Called as part of autocomplete `isOptionEqualToValue` prop.

        Parameters
        ----------
        option : obj
            The structure of the autocomplete options.
        value : obj
            The obj returned from the `onChange` method on the
            autocomplete 

        Notes
        ----
        Both `option` and `value` are structured the same:
            {"EXPENDITURE_ORGANIZATION_ID": 1001, "EXPENDITURE_ORGANIZATION_NAME": "Location Code"}
         */
        return (
            option.EXPENDITURE_ORGANIZATION_ID ==
            value.EXPENDITURE_ORGANIZATION_ID
        );
    };
    return (
        <Autocomplete
            autoComplete
            disabled={isPostPoReq}
            loading={expOrgLoading}
            filterOptions={(x) => x}
            filterSelectedOptions
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={checkIfSelectedValueIsAnOption}
            noOptionsText={noOptionsText}
            options={expOrgOptions}
            onChange={onClickExpOrg}
            onInputChange={onTypeSearchTerm}
            value={selectedExpOrg}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...freeTextFormProps}
                    label={EXP_ORG_INPUT_LABEL}
                    placeholder={EXP_ORG_INPUT_PLACEHOLDER}
                />
            )}
        />
    );
};

ExpOrgSearchAsYouType.propTypes = {
    onSelectExpOrg: PropTypes.func,
    selectedExpOrg: PropTypes.shape({
        EXPENDITURE_ORGANIZATION_ID: PropTypes.string,
        EXPENDITURE_ORGANIZATION_NAME: PropTypes.string,
    }),
};

export default ExpOrgSearchAsYouType;
