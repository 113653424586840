import {
    INVOICE_HOLDS,
    INVOICE_LINES,
    INVOICE_PAYMENTS,
} from "constants/generalConstants";

/**
 * Stores the fields that are displayed and currency formatting for the
    invoice lines, payments and holds across desktop and mobile.
 * @param {string} contentType 
 * @param {object} invoiceData 
 * @param {boolean} mobile 
 * @returns object
 */
export const contentValues = (contentType, invoiceData, mobile = false) => {
    const mappings = {
        [INVOICE_LINES]: [
            {
                header: "Line Number",
                value: invoiceData?.LINE_NUMBER,
                currency: false,
                mobile: true,
            },
            {
                header: "Line Type",
                value: invoiceData?.LINE_TYPE_LOOKUP_CODE,
                currency: false,
                mobile: true,
            },
            {
                header: "Line Description",
                value: invoiceData?.DESCRIPTION,
                currency: false,
                mobile: true,
            },
            {
                header: "Item Description",
                value: invoiceData?.ITEM_DESCRIPTION ?? "-",
                currency: false,
                mobile: true,
            },
            {
                header: "Unit of Measure",
                value: invoiceData?.UNIT_MEAS_LOOKUP_CODE ?? "-",
                currency: false,
                mobile: true,
            },
            {
                header: "Quantity Invoiced",
                value: invoiceData?.QUANTITY_INVOICED ?? 0,
                currency: false,
                mobile: true,
            },
            {
                header: "Unit Price",
                value: invoiceData?.UNIT_PRICE ?? 0,
                currency: false,
                mobile: true,
            },
            {
                header: "Amount",
                value: invoiceData?.AMOUNT,
                currency: true,
                mobile: true,
            },
            {
                header: "Original Amount",
                value: invoiceData?.ORIGINAL_AMOUNT ?? 0,
                currency: true,
                mobile: true,
            },
        ],
        [INVOICE_PAYMENTS]: [
            {
                header: "Invoice Number",
                value: invoiceData?.INVOICE_NUM,
                currency: false,
                mobile: true,
            },
            {
                header: "Invoice Payment ID",
                value: invoiceData?.INVOICE_PAYMENT_ID,
                currency: false,
                mobile: true,
            },
            {
                header: "Remit to Supplier Name",
                value: invoiceData?.REMIT_TO_SUPPLIER_NAME,
                currency: false,
                mobile: true,
            },
            {
                header: "Payment Method",
                value: invoiceData?.PAYMENT_METHOD,
                currency: false,
                mobile: true,
            },
            {
                header: "Payment Reference",
                value: invoiceData?.PAYMENT_REFERENCE,
                currency: false,
                mobile: true,
            },
            {
                header: "Payment Date",
                value: invoiceData?.PAYMENT_DATE,
                currency: false,
                mobile: true,
            },
            {
                header: "Invoice Amount",
                value: invoiceData?.INV_AMOUNT,
                currency: true,
                mobile: true,
            },
            {
                header: "Check Amount",
                value: invoiceData?.CHECK_AMOUNT,
                currency: true,
                mobile: true,
            },
            {
                header: "Status",
                value: invoiceData?.PAYMENT_STATUS,
                currency: false,
                mobile: true,
            },
        ],
        [INVOICE_HOLDS]: [
            {
                header: "Hold ID",
                value: invoiceData?.HOLD_ID,
                currency: false,
                mobile: true,
            },
            {
                header: "Hold Code",
                value: invoiceData?.HOLD_LOOKUP_CODE,
                currency: false,
                mobile: true,
            },
            {
                header: "Hold Date",
                value: invoiceData?.HOLD_DATE,
                currency: false,
                mobile: true,
            },
            {
                header: "Hold Reason",
                value: invoiceData?.HOLD_REASON,
                currency: false,
                mobile: true,
            },
            {
                header: "Release Code",
                value: invoiceData?.RELEASE_LOOKUP_CODE,
                currency: false,
                mobile: true,
            },
            {
                header: "Release Reason",
                value: invoiceData?.RELEASE_REASON,
                currency: false,
                mobile: true,
            },
            {
                header: "Status",
                value: invoiceData?.STATUS_FLAG ?? "-",
                currency: false,
                mobile: true,
            },
        ],
    };

    try {
        if (mobile) {
            return mappings[contentType].filter((data) => data.mobile);
        } else {
            return mappings[contentType];
        }
    } catch {
        return [];
    }
};
