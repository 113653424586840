import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { singleReqSummaryLineNumberBox } from "styled/styledSXProperties/styledBoxSX";

const LineNumber = ({ index, isCancelled, isCancellationPending }) => {
    const darkMode = useSelector((state) => state.general.darkMode);
    return (
        <Box sx={singleReqSummaryLineNumberBox(isCancelled, darkMode)}>
            <Typography variant="body1" fontWeight="bold" color="white">
                Line {index + 1}
                {isCancelled &&
                    (isCancellationPending
                        ? " - Cancellation Pending"
                        : " - Cancelled")}
            </Typography>
        </Box>
    );
};

LineNumber.propTypes = {
    index: PropTypes.number.isRequired,
    isCancelled: PropTypes.bool.isRequired,
    isCancellationPending: PropTypes.bool.isRequired,
};

export default LineNumber;
