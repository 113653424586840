import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { DESKTOP, MOBILE } from "../../constants/generalConstants";
import {
    RECEIPT_NUMBER,
    searchBarQueryEngine,
    searchBarQueryOptions,
} from "../../utils/searchUtils";

const IBuySearch = ({ mode }) => {
    // Collect states and state dispatchers
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const iconSX = { color: "primary.contrastText" };

    // Local states
    const [searchInput, setSearchInput] = useState("");
    const [searchOption, setSearchOption] = useState(searchBarQueryOptions[0]);

    // If search query supplied in URL, prepopulate the search bar with its value
    useEffect(() => {
        const urlSearchQuery = searchParams.get("search_query");
        urlSearchQuery && setSearchInput(urlSearchQuery);
    }, []);

    const submitSearch = () => {
        const route = searchBarQueryEngine(
            searchOption,
            searchInput.trim(),
            dispatch
        );
        searchOption != RECEIPT_NUMBER && navigate(route);
    };

    const selectSearchOption = (event) => {
        setSearchOption(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            submitSearch();
        }
    };

    return (
        <>
            <>
                <FormControl size="small">
                    <Select
                        label="advanced-search-select"
                        onChange={selectSearchOption}
                        sx={{
                            minWidth: "150px",
                            mr: 1,
                            ml: 1,
                            color: "text.placeholder",
                        }}
                        value={searchOption}
                        variant="standard"
                    >
                        <MenuItem disabled value="">
                            <em>Search Category</em>
                        </MenuItem>
                        {searchBarQueryOptions.map((searchOption, index) => {
                            return (
                                <MenuItem key={index} value={searchOption}>
                                    {searchOption}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <Divider
                    orientation="vertical"
                    sx={{
                        height: 28,
                        m: 1,
                        bgcolor: "primary.contrastText",
                    }}
                />
            </>
            <InputBase
                inputProps={{ "aria-label": "search products" }}
                placeholder={`${
                    mode === DESKTOP ? "Start typing to search" : ""
                } ${
                    searchOption === "Product List" && mode === DESKTOP
                        ? "the"
                        : mode === DESKTOP
                        ? "by"
                        : ""
                } ${mode === DESKTOP ? searchOption : "Search"}...`}
                value={searchInput}
                onChange={(event) => setSearchInput(event.target.value)}
                onKeyDown={handleKeyDown}
                sx={{
                    ml: 1,
                    flex: 0.99,
                    color: "text.white",
                    fontSize: "15px",
                    borderBottom: "0.5px solid rgba(255, 255, 255, 1)",
                }}
            />
            <IconButton aria-label="searchLogo" onClick={submitSearch}>
                <SearchIcon sx={iconSX} />
            </IconButton>
        </>
    );
};

IBuySearch.propTypes = {
    mode: PropTypes.oneOf([MOBILE, DESKTOP]),
};

export default IBuySearch;
