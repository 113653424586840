import MuiPopper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";

const StyledPopper = styled(MuiPopper, {
    shouldForwardProp: (prop) => prop !== "arrow",
})(({ theme, arrow }) => ({
    zIndex: theme.zIndex.appBar + 1,
    "& > div": {
        position: "relative",
    },
    '&[data-popper-placement*="bottom"]': {
        "& > div": {
            marginTop: arrow ? 2 : 0,
        },
        "& .MuiPopper-arrow": {
            top: 0,
            left: 0,
            marginTop: "-0.9em",
            width: "3em",
            height: "1em",
            "&::before": {
                borderWidth: "0 1em 1em 1em",
                borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
            },
        },
    },
}));

export default StyledPopper;
