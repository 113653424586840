import {
    SET_CHANGE_REQ_OWNER_FORM_REQUISITION,
    SET_TO_OWNER,
} from "../actionTypes";
import { initialState } from "../initialState";

const changeRequisitionOwnerReducer = (
    state = initialState.changeRequisitionOwner,
    action
) => {
    switch (action.type) {
        case SET_CHANGE_REQ_OWNER_FORM_REQUISITION:
            return {
                ...state,
                requisition: action.payload,
            };
        case SET_TO_OWNER:
            return {
                ...state,
                toOwner: action.payload,
            };
        default:
            return state;
    }
};

export default changeRequisitionOwnerReducer;
