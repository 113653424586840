import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { NON_CATALOGUE_ITEM } from "../../../../constants/generalConstants";
import {
    deleteRequisitionLine,
    duplicateLine,
} from "../../../../redux/actions/createRequisitionActions";
import { arrayOnCondition } from "../../../../utils/general";

const EditLineButtons = ({
    index,
    isAmendedReq,
    isPostPoReq,
    reqLine,
    reqLines,
    openNonCatForm,
    setConfirmOpen,
    stopAccordionExpansion,
}) => {
    // Collect states and state dispatchers
    const dispatch = useDispatch();

    const copyLine = () => {
        dispatch(duplicateLine(index, isAmendedReq));
    };

    const deleteLine = (index) => {
        if (reqLines["length"] !== 1 && reqLines.length !== 1) {
            dispatch(deleteRequisitionLine(index));
        }

        if (reqLines["length"] === 1 && reqLines.length === 1) {
            setConfirmOpen(true);
        }
    };

    const buttons = [
        {
            tooltip: isPostPoReq
                ? "This feature is unavailable when making a post PO requisition amendment"
                : "Duplicate requisition line item",
            ariaLabel: "copyReqLine",
            style: { color: !isPostPoReq ? "#4CB6C2" : "" },
            onClick: copyLine,
            disabled: isPostPoReq,
            icon: ContentCopyIcon,
        },
        {
            tooltip: isPostPoReq
                ? "This feature is unavailable when making a post PO requisition amendment"
                : "Delete requisition line item",
            ariaLabel: "deleteReqLine",
            style: { color: !isPostPoReq ? "#4CB6C2" : "" },
            onClick: () => deleteLine(index),
            disabled: isPostPoReq,
            icon: DeleteIcon,
        },
        ...arrayOnCondition(reqLine.itemType === NON_CATALOGUE_ITEM, {
            tooltip: isPostPoReq
                ? "This feature is unavailable when making a post PO requisition amendment"
                : "Edit non catalogue requisition line item",
            ariaLabel: "editReqLine",
            style: { color: !isPostPoReq ? "#4CB6C2" : "" },
            onClick: () => openNonCatForm(reqLine, index),
            disabled: isPostPoReq,
            icon: EditIcon,
        }),
    ];

    return (
        <Stack onClick={stopAccordionExpansion} direction={"row"} spacing={1}>
            {buttons.map((button) => {
                const Icon = button.icon;
                return (
                    <Tooltip key={button.ariaLabel} title={button.tooltip}>
                        <span>
                            <IconButton
                                aria-label={button.ariaLabel}
                                variant="contained"
                                style={button.style}
                                onClick={button.onClick}
                                size="small"
                                disabled={button.disabled}
                            >
                                <Icon color="iconButton" />
                            </IconButton>
                        </span>
                    </Tooltip>
                );
            })}
        </Stack>
    );
};

EditLineButtons.propTypes = {
    index: PropTypes.number,
    isAmendedReq: PropTypes.bool,
    isPostPoReq: PropTypes.bool,
    reqLine: PropTypes.object,
    reqLines: PropTypes.array,
    openNonCatForm: PropTypes.func,
    setConfirmOpen: PropTypes.func,
    stopAccordionExpansion: PropTypes.func,
};

export default EditLineButtons;
