import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

import { checkedIcon, icon } from "./checkboxes";

const FilterPanelAutoComplete = ({
    options,
    onChange,
    checkBoxStyle,
    label,
    placeholder,
    disabled = false,
    value = null,
    displayValue = null,
    controlled = false,
    limit = null,
    equalityTest = null,
}) => {
    const valueProps = controlled ? { value } : {};
    let optionsFilter = {};
    if (limit) {
        // Filter options to improve performance of category selection
        const defaultFilterOptions = createFilterOptions();
        const filterOptions = (options, state) => {
            return defaultFilterOptions(options, state).slice(0, limit);
        };
        optionsFilter = { filterOptions };
    }
    let isOptionEqualToValue = {};
    if (equalityTest) {
        isOptionEqualToValue = { isOptionEqualToValue: equalityTest };
    }
    return (
        <Autocomplete
            disabled={disabled}
            multiple
            options={options}
            size="small"
            disableClearable
            disableCloseOnSelect
            limitTags={3}
            getOptionLabel={(option) =>
                displayValue ? option[displayValue] : option
            }
            onChange={onChange}
            {...isOptionEqualToValue}
            {...valueProps}
            {...optionsFilter}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        size="small"
                        style={checkBoxStyle}
                        checked={selected}
                        color="secondary"
                    />
                    <Typography variant="body1">
                        {displayValue ? option[displayValue] : option}
                    </Typography>
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label={label}
                    placeholder={placeholder}
                    size="small"
                    variant="outlined"
                    color="secondary"
                />
            )}
        />
    );
};

FilterPanelAutoComplete.propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func,
    checkBoxStyle: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    displayValue: PropTypes.string,
    controlled: PropTypes.bool,
    limit: PropTypes.string,
    equalityTest: PropTypes.func,
};

FilterPanelAutoComplete.defaultProps = {
    value: null,
    displayValue: null,
    controlled: false,
    limit: null,
    equalityTest: null,
    disabled: false,
};

export default FilterPanelAutoComplete;
