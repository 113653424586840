import BlockIcon from "@mui/icons-material/Block";
import PaymentIcon from "@mui/icons-material/Payment";
import ReceiptIcon from "@mui/icons-material/Receipt";
import {
    INVOICE_HOLDS,
    INVOICE_LINES,
    INVOICE_PAYMENTS,
} from "constants/generalConstants";
import PropTypes from "prop-types";

import TabbedPanels from "../tabbedPanels";
import InvoiceTabContent from "./invoiceTabsContent";

/**
 * Invoice Tabs for Invoice Lines/Holds/Payments.
 * @param {object} invoice
 * @returns Tab Panel and Content
 */
const InvoiceSummaryTabs = ({ invoice }) => {
    /* Invoice Tabs for Invoice Lines/Holds/Payments.

    Parameters
    ----------
    invoice : object    
    */
    return (
        <>
            <TabbedPanels
                title="Invoice summary tabs"
                tabs={[
                    {
                        name: "Lines",
                        icon: <ReceiptIcon />,
                        id: "invoiceLines",
                        content: (
                            <InvoiceTabContent
                                invoiceData={invoice?.INVOICE_LINES}
                                currencyCode={invoice?.INVOICE_CURRENCY_CODE}
                                contentType={INVOICE_LINES}
                                key={0}
                            />
                        ),
                    },
                    {
                        name: "Payments",
                        icon: <PaymentIcon />,
                        id: "invoicePayments",
                        content: (
                            <InvoiceTabContent
                                invoiceData={invoice?.INVOICE_PAYMENTS}
                                currencyCode={invoice?.INVOICE_CURRENCY_CODE}
                                contentType={INVOICE_PAYMENTS}
                                key={1}
                            />
                        ),
                    }, // TODO - 569779 - This tab panel was set as disabled
                    {
                        name: "Holds",
                        icon: <BlockIcon />,
                        id: "invoiceHolds",
                        content: (
                            <InvoiceTabContent
                                invoiceData={invoice?.INVOICE_HOLDS}
                                currencyCode={invoice?.INVOICE_CURRENCY_CODE}
                                contentType={INVOICE_HOLDS}
                                key={2}
                            />
                        ),
                    },
                ]}
            />
        </>
    );
};

InvoiceSummaryTabs.propTypes = {
    invoice: PropTypes.object,
};

export default InvoiceSummaryTabs;
