import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BuildIcon from "@mui/icons-material/Build";
import BusinessIcon from "@mui/icons-material/Business";
import ComputerRoundedIcon from "@mui/icons-material/ComputerRounded";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import EngineeringIcon from "@mui/icons-material/Engineering";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Shop2Icon from "@mui/icons-material/Shop2";
import StorefrontIcon from "@mui/icons-material/Storefront";
import WindPowerIcon from "@mui/icons-material/WindPower";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { SEARCH_API_DELIMITER } from "../../constants/generalConstants";
import { homepageSmartFormBoxSX } from "../../styled/styledSXProperties/styledBoxSX";
import {
    homepageSmartFormCardMobileSX,
    homepageSmartFormCardSX,
} from "../../styled/styledSXProperties/styledCardSX";
import { PRODUCT_RESULTS } from "./../../constants/routes";

const iconSX = { height: "40px", width: "40px", color: "#FFFFFF" };
const ictIcon = () => {
    return <ComputerRoundedIcon sx={iconSX} />;
};

const marketingIcon = () => {
    return <StorefrontIcon sx={iconSX} />;
};

const repairIcon = () => {
    return <BuildIcon sx={iconSX} />;
};

const hrIcon = () => {
    return <PeopleAltIcon sx={iconSX} />;
};

const engineeringIcon = () => {
    return <EngineeringIcon sx={iconSX} />;
};

const serviceIcon = () => {
    return <ArchitectureIcon sx={iconSX} />;
};

const facilityIcon = () => {
    return <BusinessIcon sx={iconSX} />;
};

const feeIcon = () => {
    return <AccountBalanceIcon sx={iconSX} />;
};

const financeIcon = () => {
    return <AccountBalanceWalletIcon sx={iconSX} />;
};

const energyIcon = () => {
    return <WindPowerIcon sx={iconSX} />;
};

const corporateIcon = () => {
    return <CorporateFareIcon sx={iconSX} />;
};

const defaultIcon = () => {
    return <Shop2Icon sx={iconSX} />;
};

const homepageIconDispatcher = {
    computer: ictIcon,
    corporate: corporateIcon,
    it: ictIcon,
    market: marketingIcon,
    repair: repairIcon,
    hr: hrIcon,
    material: engineeringIcon,
    engine: engineeringIcon,
    service: serviceIcon,
    facility: facilityIcon,
    fee: feeIcon,
    finance: financeIcon,
    energy: energyIcon,
    default: defaultIcon,
};

const HomepageCard = ({ name, categoriesIds, team, isDesktop }) => {
    // Collect states and state dispatchers
    const darkMode = useSelector((state) => state.general.darkMode);
    // Local states
    const [searchQuery, setSearchQuery] = useState("");

    const selectIcon = () => {
        const matchedKey = Object.keys(homepageIconDispatcher).find((key) =>
            team.toLowerCase().includes(key)
        );
        return matchedKey
            ? homepageIconDispatcher[matchedKey]
            : homepageIconDispatcher["default"];
    };

    useEffect(() => {
        const searchParams = new URLSearchParams();
        categoriesIds
            ? searchParams.set(
                  "category",
                  categoriesIds.join(SEARCH_API_DELIMITER)
              )
            : searchParams.delete("category");
        setSearchQuery("?" + searchParams);
    }, [categoriesIds]);

    return (
        <Link
            to={PRODUCT_RESULTS + searchQuery}
            style={{ textDecoration: "none" }}
        >
            <Card
                sx={
                    isDesktop
                        ? homepageSmartFormCardSX(darkMode)
                        : homepageSmartFormCardMobileSX(darkMode)
                }
            >
                <Grid container spacing={1}>
                    <Grid item xs={7}>
                        <Box sx={homepageSmartFormBoxSX(darkMode)}>
                            {selectIcon()()}
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography
                            variant="body2"
                            fontWeight="normal"
                            align="right"
                            color="text.secondary"
                        >
                            PSG Codes
                        </Typography>
                        <Typography
                            variant="h3"
                            fontWeight="bold"
                            align="right"
                        >
                            {categoriesIds ? categoriesIds.length : 0}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" fontWeight="light">
                            {name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            size="small"
                            color="secondary"
                            endIcon={<ArrowForwardIcon />}
                        >
                            Go to Catalogue{" "}
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </Link>
    );
};

HomepageCard.propTypes = {
    name: PropTypes.string,
    image: PropTypes.string,
};

export default HomepageCard;
