import { Skeleton, Typography } from "@mui/material";
import React from "react";
import { DetailType } from "types/propTypes";

const BlueNameValue = ({
    detail: { header, content, hideHeader = false, isLoading = false },
}) => {
    return (
        <>
            {!hideHeader && (
                <Typography
                    variant="body2"
                    color="text.subheader"
                    align="center"
                >
                    {header}
                </Typography>
            )}
            {isLoading ? (
                <Skeleton variant="text" />
            ) : (
                <Typography variant="body2" align="center">
                    {content}
                </Typography>
            )}
        </>
    );
};

BlueNameValue.propTypes = { detail: DetailType };

export default BlueNameValue;
