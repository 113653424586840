import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetails, Stack } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    accordionSummarySX,
    accordionSX,
} from "styled/styledSXProperties/styledAccordionSX";
import { isReqLineCancelled } from "utils/cancelRequisitionUtils";

import noImage from "../../../../assets/noImage.png";
import {
    PENDING,
    PO_APPROVED,
    PO_IN_PROCESS,
} from "../../../../constants/generalConstants";
import { setReqLineLoadingState } from "../../../../redux/actions/singleReqSummaryActions";
import { getInvoicesPOsReceiptsPerReqLine } from "../../../../services/procurement/procurementAPIs";
import { getImg } from "../../../../services/search/searchAPIs";
import RequisitionLineErrorMessage from "../../../alerts/errors/requisitionLineErrorMessage";
import OnDesktop from "../../../wrappers/onDesktop";
import OnMobile from "../../../wrappers/onMobile";
import Chips from "./accordionSummary/chips";
import DeliveryInformation from "./accordionSummary/deliveryInformation";
import ItemDescription from "./accordionSummary/itemDescription";
import ItemDetails from "./accordionSummary/itemDetails";
import LineNumber from "./accordionSummary/lineNumber";
import OrderDetails from "./accordionSummary/orderDetails";
import ProductImage from "./accordionSummary/productImage";
import Total from "./accordionSummary/total";
import RequisitionAccordionLinesExpanded from "./requisitionAccordionLinesExpanded";

const RequisitionLinesAccordion = ({
    reqLine,
    index,
    stagedLine,
    reqId,
    headerStatus,
}) => {
    // Load states and dispatchers
    const darkMode = useSelector((state) => state.general.darkMode);
    const dispatch = useDispatch();
    // Local states
    const [isPOInvoiceLoading, setIsPOInvoiceLoading] = useState(true);
    const [imgUrl, setImgUrl] = useState(noImage);
    const [invoiceIds, setInvoiceIds] = useState([]);
    const [poIds, setPoIds] = useState([]);
    const reqLineId = reqLine?.LINE_ATTRIBUTE5;

    const isCancelled = isReqLineCancelled(reqLine);
    const isCancellationPending = reqLine?.CANCELLATION_FLAG == PENDING;

    useEffect(() => {
        getImg(reqLine.ITEM_IMAGE_URL, setImgUrl);
    }, [reqLine.ITEM_IMAGE_URL]);

    useEffect(() => {
        dispatch(setReqLineLoadingState(reqLineId, true));
        getInvoicesPOsReceiptsPerReqLine(
            reqLineId,
            reqId,
            setInvoiceIds,
            setIsPOInvoiceLoading,
            setPoIds,
            null,
            true,
            dispatch
        );
    }, [reqLineId]);

    const hasApprovedPo =
        poIds.filter((data) => {
            return (
                data?.AUTHORIZATION_STATUS === PO_APPROVED ||
                data?.AUTHORIZATION_STATUS === PO_IN_PROCESS
            );
        }).length > 0;

    return (
        <Accordion sx={{ ...accordionSX, opacity: isCancelled ? 0.5 : 1 }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="req-line-item-accordion-controls"
                id="req-line-item-accordion"
                sx={accordionSummarySX(darkMode)}
            >
                <OnDesktop>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={10}
                            display="flex"
                            sx={{ mb: "2px" }}
                            flexWrap="wrap"
                        >
                            <Stack direction={"row"} spacing={1}>
                                <LineNumber
                                    index={index}
                                    isCancelled={isCancelled}
                                    isCancellationPending={
                                        isCancellationPending
                                    }
                                />
                                <Chips
                                    reqLine={reqLine}
                                    isPOInvoiceLoading={isPOInvoiceLoading}
                                    poIds={poIds}
                                    invoiceIds={invoiceIds}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={2} container justifyContent="flex-end">
                            <Total reqLine={reqLine} />
                        </Grid>
                        <RequisitionLineErrorMessage
                            headerStatus={headerStatus}
                            reqLine={reqLine}
                            lineStatus={reqLine?.status}
                            lineStatusCode={reqLine?.statusCode}
                            isCancelled={isCancelled}
                            isCancellationPending={isCancellationPending}
                        />
                        <Grid container item sm={12} md={12} xl={12}>
                            <Grid container item sm={12} md={2} xl={1.5}>
                                <Grid
                                    item
                                    sm={12}
                                    md={12}
                                    xl={10}
                                    sx={{ mr: 2 }}
                                >
                                    <ProductImage
                                        reqLine={reqLine}
                                        imgUrl={imgUrl}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                item
                                sm={12}
                                md={9}
                                xl={10.5}
                                spacing={2}
                            >
                                <Grid item sm={6} md={6} xl={3}>
                                    <ItemDescription reqLine={reqLine} />
                                </Grid>
                                <Grid item sm={6} md={6} xl={3}>
                                    <ItemDetails reqLine={reqLine} />
                                </Grid>
                                <Grid item sm={6} md={6} xl={2}>
                                    <OrderDetails reqLine={reqLine} />
                                </Grid>
                                <Grid item sm={6} md={6} xl={4}>
                                    <DeliveryInformation reqLine={reqLine} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </OnDesktop>
                <OnMobile>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent="space-between"
                        >
                            <LineNumber
                                index={index}
                                isCancelled={isCancelled}
                                isCancellationPending={isCancellationPending}
                            />
                            <Total reqLine={reqLine} />
                        </Grid>

                        <RequisitionLineErrorMessage
                            headerStatus={headerStatus}
                            reqLine={reqLine}
                            lineStatus={reqLine?.status}
                            lineStatusCode={reqLine?.statusCode}
                            isCancelled={isCancelled}
                            isCancellationPending={isCancellationPending}
                        />

                        <Grid item xs={4}>
                            <ProductImage reqLine={reqLine} imgUrl={imgUrl} />
                        </Grid>
                        <Grid item xs={8}>
                            <ItemDescription reqLine={reqLine} />
                        </Grid>

                        <Grid item xs={12}>
                            <OrderDetails reqLine={reqLine} />
                        </Grid>

                        <Grid item container xs={12}>
                            <Chips
                                reqLine={reqLine}
                                isPOInvoiceLoading={isPOInvoiceLoading}
                                poIds={poIds}
                                invoiceIds={invoiceIds}
                            />
                        </Grid>
                    </Grid>
                </OnMobile>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1}>
                    <OnMobile>
                        <Grid item xs={12}>
                            <ItemDetails reqLine={reqLine} />
                        </Grid>
                        <Grid item xs={12}>
                            <DeliveryInformation reqLine={reqLine} />
                        </Grid>
                    </OnMobile>
                    <Grid item xs={12}>
                        <RequisitionAccordionLinesExpanded
                            reqLine={reqLine}
                            stagedLine={stagedLine}
                            hasApprovedPo={hasApprovedPo}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

RequisitionLinesAccordion.propTypes = {
    headerStatus: PropTypes.string,
    reqLine: PropTypes.object,
    index: PropTypes.number,
    stagedLine: PropTypes.object,
    reqId: PropTypes.string,
};

export default RequisitionLinesAccordion;
