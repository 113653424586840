import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";

import { UNKNOWN_STATUS } from "../../../constants/generalConstants";
import { BADGE_DISPATCHER } from "./badgeDispatcher";

const RequisitionStatusBadge = ({ status, sx = null, customTooltip = "" }) => {
    const tooltip = customTooltip
        ? customTooltip
        : BADGE_DISPATCHER[status]?.tooltip || "";
    return (
        <Tooltip title={tooltip} placement="top" disableInteractive>
            <Chip
                color={
                    BADGE_DISPATCHER[status]?.color ||
                    BADGE_DISPATCHER[UNKNOWN_STATUS]?.color
                }
                icon={
                    BADGE_DISPATCHER[status]?.icon ||
                    BADGE_DISPATCHER[UNKNOWN_STATUS]?.icon
                }
                label={BADGE_DISPATCHER[status]?.label || status}
                size="small"
                sx={sx}
            />
        </Tooltip>
    );
};

RequisitionStatusBadge.defaultProps = {
    sx: null,
    customTooltip: "",
};
RequisitionStatusBadge.propTypes = {
    status: PropTypes.string,
    customTooltip: PropTypes.string,
};

export default RequisitionStatusBadge;
