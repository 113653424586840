import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import BlueNameValue from "components/displays/blueNameValue";
import IBuyPaper from "components/displays/iBuyPaper";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { decimalNumberRegex } from "../../../../constants/patterns";
import {
    setReceiptLineCheckReturn,
    setReturnQuantity,
} from "../../../../redux/actions/receiptSummaryActions";
import { freeTextFormProps } from "../../../../styled/props/textFieldProps";

const ReceiptReturnLine = ({
    checkedReturn,
    returnQuantity,
    lineIndex,
    rcvLine,
    errors,
    register,
    byAmount,
}) => {
    // Collect states and state dispatchers
    const supplierName = useSelector(
        (state) => state.receiptSummary.receipt?.SUPPLIER_NAME
    );

    const dispatch = useDispatch();

    const availableForReturn =
        rcvLine?.AVAILABLE_FOR_RETURN && rcvLine?.QTY_RECEIVED_GTR_ZERO;

    const paperProperties = () =>
        availableForReturn
            ? {}
            : {
                  pointerEvents: "none",
                  opacity: 0.5,
              };

    const onCheck = (event) =>
        dispatch(setReceiptLineCheckReturn(lineIndex, event.target.checked));

    // Register text input for form validation (applied conditionally based on if the line is checked)
    const registerName = "receiptReturn" + lineIndex;
    const { ref, ...correctionProps } = register(registerName, {
        onChange: (e) => dispatch(setReturnQuantity(lineIndex, e.target.value)),
        required: checkedReturn && availableForReturn ? "Required Field" : null,
        max:
            checkedReturn && availableForReturn && rcvLine?.QUANTITY_RECEIVED
                ? {
                      value: rcvLine?.QUANTITY_RECEIVED,
                      message: `Max permitted ${
                          byAmount ? "amount" : "quantity"
                      } exceeded (${rcvLine?.QUANTITY_RECEIVED})`,
                  }
                : null,
        validate: (value) => {
            if (checkedReturn && availableForReturn && !isNaN(value)) {
                if (parseFloat(value) <= 0) {
                    return "Return quantity must be greater than 0";
                }
            }
            return null;
        },
        pattern:
            checkedReturn && availableForReturn
                ? {
                      value: decimalNumberRegex,
                      message: "Invalid quantity input",
                  }
                : null,
    });

    const disabledLineTooltip = () => {
        switch (true) {
            case !rcvLine?.QTY_RECEIVED_GTR_ZERO:
                return `This receipt line is unavailable for return, this is because its receipted ${
                    byAmount ? "amount" : "quantity"
                } is 0.`;
            case !rcvLine?.AVAILABLE_FOR_RETURN:
                return "This receipt line is unavailable for returns as its associated PO shipment is currently being receipted / corrected by another user - please try again in a few minutes";
            default:
                return "This receipt line is currently unavailable for returns";
        }
    };

    return (
        <Tooltip
            placement="top-start"
            title={availableForReturn ? "" : disabledLineTooltip()}
        >
            <span>
                <IBuyPaper additionalSx={paperProperties()}>
                    <Grid container spacing={2}>
                        <Grid
                            container
                            item
                            md={0.5}
                            xs={2}
                            display="flex"
                            alignItems="center"
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={!availableForReturn}
                                            checked={checkedReturn}
                                            onChange={onCheck}
                                            color="secondary"
                                        />
                                    }
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item container xs={10} spacing={2}>
                            <Grid item xs={6} md={4}>
                                <BlueNameValue
                                    detail={{
                                        header: "Item Description",
                                        content: rcvLine?.ITEM_DESCRIPTION,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <BlueNameValue
                                    detail={{
                                        header: "Supplier Name",
                                        content: supplierName,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <BlueNameValue
                                    detail={{
                                        header: byAmount
                                            ? "Amount Received"
                                            : "Quantity Received",
                                        content: rcvLine?.QUANTITY_RECEIVED,
                                    }}
                                />
                            </Grid>
                            {!byAmount && (
                                <Grid item xs={6} md={2}>
                                    <BlueNameValue
                                        detail={{
                                            header: "Unit of Measure",
                                            content:
                                                rcvLine?.UNIT_OF_MEASURE_DESCRIPTION,
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>

                        <Grid
                            item
                            md={1.5}
                            container
                            display="flex"
                            alignItems="center"
                        >
                            <TextField
                                {...freeTextFormProps}
                                disabled={!availableForReturn}
                                variant="outlined"
                                required
                                value={returnQuantity}
                                label={
                                    byAmount
                                        ? "Return Amount"
                                        : "Return Quantity"
                                }
                                placeholder={`Return ${
                                    byAmount ? "amount" : "quantity"
                                }...`}
                                inputRef={ref}
                                {...correctionProps}
                                error={
                                    checkedReturn
                                        ? !!errors[registerName]
                                        : false
                                }
                                helperText={errors[registerName]?.message}
                            />
                        </Grid>
                    </Grid>
                </IBuyPaper>
            </span>
        </Tooltip>
    );
};

ReceiptReturnLine.propTypes = {
    checkedReturn: PropTypes.bool,
    returnQuantity: PropTypes.string,
    lineIndex: PropTypes.number,
    rcvLine: PropTypes.object,
    errors: PropTypes.object,
    register: PropTypes.func,
    byAmount: PropTypes.bool,
};

export default ReceiptReturnLine;
