import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
    CATALOGUE_ITEM,
    SEARCH_API_DELIMITER,
    SMART_FORM,
    SMARTFORMS_MODE,
} from "constants/generalConstants";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GradientDivider from "styled/styledComponents/GradientDivider";
import { onCheckSmartForms } from "utils/productListUtils";

import FilterPanelAutoComplete from "../filterPanelAutocomplete";
import FilterPanel from "./filterPanel";
import SmartFormsFilterPanel from "./smartFormsFilterPanel";

/**
 * React component for deciding which product list
 * filter panel to render: smartforms filter panel
 * vs product list filter panel. It also houses
 * filter elements reused across both filter panels
 * @component
 */

export const checkBoxStyle = { marginRight: 8, padding: "4px 0px" };

const ProductsFilterPanelDispatcher = ({
    isSmartForms,
    searchParams,
    setSearchParams,
}) => {
    // Collect states and dispatchers
    // Temporarily remove GEN/GENIRE categories, which are not required for MVP
    const categories = useSelector((state) =>
        state.filterPanelLookups.CATEGORY_DISPLAY.filter(
            (cat) => cat.CATEGORY_DISPLAY_NAME[0] != "G"
        )
    );
    const filterLookups = useSelector((state) => state.filterPanelLookups);
    const category = useSelector((state) => state.filterPanelValues.category);
    const suppliers = useSelector((state) => state.filterPanelValues.suppliers);

    const [readSearchBarQuery, setReadSearchBarQuery] = useState();
    const [readItemNumberQuery, setReadItemNumberQuery] = useState();
    const [readSupplierNumberQuery, setReadSupplierNumberQuery] = useState();
    // If search query supplied in URL, prepopulate the search bar with its value
    useEffect(() => {
        setReadSearchBarQuery(searchParams.get("search_query"));
        setReadItemNumberQuery(searchParams.get("item_number"));
        setReadSupplierNumberQuery(searchParams.get("supplier_number"));
    }, [searchParams]);

    const clearSearch = () => {
        const newParams = new URLSearchParams();
        isSmartForms && newParams.set("smartforms", SMARTFORMS_MODE);
        setSearchParams(newParams);
    };

    /**
     * Clears a specified param from product or smartform search query
     * @param {string} param - the search param to be deleted
     * @param {URLSearchParams} searchParams - url search params instantiated in `ProductResults`
     * @param {Function} setSearchParams - search params refresh from `useSearchParams` hook (react router)
     */
    const clearSearchBarQuery = (param, searchParams, setSearchParams) => {
        searchParams.delete(param);
        setSearchParams(searchParams);
    };

    /**
     * Called when a user updates supplier selection in products or smartforms filter panel
     * @param {object[]} value - suppliers selected by user in filter panel
     * @param {URLSearchParams} searchParams - url search params instantiated in `ProductResults`
     * @param {Function} setSearchParams - search params refresh from `useSearchParams` hook (react router)
     */
    const onSupplierSelect = (value, searchParams, setSearchParams) => {
        value.length > 0
            ? searchParams.set(
                  "supplier_name",
                  value.join(SEARCH_API_DELIMITER)
              )
            : searchParams.delete("supplier_name");
        setSearchParams(searchParams);
    };

    /**
     * Called when a user updates category selection in products or smartforms filter panel
     * @param {object[]} value - categories selected by user in filter panel
     * @param {URLSearchParams} searchParams - url search params instantiated in `ProductResults`
     * @param {Function} setSearchParams - search params refresh from `useSearchParams` hook (react router)
     */
    const onCategorySelect = (value, searchParams, setSearchParams) => {
        if (value.length > 0) {
            const purchasingCategoryIds = value.reduce(
                (acc, cat) => [...acc, cat?.CATEGORY],
                []
            );
            searchParams.set(
                "purchasing_category",
                purchasingCategoryIds.join(SEARCH_API_DELIMITER)
            );
            const categoryIds = value.reduce(
                (acc, cat) => [...acc, cat?.CATEGORY_ID],
                []
            );
            searchParams.set(
                "category",
                categoryIds.join(SEARCH_API_DELIMITER)
            );
        } else {
            searchParams.delete("category");
            searchParams.delete("purchasing_category");
        }
        setSearchParams(searchParams);
    };

    const onToggleSmartForms = (_e, value) =>
        onCheckSmartForms(value === SMART_FORM, searchParams, setSearchParams);

    const filterPanelProps = { searchParams, setSearchParams };
    return (
        <Grid container item spacing={4}>
            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <div></div>
                    <Typography variant="h4" align="center" fontWeight="normal">
                        Filters
                    </Typography>
                    <Tooltip title="Clear search query and filters">
                        <IconButton
                            onClick={clearSearch}
                            aria-label="clearFilters"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                {readSearchBarQuery && (
                    <Tooltip
                        arrow
                        placement="top-start"
                        title="Search query entered into search bar"
                    >
                        <Chip
                            label={readSearchBarQuery}
                            variant="outlined"
                            onDelete={() =>
                                clearSearchBarQuery(
                                    "search_query",
                                    searchParams,
                                    setSearchParams
                                )
                            }
                            sx={{ m: "4px 0px" }}
                        />
                    </Tooltip>
                )}
                {readItemNumberQuery && isSmartForms && (
                    <Tooltip
                        arrow
                        placement="top-start"
                        title="Item number query entered into search bar"
                    >
                        <Chip
                            label={"Item Number: " + readItemNumberQuery}
                            variant="outlined"
                            onDelete={() =>
                                clearSearchBarQuery(
                                    "item_number",
                                    searchParams,
                                    setSearchParams
                                )
                            }
                            sx={{ m: "4px 0px" }}
                        />
                    </Tooltip>
                )}
                {readSupplierNumberQuery && isSmartForms && (
                    <Tooltip
                        arrow
                        placement="top-start"
                        title="Supplier number query entered into search bar"
                    >
                        <Chip
                            label={
                                "Supplier Number: " + readSupplierNumberQuery
                            }
                            variant="outlined"
                            onDelete={() =>
                                clearSearchBarQuery("supplier_number")
                            }
                            sx={{ m: "4px 0px" }}
                        />
                    </Tooltip>
                )}
                <GradientDivider />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
                <Tooltip
                    title="Smart-forms will be available in a future release of iBuy"
                    disableInteractive
                    placement="top"
                >
                    <ToggleButtonGroup
                        color="secondary"
                        size="small"
                        value={isSmartForms ? SMART_FORM : CATALOGUE_ITEM}
                        exclusive
                        onChange={onToggleSmartForms}
                        aria-label="smart-forms-toggle"
                        fullWidth
                        disabled
                    >
                        <ToggleButton value={CATALOGUE_ITEM}>
                            Products
                        </ToggleButton>
                        <ToggleButton value={SMART_FORM}>
                            Smart Forms
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                <FilterPanelAutoComplete
                    options={categories}
                    onChange={(_e, v) =>
                        onCategorySelect(v, searchParams, setSearchParams)
                    }
                    checkBoxStyle={checkBoxStyle}
                    placeholder="Type to Search Categories..."
                    label="Category"
                    value={category}
                    controlled={true}
                    displayValue="CATEGORY_DISPLAY_NAME"
                    limit="50"
                    equalityTest={(option, value) =>
                        option.CATEGORY_ID == value.CATEGORY_ID
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <FilterPanelAutoComplete
                    options={filterLookups.SUPPLIER_NAME}
                    onChange={(_e, v) =>
                        onSupplierSelect(v, searchParams, setSearchParams)
                    }
                    checkBoxStyle={checkBoxStyle}
                    placeholder="Select Supplier..."
                    label="Supplier"
                    value={suppliers}
                    controlled={true}
                />
            </Grid>
            {isSmartForms ? (
                <SmartFormsFilterPanel {...filterPanelProps} />
            ) : (
                <FilterPanel {...filterPanelProps} />
            )}
        </Grid>
    );
};

ProductsFilterPanelDispatcher.propTypes = {
    isSmartForms: PropTypes.bool,
    searchParams: PropTypes.object,
    setSearchParams: PropTypes.func,
};

export default ProductsFilterPanelDispatcher;
