import SendIcon from "@mui/icons-material/Send";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import parseISO from "date-fns/parseISO";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { MAX_DELEGATIONS } from "../../../constants/generalConstants";
import { SERVER_MESSAGE_APPROVAL_PROXY_CREATED } from "../../../constants/snackbarMessage";
import useThrottle from "../../../hooks/useThrottle";
import useUser from "../../../services/apiUtils/hooks/useUser";
import axiosApiInstance from "../../../services/middleware/axios";
import { USER_PROXY_RULE } from "../../../services/user/urls";
import alertDispatcher from "../../../utils/alertsUtils";
import GenericModalContainer from "../../modals/containers/genericModalContainer";

function dateIsValid(date) {
    return date instanceof Date && !isNaN(date);
}

function endDateIsValid(date) {
    return date instanceof Date || date == null;
}

/**
 * Removes the timezone without converting the time.
 * Essentially prevents a user selecting a day and
 *  the system converting to the day before due to BST.
 * @param {date} Date
 * @returns Date
 */
function removeTimeZone(date) {
    return new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
}

const CreateApprovalProxy = ({ closeModal, isOpen, selectedProxyRule }) => {
    const dispatch = useDispatch();
    const throttle = useThrottle();
    const { getUserAutocomplete } = useUser();
    const [startDate, setStartDate] = useState(
        selectedProxyRule ? parseISO(selectedProxyRule?.START_DATE) : null
    );
    const [endDate, setEndDate] = useState(
        selectedProxyRule && selectedProxyRule?.END_DATE != null
            ? parseISO(selectedProxyRule?.END_DATE)
            : null
    );
    const [delegateUsers, setDelegateUsers] = useState(
        selectedProxyRule ? [selectedProxyRule?.USER_FULL_NAME] : []
    );
    const [userOptions, setUserOptions] = useState([]);
    const [autocompleteIsLoading, setAutocompleteIsLoading] = useState(false);
    const [userAutocompleteQuery, setUserAutocompleteQuery] = useState("");
    const [receiveEmails] = useState(true);

    useEffect(() => {
        if (userAutocompleteQuery && !selectedProxyRule) {
            setAutocompleteIsLoading(true);
            throttle(() => {
                getUserAutocomplete(userAutocompleteQuery).then((data) => {
                    setUserOptions(data);
                    setAutocompleteIsLoading(false);
                });
            });
        } else {
            setAutocompleteIsLoading(false);
        }
    }, [userAutocompleteQuery, selectedProxyRule]);

    const canSubmit =
        dateIsValid(startDate) &&
        endDateIsValid(endDate) &&
        (!!delegateUsers[0] || selectedProxyRule) &&
        (startDate <= endDate || endDate == null);

    const resetForm = () => {
        setStartDate(null);
        setEndDate(null);
        setDelegateUsers([""]);
    };

    const submitForm = () => {
        endDate &&
            setEndDate((prevDate) => new Date(prevDate.setHours(23, 59, 59)));

        const formattedStartDate = removeTimeZone(startDate);
        const payload = {
            USERS: selectedProxyRule
                ? [selectedProxyRule.USER_ID]
                : delegateUsers,
            START_DATE: formattedStartDate,
            END_DATE: endDate,
            EMAIL_NOTIFICATIONS: receiveEmails,
        };

        const axiosAPICall = selectedProxyRule
            ? axiosApiInstance.patch(USER_PROXY_RULE, payload)
            : axiosApiInstance.post(USER_PROXY_RULE, payload);

        axiosAPICall
            .then((response) => {
                alertDispatcher(
                    "proxyRulesCreated",
                    response?.status,
                    dispatch,
                    `Delegation rule ${
                        selectedProxyRule ? "amended" : "created"
                    }!`
                );
                resetForm();
                closeModal();
            })
            .catch((error) => {
                alertDispatcher(
                    "proxyRulesCreated",
                    error?.response?.status,
                    dispatch,
                    SERVER_MESSAGE_APPROVAL_PROXY_CREATED(
                        error?.response?.data?.detail
                    )
                );
            });
    };

    return (
        <GenericModalContainer
            title={
                selectedProxyRule
                    ? "Edit Existing Delegation Rule"
                    : "Create New Delegation Rule"
            }
            closeModal={closeModal}
            isOpen={isOpen}
            subHeader={
                "Please complete the following information about the new delegation rule"
            }
            styles={{ minWidth: "500px", maxHeight: "80vh", overflowY: "auto" }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "1.5rem",
                    gap: "1.5rem",
                }}
            >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        disablePast={!selectedProxyRule}
                        label="Start Date"
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => (
                            <TextField
                                label="Start Date"
                                size="small"
                                fullWidth={true}
                                helperText={"The delegation rule starting date"}
                                {...params}
                            />
                        )}
                        onChange={(date) => {
                            setStartDate(date);
                        }}
                        maxDate={
                            endDate && !selectedProxyRule
                                ? endDate
                                : Date.now() + 100000000000
                        }
                        value={startDate}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        disablePast={!selectedProxyRule}
                        label="End Date (Optional)"
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => (
                            <TextField
                                label="End Date (Optional)"
                                size="small"
                                fullWidth={true}
                                helperText={
                                    "The delegation rule end date, indefinite if not specified"
                                }
                                {...params}
                            />
                        )}
                        onChange={(date) => {
                            setEndDate(date);
                        }}
                        minDate={startDate}
                        value={endDate}
                    />
                </LocalizationProvider>
                {!selectedProxyRule ? (
                    <Autocomplete
                        multiple
                        fullWidth
                        filterSelectedOptions
                        data-testid="proxyUserSelection"
                        onChange={(_e, value) => {
                            setDelegateUsers(value.map((value) => value.id));
                        }}
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        getOptionLabel={(usr) => (usr ? usr.NAME : null)}
                        options={
                            delegateUsers.length < MAX_DELEGATIONS
                                ? userOptions
                                : []
                        }
                        noOptionsText={
                            delegateUsers.length < MAX_DELEGATIONS
                                ? "No Users Found"
                                : "Maximum User Count Reached"
                        }
                        onInputChange={(_e, value) =>
                            setUserAutocompleteQuery(value)
                        }
                        loading={autocompleteIsLoading}
                        defaultValue={[]}
                        filterOptions={(x) => x}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Delegate Users"
                                helperText={`Select User/s to Delegate. Max ${MAX_DELEGATIONS} Users.`}
                            />
                        )}
                    />
                ) : (
                    <TextField
                        variant="outlined"
                        value={selectedProxyRule?.USER_FULL_NAME}
                        helperText={"Delegated User"}
                        disabled={true}
                    />
                )}
                <Button
                    color="success"
                    endIcon={<SendIcon />}
                    variant="contained"
                    disabled={!canSubmit}
                    onClick={submitForm}
                >
                    {selectedProxyRule ? "Edit Rule" : "Create Rule"}
                </Button>
            </Box>
        </GenericModalContainer>
    );
};

CreateApprovalProxy.propTypes = {
    closeModal: PropTypes.func,
    isOpen: PropTypes.bool,
};

export default CreateApprovalProxy;
