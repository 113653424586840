import { Stack } from "@mui/material";
import React from "react";
import { RequisitionSummaryType } from "types/propTypes";

import ReceiptSummaryCard from "./receiptSummaryCard";

const ReceiptSummaryCards = ({ reqs }) => {
    return (
        <Stack spacing={2}>
            {reqs.map((req) => (
                <ReceiptSummaryCard key={req.id} req={req} />
            ))}
        </Stack>
    );
};

ReceiptSummaryCards.propTypes = RequisitionSummaryType;

export default ReceiptSummaryCards;
