import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
export const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FilterPanelCheckbox = ({ options, checkBoxStyle }) => {
    return (
        <FormGroup>
            {options.map((code, index) => (
                <FormControlLabel
                    control={
                        <Checkbox
                            name={code}
                            style={checkBoxStyle}
                            size="small"
                        />
                    }
                    disabled
                    key={index}
                    label={<Typography variant="body1">{code}</Typography>}
                />
            ))}
        </FormGroup>
    );
};

FilterPanelCheckbox.propTypes = {
    options: PropTypes.array,
    checkBoxStyle: PropTypes.object,
};

export default FilterPanelCheckbox;
