import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";

import { setPage } from "../../../redux/actions/nonCatFormActions";
import { closeNonCatDialogue } from "../../../utils/nonCatFormUtils";

const NonCataloguePrompt = () => {
    // Collect states and dispatchers
    const dispatch = useDispatch();

    const close = () => closeNonCatDialogue(dispatch);
    const nextPage = () => dispatch(setPage(1));

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="body1">
                Are you sure the item or an equivalent is not available via the
                iBuy product list?
            </Typography>
            <Box display="flex">
                <Button
                    color="error"
                    endIcon={<CancelIcon />}
                    onClick={close}
                    sx={{ margin: "16px" }}
                    variant="contained"
                >
                    No
                </Button>
                <Button
                    color="success"
                    endIcon={<CheckCircleOutlineIcon />}
                    onClick={nextPage}
                    sx={{ margin: "16px" }}
                    variant="contained"
                >
                    Yes
                </Button>
            </Box>
        </Box>
    );
};

export default NonCataloguePrompt;
