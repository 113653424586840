import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import PreFlightCheckInvalidAlert from "./preFlightCheckInvalidAlert";

const PreFlightCheckInfoAlert = ({ preflightBody, fromOwner, toOwner }) => {
    const darkMode = useSelector((state) => state.general.darkMode);
    return (
        <>
            {!fromOwner || !toOwner ? (
                <Alert
                    severity="info"
                    variant={darkMode ? "outlined" : "standard"}
                >
                    <AlertTitle>Change Details</AlertTitle>
                    <Typography variant="body2">
                        Fill out input boxes to generate change metadata
                    </Typography>
                </Alert>
            ) : preflightBody == 400 ? (
                <PreFlightCheckInvalidAlert />
            ) : (
                <Alert
                    severity="info"
                    variant={darkMode ? "outlined" : "standard"}
                >
                    <AlertTitle>Change Details</AlertTitle>
                    <Typography variant="body2">
                        Total owned by {fromOwner?.id} —{" "}
                        <strong>{preflightBody?.total_per_user}</strong>
                    </Typography>
                    <Typography variant="body2">
                        Total owned by {fromOwner?.id} available for owner
                        change —{" "}
                        <strong>{preflightBody?.total_valid_for_change}</strong>
                    </Typography>
                    <Typography variant="body2">
                        Total undergoing an owner change —{" "}
                        <strong>
                            {preflightBody?.total_undergoing_change}
                        </strong>
                    </Typography>
                    <Typography variant="body2">
                        Total that will be updated on submit —{" "}
                        <strong>{preflightBody?.total_to_be_updated}</strong>
                    </Typography>
                </Alert>
            )}
        </>
    );
};

PreFlightCheckInfoAlert.propTypes = {
    fromOwner: PropTypes.object,
    toOwner: PropTypes.object,
    preflightBody: PropTypes.oneOfType([
        PropTypes.shape({
            total_per_user: PropTypes.number,
            total_valid_for_change: PropTypes.number,
            total_undergoing_change: PropTypes.number,
            total_to_be_updated: PropTypes.number,
        }),
        PropTypes.number,
    ]),
};

export default PreFlightCheckInfoAlert;
