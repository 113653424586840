import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Typography } from "@mui/material";
import { Tooltip } from "@mui/material";
import { TextField } from "@mui/material";
import { Box } from "@mui/material";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { postRequisitionApproval } from "../../../services/requisition/requisitionAPIs";
import GenericModalContainer from "../containers/genericModalContainer";

const ApprovalsDialogue = ({
    closeModal,
    isOpen,
    isApprove,
    reqId,
    callbackFunc,
}) => {
    const dispatch = useDispatch();
    const [notes, setNotes] = useState("");

    const confirmChoice = () => {
        // Dispatch api call to approve
        let action = isApprove ? "APPROVE" : "REJECT";

        const requestBody = {
            action: action,
            note: notes,
        };
        postRequisitionApproval(reqId, requestBody, dispatch).then(
            (success) => {
                if (success) {
                    closeModal();
                    callbackFunc(reqId, action);
                }
            }
        );
    };

    return (
        <GenericModalContainer
            title={"Requisition Approval"}
            closeModal={closeModal}
            isOpen={isOpen}
        >
            <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="body1" color="white">
                    {`Are you sure you want to ${
                        isApprove ? "approve" : "reject"
                    } 
            the requisition?`}
                </Typography>
                <Tooltip
                    title="Optional: you can add notes supporting your decision"
                    placement="right"
                >
                    <TextField
                        id="approval-notes"
                        label="Notes"
                        required
                        fullWidth
                        multiline
                        rows={4}
                        variant="filled"
                        value={notes}
                        onChange={(event) => setNotes(event.target.value)}
                    />
                </Tooltip>
                <Box display="flex">
                    <Button
                        name="yes"
                        color="error"
                        endIcon={<CancelIcon />}
                        onClick={() => {
                            closeModal();
                        }}
                        sx={{ margin: "16px" }}
                        variant="contained"
                    >
                        Cancel
                    </Button>
                    <Button
                        name="cancel"
                        color="success"
                        endIcon={<CheckCircleOutlineIcon />}
                        onClick={confirmChoice}
                        sx={{ margin: "16px" }}
                        variant="contained"
                    >
                        Yes
                    </Button>
                </Box>
            </Box>
        </GenericModalContainer>
    );
};

ApprovalsDialogue.propTypes = {
    closeModal: PropTypes.func,
    isOpen: PropTypes.bool,
    isApprove: PropTypes.bool,
};

export default ApprovalsDialogue;
