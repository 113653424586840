import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CustomSlider from "components/customSlider";
import moment from "moment";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import GradientDivider from "styled/styledComponents/GradientDivider";

import {
    APPROVED,
    ATTENTION_REQUIRED,
    CANCELLATION_SUBMITTED,
    CANCELLED,
    CHANGING_OWNER,
    CREATED,
    FULLY_RECEIPTED,
    IN_PROCESS,
    INCOMPLETE,
    INVALID_REQUISITION,
    IS_PROC_MANAGER_REDUX_ROLE,
    MAX_PRICE,
    NOT_RECEIPTED,
    PENDING,
    PO_APPROVAL_PENDING,
    PRE_APPROVED,
    RECEIPTING_IN_FLIGHT,
    REJECTED,
    REQ_SUMMARY_TABLE,
    REQ_SUMMARY_TABLE_RECEIPT,
    RETURNED,
    SEARCH_API_DELIMITER,
    SHOW_CANCELLED_PARAM,
    SUBMITTED,
} from "../../../constants/generalConstants";
import {
    setDateGTE,
    setDateLTE,
    setPONumberSearch,
    setPriceRange,
    setProjectSearch,
    setRequesterSearch,
    setRequisitionNumberSearch,
    setSupplierSearch,
} from "../../../redux/actions/reqFilterPanelActions";
import { freeTextFormProps } from "../../../styled/props/textFieldProps";
import FilterPanelAutoComplete from "../../productList/filterPanelAutocomplete";

const STATUS_OPTIONS = [
    APPROVED,
    CHANGING_OWNER,
    CREATED,
    IN_PROCESS,
    INCOMPLETE,
    INVALID_REQUISITION,
    PO_APPROVAL_PENDING,
    PENDING,
    PRE_APPROVED,
    REJECTED,
    RETURNED,
    SUBMITTED,
];

export const reqFilterFormatDate = (date) => {
    /* 
    Arguments
    ---------
    date : obj
    */
    const offset = date.getTimezoneOffset();
    const formattedDate = new Date(date.getTime() - offset * 60 * 1000);
    return formattedDate.toISOString().split("T")[0];
};

const STATUS_OPTIONS_CANCELLED = [CANCELLED, CANCELLATION_SUBMITTED];
const YEAR_LOWER_BOUND = 2009;
const PO_NUMBER_SEARCH_MIN_CHARS = 5;

const ReqFilterPanel = ({ searchParams, setSearchParams, panelMode }) => {
    // Collect states and state dispatchers
    const receiptStatus = useSelector(
        (state) => state.reqFilterPanel.receiptStatus
    );
    const mode = useSelector((state) => state.reqFilterPanel.mode);
    const dateLTE = useSelector((state) => state.reqFilterPanel.dateLTE);
    const dateGTE = useSelector((state) => state.reqFilterPanel.dateGTE);
    const priceRange = useSelector((state) => state.reqFilterPanel.priceRange);
    const requisitionNumberSearch = useSelector(
        (state) => state.reqFilterPanel.requisitionNumberSearch
    );
    const poNumberSearch = useSelector(
        (state) => state.reqFilterPanel.poNumberSearch
    );
    const showCancelled = useSelector(
        (state) => state.reqFilterPanel.showCancelled
    );
    const projectSearch = useSelector(
        (state) => state.reqFilterPanel.projectSearch
    );
    const OU = useSelector((state) => state.reqFilterPanel.OU);
    const requesterSearch = useSelector(
        (state) => state.reqFilterPanel.requesterSearch
    );
    const receiptReqStatus = useSelector(
        (state) => state.reqFilterPanel.receiptReqStatus
    );
    const receiptYear = useSelector(
        (state) => state.reqFilterPanel.receiptYear
    );
    const status = useSelector((state) => state.reqFilterPanel.status);
    const operatingUnits = useSelector((state) => state.auth.operatingUnits);
    const isProcManager = useSelector((state) =>
        IS_PROC_MANAGER_REDUX_ROLE.includes(state.auth.role)
    );
    const supplierSearch = useSelector(
        (state) => state.reqFilterPanel.supplierSearch
    );
    const dispatch = useDispatch();

    const toggleMode = (event) => {
        searchParams.set("mode", event.target.value);
        setSearchParams(searchParams);
    };

    const toggleReceiptStatus = (event) => {
        const status = event.target.value;
        searchParams.set("receiptStatus", status);
        status == "N" && searchParams.delete("receiptReqStatus");
        setSearchParams(searchParams);
    };

    const onPriceSlide = (event, newValue) => {
        /* 
        Arguments
        ---------
        event : obj
        newValue : array
        */
        dispatch(setPriceRange(newValue));
    };

    const onPriceSlideCommitted = (event, newValue) => {
        /* 
        Arguments
        ---------
        event : obj
        newValue : array
        */
        // Delete params prior to resetting - required to prevent "param leakage"
        // as upper bound is not used in some cases
        searchParams.delete("price__gte");
        searchParams.delete("price__lte");
        // Set lower bound if it is not zero
        newValue[0] != 0 && searchParams.set("price__gte", newValue[0]);
        // Set upper bound if it is not == MAX value
        newValue[1] != MAX_PRICE && searchParams.set("price__lte", newValue[1]);
        setSearchParams(searchParams);
        // Set state instantly to improve slider responsiveness
        dispatch(setPriceRange(newValue));
    };

    const onStatusSelect = (event, value) => {
        /* 
        Arguments
        ---------
        event : obj
        value : array

        */
        value
            ? searchParams.set("status", value)
            : searchParams.delete("status");
        setSearchParams(searchParams);
    };

    /**
     * Updates search parameters on requisition number search
     * @param {object} event - mui textfield `onChange` event object
     */
    const onRequisitionNumberType = (event) => {
        const userInput = event.target.value;
        userInput.length != 0
            ? searchParams.set("requisitionId", userInput)
            : searchParams.delete("requisitionId");
        setSearchParams(searchParams);
        dispatch(setRequisitionNumberSearch(userInput));
    };

    /**
     * Updates search parameters on PO number search
     * @param {object} event - mui textfield `onChange` event object
     */
    const onPONumberType = (event) => {
        const userInput = event.target.value;
        const lenInput = userInput.length;
        dispatch(setPONumberSearch(userInput));
        if (lenInput > PO_NUMBER_SEARCH_MIN_CHARS) {
            searchParams.set("po_id", userInput);
        } else if (lenInput == 0) {
            searchParams.delete("po_id");
        } else {
            return;
        }
        setSearchParams(searchParams);
    };

    /**
     * Updates search parameters on project name / number search
     * @param {object} event - mui textfield `onChange` event object
     */
    const onProjectType = (event) => {
        const userInput = event.target.value;
        userInput.length != 0
            ? searchParams.set("projectNumber", userInput)
            : searchParams.delete("projectNumber");
        setSearchParams(searchParams);
        dispatch(setProjectSearch(userInput));
    };

    /**
     * Updates search parameters on supplier name / number search
     * @param {object} event - mui textfield `onChange` event object
     */
    const onSupplierType = (event) => {
        const userInput = event.target.value;
        userInput.length != 0
            ? searchParams.set("supplierName", userInput)
            : searchParams.delete("supplierName");
        setSearchParams(searchParams);
        dispatch(setSupplierSearch(userInput));
    };

    /**
     * Updates search parameters on requester name search
     * @param {object} event - mui textfield `onChange` event object
     */
    const onRequesterType = (event) => {
        const userInput = event.target.value;
        userInput.length != 0
            ? searchParams.set("requesterId", userInput)
            : searchParams.delete("requesterId");
        setSearchParams(searchParams);
        dispatch(setRequesterSearch(userInput));
    };

    /**
     * Called when a user selects a year (only in receipt mode)
     * @param {Object} event - mui event callback
     */
    const onYearSelect = (event) => {
        searchParams.set("year", event.target.value);
        setSearchParams(searchParams);
    };

    const onOUSelect = (event, value) => {
        /* 
        Arguments
        ---------
        event : obj
        value : array

        */
        value
            ? searchParams.set("org_name", value)
            : searchParams.delete("org_name");
        setSearchParams(searchParams);
    };

    /**
     * Called on user input date on filter panel. If date is valid,
     * search params are updated, triggering an updated API call.
     * @param {Date} date - date picked by user
     * @param {URLSearchParams} searchParam - url search params passed to req list API
     * @param {Function} action - redux action function. One of `setDateGTE`
     * or `setDateLTE`
     */
    const onDateSelect = (date, searchParam, action) => {
        dispatch(action(date));
        if (date && moment(date).isValid()) {
            searchParams.set(searchParam, reqFilterFormatDate(date));
        } else {
            searchParams.delete(searchParam);
        }
        setSearchParams(searchParams);
    };

    const onDateGTESelect = (date) => {
        onDateSelect(date, "date__gte", setDateGTE);
    };

    const onDateLTESelect = (date) => {
        onDateSelect(date, "date__lte", setDateLTE);
    };

    const onPrioritySwitch = (e_, value) => {
        /* 
        Arguments
        ---------
        value : bool
        */
        value
            ? searchParams.set("receiptReqStatus", ATTENTION_REQUIRED)
            : searchParams.delete("receiptReqStatus");
        setSearchParams(searchParams);
    };

    const onReceiptingStatusSelect = (_e, value) => {
        /* 
        Arguments
        ---------
        event : obj
        value : array
        */
        value.length > 0
            ? searchParams.set(
                  "receiptReqStatus",
                  value.join(SEARCH_API_DELIMITER)
              )
            : searchParams.delete("receiptReqStatus");
        setSearchParams(searchParams);
    };

    /**
     * Updates url search parameters on change of cancel state switch
     * @param {object} e_ - Material ui switch event object (not used)
     * @param {boolean} value  - boolean state of controlled switch
     */
    const onShowCancelledSwitch = (e_, value) => {
        value
            ? searchParams.set("cancelled", SHOW_CANCELLED_PARAM)
            : searchParams.delete("cancelled");
        searchParams.delete("status");
        setSearchParams(searchParams);
    };

    const isPriorityChecked = receiptReqStatus.includes(ATTENTION_REQUIRED)
        ? true
        : false;

    const receiptStatusOptions = isPriorityChecked
        ? [ATTENTION_REQUIRED]
        : [NOT_RECEIPTED, FULLY_RECEIPTED, RECEIPTING_IN_FLIGHT];

    const clearFilters = () => setSearchParams(new URLSearchParams());

    // Array of years in scope for selection, starting from
    // `YEAR_LOWER_BOUND`
    const yearsArrayInt = Array.from(
        new Array(new Date().getFullYear() - YEAR_LOWER_BOUND + 1),
        (x, i) => i + YEAR_LOWER_BOUND
    );
    const yearsArray = [...yearsArrayInt, "All Years"];

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <div></div>
                    <Typography variant="h4" align="center" fontWeight="normal">
                        Filters
                    </Typography>
                    <Tooltip title="Clear filters">
                        <IconButton
                            onClick={clearFilters}
                            aria-label="clearFilters"
                        >
                            <DeleteIcon color="iconButton" />
                        </IconButton>
                    </Tooltip>
                </Box>
                <GradientDivider />
            </Grid>
            {panelMode == REQ_SUMMARY_TABLE_RECEIPT && (
                <>
                    <Grid item xs={12}>
                        <FormControl
                            component="fieldset"
                            variant="standard"
                            sx={{ mt: "-12px" }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="secondary"
                                            checked={isPriorityChecked}
                                            onChange={onPrioritySwitch}
                                            disabled={receiptStatus == "N"}
                                        />
                                    }
                                    label="Priority Receipts Only"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl sx={{ mt: "-16px", mb: "4px" }}>
                            <RadioGroup
                                defaultValue="female"
                                value={receiptStatus}
                                onChange={toggleReceiptStatus}
                            >
                                <FormControlLabel
                                    value="Y"
                                    control={<Radio color="secondary" />}
                                    label="Requiring Receipting"
                                />
                                <FormControlLabel
                                    value="N"
                                    control={<Radio color="secondary" />}
                                    label="Not Requiring Receipting"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <FormControl sx={{ mt: "-16px" }}>
                    <RadioGroup
                        defaultValue="female"
                        value={mode}
                        onChange={toggleMode}
                    >
                        <FormControlLabel
                            value="MY"
                            control={<Radio color="secondary" />}
                            label="My Requisitions"
                        />
                        <FormControlLabel
                            value="ALL_PERMITTED"
                            control={<Radio color="secondary" />}
                            label="All Requisitions"
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    options={
                        isProcManager ? ["IRE", "SSE", "RENUK"] : operatingUnits
                    }
                    size="small"
                    getOptionLabel={(option) => option}
                    onChange={onOUSelect}
                    value={OU}
                    noOptionsText={
                        !isProcManager && operatingUnits.length == 0
                            ? "You are not assigned to any OU"
                            : null
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Select OU..."
                            size="small"
                            variant="outlined"
                            label="Operating Unit"
                        />
                    )}
                />
            </Grid>
            {panelMode == REQ_SUMMARY_TABLE ? (
                <>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={
                                showCancelled
                                    ? STATUS_OPTIONS_CANCELLED
                                    : STATUS_OPTIONS
                            }
                            filterOptions={(x) => x}
                            size="small"
                            getOptionLabel={(option) => option}
                            onChange={onStatusSelect}
                            value={status}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Select status..."
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    variant="outlined"
                                    label="Requisition Status"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            component="fieldset"
                            variant="standard"
                            sx={{ mt: "-12px" }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="secondary"
                                            checked={showCancelled}
                                            onChange={onShowCancelledSwitch}
                                        />
                                    }
                                    label="Cancelled Requisitions"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                            size="small"
                            variant="outlined"
                            color="secondary"
                        >
                            <InputLabel id="receipt-req-year-select" required>
                                Year Created
                            </InputLabel>
                            <Select
                                id="receipt-req-year-select"
                                label="receipt-req-year-select"
                                onChange={onYearSelect}
                                value={receiptYear}
                            >
                                {yearsArray.map((year) => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FilterPanelAutoComplete
                            options={receiptStatusOptions}
                            disabled={receiptStatus == "N" || isPriorityChecked}
                            onChange={onReceiptingStatusSelect}
                            label="Receipt Status"
                            placeholder="Select status..."
                            value={receiptReqStatus}
                            controlled={true}
                            checkBoxStyle={{
                                marginRight: 8,
                                padding: "4px 0px",
                            }}
                        />
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <Typography variant="body1">
                    Price: £{priceRange[0].toLocaleString()} - £
                    {priceRange[1].toLocaleString() +
                        `${priceRange[1] == MAX_PRICE ? "+" : ""}`}
                </Typography>
                <CustomSlider
                    color="secondary"
                    onChange={onPriceSlide}
                    onChangeCommitted={onPriceSlideCommitted}
                    max={MAX_PRICE}
                    value={priceRange}
                    size="small"
                    valueLabelDisplay="auto"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Requisition ID Contains Search"
                    onChange={onRequisitionNumberType}
                    placeholder="Start typing..."
                    size="small"
                    value={requisitionNumberSearch}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="PO Number Contains Search"
                    onChange={onPONumberType}
                    placeholder="Start typing..."
                    size="small"
                    value={poNumberSearch}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Project Number Contains Search"
                    onChange={onProjectType}
                    placeholder="Start typing..."
                    size="small"
                    value={projectSearch}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Supplier Name Contains Search"
                    onChange={onSupplierType}
                    placeholder="Start typing..."
                    size="small"
                    value={supplierSearch}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Requester Name Contains Search"
                    onChange={onRequesterType}
                    placeholder="Start typing..."
                    size="small"
                    value={requesterSearch}
                />
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={10.75}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Date From"
                            inputFormat="dd/MM/yyyy"
                            onChange={onDateGTESelect}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...{
                                        ...freeTextFormProps,
                                        variant: "outlined",
                                    }}
                                    fullWidth={false}
                                />
                            )}
                            value={dateGTE}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={1.25}>
                    <Tooltip title="Clear Date From">
                        <span>
                            <IconButton
                                aria-label="createBlankCart"
                                onClick={() => onDateGTESelect(null)}
                                disabled={dateGTE ? false : true}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={10.75}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Date To"
                            inputFormat="dd/MM/yyyy"
                            onChange={onDateLTESelect}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...{
                                        ...freeTextFormProps,
                                        variant: "outlined",
                                    }}
                                    fullWidth={false}
                                />
                            )}
                            value={dateLTE}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={1.25}>
                    <Tooltip title="Clear Date To">
                        <span>
                            <IconButton
                                aria-label="createBlankCart"
                                onClick={() => onDateLTESelect(null)}
                                disabled={dateLTE ? false : true}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
};

ReqFilterPanel.propTypes = {
    searchParams: PropTypes.object,
    setSearchParams: PropTypes.func,
    panelMode: PropTypes.oneOf([REQ_SUMMARY_TABLE, REQ_SUMMARY_TABLE_RECEIPT]),
};

export default ReqFilterPanel;
