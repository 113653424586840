import Typography from "@mui/material/Typography";
import DetailListVertical from "components/displays/detailListVertical";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import { SERVICE } from "../../../../../constants/generalConstants";
import CurrencyFigure from "../../../../displays/currencyFigure";

const OrderDetails = ({ reqLine }) => {
    const displayInReqCurrency = useSelector(
        (state) => state.singleReqSummary.displayInReqCurrency
    );
    const orderDetails = [
        reqLine?.LINE_TYPE !== SERVICE
            ? {
                  header: "Quantity Ordered",
                  content: reqLine.QUANTITY,
              }
            : {
                  header: "Order Type",
                  content: "By Amount",
              },
        reqLine?.LINE_TYPE === SERVICE
            ? {
                  header: "Amount",
                  content: (
                      <CurrencyFigure
                          value={
                              displayInReqCurrency
                                  ? reqLine?.linePriceReqCurrency
                                  : reqLine?.linePriceBaseCurrency
                          }
                          code={
                              displayInReqCurrency
                                  ? reqLine?.CURRENCY_CODE
                                  : reqLine?.ouBaseCurrency
                          }
                      />
                  ),
                  disableCopy: true,
              }
            : {
                  header: "Unit Price",
                  content: (
                      <CurrencyFigure
                          value={
                              displayInReqCurrency
                                  ? reqLine?.UNIT_PRICE
                                  : reqLine?.unitPriceBaseCurrency
                          }
                          code={
                              displayInReqCurrency
                                  ? reqLine?.CURRENCY_CODE
                                  : reqLine?.ouBaseCurrency
                          }
                      />
                  ),
                  disableCopy: true,
              },
    ];

    return (
        <>
            <Typography
                variant="body1"
                color="text.subheader"
                fontWeight="bold"
            >
                Order Details
            </Typography>
            <DetailListVertical condensed={true} details={orderDetails} />
        </>
    );
};

OrderDetails.propTypes = {
    reqLine: PropTypes.object,
};

export default OrderDetails;
