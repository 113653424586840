import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import OnDesktop from "components/wrappers/onDesktop";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import alertDispatcher from "utils/alertsUtils";

import { REQ_RECEIPT_SUMMARY } from "../../../constants/routes";
import {
    clearInvoices,
    clearPOs,
    clearReqLineLoadingState,
    setDisplayInReqCurrency,
} from "../../../redux/actions/singleReqSummaryActions";
import axiosApiInstance from "../../../services/middleware/axios";
import { GET_RECEIPTS_BY_REQUISITION } from "../../../services/receipting/urls";
import { getRequisitionById } from "../../../services/requisition/requisitionAPIs";
import { contentBoxL2 } from "../../../styled/styledSXProperties/styledBoxSX";
import { distinctReceiptNumsFromGetReceiptsByReqIDApi } from "../../../utils/receiptSummaryUtils";
import ChangeRequisitionOwnerDialog from "../../modals/changeRequisitionOwner/changeRequisitionOwnerDialog";
import PageNotFound from "../../pageNotFound/pageNotFound";
import RequisitionSummarySkeleton from "../../skeletons/requisitionSummarySkeleton";
import TabbedPanels from "../../tabbedPanels";
import OnMobile from "../../wrappers/onMobile";
import ApprovalsTreeComposition from "./approvalTree/approvalsTreeComposition";
import SingleRequisitionHeader from "./header";
import RequisitionLine from "./requisitionLine";
import { SingleRequisitionSummaryContext } from "./singleRequisitionSummaryContext";
import SummaryComposition from "./summary/summaryComposition";

const SingleRequisitionSummary = () => {
    // Collect states and dispatchers
    const invoices = useSelector((state) => state.singleReqSummary.invoices);
    const POs = useSelector((state) => state.singleReqSummary.POs);
    const reqLinesLoadingState = useSelector((state) => {
        const loadingState = state.singleReqSummary?.reqLinesLoadingState
            ? state.singleReqSummary?.reqLinesLoadingState
            : {};
        return Object.keys(loadingState).some(
            (reqLine) => loadingState[reqLine]
        );
    });
    const dispatch = useDispatch();
    const { changeOwnerIsOpen, setChangeOwnerIsOpen } = useContext(
        SingleRequisitionSummaryContext
    );

    // Local states
    const [isLoading, setIsLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [req, setReq] = useState({});
    const [receiptNumbers, setReceiptNumbers] = useState([]);
    const [receiptsLoading, setReceiptsLoading] = useState(true);

    // Url params
    const { requisitionNumber } = useParams();

    // Get requisition by number
    useEffect(() => {
        refreshData();
    }, [requisitionNumber]);

    const refreshData = () => {
        // When URL changes, refresh the webpage and re-load data
        dispatch(setDisplayInReqCurrency(true));
        dispatch(clearInvoices());
        dispatch(clearPOs());
        dispatch(clearReqLineLoadingState());
        loadData();
        loadReceipts();
    };

    const loadData = async () => {
        setIsLoading(true);
        const { isLoading, notFound, req } = await getRequisitionById(
            requisitionNumber,
            dispatch
        );
        setReq(req);
        setIsLoading(isLoading);
        setNotFound(notFound);
    };

    const loadReceipts = async () => {
        setReceiptsLoading(true);
        axiosApiInstance
            .get(GET_RECEIPTS_BY_REQUISITION(requisitionNumber))
            .then((response) => {
                setReceiptNumbers(
                    distinctReceiptNumsFromGetReceiptsByReqIDApi(response.data)
                );
                setReceiptsLoading(false);
            })
            .catch((error) => {
                alertDispatcher(
                    "loadReceipts",
                    error?.response?.status,
                    dispatch
                );
                setReceiptsLoading(false);
            });
    };

    // Navigator function
    const navigate = useNavigate();
    const navigateToReceiptSummary = () =>
        navigate(REQ_RECEIPT_SUMMARY(requisitionNumber));

    const summaryComposition = !notFound && !isLoading && (
        <SummaryComposition
            req={req}
            POs={POs}
            reqLinesLoadingState={reqLinesLoadingState}
            receiptsLoading={receiptsLoading}
            receiptNumbers={receiptNumbers}
            navigateToReceiptSummary={navigateToReceiptSummary}
            invoices={invoices}
            navigate={navigate}
        />
    );

    const requisitionLines = !notFound && !isLoading && (
        <Stack spacing={3}>
            {req.requisitionLines.map((reqLine, index) => (
                <RequisitionLine
                    key={index}
                    index={index}
                    reqLine={reqLine}
                    stagedLine={req?.stagedRequisitionLines?.[index]}
                    reqId={req.id}
                    headerStatus={req?.status}
                />
            ))}
        </Stack>
    );

    const approvalsTreeComposition = !notFound && !isLoading && (
        <ApprovalsTreeComposition req={req} />
    );

    return (
        <>
            <Box sx={contentBoxL2}>
                {notFound ? (
                    <PageNotFound />
                ) : !isLoading ? (
                    <Grid
                        container
                        spacing={2}
                        alignContent="flex-start"
                        alignItems="start"
                    >
                        <SingleRequisitionHeader req={req} />
                        <OnDesktop>
                            <Grid item xs={12} lg={9}>
                                <Grid item xs={12} container>
                                    {requisitionLines}
                                </Grid>
                            </Grid>
                        </OnDesktop>
                        <OnDesktop>
                            <Grid item xs={12} lg={3} container spacing={2}>
                                <Grid item xs={12}>
                                    {summaryComposition}
                                </Grid>
                                <Grid item xs={12}>
                                    {approvalsTreeComposition}
                                </Grid>
                            </Grid>
                        </OnDesktop>
                        <OnMobile>
                            <Grid item xs={12}>
                                <TabbedPanels
                                    title="Single requisition summary tabs"
                                    tabs={[
                                        {
                                            name: "Summary",
                                            id: "summary",
                                            content: summaryComposition,
                                        },
                                        {
                                            name: "Lines",
                                            id: "requisitionLines",
                                            content: requisitionLines,
                                        },
                                        {
                                            name: "Approvals",
                                            id: "approvals",
                                            content: approvalsTreeComposition,
                                        },
                                    ]}
                                />
                            </Grid>
                        </OnMobile>
                    </Grid>
                ) : (
                    <RequisitionSummarySkeleton />
                )}
            </Box>
            <ChangeRequisitionOwnerDialog
                req={req}
                isOpen={changeOwnerIsOpen}
                onClose={() => setChangeOwnerIsOpen(false)}
                onSuccess={() => refreshData()}
            />
        </>
    );
};

export default SingleRequisitionSummary;
