import RuleIcon from "@mui/icons-material/Rule";
import { LoadingButton } from "@mui/lab";
import Tooltip from "@mui/material/Tooltip";
import {
    TOOLTIP_VALIDATE_CHARGE_ACCOUNT_DISABLED_NON_PROJECT,
    TOOLTIP_VALIDATE_CHARGE_ACCOUNT_DISABLED_PROJECT_INTERNAL,
} from "constants/tooltipText";
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { getOrCreateCCID } from "../../services/procurement/procurementAPIs";
import { SegmentIdsType } from "../../types/propTypes";
import alertDispatcher from "../../utils/alertsUtils";

const ValidateChargeAccountButton = ({
    disabled,
    segmentIds,
    operatingUnit,
    onSuccess,
    onError,
    nonProjectMode,
}) => {
    const [isValidating, setIsValidating] = useState(false);
    const dispatch = useDispatch();

    const handleClick = () => {
        setIsValidating(true);

        getOrCreateCCID(segmentIds, operatingUnit)
            .then((response) => {
                alertDispatcher("ccidValidation", 200, dispatch);
                onSuccess && onSuccess(response.CHARGE_ACCOUNT_ID);
            })
            .catch((error) => {
                alertDispatcher(
                    "ccidValidation",
                    error?.response?.status,
                    dispatch,
                    error?.response?.data?.detail
                );
                onError && onError(error);
            })
            .finally(() => {
                setIsValidating(false);
            });
    };

    return (
        <Tooltip
            title={
                disabled
                    ? nonProjectMode
                        ? TOOLTIP_VALIDATE_CHARGE_ACCOUNT_DISABLED_NON_PROJECT
                        : TOOLTIP_VALIDATE_CHARGE_ACCOUNT_DISABLED_PROJECT_INTERNAL
                    : ""
            }
            disableInteractive
        >
            <span>
                <LoadingButton
                    disabled={disabled}
                    loading={isValidating}
                    loadingPosition="end"
                    onClick={handleClick}
                    endIcon={<RuleIcon />}
                    variant="outlined"
                    color="secondary"
                >
                    Validate
                </LoadingButton>
            </span>
        </Tooltip>
    );
};

ValidateChargeAccountButton.propTypes = {
    disabled: PropTypes.bool,
    segmentIds: SegmentIdsType.isRequired,
    operatingUnit: PropTypes.string.isRequired,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    nonProjectMode: PropTypes.bool.isRequired,
};

export default ValidateChargeAccountButton;
