import { SSE_PRIMARY_DARK_BLUE, SSE_TEAL } from "themes/colours";

export const stepIconSX = (darkMode) => ({
    "&.Mui-active": {
        color: darkMode ? SSE_TEAL : SSE_PRIMARY_DARK_BLUE,
    },
    "&.Mui-completed": {
        color: "#66BB6A",
    },
});

export const stepLabelSX = {
    ".MuiStepLabel-label": {
        fontSize: "14px",
    },
    ".MuiStepLabel-label.Mui-active": {
        color: "text.primary",
    },
    ".MuiStepLabel-label.Mui-completed": {
        color: "#8A93A0",
    },
};
