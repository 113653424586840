export const clickAway = (event, ref, dispatch, action) => {
    /*
    event : obj
    ref : obj
    dispatch : func
    action : func
    */
    if (ref.current && ref.current.contains(event.target)) {
        return;
    }
    dispatch(action(false));
};

export const styledPopperModifiers = (arrowRef) => {
    /*
    Arguments
    ---------
    arrowRef : func
    Returns
    -------
    array
    
    */
    return [
        {
            name: "flip",
            enabled: true,
            options: {
                altBoundary: true,
                rootBoundary: "document",
                padding: 8,
            },
        },
        {
            name: "preventOverflow",
            enabled: true,
            options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: "document",
                padding: 8,
            },
        },
        {
            name: "arrow",
            enabled: true,
            options: {
                element: arrowRef,
            },
        },
    ];
};
