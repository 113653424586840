import { LinearProgress } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
    CORPORATE_RATE,
    NON_CAT_BASE_RATE_VALUES,
    USER_RATE,
} from "constants/generalConstants";
import { decimalNumberRegex } from "constants/patterns";
import moment from "moment";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setProduct, setProductRate } from "redux/actions/nonCatFormActions";
import { objectOnCondition } from "utils/general";
import { nonCatDefaultRateValues } from "utils/nonCatFormUtils";

import useProcurement from "../../../services/apiUtils/hooks/useProcurement";
import AlertBanner from "../../displays/alertBanner";
import { freeTextFormProps } from "./../../../styled/props/textFieldProps";
import ConversionDisplay from "./conversionDisplay";

const CPA_FIXED_TEXT = " - fixed by CPA selection";

/**
 * Renders currency selection fields for the non-catalogue
 * form, including currency code, rate, rate type and rate
 * date.
 * @component
 */
const NonCatalogueCurrencySelection = ({
    cpaSelected,
    ouBaseCurrency,
    errors,
    register,
    control,
    setValue,
}) => {
    const { getExchangeRate, isLoadingExchangeRate } = useProcurement();

    // Assemble states and state dispatchers
    const product = useSelector((state) => state.nonCatForm.product);
    const currencies = useSelector(
        (state) => state.filterPanelLookups.CURRENCIES || []
    );
    const dispatch = useDispatch();

    const isCurrencyNotOUBase = product?.CURRENCY_CODE !== ouBaseCurrency;
    const isUserRate = product?.RATE_TYPE === USER_RATE;

    // Instantiate form validators
    const { ref: rateRef, ...rateInputProps } = register("rate", {
        onChange: (e) => updateField(e.target.value, "RATE"),
        required: isCurrencyNotOUBase ? "Rate is required" : null,
        pattern: {
            value: decimalNumberRegex,
            message: "Invalid Number Input",
        },
    });

    /**
     * Writes selected currency code to redux product state. In some
     * cases, documented below, other rate based fields are also updated.
     * @param {string} newCurrency - selected currency value
     */
    const selectCurrency = (newCurrency) => {
        const newCurrencyEqBase = newCurrency === ouBaseCurrency;
        const updatedProduct = {
            ...product,
            CURRENCY_CODE: newCurrency,
        };
        if (isCurrencyNotOUBase && newCurrencyEqBase) {
            // User selects base currency when current currency
            // is not equal to base currency
            dispatch(
                setProduct({
                    ...updatedProduct,
                    ...NON_CAT_BASE_RATE_VALUES,
                })
            );
        } else if (isCurrencyNotOUBase) {
            // User selects another non-base currency when
            // current currency is not equal to base currency
            dispatch(setProduct(updatedProduct));
        } else {
            // User selects a non-base currency when current
            // currency is is equal to base currency
            dispatch(
                setProduct({
                    ...updatedProduct,
                    ...nonCatDefaultRateValues(),
                })
            );
        }
    };

    /**
     * Updates specified redux `nonCatForm.product` state with new value
     * @param {any} value - new value to be updated
     * @param {string} field - field name of redux `nonCatForm.product` state
     * being updated
     */
    const updateField = (value, field) => {
        dispatch(
            setProduct({
                ...product,
                [field]: value,
            })
        );
    };

    /**
     * Writes selected rate type into non cat redux state.
     * Nullifies rate when moving from corporate rate to
     * user rate.
     * @param {Object} e - material UI select on change event
     */
    const setRateType = (e) => {
        const newRate = e.target.value;
        dispatch(
            setProduct({
                ...product,
                RATE_TYPE: newRate,
                ...objectOnCondition(newRate === USER_RATE, {
                    RATE: "",
                }),
            })
        );
    };

    // Product RATE updater
    useEffect(() => {
        (async () => {
            const convertedRate = await (async () => {
                if (!isCurrencyNotOUBase) return NON_CAT_BASE_RATE_VALUES.RATE;
                if (product.RATE_TYPE === USER_RATE) return product.RATE || "";
                if (
                    !product.RATE_DATE ||
                    !product.CURRENCY_CODE ||
                    !ouBaseCurrency
                )
                    return "";
                return await getExchangeRate(
                    product.CURRENCY_CODE,
                    ouBaseCurrency,
                    product.RATE_DATE
                );
            })();
            setValue("rate", convertedRate);
            dispatch(setProductRate(convertedRate));
        })();
    }, [
        ouBaseCurrency,
        isCurrencyNotOUBase,
        product.CURRENCY_CODE,
        product.RATE_TYPE,
        product.RATE_DATE,
        cpaSelected,
    ]);
    return (
        <>
            <Grid item xs={6}>
                <FormControl
                    fullWidth
                    size="small"
                    variant="standard"
                    color="secondary"
                >
                    <InputLabel id="currency-select" required>
                        {`Currency${cpaSelected ? CPA_FIXED_TEXT : ""}`}
                    </InputLabel>
                    <Select
                        id="currency-select"
                        label="currency-select"
                        value={product?.CURRENCY_CODE}
                        disabled={cpaSelected}
                        onChange={(e) => selectCurrency(e.target.value)}
                    >
                        {currencies.map((currency) => (
                            <MenuItem key={currency} value={currency}>
                                {currency}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {isCurrencyNotOUBase && (
                <>
                    <Grid item xs={6}>
                        <FormControl
                            fullWidth
                            size="small"
                            variant="standard"
                            color="secondary"
                        >
                            <InputLabel id="rate-type-select" required>
                                {`Rate Type Select${
                                    cpaSelected ? CPA_FIXED_TEXT : ""
                                }`}
                            </InputLabel>
                            <Select
                                id="rate-type-select"
                                label="Rate Type Select"
                                value={product?.RATE_TYPE}
                                disabled={cpaSelected}
                                onChange={setRateType}
                            >
                                <MenuItem value={USER_RATE}>User</MenuItem>
                                <MenuItem value={CORPORATE_RATE}>
                                    Corporate
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name="rateDate"
                            defaultValue={product?.RATE_DATE}
                            rules={{
                                required: isCurrencyNotOUBase
                                    ? "Rate Date is Required"
                                    : null,
                                validate: (date) =>
                                    !moment(date).isValid() &&
                                    isCurrencyNotOUBase
                                        ? "Invalid Date"
                                        : null,
                            }}
                            control={control}
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                            }) => (
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <DesktopDatePicker
                                        disableFuture
                                        label={`Rate Date${
                                            cpaSelected ? CPA_FIXED_TEXT : ""
                                        }`}
                                        inputFormat="dd/MM/yyyy"
                                        onChange={(newDate) => {
                                            onChange(newDate); // Update form validator
                                            moment(newDate).isValid() &&
                                                updateField(
                                                    newDate,
                                                    "RATE_DATE"
                                                ); // Update redux
                                        }}
                                        value={value}
                                        disabled={cpaSelected}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                {...freeTextFormProps}
                                                error={!!error}
                                                helperText={error?.message}
                                                required
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </Grid>
                    {isUserRate && (
                        <Grid item xs={6}>
                            <TextField
                                {...freeTextFormProps}
                                label="Rate"
                                placeholder="Please enter a user rate"
                                required
                                value={product?.RATE}
                                inputRef={rateRef}
                                {...rateInputProps}
                                error={!!errors.rate}
                                helperText={errors?.rate?.message}
                                disabled={cpaSelected}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        {!isLoadingExchangeRate ? (
                            product.RATE || isUserRate ? (
                                <ConversionDisplay
                                    product={product}
                                    displayCurrency={ouBaseCurrency}
                                />
                            ) : (
                                <AlertBanner
                                    severity="warning"
                                    title="Unable to find a corporate exchange rate"
                                    description={
                                        cpaSelected
                                            ? `It was not possible to find a corporate exchange rate from ${product.CURRENCY_CODE} to ${ouBaseCurrency} for today's date. This rate is required to submit an order against the contract you selected. Please raise a ticket with the support team.`
                                            : `It was not possible to find a corporate exchange rate from ${product.CURRENCY_CODE} to ${ouBaseCurrency} for the rate date you selected. This is required to complete this form. Try using a different rate date. Alternatively, please raise a ticket with the support team.`
                                    }
                                />
                            )
                        ) : (
                            <LinearProgress color="secondary" />
                        )}
                    </Grid>
                </>
            )}
        </>
    );
};

NonCatalogueCurrencySelection.propTypes = {
    cpaSelected: PropTypes.bool,
    ouBaseCurrency: PropTypes.string,
    errors: PropTypes.object,
    register: PropTypes.func,
    setValue: PropTypes.func,
};

export default NonCatalogueCurrencySelection;
