import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PersonIcon from "@mui/icons-material/Person";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MOBILE_MEDIA_QUERY } from "constants/mediaQueries";
import useCopyAmendReq from "hooks/useCopyAmendReq";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openCancelRequisitionDialogue } from "utils/cancelRequisitionUtils";
import { openCreateReceiptDialogue } from "utils/createReceiptUtils";
import {
    amendReqButtonTooltip,
    cancelReqButtonTooltip,
    canChangeOwner,
    canReqBeAmended,
    canReqBeCancelled,
    canReqBeCopied,
    canReqBeReceipted,
    changeOwnerTooltip,
    copyReqButtonTooltip,
    receiptReqButtonTooltip,
} from "utils/requisitionActionButtonUtils";

import { IS_PROC_MANAGER_REDUX_ROLE } from "../../../../constants/generalConstants";
import { SingleRequisitionSummaryContext } from "../singleRequisitionSummaryContext";

// eslint-disable-next-line no-unused-vars
const ReqActionButtons = ({ req, margin = "2px" }) => {
    // Collect states and state dispatchers
    const isCancelFormLoading = useSelector(
        (state) => state.cancelRequisition.isLoading
    );
    const isReceiptFormLoading = useSelector(
        (state) => state.createReceipt.isLoading
    );
    const userOUs = useSelector((state) => state.auth.operatingUnits);
    const isUserProcurementManager = useSelector((state) =>
        IS_PROC_MANAGER_REDUX_ROLE.includes(state.auth.role)
    );
    const dispatch = useDispatch();
    const { setChangeOwnerIsOpen } = useContext(
        SingleRequisitionSummaryContext
    );

    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    const [clickCopyReq, clickAmendReq, isCopyFormLoading, isAmendFormLoading] =
        useCopyAmendReq(req);

    const minColumnWidth = isDesktop ? "180px" : "155px";

    return (
        <Box
            display="grid"
            gridTemplateColumns={`repeat(auto-fill, minmax(${minColumnWidth}, 1fr))`}
            gap={1}
        >
            <Tooltip title={receiptReqButtonTooltip(req, userOUs)}>
                <span>
                    <LoadingButton
                        disabled={!canReqBeReceipted(req, userOUs)}
                        loading={isReceiptFormLoading}
                        loadingPosition={isDesktop ? "end" : undefined}
                        endIcon={isDesktop ? <ReceiptIcon /> : undefined}
                        onClick={() =>
                            openCreateReceiptDialogue(req.id, dispatch)
                        }
                        size="small"
                        variant="contained"
                        sx={{ width: "100%" }}
                    >
                        Receipt
                    </LoadingButton>
                </span>
            </Tooltip>
            <Tooltip title={amendReqButtonTooltip(req, userOUs)}>
                <span>
                    <LoadingButton
                        loading={isAmendFormLoading}
                        loadingPosition={isDesktop ? "end" : undefined}
                        endIcon={isDesktop ? <ChangeCircleIcon /> : undefined}
                        disabled={!canReqBeAmended(req, userOUs)}
                        onClick={() => clickAmendReq(req.id)}
                        variant="contained"
                        size="small"
                        sx={{ width: "100%" }}
                    >
                        Amend
                    </LoadingButton>
                </span>
            </Tooltip>
            <Tooltip title={changeOwnerTooltip(req, isUserProcurementManager)}>
                <span>
                    <Button
                        endIcon={isDesktop ? <PersonIcon /> : undefined}
                        disabled={
                            !canChangeOwner(req, isUserProcurementManager)
                        }
                        onClick={() => setChangeOwnerIsOpen(true)}
                        variant="contained"
                        size="small"
                        sx={{ width: "100%" }}
                    >
                        Change owner
                    </Button>
                </span>
            </Tooltip>
            <Tooltip title={copyReqButtonTooltip(req, userOUs)}>
                <span>
                    <LoadingButton
                        disabled={!canReqBeCopied(req, userOUs)}
                        loading={isCopyFormLoading}
                        loadingPosition={isDesktop ? "end" : undefined}
                        endIcon={isDesktop ? <ContentCopyIcon /> : undefined}
                        onClick={clickCopyReq}
                        variant="contained"
                        size="small"
                        sx={{ width: "100%" }}
                    >
                        Copy
                    </LoadingButton>
                </span>
            </Tooltip>
            <Tooltip title={cancelReqButtonTooltip(req, userOUs)}>
                <span>
                    <LoadingButton
                        disabled={!canReqBeCancelled(req, userOUs)}
                        loading={isCancelFormLoading}
                        loadingPosition={isDesktop ? "end" : undefined}
                        endIcon={isDesktop ? <CancelIcon /> : undefined}
                        onClick={() =>
                            openCancelRequisitionDialogue(req.id, dispatch)
                        }
                        variant="contained"
                        size="small"
                        color="error"
                        sx={{ width: "100%" }}
                    >
                        Cancel
                    </LoadingButton>
                </span>
            </Tooltip>
        </Box>
    );
};

ReqActionButtons.defaultProps = {
    margin: "2px",
};

ReqActionButtons.propTypes = {
    req: PropTypes.object,
};

export default ReqActionButtons;
