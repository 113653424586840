import { Typography } from "@mui/material";
import OnDesktop from "components/wrappers/onDesktop";
import OnMobile from "components/wrappers/onMobile";
import { RETURN_TO_VENDOR } from "constants/generalConstants";
import React from "react";
import { useSelector } from "react-redux";

import ReceiptTransactionsList from "./receiptTransactionsList";
import ReceiptTransactionsTable from "./receiptTransactionsTable";

const ReceiptTransactions = () => {
    const receiptTransactions = useSelector((state) => {
        const receiptTransactions =
            state.receiptSummary.receipt?.receiptTransactions;
        return receiptTransactions ? receiptTransactions : [];
    });

    const containsReturnTx = receiptTransactions.some(
        (tx) => tx?.TRANSACTION_TYPE === RETURN_TO_VENDOR
    );

    return receiptTransactions.length === 0 ? (
        <Typography variant="body1">
            No transactions associated with this receipt
        </Typography>
    ) : (
        <>
            <OnDesktop>
                <ReceiptTransactionsTable
                    receiptTransactions={receiptTransactions}
                    containsReturnTx={containsReturnTx}
                />
            </OnDesktop>
            <OnMobile>
                <ReceiptTransactionsList
                    receiptTransactions={receiptTransactions}
                />
            </OnMobile>
        </>
    );
};

export default ReceiptTransactions;
