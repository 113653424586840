import { combineReducers } from "redux";

// Reducers defined in reducer directory
import alertsReducer from "./alertsReducer";
import authReducer from "./authReducer";
import cancelRequisitionReducer from "./cancelRequisitionReducer";
import changeRequisitionOwnerReducer from "./changeRequisitionOwnerReducer";
import createReceiptReducer from "./createReceiptReducer";
import createRequisitionReducer from "./createRequisitionReducer";
import filterPanelLookupsReducer from "./filterPanelLookupsReducer";
import filterPanelValuesReducer from "./filterPanelValuesReducer";
import formLookupsReducer from "./formLookupsReducer";
import generalReducer from "./generalReducer";
import homepageCategoriesReducer from "./homepageCategoriesReducer";
import nonCatFormReducer from "./nonCatFormReducer";
import receiptSummaryReducer from "./receiptSummaryReducer";
import reqFilterPanelReducer from "./reqFilterPanelReducer";
import shoppingCartReducer from "./shoppingCartReducer";
import singleReqSummaryReducer from "./singleReqSummaryReducer";

const rootReducer = combineReducers({
    alerts: alertsReducer,
    auth: authReducer,
    cancelRequisition: cancelRequisitionReducer,
    createReceipt: createReceiptReducer,
    createRequisition: createRequisitionReducer,
    filterPanelLookups: filterPanelLookupsReducer,
    filterPanelValues: filterPanelValuesReducer,
    formLookups: formLookupsReducer,
    homepageCategories: homepageCategoriesReducer,
    general: generalReducer,
    nonCatForm: nonCatFormReducer,
    shoppingCart: shoppingCartReducer,
    receiptSummary: receiptSummaryReducer,
    reqFilterPanel: reqFilterPanelReducer,
    singleReqSummary: singleReqSummaryReducer,
    changeRequisitionOwner: changeRequisitionOwnerReducer,
});

export default rootReducer;
