import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import IBuyPaper from "components/displays/iBuyPaper";

import GradientDivider from "../../styled/styledComponents/GradientDivider";

const ProductDetailsSkeleton = () => (
    <IBuyPaper>
        <Grid container spacing={5}>
            <Grid
                container
                item
                xs={12}
                md={5}
                lg={4}
                direction="column"
                justifyContent="center"
            >
                <Skeleton
                    aria-label="productListImageSkeleton"
                    sx={{
                        borderRadius: "10px",
                        height: 0,
                        width: "100%",
                        paddingTop: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "10",
                        ":hover": {
                            boxShadow: "16",
                        },
                    }}
                    variant="rectangular"
                />
            </Grid>
            <Grid item xs={12} md={7} lg={8}>
                <Grid container spacing={3}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} md={9} lg={10}>
                            <Skeleton
                                aria-label="skeletonText"
                                variant="text"
                                width="100%"
                                height={45}
                            />
                            <GradientDivider />
                        </Grid>
                        <Grid item xs={12} md={3} lg={2} align="center">
                            <Skeleton
                                aria-label="skeletonText"
                                variant="text"
                                width="60%"
                                height={20}
                            />
                            <Skeleton
                                aria-label="skeletonText"
                                variant="text"
                                width="60%"
                                height={40}
                            />
                        </Grid>
                    </Grid>
                    <Grid item alignContent="top" xs={6} align="center">
                        <Skeleton
                            aria-label="skeletonText"
                            variant="text"
                            width="40%"
                            height={30}
                        />
                        <Skeleton
                            aria-label="skeletonText"
                            variant="text"
                            width="40%"
                            height={20}
                        />
                        <Skeleton
                            aria-label="skeletonText"
                            variant="text"
                            width="40%"
                            height={20}
                        />
                        <Skeleton
                            aria-label="skeletonText"
                            variant="text"
                            width="40%"
                            height={20}
                        />
                    </Grid>
                    <Grid item xs={6} align="center">
                        <Skeleton
                            aria-label="skeletonText"
                            variant="text"
                            width="40%"
                            height={30}
                        />
                        <Skeleton
                            aria-label="skeletonText"
                            variant="text"
                            width="40%"
                            height={20}
                        />
                        <Skeleton
                            aria-label="skeletonText"
                            variant="text"
                            width="40%"
                            height={20}
                        />
                        <Skeleton
                            aria-label="skeletonText"
                            variant="text"
                            width="40%"
                            height={20}
                        />
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Skeleton
                            aria-label="skeletonText"
                            variant="text"
                            width="80%"
                            height={20}
                        />
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12} align="center">
                            <Skeleton
                                aria-label="skeletonText"
                                variant="text"
                                width="40%"
                                height={30}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </IBuyPaper>
);

export default ProductDetailsSkeleton;
