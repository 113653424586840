import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    DEFAULT_CART_ID,
    FAVOURITES_CART_NAME,
    FROM_DEFAULT,
} from "../../constants/generalConstants";
import GradientDivider from "../../styled/styledComponents/GradientDivider";
import { totalCost, totalQuantity } from "../../utils/shoppingCartUtils";
import CurrencyFigure from "../displays/currencyFigure";
import IBuyPaper from "../displays/iBuyPaper";
import { CART_PREFIX } from "./../../constants/routes";
import {
    setShowCreateNameCartDialogue,
    setShowManageCartDialogue,
    updateActiveCart,
} from "./../../redux/actions/generalActions";
import { createRequisition } from "./../../utils/shoppingCartUtils";
import ManageCartDialogue from "./../modals/cartManagement/manageCartDialogue";

const ShoppingCartSummary = ({ isDesktop }) => {
    // Collect states and state dispatchers
    const carts = useSelector((state) => state.shoppingCart);
    const activeCart = useSelector((state) => state.general.activeCart);
    const items = useSelector((state) => state.shoppingCart[activeCart].items);
    const reqFormLoading = useSelector(
        (state) => state.createRequisition.formLoading
    );
    const activeCartObj = useSelector(
        (state) => state.shoppingCart[activeCart]
    );
    const dispatch = useDispatch();

    const navigate = useNavigate();

    // Update active shopping cart state, called when using the select cart dropdown filter
    // and route to cart URL
    const handleSelectActiveCart = (event) => {
        const selection = event.target.value;
        dispatch(updateActiveCart(selection));
        navigate(CART_PREFIX + selection);
    };

    const handleClickSaveCart = () =>
        dispatch(
            setShowCreateNameCartDialogue({ open: true, mode: FROM_DEFAULT })
        );

    const handleClickManageCart = () =>
        dispatch(setShowManageCartDialogue(true));

    return (
        <>
            <IBuyPaper
                additionalSx={{
                    flexDirection: "column",
                    marginBottom: "24px",
                    overflowY: "auto",
                    width: "100%",
                }}
            >
                <Typography variant="h5">Select Shopping Cart</Typography>
                <FormControl
                    size="small"
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingTop: "16px",
                    }}
                >
                    <Select
                        onChange={handleSelectActiveCart}
                        sx={{ flexGrow: 1, marginRight: "16px" }}
                        value={activeCart}
                        color="secondary"
                    >
                        {Object.keys(carts)
                            .filter(
                                (key) => carts[key].name != FAVOURITES_CART_NAME
                            )
                            .map((key) => (
                                <MenuItem key={carts[key].id} value={key}>
                                    {carts[key].name}
                                </MenuItem>
                            ))}
                    </Select>
                    <Button
                        variant="outlined"
                        onClick={
                            activeCart === DEFAULT_CART_ID
                                ? handleClickSaveCart
                                : handleClickManageCart
                        }
                        sx={{ flex: "0 0 auto" }} // Prevent text wrapping
                        color="secondary"
                    >
                        {activeCart === DEFAULT_CART_ID
                            ? "Save Cart"
                            : "Manage Cart"}
                    </Button>
                </FormControl>
            </IBuyPaper>
            <IBuyPaper
                additionalSx={{
                    flexDirection: "column",
                    overflowY: "auto",
                    width: "100%",
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: "bold",
                    }}
                >
                    Cart Summary
                </Typography>
                <GradientDivider />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h6">Total Items</Typography>
                    <Typography variant="h6">
                        <NumberFormat
                            value={totalQuantity(items)}
                            displayType={"text"}
                            thousandSeparator={true}
                        />
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h6">Supplier</Typography>
                    <Typography variant="h6">
                        {totalQuantity(items) === 0
                            ? "Cart Empty"
                            : items[0].SUPPLIER_NAME
                            ? items[0].SUPPLIER_NAME
                            : "Not Specified"}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h6">Operating Unit</Typography>
                    <Typography variant="h6">
                        {totalQuantity(items) === 0
                            ? "Cart Empty"
                            : items[0]?.IBUY_CART_ITEM_OU}
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    paddingTop={isDesktop ? "24px" : "12px"}
                >
                    <Typography variant={isDesktop ? "h3" : "h4"}>
                        Total
                    </Typography>
                    <Typography
                        variant={isDesktop ? "h3" : "h4"}
                        color="text.secondaryLight"
                    >
                        <CurrencyFigure
                            value={totalCost(items)}
                            code={items[0]?.CURRENCY_CODE}
                        />
                    </Typography>
                </Box>
                <GradientDivider />
                <LoadingButton
                    disabled={totalQuantity(items) == 0 ? true : false}
                    onClick={() => createRequisition(activeCartObj, dispatch)}
                    color="primary"
                    sx={{ marginTop: isDesktop ? "20px" : "4px" }}
                    bgcolor="primary.main"
                    variant="contained"
                    loading={reqFormLoading}
                >
                    Create Requisition
                </LoadingButton>
                <ManageCartDialogue />
            </IBuyPaper>
        </>
    );
};

export default ShoppingCartSummary;
