import Typography from "@mui/material/Typography";
import DetailListVertical from "components/displays/detailListVertical";
import PropTypes from "prop-types";
import React from "react";

const DeliveryInformation = ({ reqLine }) => {
    const deliveryDetails = [
        {
            header: "Location",
            content: reqLine?.DELIVERY_LOCATION
                ? reqLine?.DELIVERY_LOCATION
                : "Not Available",
        },
        {
            header: "FOB Details",
            content: reqLine?.FOB_DESCRIPTION
                ? reqLine?.FOB_DESCRIPTION
                : "Not Available",
        },
    ];

    return (
        <>
            <Typography
                variant="body1"
                color="text.subheader"
                fontWeight="bold"
            >
                Delivery Information
            </Typography>
            <DetailListVertical condensed={true} details={deliveryDetails} />
        </>
    );
};

DeliveryInformation.propTypes = {
    reqLine: PropTypes.object,
};

export default DeliveryInformation;
