import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { styledPaperSX } from "../../styled/styledSXProperties/styledPaperSX";

const IBuyPaper = ({ children, additionalSx = null, ...paperProps }) => {
    // Collect redux states and dispatchers
    const darkMode = useSelector((state) => state.general.darkMode);

    const paperSx = additionalSx
        ? { ...styledPaperSX(darkMode), ...additionalSx }
        : styledPaperSX(darkMode);
    return (
        <Paper sx={paperSx} {...paperProps}>
            {children}
        </Paper>
    );
};

IBuyPaper.propTypes = {
    additionalSx: PropTypes.object,
};

export default IBuyPaper;
