// eslint-disable-next-line no-undef
const clientId = GLOBAL_CONFIG.clientId;
// eslint-disable-next-line no-undef
const authority = GLOBAL_CONFIG.authority;
// eslint-disable-next-line no-undef
const redirectUri = GLOBAL_CONFIG.redirectUri;
// eslint-disable-next-line no-undef
const scopes = GLOBAL_CONFIG.scopes;

export const msalConfig = {
    auth: {
        clientId: clientId,
        authority: authority,
        redirectUri: redirectUri,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false, // Set to "true" if having issues on IE11 or Edge
    },
};

export const tokenRequest = {
    scopes: scopes,
};
