import { Table, TableCell, TableRow } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const tableRowSx = {
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    overflowWrap: "break-word",
};

const NotesRow = ({ header, value }) => (
    <TableRow sx={tableRowSx}>
        <TableCell align="left" sx={{ minWidth: 50 }}>
            <Typography color="text.secondary" variant="body1">
                {header}
            </Typography>
        </TableCell>
        <TableCell align="left">
            <Typography variant="body1">{value}</Typography>
        </TableCell>
    </TableRow>
);

NotesRow.propTypes = {
    header: PropTypes.string,
    value: PropTypes.string,
};

const NOT_ENTERED_MSG = "Not entered for this line";

const SupplementingNotes = ({ reqLine }) => {
    return (
        <Table
            aria-label="simple table"
            size="small"
            data-testid="supplementingNotesTable"
        >
            <TableBody>
                <NotesRow
                    header="Requisition Description"
                    value={reqLine?.HEADER_DESCRIPTION || NOT_ENTERED_MSG}
                />
                <NotesRow
                    header="Justification"
                    value={reqLine?.JUSTIFICATION || NOT_ENTERED_MSG}
                />
                <NotesRow
                    header="Notes to Approver"
                    value={reqLine?.NOTE_TO_APPROVER || NOT_ENTERED_MSG}
                />
                <NotesRow
                    header="Notes to Buyer"
                    value={reqLine?.NOTE_TO_BUYER || NOT_ENTERED_MSG}
                />
                <NotesRow
                    header="Notes to Receiver"
                    value={reqLine?.NOTE_TO_RECEIVER || NOT_ENTERED_MSG}
                />
            </TableBody>
        </Table>
    );
};

SupplementingNotes.propTypes = {
    reqLine: PropTypes.object,
};

export default SupplementingNotes;
