// Returns action creators for each general action type
import {
    DECREMENT_APPROVALS_COUNT,
    SET_ACTIVE_SMART_FORM,
    SET_ADD_TO_NEW_NAMED_CART_ITEM,
    SET_APPROVALS_COUNT,
    SET_CARTS_LOADING,
    SET_DARK_MODE,
    SET_FAVOURITES_ID,
    SET_HOMEPAGE_CATS_LOADING,
    SET_IS_SCROLLING,
    SET_RECEIPTS_COUNT,
    SET_SEARCH_CATEGORY,
    SET_SHOW_ADD_TO_NEW_NAMED_CART_DIALOGUE,
    SET_SHOW_APPROVALS_DIALOGUE,
    SET_SHOW_BATCH_REQ_OWNER_CHANGE_DIALOGUE,
    SET_SHOW_CANCEL_REQUISITION_DIALOGUE,
    SET_SHOW_CREATE_NAME_CART_DIALOGUE,
    SET_SHOW_DISPUTE_DIALOGUE,
    SET_SHOW_MANAGE_CART_DIALOGUE,
    SET_SHOW_NON_CATALOGUE_DIALOGUE,
    SET_SHOW_RECEIPT_SUMMARY_DIALOGUE,
    SET_SHOW_RECEIPTING_DIALOGUE,
    SET_SHOW_REQUISITION_DIALOGUE,
    SET_SHOW_SHOPPING_CART_SUMMARY_POPPER,
    SET_SHOW_SMART_FORMS_DIALOGUE,
    SET_SHOW_USER_INFO,
    SET_USER_INFO_LOADING,
    TOGGLE_NAVIGATION_BAR_WIDTH,
    TOGGLE_SPEED_DIAL_PLACEMENT,
    UPDATE_ACTIVE_CART,
} from "../actionTypes";

// Action creators for general slice
export const setAddToNewNamedCartItem = (item) => {
    return { type: SET_ADD_TO_NEW_NAMED_CART_ITEM, payload: item };
};

export const setCartsLoading = (isLoading) => {
    return { type: SET_CARTS_LOADING, payload: isLoading };
};

export const setFavouritesId = (id) => {
    return { type: SET_FAVOURITES_ID, payload: id };
};

export const setHomepageCategoriesLoading = (isLoading) => {
    return { type: SET_HOMEPAGE_CATS_LOADING, payload: isLoading };
};

export const setIsScrolling = (updatedIsScrolling) => {
    return { type: SET_IS_SCROLLING, payload: updatedIsScrolling };
};

export const setSearchCategory = (updatedSearchCategory) => {
    return { type: SET_SEARCH_CATEGORY, payload: updatedSearchCategory };
};

export const toggleNavigationBarWidth = (isCollapsed) => {
    return { type: TOGGLE_NAVIGATION_BAR_WIDTH, payload: isCollapsed };
};

export const setDarkMode = (isDarkMode) => {
    return { type: SET_DARK_MODE, payload: isDarkMode };
};

export const setShowAddToNewNamedCartDialogue = (isOpen) => {
    return { type: SET_SHOW_ADD_TO_NEW_NAMED_CART_DIALOGUE, payload: isOpen };
};

export const setShowCreateNameCartDialogue = (config) => {
    return { type: SET_SHOW_CREATE_NAME_CART_DIALOGUE, payload: config };
};

export const setShowReceiptSummaryDialogue = (isOpen) => {
    return { type: SET_SHOW_RECEIPT_SUMMARY_DIALOGUE, payload: isOpen };
};

export const setShowManageCartDialogue = (isOpen) => {
    return { type: SET_SHOW_MANAGE_CART_DIALOGUE, payload: isOpen };
};

export const setShowNonCatalogueDialogue = (isOpen) => {
    return { type: SET_SHOW_NON_CATALOGUE_DIALOGUE, payload: isOpen };
};

export const setShowApprovalsDialogue = (config) => {
    return { type: SET_SHOW_APPROVALS_DIALOGUE, payload: config };
};

export const setUserInfoLoading = (isLoading) => {
    return { type: SET_USER_INFO_LOADING, payload: isLoading };
};

export const setActiveSmartForm = (activeSmartForm) => {
    return { type: SET_ACTIVE_SMART_FORM, payload: activeSmartForm };
};

export const setShowRequisitionDialogue = (isOpen) => {
    return { type: SET_SHOW_REQUISITION_DIALOGUE, payload: isOpen };
};

export const setShowShoppingCartSummaryPopper = (isOpen) => {
    return {
        type: SET_SHOW_SHOPPING_CART_SUMMARY_POPPER,
        payload: isOpen,
    };
};

export const setShowBatchChangeReqOwnerDialogue = (isOpen) => {
    return {
        type: SET_SHOW_BATCH_REQ_OWNER_CHANGE_DIALOGUE,
        payload: isOpen,
    };
};

export const setShowUserInfo = (isOpen) => {
    return {
        type: SET_SHOW_USER_INFO,
        payload: isOpen,
    };
};

export const setShowReceiptDialogue = (isOpen) => {
    return {
        type: SET_SHOW_RECEIPTING_DIALOGUE,
        payload: isOpen,
    };
};

export const setShowSmartFormsDialogue = (isOpen) => {
    return {
        type: SET_SHOW_SMART_FORMS_DIALOGUE,
        payload: isOpen,
    };
};

export const updateActiveCart = (newActiveCart) => {
    return { type: UPDATE_ACTIVE_CART, payload: newActiveCart };
};

export const DecrementApprovalsCount = () => {
    return { type: DECREMENT_APPROVALS_COUNT };
};

export const SetApprovalsCount = (approvalsCount) => {
    return { type: SET_APPROVALS_COUNT, payload: approvalsCount };
};

export const setReceiptsCount = (receiptCount) => {
    return { type: SET_RECEIPTS_COUNT, payload: receiptCount };
};

export const setShowDisputeDialogue = (isOpen) => {
    return { type: SET_SHOW_DISPUTE_DIALOGUE, payload: isOpen };
};

export const setShowCancelRequisitionDialogue = (isOpen) => {
    return { type: SET_SHOW_CANCEL_REQUISITION_DIALOGUE, payload: isOpen };
};

export const toggleSpeedDialPlacement = (speedDial) => {
    return { type: TOGGLE_SPEED_DIAL_PLACEMENT, payload: speedDial };
};
