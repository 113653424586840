import { getCartItemInternalExternalStatus } from "services/cart/cartAPIs";

import {
    DEFAULT_CART_NAME,
    FAVOURITES_CART_NAME,
} from "../constants/generalConstants";
import { prepareReqFromCart } from "./createRequisitionUtils";

export const invalidCartNames = (shoppingCartState) => {
    /* Any existing cart name is invalid.
    Arguments
    ---------
    shoppingCartState : obj

    Returns
    -------
    protected cart names : array
    */
    return Object.keys(shoppingCartState).reduce(
        (names, key) => {
            names.push(shoppingCartState[key].name.toLowerCase());
            return names;
        },
        [
            DEFAULT_CART_NAME.toLowerCase(),
            FAVOURITES_CART_NAME.toLowerCase(),
            "",
        ]
    );
};

export const totalCost = (items) => {
    /* 
    Arguments
    ---------
    items : array
    
    Return
    ------
    total cost : float
    */
    const cost = items.reduce(
        (acc, { QUANTITY, UNIT_PRICE }) => acc + QUANTITY * UNIT_PRICE,
        0
    );
    return Math.round(cost * 100) / 100;
};

export const totalQuantity = (items) => {
    /* 
    Arguments
    ---------
    items : array
    */
    return items.reduce(
        (acc, { QUANTITY }) => parseFloat(acc) + parseFloat(QUANTITY),
        0
    );
};

export const createRequisition = (cart, dispatch) => {
    /*
    Arguments
    ---------
    cart : obj
    dispatch : func
    */
    getCartItemInternalExternalStatus(cart?.items, dispatch);
    prepareReqFromCart(cart, dispatch);
};

export const inItems = (PO_LINE_ID, items) => {
    /*
    Arguments
    ---------
    productId : uuid
    items : array
    */
    return items.some((item) => item.PO_LINE_ID == PO_LINE_ID);
};

export const quantityOptions = (min, max) => {
    /*
    Arguments
    ---------
    min : integer
    max : integer

    Returns
    -------
    array
    */
    const options = [];
    for (var index = min; index <= max; index++) {
        options.push(index.toString());
    }
    return options;
};

export const amendedCartItems = (items, actionType, payload) => {
    /* This function should is called anytime an ibuy cart item is updated.
    Arguments
    ---------
    items : array
    actionType : obj

    Returns
    -------
    array

    Notes
    -----
    "ADD" and "UPDATE_QUANTITY" performing similar quantity management functions.
    This is intended. "ADD" should be used by the iBuy add to cart button - it
    can only increment the quantity. ""UPDATE_QUANTITY", should be used to directly
    change the quantity of items already carts to any value. "UPDATE_CART_ITEM"
    should be used to replace an entire cart item.

    This function should be seen as a wrapper function for `addCartItem`, `removeCartItem'
    and `updateCartItemQuantity`. These functions should not be called directly.

    When adding a BPA (catalogue item to a cart) the full item is stored in
    the redux cart. If the cart is saved as a named cart, only relevant backend
    IDs are stored in cosmos database.
    */
    switch (actionType) {
        case "ADD":
            return addCartItem(items, payload);
        case "REMOVE":
            return removeCartItem(items, payload);
        case "UPDATE_QUANTITY":
            return updateCartItemQuantity(items, payload);
        case "UPDATE_CART_ITEM":
            return updateCartItem(items, payload);
        default:
            console.warn("Invalid action type input (updateCartItems utility)");
    }
};

export const addCartItem = (items, { newItem, qtyIncrement }) => {
    /* Called via the `updateCartItems` utils
    Arguments
    ---------
    items : array
    newItem : obj

    Returns
    -------
    array
    */
    return items.some((item) => item.PO_LINE_ID === newItem.PO_LINE_ID)
        ? // Item already in cart: update quantity
          items.map((item) =>
              item.PO_LINE_ID === newItem.PO_LINE_ID
                  ? {
                        ...item,
                        QUANTITY: item.QUANTITY + 1,
                    }
                  : item
          )
        : // Item not in cart: add to items array
          [...items, { ...newItem, QUANTITY: qtyIncrement }];
};

export const removeCartItem = (items, poLineItemToBeDeleted) => {
    /* Called via the `updateCartItems` utils
    Arguments
    ---------
    items : array
    poLineItemToBeDeleted : integer

    Returns
    -------
    array
    */
    return items.filter((item) => item.PO_LINE_ID != poLineItemToBeDeleted);
};

export const updateCartItemQuantity = (
    items,
    { poLineItemToUpdate, newQuantity }
) => {
    /* Called via the `updateCartItems` utils
    Arguments
    ---------
    items : array
    poLineItemToUpdate : integer
    newQuantity : integer

    Returns
    -------
    array
    */

    return items.map((item) =>
        // If item ID matches target ID, update quantity state
        item.PO_LINE_ID === poLineItemToUpdate
            ? { ...item, QUANTITY: newQuantity }
            : item
    );
};

export const updateCartItem = (items, newLineItem) => {
    /* Called via the `updateCartItems` utils
    Arguments
    ---------
    items : array
    newLineItem : obj

    Returns
    -------
    array
    */

    return items.map((item) =>
        // If item ID matches target ID, update quantity state
        item.PO_LINE_ID === newLineItem.PO_LINE_ID ? newLineItem : item
    );
};

export const getFavouritesId = (cartsArray) => {
    /*
    Arguments
    ---------
    shoppingCartState : obj
    
    Returns
    -------
    str
    */
    const matchArray = cartsArray.filter(
        (cart) => cart.name == [FAVOURITES_CART_NAME]
    );
    return matchArray.length == 0 ? null : matchArray[0].id;
};
