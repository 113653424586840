import SingleRequisitionSummary from "../../requisitions/singleRequisitionSummary/singleRequisitionSummary";
import { SingleRequisitionSummaryProvider } from "../../requisitions/singleRequisitionSummary/singleRequisitionSummaryContext";

const SingleRequisitionSummaryPage = () => {
    return (
        <SingleRequisitionSummaryProvider>
            <SingleRequisitionSummary />
        </SingleRequisitionSummaryProvider>
    );
};

export default SingleRequisitionSummaryPage;
