import Box from "@mui/material/Box";
import ProcurementAdmin from "components/procurementAdmin/procurementAdmin";
import SmartFormDetails from "components/smartFormDetails/smartFormDetails";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import alertDispatcher from "utils/alertsUtils";

import Approvals from "./components/approvals/approvals";
import InvoiceSummaryPage from "./components/invoiceSummary/invoiceSummaryPage";
import PageNotFound from "./components/pageNotFound/pageNotFound";
import Homepage from "./components/pages/homepage/homepage";
import SingleRequisitionSummary from "./components/pages/singleRequisitionSummary/singleRequisitionSummary";
import PoSummaryPage from "./components/poSummary/poSummaryPage";
import ProductDetails from "./components/productDetails/productDetails";
import ProductResults from "./components/productList/productResults";
import ReceiptSummary from "./components/receiptSummary/receiptSummary";
import RequisitionsSummary from "./components/requisitions/requisitionListPage/requisitionsSummary";
import { RequisitionsSummaryProvider } from "./components/requisitions/requisitionListPage/requisitionsSummaryContext";
import ShoppingCart from "./components/shoppingCart/shoppingCart";
import {
    IS_PROC_MANAGER_REDUX_ROLE,
    REQ_SUMMARY_TABLE,
} from "./constants/generalConstants";
import * as routes from "./constants/routes";
import { contentBoxL2 } from "./styled/styledSXProperties/styledBoxSX";

const ProtectedRoute = ({ entryCondition, children }) => {
    const dispatch = useDispatch();
    if (!entryCondition) {
        alertDispatcher(
            "protectedRoute",
            "403",
            dispatch,
            "You do not have permission to access this page"
        );
        return <Navigate to="/notFound" replace />;
    }
    return children;
};

const IBuyRouter = () => {
    const isProcManager = useSelector((state) =>
        IS_PROC_MANAGER_REDUX_ROLE.includes(state.auth.role)
    );
    return (
        <Routes>
            <Route path={routes.HOMEPAGE} element={<Homepage />} />
            <Route path={routes.PRODUCT_RESULTS} element={<ProductResults />} />
            <Route path={routes.CART_ROUTE_SETTER} element={<ShoppingCart />} />
            <Route
                path={routes.REQUISITIONS}
                element={
                    <RequisitionsSummaryProvider>
                        <RequisitionsSummary mode={REQ_SUMMARY_TABLE} />
                    </RequisitionsSummaryProvider>
                }
            />
            <Route
                path={routes.REQUISITION_SUMMARY}
                element={<SingleRequisitionSummary />}
            />
            <Route path={routes.APPROVALS} element={<Approvals />} />
            <Route path={routes.PRODUCT_DETAILS} element={<ProductDetails />} />
            <Route
                path={routes.SMART_FORM_DETAILS}
                element={<SmartFormDetails />}
            />

            <Route
                path={routes.INVOICE_SUMMARY_SETTER}
                element={<InvoiceSummaryPage />}
            />
            <Route
                path={routes.PO_SUMMARY_SETTER}
                element={<PoSummaryPage />}
            />
            <Route path={routes.RECEIPTS} element={<ReceiptSummary />} />
            <Route
                path={routes.PROCUREMENT_MANAGER_ADMIN}
                element={
                    <ProtectedRoute entryCondition={isProcManager}>
                        <ProcurementAdmin />
                    </ProtectedRoute>
                }
            />
            <Route
                path="*"
                element={
                    <Box sx={contentBoxL2}>
                        <PageNotFound />
                    </Box>
                }
            />
        </Routes>
    );
};

export default IBuyRouter;
