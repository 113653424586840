import { Divider, Grid, Stack, Typography } from "@mui/material";
import CurrencyFigure from "components/displays/currencyFigure";
import DetailListVertical from "components/displays/detailListVertical";
import RequisitionStatusBadge from "components/displays/status/requisitionStatusBadge";
import { CANCELLED } from "constants/generalConstants";
import PropTypes from "prop-types";
import { isReqLineCancelled } from "utils/cancelRequisitionUtils";

import ReceiptLines from "./receiptLines/receiptLines";

const RequisitionLine = ({ reqLine }) => {
    const isCancelled = isReqLineCancelled(reqLine);
    const opacity = isCancelled ? 0.5 : 1;
    return (
        <Grid container spacing={1} sx={{ opacity }}>
            <Grid item xs={12}>
                <Divider textAlign="right">
                    <RequisitionStatusBadge
                        status={
                            isCancelled ? CANCELLED : reqLine?.RECEIPTING_STATUS
                        }
                    />
                </Divider>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">
                    {reqLine.ITEM_DESCRIPTION}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                    <Typography variant="body1">
                        <CurrencyFigure
                            value={reqLine?.unitPriceBaseCurrency}
                            code={reqLine?.ouBaseCurrency}
                        />
                    </Typography>
                    <Typography variant="body1">
                        {reqLine.UNIT_OF_MEASURE_DESCRIPTION}
                    </Typography>
                </Stack>
            </Grid>

            <Grid item xs={12}>
                <DetailListVertical
                    details={[
                        { header: "Total Ordered", content: reqLine.QUANTITY },
                        {
                            header: "Total Received",
                            content: reqLine.total_received,
                        },
                        {
                            header: "Total Invoiced",
                            content: reqLine.QUANTITY_INVOICED,
                        },
                    ]}
                />
            </Grid>

            <Grid item xs={12}>
                {reqLine.receiptLines && reqLine.receiptLines.length > 0 ? (
                    <ReceiptLines receiptLines={reqLine.receiptLines} />
                ) : (
                    <Typography variant="body1" color="text.secondary">
                        No receipt lines
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};

RequisitionLine.propTypes = {
    reqLine: PropTypes.object.isRequired,
};

export default RequisitionLine;
