import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import OnDesktop from "components/wrappers/onDesktop";
import OnMobile from "components/wrappers/onMobile";
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StyledPopperArrow from "styled/styledComponents/styledPopperArrow";

import { setShowUserInfo } from "../../../redux/actions/generalActions";
import StyledPopper from "../../../styled/styledComponents/styledPopper";
import GenericModalContainer from "../containers/genericModalContainer";
import { popperSX } from "./../../../styled/styledSXProperties/styledPaperSX";
import { clickAway, styledPopperModifiers } from "./../../../utils/popperUtils";
import UserProfileContent from "./userProfileContent";

// eslint-disable-next-line no-undef
export const VERSION = GLOBAL_CONFIG.version;
// eslint-disable-next-line no-undef
export const RELEASE_DATE = GLOBAL_CONFIG.releaseDate;

const UserPopper = ({ userIconRef }) => {
    const showUserInfo = useSelector((state) => state.general.showUserInfo);
    const dispatch = useDispatch();

    const [arrowRef, setArrowRef] = useState(null);

    const closeDialogue = () => {
        dispatch(setShowUserInfo(false));
    };

    return (
        <>
            <OnDesktop>
                <ClickAwayListener
                    onClickAway={(event) =>
                        clickAway(event, userIconRef, dispatch, setShowUserInfo)
                    }
                >
                    <StyledPopper
                        anchorEl={userIconRef.current}
                        arrow
                        disablePortal={false}
                        modifiers={styledPopperModifiers(arrowRef)}
                        open={showUserInfo}
                        placement="bottom-end"
                    >
                        <>
                            <StyledPopperArrow
                                className="MuiPopper-arrow"
                                ref={setArrowRef}
                            />
                            <Paper
                                sx={{
                                    ...popperSX,
                                    alignItems: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    minWidth: "215px",
                                    padding: "15px",
                                }}
                            >
                                <UserProfileContent />
                            </Paper>
                        </>
                    </StyledPopper>
                </ClickAwayListener>
            </OnDesktop>
            <OnMobile>
                <GenericModalContainer
                    title={"User Profile"}
                    isOpen={showUserInfo}
                    closeModal={closeDialogue}
                >
                    <UserProfileContent />
                </GenericModalContainer>
            </OnMobile>
        </>
    );
};

UserPopper.propTypes = {
    userIconRef: PropTypes.object,
};

export default UserPopper;
