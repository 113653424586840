/* This module contains methods to preset the local states of 
./nonCatalogueForm in its different operating contexts. Note
these setters should only be used to set states synchronously.
Any states relying on asynchronous code should be managed either
in redux or in useEffect statements in the ./nonCatalogueForm
*/
import {
    cpaDefined,
    supplierDefined,
    supplierOrCpa,
} from "../../../utils/nonCatFormUtils";
import {
    CPA,
    EDIT_NON_CAT_CART_ITEM,
    EDIT_NON_CAT_REQ_LINE,
    NEW_NON_CAT_ITEM,
    NEW_SMART_FORM_ITEM,
    SUPPLIER,
    TYPE_OPTIONS,
} from "./../../../constants/generalConstants";
import { min3CharactersText, startNewSearchText } from "./nonCatalogueForm";

const supplierSiteDefined = (product) => {
    /*
    Arguments
    ----------
    product : obj
    */
    return !!product?.SUPPLIER_SITE_ID && !!product?.SUPPLIER_SITE_CODE;
};

const isEditItem = (mode) => {
    /*
    Arguments
    ----------
    mode : str
    */
    return (
        mode == EDIT_NON_CAT_CART_ITEM ||
        mode == EDIT_NON_CAT_REQ_LINE ||
        mode == NEW_SMART_FORM_ITEM
    );
};

const getContactNameFromContacts = (contacts, contactId) => {
    /*
    Arguments
    ----------
    product : obj
    contactId : str
    */
    return contacts.find((contact) => contact.SUPPLIER_CONTACT_ID == contactId)
        ?.title;
};

const getContactNumberFromContacts = (contacts, contactId) => {
    /*
    Arguments
    ----------
    product : obj
    contactId : str
    */
    return contacts.find((contact) => contact.SUPPLIER_CONTACT_ID == contactId)
        ?.PHONE;
};

const supplierContactDefined = (product) => {
    /*
    Arguments
    ----------
    product : obj
    */
    return (
        !!product?.SUGGESTED_VENDOR_CONTACT_ID ||
        !!product?.SUGGESTED_VENDOR_PHONE ||
        !!product?.CONTACT_NAME
    );
};

export const initialCategoryInfo = (mode, product, setValue) => {
    switch (true) {
        case mode == NEW_NON_CAT_ITEM:
            return null;
        case isEditItem(mode):
            // Category should always be defined.
            if (product?.CATEGORY_DISPLAY_NAME) {
                setValue("cat", product?.CATEGORY_DISPLAY_NAME);
                return {
                    CATEGORY_ID: product?.CATEGORY_ID,
                    CATEGORY: product?.CATEGORY,
                    CATEGORY_DISPLAY_NAME: product?.CATEGORY_DISPLAY_NAME,
                };
            } else {
                return null;
            }
        default:
            console.error("Invalid initial category info state set");
            return null;
    }
};

export const initialUOMValue = (mode, product, setValue) => {
    switch (true) {
        case mode == NEW_NON_CAT_ITEM:
            return null;
        case isEditItem(mode):
            // Unit of measure should always be defined.
            setValue("uom", product?.UNIT_OF_MEASURE);
            return {
                UNIT_MEAS_LOOKUP_CODE: product?.UNIT_MEAS_LOOKUP_CODE,
                UNIT_OF_MEASURE: product?.UNIT_OF_MEASURE,
            };
        default:
            console.error("Invalid initial unit of measure value state set");
            return null;
    }
};

export const initialContactDisabled = (mode, product) => {
    switch (true) {
        case mode == NEW_NON_CAT_ITEM:
            return true;
        case isEditItem(mode):
            if (supplierOrCpa(product) == CPA) {
                return true;
            } else if (
                supplierOrCpa(product) == SUPPLIER &&
                supplierSiteDefined(product)
            ) {
                return false;
            } else {
                return true;
            }
        default:
            console.error("Invalid initial contact disabled state set");
            return null;
    }
};

export const initialContactInfo = (mode, product, contacts) => {
    switch (mode) {
        case NEW_NON_CAT_ITEM:
            return null;
        case EDIT_NON_CAT_CART_ITEM:
        case NEW_SMART_FORM_ITEM:
            return supplierContactDefined(product)
                ? {
                      SUPPLIER_CONTACT_ID: product?.SUGGESTED_VENDOR_CONTACT_ID,
                      PHONE: product?.SUGGESTED_VENDOR_PHONE,
                      title: product?.CONTACT_NAME,
                  }
                : null;
        case EDIT_NON_CAT_REQ_LINE:
            return supplierContactDefined(product)
                ? {
                      SUPPLIER_CONTACT_ID: product?.SUGGESTED_VENDOR_CONTACT_ID,
                      PHONE: product?.SUGGESTED_VENDOR_CONTACT_ID
                          ? getContactNumberFromContacts(
                                contacts,
                                product?.SUGGESTED_VENDOR_CONTACT_ID
                            )
                          : null,
                      title: product?.SUGGESTED_VENDOR_CONTACT_ID
                          ? getContactNameFromContacts(
                                contacts,
                                product?.SUGGESTED_VENDOR_CONTACT_ID
                            )
                          : null,
                  }
                : null;
        default:
            console.error("Invalid initial contact info state set");
            return null;
    }
};

export const initialItemType = (mode, product) => {
    switch (true) {
        case mode == NEW_NON_CAT_ITEM:
            return TYPE_OPTIONS[0];
        case isEditItem(mode):
            return product?.ITEM_TYPE;
        default:
            console.error("Invalid initial non catalogue type state set");
            return null;
    }
};

export const initialSitesDisabled = (mode, product) => {
    switch (true) {
        case mode == NEW_NON_CAT_ITEM:
            return true;
        case isEditItem(mode):
            // Sites should be enabled if supplier is defined
            if (supplierOrCpa(product) == CPA) {
                return true;
            } else if (
                supplierOrCpa(product) == SUPPLIER &&
                supplierDefined(product)
            ) {
                return false;
            } else {
                return true;
            }
        default:
            console.error("Invalid initial supplier sites state set");
            return null;
    }
};

export const initialSupplierNamesLoading = (mode) => {
    switch (true) {
        case mode == NEW_NON_CAT_ITEM:
            return false;
        case isEditItem(mode):
            return false;
        default:
            console.error("Invalid initial supplier name loading state set");
            return null;
    }
};

export const initialSupplierNameOptions = (mode, product) => {
    switch (true) {
        case mode == NEW_NON_CAT_ITEM:
            return [];
        case isEditItem(mode):
            // If supplier defined, preset options
            return supplierDefined(product) &&
                supplierOrCpa(product) == SUPPLIER
                ? [
                      {
                          SUPPLIER_ID: product?.SUPPLIER_ID,
                          SUPPLIER_NUMBER_NAME: product?.SUPPLIER_NUMBER_NAME,
                      },
                  ]
                : [];
        default:
            console.error("Invalid initial supplier name options state set");
            return null;
    }
};

export const initialSupplierNameTextInput = (mode, product) => {
    switch (true) {
        case mode == NEW_NON_CAT_ITEM:
            return "";
        case isEditItem(mode):
            // If supplier defined, fill out field
            return supplierDefined(product) &&
                supplierOrCpa(product) == SUPPLIER
                ? product?.SUPPLIER_NUMBER_NAME
                : "";
        default:
            console.error("Invalid initial supplier name text input state set");
            return null;
    }
};

export const initialSupplierNoOptionsText = (mode, product) => {
    switch (true) {
        case mode == NEW_NON_CAT_ITEM:
            return min3CharactersText;
        case isEditItem(mode):
            return supplierDefined(product)
                ? startNewSearchText
                : min3CharactersText;
        default:
            console.error("Invalid initial supplier name no options state set");
            return null;
    }
};

export const initialSupplierNameValue = (mode, product) => {
    switch (true) {
        case mode == NEW_NON_CAT_ITEM:
            return null;
        case isEditItem(mode):
            return supplierDefined(product)
                ? {
                      SUPPLIER_ID: product?.SUPPLIER_ID,
                      SUPPLIER_NUMBER_NAME: product?.SUPPLIER_NUMBER_NAME,
                  }
                : null;
        default:
            console.error(
                "Invalid initial supplier name value input state set"
            );
            return null;
    }
};

export const initialSupplierSiteValue = (mode, product) => {
    switch (true) {
        case mode == NEW_NON_CAT_ITEM:
            return null;
        case isEditItem(mode):
            return supplierSiteDefined(product) &&
                supplierOrCpa(product) == SUPPLIER
                ? {
                      SUPPLIER_SITE_ID: product?.SUPPLIER_SITE_ID,
                      SUPPLIER_SITE_CODE: product?.SUPPLIER_SITE_CODE,
                  }
                : null;
        default:
            console.error(
                "Invalid initial supplier sites value input state set"
            );
            return null;
    }
};

export const initialCpasValue = (mode, product) => {
    switch (true) {
        case mode == NEW_NON_CAT_ITEM:
            return null;
        case isEditItem(mode):
            return cpaDefined(product) &&
                (supplierOrCpa(product) == CPA || mode == NEW_SMART_FORM_ITEM)
                ? {
                      CPA_NUMBER: product?.CPA_NUMBER,
                      CPA_NUM_NAME: product?.CPA_NUM_NAME,
                      CURRENCY_CODE: product?.CURRENCY_CODE,
                  }
                : null;
        default:
            console.error("Invalid initial CPA value input state set");
            return null;
    }
};

export const initialCpasTextInput = (mode, product) => {
    switch (true) {
        case mode == NEW_NON_CAT_ITEM:
            return "";
        case isEditItem(mode):
            // If supplier defined, fill out field
            return cpaDefined(product) ? product?.CPA_NUM_NAME : "";
        default:
            console.error("Invalid initial supplier name text input state set");
            return null;
    }
};

export const initialCpasLoading = (mode) => {
    switch (true) {
        case mode == NEW_NON_CAT_ITEM:
            return false;
        case isEditItem(mode):
            return false;
        default:
            console.error("Invalid initial CPA loading state set");
            return null;
    }
};

export const initialCpasOptions = (mode, product) => {
    switch (true) {
        case mode == NEW_NON_CAT_ITEM:
            return [];
        case isEditItem(mode):
            // If supplier defined, preset options
            return cpaDefined(product)
                ? [
                      {
                          CPA_NUMBER: product?.CPA_NUMBER,
                          CPA_NUM_NAME: product?.CPA_NUM_NAME,
                      },
                  ]
                : [];
        default:
            console.error("Invalid initial cpa options state set");
            return null;
    }
};

export const initialCpasNoOptionsText = (mode, product) => {
    switch (true) {
        case mode == NEW_NON_CAT_ITEM:
            return min3CharactersText;
        case isEditItem(mode):
            return cpaDefined(product)
                ? startNewSearchText
                : min3CharactersText;
        default:
            console.error("Invalid initial CPA no options state set");
            return null;
    }
};

export const initialSupplierNamesDisabled = (mode, product) => {
    switch (true) {
        case mode == NEW_NON_CAT_ITEM:
            return false;
        case isEditItem(mode):
            if (supplierOrCpa(product) == CPA) {
                // Supplier defined - disable
                return true;
            } else {
                return false;
            }
        default:
            console.error("Invalid initial supplier names disabled state set");
            return null;
    }
};

export const initialCpasDisabled = (mode, product) => {
    switch (true) {
        case mode == NEW_NON_CAT_ITEM:
            return false;
        case isEditItem(mode):
            if (supplierOrCpa(product) == SUPPLIER) {
                // Supplier defined - disable
                return true;
            } else {
                return false;
            }
        default:
            console.error("Invalid cpa names disabled state set");
            return null;
    }
};
