import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CustomSlider from "components/customSlider";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
    MAX_LEAD_TIME,
    MAX_UNIT_PRICE,
    SEARCH_API_DELIMITER,
} from "../../../constants/generalConstants";
import {
    setLeadTimeLTEFilter,
    setUnitPriceRangeFilter,
} from "../../../redux/actions/filterPanelValues";
import FilterPanelAutoComplete from "../filterPanelAutocomplete";
import { checkBoxStyle } from "./productsFilterPanelDispatcher";

const FilterPanel = ({ searchParams, setSearchParams }) => {
    // Collect states and state dispatchers
    const filterLookups = useSelector((state) => state.filterPanelLookups);
    const leadTimeLTE = useSelector(
        (state) => state.filterPanelValues.leadTimeLTE
    );
    const unitPriceRange = useSelector(
        (state) => state.filterPanelValues.unitPriceRange
    );
    const lineTypes = useSelector((state) => state.filterPanelValues.lineTypes);
    const unitOfMeasures = useSelector(
        (state) => state.filterPanelValues.unitOfMeasures
    );
    const dispatch = useDispatch();

    const onUnitPriceSlide = (e_, newValue) => {
        /* 
        Arguments
        ---------
        event : obj
        newValue : array

        Returns
        -------
        string
        */
        dispatch(setUnitPriceRangeFilter(newValue));
    };

    const onUnitPriceSlideCommitted = (e_, newValue) => {
        /* 
        Arguments
        ---------
        event : obj
        newValue : array

        Returns
        -------
        string
        */
        // Set state instantly to improve slider responsiveness
        dispatch(setUnitPriceRangeFilter(newValue));
        // Delete params prior to resetting - required to prevent "param leakage"
        // as upper bound is not used in some cases
        searchParams.delete("unit_price_ge");
        searchParams.delete("unit_price_le");
        searchParams.set("unit_price_ge", newValue[0]);
        newValue[1] != MAX_UNIT_PRICE &&
            searchParams.set("unit_price_le", newValue[1]);
        setSearchParams(searchParams);
    };

    const processLeadTime = (value) => {
        /* 
        Arguments
        ---------
        value : int

        Returns
        -------
        obj
        */
        // If slider is set to max value, set query to null (unbounded)
        const query = value == MAX_LEAD_TIME ? null : value;
        const isBounded = query || query == 0;
        return { query, isBounded };
    };

    const onLeadTimeSlide = (_e, newValue) => {
        /* 
        Arguments
        ---------
        event : obj
        newValue : array
        */
        const { query, isBounded } = processLeadTime(newValue);
        dispatch(setLeadTimeLTEFilter(isBounded ? query : MAX_LEAD_TIME));
    };

    const onLeadTimeSlideCommitted = (_e, newValue) => {
        /* 
        Arguments
        ---------
        event : obj
        newValue : array
        */
        searchParams.delete("lead_time");
        const { query, isBounded } = processLeadTime(newValue);
        // If query bounded, set search params and state
        isBounded && searchParams.set("lead_time", query);
        setSearchParams(searchParams);
        // Set state instantly to improve slider responsiveness
        dispatch(setLeadTimeLTEFilter(isBounded ? query : MAX_LEAD_TIME));
    };

    const onLineTypeSelect = (_e, value) => {
        /* 
        Arguments
        ---------
        event : obj
        value : array
        */
        value.length > 0
            ? searchParams.set("line_type", value.join(SEARCH_API_DELIMITER))
            : searchParams.delete("line_type");
        setSearchParams(searchParams);
    };

    const onUnitMeasureSelect = (_e, value) => {
        /* 
        Arguments
        ---------
        event : obj
        value : array

        */
        if (value.length > 0) {
            const uomIds = value.reduce(
                (acc, uom) => [...acc, uom?.UNIT_MEAS_LOOKUP_CODE],
                []
            );
            searchParams.set("unit_measure", uomIds.join(SEARCH_API_DELIMITER));
        } else {
            searchParams.delete("unit_measure");
        }
        setSearchParams(searchParams);
    };

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="body1">
                    Unit Price: £{unitPriceRange[0].toLocaleString()} - £
                    {unitPriceRange[1].toLocaleString() +
                        `${unitPriceRange[1] == MAX_UNIT_PRICE ? "+" : ""}`}
                </Typography>
                <CustomSlider
                    getAriaLabel={(index) => "priceSliderThumb" + index}
                    color="secondary"
                    onChange={onUnitPriceSlide}
                    onChangeCommitted={onUnitPriceSlideCommitted}
                    max={MAX_UNIT_PRICE}
                    value={unitPriceRange}
                    size="small"
                    valueLabelDisplay="auto"
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">
                    Maximum Lead Time:{" "}
                    {leadTimeLTE + `${leadTimeLTE == MAX_LEAD_TIME ? "+" : ""}`}{" "}
                    days
                </Typography>
                <CustomSlider
                    getAriaLabel={(index) => "leadTimeSlider" + index}
                    color="secondary"
                    onChangeCommitted={onLeadTimeSlideCommitted}
                    onChange={onLeadTimeSlide}
                    max={MAX_LEAD_TIME}
                    value={leadTimeLTE}
                    size="small"
                    valueLabelDisplay="auto"
                />
            </Grid>
            <Grid item xs={12}>
                <FilterPanelAutoComplete
                    options={filterLookups.LINE_TYPE}
                    onChange={onLineTypeSelect}
                    checkBoxStyle={checkBoxStyle}
                    placeholder="Select Line Type..."
                    label="Line Type"
                    value={lineTypes}
                    controlled={true}
                />
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: "16px" }}>
                <FilterPanelAutoComplete
                    options={filterLookups.UNIT_OF_MEASURES}
                    onChange={onUnitMeasureSelect}
                    checkBoxStyle={checkBoxStyle}
                    placeholder="Select Unit of Measure..."
                    label="Unit of Measure"
                    value={unitOfMeasures}
                    controlled={true}
                    displayValue="UNIT_OF_MEASURE"
                    limit="50"
                    equalityTest={(option, value) =>
                        option.UNIT_MEAS_LOOKUP_CODE ==
                        value.UNIT_MEAS_LOOKUP_CODE
                    }
                />
            </Grid>
        </>
    );
};

FilterPanel.propTypes = {
    searchParams: PropTypes.object,
    setSearchParams: PropTypes.func,
};

export default FilterPanel;
