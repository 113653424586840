import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CopyTypography from "components/displays/copyTypography";
import FormatNameValue from "components/displays/formatNameValue";
import IBuyPaper from "components/displays/iBuyPaper";
import OnDesktop from "components/wrappers/onDesktop";
import OnMobile from "components/wrappers/onMobile";
import { NON_PROJECT_REQ, PROJECT_REQ } from "constants/generalConstants";
import PropTypes from "prop-types";
import { headerProps } from "styled/props/headerProps";
import { textProps } from "styled/props/textProps";
import { subTextStyle } from "styled/styledSXProperties/styledSubText";

import HeaderChips from "./headerChips";
import ReqActionButtons from "./reqActionButtons";

export const sourcingTextDispatcher = {
    INVENTORY: "SSE warehouse",
    VENDOR: "External supplier",
};

export const accountedAgainstDispatcher = {
    [PROJECT_REQ]: "Project Requisition",
    [NON_PROJECT_REQ]: "Non-Project Requisition",
};

const SingleRequisitionHeader = ({ req }) => {
    const TitleTypography = () => (
        <>
            <Typography {...headerProps}>iBuy Requisition:&nbsp;</Typography>
            <CopyTypography {...{ ...headerProps, mr: "10px" }}>
                {req.id}
            </CopyTypography>
        </>
    );
    return (
        <>
            {/* Title Bar */}
            <Grid item xs={12}>
                <IBuyPaper>
                    <OnDesktop>
                        <Grid item md={7} xl={7.5} container spacing={1}>
                            <Grid item xs={12} container flexDirection="row">
                                <TitleTypography />
                                <HeaderChips req={req} />
                            </Grid>
                            <Grid item xs={12} container>
                                <FormatNameValue
                                    name="Preparer"
                                    value={req?.submittedByName}
                                    variant={subTextStyle.variant}
                                    sx={{ mr: "16px" }}
                                />
                                <Typography {...textProps} sx={{ mr: "16px" }}>
                                    |
                                </Typography>
                                <FormatNameValue
                                    name="Operating Unit"
                                    value={req?.requisitionHeaderForm?.ORG_NAME}
                                    variant={subTextStyle.variant}
                                    sx={{ mr: "16px" }}
                                />
                                <Typography {...textProps} sx={{ mr: "16px" }}>
                                    |
                                </Typography>
                                <FormatNameValue
                                    name="Supplier"
                                    value={req.supplierName}
                                    variant={subTextStyle.variant}
                                />
                            </Grid>
                        </Grid>
                        <Grid item md={5} xl={4.5}>
                            <ReqActionButtons req={req} margin="8px" />
                        </Grid>
                    </OnDesktop>
                    <OnMobile>
                        <Grid container spacing={1}>
                            <Grid
                                item
                                xs={12}
                                sm={7}
                                container
                                flexDirection="row"
                            >
                                <TitleTypography />
                            </Grid>
                            <Grid item xs container flexDirection="row">
                                <HeaderChips req={req} />
                            </Grid>
                            <Grid container item xs={12} flexDirection="column">
                                <FormatNameValue
                                    name="Preparer"
                                    value={req?.submittedByName}
                                    variant={subTextStyle.variant}
                                    sx={{ mr: "16px" }}
                                />
                                <FormatNameValue
                                    name="Operating Unit"
                                    value={req?.requisitionHeaderForm?.ORG_NAME}
                                    variant={subTextStyle.variant}
                                    sx={{ mr: "16px" }}
                                />
                                <FormatNameValue
                                    name="Supplier"
                                    value={req.supplierName}
                                    variant={subTextStyle.variant}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ReqActionButtons req={req} />
                            </Grid>
                        </Grid>
                    </OnMobile>
                </IBuyPaper>
            </Grid>
        </>
    );
};

SingleRequisitionHeader.propTypes = { req: PropTypes.object };

export default SingleRequisitionHeader;
