import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import OnDesktop from "components/wrappers/onDesktop";
import OnMobile from "components/wrappers/onMobile";
import React from "react";

const RequisitionSummarySkeleton = () => {
    return (
        <Box sx={{ marginTop: "24px" }}>
            <Grid container spacing={3}>
                <OnMobile>
                    <Grid item xs={12}>
                        <Skeleton
                            aria-label="reqSummarySkeleton"
                            variant="rectangular"
                            height={30}
                            sx={{
                                borderRadius: "10px",
                                boxSizing: "border-box",
                            }}
                        />
                    </Grid>
                </OnMobile>

                <Grid item xs={12}>
                    <Skeleton
                        aria-label="reqSummarySkeleton"
                        variant="rectangular"
                        height={100}
                        sx={{
                            borderRadius: "10px",
                            boxSizing: "border-box",
                        }}
                    />
                </Grid>

                <OnMobile>
                    <Grid item xs={12}>
                        <Skeleton
                            aria-label="reqSummarySkeleton"
                            variant="rectangular"
                            height={30}
                            sx={{
                                borderRadius: "10px",
                                boxSizing: "border-box",
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Skeleton
                            aria-label="reqSummarySkeleton"
                            variant="rectangular"
                            height={150}
                            sx={{
                                borderRadius: "10px",
                                boxSizing: "border-box",
                            }}
                        />
                    </Grid>
                </OnMobile>

                <OnDesktop>
                    <Grid item container xs={9} spacing={2}>
                        {[...Array(5).keys()].map((idx) => (
                            <Grid item xs={12} key={idx}>
                                <Skeleton
                                    aria-label="reqSummarySkeleton"
                                    variant="rectangular"
                                    width="100%"
                                    height={100}
                                    sx={{
                                        borderRadius: "10px",
                                        boxSizing: "border-box",
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Grid
                        item
                        container
                        xs={3}
                        spacing={2}
                        alignContent="flex-start"
                    >
                        {[...Array(2).keys()].map((idx) => (
                            <Grid item xs={12} key={idx}>
                                <Skeleton
                                    aria-label="reqSummarySkeleton"
                                    variant="rectangular"
                                    width="100%"
                                    height={200}
                                    sx={{
                                        borderRadius: "10px",
                                        boxSizing: "border-box",
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </OnDesktop>
            </Grid>
        </Box>
    );
};

export default RequisitionSummarySkeleton;
