import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import BlueNameValue from "components/displays/blueNameValue";
import IBuyPaper from "components/displays/iBuyPaper";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { decimalNumberRegex } from "../../../../constants/patterns";
import {
    setReason,
    setReceiptLineCheck,
    setUpdatedQuantity,
} from "../../../../redux/actions/receiptSummaryActions";
import { freeTextFormProps } from "../../../../styled/props/textFieldProps";
import { receiptLimit } from "../../../../utils/createReceiptUtils";

const ReceiptCorrectionLine = ({
    checked,
    paidInvoiceExist,
    updatedQuantity,
    reason,
    lineIndex,
    rcvLine,
    errors,
    register,
    byAmount,
}) => {
    // Collect states and state dispatchers
    const supplierName = useSelector(
        (state) => state.receiptSummary.receipt?.SUPPLIER_NAME
    );

    const dispatch = useDispatch();

    const availableForCorrection = rcvLine?.AVAILABLE_FOR_CORRECTION;

    const paperProperties = () =>
        availableForCorrection
            ? {}
            : {
                  pointerEvents: "none",
                  opacity: 0.5,
              };

    const onCheck = (event) =>
        dispatch(setReceiptLineCheck(lineIndex, event.target.checked));

    const [, maxLimit] = receiptLimit(
        rcvLine?.QTY_RCV_TOLERANCE,
        rcvLine?.QUANTITY_ORDERED,
        rcvLine?.QUANTITY_RECEIVED
    );

    // Register text input for form validation (applied conditionally based on if the line is checked)
    const registerName = "receiptCorrection" + lineIndex;
    const { ref, ...correctionProps } = register(registerName, {
        onChange: (e) =>
            dispatch(setUpdatedQuantity(lineIndex, e.target.value)),
        required: checked && availableForCorrection ? "Required Field" : null,
        validate: (value) =>
            checked && availableForCorrection
                ? value != rcvLine?.QUANTITY_RECEIVED
                : true,
        max:
            checked && availableForCorrection && maxLimit
                ? {
                      value: maxLimit,
                      message: `Max permitted ${
                          byAmount ? "amount" : "quantity"
                      } exceeded (${maxLimit})`,
                  }
                : null,
        pattern:
            checked && availableForCorrection
                ? {
                      value: decimalNumberRegex,
                      message: "Invalid quantity input",
                  }
                : null,
    });

    const disabledLineTooltip = () =>
        !availableForCorrection
            ? "This receipt line is unavailable for corrections as its associated PO shipment is currently being receipted / corrected by another user - please try again in a few minutes"
            : "This receipt line is currently unavailable for corrections";

    const correctionHelperText = () => {
        if (checked && errors[registerName]?.type == "validate") {
            return "Correction quantity cannot match quantity received";
        } else if (checked && errors[registerName]?.message) {
            return errors[registerName]?.message;
        } else {
            return "";
        }
    };

    return (
        <Tooltip
            placement="top-start"
            title={availableForCorrection ? "" : disabledLineTooltip()}
        >
            <span>
                <IBuyPaper additionalSx={paperProperties()}>
                    <Grid container spacing={2}>
                        <Grid
                            container
                            item
                            lg={0.4}
                            md={0.5}
                            xs={2}
                            display="flex"
                            alignItems="center"
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={!availableForCorrection}
                                            checked={checked}
                                            onChange={onCheck}
                                            color="secondary"
                                        />
                                    }
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            container
                            lg={6.5}
                            md={8.5}
                            xs={10}
                            spacing={2}
                        >
                            <Grid item xs={6} md={3}>
                                <BlueNameValue
                                    detail={{
                                        header: "Item Description",
                                        content: rcvLine?.ITEM_DESCRIPTION,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <BlueNameValue
                                    detail={{
                                        header: "Supplier Name",
                                        content: supplierName,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <BlueNameValue
                                    detail={{
                                        header: byAmount
                                            ? "Amount Received"
                                            : "Quantity Received",
                                        content: rcvLine?.QUANTITY_RECEIVED,
                                    }}
                                />
                            </Grid>
                            {!byAmount && (
                                <Grid item xs={6} md={3}>
                                    <BlueNameValue
                                        detail={{
                                            header: "Unit of Measure",
                                            content:
                                                rcvLine?.UNIT_OF_MEASURE_DESCRIPTION,
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>

                        <Grid
                            item
                            lg={1.5}
                            md={3}
                            container
                            display="flex"
                            alignItems="center"
                        >
                            <TextField
                                {...freeTextFormProps}
                                disabled={!availableForCorrection}
                                variant="outlined"
                                required
                                value={updatedQuantity}
                                label={
                                    byAmount
                                        ? "Correct Amount"
                                        : "Correct Quantity"
                                }
                                placeholder={`Correct ${
                                    byAmount ? "amount" : "quantity"
                                }...`}
                                inputRef={ref}
                                {...correctionProps}
                                error={checked ? !!errors[registerName] : false}
                                helperText={correctionHelperText()}
                                sx={{ mt: "6px" }}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={3.5}
                            md={12}
                            container
                            display="flex"
                            alignItems="center"
                        >
                            <TextField
                                {...freeTextFormProps}
                                disabled={!availableForCorrection}
                                multiline
                                variant="outlined"
                                value={reason}
                                onChange={(e) =>
                                    dispatch(
                                        setReason(lineIndex, e.target.value)
                                    )
                                }
                                label="Reason for correction"
                                placeholder="Enter reason for correction..."
                                sx={{ mt: "6px" }}
                            />
                        </Grid>
                        {paidInvoiceExist && (
                            <Grid item xs={12}>
                                <Typography
                                    variant="body2"
                                    color="error"
                                    marginTop="-12px"
                                    marginBottom="-8px"
                                >
                                    Paid invoices exist for the purchase order
                                    shipment associated with this receipt line
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </IBuyPaper>
            </span>
        </Tooltip>
    );
};

ReceiptCorrectionLine.propTypes = {
    checked: PropTypes.bool,
    updatedQuantity: PropTypes.string,
    reason: PropTypes.string,
    paidInvoiceExist: PropTypes.bool,
    lineIndex: PropTypes.number,
    rcvLine: PropTypes.object,
    errors: PropTypes.object,
    register: PropTypes.func,
    byAmount: PropTypes.bool,
};

export default ReceiptCorrectionLine;
