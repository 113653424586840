import useMediaQuery from "@mui/material/useMediaQuery";

import { MOBILE_MEDIA_QUERY } from "../../constants/mediaQueries";

const OnMobile = ({ children }) => {
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    if (!isDesktop) return children;
};

export default OnMobile;
