import CardMedia from "@mui/material/CardMedia";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import noImage from "../../assets/noImage.png";
import { getImg } from "../../services/search/searchAPIs";

export const ReqLineImage = ({ ITEM_IMAGE_URL }) => {
    const [imgUrl, setImgUrl] = useState(noImage);

    useEffect(() => {
        getImg(ITEM_IMAGE_URL, setImgUrl);
    }, [ITEM_IMAGE_URL]);

    return (
        <CardMedia
            component="img"
            sx={{ borderRadius: "10px" }}
            src={imgUrl}
            alt="Req Line Item Image Thumbnail"
        />
    );
};

ReqLineImage.propTypes = {
    ITEM_IMAGE_URL: PropTypes.string,
};
