import { Typography } from "@mui/material";
import OnDesktop from "components/wrappers/onDesktop";
import OnMobile from "components/wrappers/onMobile";
import React from "react";
import { useSelector } from "react-redux";

import ReceiptLinesList from "./receiptLinesList";
import ReceiptLinesTable from "./receiptLinesTable";

const ReceiptLines = () => {
    const receiptLines = useSelector((state) => {
        const receiptLines = state.receiptSummary.receipt?.receiptLines;
        return receiptLines ? receiptLines : [];
    });

    return receiptLines.length === 0 ? (
        <Typography variant="body1">
            No receipt lines associated with this receipt
        </Typography>
    ) : (
        <>
            <OnDesktop>
                <ReceiptLinesTable receiptLines={receiptLines} />
            </OnDesktop>
            <OnMobile>
                <ReceiptLinesList receiptLines={receiptLines} />
            </OnMobile>
        </>
    );
};

export default ReceiptLines;
