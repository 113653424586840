import { MOBILE } from "../../constants/generalConstants";
import IBuySearch from "./iBuySearch";
import SearchBarBackground from "./searchBarBackground";

const SearchBarMobile = () => {
    return (
        <>
            <SearchBarBackground>
                <IBuySearch mode={MOBILE} />
            </SearchBarBackground>
        </>
    );
};
export default SearchBarMobile;
