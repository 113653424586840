import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { REQUISITION_SUMMARY_PAGE } from "constants/routes";
import { useState } from "react";
import { useNavigate } from "react-router";

import {
    TOOLTIP_PO_DISTRIBUTION_DESCRIPTION,
    TOOLTIP_PO_SHIPMENT_DESCRIPTION,
} from "../../constants/tooltipText";
import CurrencyFigure from "../displays/currencyFigure";
import PoShipmentLineStatusChip from "./poShipmentLineStatusChip";

const tableHeaderProps = {
    align: "center",
    color: "text.subheader",
    variant: "body1",
    fontWeight: "bold",
};

const subTableHeaderProps = {
    align: "center",
    color: "text.subheader",
    variant: "body1",
    fontWeight: "bold",
};

const PoDistributionRow = ({ poDistributionLine, currency }) => {
    const navigate = useNavigate();
    const linkToReqSummary = (reqLineRef) => {
        /* 
        Arguments
        ---------
        reqLineRef : str
        */
        const reqId = reqLineRef
            ? reqLineRef.substring(0, reqLineRef.length - 2)
            : null;
        navigate(REQUISITION_SUMMARY_PAGE(reqId));
    };
    return (
        <TableRow hover data-testid="distributionTableRow">
            <TableCell align="center" component="th" scope="row">
                {poDistributionLine?.DISTRIBUTION_NUM}
            </TableCell>
            <TableCell align="center">
                <Link
                    component="button"
                    disabled={!poDistributionLine?.IBUY_REQ_LINE_REF}
                    color="secondary"
                    onClick={() => {
                        linkToReqSummary(poDistributionLine?.IBUY_REQ_LINE_REF);
                    }}
                >
                    {poDistributionLine?.IBUY_REQ_LINE_REF}
                </Link>
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {poDistributionLine?.DISTRIBUTION_TYPE}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {poDistributionLine?.DST_DESTINATION_CONTEXT}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {poDistributionLine?.QUANTITY_ORDERED}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {poDistributionLine?.QUANTITY_BILLED}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {poDistributionLine?.QUANTITY_DELIVERED}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {poDistributionLine?.QUANTITY_CANCELLED}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {poDistributionLine?.EXPENDITURE_ITEM_DATE || "-"}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                <CurrencyFigure
                    value={poDistributionLine?.AMOUNT_BILLED || 0}
                    code={currency}
                />
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {poDistributionLine?.DELIVERED_TO_PERSON || "-"}
            </TableCell>
        </TableRow>
    );
};

const PoShipmentRow = ({ currency, unitPrice, poShipmentLine }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow hover>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        data-testid="poShipmentExpand"
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {poShipmentLine?.SHIPMENT_NUM}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {poShipmentLine?.SHIPMENT_TYPE}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {poShipmentLine?.LOC_SHIP_TO_LOCATION_CODE}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {poShipmentLine?.QUANTITY}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {poShipmentLine?.QUANTITY_RECEIVED}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {poShipmentLine?.QUANTITY_ACCEPTED}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {poShipmentLine?.QUANTITY_CANCELLED}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {poShipmentLine?.QUANTITY_REJECTED}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {poShipmentLine?.PROMISED_DATE || "-"}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {poShipmentLine?.NEED_BY_DATE}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    <CurrencyFigure
                        value={poShipmentLine?.QUANTITY * unitPrice}
                        code={currency}
                    />
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {poShipmentLine?.MATCHING_TYPE}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {poShipmentLine?.MATCH_OPTION == "R"
                        ? "Receipt"
                        : poShipmentLine?.MATCH_OPTION == "P"
                        ? "Purchase Order"
                        : "N/A"}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    <PoShipmentLineStatusChip
                        closedCode={poShipmentLine?.CLOSED_CODE}
                        cancelFlag={poShipmentLine?.CANCEL_FLAG}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={12}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Tooltip
                                title={TOOLTIP_PO_DISTRIBUTION_DESCRIPTION}
                                placement="top"
                                followCursor
                            >
                                <Typography
                                    fontWeight="bold"
                                    variant="body1"
                                    color="text.primary"
                                    sx={{ mt: "16px" }}
                                >
                                    Distributions associated with Shipment:{" "}
                                    {poShipmentLine?.id}
                                </Typography>
                            </Tooltip>
                            <Table
                                size="small"
                                aria-label="purchases"
                                sx={{ mb: "32px" }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography
                                                {...{
                                                    ...subTableHeaderProps,
                                                }}
                                            >
                                                Distribution Number
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...{
                                                    ...subTableHeaderProps,
                                                }}
                                            >
                                                Requisition Number
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Distribution Type
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Destination Context
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Quantity Ordered
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Quantity Billed
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Quantity Delivered
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Quantity Cancelled
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Expenditure Date
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Billed Amount
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Deliver To Person
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {poShipmentLine.poDist.map(
                                        (poDistributionLine, index) => (
                                            <PoDistributionRow
                                                key={
                                                    "dist" +
                                                    index +
                                                    poDistributionLine?.id
                                                }
                                                poDistributionLine={
                                                    poDistributionLine
                                                }
                                                currency={currency}
                                            />
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const PoRow = ({ poLine, currency }) => {
    const [open, setOpen] = useState(false);
    // const lines = [];
    return (
        <>
            <TableRow hover sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        data-testid="poLineExpand"
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                    {poLine?.LINE_NUM}
                </TableCell>
                <TableCell align="center">{poLine?.LINE_TYPE}</TableCell>
                <TableCell align="center">{poLine?.ITEM}</TableCell>
                <TableCell align="center">{poLine?.ITEM_DESCRIPTION}</TableCell>
                <TableCell align="center">
                    {poLine?.UNIT_MEAS_LOOKUP_CODE}
                </TableCell>
                <TableCell align="center">{poLine?.QUANTITY}</TableCell>
                <TableCell align="center">
                    <CurrencyFigure
                        value={poLine?.UNIT_PRICE}
                        code={currency}
                    />
                </TableCell>
                <TableCell align="center">{poLine?.CATEGORY}</TableCell>
                <TableCell align="center">
                    {poLine.BPA ? poLine.BPA : poLine?.CONTRACT}
                </TableCell>
                <TableCell align="center">
                    <PoShipmentLineStatusChip
                        closedCode={poLine?.CLOSED_CODE}
                        cancelFlag={poLine?.CANCEL_FLAG}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={12}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Tooltip
                                title={TOOLTIP_PO_SHIPMENT_DESCRIPTION}
                                placement="top"
                                followCursor={true}
                            >
                                <Typography
                                    fontWeight="bold"
                                    variant="body1"
                                    color="text.primary"
                                    sx={{ mt: "16px" }}
                                >
                                    Shipments associated with PO Line:{" "}
                                    {poLine?.PO_LINE_ID}
                                </Typography>
                            </Tooltip>
                            <Table
                                size="small"
                                aria-label="purchases"
                                sx={{ mb: "32px" }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>
                                            <Typography
                                                {...{
                                                    ...subTableHeaderProps,
                                                }}
                                            >
                                                Shipment Number
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Shipment Type
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Shipping Location
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Quantity Ordered
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Quantity Received
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Quantity Accepted
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Quantity Cancelled
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Quantity Rejected
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Promised Date
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Need By Date
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Amount
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Match Type
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Match Option
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Status
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {poLine.poLocations.map(
                                        (poShipmentLine, index) => (
                                            <PoShipmentRow
                                                key={index + poShipmentLine?.id}
                                                poShipmentLine={poShipmentLine}
                                                currency={currency}
                                                unitPrice={poLine.UNIT_PRICE}
                                            />
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
const PoLinesTable = ({ poLines, currency }) => {
    return (
        <TableContainer>
            <Table size="small" data-testid="poSummaryTable">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>
                            <Typography
                                {...{ ...tableHeaderProps, align: "center" }}
                            >
                                Line Number
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography {...tableHeaderProps}>
                                Line Type
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography {...tableHeaderProps}>Item</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography {...tableHeaderProps}>
                                Item Description
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography {...tableHeaderProps}>
                                Unit of Measure
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography {...tableHeaderProps}>
                                Quantity Ordered
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography {...tableHeaderProps}>Price</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography {...tableHeaderProps} align="center">
                                Category
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography {...tableHeaderProps} align="center">
                                Agreement Number
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography {...tableHeaderProps} align="center">
                                Status
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {poLines.map((poLine, index) => (
                        <PoRow
                            key={index}
                            poLine={poLine}
                            currency={currency}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PoLinesTable;
