import {
    addInvoices,
    addPOs,
    setReqLineLoadingState,
} from "../../redux/actions/singleReqSummaryActions";
import alertDispatcher from "../../utils/alertsUtils";
import { objectOnCondition } from "../../utils/general";
import axiosApiInstance from "../middleware/axios";
import { GET_RECEIPTS_BY_REQUISITION } from "../receipting/urls";
import {
    GET_CORPORATE_EXCHANGE_RATE,
    GET_INVOICES_BY_REQ_LINE_ID,
    GET_OR_CREATE_CCID,
    GET_PO_PDF_BY_PO_NUM,
    GET_POS_BY_REQ_LINE_ID,
    GET_POS_PER_REQUISITION,
    GET_SEGMENT_AUTOCOMPLETE,
} from "./urls";

var Buffer = require("buffer/").Buffer;

export const getPoPdf = async (id, setLoadingPoPdf, setURL, dispatch) => {
    /* 
    Arguments
    ---------
    id : str
    setURL: func
    setLoadingPoPdf : func
    */
    try {
        setLoadingPoPdf(true);
        const response = await axiosApiInstance.get(GET_PO_PDF_BY_PO_NUM(id), {
            responseType: "arraybuffer",
        });
        let base64ImageString = Buffer.from(response.data, "binary").toString(
            "base64"
        );
        setURL("data:application/pdf;base64," + base64ImageString);
    } catch (error) {
        setLoadingPoPdf(false);
        alertDispatcher("getPoPdf", error?.response?.status, dispatch);
    }
};

/**
 * Retrieves PO information for whole requisition.
 * @param {*} reqId - requisition ID
 * @param {*} dispatch - redux dispatch function
 */
export const getPosPerRequisition = async (reqId, dispatch) => {
    try {
        const response = await axiosApiInstance.get(
            GET_POS_PER_REQUISITION(reqId)
        );
        return response.data;
    } catch (error) {
        alertDispatcher("getPosPerRequisition", 500, dispatch);
        return null;
    }
};

export const getInvoicesPOsReceiptsPerReqLine = (
    reqLineId,
    reqId,
    setInvoiceIds,
    setIsLoading,
    setPoIds,
    setReceiptByReqLine,
    singleReqSummaryPage = false,
    dispatch = null
) => {
    /* 
    Arguments
    ---------
    reqLineId : str
    reqId : str
    setInvoiceIds: func
    setIsLoading : func
    setPoIds : func
    setReceiptByReqLine : func
    singleReqSummaryPage : bool
    dispatch : func

    Notes
    -----
    This function has a two use cases: the requisition summary
    table and the single requisition summary page (in requisition
    summary lines accordion). The receiptPromise is not required for
    the single requisition summary page and is excluded from 
    `promisesToAwait` when `singleReqSummaryPage=true`. 
    */
    const invoicePromise = axiosApiInstance
        .get(GET_INVOICES_BY_REQ_LINE_ID(reqLineId))
        .then((response) => {
            if (response.data) {
                const invoiceIds = response.data.map((line) => {
                    return {
                        INVOICE_ID: line?.INVOICE_ID,
                        INVOICE_AMOUNT: line?.INVOICE_AMOUNT
                            ? line?.INVOICE_AMOUNT
                            : 0,
                        AMOUNT_PAID: line?.AMOUNT_PAID ? line?.AMOUNT_PAID : 0,
                        INVOICE_NUM: line?.INVOICE_NUM,
                    };
                });
                setInvoiceIds(invoiceIds);
                singleReqSummaryPage && dispatch(addInvoices(invoiceIds));
            }
        })
        .catch(() => {});

    const poPromise = axiosApiInstance
        .get(GET_POS_BY_REQ_LINE_ID(reqLineId))
        .then((response) => {
            if (response.data) {
                const POsStaged = response.data.reduce((acc, PO) => {
                    const mappedPOLinesCleansed = PO?.poLines
                        ? PO?.poLines
                        : [];
                    const mappedPOLines = mappedPOLinesCleansed.map((line) => {
                        var totalInvoiced = 0;
                        for (let loc of line.poLocations) {
                            totalInvoiced +=
                                loc.QUANTITY_BILLED * line.UNIT_PRICE;
                        }
                        return {
                            PO_NUMBER: line?.PO_NUMBER,
                            APPROVED_FLAG: PO?.APPROVED_FLAG,
                            AUTHORIZATION_STATUS: PO?.AUTHORIZATION_STATUS,
                            INVOICED_AMOUNT: totalInvoiced,
                            AGENT_NAME: PO?.AGENT_NAME,
                            CLOSED_CODE: PO?.CLOSED_CODE,
                            CANCEL_FLAG: PO?.CANCEL_FLAG,
                        };
                    });
                    return [...acc, ...mappedPOLines];
                }, []);
                const distinctPOs = [
                    ...new Map(
                        POsStaged.map((poDetail) => [
                            poDetail["PO_NUMBER"],
                            poDetail,
                        ])
                    ).values(),
                ];
                singleReqSummaryPage && dispatch(addPOs(distinctPOs));
                setPoIds(distinctPOs);
            }
        })
        .catch(() => {});

    let receiptPromise;
    if (!singleReqSummaryPage) {
        receiptPromise = axiosApiInstance
            .get(GET_RECEIPTS_BY_REQUISITION(reqId))
            .then((response) => {
                const receiptData = response.data.reduce((acc, reqLine) => {
                    return {
                        ...acc,
                        [reqLine.ibuy_req_line_ref]: {
                            total_received: reqLine?.total_received,
                            QUANTITY_INVOICED: reqLine?.QUANTITY_INVOICED,
                            amountReceivedBaseCurrency:
                                reqLine?.amountReceivedBaseCurrency,
                            ouBaseCurrency: reqLine?.ouBaseCurrency,
                        },
                    };
                }, {});
                setReceiptByReqLine(receiptData);
            })
            .catch(() => {});
    }
    // Conditionally determine which promises much be awaited
    const promisesToAwait = singleReqSummaryPage
        ? [invoicePromise, poPromise]
        : [invoicePromise, poPromise, receiptPromise];

    Promise.all(promisesToAwait).then(() => {
        setIsLoading(false);
        singleReqSummaryPage &&
            dispatch(setReqLineLoadingState(reqLineId, false));
    });
};

/**
 * @param {number} segmentId
 * @param {string} searchTerm
 * @returns {Promise<Object[]>}
 */
export const segmentAutocompleteGet = async (segmentId, searchTerm) => {
    const requestUrl = GET_SEGMENT_AUTOCOMPLETE(segmentId, searchTerm);
    const response = await axiosApiInstance.get(requestUrl);
    return response.data;
};

/**
 * @typedef {Object} SegmentIds
 * @property {string | undefined} SEGMENT1_ID
 * @property {string | undefined} SEGMENT2_ID
 * @property {string | undefined} SEGMENT3_ID
 * @property {string | undefined} SEGMENT4_ID
 */

/**
 * @typedef {Object} GetOrCreateCCIDResponse
 * @property {number} CHARGE_ACCOUNT_ID
 */

/**
 * @param {SegmentIds} segmentIds
 * @param {string} operatingUnit
 * @returns {Promise<GetOrCreateCCIDResponse>}
 */
export const getOrCreateCCID = async (segmentIds, operatingUnit) => {
    const requestUrl = GET_OR_CREATE_CCID();

    const response = await axiosApiInstance.post(
        requestUrl,
        {
            ...objectOnCondition(segmentIds.SEGMENT1_ID, {
                SEGMENT1_ID: segmentIds.SEGMENT1_ID,
            }),
            ...objectOnCondition(segmentIds.SEGMENT2_ID, {
                SEGMENT2_ID: segmentIds.SEGMENT2_ID,
            }),
            ...objectOnCondition(segmentIds.SEGMENT3_ID, {
                SEGMENT3_ID: segmentIds.SEGMENT3_ID,
            }),
            ...objectOnCondition(segmentIds.SEGMENT4_ID, {
                SEGMENT4_ID: segmentIds.SEGMENT4_ID,
            }),
            OPERATING_UNIT: operatingUnit,
        },
        {
            headers: {
                "Content-Type": "application/json",
            },
        }
    );
    return response.data;
};

/**
 * The JSON object returned from the "/corporate-exchange-rate" endpoint
 * @typedef {Object} GetExchangeRateResponse
 * @property {number} rate
 */

/**
 * API wrapper for the "/corporate-exchange-rate" endpoint
 * @param {string} fromCurrencyCode
 * @param {string} toCurrencyCode
 * @param {string} rateDate
 * @returns {Promise<GetExchangeRateResponse>}
 */
export const getApiExchangeRate = async (
    fromCurrencyCode,
    toCurrencyCode,
    rateDate
) => {
    return await axiosApiInstance.get(GET_CORPORATE_EXCHANGE_RATE(), {
        params: {
            from_code: fromCurrencyCode,
            to_code: toCurrencyCode,
            rate_date: rateDate,
        },
    });
};
