import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import OutlinedChip from "components/displays/outlinedChip";
import NonCatalogueFormSkeleton from "components/skeletons/nonCatalogueFormSkeleton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setInformationTemplatesLoading } from "redux/actions/nonCatFormActions";
import { closeSmartFormsDialogue } from "utils/smartFormsUtils";

import { MOBILE_MEDIA_QUERY } from "../../../constants/mediaQueries";
import axiosApiInstance from "../../../services/middleware/axios";
import { GET_INFORMATION_TEMPLATE } from "../../../services/search/urls";
import NonCatalogueForm from "../nonCatalogueForm/nonCatalogueForm";
import YesNoPrompt from "../prompts/yesNoPrompt";

const OUHelperText =
    "The operating unit of the smart-form. This limits the suppliers and CPAs available for selection. If you are a member of multiple OUs, you can switch by clicking your name on the search bar";

const SmartFormDialogue = () => {
    // Collect states and dispatchers
    const activeSmartForm = useSelector(
        (state) => state.general.activeSmartForm
    );
    const product = useSelector((state) => state.nonCatForm.product);
    const showSmartFormsDialogue = useSelector(
        (state) => state.general.showSmartFormsDialogue
    );
    const dispatch = useDispatch();

    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    const smartFormsDataLoading = useSelector(
        (state) => state.nonCatForm.smartFormsDataLoading
    );

    // Local states
    const [closeFormPromptOpen, setCloseFormPromptOpen] = useState(false);
    const [informationTemplate, setInformationTemplates] = useState([]);

    useEffect(() => {
        if (activeSmartForm) {
            dispatch(setInformationTemplatesLoading(true));
            axiosApiInstance
                .get(GET_INFORMATION_TEMPLATE(activeSmartForm?.TEMPLATE_ID))
                .then((response) => {
                    setInformationTemplates(response.data);
                    dispatch(setInformationTemplatesLoading(false));
                })
                .catch(() => {
                    setInformationTemplates([]);
                    dispatch(setInformationTemplatesLoading(false));
                });
        }
    }, [activeSmartForm]);

    const onClickClose = (e, reason) => {
        if (reason == "backdropClick") return;
        setCloseFormPromptOpen(true);
    };

    return (
        <Dialog
            maxWidth="xl"
            onClose={onClickClose}
            open={showSmartFormsDialogue}
            fullScreen={!isDesktop}
        >
            <DialogContent>
                <Grid container spacing={2} marginBottom="16px">
                    <Grid item xs={11}>
                        <Box display="flex" flexDirection="row">
                            <Typography variant="h3" marginBottom={"4px"}>
                                {`${
                                    activeSmartForm?.TEMPLATE_NAME ||
                                    "Smart Form"
                                }`}
                            </Typography>

                            <Tooltip title={OUHelperText} placement="top-start">
                                <>
                                    <OutlinedChip
                                        value={`Operating Unit: ${product?.IBUY_CART_ITEM_OU}`}
                                        size="small"
                                        marginLeft
                                    />
                                </>
                            </Tooltip>
                        </Box>
                        <Typography
                            variant="body1"
                            fontWeight="normal"
                            color="text.secondaryLight"
                        >
                            Complete the below form to add this smart form item
                            to your shopping cart. Some fields are
                            auto-populated and cannot be edited
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={1}
                        justifyContent="flex-end"
                        alignContent="center"
                    >
                        <IconButton
                            onClick={onClickClose}
                            aria-label="closeSmartFormDialogue"
                            sx={{ mt: "-25px" }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        {!smartFormsDataLoading ? (
                            <NonCatalogueForm
                                informationTemplate={informationTemplate}
                            />
                        ) : (
                            <Grid container spacing={2}>
                                <NonCatalogueFormSkeleton />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <YesNoPrompt
                title="Close smart-form?"
                subtitle="Closing this smart-form prior to clicking 'Add to Cart' will result in all entered data being lost. Are you sure you wish to close this form?"
                promptOpen={closeFormPromptOpen}
                setPromptOpen={setCloseFormPromptOpen}
                onClickYes={() => closeSmartFormsDialogue(dispatch)}
            />
        </Dialog>
    );
};

export default SmartFormDialogue;
