import {
    SSE_PRIMARY_DARK_BLUE_GRADIENT_FILL,
    SSE_TEAL_GRADIENT_FILL,
} from "themes/colours";

export const screenSX = (darkMode) => ({
    backgroundImage: darkMode
        ? "linear-gradient(to bottom right, #26262d, #26262dF5)"
        : "linear-gradient(to bottom right, #F0F2F5, #f5f3f0)",
    display: "flex",
    height: `var(--app-height)`,
    width: "100vw",
});

export const contentBoxL1 = {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflowY: "auto",
    padding: "0px 16px 16px 24px",
};

export const contentBoxL1Mobile = {
    ...contentBoxL1,
    padding: "0px 8px 8px 8px",
};

export const contentBoxL2 = {
    margin: "24px 0px 0px 0px",
    width: "100%",
};

export const homepageSmartFormBoxSX = (darkMode) => ({
    borderRadius: "10px",
    marginTop: "-24px",
    marginBottom: "14px",
    height: "64px",
    width: "64px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: darkMode
        ? SSE_TEAL_GRADIENT_FILL
        : SSE_PRIMARY_DARK_BLUE_GRADIENT_FILL,
});

export const reqLineImageIconBoxSX = (darkMode) => ({
    borderRadius: "10px",
    backgroundImage: darkMode
        ? SSE_TEAL_GRADIENT_FILL
        : SSE_PRIMARY_DARK_BLUE_GRADIENT_FILL,
    height: 0,
    width: "80%",
    paddingTop: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

export const singleReqSummaryLineNumberBox = (isCancelled, darkMode) => ({
    borderRadius: "10px",
    marginTop: "-24px",
    minWidth: "50px",
    height: "42px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: isCancelled
        ? "linear-gradient(to right bottom, #f44336, #f32c1e)"
        : darkMode
        ? "linear-gradient(to right bottom, #0097A9, #4CB6C2)"
        : SSE_PRIMARY_DARK_BLUE_GRADIENT_FILL,
    padding: "0px 10px",
});
