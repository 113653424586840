import Chip from "@mui/material/Chip";
import PropTypes from "prop-types";

const OutlinedChip = ({
    value,
    marginLeft = false,
    marginBottom = "0px",
    clickable = false,
    size = "small",
    onClick = null,
    marginRight = false,
}) => {
    return (
        <>
            {value ? (
                <Chip
                    label={value}
                    size={size}
                    variant="outlined"
                    clickable={clickable}
                    onClick={onClick || null}
                    sx={{
                        ml: marginLeft ? "8px" : "0px",
                        mr: marginRight ? "8px" : "0px",
                        color: "text.subheader",
                        borderColor: "text.subheader",
                        mb: marginBottom,
                        cursor: clickable ? "pointer" : "default",
                    }}
                />
            ) : (
                <></>
            )}
        </>
    );
};

OutlinedChip.defaultProps = {
    marginLeft: false,
    clickable: false,
    size: "small",
    marginBottom: "0px",
    onClick: null,
    marginRight: false,
};

OutlinedChip.propTypes = {
    value: PropTypes.string,
    marginLeft: PropTypes.bool,
};

export default OutlinedChip;
