import { SERVER_SEARCH_CATEGORIES } from "../../constants/snackbarMessage";
import { setLookups } from "../../redux/actions/filterPanelLookupsActions";
import { setHomepageCategoriesLoading } from "../../redux/actions/generalActions";
import { setHomepageCategories } from "../../redux/actions/homepageCategoriesActions";
import alertDispatcher from "../../utils/alertsUtils";
import noImage from "./../../assets/noImage.png";
import axiosApiInstance from "./../middleware/axios";
import {
    CPA_AUTOCOMPLETE_GET,
    GET_CATEGORIES,
    LOOKUPS,
    SEARCH_CATALOGUE_IMAGES_GET,
    SEARCH_CATALOGUE_ITEMS,
    SMART_FORM_SEARCH,
} from "./urls";

export const searchSmartFormItems = async (query, OU) => {
    /* 
    Arguments
    ---------
    query : string
    OU : string

    Returns
    -------
    array
    */
    try {
        const params = new URLSearchParams(query);
        params.set("OU", OU);
        let request = await axiosApiInstance.get(
            SMART_FORM_SEARCH + `?${params.toString()}`
        );
        return request?.data ? request.data : [];
    } catch (error) {
        console.error("Unable to retrieve search results");
        return [];
    }
};

export const searchCatalogueItems = async (query, OU) => {
    /* 
    Arguments
    ---------
    query : string
    OU : string

    Returns
    -------
    array
    */
    try {
        const params = new URLSearchParams(query);
        params.set("OU", OU);
        let request = await axiosApiInstance.get(
            SEARCH_CATALOGUE_ITEMS + `?${params.toString()}`
        );
        return request?.data ? request.data : [];
    } catch (error) {
        console.error("Unable to retrieve search results");
        return [];
    }
};

export const getFilterPanelLookUps = async (dispatch) => {
    /* 
    Arguments
    ---------
    dispatch : func
    */
    try {
        let request = await axiosApiInstance.get(LOOKUPS);
        dispatch(setLookups(request.data));
    } catch (error) {
        alertDispatcher(
            "searchFilters",
            error?.response?.status,
            dispatch,
            SERVER_SEARCH_CATEGORIES("filter categories")
        );
    }
};

export const getHomePageCategories = async (dispatch) => {
    /* 
    Arguments
    ---------
    dispatch : func
    */
    try {
        dispatch(setHomepageCategoriesLoading(true));
        let request = await axiosApiInstance.get(GET_CATEGORIES);
        dispatch(setHomepageCategories(request.data));
        dispatch(setHomepageCategoriesLoading(false));
    } catch (error) {
        alertDispatcher(
            "searchFilters",
            error?.response?.status,
            dispatch,
            SERVER_SEARCH_CATEGORIES("homepage categories")
        );
        dispatch(setHomepageCategoriesLoading(false));
    }
};

var Buffer = require("buffer/").Buffer; // note: the trailing slash is important!
export const getImg = async (ITEM_IMAGE_URL, setImgUrl) => {
    /* 
    Arguments
    ---------
    ITEM_IMAGE_URL : string
    setImgUrl : func
    */
    if (!ITEM_IMAGE_URL) {
        setImgUrl(noImage);
        return;
    }
    try {
        const response = await axiosApiInstance.get(
            SEARCH_CATALOGUE_IMAGES_GET(ITEM_IMAGE_URL),
            { responseType: "arraybuffer" }
        );
        let base64ImageString = Buffer.from(response.data, "binary").toString(
            "base64"
        );
        setImgUrl("data:image/png;base64," + base64ImageString);
    } catch {
        setImgUrl(noImage);
    }
};

export const cpaAutocompleteGet = async (
    dispatch,
    OU,
    cpa,
    setOptions,
    value,
    setCpasLoading
) => {
    try {
        let newOptions = [];
        // If an input is selected, set it as the option
        if (value) {
            newOptions = [value];
        }
        setCpasLoading(true);
        let encodedCpaName = encodeURIComponent(cpa);
        if (cpa.includes("/"))
            encodedCpaName = encodeURIComponent(encodedCpaName);
        let response = await axiosApiInstance.get(
            CPA_AUTOCOMPLETE_GET(encodedCpaName, OU)
        );
        const results = response.data;

        if (results) {
            newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
        setCpasLoading(false);
    } catch (error) {
        alertDispatcher(
            "searchFilters",
            error?.response?.status,
            dispatch,
            SERVER_SEARCH_CATEGORIES("Contract Purchase Agreements")
        );
    }
};

/**
 * Retrieves CPA by ID using `search_by_id` parameter
 * of CPA autocomplete API
 * @param {string} cpaId - cpa id being requested
 * @param {string} OU - operating unit of non-cat item
 * @param {function} dispatch - redux dispatch function
 * @returns {Promise} - axios request promise
 */
export const getCpaById = async (cpaId, OU, dispatch) => {
    try {
        // Call API, load results into redux
        const response = await axiosApiInstance.get(
            CPA_AUTOCOMPLETE_GET(cpaId, OU, true)
        );
        return response.data;
    } catch (error) {
        alertDispatcher("getCpaByID", error?.response?.status, dispatch);
    }
};
