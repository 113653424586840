import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useContext, useEffect } from "react";

import { EditInformationTemplateContext } from "../../../../hooks/contexts";
import FreeTextInfoTemplate from "./freeTextInfoTemplate";

const generateInitialCategoryField = (ift) => {
    const generateAttachmentText = ift?.TEMPLATES.map((iftField) => {
        const value = iftField?.VALUE
            ? iftField?.VALUE
            : iftField?.DEFAULT_VALUE;
        return `${iftField.ATTRIBUTE_NAME}=${value}`;
    }).join("|");

    return {
        TEMPLATE_ID: ift?.TEMPLATE_ID,
        LEVEL_TYPE: "LINE",
        DATA_TYPE: "Short Text",
        CATEGORY: ift?.ATTACH_CATEGORY_NAME,
        DESCRIPTION: ift?.TEMPLATE_NAME,
        FILENAME: null,
        ATTACHMENT_TEXT: generateAttachmentText,
    };
};

const InformationTemplateGenerator = ({ ift }) => {
    const editPageInputs = useContext(EditInformationTemplateContext); // can be null. Do not send wrap context provider if to be read only

    useEffect(() => {
        updateCategoryField();
    }, []);

    const updateCategoryField = () => {
        editPageInputs &&
            editPageInputs.updateInfomationTemplateField(
                generateInitialCategoryField(ift)
            );
    };

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h5" color="text.subheader">
                    {ift?.TEMPLATE_NAME}
                </Typography>
                <Typography variant="body1">
                    {ift?.ATTACH_CATEGORY_NAME}
                </Typography>
            </Grid>
            {ift?.TEMPLATES.map((iftField, index) => (
                <Grid item xs={6} key={index}>
                    <FreeTextInfoTemplate
                        key={index}
                        iftField={iftField}
                        iftValue={{ id: null }}
                        setIftValue={null}
                        updateCategoryField={updateCategoryField}
                    />
                </Grid>
            ))}
        </>
    );
};

FreeTextInfoTemplate.propTypes = {
    ift: PropTypes.object,
};

export default InformationTemplateGenerator;
