import { styled } from "@mui/material/styles";

const StyledPopperArrow = styled("div")({
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
    },
});

export default StyledPopperArrow;
