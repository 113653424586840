import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { INVALID_CART_NAME_MSG } from "../../../constants/generalConstants";
import { setShowManageCartDialogue } from "../../../redux/actions/generalActions";
import { patchRenamedCart } from "../../../services/cart/cartAPIs";
import styledDialogueSX from "../../../styled/styledSXProperties/styledDialogueSX";
import { deleteCart } from "./../../../services/cart/cartAPIs";
import { invalidCartNames } from "./../../../utils/shoppingCartUtils";

const ManageCartDialogue = () => {
    // Collect states and state dispatchers
    const activeCart = useSelector((state) => state.general.activeCart);
    const carts = useSelector((state) => state.shoppingCart);
    const showManageCartDialogue = useSelector(
        (state) => state.general.showManageCartDialogue
    );
    const dispatch = useDispatch();

    const navigate = useNavigate();

    // Instantiate local states used only within component
    const [inputRename, setInputRename] = useState("");
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);

    const handleClose = () => {
        dispatch(setShowManageCartDialogue(false));
    };

    // On click, rename cart and cart key in state management, update active
    // cart state, close dialogue box and route to cart URL
    const clickRenameCart = async () => {
        const isInvalid = invalidCartNames(carts).includes(
            inputRename.trim().toLowerCase()
        );
        if (isInvalid) {
            setError(true);
            setErrorMsg(INVALID_CART_NAME_MSG);
        } else {
            dispatch(setShowManageCartDialogue(false));
            patchRenamedCart(activeCart, dispatch, inputRename);
            setErrorMsg("");
            setInputRename("");
            setError(false);
        }
    };

    // Delete shopping cart from state management, set the active cart to
    // default and close pop-up
    const handleDeleteCartClick = async () => {
        dispatch(setShowManageCartDialogue(false));
        deleteCart(activeCart, dispatch, navigate);
    };

    return (
        <>
            <Dialog
                open={showManageCartDialogue}
                onClose={handleClose}
                fullWidth
            >
                <DialogTitle
                    variant="h3"
                    sx={{ padding: "16px", ...styledDialogueSX }}
                >
                    Manage Named Cart
                </DialogTitle>
                <DialogContent sx={{ padding: "16px", ...styledDialogueSX }}>
                    <DialogContentText>Rename Cart</DialogContentText>
                    <Box sx={{ display: "flex", justifyContent: "stretch" }}>
                        <TextField
                            id="name"
                            color="secondary"
                            label="New Cart Name"
                            fullWidth
                            variant="standard"
                            value={inputRename}
                            helperText={errorMsg}
                            error={error}
                            onChange={(event) =>
                                setInputRename(event.target.value)
                            }
                            required
                        />
                        <DialogActions>
                            <Button
                                variant="contained"
                                onClick={clickRenameCart}
                                sx={{ margin: "0px 10px" }}
                            >
                                Rename
                            </Button>
                        </DialogActions>
                    </Box>
                    <DialogContentText sx={{ margin: "24px 0px 0px 0px" }}>
                        Delete Cart
                    </DialogContentText>
                    <DialogActions
                        sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            margin: "16px 0px 0px 0px",
                            padding: "0px",
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={handleDeleteCartClick}
                            endIcon={<DeleteForeverOutlinedIcon />}
                        >
                            Delete Cart
                        </Button>
                    </DialogActions>
                    <DialogActions>
                        <Button
                            variant="contained"
                            onClick={handleClose}
                            sx={{ margin: "0px 8px" }}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ManageCartDialogue;
