import {
    CORRECT_RECEIPT,
    CREATE_RECEIPT,
    RETURN_RECEIPT,
} from "../../constants/generalConstants";
import {
    NOTFOUND_RECEIPT_BY_NUMBER,
    NOTFOUND_RECEIPT_IN_OU,
    PAYLOAD_DISPUTE,
    PAYLOAD_RECEIPT_CREATION,
    SERVER_MESSAGE_RECEIPT_BY_NUMBER,
} from "../../constants/snackbarMessage";
import {
    setCreateReceiptLoading,
    setPoShipments,
    setReceiptOU,
    setReceiptReqNum,
    setRequester,
    setSubmittedDate,
} from "../../redux/actions/createReceiptActions";
import {
    setShowDisputeDialogue,
    setShowReceiptDialogue,
    setShowReceiptSummaryDialogue,
} from "../../redux/actions/generalActions";
import {
    setReceipt,
    setReceiptSummaryID,
    setReceiptSummaryLoading,
} from "../../redux/actions/receiptSummaryActions";
import alertDispatcher from "../../utils/alertsUtils";
import {
    addCreationProperties,
    allShipmentsClosedForReceiving,
    closeCreateReceiptDialogue,
    showReceivingNotAvailableSnackbar,
} from "../../utils/createReceiptUtils";
import {
    addCorrectionProperties,
    addReturnProperties,
} from "../../utils/receiptSummaryUtils";
import axiosApiInstance from "../middleware/axios";
import {
    CREATE_RECEIPT_EVENT,
    DISPUTE_INVOICE,
    GET_RECEIPT_BY_NUMBER,
    GET_RECEIPTS_BY_REQUISITION,
    GET_SHIPMENTS_BY_REQUISITION,
} from "./urls";

export const getCreateReceiptDataByReqId = async (id, dispatch) => {
    /* 
    Arguments
    ---------
    id : str
    dispatch: func
    */
    try {
        const request = await axiosApiInstance.get(
            GET_SHIPMENTS_BY_REQUISITION(id)
        );
        const response = request.data;
        if (!allShipmentsClosedForReceiving(response.shipmentLines)) {
            dispatch(setReceiptOU(response.OU));
            dispatch(setRequester(response.requesterName));
            dispatch(setSubmittedDate(response.submittedDate));
            dispatch(
                setPoShipments(addCreationProperties(response.shipmentLines))
            );
            dispatch(setCreateReceiptLoading(false));
            dispatch(setShowReceiptDialogue(true));
        } else {
            dispatch(setCreateReceiptLoading(false));
        }
        showReceivingNotAvailableSnackbar(response?.shipmentLines, dispatch);
    } catch (error) {
        // If API call fails, close the dialogue and clear state
        dispatch(setCreateReceiptLoading(false));
        dispatch(setReceiptReqNum(null));
        closeCreateReceiptDialogue(dispatch);
        alertDispatcher(
            "getCreateReceiptDataByReqId",
            error?.response?.status,
            dispatch
        );
    }
};

const receiptCreatedSuccessMessage = (createdReceiptIds, reqId) => {
    /* 
    Arguments
    ---------
    createdReceiptIds : array
    */
    return createdReceiptIds.constructor == Array
        ? `${createdReceiptIds.length} receipt${
              createdReceiptIds.length !== 1 ? "s" : ""
          } successfully created: ${createdReceiptIds.join(
              ", "
          )} and submitted to Harmony for validation (against requisition ${reqId})`
        : `Receipt created successfully and submitted to Harmony for validation ${reqId}`;
};

const receiptCreateLockedShipmentMessage = (lockedShipments) => {
    /* When the POST receipt error receives a payload, it will first check the
    validity of the data and throw a 400 error if any issues are spotted. If
    all checks pass, it writes the payload to the receipts container. Prior
    to each write, it runs a final check to ensure associated PO shipments
    have not been locked by anther user in the latent period. Any IDs found
    to be locked in this second check are returned in the lockedShipments portion
    of the 201 response. This function generates the warning message for this
    scenario.

    Arguments
    ---------
    lockedShipments : array
    */
    return lockedShipments.constructor == Array && lockedShipments.length > 0
        ? `Partial success - unable to create receipts for ${
              lockedShipments.length
          } requisition line${
              lockedShipments.length !== 1 ? "s" : ""
          } as the following PO shipments are now locked: ${lockedShipments.join(
              ", "
          )} - please try again in a few minutes. `
        : "";
};

/**
 * Returns Success Message for Corrections
 * @param {Array} lockedShipments
 * @param {Array} createdReceiptIds
 * @returns {any}
 */
export const correctedSuccessMessage = (lockedShipments, createdReceiptIds) => {
    if (!lockedShipments.length > 0 && createdReceiptIds.length == 0) {
        return "Unable to correct receipt as all associated PO shipments are being receipted by another user - please try again in a few minutes";
    } else if (lockedShipments.length > 0) {
        return `Partial success - ${createdReceiptIds.length} receipt line${
            createdReceiptIds.length !== 1 ? "s" : ""
        } successfully corrected and submitted to Harmony for validation. Unable to correct ${
            lockedShipments.length
        } receipt line${
            lockedShipments.length !== 1 ? "s" : ""
        } as associated PO shipments are being receipted by another user - please try again in a few minutes`;
    } else {
        return "Receipt successfully corrected and submitted to Harmony for validation";
    }
};

/**
 * Returns Success Message for Returns
 * @param {Array} lockedShipments
 * @param {Array} createdReceiptIds
 * @returns {any}
 */
export const returnedSuccessMessage = (lockedShipments, createdReceiptIds) => {
    if (!lockedShipments.length > 0 && createdReceiptIds.length == 0) {
        return "Unable to return receipt as all associated PO shipments are being receipted by another user - please try again in a few minutes";
    } else if (lockedShipments.length > 0) {
        return `Partial success - ${createdReceiptIds.length} receipt line${
            createdReceiptIds.length !== 1 ? "s" : ""
        } successfully returned and submitted to Harmony for validation. Unable to return ${
            lockedShipments.length
        } receipt line${
            lockedShipments.length !== 1 ? "s" : ""
        } as associated PO shipments are being receipted by another user - please try again in a few minutes`;
    } else {
        return "Receipt successfully returned and submitted to Harmony for validation";
    }
};

/**
 * @param {any} payload
 * @param {string} mode (one of "CREATE RECEIPT", "CORRECT RECEIPT", "RETURN RECEIPT")
 * @param {function} dispatch
 * @param {string} reqId=null
 */
export const createReceiptEvents = async (
    payload,
    mode,
    dispatch,
    reqId = null
) => {
    mode == CREATE_RECEIPT && dispatch(setCreateReceiptLoading(true));
    [RETURN_RECEIPT, CORRECT_RECEIPT].includes(mode) &&
        dispatch(setReceiptSummaryLoading(true));
    try {
        const request = await axiosApiInstance.post(
            CREATE_RECEIPT_EVENT,
            payload
        );
        const createdReceiptIds = request.data?.successful_ids;
        const lockedShipments = request.data?.locked_shipments;
        if (mode == CREATE_RECEIPT) {
            const createdReceiptIds = request.data?.successful_ids;
            alertDispatcher(
                "createReceiptEvents",
                lockedShipments.length > 0 ? 409 : 201,
                dispatch,
                receiptCreateLockedShipmentMessage(lockedShipments) +
                    receiptCreatedSuccessMessage(createdReceiptIds, reqId)
            );

            dispatch(setShowReceiptDialogue(false));
            dispatch(setCreateReceiptLoading(false));
        } else if (mode == CORRECT_RECEIPT) {
            alertDispatcher(
                "createReceiptEvents",
                lockedShipments.length > 0 ? 409 : 201,
                dispatch,
                correctedSuccessMessage(lockedShipments, createdReceiptIds)
            );
            dispatch(setShowReceiptSummaryDialogue(false));
            dispatch(setReceiptSummaryLoading(false));
        } else if (mode == RETURN_RECEIPT) {
            alertDispatcher(
                "createReceiptEvents",
                lockedShipments.length > 0 ? 409 : 201,
                dispatch,
                returnedSuccessMessage(lockedShipments, createdReceiptIds)
            );
            dispatch(setShowReceiptSummaryDialogue(false));
            dispatch(setReceiptSummaryLoading(false));
        }
    } catch (error) {
        // If API call fails, close the dialogue and clear state
        if (error.response.status == 400) {
            // Validation error
            alertDispatcher(
                "createReceiptEvents",
                error.response.status,
                dispatch,
                error?.response?.data?.detail
                    ? `${PAYLOAD_RECEIPT_CREATION} - ${error.response.data.detail}`
                    : PAYLOAD_RECEIPT_CREATION
            );
        } else {
            // Server error
            alertDispatcher(
                "createReceiptEvents",
                error.response.status,
                dispatch
            );
        }
        mode == CREATE_RECEIPT && dispatch(setCreateReceiptLoading(false));
        mode == CORRECT_RECEIPT && dispatch(setReceiptSummaryLoading(false));
        mode == RETURN_RECEIPT && dispatch(setReceiptSummaryLoading(false));
    }
};

export const getReceiptByNumber = async (id, dispatch, activeOU) => {
    /* 
    Arguments
    ---------
    id : str
    dispatch: func
    */
    try {
        const request = await axiosApiInstance.get(
            GET_RECEIPT_BY_NUMBER(id, activeOU)
        );
        const receiptWithCorrectionProps = addCorrectionProperties(
            request.data
        );
        dispatch(setReceipt(addReturnProperties(receiptWithCorrectionProps)));
        dispatch(setReceiptSummaryLoading(false));
    } catch (error) {
        // If API call fails, close the dialogue and clear state
        dispatch(setReceipt({}));
        dispatch(setReceiptSummaryID(null));
        dispatch(setShowReceiptSummaryDialogue(false));
        alertDispatcher(
            "getReceipts",
            error?.response?.status,
            dispatch,
            error?.response?.status === 404
                ? NOTFOUND_RECEIPT_BY_NUMBER(id)
                : error?.response?.data?.detail?.includes(
                      "Receipt not found in currently selected OU"
                  )
                ? NOTFOUND_RECEIPT_IN_OU(id)
                : SERVER_MESSAGE_RECEIPT_BY_NUMBER(id)
        );
    }
};

export const getReceiptByReqId = async (
    requisitionId,
    setIsLoading,
    setReqLinesWithReceipts,
    dispatch
) => {
    /* 
    Arguments
    ---------
    requisitionId : str
    setIsLoading : func
    setReqLinesWithReceipts : func
    dispatch: func
    */
    try {
        const request = await axiosApiInstance.get(
            GET_RECEIPTS_BY_REQUISITION(requisitionId)
        );
        setReqLinesWithReceipts(request.data);
        setIsLoading(false);
    } catch (error) {
        // If API call fails, trigger a notification and return empty array
        alertDispatcher(
            "getReceipts",
            error?.response?.status,
            dispatch,
            SERVER_MESSAGE_RECEIPT_BY_NUMBER(requisitionId)
        );
        setReqLinesWithReceipts([]);
        setIsLoading(false);
    }
};

export const postDispute = async (
    invoiceId,
    reason,
    sharepointLink,
    setIsLoading,
    dispatch,
    toggleDisputeSubmitKey
) => {
    /* 
    Arguments
    ---------
    invoiceId : string
    reason : string
    sharepointLink : string
    setIsLoading : func
    dispatch : func
    */
    // Parse payload
    // navigate(INVOICE_SUMMARY_PAGE(invoiceId));
    setIsLoading(true);
    const payload = {
        DISPUTE_REASON: reason,
        DISPUTE_SHAREPOINT_LINK: sharepointLink,
    };
    try {
        const response = await axiosApiInstance.post(
            DISPUTE_INVOICE(invoiceId),
            payload
        );
        alertDispatcher("postDispute", response?.status, dispatch);
        toggleDisputeSubmitKey();
        dispatch(setShowDisputeDialogue(false));
    } catch (error) {
        // If API call fails, close the dialogue and clear state
        if (error.response.status == 400) {
            // Validation error
            alertDispatcher(
                "postDispute",
                error?.response?.status,
                dispatch,
                error?.response?.data?.detail
                    ? `${PAYLOAD_DISPUTE} - ${error.response.data.detail}`
                    : PAYLOAD_DISPUTE
            );
        } else {
            // Server error
            alertDispatcher("postDispute", error?.response?.status, dispatch);
        }
    }
    setIsLoading(false);
};

export const getDispute = async (
    invoiceId,
    setIsDisputeLoading,
    setDispute,
    dispatch
) => {
    /* 
    Arguments
    ---------
    invoiceId : string
    invoiceId : func
    reason : func
    dispatch : func
    */
    setIsDisputeLoading(true);
    // Error messages
    try {
        const request = await axiosApiInstance.get(DISPUTE_INVOICE(invoiceId));
        setDispute(request.data);
    } catch (error) {
        setDispute(false);
        // Only throw snackbar warning if server error
        error?.response?.status == 500 &&
            alertDispatcher("getDispute", error?.response?.status, dispatch);
    }
    setIsDisputeLoading(false);
};
