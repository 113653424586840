import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import { Stack, useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";

import { MOBILE_MEDIA_QUERY } from "../../../constants/mediaQueries";
import useUser from "../../../services/apiUtils/hooks/useUser";
import { styledDialogChildSX } from "../../../styled/styledSXProperties/styledDialogueSX";
import { baseStyledPaperSX } from "../../../styled/styledSXProperties/styledPaperSX";
import CustomDialogTitle from "../../customDialog/customDialogTitle";
import DetailListVertical from "../../displays/detailListVertical";
import UserSearchAsYouType from "../../inputs/searchAsYouType/userSearchAsYouType";

const ChangeRequisitionOwnerDialog = ({ req, isOpen, onClose, onSuccess }) => {
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);
    const { changeRequisitionOwner, isChangeRequisitionOwnerLoading } =
        useUser();
    const [toOwner, setToOwner] = useState();

    // Collect states and state dispatchers
    const darkMode = useSelector((state) => state.general.darkMode);

    const handleClose = (e_, reason) => {
        if (reason && reason === "backdropClick") return;
        setToOwner(undefined);
        onClose();
    };

    const handleSubmit = () => {
        changeRequisitionOwner(req.id, toOwner?.id).then((status) => {
            if (status === 200) {
                onSuccess();
                onClose();
            }
        });
    };

    return (
        <Dialog
            fullWidth
            onClose={handleClose}
            open={isOpen}
            fullScreen={!isDesktop}
            PaperProps={{ sx: baseStyledPaperSX(darkMode) }}
        >
            <CustomDialogTitle
                onClose={handleClose}
                tooltip="Close change owner form"
                title="Change Requisition Owner"
                subtitle="Changing the ownership of this requisition will change
                both the preparer and the requestor, ensuring that any
                future notifications and actions required on this
                requisition will be the responsibility of the new owner."
            />
            <DialogContent sx={styledDialogChildSX}>
                <Stack spacing={2} alignContent="stretch">
                    <DetailListVertical
                        condensed={true}
                        details={[
                            {
                                header: "Requisition ID",
                                content: req?.id,
                            },
                            {
                                header: "Current owner",
                                content: req?.submittedByName,
                            },
                        ]}
                    />
                    <UserSearchAsYouType
                        selectedValue={toOwner}
                        setSelectedValue={(user) => setToOwner(user)}
                        label="New Owner"
                    />
                </Stack>
            </DialogContent>
            <DialogActions sx={styledDialogChildSX}>
                <LoadingButton
                    color="success"
                    loading={isChangeRequisitionOwnerLoading}
                    loadingPosition="end"
                    endIcon={<SendIcon />}
                    onClick={handleSubmit}
                    disabled={!toOwner}
                    variant="contained"
                >
                    Submit
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

ChangeRequisitionOwnerDialog.propTypes = {
    req: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default ChangeRequisitionOwnerDialog;
