import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IBuyCard from "components/displays/iBuyCard";
import OutlinedChip from "components/displays/outlinedChip";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GradientDivider from "styled/styledComponents/GradientDivider";

import { NON_CATALOGUE_ITEM } from "../../constants/generalConstants";
import { PRODUCT_DETAILS_PAGE } from "../../constants/routes";
import { getImg } from "../../services/search/searchAPIs";
import AddToCartButton from "../buttons/addToCartButton";
import FavouriteButton from "../buttons/favouriteButton";
import CurrencyFigure from "../displays/currencyFigure";
import noImage from "./../../assets/noImage.png";

const DESCRIPTION_WRAP_CHARACTERS = 75;

const ProductCard = ({ product, isDesktop }) => {
    const [imgUrl, setImgUrl] = useState(noImage);

    useEffect(() => {
        getImg(product?.ITEM_IMAGE_URL, setImgUrl);
    }, [product]);

    const disableNonCatItemClick = () =>
        product?.itemType == NON_CATALOGUE_ITEM
            ? (e) => e.preventDefault()
            : null;

    const isDescriptionWrapped = () =>
        product?.ITEM_DESCRIPTION
            ? product?.ITEM_DESCRIPTION.length > DESCRIPTION_WRAP_CHARACTERS
                ? true
                : false
            : false;

    const descriptionText = () =>
        isDescriptionWrapped()
            ? product?.ITEM_DESCRIPTION.substring(
                  0,
                  DESCRIPTION_WRAP_CHARACTERS
              ) + "..."
            : product?.ITEM_DESCRIPTION;

    return (
        <Grid
            container
            flexDirection="column"
            sx={{ marginTop: isDesktop ? "24px" : "0px" }}
        >
            <Link
                to={PRODUCT_DETAILS_PAGE(product?.PO_LINE_ID)}
                style={{ textDecoration: "none" }}
                onClick={disableNonCatItemClick}
            >
                <IBuyCard
                    xs={12}
                    additionalSx={{
                        margin: isDesktop ? "0px" : "4px 10px",
                        overflow: "visible",
                    }}
                >
                    <Grid
                        container
                        item
                        xs={12}
                        sx={{
                            display: "flex",
                            flexDirection: isDesktop ? "column" : "row",
                            paddingTop: "1px",
                        }}
                    >
                        <Grid
                            container
                            item
                            xs={isDesktop ? 12 : 5}
                            sx={{
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "0px",
                                position: "relative",
                                ":hover": {
                                    transform: "scale(1.03)",
                                    transition: "0.2s ease",
                                },
                            }}
                        >
                            <Grid
                                item
                                alt={product?.ITEM_DESCRIPTION}
                                alignItems="center"
                                alignContent="center"
                                component="img"
                                src={imgUrl}
                                aria-label={product?.ITEM_DESCRIPTION}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = noImage;
                                }}
                                sx={{
                                    borderRadius: "10px",
                                    marginTop: isDesktop ? "-24px" : "0px",
                                    boxShadow: "10",
                                    ":hover": {
                                        boxShadow: "16",
                                    },
                                }}
                                mr={isDesktop ? 4 : 0}
                                ml={isDesktop ? 4 : 2}
                                width={isDesktop ? "73%" : "85%"}
                                height="auto"
                            />
                            <FavouriteButton
                                product={product}
                                positionAbsolute
                            />
                        </Grid>
                        <Grid container item xs={isDesktop ? 12 : 7}>
                            <Fade in={true} timeout={200}>
                                <CardContent sx={{ width: "100%" }}>
                                    <Tooltip
                                        disableInteractive
                                        title={
                                            isDescriptionWrapped()
                                                ? product?.ITEM_DESCRIPTION
                                                : ""
                                        }
                                    >
                                        <Typography
                                            fontWeight="bold"
                                            variant="body2"
                                        >
                                            {descriptionText()}
                                        </Typography>
                                    </Tooltip>
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        {product?.SUPPLIER_NAME
                                            ? product.SUPPLIER_NAME
                                            : "Supplier not listed"}
                                    </Typography>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Typography
                                            fontWeight="bold"
                                            variant="h6"
                                            color="text.subheader"
                                            sx={{
                                                margin: "8px 0px 8px 0px",
                                            }}
                                        >
                                            <CurrencyFigure
                                                value={product?.UNIT_PRICE}
                                                code={product?.CURRENCY_CODE}
                                            />
                                        </Typography>
                                        <Typography
                                            color="text.subheader"
                                            variant="h6"
                                            fontWeight="bold"
                                            sx={{
                                                margin: "8px 0px 8px 0px",
                                            }}
                                        >
                                            {product?.UNIT_OF_MEASURE}
                                        </Typography>
                                    </Box>
                                    {product?.itemType ==
                                        NON_CATALOGUE_ITEM && (
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <OutlinedChip value="Non-Catalogue Item" />
                                        </Box>
                                    )}
                                    {isDesktop && (
                                        <GradientDivider
                                            sx={{
                                                margin: "8px 5px 16px 5px",
                                            }}
                                        />
                                    )}
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <AddToCartButton
                                            product={product}
                                            nonCat={false}
                                        />
                                    </Box>
                                </CardContent>
                            </Fade>
                        </Grid>
                    </Grid>
                </IBuyCard>
            </Link>
        </Grid>
    );
};

ProductCard.propTypes = {
    product: PropTypes.object,
};

export default ProductCard;
