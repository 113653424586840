import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TOOLTIP_DELETE_CART } from "../../../constants/tooltipText";
import { updateCartItems } from "../../../services/cart/cartAPIs";
import { getImg } from "../../../services/search/searchAPIs";
import GradientDivider from "../../../styled/styledComponents/GradientDivider";
import { amendedCartItems } from "../../../utils/shoppingCartUtils";
import CurrencyFigure from "../../displays/currencyFigure";
import noImage from "./../../../assets/noImage.png";

const ShoppingCartSummaryPopperItem = ({ product }) => {
    // Collect states and state dispatchers
    const dispatch = useDispatch();
    const activeCart = useSelector((state) => state.general.activeCart);
    const carts = useSelector((state) => state.shoppingCart);

    const handleDeleteCartItem = () => {
        const newItems = amendedCartItems(
            carts[activeCart].items,
            "REMOVE",
            product.PO_LINE_ID
        );
        updateCartItems(activeCart, dispatch, newItems);
    };

    const [imgUrl, setImgUrl] = useState(noImage);

    // Import image
    useEffect(() => {
        getImg(product.ITEM_IMAGE_URL, setImgUrl);
    }, [product.ITEM_IMAGE_URL]);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "10px",
                }}
            >
                <CardMedia
                    component="img"
                    sx={{
                        width: "30%",
                        height: "30%",
                        borderRadius: "10px",
                        margin: "10px 10px 10px 0px",
                    }}
                    src={imgUrl}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = noImage;
                    }}
                    alt="Product Image Thumbnail"
                />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: "bold",
                            padding: "0px 10px 0px 0px",
                        }}
                    >
                        {product.ITEM_DESCRIPTION}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 12,
                            padding: "0px 10px 0px 0px",
                        }}
                    >
                        {`Supplier: ${product.SUPPLIER_NAME}`}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 12,
                            padding: "0px 10px 0px 0px",
                        }}
                    >
                        {`Unit of Measure: ${product.UNIT_OF_MEASURE}`}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 12,
                            padding: "0px 10px 0px 0px",
                        }}
                    >
                        {`Qty: ${product.QUANTITY}`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: "bold",
                        }}
                    >
                        <CurrencyFigure
                            value={product?.UNIT_PRICE}
                            code={product?.CURRENCY_CODE}
                        />
                    </Typography>
                    <Tooltip title={TOOLTIP_DELETE_CART}>
                        <IconButton
                            onClick={handleDeleteCartItem}
                            aria-label="cartPopperTrashButton"
                        >
                            <DeleteForeverOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <GradientDivider sx={{ margin: "2px" }} />
        </>
    );
};

ShoppingCartSummaryPopperItem.propTypes = {
    product: PropTypes.object,
};

export default ShoppingCartSummaryPopperItem;
