import Tooltip from "@mui/material/Tooltip";
import OutlinedChip from "components/displays/outlinedChip";
import RequisitionStatusBadge from "components/displays/status/requisitionStatusBadge";
import OnDesktop from "components/wrappers/onDesktop";
import {
    TOOLTIP_INTERNAL_REQ_DEFINITION,
    TOOLTIP_PURCHASE_REQ_DEFINITION,
} from "constants/tooltipText";
import PropTypes from "prop-types";

import { accountedAgainstDispatcher } from "./index";

export const sourcingChipDispatcher = {
    INVENTORY: "Internal",
    VENDOR: "Purchase",
};

export const sourcingChipTooltipDispatcher = {
    INVENTORY: TOOLTIP_INTERNAL_REQ_DEFINITION,
    VENDOR: TOOLTIP_PURCHASE_REQ_DEFINITION,
};

/**
 * Component displaying chips/pills on the header bar of
 * single requisition summary page.
 * @component
 */
const HeaderChips = ({ req }) => {
    const headerSourceCode = req.requisitionLines[0].SOURCE_TYPE_CODE;
    return (
        <>
            <RequisitionStatusBadge
                status={req.status}
                sx={{ m: "0px 8px 6px 0px" }}
            />
            <OnDesktop>
                {req?.amended === true && (
                    <OutlinedChip value="Amended" marginRight />
                )}
                <Tooltip
                    disableInteractive
                    placement="top"
                    title={sourcingChipTooltipDispatcher[headerSourceCode]}
                >
                    <span>
                        <OutlinedChip
                            value={sourcingChipDispatcher[headerSourceCode]}
                            marginRight
                        />
                    </span>
                </Tooltip>
            </OnDesktop>
            <OutlinedChip
                value={
                    accountedAgainstDispatcher[
                        req.requisitionHeaderForm.ACCOUNTED_AGAINST
                    ]
                }
            />
        </>
    );
};

HeaderChips.propTypes = {
    req: PropTypes.object,
};

export default HeaderChips;
