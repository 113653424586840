import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { useContext } from "react";

import { EditInformationTemplateContext } from "../../../../hooks/contexts";
import useThrottle from "../../../../hooks/useThrottle";
import { freeTextFormProps } from "../../../../styled/props/textFieldProps";

const FreeTextInfoTemplate = ({ iftField, iftValue, updateCategoryField }) => {
    // Throttle function to avoid unneceesary function processing on key up
    const throttle = useThrottle(200); // 0.2 second timeout buffer for field updates (for performance)
    const editPageInputs = useContext(EditInformationTemplateContext); // can be null. Do not send wrap context provider if to be read only
    let inputProps = {}; // These are only functionally utilized if in edit mode
    if (editPageInputs) {
        // Additional logic IF the form is in edit mode (wrapped in <EditInformationTemplateContext/>)
        const errors = editPageInputs ? editPageInputs.errors : null;
        const fieldIdentifier = `${iftField.id}_${iftField.SEQUENCE}`;

        // Validation logic
        const { ref: fieldref, ...defaultInputProps } = editPageInputs.register(
            fieldIdentifier,
            {
                required:
                    iftField.REQUIRED_FLAG === "Y"
                        ? "This field is required"
                        : false,
                onChange: (e) => {
                    updateField(e);
                },
            }
        );

        // Override input props with additional field props
        inputProps = {
            ...defaultInputProps,
            required: iftField.REQUIRED_FLAG === "Y",
            defaultValue: iftField?.DEFAULT_VALUE,
            inputRef: fieldref,
            error: !!errors?.[fieldIdentifier],
            helperText: errors?.[fieldIdentifier]?.message,
        };
    }

    const updateField = (event) => {
        if (editPageInputs && editPageInputs?.updateInfomationTemplateField) {
            throttle(() => {
                iftField.VALUE = event.target.value.replaceAll("|", ""); // restrict bars as delimited
                updateCategoryField();
            });
        }
    };

    return (
        <>
            {iftField.DISPLAY_FLAG == "Y" && (
                <TextField
                    {...freeTextFormProps}
                    {...inputProps}
                    label={iftField.DESCRIPTION}
                    value={iftValue[iftValue.id]}
                    placeholder={editPageInputs ? "Enter value..." : "N/A"}
                    disabled={!editPageInputs}
                />
            )}
        </>
    );
};

FreeTextInfoTemplate.propTypes = {
    iftField: PropTypes.object,
    product: PropTypes.object,
    setProduct: PropTypes.func,
};

export default FreeTextInfoTemplate;
