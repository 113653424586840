import { Divider, Typography } from "@mui/material";
import DetailListVertical from "components/displays/detailListVertical";
import PropTypes from "prop-types";
import React from "react";

import PoLink from "./poLink";

const ReceiptLinesList = ({ receiptLines }) => {
    return (
        <>
            {receiptLines.map((receiptLine) => (
                <React.Fragment key={receiptLine.LINE_NUM}>
                    <Divider textAlign="left">
                        <Typography variant="body1">
                            Receipt Line {receiptLine.LINE_NUM}
                        </Typography>
                    </Divider>
                    <DetailListVertical
                        condensed
                        details={[
                            {
                                header: "Item Description",
                                content: receiptLine.ITEM_DESCRIPTION,
                            },
                            {
                                header: "Quantity Received",
                                content: receiptLine.QUANTITY_RECEIVED,
                            },
                            {
                                header: "Unit of Measure",
                                content:
                                    receiptLine.UNIT_OF_MEASURE_DESCRIPTION,
                            },
                            {
                                header: "Shipping Location",
                                content: receiptLine.SHIP_TO_LOCATION_CODE,
                            },
                            {
                                header: "Purchase Order",
                                content: (
                                    <PoLink poNumber={receiptLine?.PO_NUMBER} />
                                ),
                            },
                        ]}
                    />
                </React.Fragment>
            ))}
        </>
    );
};

ReceiptLinesList.propTypes = {
    receiptLines: PropTypes.array.isRequired,
};

export default ReceiptLinesList;
