import { createContext } from "react";

// ----------------------------------------------------------------------
/* Example EditInformationTemplateContext value
{
    updateInfomationTemplateField: setAttachmentsList, // setState func
    register: register, // React Hooks Form register
    errors: errors, // React Hooks form errors field val
}
*/
export const EditInformationTemplateContext = createContext();
// ----------------------------------------------------------------------
