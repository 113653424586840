import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
    setSupplierName,
    setSupplierSite,
    setWholeLine,
} from "../../redux/actions/createRequisitionActions";
import { primaryButtonSx } from "../../styled/styledSXProperties/styledButtonSX";
import alertDispatcher from "../../utils/alertsUtils";
import {
    closeNonCatDialogue,
    injectNonCatItemToReqLine,
    validNonCatItemInjection,
} from "../../utils/nonCatFormUtils";

const UpdateRequisitionLineButton = ({
    product,
    reqLineIndex,
    nonCatFormValidator,
}) => {
    // Collect states and state dispatchers
    const reqLines = useSelector(
        (state) => state.createRequisition.iBuyRequisition.requisitionLines
    );
    const req = useSelector((state) => state.createRequisition.iBuyRequisition);
    const dispatch = useDispatch();

    const clickUpdateReqLine = (event) =>
        nonCatFormValidator(() => updateReqLine())(event);

    const updateReqLine = () => {
        /* Two routes:
        1) User updates req line successfully successfully
        2) User unable to update item to cart as supplier validation rules violated.
        */
        const [isInjectionValid, errorMessage] = validNonCatItemInjection(
            product,
            req
        );
        if (isInjectionValid) {
            // Route 1 (see above)
            const updatedLine = injectNonCatItemToReqLine(
                reqLines[reqLineIndex],
                product
            );
            // TODO - align schemas so below duplicate fields do not require setting
            dispatch(
                setWholeLine(reqLineIndex, {
                    ...updatedLine,
                    SUGGESTED_VENDOR_ID: updatedLine.SUPPLIER_ID,
                    SUGGESTED_VENDOR_SITE_ID: updatedLine.SUPPLIER_SITE_ID,
                })
            );
            dispatch(setSupplierName(updatedLine.SUPPLIER_NUMBER_NAME));
            dispatch(setSupplierSite(updatedLine.SUPPLIER_SITE_ID));
            closeNonCatDialogue(dispatch);
        } else {
            // Route 2 (see above)
            alertDispatcher("updateReqLine", "400", dispatch, errorMessage);
        }
    };
    return (
        <Button
            onClick={clickUpdateReqLine}
            variant="contained"
            sx={primaryButtonSx}
        >
            Update Requisition Line
        </Button>
    );
};

UpdateRequisitionLineButton.propTypes = {
    product: PropTypes.object,
    index: PropTypes.number,
    nonCatFormValidator: PropTypes.func,
};

export default UpdateRequisitionLineButton;
