import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import alertDispatcher from "../../../utils/alertsUtils";
import { dateFormat } from "../../../utils/general";
import { getApiExchangeRate } from "../../procurement/procurementAPIs";

const useProcurement = () => {
    const dispatch = useDispatch();
    const [isLoadingExchangeRate, setIsLoadingExchangeRate] = useState(false);

    const getExchangeRate = useCallback(
        /**
         * @param {string} fromCurrencyCode
         * @param {string} toCurrencyCode
         * @param {Date} rateDate
         * @returns {Promise<number|undefined>}
         */
        async (fromCurrencyCode, toCurrencyCode, rateDate) => {
            setIsLoadingExchangeRate(true);

            if (fromCurrencyCode === toCurrencyCode) return 1;

            try {
                const response = await getApiExchangeRate(
                    fromCurrencyCode,
                    toCurrencyCode,
                    dateFormat(rateDate, "YYYY-MM-DD")
                );

                return response.data.rate;
            } catch (error) {
                alertDispatcher(
                    "getExchangeRate",
                    error?.response?.status,
                    dispatch
                );
                // "" is equivalent to null. It will be caught
                // by form validator, preventing submission
                return "";
            } finally {
                setIsLoadingExchangeRate(false);
            }
        },
        [dispatch]
    );

    return { getExchangeRate, isLoadingExchangeRate };
};

export default useProcurement;
