import { createContext, useState } from "react";

export const SingleRequisitionSummaryContext = createContext({
    changeOwnerIsOpen: false,
    setChangeOwnerIsOpen: () => {},
});

export const SingleRequisitionSummaryProvider = ({ children }) => {
    const [changeOwnerIsOpen, setChangeOwnerIsOpen] = useState(false);

    return (
        <SingleRequisitionSummaryContext.Provider
            value={{ changeOwnerIsOpen, setChangeOwnerIsOpen }}
        >
            {children}
        </SingleRequisitionSummaryContext.Provider>
    );
};
