import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { FormControlLabel, Stack, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import DetailListVertical from "components/displays/detailListVertical";
import IBuyPaper from "components/displays/iBuyPaper";
import { TOOLTIP_BASE_CURRENCY_TOGGLE } from "constants/tooltipText";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDisplayInReqCurrency } from "redux/actions/singleReqSummaryActions";
import { arrayOnCondition } from "utils/general";

import {
    INTERNAL_REQUISITION,
    RETURNED,
} from "../../../../constants/generalConstants";
import {
    INVOICE_SUMMARY_PAGE,
    PO_SUMMARY_PAGE,
} from "../../../../constants/routes";
import { subHeaderProps } from "../../../../styled/props/subHeaderProps";
import { openReceiptSummaryDialogue } from "../../../../utils/receiptSummaryUtils";
import CopyWrapper from "../../../displays/copyWrapper";
import CurrencyFigure from "../../../displays/currencyFigure";
import { sourcingChipDispatcher } from "../header/headerChips";

const SummaryComposition = ({
    req,
    POs,
    reqLinesLoadingState,
    receiptsLoading,
    receiptNumbers,
    // TODO - Check if we're fine not having this button
    // eslint-disable-next-line no-unused-vars
    navigateToReceiptSummary,
    invoices,
    navigate,
}) => {
    const displayInReqCurrency = useSelector(
        (state) => state.singleReqSummary.displayInReqCurrency
    );
    const dispatch = useDispatch();

    const summaryDetails = [
        {
            header: "Harmony Req Number",
            content: req?.HARMONY_REQUISITION_NUMBER
                ? req?.HARMONY_REQUISITION_NUMBER
                : "Not yet issued",
        },
        {
            header: "Date Raised",
            content: moment(req.submittedDate).format("DD/MM/YYYY"),
        },
        {
            header: "Description",
            content: req?.requisitionHeaderForm.HEADER_DESCRIPTION,
        },
        {
            header: "Number of Lines",
            content: `${req.requisitionLines.length}`,
        },
        {
            header: "Sourcing Category",
            content:
                sourcingChipDispatcher[
                    req.requisitionLines[0].SOURCE_TYPE_CODE
                ],
        },
        {
            header: "Purchase Orders",
            content:
                POs.length > 0 ? (
                    <>
                        {POs.map((PO) => (
                            <Stack
                                display="inline-block"
                                key={PO.PO_NUMBER}
                                direction="row"
                                spacing={0}
                            >
                                <Link
                                    component="button"
                                    color="secondary"
                                    onClick={() =>
                                        navigate(PO_SUMMARY_PAGE(PO?.PO_NUMBER))
                                    }
                                >
                                    {PO?.PO_NUMBER}
                                </Link>
                                <CopyWrapper value={PO.PO_NUMBER} />
                            </Stack>
                        ))}
                    </>
                ) : (
                    "No POs issued"
                ),
            isLoading: reqLinesLoadingState,
            disableCopy: true,
        },
        {
            header: "Receipts",
            content:
                receiptNumbers.length > 0 ? (
                    <>
                        {receiptNumbers.map((receiptNum) => (
                            <Stack
                                display="inline-block"
                                direction="row"
                                key={receiptNum}
                                spacing={0}
                            >
                                <Link
                                    component="button"
                                    color="secondary"
                                    onClick={() =>
                                        openReceiptSummaryDialogue(
                                            receiptNum,
                                            dispatch
                                        )
                                    }
                                >
                                    {receiptNum}
                                </Link>
                                <CopyWrapper value={receiptNum} />
                            </Stack>
                        ))}
                        <Tooltip
                            placement="top"
                            title="Go to requisition receipting summary"
                            disableInteractive
                        >
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                onClick={navigateToReceiptSummary}
                                sx={{
                                    height: "19px",
                                }}
                            >
                                <ArrowForwardOutlinedIcon
                                    sx={{
                                        height: "17px",
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </>
                ) : req?.SOURCING_TYPE == INTERNAL_REQUISITION ? (
                    "Receipting not required"
                ) : (
                    "No receipts created"
                ),
            disableCopy: true,
            isLoading: receiptsLoading,
        },
        {
            header: "Invoices",
            content:
                invoices.length > 0
                    ? invoices.map((invoice, index) => (
                          <Stack
                              display="inline-block"
                              direction="row"
                              key={invoice?.INVOICE_NUM}
                              spacing={0}
                          >
                              <Link
                                  key={index}
                                  component="button"
                                  color="secondary"
                                  sx={{ mr: "8px" }}
                                  onClick={() =>
                                      navigate(
                                          INVOICE_SUMMARY_PAGE(
                                              invoice?.INVOICE_ID
                                          )
                                      )
                                  }
                              >
                                  {invoice?.INVOICE_NUM}
                              </Link>
                              <CopyWrapper value={invoice?.INVOICE_NUM} />
                          </Stack>
                      ))
                    : "No invoices received",
            disableCopy: true,
            isLoading: reqLinesLoadingState,
        },
        ...arrayOnCondition(req?.status == RETURNED, {
            header: "Buyer Notes",
            content: req?.REQ_APPRVL_NOTE || "No buyer return note provided",
        }),
    ];

    return (
        <IBuyPaper>
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <Typography {...subHeaderProps} gutterBottom>
                        Summary
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Box
                        display={"flex"}
                        justifyContent="flex-end"
                        alignItems="flex-end"
                    >
                        <Typography
                            color="text.secondary"
                            gutterBottom
                            variant={"body1"}
                            sx={{ mr: "12px" }}
                        >
                            Total
                        </Typography>
                        <Typography variant={"h3"}>
                            <CurrencyFigure
                                value={
                                    displayInReqCurrency
                                        ? req?.totalPriceReqCurrency
                                        : req?.totalPriceBaseCurrency
                                }
                                code={
                                    displayInReqCurrency
                                        ? req.requisitionLines[0]?.CURRENCY_CODE
                                        : req?.ouBaseCurrency
                                }
                            />
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <DetailListVertical details={summaryDetails} condensed />
                </Grid>
                {req?.reqCurrencyEqOuBase === false && (
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    color="secondary"
                                    checked={!displayInReqCurrency}
                                />
                            }
                            label={`Display prices in ${req?.ouBaseCurrency}`}
                            onChange={(e) => {
                                dispatch(
                                    setDisplayInReqCurrency(!e.target.checked)
                                );
                            }}
                        />
                        <Tooltip
                            title={TOOLTIP_BASE_CURRENCY_TOGGLE(
                                req.requisitionLines[0]?.CURRENCY_CODE,
                                req?.ouBaseCurrency,
                                req?.requisitionHeaderForm?.ORG_NAME
                            )}
                            placement="top"
                        >
                            <IconButton onClick={null} sx={{ ml: "-8px" }}>
                                <HelpOutlineRoundedIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>
        </IBuyPaper>
    );
};

export default SummaryComposition;
