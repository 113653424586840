import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import React from "react";

const AllRequisitionsSkeleton = () => {
    return (
        <Grid container spacing={2}>
            {[...Array(10).keys()].map((v) => {
                return (
                    <Grid item xs={12} key={v}>
                        <Skeleton
                            aria-label="tableRow"
                            height={50}
                            variant="rectangular"
                            sx={{
                                borderRadius: "10px",
                                boxSizing: "border-box",
                            }}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};
export default AllRequisitionsSkeleton;
