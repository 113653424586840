import {
    SET_DATE_GTE,
    SET_DATE_LTE,
    SET_MODE,
    SET_OU,
    SET_PO_NUMBER_SEARCH,
    SET_PRICE_RANGE,
    SET_PROJECT_SEARCH,
    SET_RECEIPT_REQ_STATUS,
    SET_RECEIPT_STATUS,
    SET_RECEIPT_YEAR,
    SET_REQUESTER_SEARCH,
    SET_REQUISITION_NUMBER_SEARCH,
    SET_SHOW_CANCELLED,
    SET_STATUS,
    SET_SUPPLIER_SEARCH,
} from "../actionTypes";
import { initialState } from "../initialState";

const reqFilterPanelReducer = (state = initialState.reqFilterPanel, action) => {
    switch (action.type) {
        case SET_MODE:
            return {
                ...state,
                mode: action.payload,
            };
        case SET_RECEIPT_STATUS:
            return {
                ...state,
                receiptStatus: action.payload,
            };
        case SET_PRICE_RANGE:
            return {
                ...state,
                priceRange: action.payload,
            };
        case SET_PROJECT_SEARCH:
            return {
                ...state,
                projectSearch: action.payload,
            };

        case SET_OU:
            return {
                ...state,
                OU: action.payload,
            };
        case SET_REQUESTER_SEARCH:
            return {
                ...state,
                requesterSearch: action.payload,
            };
        case SET_REQUISITION_NUMBER_SEARCH:
            return {
                ...state,
                requisitionNumberSearch: action.payload,
            };
        case SET_SHOW_CANCELLED:
            return {
                ...state,
                showCancelled: action.payload,
            };
        case SET_PO_NUMBER_SEARCH:
            return {
                ...state,
                poNumberSearch: action.payload,
            };
        case SET_STATUS:
            return {
                ...state,
                status: action.payload,
            };
        case SET_SUPPLIER_SEARCH:
            return {
                ...state,
                supplierSearch: action.payload,
            };
        case SET_DATE_GTE:
            return {
                ...state,
                dateGTE: action.payload,
            };
        case SET_DATE_LTE:
            return {
                ...state,
                dateLTE: action.payload,
            };
        case SET_RECEIPT_REQ_STATUS:
            return {
                ...state,
                receiptReqStatus: action.payload,
            };
        case SET_RECEIPT_YEAR:
            return {
                ...state,
                receiptYear: action.payload,
            };
        default:
            return state;
    }
};

export default reqFilterPanelReducer;
