import { Stack } from "@mui/material";

import { RequisitionSummaryType } from "../../../../../types/propTypes";
import RequisitionSummaryCard from "./requisitionSummaryCard";

const RequisitionSummaryCards = ({ reqs }) => {
    return (
        <Stack spacing={2}>
            {reqs.map((req) => (
                <RequisitionSummaryCard key={req.id} req={req} />
            ))}
        </Stack>
    );
};

RequisitionSummaryCards.propTypes = RequisitionSummaryType;

export default RequisitionSummaryCards;
