import { Divider, Typography } from "@mui/material";
import DetailListVertical from "components/displays/detailListVertical";
import { RETURN_TO_VENDOR } from "constants/generalConstants";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { arrayOnCondition } from "utils/general";

/**
 * Converts redux return reason (from filterPanelLookups redux slice)
 * from array format `[{REASON_ID: 0, REASON: "Name of reason"}]`
 * into an object in the form: `{0: "Name of reason"}`
 * @param {Object} returnReasonsStateArray - redux `state` from `useSelector` callback
 * @returns {Object} - transformed return reasons in form `{0: "Name of reason"}`
 */
export const parseReturnReasonsIntoObject = (reduxState) => {
    const reasonsArray = reduxState.filterPanelLookups.RETURN_REASONS || [];
    return reasonsArray.reduce(
        (acc, reason) => ({
            ...acc,
            [reason?.REASON_ID]: reason?.REASON,
        }),
        {}
    );
};

const ReceiptTransactionsList = ({ receiptTransactions }) => {
    const returnReasons = useSelector((state) =>
        parseReturnReasonsIntoObject(state)
    );
    return (
        <>
            {receiptTransactions.map((receiptTransaction) => (
                <React.Fragment key={receiptTransaction.LINE_NUM}>
                    <Divider textAlign="left">
                        <Typography variant="body1">
                            #{receiptTransaction.LINE_NUM}
                        </Typography>
                    </Divider>
                    <DetailListVertical
                        details={[
                            {
                                header: "Transaction Type",
                                content: receiptTransaction.TRANSACTION_TYPE,
                            },
                            {
                                header: "Transaction Date",
                                content: receiptTransaction.TRANSACTION_DATE,
                            },
                            {
                                header: "Transacted By",
                                content: receiptTransaction.TRANSACTED_BY,
                            },
                            {
                                header: "Unit of Measure",
                                content:
                                    receiptTransaction.UNIT_OF_MEASURE_DESCRIPTION,
                            },
                            {
                                header: "Quantity",
                                content: receiptTransaction.QUANTITY,
                            },
                            ...arrayOnCondition(
                                receiptTransaction?.TRANSACTION_TYPE ===
                                    RETURN_TO_VENDOR,
                                {
                                    header: "Return Reason",
                                    content:
                                        returnReasons[
                                            receiptTransaction?.REASON_ID
                                        ] || "-",
                                }
                            ),
                            ...arrayOnCondition(
                                receiptTransaction?.TRANSACTION_TYPE ===
                                    RETURN_TO_VENDOR,
                                {
                                    header: "RMA Reference",
                                    content:
                                        receiptTransaction?.RMA_REFERENCE ||
                                        "-",
                                }
                            ),
                        ]}
                    />
                </React.Fragment>
            ))}
        </>
    );
};

ReceiptTransactionsList.propTypes = {
    receiptTransactions: PropTypes.array.isRequired,
};

export default ReceiptTransactionsList;
