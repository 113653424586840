import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import { useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { FAVOURITES_CART_NAME } from "../../constants/generalConstants";
import { MOBILE_MEDIA_QUERY } from "../../constants/mediaQueries";
import {
    postFavouritesCart,
    updateCartItems,
} from "../../services/cart/cartAPIs";
import alertDispatcher from "../../utils/alertsUtils";
import { amendedCartItems, inItems } from "../../utils/shoppingCartUtils";

const FavouriteButton = ({ product, positionAbsolute, white }) => {
    // Collect states and state dispatchers
    const userId = useSelector((state) => state.auth.samAccountName);
    const carts = useSelector((state) => state.shoppingCart);
    const favouritesId = useSelector((state) => state.general.favouritesId);
    const dispatch = useDispatch();
    const favourites = useSelector((state) => {
        const id = state.general.favouritesId;
        return id ? state.shoppingCart[id].items : [];
    });

    const addToFavourites = (event) => {
        event.preventDefault();
        if (favouritesId) {
            // Favourites cart already exists - add to cart
            const newItems = amendedCartItems(favourites, "ADD", {
                newItem: product,
                qtyIncrement: 1,
            });
            updateCartItems(favouritesId, dispatch, newItems, true, true);
        } else {
            // Favourites cart needs to be created - create cart with item in it
            const favCart = {
                userId: userId,
                name: FAVOURITES_CART_NAME,
                items: [{ ...product, QUANTITY: 1 }],
                enabled: true,
            };
            postFavouritesCart(favCart, dispatch);
        }
    };
    const removeFromFavourites = (event) => {
        event.preventDefault();
        alertDispatcher("removeFromFavourites", 200, dispatch);
        const amendedItems = amendedCartItems(
            carts[favouritesId].items,
            "REMOVE",
            product?.PO_LINE_ID
        );
        updateCartItems(favouritesId, dispatch, amendedItems, false, true);
    };
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);
    const loc = positionAbsolute
        ? isDesktop
            ? {
                  position: "absolute",
                  bottom: "0%",
                  right: "15%",
              }
            : {
                  position: "absolute",
                  bottom: "10%",
                  right: "0%",
              }
        : {};
    return (
        <>
            {inItems(product?.PO_LINE_ID, favourites) ? (
                <IconButton onClick={removeFromFavourites} sx={loc}>
                    <FavoriteOutlinedIcon
                        color="pink"
                        aria-label="favouriteSelectedIcon"
                    />
                </IconButton>
            ) : (
                <IconButton onClick={addToFavourites} sx={loc}>
                    <FavoriteBorderIcon
                        color={white ? "iconButton" : "primary"}
                        aria-label="favouriteUnselectedIcon"
                    />
                </IconButton>
            )}
        </>
    );
};

FavouriteButton.defaultProps = {
    product: {},
    positionAbsolute: false,
    white: false,
};

FavouriteButton.propTypes = {
    product: PropTypes.object,
    positionAbsolute: PropTypes.bool,
    white: PropTypes.bool,
};

export default FavouriteButton;
