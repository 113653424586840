import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IBuyPaper from "components/displays/iBuyPaper";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { MOBILE_MEDIA_QUERY } from "../../constants/mediaQueries";
import { DecrementApprovalsCount } from "../../redux/actions/generalActions";
import { listRequisitionsForApproval } from "../../services/requisition/requisitionAPIs";
import { contentBoxL2 } from "../../styled/styledSXProperties/styledBoxSX";
import ApprovalsCardSkeleton from "../skeletons/approvalsCardSkeleton";
import ApprovalProxySidebar from "./approvalProxy/approvalProxySidebar";
import ApprovalsCard from "./approvalsCard";
import ApprovalsFilterPanel from "./approvalsFilterPanel";

const REQS_PER_PAGE = 8;

const Approvals = () => {
    const dispatch = useDispatch();
    const samAccountName = useSelector((state) => state.auth.samAccountName);
    const [isLoading, setIsLoading] = useState(true);
    const [showApprovals, setShowApprovals] = useState(false);
    const [showApprovalFilters, setShowApprovalFilters] = useState(false);
    const [page, setPage] = useState(1);
    const [results, setResults] = useState(Array(20).fill(null));
    const [searchParams] = useSearchParams();
    const [totalResults, setTotalResults] = useState(0);

    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    const dialogueState = useSelector(
        (state) => state.general.showApprovalsDialogue.reqId
    );

    // Preset sort dropdown list based on URL params
    useEffect(() => {
        // Read URL and set state
        searchParams.set("n", REQS_PER_PAGE);
        getReqs();
        setPage(1);
    }, [searchParams, dialogueState]);

    const getReqs = async () => {
        const res = await listRequisitionsForApproval(
            dispatch,
            searchParams.toString(),
            setIsLoading,
            setTotalResults,
            false,
            samAccountName
        );
        setResults(res);
    };

    const popFromResults = (requisitionId, action) => {
        const newResults = results.map((req) => {
            if (requisitionId == req.id) {
                req.approvals_info.currentApprover = null; // not neccesarily null, but this information isn't required anyway,
                req.approvals_info.action = action;
            }
            return req;
        });
        setResults(newResults);
        dispatch(DecrementApprovalsCount());
    };

    // Note, pagination offset params are not appended to search params URL
    // Refer to iBuy wikki: Azure iBuy application > iBuy features > 3.5
    const getNextPage = async () => {
        searchParams.set("offset", page * REQS_PER_PAGE);
        // const response = { data: sample_response.data };
        const response = await listRequisitionsForApproval(
            dispatch,
            searchParams.toString(),
            setIsLoading,
            setTotalResults,
            true,
            samAccountName
        );
        searchParams.delete("offset"); // Offset params not permanently stored in URL.
        // const nextPage = response.data ? response.data : [];
        const nextPage = response ? response : [];
        setResults([...results, ...nextPage]);
        setPage(page + 1);
    };

    return (
        <Box sx={{ ...contentBoxL2, display: "flex" }}>
            <Grid
                container
                spacing={3}
                justifyContent={isDesktop ? "flex-start" : "center"}
            >
                <Grid item xs={12}>
                    <Box
                        display="flex"
                        alignItems="flex-start"
                        justifyContent={isDesktop ? "right" : "center"}
                        flexWrap="wrap"
                        gap={"1rem"}
                    >
                        <ApprovalProxySidebar
                            isOpen={showApprovals}
                            closeSidebarCallback={setShowApprovals}
                        />
                        <Button
                            color="secondary"
                            onClick={() => {
                                setShowApprovals(true);
                            }}
                            variant="contained"
                            startIcon={<PersonAddIcon />}
                        >
                            Delegations
                        </Button>
                        <ApprovalsFilterPanel
                            isOpen={showApprovalFilters}
                            closeSidebarCallback={setShowApprovalFilters}
                            setPage={setPage}
                            getReqs={getReqs}
                            isDesktop={isDesktop}
                        />
                        <Button
                            color="secondary"
                            onClick={() => {
                                setShowApprovalFilters(true);
                            }}
                            variant="contained"
                            startIcon={<FilterAltIcon />}
                        >
                            Filter and Sort
                        </Button>
                    </Box>

                    <Typography
                        variant="h6"
                        sx={{
                            color: "text.primary",
                            marginRight: "20px",
                            textAlign: isDesktop ? "right" : "center",
                            marginTop: "20px",
                            fontWeight: "bold",
                        }}
                    >
                        {"Approvals: "}
                        <NumberFormat
                            fixedDecimalScale
                            value={`${totalResults.toLocaleString()}`}
                            displayType="text"
                        />
                    </Typography>
                </Grid>
                {results.length === 0 && !isLoading ? (
                    <Grid item xs={12} paddingLeft={"20px"} paddingTop={"20px"}>
                        <IBuyPaper>
                            <Typography variant="body1" fontWeight="bold">
                                No requisitions pending approval
                            </Typography>
                        </IBuyPaper>
                    </Grid>
                ) : (
                    <>
                        {results.map((item, index) => {
                            return (
                                <Grid
                                    key={index}
                                    item
                                    xs={11}
                                    md={6}
                                    lg={4}
                                    xl={3}
                                >
                                    {isLoading ? (
                                        <ApprovalsCardSkeleton />
                                    ) : (
                                        <ApprovalsCard
                                            requisition={item}
                                            popFromResults={popFromResults}
                                        />
                                    )}
                                </Grid>
                            );
                        })}
                        {/* If there are pages to scroll through, render see more button */}
                        {page < Math.ceil(totalResults / REQS_PER_PAGE) && (
                            <Grid
                                item
                                xs={12}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <Button
                                    color="secondary"
                                    onClick={getNextPage}
                                    sx={{ width: "20%" }}
                                >
                                    See More
                                </Button>
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </Box>
    );
};

export default Approvals;
