import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { updateCartItems } from "../../services/cart/cartAPIs";
import { primaryButtonSx } from "../../styled/styledSXProperties/styledButtonSX";
import alertDispatcher from "../../utils/alertsUtils";
import { closeNonCatDialogue } from "../../utils/nonCatFormUtils";
import { amendedCartItems } from "../../utils/shoppingCartUtils";
import { setAddToNewNamedCartItem } from "./../../redux/actions/generalActions";
import { setShowAddToNewNamedCartDialogue } from "./../../redux/actions/generalActions";
import { validateAddToCart } from "./../../utils/productListUtils";

const UpdateCartItemButton = ({ product, nonCatFormValidator }) => {
    // Collect states and state dispatchers
    const carts = useSelector((state) => state.shoppingCart);
    const darkMode = useSelector((state) => state.general.darkMode);
    const activeCart = useSelector((state) => state.general.activeCart);
    const dispatch = useDispatch();

    const clickUpdateCartItem = (event) =>
        nonCatFormValidator(() => updateCartItem())(event);

    const openAddToNewNamedCartDialogue = () =>
        dispatch(setShowAddToNewNamedCartDialogue(true));

    const updateCartItem = () => {
        /* Two routes:
        1) User updates cart item successfully
        2) User unable to update item to cart as supplier validation rules violated. User offered to
        add item to new cart

        Arguments
        ---------
        product : obj
        
        */
        const [validCartItem, errorMsg] = validateAddToCart(
            carts[activeCart].items,
            product
        );
        if (validCartItem || carts[activeCart].items.length == 1) {
            // Route 1 (see above)
            const newItems = amendedCartItems(
                carts[activeCart].items,
                "UPDATE_CART_ITEM",
                product
            );
            updateCartItems(activeCart, dispatch, newItems, true);
            closeNonCatDialogue(dispatch);
        } else {
            // Route 2 (see above)
            const action = {
                text: "Add to new cart",
                action: openAddToNewNamedCartDialogue,
            };
            alertDispatcher("updateCartItem", 400, dispatch, errorMsg, action);
            dispatch(
                setAddToNewNamedCartItem({ product: product, nonCatForm: true })
            );
        }
    };
    return (
        <Button
            onClick={clickUpdateCartItem}
            variant="contained"
            sx={primaryButtonSx(darkMode)}
        >
            Update Cart Item
        </Button>
    );
};

UpdateCartItemButton.propTypes = {
    product: PropTypes.object,
    nonCatFormValidator: PropTypes.func,
};

export default UpdateCartItemButton;
