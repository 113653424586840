import Tooltip from "@mui/material/Tooltip";
import getSymbolFromCurrency from "currency-symbol-map";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

const CurrencyFigure = ({ value, code, decimalPlaces = 2 }) => {
    return (
        <Tooltip disableInteractive title={`Currency: ${code}`} placement="top">
            <span>
                <NumberFormat
                    decimalScale={decimalPlaces}
                    fixedDecimalScale
                    value={value}
                    displayType={"text"}
                    thousandSeparator={true}
                    defaultValue="0"
                    prefix={getSymbolFromCurrency(code)}
                />
            </span>
        </Tooltip>
    );
};

CurrencyFigure.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    code: PropTypes.string,
    decimalPlaces: PropTypes.number,
};

CurrencyFigure.defaultProps = {
    decimalPlaces: 2,
};

export default CurrencyFigure;
