import { API_AUTOCOMPLETE_COOLDOWN_MS } from "../constants/generalConstants";

let throttleTimer = setTimeout(() => {}, 0); // initialize blank timeout

function useThrottle(customTimeout) {
    function throttleFunction(callbackFunc) {
        clearTimeout(throttleTimer);
        throttleTimer = setTimeout(
            callbackFunc,
            customTimeout ? customTimeout : API_AUTOCOMPLETE_COOLDOWN_MS
        );
    }

    return throttleFunction;
}

export default useThrottle;
