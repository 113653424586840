import {
    Grid,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableRow,
    Typography,
} from "@mui/material";
import OnDesktop from "components/wrappers/onDesktop";
import OnMobile from "components/wrappers/onMobile";
import PropTypes from "prop-types";
import React from "react";

import FormatNameValue from "./formatNameValue";
import IBuyCard from "./iBuyCard";

const subHeaderStyle = {
    color: "text.subheader",
    fontWeight: "bold",
    variant: "h5",
};

/**
 * Card to display summary information
 * @param {object} data
 * @param {boolean} isDesktop
 * @param {boolean} cardTitle
 * @param {number} colWidth
 * @returns
 */
const ProcurementCard = ({ data, isDesktop, cardTitle, colWidth }) => {
    const columnWidth = colWidth ?? 35;

    return (
        <>
            <OnDesktop>
                <IBuyCard additionalSx={{ p: isDesktop ? 1.5 : 1, mb: 0 }}>
                    <Grid item xs={12}>
                        <Typography {...subHeaderStyle}>
                            {data.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {data.data.map((field, i) => {
                            return field.component ? (
                                <React.Fragment key={i}>
                                    <Typography
                                        component="span"
                                        color="text.secondary"
                                        variant="body1"
                                    >
                                        {field.name}: &nbsp;
                                    </Typography>
                                    {field.component}
                                </React.Fragment>
                            ) : (
                                <FormatNameValue
                                    key={i}
                                    name={field.name}
                                    value={field.value}
                                    variant={"body1"}
                                />
                            );
                        })}
                    </Grid>
                </IBuyCard>
            </OnDesktop>
            <OnMobile>
                {cardTitle && (
                    <Typography
                        variant="h6"
                        color="text.subheader"
                        fontWeight="bold"
                        py={1}
                        px={2}
                    >
                        {data.title}
                    </Typography>
                )}
                <Grid item xs={12} pb={0.5}>
                    <Table
                        size="small"
                        sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                            },
                            display: "block",
                        }}
                    >
                        <colgroup>
                            <col style={{ width: `${columnWidth}%` }} />
                            <col style={{ width: `${100 - columnWidth}%` }} />
                        </colgroup>
                        <TableBody>
                            {data.data.map((field, i) => (
                                <TableRow key={i}>
                                    <TableCell
                                        align="left"
                                        style={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {field.name}
                                    </TableCell>
                                    <TableCell align="left">
                                        {field.component ? (
                                            <React.Fragment key={i}>
                                                {field.component}
                                            </React.Fragment>
                                        ) : (
                                            field.value ?? "-"
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </OnMobile>
        </>
    );
};
ProcurementCard.propTypes = {
    data: PropTypes.object,
    isDesktop: PropTypes.bool,
    cardTitle: PropTypes.bool,
    colWidth: PropTypes.number,
};

export default ProcurementCard;
