import { CORRECT_RECEIPT, RETURN_RECEIPT } from "../constants/generalConstants";
import {
    NO_RECEIPTED_ITEMS_RETURN,
    UNAVAILABLE_RECEIPT_CORRECTION,
    UNAVAILABLE_RECEIPT_RETURN,
} from "../constants/snackbarMessage";
import { setShowReceiptSummaryDialogue } from "../redux/actions/generalActions";
import {
    setReceiptSummaryID,
    setReceiptSummaryLoading,
    setReceiptSummaryPageNum,
} from "../redux/actions/receiptSummaryActions";
import {
    createReceiptEvents,
    getReceiptByNumber,
} from "../services/receipting/receiptingAPIs";
import store from "./../redux/store";
import alertDispatcher from "./alertsUtils";

export const openReceiptSummaryDialogue = (receiptNumber, dispatch) => {
    /*
    Arguments
    ---------
    receiptNumber : str
    dispatch : func
    */
    // Open the form, request required data
    // set number
    dispatch(setReceiptSummaryPageNum(0));
    dispatch(setReceiptSummaryLoading(true));
    dispatch(setReceiptSummaryID(receiptNumber));
    dispatch(setShowReceiptSummaryDialogue(true));
    getReceiptByNumber(receiptNumber, dispatch, store.getState().auth.activeOU);
};

export const closeReceiptSummaryDialogue = (dispatch) => {
    /*
    Arguments
    ---------
    dispatch : func
        */
    dispatch(setShowReceiptSummaryDialogue(false));
};

/**
 * Adds two fields (checked, updatedQuantity and reason) against
 * each receipt line to facilitate receipt checking
 * @param {object} receipt
 * @returns {object}
 */
export const addCorrectionProperties = (receipt) => {
    return {
        ...receipt,
        receiptLines: receipt.receiptLines.reduce(
            (acc, line) => [
                ...acc,
                {
                    ...line,
                    checked: true,
                    updatedQuantity: "",
                    reason: "",
                    checkedReturn: true,
                    returnQuantity: "",
                },
            ],
            []
        ),
    };
};

/**
 * Adds three fields (returnReason, returnRMA and returnComment) against receipt
 * to facilitate receipt checking
 * @param {object} receipt
 * @returns {object}
 */
export const addReturnProperties = (receipt) => {
    return {
        ...receipt,
        returnReasonID: "",
        returnRMA: "",
        returnComment: "",
    };
};

export const submitReceiptCorrection = (receipt, dispatch) => {
    /*
    Arguments
    ---------
    receipt : obj
    dispatch : func
    */
    const { receiptLines, ...receiptHeaderInfo } = receipt;
    const linesDefined = receiptLines ? receiptLines : [];
    const checkedLines = linesDefined.filter(
        (line) => line?.checked && line.AVAILABLE_FOR_CORRECTION
    );
    // Parse into array of receipting events. One receipting correction event is created for each checked receipt line
    const payload = checkedLines.reduce(
        (acc, line) => [
            ...acc,
            parseCorrectionPayload(line, receiptHeaderInfo),
        ],
        []
    );
    createReceiptEvents(payload, CORRECT_RECEIPT, dispatch);
};

export const submitReceiptReturn = (receipt, dispatch) => {
    /*
    Arguments
    ---------
    receipt : obj
    dispatch : func
    */
    const { receiptLines, ...receiptHeaderInfo } = receipt;
    const linesDefined = receiptLines ? receiptLines : [];
    const checkedLines = linesDefined.filter(
        (line) =>
            line?.checkedReturn &&
            line?.AVAILABLE_FOR_RETURN &&
            line?.QTY_RECEIVED_GTR_ZERO
    );
    // Parse into array of receipting events. One receipting return event is created for each checked receipt line
    const payload = checkedLines.reduce(
        (acc, line) => [...acc, parseReturnPayload(line, receiptHeaderInfo)],
        []
    );
    createReceiptEvents(payload, RETURN_RECEIPT, dispatch);
};

const parseReceiptHeader = (receiptHeaderInfo) => ({
    IBUY_RECEIPT_ID: receiptHeaderInfo?.IBUY_RECEIPT_ID,
    VENDOR_ID: receiptHeaderInfo?.SUPPLIER_ID,
    VENDOR_SITE_ID: receiptHeaderInfo?.SUPPLIER_SITE_ID,
    PACKING_SLIP: receiptHeaderInfo?.RECEIPT_NUM,
});

export const parseCorrectionPayload = (rcvLine, receiptHeaderInfo) => {
    /*
    Arguments
    ---------
    rcvLine : obj
    receiptHeaderInfo : obj
    */
    const receiptHeader = parseReceiptHeader(receiptHeaderInfo);
    // `quantity_change` rounded to two decimal places to avoid floating arithmetic errors
    const receiptTransactions = [
        {
            original_quantity: parseFloat(rcvLine?.QUANTITY_RECEIVED),
            updated_quantity: parseFloat(rcvLine?.updatedQuantity),
            quantity_change:
                Math.round(
                    (parseFloat(rcvLine?.updatedQuantity) -
                        parseFloat(rcvLine?.QUANTITY_RECEIVED)) *
                        100
                ) / 100,
            UNIT_OF_MEASURE: rcvLine?.UNIT_OF_MEASURE_MEANING,
            SHIP_TO_LOCATION_ID: rcvLine?.SHIP_TO_LOCATION_ID,
            PO_HEADER_ID: rcvLine?.PO_HEADER_ID,
            PO_LINE_ID: rcvLine?.PO_LINE_ID,
            PO_LINE_LOCATION_ID: rcvLine?.PO_LINE_LOCATION_ID,
            COMMENTS: rcvLine?.reason,
            VALIDATION_FLAG: "Y",
            PARENT_TRANSACTION_ID: rcvLine?.PARENT_TRANSACTION_ID,
        },
    ];
    const payload = {
        correction: true,
        return_item: false,
        receiptHeader,
        receiptTransactions,
    };
    return payload;
};

export const parseReturnPayload = (rcvLine, receiptHeaderInfo) => {
    /*
    Arguments
    ---------
    rcvLine : obj
    receiptHeaderInfo : obj
    */
    const receiptHeader = parseReceiptHeader(receiptHeaderInfo);

    const receiptTransactions = [
        {
            return_quantity: parseFloat(rcvLine?.returnQuantity),
            UNIT_OF_MEASURE: rcvLine?.UNIT_OF_MEASURE_MEANING,
            SHIP_TO_LOCATION_ID: rcvLine?.SHIP_TO_LOCATION_ID,
            PO_HEADER_ID: rcvLine?.PO_HEADER_ID,
            PO_LINE_ID: rcvLine?.PO_LINE_ID,
            PO_LINE_LOCATION_ID: rcvLine?.PO_LINE_LOCATION_ID,
            COMMENTS: receiptHeaderInfo?.returnComment,
            PARENT_TRANSACTION_ID: rcvLine?.PARENT_TRANSACTION_ID,
            REASON_ID: receiptHeaderInfo?.returnReasonID,
            RMA_REFERENCE: receiptHeaderInfo?.returnRMA,
        },
    ];
    const payload = {
        correction: false,
        return_item: true,
        receiptHeader,
        receiptTransactions,
    };
    return payload;
};

export const showCorrectionNotAvailableSnackbar = (
    someLinesAvailableForCorrection,
    dispatch
) => {
    /* This function generates a warning message displayed when the user
    opens the correct receipt dialogue screen.

    Arguments
    ---------
    someLinesAvailableForCorrection : bool
    dispatch : func

    */

    // Trigger snackbar only if message defined
    !someLinesAvailableForCorrection &&
        alertDispatcher(
            "receiptCorrectMessage",
            404,
            dispatch,
            UNAVAILABLE_RECEIPT_CORRECTION
        );
};

/**
 * This function generates a warning message displayed when the user
    opens the return receipt dialogue screen.
 * @param {boolean} someLinesAvailableForReturn
 * @param {boolean} someLinesHasItemsReceived
 * @param {Function} dispatch
 * @returns {any}
 */
export const showReturnNotAvailableSnackbar = (
    someLinesAvailableForReturn,
    someLinesHasItemsReceived,
    dispatch
) => {
    // Trigger snackbar only if message defined
    if (!someLinesHasItemsReceived) {
        alertDispatcher(
            "receiptCorrectMessage",
            404,
            dispatch,
            NO_RECEIPTED_ITEMS_RETURN
        );
    } else if (!someLinesAvailableForReturn) {
        alertDispatcher(
            "receiptCorrectMessage",
            404,
            dispatch,
            UNAVAILABLE_RECEIPT_RETURN
        );
    }
};

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export const distinctReceiptNumsFromGetReceiptsByReqIDApi = (
    receiptsByReqIdResponse
) => {
    /* 
    Arguments
    ---------
    receiptsByReqIdResponse : array
    */
    const receiptsByReqIdResponseTypeChecked =
        receiptsByReqIdResponse.constructor == Array
            ? receiptsByReqIdResponse
            : [];
    // Generate an array of all receipt numbers
    const allReceiptNumbers = receiptsByReqIdResponseTypeChecked.reduce(
        (acc, reqLine) => {
            const receiptLinesTypeChecked = reqLine?.receiptLines
                ? reqLine?.receiptLines
                : [];
            const receiptNumsPerReqLine = receiptLinesTypeChecked.map(
                (receiptLine) => receiptLine?.RECEIPT_NUM
            );
            return [...acc, ...receiptNumsPerReqLine];
        },
        []
    );
    // Return unique list of receipt numbers
    return allReceiptNumbers.filter(onlyUnique);
};
