import {
    APPROVED,
    CANCELLED,
    CREATED,
    IN_PROCESS,
    INCOMPLETE,
    INTERNAL_REQUISITION,
    PRE_APPROVED,
    REJECTED,
    RETURNED,
    VALID_OWNER_CHANGE_STATUSES,
} from "../constants/generalConstants";
import {
    TOOLTIP_CANNOT_RECEIPT_NO_PO,
    TOOLTIP_RECEIPTING_NOT_REQUIRED,
} from "../constants/tooltipText";

const VALID_STATUSES_FOR_AMENDMENTS = [
    APPROVED,
    CREATED,
    IN_PROCESS,
    INCOMPLETE,
    PRE_APPROVED,
    REJECTED,
    RETURNED,
];

const VALID_STATUSES_FOR_CANCELLATIONS = [
    APPROVED,
    CREATED,
    PRE_APPROVED,
    REJECTED,
    RETURNED,
];

const VALID_STATUSES_FOR_RECEIVING = [APPROVED, CREATED, PRE_APPROVED];

/**
 * Returns tooltip for the amend button for a requisition
 * @param {object} req - the requisition to which the tooltip applies
 * @param {Array} userOUs - the operating units the user belongs to
 * @returns {string} The tooltip message
 */
export const amendReqButtonTooltip = (req, userOUs) => {
    let tooltip;
    switch (true) {
        case canReqBeAmended(req, userOUs):
            tooltip = "Amend Requisition";
            break;
        case !req.userOwned:
            tooltip = "It is not possible to amend other users' requisitions";
            break;
        case req?.SOURCING_TYPE == INTERNAL_REQUISITION:
            tooltip =
                "This requisition is internally sourced and has an associated internal sales order. Therefore, it is unavailable for amendments.";
            break;
        case !(
            req?.HARMONY_REQUISITION_HEADER_ID &&
            req?.HARMONY_REQUISITION_NUMBER
        ):
            tooltip =
                "This requisition is unavailable for amendments as it has not yet been processed by harmony.";
            break;
        case !VALID_STATUSES_FOR_AMENDMENTS.includes(req?.status):
            tooltip = `A requisition cannot be amended when its status is ${req?.status}`;
            break;
        case !req?.CHARGE_ACCOUNT_GENERATED:
            tooltip =
                "A charge account hasn't been generated for this requisition yet - check back soon!";
            break;
        default:
            tooltip =
                "This requisition is currently unavailable for amendments";
            break;
    }
    return tooltip;
};

/**
 * All users (within the req's OU) can cancel a requisition pre-po.
 * Only the owner of the requisition can cancel it post PO. This
 * function applies this equality test.
 * @param {*} req - requisition object
 * @returns {boolean} - whether the user is allowed to cancel the requisition
 */
export const canUserCancelRequisition = (req) => {
    const poNumbers = req?.PO_NUMBERS || [];
    return poNumbers.length > 0 ? req?.userOwned : true;
};

/**
 * Returns tooltip for the cancellation button for a requisition
 * @param {object} req - the requisition to which the tooltip applies
 * @param {Array} userOUs - the operating units the user belongs to
 * @returns {string} The tooltip message
 */
export const cancelReqButtonTooltip = (req, userOUs) => {
    let tooltip;
    switch (true) {
        case canReqBeCancelled(req, userOUs):
            tooltip = "Cancel Requisition";
            break;
        case req?.status == CANCELLED:
            tooltip = "This requisition has already been fully cancelled";
            break;
        case req?.SOURCING_TYPE == INTERNAL_REQUISITION:
            tooltip =
                "This requisition is internally sourced and has an associated internal sales order. Therefore, it is unavailable for cancellation.";
            break;
        case !(
            req?.HARMONY_REQUISITION_HEADER_ID &&
            req?.HARMONY_REQUISITION_NUMBER
        ):
            tooltip =
                "This requisition is unavailable for cancellation as it has not yet been processed by harmony.";
            break;
        case !VALID_STATUSES_FOR_CANCELLATIONS.includes(req?.status):
            tooltip = `A requisition cannot be cancelled when its status is ${req?.status}`;
            break;
        case !canUserCancelRequisition(req):
            tooltip =
                "This requisition has an associated PO, meaning only its owner can cancel it";
            break;
        case !req?.CHARGE_ACCOUNT_GENERATED:
            tooltip =
                "A charge account hasn't been generated for this requisition yet.";
            break;
        default:
            tooltip =
                "This requisition is currently unavailable for cancellation";
            break;
    }
    return tooltip;
};

/**
 * A requisition can be amended provided it is in a valid state, it is not
 * externally sourced, the user is in the operating unit of the requisition
 * and the user owns the requisition.
 * @param {object} req the requisition to which the tooltip applies
 * @param {Array} userOUs the operating units the user belongs to
 * @returns {boolean} flag determining if req can be amended
 */
export const canReqBeAmended = (req, userOUs) => {
    return (
        req.userOwned === true &&
        req?.SOURCING_TYPE != INTERNAL_REQUISITION &&
        VALID_STATUSES_FOR_AMENDMENTS.includes(req?.status) &&
        userOUs.includes(req.requisitionHeaderForm?.ORG_NAME) &&
        !!req?.HARMONY_REQUISITION_HEADER_ID &&
        !!req?.HARMONY_REQUISITION_NUMBER &&
        !!req?.CHARGE_ACCOUNT_GENERATED
    );
};

/**
 * A requisition can be cancelled provided it is in a
 valid state, it is not externally sourced and the
 user is in the operating unit of the requisition.
 * @param {object} req requisition object
 * @param {Array} userOUs list of OUs the user belongs to
 * @returns {boolean} flag determining if req can be cancelled
 */
export const canReqBeCancelled = (req, userOUs) => {
    return (
        canUserCancelRequisition(req) &&
        req?.SOURCING_TYPE != INTERNAL_REQUISITION &&
        VALID_STATUSES_FOR_CANCELLATIONS.includes(req?.status) &&
        userOUs.includes(req.requisitionHeaderForm?.ORG_NAME) &&
        !!req?.HARMONY_REQUISITION_HEADER_ID &&
        !!req?.HARMONY_REQUISITION_NUMBER &&
        !!req?.CHARGE_ACCOUNT_GENERATED
    );
};

/**
 * A requisition can be copied provided it the user is in the operating
 * unit of the requisition.
 * @param {object} req the requisition to which the tooltip applies
 * @param {Array} userOUs the operating units the user belongs to
 * @returns {boolean} flag determining if req can be copied
 */
export const canReqBeCopied = (req, userOUs) => {
    return userOUs.includes(req.requisitionHeaderForm?.ORG_NAME);
};

/**
 * A requisition can be receipted provided it is in a
 * created state, it is not externally sourced, the
 * user is in the operating unit of the requisition and
 * it has a PO assigned against it.
 * @param {object} req the requisition to which the tooltip applies
 * @param {Array} userOUs the operating units the user belongs to
 * @returns {boolean} flag determining if req can be receipted
 */
export const canReqBeReceipted = (req, userOUs) => {
    const poNumbers = req?.PO_NUMBERS || [];
    return (
        req?.SOURCING_TYPE != INTERNAL_REQUISITION &&
        VALID_STATUSES_FOR_RECEIVING.includes(req?.status) &&
        userOUs.includes(req.requisitionHeaderForm?.ORG_NAME) &&
        poNumbers.length > 0
    );
};

/**
 * Returns tooltip for the copy button for a requisition
 * @param {object} req the requisition to which the tooltip applies
 * @param {Array} userOUs the operating units the user belongs to
 * @returns {string} The tooltip message
 */
export const copyReqButtonTooltip = (req, userOUs) => {
    return canReqBeCopied(req, userOUs)
        ? "Copy Requisition"
        : `This requisition cannot be copied as you are not a member of its operating unit: ${req.requisitionHeaderForm?.ORG_NAME}`;
};

/**
 * Returns tooltip for the receipt button for a requisition
 * @param {object} req the requisition to which the tooltip applies
 * @param {Array} userOUs the operating units the user belongs to
 * @returns {string} The tooltip message
 */
export const receiptReqButtonTooltip = (req, userOUs) => {
    let tooltip;
    const poNumbers = req?.PO_NUMBERS || [];
    switch (true) {
        case canReqBeReceipted(req, userOUs):
            tooltip = "Create receipt for this requisition";
            break;
        case poNumbers.length == 0:
            tooltip = TOOLTIP_CANNOT_RECEIPT_NO_PO;
            break;
        case req?.SOURCING_TYPE == INTERNAL_REQUISITION:
            tooltip = TOOLTIP_RECEIPTING_NOT_REQUIRED;
            break;
        case !userOUs.includes(req.requisitionHeaderForm?.ORG_NAME):
            tooltip = `This requisition cannot be receipted as you are not a member of its operating unit: ${req.requisitionHeaderForm?.ORG_NAME}`;
            break;
        case !VALID_STATUSES_FOR_RECEIVING.includes(req?.status):
            tooltip = `A requisition with a status ${req?.status} cannot be received.`;
            break;
        default:
            tooltip =
                "This requisition is currently unavailable for receipting";
            break;
    }
    return tooltip;
};

/**
 * @param {Object} req
 * @param {boolean} isUserProcurementManager
 * @returns {string}
 */
export const canChangeOwner = (req, isUserProcurementManager) => {
    return (
        VALID_OWNER_CHANGE_STATUSES.includes(req.status) &&
        (req.userOwned || req.isSupervisor || isUserProcurementManager)
    );
};

/**
 * @param {Object} req
 * @param {boolean} isUserProcurementManager
 * @returns {string}
 */
export const changeOwnerTooltip = (req, isUserProcurementManager) => {
    switch (true) {
        case canChangeOwner(req, isUserProcurementManager):
            return "Change owner";
        case !VALID_OWNER_CHANGE_STATUSES.includes(req.status):
            return `You cannot change the owner of a requisition with a ${req.status} status`;
        case !req.userOwned && !req.isSupervisor && !isUserProcurementManager:
            return "You don't have permission to modify the owner of this requisition";
        default:
            return "The owner of this requisition can't be changed";
    }
};
