import { SvgIcon } from "@mui/material";

const Logo = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M12.617.375c.744.35 1.147.732 1.353 1.257.144.391.154.535.051.938-.299 1.195-1.065 1.814-4.349 3.493-1.24.639-2.603 1.381-3.016 1.649-2.159 1.391-3.336 2.793-3.926 4.689-.341 1.102-.341 3.277 0 4.472.786 2.721 2.686 4.657 5.92 6.049.825.361 2.852.979 2.933.896.02-.02-.217-.166-.527-.329-1.023-.535-1.405-1.401-1.033-2.36.372-.979 1.435-1.761 4.525-3.318 3.223-1.628 4.711-2.73 5.764-4.266 2.138-3.153 1.363-7.913-1.726-10.5C17.058 1.767 14.547.602 12.46.2c-.33-.073-.31-.051.154.176Zm3.45 1.844c.825.474 1.922 1.546 2.242 2.205.588 1.195.393 2.606-.527 3.771-.909 1.155-2.005 1.885-5.775 3.812-1.539.794-3.243 1.732-3.78 2.092-1.198.794-2.18 1.793-2.561 2.596-.372.814-.423 1.895-.134 2.762l.207.618-.599-.65c-.735-.771-1.397-2.039-1.613-3.069-.196-.948-.196-2.721 0-3.493.475-1.814 1.861-3.39 4.091-4.699a90.943 90.943 0 0 1 2.779-1.473c1.136-.587 2.366-1.288 2.727-1.546 1.186-.843 1.816-1.863 1.816-2.944v-.517l.289.113c.154.061.527.247.837.422Z" />
        </SvgIcon>
    );
};

export default Logo;
