import BlockIcon from "@mui/icons-material/Block";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FlakyIcon from "@mui/icons-material/Flaky";
import PendingIcon from "@mui/icons-material/Pending";
import { Chip, Tooltip } from "@mui/material";
import {
    CANCELLED,
    DETAILED_PO_CHIP,
    MINIMAL_PO_CHIP,
    PO_CANCEL_FLAG_TRUE,
    PO_CLOSED,
    PO_IN_PROCESS,
    PO_INCOMPLETE,
    REQUISITION_PO_CHIP,
} from "constants/generalConstants";
import { PO_SUMMARY_PAGE } from "constants/routes";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const PurchaseOrderChip = ({ data, variant }) => {
    var label;
    var chipVariant = variant === DETAILED_PO_CHIP ? "filled" : "outlined";

    const title =
        data?.CANCEL_FLAG === PO_CANCEL_FLAG_TRUE
            ? `Purchase order ${data?.PO_NUMBER} is CANCELLED. Click to view`
            : !data?.AUTHORIZATION_STATUS
            ? "Click to view purchase order"
            : `Purchase order ${data?.PO_NUMBER} is ${data?.AUTHORIZATION_STATUS} and ${data?.CLOSED_CODE}. Click to view`;

    const chipColor = (data) => {
        let color;
        if (data?.CANCEL_FLAG === PO_CANCEL_FLAG_TRUE) {
            color = "error";
        } else if (
            data?.CLOSED_CODE === PO_CLOSED ||
            data?.AUTHORIZATION_STATUS === PO_INCOMPLETE
        ) {
            color = "warning";
        } else if (
            data?.AUTHORIZATION_STATUS === PO_IN_PROCESS ||
            !data?.AUTHORIZATION_STATUS
        ) {
            color = "info";
        } else {
            color = "success";
        }
        return color;
    };

    const chipIcon = (data) => {
        let icon;
        if (variant === MINIMAL_PO_CHIP) {
            icon = null;
        } else if (data?.CANCEL_FLAG === PO_CANCEL_FLAG_TRUE) {
            icon = <CancelIcon />;
        } else if (data?.CLOSED_CODE === PO_CLOSED) {
            icon = <BlockIcon />;
        } else if (data?.AUTHORIZATION_STATUS === PO_INCOMPLETE) {
            icon = <FlakyIcon />;
        } else if (
            data?.AUTHORIZATION_STATUS === PO_IN_PROCESS ||
            !data?.AUTHORIZATION_STATUS
        ) {
            icon = <PendingIcon />;
        } else {
            icon = <CheckCircleOutlineIcon />;
        }

        return icon;
    };

    if (variant === MINIMAL_PO_CHIP) {
        label = data?.PO_NUMBER;
    } else if (variant === REQUISITION_PO_CHIP) {
        label = `PO ${data?.PO_NUMBER}`;
    } else if (data?.CANCEL_FLAG === PO_CANCEL_FLAG_TRUE) {
        label = CANCELLED;
    } else {
        label = `${data?.AUTHORIZATION_STATUS} - ${data?.CLOSED_CODE}`;
    }

    return (
        <Tooltip title={title}>
            <Link
                to={PO_SUMMARY_PAGE(data?.PO_NUMBER)}
                style={{
                    textDecoration: "none",
                }}
            >
                <Chip
                    label={label}
                    size="small"
                    color={chipColor(data)}
                    icon={chipIcon(data)}
                    variant={chipVariant}
                    sx={{ cursor: "pointer" }}
                />
            </Link>
        </Tooltip>
    );
};

PurchaseOrderChip.propTypes = {
    data: PropTypes.object,
    variant: PropTypes.oneOf([
        MINIMAL_PO_CHIP,
        DETAILED_PO_CHIP,
        REQUISITION_PO_CHIP,
    ]),
};

export default PurchaseOrderChip;
