import DynamicFormOutlinedIcon from "@mui/icons-material/DynamicFormOutlined";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { SMART_FORM_DETAILS_PAGE } from "constants/routes";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { openSmartFormModal } from "utils/smartFormsUtils";

import GradientDivider from "../../styled/styledComponents/GradientDivider";
import { homepageSmartFormBoxSX } from "../../styled/styledSXProperties/styledBoxSX";
import { homepageSmartFormCardSX } from "../../styled/styledSXProperties/styledCardSX";
import ProductCardSkeleton from "../skeletons/productCardSkeleton";

const SmartFormCard = ({ smartForm, isLoading }) => {
    // Collect states and state dispatchers
    const darkMode = useSelector((state) => state.general.darkMode);
    const browsingOU = useSelector((state) => state.auth.activeOU);
    const dispatch = useDispatch();

    /**
     * Called on click add to cart. Opens smart-form modal
     * and cancels link action to smart-forms detail page
     * @param {object} event - button click event object
     */
    const clickAddToCart = (event) => {
        event.preventDefault();
        openSmartFormModal(smartForm, dispatch, browsingOU);
    };
    return (
        <>
            {isLoading ? (
                <ProductCardSkeleton />
            ) : (
                <Link
                    to={SMART_FORM_DETAILS_PAGE(smartForm?.TEMPLATE_ID)}
                    style={{ textDecoration: "none" }}
                >
                    <Card sx={homepageSmartFormCardSX(darkMode)}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Box sx={homepageSmartFormBoxSX(darkMode)}>
                                    <DynamicFormOutlinedIcon
                                        aria-label="smartFormCardLogo"
                                        sx={{
                                            height: "40px",
                                            width: "40px",
                                            color: "#ffffff",
                                        }}
                                    />
                                    ;
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="body2"
                                    fontWeight="normal"
                                    align="right"
                                    color="text.secondary"
                                >
                                    Contract
                                </Typography>
                                <Typography
                                    variant="h5"
                                    fontWeight="bold"
                                    align="right"
                                >
                                    {smartForm?.CONTRACT
                                        ? smartForm.CONTRACT
                                        : "No Associated Contract"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="body1"
                                    fontWeight="bold"
                                    minHeight={"1rem"}
                                >
                                    {smartForm?.TEMPLATE_NAME}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                >
                                    {`Category: ${
                                        smartForm?.PURCHASING_CATEGORY ||
                                        "No Associated Category"
                                    }`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <GradientDivider
                                    sx={{
                                        margin: "8px 5px 16px 5px",
                                    }}
                                />
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={clickAddToCart}
                                    >
                                        Add to Cart
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                </Link>
            )}
        </>
    );
};

SmartFormCard.propTypes = {
    smartForm: PropTypes.object,
};

export default SmartFormCard;
