import { Box } from "@mui/material";
import React from "react";
import { DetailListType } from "types/propTypes";

import FormatNameValue from "./formatNameValue";

const DetailListPiped = ({ details }) => {
    return (
        <Box
            sx={{
                "&>*:not(:last-child):after": {
                    content: "''",
                    border: "1px solid",
                    borderColor: "theme.primary",
                    mx: "16px",
                },
            }}
        >
            {details.map((detail) => (
                <FormatNameValue
                    key={detail.header}
                    name={detail.header}
                    value={detail.content}
                    variant="body1"
                    sx={{ display: "inline-block" }}
                    error={detail.hasError}
                />
            ))}
        </Box>
    );
};

DetailListPiped.propTypes = {
    details: DetailListType.isRequired,
};

export default DetailListPiped;
