import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import {
    API_AUTOCOMPLETE_COOLDOWN_MS,
    LOCAL_BUYER_CPA_TRUE,
} from "constants/generalConstants";
import useIsMount from "hooks/useIsMount";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCpaNumber } from "redux/actions/filterPanelValues";

const SmartFormsFilterPanel = ({ searchParams, setSearchParams }) => {
    // Collect states and state dispatchers
    const cpaNumber = useSelector((state) => state.filterPanelValues.cpaNumber);
    const localBuyerOnly = useSelector(
        (state) => state.filterPanelValues.localBuyerOnly
    );
    const dispatch = useDispatch();

    /**
     * Writes user input cpa number to url and redux when
     * user searches for cpa number
     * @param {string} userInput - user input to textfield
     */
    const onCpaNumberType = (userInput) => {
        userInput.length != 0
            ? searchParams.set("cpa_num", userInput)
            : searchParams.delete("cpa_num");
        setSearchParams(searchParams);
        dispatch(setCpaNumber(userInput));
    };

    const debounceCpaNumberSearchAsYouType = useCallback(
        debounce((userInput) => {
            onCpaNumberType(userInput);
        }, API_AUTOCOMPLETE_COOLDOWN_MS),
        []
    );

    const isFirstRender = useIsMount();

    useEffect(() => {
        !isFirstRender && debounceCpaNumberSearchAsYouType(cpaNumber);
    }, [cpaNumber]);

    /**
     * Updates url search parameters on change of local buyer cancel switch state
     * @param {object} e_ - Material ui switch event object (not used)
     * @param {boolean} value  - boolean state of controlled switch
     */
    const onLocalBuyerSwitch = (e_, value) => {
        value
            ? searchParams.set("local_buyer_only", LOCAL_BUYER_CPA_TRUE)
            : searchParams.delete("local_buyer_only");
        setSearchParams(searchParams);
    };
    return (
        <>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Contract (CPA) Number Contains Search"
                    onChange={(e) => dispatch(setCpaNumber(e.target.value))}
                    placeholder="Start typing..."
                    size="small"
                    value={cpaNumber}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl
                    component="fieldset"
                    variant="standard"
                    sx={{ mt: "-12px" }}
                >
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    color="secondary"
                                    checked={localBuyerOnly}
                                    onChange={onLocalBuyerSwitch}
                                />
                            }
                            label="Local Buyer Contracts Only"
                        />
                    </FormGroup>
                </FormControl>
            </Grid>
        </>
    );
};

SmartFormsFilterPanel.propTypes = {
    searchParams: PropTypes.object,
    setSearchParams: PropTypes.func,
};

export default SmartFormsFilterPanel;
