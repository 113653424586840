import axiosApiInstance from "../middleware/axios";
import {
    BATCH_OWNER_UPDATE,
    SINGLE_OWNER_UPDATE,
    USER_NAME_AUTOCOMPLETE_GET,
} from "./urls";

export const getApiUserAutocomplete = async (searchTerm) => {
    return await axiosApiInstance.get(USER_NAME_AUTOCOMPLETE_GET(searchTerm));
};

/**
 * Sends patch request to batch req owner change API.
 * @param {Boolean} isPreflight - flag denoting if API should
 * be called in `prelight mode`, a mode that does provides
 * metadata about the request that would be sent if `isPreflight`
 * was set to false
 * @param {{from_owner: string, to_owner: string, from_date: string, to_date: string}} body - patch body
 * @returns
 */
export const patchBatchReqOwnerUpdate = async (isPreflight, body) => {
    return await axiosApiInstance.patch(BATCH_OWNER_UPDATE(isPreflight), body);
};

export const patchSingleReqOwnerUpdate = async (body) => {
    return await axiosApiInstance.patch(SINGLE_OWNER_UPDATE(), body);
};
