import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

import CopyTypography from "./copyTypography";

const FormatNameValue = ({
    name,
    value,
    variant,
    error = false,
    sx = null,
    disableCopy = false,
}) => {
    return (
        <Box sx={sx}>
            <Typography
                component={"span"}
                color="text.secondary"
                variant={variant}
            >
                {name + ": "}&nbsp;
            </Typography>
            <Box
                display="inline"
                sx={
                    error
                        ? { color: "text.errorLight" }
                        : { color: "text.primary" }
                }
            >
                {value ? (
                    disableCopy ? (
                        <Typography component={"span"} variant={variant}>
                            {value}
                        </Typography>
                    ) : (
                        <CopyTypography component={"span"} variant={variant}>
                            {value}
                        </CopyTypography>
                    )
                ) : (
                    "-"
                )}
            </Box>
        </Box>
    );
};

FormatNameValue.defaultProps = {
    error: false,
    sx: null,
};

FormatNameValue.propTypes = {
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.string,
    error: PropTypes.bool,
};

export default FormatNameValue;
