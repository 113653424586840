import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import CurrencyFigure from "components/displays/currencyFigure";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router";

import { REQUISITION_SUMMARY_PAGE } from "../../../../../constants/routes";
import { dateFormat } from "../../../../../utils/general";
import DetailListVertical from "../../../../displays/detailListVertical";
import IBuyPaper from "../../../../displays/iBuyPaper";
import RequisitionStatusBadge from "../../../../displays/status/requisitionStatusBadge";
import HeaderPOValue from "../headerPOValue";
import RequisitionButtonCollection, {
    StandaloneGotoReqButton,
} from "../requisitionButtonCollection";

const RequisitionSummaryCard = ({ req }) => {
    const navigate = useNavigate();

    const goToSummary = (id) => navigate(REQUISITION_SUMMARY_PAGE(id));

    return (
        <IBuyPaper>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography
                            component="h2"
                            variant="h5"
                            color="text.subheader"
                        >
                            {req.id}
                        </Typography>
                        <RequisitionStatusBadge status={req.status} />
                    </Stack>
                </Grid>

                <Grid item xs={12} container spacing={1}>
                    <Grid item xs>
                        <DetailListVertical
                            condensed={true}
                            details={[
                                {
                                    header: "Description",
                                    content:
                                        req.requisitionHeaderForm
                                            .HEADER_DESCRIPTION,
                                },
                                {
                                    header: "Price",
                                    content: (
                                        <CurrencyFigure
                                            value={req?.totalPriceBaseCurrency}
                                            code={req?.ouBaseCurrency}
                                        />
                                    ),
                                },
                                {
                                    header: "Supplier",
                                    content: req.supplierName,
                                },
                                {
                                    header: "Preparer",
                                    content: req.submittedByName,
                                },
                                {
                                    header: "Date Created",
                                    content: dateFormat(req.submittedDate),
                                },
                                {
                                    header: "Purchase Order",
                                    content: (
                                        <HeaderPOValue
                                            onMultipleClick={() =>
                                                goToSummary(req.id)
                                            }
                                            req={req}
                                        />
                                    ),
                                },
                            ]}
                        />
                    </Grid>

                    <Grid item xs="auto">
                        <RequisitionButtonCollection req={req} />
                        <StandaloneGotoReqButton req={req} />
                    </Grid>
                </Grid>
            </Grid>
        </IBuyPaper>
    );
};

RequisitionSummaryCard.propTypes = {
    req: PropTypes.object.isRequired,
};

export default RequisitionSummaryCard;
