import * as constants from "../constants/snackbarMessage";
import { appendToSnackbarQueue } from "./../redux/actions/alertsActions";

/**
 * Trigger for the snackbar component
 *
 * @function
 */
export const triggerSnackbar = (severity, message, action, dispatch) => {
    /*
    severity : string
    message : string
    action : func
    dispatch : func
    */
    dispatch(
        appendToSnackbarQueue({
            action,
            message,
            severity,
        })
    );
};

/**
 * Dispatches user messaging events based on function and status to display as alerts
 * @param   {string} functionName Name of the function calling the dispatcher
 * @param   {number} statusCode Status code of the API event
 * @param   {function} dispatch Dispatch object
 * @param   {string} message Alternative message to inject
 * @param   {function} action Component to inject into the alert
 */
const alertDispatcher = (
    functionName,
    statusCode,
    dispatch,
    message = "message not found",
    action = null
) => {
    const { success_status, info_status, warning_status, error_status } =
        constants;

    const messages = {
        server_error: error_status(constants.GENERIC_ERROR_MSG),
        postRequisition: {
            201: success_status(message),
            400: warning_status(
                constants.PAYLOAD_MESSAGE_POST_REQUISITION(message)
            ),
            403: error_status(message || constants.UNAUTHORIZED_FIREWALL), // 403 with no message response is likely firewall filter
            server_error: error_status(constants.SERVER_MESSAGE_POST_REQ),
        },
        reqFormSubmit: {
            400: warning_status(constants.PAYLOAD_SUBMIT_REQ_AMENDMENT),
            409: warning_status(constants.PAYLOAD_SUBMIT_REQ_DUPLICATE_LINES),
        },
        updateReqLine: {
            400: warning_status(message),
        },
        getRequisitionById: {
            404: warning_status(constants.NOTFOUND_MESSAGE_REQ_ID),
            server_error: error_status(constants.SERVER_MESSAGE_DATA_RETRIEVAL),
        },
        listRequisitions: {
            server_error: error_status(constants.SERVER_MESSAGE_DATA_RETRIEVAL),
        },
        getTaskAndChargeAccountsByProject: {
            server_error: error_status(
                constants.SERVER_MESSAGE_CANNOT_RETRIEVE_CHARGE_ACCOUNTS
            ),
        },
        postAttachments: {
            200: success_status(message),
            400: warning_status(constants.PAYLOAD_MESSAGE_POST_ATTACHMENT),
            403: warning_status(constants.UNAUTHORIZED_MESSAGE_POST_ATTACHMENT),
            server_error: error_status(
                constants.SERVER_MESSAGE_CANNOT_POST_ATTACHMENT
            ),
        },
        getAttachment: {
            server_error: error_status(
                constants.SERVER_MESSAGE_CANNOT_GET_ATTACHMENT
            ),
        },
        getApprovalsTree: {
            server_error: error_status(constants.SERVER_GET_APPROVALS_TREE),
        },
        listRequisitionsForApproval: {
            server_error: error_status(message),
        },
        listReceiptsForApproval: {
            server_error: error_status(message),
        },
        postRequisitionApproval: {
            200: success_status(constants.SUCCESS_APPROVAL_POSTED),
            403: warning_status(constants.UNAUTHORIZED_MESSAGE_APPROVAL_UPDATE),
            server_error: error_status(
                constants.SERVER_MESSAGE_APPROVAL_UPDATE
            ),
        },
        projAutocompleteGet: {
            server_error: error_status(
                constants.SERVER_MESSAGE_PROJECT_AUTOCOMPLETE
            ),
        },
        getProjectByID: {
            server_error: error_status(constants.SERVER_MESSAGE_PROJECT_BY_ID),
        },
        getCpaByID: {
            server_error: error_status(constants.SERVER_MESSAGE_CPA_BY_ID),
        },
        deliveryLocAutocompleteGet: {
            server_error: error_status(
                constants.SERVER_MESSAGE_DELIVER_TO_LOCATION
            ),
        },
        getDeliveryLocById: {
            server_error: error_status(
                constants.SERVER_MESSAGE_DELIVER_LOCATION_BY_ID
            ),
        },
        hrOrgAutocompleteGet: {
            server_error: error_status(
                constants.SERVER_MESSAGE_HR_ORGANIZATION
            ),
        },
        getHrOrgById: {
            server_error: error_status(
                constants.SERVER_MESSAGE_HR_ORGANIZATION_BY_ID
            ),
        },
        companyCodeAutocompleteGet: {
            server_error: error_status(constants.SERVER_MESSAGE_COMPANY_CODES),
            400: warning_status(constants.BAD_REQUEST_COMPANY_CODES),
        },
        costCentreAutocompleteGet: {
            server_error: error_status(constants.SERVER_MESSAGE_COST_CENTRES),
            400: warning_status(constants.BAD_REQUEST_COST_CENTRES),
        },
        accountAutocompleteGet: {
            server_error: error_status(constants.SERVER_MESSAGE_ACCOUNTS),
            400: warning_status(constants.BAD_REQUEST_ACCOUNTS),
        },
        proxyRules: {
            server_error: error_status(constants.SERVER_MESSAGE_APPROVAL_PROXY),
        },
        proxyRulesCreated: {
            201: success_status(message),
            server_error: warning_status(message),
        },
        proxyRulesDeleted: {
            200: success_status(constants.SUCCESS_APPROVAL_PROXY_DELETED),
            server_error: error_status(
                constants.SERVER_ERROR_APPROVAL_PROXY_DELETED
            ),
        },
        getUserInfo: {
            server_error: warning_status(constants.SERVER_AZURE_AD_TOKEN),
        },
        CopyTypography: {
            200: info_status(message),
            server_error: warning_status(constants.FAILED_TO_COPY_TO_CLIPBOARD),
        },
        downloadAttachment: {
            server_error: warning_status(constants.SERVER_DOWNLOAD_ATTACHMENT),
        },
        uploadAttachment: {
            400: warning_status(constants.PAYLOAD_UPLOAD_ATTACHMENT),
            server_error: error_status(constants.SERVER_UPLOAD_ATTACHMENT),
        },
        productDetails: {
            404: warning_status(constants.NOTFOUND_PRODUCT_DETAILS),
            server_error: error_status(constants.SERVER_MESSAGE_DATA_RETRIEVAL),
        },
        smartFormDetails: {
            404: warning_status(constants.NOTFOUND_SMART_FORM_DETAILS),
            server_error: error_status(constants.SERVER_MESSAGE_DATA_RETRIEVAL),
        },
        searchFilters: {
            server_error: error_status(message),
        },
        invoice: {
            404: warning_status(constants.NOTFOUND_INVOICE_ID),
            server_error: warning_status(constants.SERVER_INVOICE_ID),
        },
        purchaseOrder: {
            404: warning_status(constants.NOTFOUND_PO_ID),
            server_error: error_status(constants.SERVER_PO_ID),
        },
        getPoPdf: {
            404: warning_status(constants.NOTFOUND_PO_PDF),
            server_error: error_status(constants.SERVER_ERROR_PO_PDF),
        },
        supplier: {
            server_error: error_status(message),
        },
        postCancellation: {
            201: success_status(message),
            400: warning_status(
                constants.POST_CANCELLATION_WARNING_MESSAGE(message)
            ),
            403: error_status(message || constants.UNAUTHORIZED_FIREWALL), // 403 with no message response is likely firewall filter
            server_error: error_status(constants.SERVER_MESSAGE_POST_REQ),
        },
        addToCartAction: {
            400: warning_status(message),
        },
        updateCartItem: {
            400: warning_status(message),
        },
        namedCart: {
            200: success_status(constants.SUCCESS_NAMED_CART_CREATED),
            201: success_status(constants.SUCCESS_NAMED_CART_CREATED),
            server_error: error_status(
                constants.SERVER_MESSAGE_NAMED_CART_CREATED
            ),
        },
        getCarts: {
            server_error: error_status(constants.SERVER_MESSAGE_GET_CARTS),
        },
        updateCart: {
            200: success_status(message),
            201: success_status(message),
            server_error: error_status(message),
        },
        addToFavourites: {
            200: success_status(constants.SUCCESS_ADD_TO_FAVOURITES),
            201: success_status(constants.SUCCESS_ADD_TO_FAVOURITES),
            server_error: error_status(
                constants.SERVER_MESSAGE_ADD_TO_FAVOURITES
            ),
        },
        removeFromFavourites: {
            200: success_status(constants.SUCCESS_REMOVED_FROM_FAVOURITES),
        },
        loadReceipts: {
            server_error: warning_status(constants.SERVER_MESSAGE_RECEIPT_LOAD),
        },
        internalExternalCartCheck: {
            400: warning_status(constants.INVALID_CART_MIXED_SOURCING_TYPE),
            server_error: error_status(
                constants.SERVER_MESSAGE_CREATE_REQ_FORM_LOAD
            ),
        },
        getCreateReceiptDataByReqId: {
            server_error: warning_status(
                constants.SERVER_MESSAGE_RECEIPT_REQ_ID
            ),
        },
        receiptMessage: {
            server_error: warning_status(message),
        },
        receiptCorrectMessage: {
            server_error: warning_status(message),
        },
        createReceiptEvents: {
            201: success_status(message),
            400: warning_status(message),
            409: warning_status(message),
            server_error: error_status(constants.SERVER_MESSAGE_RECEIPT_REQ_ID),
        },
        getReceipts: {
            404: warning_status(message),
            server_error: error_status(message),
        },
        postDispute: {
            201: success_status(constants.SUCCESS_DISPUTE),
            400: warning_status(message),
            server_error: error_status(constants.SERVER_MESSAGE_DISPUTE),
        },
        getDispute: {
            404: warning_status(constants.NOTFOUND_DISPUTE),
            server_error: error_status(constants.SERVER_MESSAGE_GET_DISPUTE),
        },
        copyAmendRequisition: {
            400: warning_status(constants.CANNOT_AMEND_AS_CANCELLED),
        },
        getPosPerRequisition: {
            server_error: error_status(constants.SEVER_ERROR_GET_POS_PER_REQ),
        },
        notifications: {
            200: info_status(message),
        },
        ccidValidation: {
            200: success_status(constants.SUCCESSFUL_VALIDATION),
            400: warning_status(message),
            401: warning_status(message),
            server_error: error_status(message),
        },
        protectedRoute: {
            403: warning_status(message),
        },
        getExchangeRate: {
            400: warning_status(constants.BAD_REQUEST_EXCHANGE_RATE),
            404: warning_status(constants.NOT_FOUND_EXCHANGE_RATE),
            server_error: error_status(message),
        },
        getExchangeRateCopy: {
            400: warning_status(message),
            404: warning_status(message),
            server_error: error_status(message),
        },
        getUserAutocomplete: {
            404: warning_status(message),
            server_error: error_status(message),
        },
        batchOwnerUpdate: {
            200: success_status(message),
            400: warning_status(message),
            404: warning_status(message),
            server_error: error_status(message),
        },
        changeRequisitionOwner: {
            200: success_status(message),
            400: warning_status(message),
            404: warning_status(message),
            server_error: error_status(message),
        },
        handleNextValidate: {
            400: warning_status(message),
        },
    };

    let messageOutput = messages.server_error;

    if (functionName in messages) {
        if (statusCode in messages[functionName]) {
            messageOutput = messages[functionName][statusCode];
        } else {
            messageOutput = messages[functionName]?.server_error;
        }
    } else {
        console.warn("User message does not exist.");
    }

    triggerSnackbar(
        messageOutput.message_status,
        messageOutput.msg,
        action,
        dispatch
    );
};

export default alertDispatcher;
