import {
    SET_CATEGORY_FILTER,
    SET_CPA_NUMBER,
    SET_LEAD_TIME_LTE_FILTER,
    SET_LINE_TYPE_FILTER,
    SET_LOCAL_BUYER_ONLY,
    SET_SUPPLIER_FILTER,
    SET_UNIT_MEASURE_FILTER,
    SET_UNIT_PRICE_RANGE_FILTER,
} from "../actionTypes";
import { initialState } from "../initialState";

const filterPanelValuesReducer = (
    state = initialState.filterPanelValues,
    action
) => {
    switch (action.type) {
        case SET_CATEGORY_FILTER:
            return {
                ...state,
                category: action.payload,
            };
        case SET_LEAD_TIME_LTE_FILTER:
            return {
                ...state,
                leadTimeLTE: action.payload,
            };
        case SET_UNIT_PRICE_RANGE_FILTER:
            return {
                ...state,
                unitPriceRange: action.payload,
            };
        case SET_LINE_TYPE_FILTER:
            return {
                ...state,
                lineTypes: action.payload,
            };
        case SET_UNIT_MEASURE_FILTER:
            return {
                ...state,
                unitOfMeasures: action.payload,
            };
        case SET_SUPPLIER_FILTER:
            return {
                ...state,
                suppliers: action.payload,
            };
        case SET_CPA_NUMBER:
            return {
                ...state,
                cpaNumber: action.payload,
            };
        case SET_LOCAL_BUYER_ONLY:
            return {
                ...state,
                localBuyerOnly: action.payload,
            };
        default:
            return state;
    }
};
export default filterPanelValuesReducer;
