import { CREATE_RECEIPT } from "../constants/generalConstants";
import {
    setCreateReceiptLoading,
    setHeaderInput,
    setReceiptReqNum,
} from "../redux/actions/createReceiptActions";
import { setShowReceiptDialogue } from "../redux/actions/generalActions";
import {
    createReceiptEvents,
    getCreateReceiptDataByReqId,
} from "../services/receipting/receiptingAPIs";
import alertDispatcher from "./alertsUtils";

export const openCreateReceiptDialogue = (iBuyReqNumber, dispatch) => {
    /*
    Arguments
    ---------
    iBuyReqNumber : str
    dispatch : func
    */
    dispatch(
        setHeaderInput({
            uniqueReference: "",
            receiptDate: new Date(),
            comments: "",
        })
    );
    dispatch(setCreateReceiptLoading(true));
    getCreateReceiptDataByReqId(iBuyReqNumber, dispatch);
    dispatch(setReceiptReqNum(iBuyReqNumber));
};

export const closeCreateReceiptDialogue = (dispatch) => {
    /*
    Arguments
    ---------
    dispatch : func
    */
    dispatch(setShowReceiptDialogue(false));
};

export const submitReceiptCreation = (
    poShipments,
    headerInput,
    dispatch,
    reqId
) => {
    /*
    Arguments
    ---------
    poShipments : array
    headerInput : obj
    dispatch : func
    reqId : str
    */
    // Parse into array of receipting events. One receipt creation event is created for
    // each checked shipment. A shipment is only receipted if 1) it is checked by user
    // 2) is approved, 3) is available for receipting, 4) has a PO associated and
    // 5) is not closed for receipting.
    const checkedShipments = poShipments.filter(
        (shipment) =>
            shipment?.checked &&
            shipment?.PO_AVAILABLE &&
            shipment?.PO_APPROVED &&
            shipment?.AVAILABLE_FOR_RECEIPT &&
            !shipment?.CLOSED_FOR_RECEIPT
    );
    const transactionsArray = checkedShipments.reduce(
        (acc, shipment) => [
            ...acc,
            parseReceiptTransactionPayload(shipment, headerInput),
        ],
        []
    );
    const payload = [
        {
            receiptHeader: parseReceiptHeader(poShipments, headerInput),
            receiptTransactions: transactionsArray,
        },
    ];
    createReceiptEvents(payload, CREATE_RECEIPT, dispatch, reqId);
};

export const parseReceiptHeader = (poShipments, headerInput) => {
    /*
    Arguments
    ---------
    poShipments : array
    headerInput : obj
    */
    return {
        TRANSACTION_TYPE: "NEW",
        VENDOR_ID: poShipments[0]?.SUPPLIER_ID,
        VENDOR_SITE_ID: poShipments[0]?.SUPPLIER_SITE_ID,
        PACKING_SLIP: headerInput?.uniqueReference,
    };
};

export const parseReceiptTransactionPayload = (shipment, headerInput) => {
    /*
    Arguments
    ---------
    shipment : obj
    headerInput : obj
    */
    const receiptTransaction = {
        TRANSACTION_DATE: headerInput?.receiptDate,
        QUANTITY: parseFloat(shipment?.receiptQuantity),
        UNIT_OF_MEASURE: shipment?.UNIT_OF_MEASURE_MEANING,
        AUTO_TRANSACT_CODE: "DELIVER",
        SHIP_TO_LOCATION_ID: shipment?.LOC_SHIP_TO_LOCATION_ID,
        PO_HEADER_ID: shipment?.PO_HEADER_ID,
        PO_LINE_ID: shipment?.PO_LINE_ID,
        PO_LINE_LOCATION_ID: shipment?.LINE_LOCATION_ID,
        COMMENTS: headerInput?.comments,
        PARENT_TRANSACTION_ID: null,
    };
    return receiptTransaction;
};

export const addCreationProperties = (poShipments) => {
    /* Adds two fields (checked and receiptQuantity) against
    each po shipment to facilitate receipt creation

    Arguments
    ---------
    poShipments : array

    Returns
    -------
    obj
    */
    return poShipments.reduce(
        (acc, shipment) => [
            ...acc,
            {
                ...shipment,
                checked: true,
                receiptQuantity: defaultReceiptQuantity(
                    shipment.QUANTITY,
                    shipment.QUANTITY_RECEIVED
                ),
            },
        ],
        []
    );
};

export const defaultReceiptQuantity = (quantity, quantityReceived) => {
    /* 
    Arguments
    ---------
    quantity : float
    quantityReceived : gloat

    Returns
    -------
    str
    */
    const qtyDefined = !!quantity;
    const qtyReceivedDefined = !!quantityReceived;
    return qtyDefined && qtyReceivedDefined
        ? `${Math.round((quantity - quantityReceived) * 100) / 100}`
        : `${quantity}`;
};

export const showReceivingNotAvailableSnackbar = (shipments, dispatch) => {
    /* This function generates a warning message displayed the 
    Arguments
    ---------
    shipments : array
     dispatch : func
    */
    const allClosedForReceiving = shipments.every(
        (shipment) => shipment?.CLOSED_FOR_RECEIPT && shipment?.PO_AVAILABLE
    );

    const allPOUnavailable = shipments.every(
        (shipment) => !shipment?.PO_AVAILABLE
    );

    const allPOUnapproved = shipments.every(
        (shipment) => shipment?.PO_AVAILABLE && !shipment?.PO_APPROVED
    );
    const allLocked = shipments.every(
        (shipment) => !shipment?.AVAILABLE_FOR_RECEIPT && shipment?.PO_AVAILABLE
    );
    const someUnlocked = shipments.some(
        (shipment) =>
            shipment?.AVAILABLE_FOR_RECEIPT &&
            shipment?.PO_AVAILABLE &&
            shipment?.PO_APPROVED &&
            !shipment?.CLOSED_FOR_RECEIPT
    );
    const allLockedOrUnavailable = shipments.every(
        (shipment) =>
            !shipment?.AVAILABLE_FOR_RECEIPT || !shipment?.PO_AVAILABLE
    );
    const allUnApprovedOrUnavailable = shipments.every(
        (shipment) =>
            (shipment?.PO_AVAILABLE && !shipment?.PO_APPROVED) ||
            !shipment?.PO_AVAILABLE
    );
    const allUnApprovedOrLocked = shipments.every(
        (shipment) =>
            (shipment?.PO_AVAILABLE && !shipment?.PO_APPROVED) ||
            (!shipment?.AVAILABLE_FOR_RECEIPT && shipment?.PO_AVAILABLE)
    );
    const allLockedOrClosedForReceiving = shipments.every(
        (shipment) =>
            (!shipment?.AVAILABLE_FOR_RECEIPT ||
                shipment?.CLOSED_FOR_RECEIPT) &&
            shipment?.PO_AVAILABLE
    );

    const allLockedOrClosedForReceivingOrPONotAvailable = shipments.every(
        (shipment) =>
            !shipment?.AVAILABLE_FOR_RECEIPT ||
            shipment?.CLOSED_FOR_RECEIPT ||
            !shipment?.PO_AVAILABLE
    );
    let msg;
    switch (true) {
        case allClosedForReceiving:
            // All closed
            msg =
                "All PO shipment lines associated with this requisition are now closed for receipting";
            break;
        case allPOUnavailable: {
            // All no PO generated
            msg =
                "Requisition unavailable for receipting as PO has not yet been generated";
            break;
        }
        case allPOUnapproved: {
            // All have POs which are unapproved
            msg =
                "Requisition unavailable for receipting as all associated PO shipments are currently unapproved";
            break;
        }
        case allLocked: {
            // All locked
            msg =
                "Requisition unavailable for receipting as it is currently undergoing a receipting, correction or return transaction of the associated shipments - try again in a few minutes";
            break;
        }
        case someUnlocked:
            // Some available unlocked
            msg = null;
            break;
        case allUnApprovedOrUnavailable:
            msg =
                "Requisition unavailable for receipting. Some lines do not yet have an associated PO and some are associated with PO shipments which are unapproved";
            break;
        case allUnApprovedOrLocked:
            msg =
                "Requisition unavailable for receipting. Some lines are associated with PO shipments which are unapproved and some are locked as another user is receipting, correcting or returning the associated shipments";
            break;
        // All locked or unavailable
        case allLockedOrUnavailable: {
            msg =
                "Requisition unavailable for receipting. Some lines do not yet have an associated PO and some are locked as another user is receipting, correcting or returning the associated shipments";
            break;
        }
        case allLockedOrClosedForReceiving: {
            msg =
                "Requisition unavailable for receipting. Some associated PO shipments are closed for receiving and some are locked as another user is receipting, correcting or returning them";
            break;
        }
        case allLockedOrClosedForReceivingOrPONotAvailable:
            msg =
                "Requisition unavailable for receipting. Some associated PO shipments are closed for receiving, some do not yet have an associated PO and some are locked as another user is receipting, correcting or returning them";
            break;
        default:
            msg = null;
            break;
    }
    // Trigger snackbar only if message defined
    msg && alertDispatcher("receiptMessage", 400, dispatch, msg);
};

export const receiptLimit = (tolerance, qtyOrdered, currentQtyReceived) => {
    /* 
    Arguments
    ---------
    tolerance : float (% tolerance)
    qtyOrdered : float
    currentQtyReceived : float

    Returns
    -------
    array [currentLimit, maxLimit]
    */
    // If both undefined, return null
    if (!tolerance && !qtyOrdered) {
        return [null, null];
    }
    // If quantity defined and tolerance not defined, return quantity
    if (!tolerance && qtyOrdered) {
        return [qtyOrdered - currentQtyReceived, qtyOrdered];
    }

    // If tolerance defined and quantity not defined, return null
    if (tolerance && !qtyOrdered) {
        return [null, null];
    }
    // Tolerance undefined, return qtyOrdered
    if (!tolerance) {
        return qtyOrdered;
    }
    try {
        const maxLimit = ((100 + tolerance) * qtyOrdered) / 100;
        return maxLimit
            ? [maxLimit - currentQtyReceived, maxLimit]
            : [null, null];
    } catch {
        return [null, null];
    }
};

export const allShipmentsClosedForReceiving = (poShipments) => {
    /* 
    Arguments
    ---------
    poShipments : array

    Returns
    -------
    bool
    */
    const poShipmentsCleansed = poShipments ? poShipments : [];
    return poShipmentsCleansed.every(
        (shipment) => shipment?.CLOSED_FOR_RECEIPT && shipment?.PO_AVAILABLE
    );
};
