import { Alert } from "@mui/material";
import Grid from "@mui/material/Grid";

import InformationTemplateGenerator from "./informationTemplateGenerator";

/*
Information templates
---------------------
Components within the information templates directory are used to render
dynamic forms using data stored in the information templates cosmos container.
`InformationTemplates` is rendered for each smart form:
- `InformationTemplates` calls `InformationTemplateGenerator` to generate n 
groups of information template forms, where n is the number of information 
templates associated with the smartform
- `InformationTemplateGenerator` dynamically renders user input boxes for each group
*/
const nonTemplatesMsg =
    "No information templates associated with this smart form";
const InformationTemplates = ({ ifts }) => {
    return (
        <Grid item xs={12} container spacing={2}>
            {ifts.length > 0 ? (
                ifts.map((ift, index) => (
                    <InformationTemplateGenerator ift={ift} key={index} />
                ))
            ) : (
                <Grid item xs={12}>
                    <Alert
                        variant="outlined"
                        severity="info"
                        sx={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
                    >
                        {nonTemplatesMsg}
                    </Alert>
                </Grid>
            )}
        </Grid>
    );
};

export default InformationTemplates;
