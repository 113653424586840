import { getPosPerRequisition } from "services/procurement/procurementAPIs";

import {
    CANCELLATION_FLAG_STATUSES,
    PO_APPROVED,
} from "../constants/generalConstants";
import {
    setCancelFormLoading,
    setCancelHeaderReason,
    setCancelReqHeaderInfo,
    setCancelReqId,
    setCancelReqLines,
} from "../redux/actions/cancelRequisitionActions";
import { setShowCancelRequisitionDialogue } from "../redux/actions/generalActions";
import {
    getRequisitionById,
    postCancellation,
} from "../services/requisition/requisitionAPIs";

/**
 * Handles tear-down of cancel requisition dialogue
 * @param {function} dispatch - Redux dispatch function
 */
export const tearDownCancelRequisitionDialogue = (dispatch) => {
    dispatch(setShowCancelRequisitionDialogue(false));
    dispatch(setCancelReqHeaderInfo({}));
    dispatch(setCancelReqLines([]));
    dispatch(setCancelReqId(null));
    dispatch(setCancelHeaderReason(""));
    dispatch(setCancelFormLoading(false));
};

/**
 * Entry point for cancel requisition dialogue
 * @param {string} iBuyReqNumber Req ID being cancelled
 * @param {func} dispatch Redux dispatch function
 */
export const openCancelRequisitionDialogue = async (
    iBuyReqNumber,
    dispatch
) => {
    // Initial redux state update
    dispatch(setCancelReqId(iBuyReqNumber));
    dispatch(setCancelFormLoading(true));

    // Retrieve requisition from database
    const { notFound, req } = await getRequisitionById(iBuyReqNumber, dispatch);
    if (notFound) {
        tearDownCancelRequisitionDialogue(dispatch);
        return;
    }

    // Link PO to requisition
    const reqWithPo = await addPoInformationToRequisitionLines(req, dispatch);
    if (!reqWithPo) {
        tearDownCancelRequisitionDialogue(dispatch);
        return;
    }

    // Set redux form state
    const headerForm = reqWithPo?.requisitionHeaderForm || {};
    dispatch(
        setCancelReqHeaderInfo({
            operatingUnit: headerForm?.ORG_NAME,
            requesterName: reqWithPo?.submittedByName,
            submittedDate: reqWithPo?.submittedDate,
        })
    );
    dispatch(
        setCancelReqLines(addCancelProperties(reqWithPo?.requisitionLines))
    );
    dispatch(setCancelFormLoading(false));
    dispatch(setShowCancelRequisitionDialogue(true));
};

/**
 * Adds two fields (checked and cancel reason) against
   each requisition line to facilitate cancellation form,
   and filter out cancelled lines
 * @param  {Array} reqLines Requisition lines array
 * @returns {Array} - Array of req lines with added properties
 */
export const addCancelProperties = (reqLines) => {
    const reqLinesCleansed = reqLines || [];
    const reqLinesFiltered = reqLinesCleansed.filter(
        (line) => !isReqLineCancelled(line)
    );
    return reqLinesFiltered.reduce(
        (acc, line) => [
            ...acc,
            {
                ...line,
                checked: false,
                REQUISITION_CANCEL_REASON: "",
            },
        ],
        []
    );
};

/**
 * Generates cancellation post payload and submits to API
 * @param  {Array} reqLines - Requisition lines array
 * @param {func} dispatch - Redux dispatch function
 *  @param {func} navigate - react router programmatic router setter
 */
export const submitCancellation = (reqLines, dispatch, navigate) => {
    // Select only user checked lines
    const reqId = reqLines[0]?.REFERENCE_NUM;
    const checkedReqLines = reqLines.filter(
        (line) =>
            line?.checked && canReqLineBeCancelled(line?.poInfo?.hasPo, line)
    );
    const postPayload = checkedReqLines.reduce(
        (acc, line) => ({
            ...acc,
            [line?.LINE_ATTRIBUTE5]: {
                CANCELLATION_REASON: line?.REQUISITION_CANCEL_REASON,
            },
        }),
        {}
    );
    postCancellation(reqId, postPayload, dispatch, navigate);
};

/**
 * Determines if a requisition line is cancelled on in
 * a cancellation pending state
 * @param {Object} line - requisition line object
 * @returns {boolean} bool stating if the req line is cancelled
 */
export const isReqLineCancelled = (line) =>
    CANCELLATION_FLAG_STATUSES.includes(line?.CANCELLATION_FLAG);

/**
 * Retrieves PO information for requisition and appends into
 * requisition line payload.
 * @param {Object} req - requisition prior to adding po information
 * @param {function} dispatch - Redux dispatch function
 * @returns {Object} requisition updated with po information
 */
export const addPoInformationToRequisitionLines = async (req, dispatch) => {
    const posPerRequisition = await getPosPerRequisition(req?.id, dispatch);
    // `getPosPerRequisition` returns `null` if API call fails. In this case,
    // this function also returns null, which triggers `openCancelRequisitionDialogue`
    // to halt opening of cancel requisition dialogue.
    if (!posPerRequisition) {
        return null;
    }
    const reqLines = req?.requisitionLines || [];
    return {
        ...req,
        requisitionLines: reqLines.map((line) => ({
            ...line,
            poInfo: posPerRequisition[line?.LINE_ATTRIBUTE5] || {
                hasPo: false,
            },
        })),
    };
};

/**
 * Determines if requisition line is in scope for cancellation
 * @param {boolean} hasPo - bool stating if the req line has a po or not
 * @param {object} reqLine - the requisition line
 * @returns {boolean} - bool stating if line in scope for cancellation
 */
export const canReqLineBeCancelled = (hasPo, reqLine) => {
    const associatedShipments = reqLine?.poInfo?.associatedShipments || [];
    const shipment = associatedShipments[0] || {};
    if (hasPo) {
        return (
            associatedShipments.length == 1 &&
            shipment?.PO_HEADER_AUTHORIZATION_STATUS == PO_APPROVED &&
            shipment?.numberOfDistributions == 1 &&
            shipment?.QUANTITY_RECEIVED == 0 &&
            shipment?.QUANTITY_BILLED == 0 &&
            !shipment?.isPOLineClosed &&
            !shipment?.isPOShipmentClosed &&
            !shipment?.isPOShipmentLockedForReceiving
        );
    } else {
        return true;
    }
};

/**
 * Defines register name for validators in the cancel req form
 * @param {index} int - index of req line in `requisitionLines` list
 * @returns {string} - name of form
 */
export const cancelReasonRegisterName = (index) => `receiptCreation ${index}`;
