import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CurrencyFigure from "components/displays/currencyFigure";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

const Total = ({ reqLine }) => {
    const displayInReqCurrency = useSelector(
        (state) => state.singleReqSummary.displayInReqCurrency
    );
    return (
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            <Typography
                color="text.secondary"
                variant={"body2"}
                sx={{ mr: "12px" }}
            >
                Total
            </Typography>
            <Typography variant={"h3"}>
                <CurrencyFigure
                    value={
                        displayInReqCurrency
                            ? reqLine?.linePriceReqCurrency
                            : reqLine?.linePriceBaseCurrency
                    }
                    code={
                        displayInReqCurrency
                            ? reqLine?.CURRENCY_CODE
                            : reqLine?.ouBaseCurrency
                    }
                />
            </Typography>
        </Box>
    );
};

Total.propTypes = {
    reqLine: PropTypes.object,
};

export default Total;
