import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Stack } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import InfoLine from "components/displays/infoLine";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    EDIT_NON_CAT_REQ_LINE,
    NON_PROJECT_REQ,
    PO_CLOSED,
    PROJECT_REQ,
    SERVICE,
    TYPE_OPTIONS,
} from "../../../../constants/generalConstants";
import { decimalNumberRegex } from "../../../../constants/patterns";
import {
    TOOLTIP_MULTIPLE_PO_SHIPMENTS_PER_REQ_LINE,
    TOOLTIP_ONE_PO_CHANGE_PER_AMENDMENT,
    TOOLTIP_PO_CLOSED,
    TOOLTIP_UNAPPROVED_PO_AMENDMENT,
} from "../../../../constants/tooltipText";
import { setLine } from "../../../../redux/actions/createRequisitionActions";
import { setShowRequisitionDialogue } from "../../../../redux/actions/generalActions";
import { freeTextFormProps } from "../../../../styled/props/textFieldProps";
import { updatePostPOEditState } from "../../../../utils/copyRequisitionUtils";
import {
    isNeedByDateAmended,
    isQtyAmended,
    postPOAmendQtyRestrictions,
} from "../../../../utils/createRequisitionUtils";
import { arrayOnCondition } from "../../../../utils/general";
import { openNonCatDialogue } from "../../../../utils/nonCatFormUtils";
import ValidateChargeAccountButton from "../../../buttons/validateChargeAccountButton";
import CurrencyFigure from "../../../displays/currencyFigure";
import DetailListVertical from "../../../displays/detailListVertical";
import FormatNameValue from "../../../displays/formatNameValue";
import { ReqLineImage } from "../../../displays/reqLineImage";
import AttachmentsUpload from "../../../inputs/attachmentsUpload";
import AccountSearchAsYouType from "../../../inputs/searchAsYouType/chargeAccount/accountSearchAsYouType";
import DeliveryLocSearchAsYouType from "../../../inputs/searchAsYouType/deliveryLocSearchAsYouType";
import ExpOrgSearchAsYouType from "../../../inputs/searchAsYouType/expOrgSearchAsYouType";
import ValidatedAutocomplete from "../../../inputs/validatedAutoComplete";
import { gridColumnProps, gridSpacing } from "../../../layouts/gridLayout";
import YesNoPrompt from "../../prompts/yesNoPrompt";
import EditLineButtons from "./editLineButtons";

const lineItemFormGridColumnProps = {
    ...gridColumnProps,
    xs: 12,
    md: 6,
    lg: 4,
};
const accordionDetailsMsg =
    "The values on this page have been pre-populated based on details entered in section 1 (Header Information). Any changes to the below will only be applied to: ";
export const LineItemFormSingleLine = ({
    errors,
    register,
    reqLine,
    reqLines,
    index,
}) => {
    // Collect states and state dispatchers
    const headerForm = useSelector(
        (state) => state.createRequisition.iBuyRequisition.requisitionHeaderForm
    );

    const lookups = useSelector((state) => state.formLookups);
    const reqId = useSelector(
        (state) => state.createRequisition.iBuyRequisition.id
    );
    const isAmendedReq = useSelector(
        (state) => state.createRequisition.amendedReq
    );
    const isPostPoReq = useSelector(
        (state) => state.createRequisition.isPostPoReq
    );
    const postPOMetadata = useSelector(
        (state) => state.createRequisition.postPOMetadata
    );
    const poUnderEdit = useSelector((state) => {
        if (!isPostPoReq) {
            return null;
        }
        const postPOMetadata = state.createRequisition.postPOMetadata;
        const reqIdEdited = Object.keys(postPOMetadata).find(
            (reqLineId) => postPOMetadata[reqLineId]?.isEdited == true
        );
        return postPOMetadata[reqIdEdited]?.poNumber;
    });
    const dispatch = useDispatch();

    // // Local states
    const [tasksDisabled, setTasksDisabled] = useState(true);
    const [accountDisabled, setAccountsDisabled] = useState(true);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const isReqLineEditable = (reqLineId) => {
        const isThePOUnderEdit = poUnderEdit
            ? postPOMetadata[reqLineId]?.poNumber == poUnderEdit
            : true;
        const canPOBeEdited = postPOMetadata[reqLineId]?.canBeEdited;
        const onePOShipmentPerLine =
            postPOMetadata[reqLineId]?.associatedPOShipments.length == 1;
        const poShipment =
            postPOMetadata[reqLineId]?.associatedPOShipments[0] || {};
        const isShipmentOpen = poShipment?.CLOSED_CODE != PO_CLOSED;
        // If post po amendment, assign editable states based on postPOMetaData
        // object. If not post po amendment, req line should always be editable
        return isPostPoReq
            ? isThePOUnderEdit &&
                  canPOBeEdited &&
                  onePOShipmentPerLine &&
                  isShipmentOpen
            : true;
    };

    // Quantity form validation registration. Note required field settings are
    // disabled for postPOReq lines which cannot be edited.
    const qtyValidatorRefName = `qty_${index}`;
    const { ref: qtyRef, ...qtyInputProps } = register(`qty_${index}`, {
        onChange: (e) => updateLine(index, "QUANTITY", e.target.value),
        required:
            !isReqLineEditable(reqLine?.LINE_ATTRIBUTE5) && isPostPoReq
                ? null
                : "Required field",
        pattern: {
            value: decimalNumberRegex,
            message: "Invalid number",
        },
        min: isPostPoReq
            ? {
                  value: postPOAmendQtyRestrictions(
                      postPOMetadata,
                      reqLine?.LINE_ATTRIBUTE5
                  ).min,
                  message: postPOAmendQtyRestrictions(
                      postPOMetadata,
                      reqLine?.LINE_ATTRIBUTE5
                  ).msg,
              }
            : null,
    });

    useEffect(() => {
        if (
            !reqLine.IS_VALIDATED &&
            headerForm.ACCOUNTED_AGAINST === NON_PROJECT_REQ
        ) {
            updateLine(index, "CHARGE_ACCOUNT_ID", undefined);
        }
    }, [reqLine.IS_VALIDATED]);

    useEffect(() => {
        // If project ID defined in header, enable charge account and task dropdowns.
        setTasksDisabled(headerForm.PROJECT_ID ? isPostPoReq : true);
        setAccountsDisabled(headerForm.SEGMENT2_ID ? isPostPoReq : true);

        if (headerForm.ACCOUNTED_AGAINST === PROJECT_REQ) {
            const expOrgID = headerForm?.EXPENDITURE_ORGANIZATION_ID || null;
            const expOrgName =
                headerForm?.EXPENDITURE_ORGANIZATION_NAME || null;
            const expOrgType = headerForm?.EXPENDITURE_TYPE || null;
            const expOrgItemDate = headerForm?.EXPENDITURE_ITEM_DATE || null;

            const selectValue = (itm1, itm2) =>
                itm1 != null || itm1 != undefined ? itm1 : itm2;

            updateLine(
                index,
                "EXPENDITURE_ORGANIZATION_ID",
                selectValue(reqLine.EXPENDITURE_ORGANIZATION_ID, expOrgID)
            );

            updateLine(
                index,
                "EXPENDITURE_ORGANIZATION_NAME",
                selectValue(reqLine.EXPENDITURE_ORGANIZATION_NAME, expOrgName)
            );

            updateLine(
                index,
                "EXPENDITURE_TYPE",
                selectValue(reqLine.EXPENDITURE_TYPE, expOrgType)
            );

            updateLine(
                index,
                "EXPENDITURE_ITEM_DATE",
                selectValue(reqLine.EXPENDITURE_ITEM_DATE, expOrgItemDate)
            );
        }

        // If project ID is defined and has changed, clear drop downs 0R
        // if project ID is null, also clear dropdowns
        if (
            headerForm.ACCOUNTED_AGAINST === PROJECT_REQ &&
            ((headerForm.PROJECT_ID != reqLines[0].PROJECT_ID &&
                headerForm.PROJECT_ID) ||
                !headerForm.PROJECT_ID)
        ) {
            reqLines.forEach((_item, index) => {
                updateLine(index, "TASK_ID", null);
            });
        }
    }, []);

    const updateAttachments = (attachments, index) => {
        updateLine(index, "attachment", attachments);
    };

    const isNonCatByAmount = (index) =>
        reqLines[index]?.ITEM_TYPE == TYPE_OPTIONS[2] &&
        reqLines[index]?.LINE_TYPE == SERVICE;

    // Stop expansion for interactive components in accordion summary header
    const stopAccordionExpansion = (event) => {
        event.stopPropagation();
    };

    const updateLine = (index, field, value, omitChangedField) => {
        // Called only during post PO amendment process flow, to check
        // the edit status of fields
        isPostPoReq &&
            updatePostPOEditState(
                field,
                value,
                index,
                reqLines,
                postPOMetadata,
                field,
                dispatch
            );
        if (
            isAmendedReq &&
            omitChangedField !== true &&
            reqLines[index]?.CHANGE_ACTION !== "NEW"
        ) {
            dispatch(setLine(index, "CHANGE_ACTION", "CHANGED"));
        }
        dispatch(setLine(index, field, value));
    };

    const closeForm = () => {
        dispatch(setShowRequisitionDialogue(false));
    };

    const openNonCatForm = (reqLine, index) => {
        /*
        Arguments
        ---------
        reqLine : obj
        */
        openNonCatDialogue(
            dispatch,
            EDIT_NON_CAT_REQ_LINE,
            null,
            reqLine,
            index,
            headerForm.ORG_NAME
        );
    };

    const reqLineNotEditableTooltip = (reqLineId) => {
        const poShipment =
            postPOMetadata[reqLineId]?.associatedPOShipments[0] || {};
        if (isReqLineEditable(reqLineId)) {
            return "";
        }
        if (!postPOMetadata[reqLineId]?.canBeEdited) {
            return TOOLTIP_UNAPPROVED_PO_AMENDMENT;
        }
        if (postPOMetadata[reqLineId]?.associatedPOShipments.length != 1) {
            return TOOLTIP_MULTIPLE_PO_SHIPMENTS_PER_REQ_LINE;
        }
        if (!poShipment?.CLOSED_CODE != PO_CLOSED) {
            return TOOLTIP_PO_CLOSED;
        }

        return TOOLTIP_ONE_PO_CHANGE_PER_AMENDMENT;
    };

    const selectedDeliveryLoc = () =>
        reqLine?.DELIVER_TO_LOCATION_ID && reqLine?.LOCATION_CODE
            ? {
                  LOCATION_ID: reqLine.DELIVER_TO_LOCATION_ID,
                  LOCATION_CODE: reqLine?.LOCATION_CODE,
              }
            : null;

    const onSelectExpOrg = (value) => {
        /* Called when an expenditure organisation is selected/cleared by user.

        Parameters
        ----------
        value : obj
            The obj returned from the `onChange` method on the autocomplete
            {
                "EXPENDITURE_ORGANIZATION_ID": 123,
                "EXPENDITURE_ORGANIZATION_NAME": "Name"
            }
            */
        const expOrgId = value?.EXPENDITURE_ORGANIZATION_ID || null;
        const expOrgName = value?.EXPENDITURE_ORGANIZATION_NAME || null;

        // Update form lookups
        updateLine(index, "EXPENDITURE_ORGANIZATION_ID", expOrgId);
        updateLine(index, "EXPENDITURE_ORGANIZATION_NAME", expOrgName);
    };

    const selectedExpOrg = () =>
        reqLine?.EXPENDITURE_ORGANIZATION_ID &&
        reqLine?.EXPENDITURE_ORGANIZATION_NAME
            ? {
                  EXPENDITURE_ORGANIZATION_ID:
                      reqLine.EXPENDITURE_ORGANIZATION_ID,
                  EXPENDITURE_ORGANIZATION_NAME:
                      reqLine?.EXPENDITURE_ORGANIZATION_NAME,
              }
            : null;

    const onSelectDeliveryLoc = (value) => {
        /* Called when a delivery location is selected/cleared by user.

    Parameters
    ----------
    value : obj
        The obj returned from the `onChange` method on the
        autocomplete {"LOCATION_ID": 123, "LOCATION_CODE": "Name"}
        */
        const locCode = value?.LOCATION_CODE || null;
        const locId = value?.LOCATION_ID || null;
        // Update form lookups
        updateLine(index, "DELIVER_TO_LOCATION_ID", locId);
        updateLine(index, "LOCATION_CODE", locCode);
    };

    const selectedAccount =
        reqLine.SEGMENT3_ID && reqLine.SEGMENT3_NAME
            ? {
                  SEGMENT3_ID: reqLine.SEGMENT3_ID,
                  SEGMENT3_NAME: reqLine.SEGMENT3_NAME,
              }
            : null;

    const isProjectReq = () => {
        return headerForm.ACCOUNTED_AGAINST == PROJECT_REQ;
    };

    const summaryContentSpacing = 2;
    return (
        <>
            <Tooltip
                title={reqLineNotEditableTooltip(reqLine.LINE_ATTRIBUTE5)}
                placement="top-start"
                key={index}
            >
                <span>
                    <Accordion
                        sx={
                            isReqLineEditable(reqLine.LINE_ATTRIBUTE5)
                                ? {
                                      backgroundColor: "background.accordion",
                                  }
                                : {
                                      pointerEvents: "none",
                                      opacity: 0.5,
                                  }
                        }
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="req-line-item-accordion-controls"
                            id="req-line-item-accordion"
                            sx={{
                                "&.Mui-focusVisible": {
                                    backgroundColor: "#2E3037B3",
                                    backgroundImage: "#2E3037B3",
                                },
                            }}
                        >
                            <Box
                                sx={(theme) => {
                                    return {
                                        display: "grid",
                                        gap: theme.spacing(
                                            summaryContentSpacing
                                        ),
                                        gridTemplateAreas:
                                            "'image description' " +
                                            "'content content' " +
                                            "'buttons buttons'",
                                        gridTemplateColumns: "1fr 2fr",
                                        [theme.breakpoints.up("md")]: {
                                            gridTemplateAreas:
                                                `'image description buttons' ` +
                                                "'image content buttons' ",
                                            gridTemplateColumns:
                                                "0.7fr 7.3fr auto",
                                            gridTemplateRows: "auto 1fr",
                                        },
                                    };
                                }}
                            >
                                <Box
                                    gridArea={"image"}
                                    display="flex"
                                    alignItems="center"
                                >
                                    <ReqLineImage
                                        ITEM_IMAGE_URL={reqLine.ITEM_IMAGE_URL}
                                    />
                                </Box>
                                <Box gridArea={"description"}>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        data-testid="lineItemHeader"
                                        color="text.subheader"
                                    >
                                        {reqLine.ITEM_DESCRIPTION}
                                    </Typography>
                                </Box>
                                {/* Please note grid columns are divided into 25 */}
                                <Grid
                                    gridArea={"content"}
                                    container
                                    spacing={summaryContentSpacing}
                                    columns={25}
                                >
                                    <Grid item xs>
                                        <Box
                                            display={"flex"}
                                            flexDirection={{
                                                xs: "column",
                                                md: "row",
                                            }}
                                            flexWrap={"wrap"}
                                            gap={summaryContentSpacing}
                                        >
                                            <Box
                                                flexBasis={{
                                                    xs: "auto",
                                                    md: "200px",
                                                }}
                                            >
                                                <DetailListVertical
                                                    condensed={true}
                                                    details={[
                                                        ...arrayOnCondition(
                                                            isPostPoReq,
                                                            {
                                                                header: "PO",
                                                                content:
                                                                    postPOMetadata[
                                                                        reqLine
                                                                            .LINE_ATTRIBUTE5
                                                                    ]?.poNumber,
                                                            }
                                                        ),
                                                        {
                                                            header: isNonCatByAmount(
                                                                index
                                                            )
                                                                ? "Amount"
                                                                : "Unit Price",
                                                            content: (
                                                                <CurrencyFigure
                                                                    value={
                                                                        isNonCatByAmount(
                                                                            index
                                                                        )
                                                                            ? reqLine?.QUANTITY
                                                                            : reqLine?.UNIT_PRICE
                                                                    }
                                                                    code={
                                                                        reqLine?.CURRENCY_CODE
                                                                    }
                                                                />
                                                            ),
                                                        },
                                                        {
                                                            header: "Category",
                                                            content:
                                                                reqLine.CATEGORY,
                                                        },
                                                    ]}
                                                />
                                            </Box>

                                            <Box
                                                flexBasis={{
                                                    xs: "auto",
                                                    md: "400px",
                                                }}
                                            >
                                                {isProjectReq() ? (
                                                    <FormatNameValue
                                                        name="Project (fixed for all lines)"
                                                        value={
                                                            reqLine.SEGMENT4_ID &&
                                                            reqLine.SEGMENT4_NAME
                                                                ? `${reqLine.SEGMENT4_ID} - ${reqLine.SEGMENT4_NAME}`
                                                                : "Not Input (enter on page 1)"
                                                        }
                                                    />
                                                ) : (
                                                    <>
                                                        <Typography
                                                            variant="body2"
                                                            color="text.subheader"
                                                            fontWeight="bold"
                                                        >
                                                            Header-level GL
                                                            segments - fixed for
                                                            all lines
                                                        </Typography>
                                                        <DetailListVertical
                                                            condensed={true}
                                                            details={[
                                                                {
                                                                    header: "BSV Company Code",
                                                                    content:
                                                                        reqLine.SEGMENT1_NAME,
                                                                },
                                                                {
                                                                    header: "Cost Centre",
                                                                    content:
                                                                        reqLine.SEGMENT2_NAME,
                                                                },
                                                                {
                                                                    header: "Account",
                                                                    content:
                                                                        reqLine.SEGMENT3_NAME,
                                                                },
                                                            ]}
                                                        />
                                                    </>
                                                )}
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={25} md={5}>
                                        <Stack>
                                            <TextField
                                                {...freeTextFormProps}
                                                disabled={
                                                    !isReqLineEditable(
                                                        reqLine.LINE_ATTRIBUTE5
                                                    )
                                                }
                                                label={
                                                    isNonCatByAmount(index)
                                                        ? "Amount"
                                                        : "Qty"
                                                }
                                                required
                                                size="small"
                                                value={`${reqLine.QUANTITY}`}
                                                {...qtyInputProps}
                                                inputRef={qtyRef}
                                                error={
                                                    !!errors[
                                                        qtyValidatorRefName
                                                    ]
                                                }
                                                helperText={
                                                    errors[qtyValidatorRefName]
                                                        ?.message
                                                }
                                                onClick={stopAccordionExpansion}
                                                sx={{ mb: "1px" }}
                                            />

                                            {!isNonCatByAmount(index) && (
                                                <FormatNameValue
                                                    name="UOM"
                                                    value={
                                                        reqLine?.UNIT_OF_MEASURE
                                                    }
                                                    variant="body2"
                                                />
                                            )}

                                            {isQtyAmended(
                                                reqLine.LINE_ATTRIBUTE5,
                                                reqLine,
                                                poUnderEdit,
                                                isPostPoReq,
                                                postPOMetadata
                                            ) && (
                                                <Typography
                                                    color="#ed6c02"
                                                    variant="body2"
                                                    marginTop="2px"
                                                >
                                                    {`Was: ${
                                                        postPOMetadata[
                                                            reqLine
                                                                .LINE_ATTRIBUTE5
                                                        ]?.originalQty
                                                    }`}
                                                </Typography>
                                            )}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={25} md={5}>
                                        <Stack>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                            >
                                                <DesktopDatePicker
                                                    disablePast
                                                    disabled={
                                                        !isReqLineEditable(
                                                            reqLine.LINE_ATTRIBUTE5
                                                        )
                                                    }
                                                    label="Need By Date*"
                                                    inputFormat="dd/MM/yyyy"
                                                    value={
                                                        reqLines[index]
                                                            .NEED_BY_DATE
                                                    }
                                                    onChange={(date) =>
                                                        updateLine(
                                                            index,
                                                            "NEED_BY_DATE",
                                                            date
                                                        )
                                                    }
                                                    onClick={
                                                        stopAccordionExpansion
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            {...freeTextFormProps}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>

                                            {isNeedByDateAmended(
                                                reqLine.LINE_ATTRIBUTE5,
                                                reqLine,
                                                poUnderEdit,
                                                isPostPoReq,
                                                postPOMetadata
                                            ) && (
                                                <Typography
                                                    color="#ed6c02"
                                                    variant="body2"
                                                    marginTop="6px"
                                                >
                                                    {`Was: ${
                                                        postPOMetadata[
                                                            reqLine
                                                                .LINE_ATTRIBUTE5
                                                        ]?.originalNeedByDate
                                                            ? new Date(
                                                                  postPOMetadata[
                                                                      reqLine.LINE_ATTRIBUTE5
                                                                  ]?.originalNeedByDate
                                                              ).toDateString()
                                                            : "N/A"
                                                    }`}
                                                </Typography>
                                            )}
                                        </Stack>
                                    </Grid>
                                </Grid>

                                <Box gridArea={"buttons"} alignSelf={"center"}>
                                    <EditLineButtons
                                        stopAccordionExpansion={
                                            stopAccordionExpansion
                                        }
                                        index={index}
                                        isAmendedReq={isAmendedReq}
                                        isPostPoReq={isPostPoReq}
                                        openNonCatForm={openNonCatForm}
                                        reqLine={reqLine}
                                        reqLines={reqLines}
                                        setConfirmOpen={setConfirmOpen}
                                    />
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={gridSpacing}>
                                <Grid {...lineItemFormGridColumnProps}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h5"
                                            color="text.subheader"
                                        >
                                            General
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            {...freeTextFormProps}
                                            label="Justification (Max 480 Characters)"
                                            multiline
                                            onChange={(e) =>
                                                updateLine(
                                                    index,
                                                    "JUSTIFICATION",
                                                    e.target.value
                                                )
                                            }
                                            placeholder="Justification..."
                                            value={
                                                reqLines[index].JUSTIFICATION
                                            }
                                            disabled={isPostPoReq}
                                            inputProps={{ maxLength: 480 }}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            {...freeTextFormProps}
                                            label="Note to Buyer (Max 480 Characters)"
                                            multiline
                                            onChange={(e) =>
                                                updateLine(
                                                    index,
                                                    "NOTE_TO_BUYER",
                                                    e.target.value
                                                )
                                            }
                                            placeholder="Note to Buyer..."
                                            value={
                                                reqLines[index].NOTE_TO_BUYER
                                            }
                                            disabled={isPostPoReq}
                                            inputProps={{ maxLength: 480 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            {...freeTextFormProps}
                                            label="Note to Receiver (Max 480 Characters)"
                                            multiline
                                            onChange={(e) =>
                                                updateLine(
                                                    index,
                                                    "NOTE_TO_RECEIVER",
                                                    e.target.value
                                                )
                                            }
                                            placeholder="Note to Receiver..."
                                            value={
                                                reqLines[index].NOTE_TO_RECEIVER
                                            }
                                            disabled={isPostPoReq}
                                            inputProps={{ maxLength: 480 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            {...freeTextFormProps}
                                            label="Note to Approver (Max 448 Characters)"
                                            multiline
                                            onChange={(e) =>
                                                updateLine(
                                                    index,
                                                    "NOTE_TO_APPROVER",
                                                    e.target.value
                                                )
                                            }
                                            placeholder="Note to Approver..."
                                            value={
                                                reqLines[index].NOTE_TO_APPROVER
                                            }
                                            disabled={isPostPoReq}
                                            inputProps={{ maxLength: 448 }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid {...lineItemFormGridColumnProps}>
                                    {headerForm.ACCOUNTED_AGAINST ==
                                    PROJECT_REQ ? (
                                        <>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h5"
                                                    color="text.subheader"
                                                >
                                                    Accounting
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ValidatedAutocomplete
                                                    disabled={tasksDisabled}
                                                    label={`Project Task${
                                                        !headerForm.PROJECT_ID
                                                            ? " - select a project to activate"
                                                            : ""
                                                    }`}
                                                    placeholder="Select Project Task..."
                                                    onChange={(e, value) => {
                                                        updateLine(
                                                            index,
                                                            "TASK_ID",
                                                            value.TASK_ID
                                                        );
                                                    }}
                                                    options={lookups.tasks}
                                                    value={
                                                        reqLines[index].TASK_ID
                                                    }
                                                    IDName={{
                                                        id: "TASK_ID",
                                                        name: "TASK_UNIQUE_NAME",
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <DesktopDatePicker
                                                        label="Expenditure Item Date"
                                                        inputFormat="dd/MM/yyyy"
                                                        onChange={(date) =>
                                                            updateLine(
                                                                index,
                                                                "EXPENDITURE_ITEM_DATE",
                                                                date
                                                            )
                                                        }
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                {...freeTextFormProps}
                                                                fullWidth={
                                                                    false
                                                                }
                                                            />
                                                        )}
                                                        value={
                                                            reqLines[index]
                                                                .EXPENDITURE_ITEM_DATE
                                                        }
                                                        disabled={isPostPoReq}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <ValidatedAutocomplete
                                                    label="Expenditure Type"
                                                    placeholder="Select Expenditure Type..."
                                                    onChange={(e, value) =>
                                                        updateLine(
                                                            index,
                                                            "EXPENDITURE_TYPE",
                                                            value
                                                        )
                                                    }
                                                    options={
                                                        lookups.expenditureTypes
                                                    }
                                                    value={
                                                        reqLines[index]
                                                            .EXPENDITURE_TYPE
                                                    }
                                                    disabled={isPostPoReq}
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ExpOrgSearchAsYouType
                                                    onSelectExpOrg={
                                                        onSelectExpOrg
                                                    }
                                                    selectedExpOrg={selectedExpOrg()}
                                                />
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h5"
                                                    color="text.subheader"
                                                >
                                                    Account Segment
                                                    Configuration
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                xs={12}
                                                gap={2}
                                            >
                                                <Grid item xs>
                                                    <AccountSearchAsYouType
                                                        disabled={
                                                            accountDisabled
                                                        }
                                                        selectedValue={
                                                            selectedAccount
                                                        }
                                                        setSelectedValue={(
                                                            newValue
                                                        ) => {
                                                            updateLine(
                                                                index,
                                                                "SEGMENT3_ID",
                                                                newValue.SEGMENT3_ID
                                                            );
                                                            updateLine(
                                                                index,
                                                                "SEGMENT3_NAME",
                                                                newValue.SEGMENT3_NAME
                                                            );
                                                            updateLine(
                                                                index,
                                                                "IS_VALIDATED",
                                                                false
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    {reqLine.IS_VALIDATED ? (
                                                        <CheckCircleIcon color="success" />
                                                    ) : (
                                                        <ValidateChargeAccountButton
                                                            disabled={
                                                                !reqLine.SEGMENT3_ID
                                                            }
                                                            segmentIds={{
                                                                SEGMENT1_ID:
                                                                    reqLine.SEGMENT1_ID,
                                                                SEGMENT2_ID:
                                                                    reqLine.SEGMENT2_ID,
                                                                SEGMENT3_ID:
                                                                    reqLine.SEGMENT3_ID,
                                                            }}
                                                            operatingUnit={
                                                                headerForm.ORG_NAME
                                                            }
                                                            onSuccess={(
                                                                ccid
                                                            ) => {
                                                                updateLine(
                                                                    index,
                                                                    "IS_VALIDATED",
                                                                    true
                                                                );
                                                                updateLine(
                                                                    index,
                                                                    "CHARGE_ACCOUNT_ID",
                                                                    ccid
                                                                );
                                                            }}
                                                            onError={() =>
                                                                updateLine(
                                                                    index,
                                                                    "IS_VALIDATED",
                                                                    false
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h5"
                                            color="text.subheader"
                                        >
                                            Delivery
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DeliveryLocSearchAsYouType
                                            selectedDeliveryLoc={selectedDeliveryLoc()}
                                            onSelectDeliveryLoc={
                                                onSelectDeliveryLoc
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid {...lineItemFormGridColumnProps}>
                                    <Grid item xs={12}>
                                        <AttachmentsUpload
                                            setStateCallback={(attachments) => {
                                                updateAttachments(
                                                    attachments,
                                                    index
                                                );
                                            }}
                                            initialAttachments={
                                                reqLine.attachment
                                            }
                                            requisitionId={reqId}
                                            disableUpload={isPostPoReq}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InfoLine>
                                            {accordionDetailsMsg +
                                                reqLine.ITEM_DESCRIPTION}
                                        </InfoLine>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </span>
            </Tooltip>
            <YesNoPrompt
                title="Delete line item?"
                subtitle="This is the only item in the requisition. Deleting this
                will close the create requisition form. Are you sure you wish to delete this line item?"
                promptOpen={confirmOpen}
                setPromptOpen={setConfirmOpen}
                onClickYes={closeForm}
            />
        </>
    );
};

LineItemFormSingleLine.propTypes = {
    reqLine: PropTypes.object,
    reqLines: PropTypes.array,
    errors: PropTypes.object,
    register: PropTypes.func,
    index: PropTypes.number,
};
