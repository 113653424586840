export const styledCardSX = (darkMode) => ({
    backgroundImage: darkMode
        ? "linear-gradient(to bottom right, #3a3c45, #2E3037)"
        : "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "10",
    marginBottom: "20px",
    ":hover": {
        boxShadow: "24",
    },
});

export const homepageSmartFormCardMobileSX = (darkMode) => ({
    ...styledCardSX(darkMode),
    boxShadow: "10",
    padding: "12px",
    overflow: "visible",
});

export const homepageSmartFormCardSX = (darkMode) => ({
    ...homepageSmartFormCardMobileSX(darkMode),
    ":hover": {
        transform: "translate(0px, -8px)",
        transition: "0.3s ease",
    },
});
