import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import React from "react";

import useClipboard from "../../hooks/useClipboard";

const tooltip = "Click to copy to clipboard";

const CopyWrapper = ({ content, value, customMsg }) => {
    const { copyToClipboard } = useClipboard();

    return (
        <Tooltip
            placement="top"
            title={tooltip}
            disableInteractive
            sx={{ cursor: "pointer" }}
        >
            {content ? (
                <Box
                    display="inline"
                    onClick={() => copyToClipboard(value, customMsg)}
                >
                    {content}
                </Box>
            ) : (
                <IconButton
                    aria-label="close"
                    color="inherit"
                    onClick={() => {
                        copyToClipboard(value, customMsg);
                    }}
                >
                    <ContentCopyIcon
                        sx={{
                            fontSize: 15,
                        }}
                    />
                </IconButton>
            )}
        </Tooltip>
    );
};

CopyWrapper.propTypes = {
    value: PropTypes.any.isRequired,
    content: PropTypes.any,
    customMsg: PropTypes.string,
};

export default CopyWrapper;
