import PropTypes from "prop-types";

export const SegmentIdsType = PropTypes.exact({
    SEGMENT1_ID: PropTypes.string,
    SEGMENT2_ID: PropTypes.string,
    SEGMENT3_ID: PropTypes.string,
    SEGMENT4_ID: PropTypes.string,
});

export const ProjectType = PropTypes.shape({
    id: PropTypes.string,
    PROJECT_NAME_NUM: PropTypes.string,
    PROJECT_NUMBER: PropTypes.string,
});

export const DetailType = PropTypes.shape({
    header: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    subContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    hideHeader: PropTypes.bool,
    isLoading: PropTypes.bool,
    hasError: PropTypes.bool,
    disableCopy: PropTypes.bool,
});

export const DetailListType = PropTypes.arrayOf(DetailType);

export const ButtonType = PropTypes.shape({
    disabled: PropTypes.bool.isRequired,
    tooltipText: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    icon: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    ariaLabel: PropTypes.string.isRequired,
});

export const ButtonListType = PropTypes.arrayOf(ButtonType);

export const RequisitionSummaryType = {
    reqs: PropTypes.array.isRequired,
};
