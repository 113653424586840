import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import ValidateChargeAccountButtonContainer from "../buttons/validateChargeAccountButtonContainer";
import AccountHeaderField from "../fields/accountHeaderField";
import CompanyCodeHeaderField from "../fields/companyCodeHeaderField";
import CostCentreHeaderField from "../fields/costCentreHeaderField";

const AccountingNonProjectInputs = ({ updateHeader }) => {
    // Collect states and state dispatchers
    const headerForm = useSelector(
        (state) => state.createRequisition.iBuyRequisition.requisitionHeaderForm
    );
    const isPostPoReq = useSelector(
        (state) => state.createRequisition.isPostPoReq
    );

    useEffect(() => {
        if (!headerForm.IS_VALIDATED) {
            updateHeader(undefined, "CHARGE_ACCOUNT_ID");
        }
    }, [headerForm.IS_VALIDATED]);

    return (
        <>
            <Grid item xs={12}>
                <CompanyCodeHeaderField
                    disabled={isPostPoReq}
                    updateHeader={updateHeader}
                />
            </Grid>
            <Grid item xs={12}>
                <CostCentreHeaderField
                    disabled={isPostPoReq}
                    updateHeader={updateHeader}
                />
            </Grid>
            <Grid item xs={12}>
                <AccountHeaderField
                    disabled={isPostPoReq}
                    updateHeader={updateHeader}
                />
            </Grid>
            <Grid item xs={12}>
                <ValidateChargeAccountButtonContainer
                    isValidated={headerForm.IS_VALIDATED}
                    isDisabled={
                        !headerForm.SEGMENT1_ID ||
                        !headerForm.SEGMENT2_ID ||
                        !headerForm.SEGMENT3_ID
                    }
                    segmentIds={{
                        SEGMENT1_ID: headerForm.SEGMENT1_ID,
                        SEGMENT2_ID: headerForm.SEGMENT2_ID,
                        SEGMENT3_ID: headerForm.SEGMENT3_ID,
                    }}
                    operatingUnit={headerForm.ORG_NAME}
                    updateHeader={updateHeader}
                />
            </Grid>
        </>
    );
};

AccountingNonProjectInputs.propTypes = {
    updateHeader: PropTypes.func,
};

export default AccountingNonProjectInputs;
