// eslint-disable-next-line no-undef
export const BASE_BACKEND_URL = GLOBAL_CONFIG.REACT_APP_APIM_URL;
// eslint-disable-next-line no-undef
export const ENVIRONMENT = GLOBAL_CONFIG.REACT_APP_ENVIRONMENT;

// Base requisition URL
export const PROCUREMENT_API_URL = `${BASE_BACKEND_URL}/ibuy-${ENVIRONMENT}-Procurement`;

export const GET_PO_BY_ID = (poId) => {
    return `${PROCUREMENT_API_URL}/purchase-order/${poId}`;
};

export const GET_INVOICE_BY_ID = (invoiceId) => {
    return `${PROCUREMENT_API_URL}/invoices/${invoiceId}`;
};

export const GET_INVOICES_BY_REQ_LINE_ID = (reqLineId) => {
    return `${PROCUREMENT_API_URL}/invoices/requisition-line/${reqLineId}`;
};

export const GET_POS_BY_REQ_LINE_ID = (reqLineId) => {
    return `${PROCUREMENT_API_URL}/purchase-order-by-req/${reqLineId}`;
};

export const GET_PO_PDF_BY_PO_NUM = (poId) => {
    return `${PROCUREMENT_API_URL}/purchase-order-attachments/${poId}`;
};

export const GET_POS_PER_REQUISITION = (reqId) => {
    return `${PROCUREMENT_API_URL}/purchase-orders-by-req-id/${reqId}`;
};

/**
 * @param {number} segmentId
 * @param {string} value
 * @returns {string}
 * @constructor
 */
export const GET_SEGMENT_AUTOCOMPLETE = (segmentId, value) => {
    const searchParams = new URLSearchParams({
        by_id: "N",
    });
    const route = `${PROCUREMENT_API_URL}/segment-autocomplete/${segmentId}/${value}`;
    return `${route}?${searchParams.toString()}`;
};

export const GET_OR_CREATE_CCID = () => {
    return `${PROCUREMENT_API_URL}/charge-account-creation`;
};

export const GET_CORPORATE_EXCHANGE_RATE = () => {
    return `${PROCUREMENT_API_URL}/corporate-exchange-rate`;
};
