// Returns action creators for each receipt summary action type
import {
    SET_CORRECT_QUANTITY,
    SET_RECEIPT,
    SET_RECEIPT_LINE_CHECK,
    SET_RECEIPT_LINE_RETURN_CHECK,
    SET_RECEIPT_REASON,
    SET_RECEIPT_SUMMARY_ID,
    SET_RECEIPT_SUMMARY_LOADING,
    SET_RECEIPT_SUMMARY_PAGE_NUM,
    SET_RETURN_COMMENT,
    SET_RETURN_QUANTITY,
    SET_RETURN_REASON_ID,
    SET_RETURN_RMA,
} from "../actionTypes";

// Action creators for receipt summary slice
export const setReceipt = (receipt) => {
    return { type: SET_RECEIPT, payload: receipt };
};

export const setReceiptSummaryID = (id) => {
    return { type: SET_RECEIPT_SUMMARY_ID, payload: id };
};

export const setReceiptSummaryPageNum = (pageNum) => {
    return { type: SET_RECEIPT_SUMMARY_PAGE_NUM, payload: pageNum };
};

export const setReceiptLineCheck = (index, checked) => {
    return { type: SET_RECEIPT_LINE_CHECK, payload: { index, checked } };
};

export const setUpdatedQuantity = (index, updatedQuantity) => {
    return { type: SET_CORRECT_QUANTITY, payload: { index, updatedQuantity } };
};

export const setReceiptSummaryLoading = (isLoading) => {
    return { type: SET_RECEIPT_SUMMARY_LOADING, payload: isLoading };
};

export const setReason = (index, reason) => {
    return { type: SET_RECEIPT_REASON, payload: { index, reason } };
};

export const setReceiptLineCheckReturn = (index, checkedReturn) => {
    return {
        type: SET_RECEIPT_LINE_RETURN_CHECK,
        payload: { index, checkedReturn },
    };
};

export const setReturnQuantity = (index, returnQuantity) => {
    return { type: SET_RETURN_QUANTITY, payload: { index, returnQuantity } };
};

export const setReturnComment = (returnComment) => {
    return { type: SET_RETURN_COMMENT, payload: returnComment };
};

export const setReturnRMA = (returnRMA) => {
    return { type: SET_RETURN_RMA, payload: returnRMA };
};

export const setReturnReasonID = (returnReasonID) => {
    return { type: SET_RETURN_REASON_ID, payload: returnReasonID };
};
