import Grid from "@mui/material/Grid";
import DetailListPiped from "components/displays/detailListPiped";
import DetailListVertical from "components/displays/detailListVertical";
import OnDesktop from "components/wrappers/onDesktop";
import OnMobile from "components/wrappers/onMobile";
import React from "react";
import { useSelector } from "react-redux";

/**
 * Renders header summary information on the create receipt
 * form, including operating unit, requester name, date
 * requisition was raised and supplier.
 * @component
 */
const SummaryLine = () => {
    // Collect states and state dispatchers
    const requesterName = useSelector(
        (state) => state.createReceipt.requesterName
    );
    const submittedDate = useSelector(
        (state) => state.createReceipt.submittedDate
    );
    const operatingUnit = useSelector(
        (state) => state.createReceipt.operatingUnit
    );
    const poShipments = useSelector((state) => state.createReceipt.poShipments);

    const summaryLineDetails = [
        {
            header: "Operating Unit",
            content: operatingUnit,
        },
        {
            header: "Requester",
            content: requesterName,
        },
        {
            header: "Requisition Raised",
            content: submittedDate ? submittedDate.replaceAll("-", "/") : "-",
        },
        {
            header: "Supplier",
            content: poShipments[0]?.SUPPLIER_NAME,
        },
    ];

    return (
        <Grid container spacing={2} marginBottom="16px">
            <Grid item xs={12}>
                <OnDesktop>
                    <DetailListPiped details={summaryLineDetails} />
                </OnDesktop>
                <OnMobile>
                    <DetailListVertical
                        condensed={true}
                        details={summaryLineDetails}
                    />
                </OnMobile>
            </Grid>
        </Grid>
    );
};

export default SummaryLine;
