import jwt_decode from "jwt-decode";

import { tokenRequest } from "../authConfig";
import { msalInstance } from "../authProvider";
import {
    NOT_ASSIGNED_REDUX_ROLE,
    PROC_MANAGER,
    PROC_MANAGER_AND_REQUESTOR_REDUX_ROLE,
    PROC_MANAGER_REDUX_ROLE,
    REQUESTER,
    REQUESTOR_REDUX_ROLE,
} from "../constants/generalConstants";
import { setAuth } from "../redux/actions/authActions";
import { setUserInfoLoading } from "../redux/actions/generalActions";
import { setDefaultCartUserId } from "../redux/actions/shoppingCartActions";
import alertDispatcher from "./alertsUtils";

export const getUserInfo = async (dispatch) => {
    /*
    Arguments
    ---------
    dispatch :  func
    */
    msalInstance
        .acquireTokenSilent(tokenRequest)
        .then((res) => {
            const userClaims = jwt_decode(res.accessToken);
            const email = userClaims.email;
            const userName = stripContractor(userClaims.name);
            const samAccountName = userClaims.samAccountName;
            const operatingUnits = extractOUsFromRoles(userClaims.roles);
            const role = getiBuyRole(userClaims.roles);
            // If local storage has an OU, set as active OU
            const localStorageOU = localStorage.getItem("OU");
            const activeOU = localStorageOU
                ? localStorageOU
                : initialActiveOU(operatingUnits);
            dispatch(
                setAuth({
                    userName,
                    email,
                    samAccountName,
                    id_token: null,
                    operatingUnits,
                    role,
                    activeOU,
                })
            );
            dispatch(setUserInfoLoading(false));
            dispatch(setDefaultCartUserId(samAccountName));
        })
        .catch((e) => {
            const msg =
                "Unable to read Azure AD token - please refresh your browser";
            console.error(msg + " " + e?.message?.toString());
            alertDispatcher("getUserInfo", e?.response?.status, dispatch);
            dispatch(setUserInfoLoading(false));
        });
};

export const getInitialsFromEmail = (email) => {
    /*
    Arguments
    ---------
    email : string
    Returns
    -------
    string
    */
    if (email) {
        const initialOne = email[0];
        const userName = email.split("@")[0];
        const surname = userName.split(".")[1];
        const initialTwo = surname ? surname[0] : "";
        return (initialOne + initialTwo).toUpperCase();
    } else {
        return null;
    }
};

export const stripContractor = (userName) => {
    /*
    Arguments
    ---------
    userName : string
    Returns
    -------
    string
    */
    return userName ? userName.replace("(Contractor)", "").trim() : null;
};

export const extractOUsFromRoles = (roles) => {
    /*
    Arguments
    ---------
    roles : array

    Returns
    -------
    array
    */
    return roles.filter((item) => item != PROC_MANAGER && item != REQUESTER);
};

export const initialActiveOU = (OUArray) => {
    /*
    Arguments
    ---------
    OUArray : array

    Returns
    -------
    str
    */
    // Return last item in array as defualt OU should be SSE
    return OUArray && OUArray.length > 0 ? OUArray.sort().reverse()[0] : null;
};

export const getiBuyRole = (roles) => {
    /*
    Arguments
    ---------
    roles : array

    Returns
    -------
    bool
    */
    const isProcManager = roles.some((item) => item == PROC_MANAGER);
    const isRequester = roles.some((item) => item == REQUESTER);
    if (isProcManager && !isRequester) {
        return PROC_MANAGER_REDUX_ROLE;
    } else if (!isProcManager && isRequester) {
        return REQUESTOR_REDUX_ROLE;
    } else if (isProcManager && isRequester) {
        return PROC_MANAGER_AND_REQUESTOR_REDUX_ROLE;
    } else {
        return NOT_ASSIGNED_REDUX_ROLE;
    }
};

export const logOutUser = async (accounts) => {
    /*
    Arguments
    ---------
    accounts : obj (returned from the useMsal instance: `const { accounts } = useMsal();`)
    */
    const logoutRequest = {
        account: msalInstance.getAccountByHomeId(accounts[0].homeAccountId),
        postLogoutRedirectUri: msalInstance.getConfiguration().auth.redirectUri,
    };
    await msalInstance.logoutRedirect(logoutRequest);
};
