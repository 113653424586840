import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { setHedgingCheckPassed } from "../../../../redux/actions/createRequisitionActions";
import AlertBanner from "../../../displays/alertBanner";

const HedgingWarning = ({ shouldShowCheckbox }) => {
    const dispatch = useDispatch();
    const hedgingCheckPassed = useSelector(
        (state) => state.createRequisition.hedgingCheckPassed
    );

    const handleConsentChange = (event) => {
        dispatch(setHedgingCheckPassed(event.target.checked));
    };

    const descriptionStarter =
        "The total value of all non-catalogue requisition lines not attached to a contract purchase agreement (CPA) exceeds £250k (GBP).";
    const confirmationDescription = `${descriptionStarter} Are you sure you want to proceed?`;
    const editReqDescription = `${descriptionStarter} Please navigate back to page 2, click the pencil icon on any non-catalogue lines and enter the required hedge exchange rate as a "User" rate type. If the hedge exchange rate is not known, please contact your Finance Business Partner or Finance Contact.`;

    return (
        <AlertBanner
            severity={"warning"}
            title={"High value requisition"}
            description={
                shouldShowCheckbox
                    ? confirmationDescription
                    : editReqDescription
            }
            content={
                shouldShowCheckbox && (
                    <FormControlLabel
                        label="Yes, I'm sure"
                        control={
                            <Checkbox
                                checked={hedgingCheckPassed}
                                onChange={handleConsentChange}
                                color="secondary"
                            />
                        }
                    />
                )
            }
        />
    );
};

HedgingWarning.propTypes = {
    shouldShowCheckbox: PropTypes.bool.isRequired,
};

export default HedgingWarning;
