import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

const HeaderInfoSkeleton = () => {
    return (
        <Grid container item xs={4} spacing={1}>
            <Grid item xs={12}>
                <Skeleton
                    aria-label="headerText"
                    variant="h6"
                    width="100%"
                    sx={{
                        borderRadius: "10px",
                        boxSizing: "border-box",
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Skeleton
                    aria-label="headerText"
                    variant="body1"
                    width="75%"
                    sx={{
                        borderRadius: "10px",
                        boxSizing: "border-box",
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Skeleton
                    aria-label="headerText"
                    variant="body1"
                    width="50%"
                    sx={{
                        borderRadius: "10px",
                        boxSizing: "border-box",
                    }}
                />
            </Grid>
        </Grid>
    );
};

const PostRequisitionSkeleton = ({ numLines }) => {
    return (
        <Grid container spacing={2}>
            <HeaderInfoSkeleton />
            <HeaderInfoSkeleton />
            <HeaderInfoSkeleton />
            {[...Array(numLines).keys()].map((v) => {
                return (
                    <Grid item xs={12} key={v}>
                        <Skeleton
                            aria-label="lineAccordion"
                            height={85}
                            variant="rectangular"
                            sx={{
                                borderRadius: "10px",
                                boxSizing: "border-box",
                            }}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};
export default PostRequisitionSkeleton;
