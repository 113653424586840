import MuiLink from "@mui/material/Link";
import PropTypes from "prop-types";

import PurchaseOrderChip from "../../../buttons/purchaseOrderChip";

const HeaderPOValue = ({ req, onMultipleClick }) => {
    const poNumbers = req?.PO_NUMBERS
        ? req?.PO_NUMBERS.constructor == Array
            ? req.PO_NUMBERS
            : []
        : [];
    if (poNumbers.length == 0) {
        return "N/A";
    } else if (poNumbers.length == 1) {
        return (
            <PurchaseOrderChip
                data={{ PO_NUMBER: poNumbers[0] }}
                variant="minimal"
            />
        );
    } else {
        return (
            <MuiLink
                component="button"
                color="secondary"
                onClick={onMultipleClick}
            >
                MULTIPLE
            </MuiLink>
        );
    }
};

HeaderPOValue.propTypes = {
    req: PropTypes.object.isRequired,
    onMultipleClick: PropTypes.func.isRequired,
};

export default HeaderPOValue;
