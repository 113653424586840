import { createContext, useState } from "react";

export const RequisitionsSummaryContext = createContext({
    reqs: [],
    setReqs: () => {},
    currentReqId: undefined,
    setCurrentReqId: () => {},
});

export const RequisitionsSummaryProvider = ({ children }) => {
    const [reqs, setReqs] = useState([]);
    const [currentReqId, setCurrentReqId] = useState(undefined);

    return (
        <RequisitionsSummaryContext.Provider
            value={{
                reqs,
                setReqs,
                currentReqId,
                setCurrentReqId,
            }}
        >
            {children}
        </RequisitionsSummaryContext.Provider>
    );
};
