import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { segmentAutocompleteGet } from "../../../../services/procurement/procurementAPIs";
import alertDispatcher from "../../../../utils/alertsUtils";
import { COMPANY_CODE_INPUT_PLACEHOLDER, COMPANY_CODE_LABEL } from "../labels";
import SearchAsYouType from "../searchAsYouType";

const CompanyCodeSearchAsYouType = ({
    disabled,
    selectedValue,
    setSelectedValue,
}) => {
    const dispatch = useDispatch();

    const autocompleteGet = (searchTerm, setOptions, setIsLoading) => {
        let newOptions = [];

        // If an input is selected, set it as the option
        if (selectedValue) {
            newOptions = [selectedValue];
        }

        setIsLoading(true);

        segmentAutocompleteGet(1, searchTerm)
            .then((results) => {
                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                setOptions(newOptions);
            })
            .catch((error) => {
                alertDispatcher(
                    "companyCodeAutocompleteGet",
                    error?.response?.status,
                    dispatch
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <SearchAsYouType
            selectedValue={selectedValue}
            onSelectValue={setSelectedValue}
            isDisabled={disabled}
            autocompleteGet={autocompleteGet}
            searchPrompt={COMPANY_CODE_INPUT_PLACEHOLDER}
            label={COMPANY_CODE_LABEL}
            optionKeyMapping={{
                id: "SEGMENT1_ID",
                label: "SEGMENT1_NAME",
            }}
        />
    );
};

CompanyCodeSearchAsYouType.propTypes = {
    disabled: PropTypes.bool,
    selectedValue: PropTypes.object,
    setSelectedValue: PropTypes.func.isRequired,
};

export default CompanyCodeSearchAsYouType;
