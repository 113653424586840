// Action Types
import {
    DECREMENT_APPROVALS_COUNT,
    SET_ACTIVE_SMART_FORM,
    SET_ADD_TO_NEW_NAMED_CART_ITEM,
    SET_APPROVALS_COUNT,
    SET_CARTS_LOADING,
    SET_DARK_MODE,
    SET_FAVOURITES_ID,
    SET_HOMEPAGE_CATS_LOADING,
    SET_IS_SCROLLING,
    SET_RECEIPTS_COUNT,
    SET_SEARCH_CATEGORY,
    SET_SHOW_ADD_TO_NEW_NAMED_CART_DIALOGUE,
    SET_SHOW_APPROVALS_DIALOGUE,
    SET_SHOW_BATCH_REQ_OWNER_CHANGE_DIALOGUE,
    SET_SHOW_CANCEL_REQUISITION_DIALOGUE,
    SET_SHOW_CREATE_NAME_CART_DIALOGUE,
    SET_SHOW_DISPUTE_DIALOGUE,
    SET_SHOW_MANAGE_CART_DIALOGUE,
    SET_SHOW_NON_CATALOGUE_DIALOGUE,
    SET_SHOW_RECEIPT_SUMMARY_DIALOGUE,
    SET_SHOW_RECEIPTING_DIALOGUE,
    SET_SHOW_REQUISITION_DIALOGUE,
    SET_SHOW_SHOPPING_CART_SUMMARY_POPPER,
    SET_SHOW_SMART_FORMS_DIALOGUE,
    SET_SHOW_USER_INFO,
    SET_USER_INFO_LOADING,
    TOGGLE_NAVIGATION_BAR_WIDTH,
    TOGGLE_SPEED_DIAL_PLACEMENT,
    UPDATE_ACTIVE_CART,
} from "../actionTypes";
import { initialState } from "../initialState";

const generalReducer = (state = initialState.general, action) => {
    switch (action.type) {
        case TOGGLE_NAVIGATION_BAR_WIDTH:
            return {
                ...state,
                collapseNavBar: action.payload,
            };
        case SET_ADD_TO_NEW_NAMED_CART_ITEM:
            return {
                ...state,
                addToNewNamedCartItem: action.payload,
            };
        case SET_FAVOURITES_ID:
            return {
                ...state,
                favouritesId: action.payload,
            };
        case SET_HOMEPAGE_CATS_LOADING:
            return {
                ...state,
                homepageCategoriesLoading: action.payload,
            };
        case SET_CARTS_LOADING:
            return {
                ...state,
                cartsLoading: action.payload,
            };
        case SET_IS_SCROLLING:
            return {
                ...state,
                isScrolling: action.payload,
            };
        case SET_SEARCH_CATEGORY:
            return {
                ...state,
                searchCategory: action.payload,
            };
        case SET_SHOW_ADD_TO_NEW_NAMED_CART_DIALOGUE:
            return {
                ...state,
                showAddToNewNamedCartDialogue: action.payload,
            };
        case SET_SHOW_CREATE_NAME_CART_DIALOGUE:
            return {
                ...state,
                showCreateNamedCartDialogue: action.payload,
            };
        case SET_SHOW_MANAGE_CART_DIALOGUE:
            return {
                ...state,
                showManageCartDialogue: action.payload,
            };
        case SET_SHOW_NON_CATALOGUE_DIALOGUE:
            return {
                ...state,
                showNonCatalogueDialogue: action.payload,
            };
        case SET_SHOW_APPROVALS_DIALOGUE:
            return {
                ...state,
                showApprovalsDialogue: action.payload,
            };
        case SET_SHOW_SMART_FORMS_DIALOGUE:
            return {
                ...state,
                showSmartFormsDialogue: action.payload,
            };
        case SET_USER_INFO_LOADING:
            return {
                ...state,
                userInfoLoading: action.payload,
            };
        case SET_ACTIVE_SMART_FORM:
            return {
                ...state,
                activeSmartForm: action.payload,
            };
        case SET_SHOW_REQUISITION_DIALOGUE:
            return {
                ...state,
                showRequisitionDialogue: action.payload,
            };
        case SET_SHOW_BATCH_REQ_OWNER_CHANGE_DIALOGUE:
            return {
                ...state,
                showBatchChangeReqOwnerDialogue: action.payload,
            };
        case SET_SHOW_SHOPPING_CART_SUMMARY_POPPER:
            return {
                ...state,
                showShoppingCartSummaryPopper: action.payload,
            };
        case SET_SHOW_RECEIPT_SUMMARY_DIALOGUE:
            return {
                ...state,
                showReceiptSummaryDialogue: action.payload,
            };
        case SET_SHOW_USER_INFO:
            return {
                ...state,
                showUserInfo: action.payload,
            };
        case SET_SHOW_RECEIPTING_DIALOGUE:
            return {
                ...state,
                showReceiptingDialogue: action.payload,
            };
        case SET_SHOW_DISPUTE_DIALOGUE:
            return {
                ...state,
                showDisputeDialogue: action.payload,
            };
        case UPDATE_ACTIVE_CART:
            return {
                ...state,
                activeCart: action.payload,
            };
        case SET_DARK_MODE:
            return {
                ...state,
                darkMode: action.payload,
            };
        case SET_APPROVALS_COUNT:
            return {
                ...state,
                approvalsNotificationCount: action.payload,
            };
        case DECREMENT_APPROVALS_COUNT:
            return {
                ...state,
                approvalsNotificationCount:
                    state.approvalsNotificationCount - 1,
            };
        case SET_SHOW_CANCEL_REQUISITION_DIALOGUE:
            return {
                ...state,
                showCancelRequisitionDialogue: action.payload,
            };
        case SET_RECEIPTS_COUNT:
            return {
                ...state,
                receiptsNotificationCount: action.payload,
            };
        case TOGGLE_SPEED_DIAL_PLACEMENT:
            return {
                ...state,
                speedDialRight: action.payload,
            };
        default:
            return state;
    }
};

export default generalReducer;
