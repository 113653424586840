import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { FormControl, MenuItem, Select } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import OnDesktop from "components/wrappers/onDesktop";
import { TOOLTIP_WHAT_IS_RETURN } from "constants/tooltipText";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setReturnComment,
    setReturnReasonID,
    setReturnRMA,
} from "redux/actions/receiptSummaryActions";
import { freeTextFormProps } from "styled/props/textFieldProps";

import { SERVICE } from "../../../../constants/generalConstants";
import ReceiptReturnLine from "./receiptReturnLine";

const ReceiptReturn = ({ errors, register }) => {
    const returnReasons = useSelector(
        (state) =>
            state.filterPanelLookups.RETURN_REASONS || [
                { REASON_ID: "N/A", REASON: "NONE" },
            ]
    );
    const receipt = useSelector((state) => {
        const receipt = state.receiptSummary?.receipt;
        return receipt ? receipt : {};
    });
    const receiptLines = useSelector((state) => {
        const receiptLines = state.receiptSummary.receipt?.receiptLines;
        return receiptLines ? receiptLines : [];
    });
    const dispatch = useDispatch();

    return (
        <Grid container spacing={2} marginBottom="16px">
            <Grid item md={3} container display="flex" alignItems="center">
                <FormControl
                    fullWidth
                    size="small"
                    variant="standard"
                    color="secondary"
                >
                    <InputLabel id="reason-select">Return Reason</InputLabel>
                    <Select
                        id="reason-select"
                        label="Reason"
                        value={receipt.returnReason}
                        onChange={(e) =>
                            dispatch(setReturnReasonID(e.target.value))
                        }
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {returnReasons.map((returnReason) => (
                            <MenuItem
                                key={returnReason.REASON_ID}
                                value={returnReason.REASON_ID}
                            >
                                {returnReason.REASON}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={3} container display="flex" alignItems="center">
                <TextField
                    {...freeTextFormProps}
                    multiline
                    label="RMA"
                    placeholder="Enter RMA..."
                    value={receipt.returnRMA}
                    onChange={(e) => dispatch(setReturnRMA(e.target.value))}
                />
            </Grid>
            <Grid item md={6} container display="flex" alignItems="center">
                <TextField
                    {...freeTextFormProps}
                    multiline
                    label="Comment"
                    placeholder="Enter Comment..."
                    value={receipt.returnComment}
                    onChange={(e) => dispatch(setReturnComment(e.target.value))}
                />
            </Grid>
            <Grid item xs={12} container display="flex" flexDirection="row">
                <Typography variant="h5" color="text.subheader">
                    Receipt Lines Available for Return
                </Typography>
                <OnDesktop>
                    <Tooltip
                        title={TOOLTIP_WHAT_IS_RETURN}
                        placement="top-start"
                        disableInteractive
                    >
                        <IconButton onClick={null} sx={{ p: 0, ml: 2 }}>
                            <HelpOutlineRoundedIcon />
                        </IconButton>
                    </Tooltip>
                </OnDesktop>
            </Grid>

            {receiptLines.map((rcvLine, index) => {
                // Destructured to enable prop-type checking on key arguments
                const { checkedReturn, returnQuantity, PO_LINE_TYPE, ...line } =
                    rcvLine;
                return (
                    <Grid item xs={12} key={index}>
                        <ReceiptReturnLine
                            checkedReturn={checkedReturn}
                            returnQuantity={returnQuantity}
                            lineIndex={index}
                            rcvLine={line}
                            errors={errors}
                            register={register}
                            byAmount={PO_LINE_TYPE == SERVICE ? true : false}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};

ReceiptReturn.propTypes = {
    errors: PropTypes.object,
    register: PropTypes.func,
};

export default ReceiptReturn;
