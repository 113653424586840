import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { freeTextFormProps } from "../../../../styled/props/textFieldProps";
import InfoLine from "../../../displays/infoLine";
import DeliveryLocSearchAsYouType from "../../../inputs/searchAsYouType/deliveryLocSearchAsYouType";
import { headerFormGridColumnProps } from "./headerFormGridLayout";

const guideNote =
    "Fields entered on this page will be automatically applied to all items in the requisition. You will have the opportunity to amend these fields for individual items on page 2.";

const Section3Delivery = ({ disabled, updateHeader }) => {
    // Collect states and state dispatchers
    const headerForm = useSelector(
        (state) => state.createRequisition.iBuyRequisition.requisitionHeaderForm
    );

    /**
     * Called when a delivery location is selected/cleared by user.
     * @param {Object} value - the obj returned from the `onChange`
     * method on the autocomplete
     * {"LOCATION_ID": 123, "LOCATION_CODE": "Name"}
     */
    const onSelectDeliveryLoc = (value) => {
        const locCode = value?.LOCATION_CODE || null;
        const locId = value?.LOCATION_ID || null;
        // Update form lookups
        updateHeader(locId, "DELIVER_TO_LOCATION_ID");
        updateHeader(locCode, "LOCATION_CODE");
    };

    const selectedDeliveryLoc =
        headerForm?.DELIVER_TO_LOCATION_ID && headerForm?.LOCATION_CODE
            ? {
                  LOCATION_ID: headerForm.DELIVER_TO_LOCATION_ID,
                  LOCATION_CODE: headerForm?.LOCATION_CODE,
              }
            : null;

    return (
        <Grid {...headerFormGridColumnProps}>
            <Grid item xs={12}>
                <Typography variant="h5" color="text.subheader">
                    Delivery
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <DeliveryLocSearchAsYouType
                    selectedDeliveryLoc={selectedDeliveryLoc}
                    onSelectDeliveryLoc={onSelectDeliveryLoc}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    {...freeTextFormProps}
                    label="Note to Receiver (Max 480 Characters)"
                    multiline
                    onChange={(e) =>
                        updateHeader(e.target.value, "NOTE_TO_RECEIVER")
                    }
                    placeholder="Note to Receiver..."
                    value={headerForm.NOTE_TO_RECEIVER}
                    disabled={disabled}
                    inputProps={{ maxLength: 480 }}
                />
            </Grid>
            <Grid item xs={12}>
                <InfoLine>{guideNote}</InfoLine>
            </Grid>
        </Grid>
    );
};

Section3Delivery.propTypes = {
    disabled: PropTypes.any,
    updateHeader: PropTypes.func,
};
export default Section3Delivery;
