import { Chip, Skeleton, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import PurchaseOrderChip from "components/buttons/purchaseOrderChip";
import OutlinedChip from "components/displays/outlinedChip";
import { INVOICE_SUMMARY_PAGE } from "constants/routes";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import { dateFormat } from "../../../../../utils/general";

const Chips = ({ reqLine, isPOInvoiceLoading, poIds, invoiceIds }) => {
    return (
        <Box display={"flex"} flexDirection={"row"} gap={1} flexWrap={"wrap"}>
            <OutlinedChip
                value={`Need By: ${dateFormat(reqLine?.NEED_BY_DATE)}`}
            />
            <OutlinedChip value={reqLine?.LINE_TYPE} />

            {!isPOInvoiceLoading ? (
                <>
                    {poIds.length ? (
                        poIds.map((data, index) => {
                            return (
                                <PurchaseOrderChip
                                    data={data}
                                    variant="requisition"
                                    key={index}
                                />
                            );
                        })
                    ) : (
                        <OutlinedChip value="PO Not Issued" />
                    )}

                    {invoiceIds.length ? (
                        invoiceIds.map((data, index) => {
                            return (
                                <Link
                                    key={"pochip" + data?.INVOICE_ID + index}
                                    to={INVOICE_SUMMARY_PAGE(data?.INVOICE_ID)}
                                    style={{
                                        textDecoration: "none",
                                    }}
                                >
                                    <Tooltip
                                        title={`Click to view invoice summary for invoice number: ${data?.INVOICE_NUM} (invoice ID: ${data?.INVOICE_ID})`}
                                        placement="top"
                                    >
                                        <Chip
                                            label={`Invoice - ${
                                                data?.INVOICE_NUM
                                            } - ${
                                                data?.APPROVAL_STATUS === "Y"
                                                    ? "Approved"
                                                    : "Unapproved"
                                            }`}
                                            size="small"
                                            sx={{
                                                cursor: "pointer",
                                                "&:hover": {
                                                    background: "#d8d8d84d",
                                                },
                                            }}
                                            color={
                                                data?.APPROVAL_STATUS === "Y"
                                                    ? "success"
                                                    : "warning"
                                            }
                                            variant="outlined"
                                        />
                                    </Tooltip>
                                </Link>
                            );
                        })
                    ) : (
                        <OutlinedChip value="Invoice Not Received" />
                    )}
                </>
            ) : (
                <>
                    {Array.from(Array(2)).map((x, index) => (
                        <Skeleton
                            key={index}
                            variant="heading"
                            width="100px"
                            height="24px"
                            sx={{
                                borderRadius: "20px",
                                boxSizing: "border-box",
                                ml: "8px",
                            }}
                        />
                    ))}
                </>
            )}
        </Box>
    );
};

Chips.propTypes = {
    reqLine: PropTypes.object,
    isPOInvoiceLoading: PropTypes.bool.isRequired,
    poIds: PropTypes.arrayOf(PropTypes.object).isRequired,
    invoiceIds: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Chips;
