import { REQ_SUMMARY_TABLE_RECEIPT } from "../../constants/generalConstants";
import RequisitionsSummary from "../requisitions/requisitionListPage/requisitionsSummary";
import { RequisitionsSummaryProvider } from "../requisitions/requisitionListPage/requisitionsSummaryContext";

const ReceiptSummary = () => {
    return (
        <RequisitionsSummaryProvider>
            <RequisitionsSummary mode={REQ_SUMMARY_TABLE_RECEIPT} />
        </RequisitionsSummaryProvider>
    );
};

export default ReceiptSummary;
