import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import DetailListVertical from "components/displays/detailListVertical";
import OutlinedChip from "components/displays/outlinedChip";
import { IBUY_PRODUCT_CATEGORY_SHAREPOINT_URL } from "constants/generalConstants";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { openUserPopper } from "utils/productListUtils";
import {
    openSmartFormModal,
    SMART_FORM_NON_CAT_ITEM_TYPE_DISPATCHER,
} from "utils/smartFormsUtils";

import axiosApiInstance from "../../services/middleware/axios";
import {
    GET_INFORMATION_TEMPLATE,
    SMART_FORM_SEARCH_BY_ID,
} from "../../services/search/urls";
import alertDispatcher from "../../utils/alertsUtils";
import IBuyPaper from "../displays/iBuyPaper";
import PageNotFound from "../pageNotFound/pageNotFound";
import ProductDetailsSkeleton from "../skeletons/productDetailsSkeleton";
import { contentBoxL2 } from "./../../styled/styledSXProperties/styledBoxSX";

const SmartFormDetails = () => {
    // Collect states and state dispatchers
    const activeOU = useSelector((state) => state.auth.activeOU);
    const userInfoLoading = useSelector(
        (state) => state.general.userInfoLoading
    );
    const { id } = useParams();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [smartForm, setSmartForm] = useState({});

    useEffect(() => {
        // Search API must only be called once a user's actice OUs
        // have been retrieved and saved in redux
        if (!userInfoLoading) {
            setNotFound(false);
            // On page load, get the smartForm data from the API
            axiosApiInstance
                .get(SMART_FORM_SEARCH_BY_ID(id, activeOU))
                .then((response) => {
                    const data = response.data.data;
                    if (data.length <= 0) {
                        const action = {
                            text: "Change Operating Unit",
                            action: (e) => openUserPopper(e, dispatch),
                        };
                        alertDispatcher(
                            "smartFormDetails",
                            "404",
                            dispatch,
                            "",
                            action
                        );
                        setNotFound(true);
                        setIsLoading(false);
                    } else {
                        let result = data[0];
                        // Extract associated information templates and inject into
                        // results payload
                        axiosApiInstance
                            .get(
                                GET_INFORMATION_TEMPLATE(smartForm?.TEMPLATE_ID)
                            )
                            .then((iftResponse) => {
                                setSmartForm({
                                    ...result,
                                    INFORMATION_TEMPLATES: iftResponse.data,
                                });
                                setIsLoading(false);
                            })
                            .catch(() => {
                                setSmartForm({
                                    ...result,
                                    INFORMATION_TEMPLATES: [],
                                });
                                setIsLoading(false);
                            });
                    }
                })
                .catch((e) => {
                    alertDispatcher(
                        "smartFormDetails",
                        e?.response?.status,
                        dispatch
                    );
                    setNotFound(true);
                    setIsLoading(false);
                });
        }
    }, [activeOU]);

    // Go to category SharePoint page
    const goToCategoryTree = () => {
        window.open(IBUY_PRODUCT_CATEGORY_SHAREPOINT_URL, "_blank").focus();
    };

    // const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    const generalInfoDetails = [
        {
            header: "Product Type",
            content: "iBuy smart-form",
        },
        {
            header: "Descriptive keywords",
            content: smartForm?.KEYWORDS,
        },
        {
            header: "Item Type",
            content:
                SMART_FORM_NON_CAT_ITEM_TYPE_DISPATCHER[smartForm?.ITEM_TYPE],
        },
        {
            header: "Category Code",
            content: smartForm?.PURCHASING_CATEGORY,
        },
        {
            header: "Currency",
            content: smartForm?.CURRENCY || "Not specified on smart-form",
        },
    ];

    const supplierInfoDetails = [
        {
            header: "Associated Contract",
            content:
                smartForm?.CONTRACT ||
                "No contract purchase agreement associated",
        },
        {
            header: "Can contract be edited",
            content: smartForm?.CONTRACT_EDITABLE_FLAG,
        },
        {
            header: "Supplier Name",
            content: "Add supplier from the lookups api",
        },
        {
            header: "Supplier Site",
            content: "Additional supplier site lookups",
        },
    ];

    return (
        <Box sx={{ contentBoxL2, marginTop: 2 }}>
            {notFound ? (
                <PageNotFound />
            ) : !isLoading ? (
                <IBuyPaper>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                flexDirection="row"
                                flexWrap="wrap"
                            >
                                <Typography
                                    variant="h4"
                                    marginRight="14px"
                                    marginBottom="4px"
                                >
                                    {`Smart-Form Summary: ${smartForm?.TEMPLATE_NAME}`}
                                </Typography>
                                <OutlinedChip value="Smart-Form" marginLeft />
                                {smartForm?.CONTRACT && (
                                    <OutlinedChip value="CPA" marginLeft />
                                )}
                                {smartForm?.PURCHASING_CATEGORY && (
                                    <OutlinedChip
                                        value={`Category: ${smartForm?.PURCHASING_CATEGORY}`}
                                        marginLeft
                                        onClick={goToCategoryTree}
                                        clickable
                                    />
                                )}
                            </Box>

                            <Typography
                                variant="body1"
                                color="text.secondary"
                                marginTop="4px"
                            >
                                To open this smart-form, click &quot;Launch
                                Smart Form&quot;!
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            md={5.5}
                            lg={3.7}
                            spacing={3}
                            alignContent="flex-start"
                            alignItems="start"
                        >
                            <Grid item xs={12}>
                                <Typography
                                    variant="h5"
                                    color="text.subheader"
                                    marginBottom="12px"
                                >
                                    General Information
                                </Typography>
                                <DetailListVertical
                                    condensed={true}
                                    details={generalInfoDetails}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h5"
                                    color="text.subheader"
                                    marginBottom="12px"
                                >
                                    Supplier Information
                                </Typography>
                                <DetailListVertical
                                    condensed={true}
                                    details={supplierInfoDetails}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                container
                                justifyContent="flex-end"
                            >
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() =>
                                        openSmartFormModal(
                                            smartForm,
                                            dispatch,
                                            activeOU
                                        )
                                    }
                                >
                                    Launch Smart Form
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={0.1}>
                            <Divider orientation="vertical" />
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            md={5.5}
                            lg={3.7}
                            spacing={3}
                            alignContent="flex-start"
                            alignItems="start"
                        >
                            <Grid item xs={12}>
                                <Typography
                                    variant="h5"
                                    color="text.subheader"
                                    marginBottom="12px"
                                >
                                    Information Templates
                                </Typography>
                                <Typography variant="body1">
                                    Information templates input fields bespoke
                                    to each smart-form which you are required to
                                    fill out. Please be prepared to enter this
                                    information when ordering via this
                                    smart-form
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {smartForm?.INFORMATION_TEMPLATES.length > 0 ? (
                                    smartForm?.INFORMATION_TEMPLATES.map(
                                        (ift, index) => (
                                            <List
                                                key={index}
                                                sx={{
                                                    bgcolor:
                                                        "background.dialogue",
                                                }}
                                            >
                                                <ListItem alignItems="flex-start">
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            sx={{
                                                                bgcolor:
                                                                    "text.subheader",
                                                                color: "white",
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={
                                                            ift?.TEMPLATE_NAME
                                                        }
                                                        primaryTypographyProps={{
                                                            fontWeight: "bold",
                                                        }}
                                                        secondary={
                                                            <>
                                                                <Typography
                                                                    sx={{
                                                                        display:
                                                                            "inline",
                                                                    }}
                                                                    component="span"
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                    marginRight="16px"
                                                                >
                                                                    {`Provides Additional information submitted ${ift?.ATTACH_CATEGORY_NAME}`}
                                                                </Typography>

                                                                {`Number of Inputs: ${ift?.TEMPLATES.length}`}
                                                            </>
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider
                                                    variant="inset"
                                                    component="li"
                                                />
                                            </List>
                                        )
                                    )
                                ) : (
                                    <Typography variant="body1">
                                        There are no information templates
                                        associated with this smart-form
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={0.1}>
                            <Divider orientation="vertical" />
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            md={12}
                            lg={3.7}
                            spacing={3}
                            alignContent="flex-start"
                            alignItems="start"
                        >
                            <Grid item xs={12}>
                                <Typography
                                    variant="h5"
                                    color="text.subheader"
                                    marginBottom="12px"
                                >
                                    Helper Information
                                </Typography>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography>
                                            What is a smart-form?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Smart-forms streamline non-catalogue
                                            purchasing requests. They are fully
                                            configurable non-catalogue templates
                                            that default key information such as
                                            preferred suppliers, category, and
                                            price on the request.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography>
                                            How do I order via a smart-form?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Click &quot;Launch Smart Form&quot;
                                            on this page to launch the
                                            smart-form. Fill out the required
                                            information and add it to your cart.
                                            From there, you can create a
                                            requisition as you would with a
                                            normal iBuy product.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography>
                                            How is a smart-form order different
                                            to a non-catalogue order?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Smart-forms are very similar to
                                            non-catalogue orders. The only
                                            difference is that in a smart-form,
                                            some fields are pre-configured
                                            automatically to save you time!
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography>
                                            When should I use a smart-form?
                                            DEBBIE MUST APPROVE
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Use a smart-form when you cannot
                                            find the product or service you need
                                            on the iBuy product list
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Grid>
                </IBuyPaper>
            ) : (
                <ProductDetailsSkeleton />
            )}
        </Box>
    );
};

export default SmartFormDetails;
