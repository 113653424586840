import { DialogActions, useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { MOBILE_MEDIA_QUERY } from "constants/mediaQueries";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { styledDialogChildSX } from "styled/styledSXProperties/styledDialogueSX";
import { baseStyledPaperSX } from "styled/styledSXProperties/styledPaperSX";

import {
    closeReceiptSummaryDialogue,
    submitReceiptCorrection,
    submitReceiptReturn,
} from "../../../utils/receiptSummaryUtils";
import CustomDialogTitle from "../../customDialog/customDialogTitle";
import ReceiptSummarySkeleton from "../../skeletons/receiptSummarySkeleton";
import YesNoPrompt from "../prompts/yesNoPrompt";
import ReceiptCorrection from "./corrections/receiptCorrections";
import ReceiptSummaryDialogActions from "./dialog/receiptSummaryDialogActions";
import ReceiptSummaryDetails from "./receiptSummaryDetails";
import ReceiptReturn from "./returns/receiptReturn";
import ReceiptSummaryDetail from "./summary/receiptSummaryDetail";

export const RECEIPT_SUMMARY_PAGE_NUM = 0;

export const CORRECTION_PAGE_NUM = 1;

export const RETURN_PAGE_NUM = 2;

const ReceiptSummaryContent = ({ pageNum, register, errors }) => {
    return (
        <>
            <ReceiptSummaryDetails />
            {pageNum == RECEIPT_SUMMARY_PAGE_NUM && <ReceiptSummaryDetail />}
            {pageNum == CORRECTION_PAGE_NUM && (
                <ReceiptCorrection errors={errors} register={register} />
            )}
            {pageNum == RETURN_PAGE_NUM && (
                <ReceiptReturn errors={errors} register={register} />
            )}
        </>
    );
};

ReceiptSummaryContent.propTypes = {
    pageNum: PropTypes.number,
    register: PropTypes.any,
    errors: PropTypes.any,
};

const ReceiptSummaryDialogue = () => {
    // Collect states and state dispatchers
    const isOpen = useSelector(
        (state) => state.general.showReceiptSummaryDialogue
    );
    const pageNum = useSelector((state) => state.receiptSummary.pageNum);
    const isLoading = useSelector((state) => state.receiptSummary.isLoading);
    const receiptId = useSelector((state) => state.receiptSummary.receiptId);
    const receipt = useSelector((state) => state.receiptSummary.receipt);
    const darkMode = useSelector((state) => state.general.darkMode);
    const receiptLines = useSelector((state) =>
        state.receiptSummary?.receipt?.receiptLines
            ? state.receiptSummary?.receipt?.receiptLines
            : []
    );
    const dispatch = useDispatch();

    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    // Prompt management states closing functions
    const [confirmClosureOpen, setConfirmClosureOpen] = useState(false);
    const [confirmCorrectionSubmitOpen, setConfirmCorrectionSubmitOpen] =
        useState(false);
    const [confirmReturnSubmitOpen, setConfirmReturnSubmitOpen] =
        useState(false);
    const someReceiptLinesWithPaidInvoice = () =>
        receiptLines.some((line) => line?.PAID_INVOICE_EXIST === true);

    const title = () => {
        switch (pageNum) {
            case RETURN_PAGE_NUM:
                return `Return Receipt: ${receiptId}`;
            case CORRECTION_PAGE_NUM:
                return `Correct Receipt: ${receiptId}`;
            default:
                return `Receipt Summary: ${receiptId}`;
        }
    };

    /**
     * @param {object} _event
     * @param {string} reason
     */
    const handleClose = (_event, reason) => {
        if (reason && reason == "backdropClick") return;
        closeReceiptSummaryDialogue(dispatch);
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="lg"
                onClose={handleClose}
                open={isOpen}
                fullScreen={!isDesktop}
                PaperProps={{
                    sx: baseStyledPaperSX(darkMode),
                }}
            >
                <CustomDialogTitle
                    onClose={() => setConfirmClosureOpen(true)}
                    title={title()}
                    tooltip="Close receipting summary"
                />
                <DialogContent sx={styledDialogChildSX}>
                    {isLoading ? (
                        <ReceiptSummarySkeleton />
                    ) : (
                        <ReceiptSummaryContent
                            pageNum={pageNum}
                            register={register}
                            errors={errors}
                        />
                    )}
                </DialogContent>
                <DialogActions sx={styledDialogChildSX}>
                    <ReceiptSummaryDialogActions
                        pageNum={pageNum}
                        receiptLines={receiptLines}
                        handleSubmit={handleSubmit}
                        setConfirmCorrectionSubmitOpen={
                            setConfirmCorrectionSubmitOpen
                        }
                        setConfirmReturnSubmitOpen={setConfirmReturnSubmitOpen}
                    />
                </DialogActions>
            </Dialog>

            {/* Close form prompt */}
            <YesNoPrompt
                title="Close receipt summary page?"
                subtitle="Closing this page will result in any entered data being lost. Are you sure you wish to close this page?"
                promptOpen={confirmClosureOpen}
                setPromptOpen={setConfirmClosureOpen}
                onClickYes={() => closeReceiptSummaryDialogue(dispatch)}
            />

            {/* Submit correction form prompt */}
            <YesNoPrompt
                title="Submit Receipt Correction?"
                subtitle={`${
                    someReceiptLinesWithPaidInvoice()
                        ? "Please note paid invoices exist for the purchase order shipments associated with some of the receipt lines you are correcting. Please also note"
                        : "Please note"
                } on submission, you will be unable to make further corrections or returns to this receipt for a short period of time whilst it is validated by Harmony. Do you wish to continue?`}
                promptOpen={confirmCorrectionSubmitOpen}
                setPromptOpen={setConfirmCorrectionSubmitOpen}
                onClickYes={() => submitReceiptCorrection(receipt, dispatch)}
            />

            {/* Submit return form prompt */}
            <YesNoPrompt
                title="Submit Receipt Return?"
                subtitle="Please note on submission, you will be unable to make further corrections or returns to this receipt for a short period of time whilst it is validated by Harmony. Do you wish to continue?"
                promptOpen={confirmReturnSubmitOpen}
                setPromptOpen={setConfirmReturnSubmitOpen}
                onClickYes={() => submitReceiptReturn(receipt, dispatch)}
            />
        </>
    );
};

export default ReceiptSummaryDialogue;
