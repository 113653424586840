import { SET_LOOKUPS } from "../actionTypes";
import { initialState } from "../initialState";

const filterPanelLookupsReducer = (
    state = initialState.filterPanelLookups,
    action
) => {
    switch (action.type) {
        case SET_LOOKUPS:
            return action.payload;
        default:
            return state;
    }
};

export default filterPanelLookupsReducer;
