// Tech debt - TODO - delete once api refactored
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
    setAllLines,
    setHeaderField,
} from "../../../../redux/actions/createRequisitionActions";
import PostPoBanner from "./postPoBanner";
import Section1General from "./section1General";
import Section2Accounting from "./section2Accounting/section2Accounting";
import Section3Delivery from "./section3Delivery";

const gridSpacing = 3;

const HeaderForm = ({ setIsNextButtonDisabled }) => {
    // Collect states and state dispatchers
    const isPostPoReq = useSelector(
        (state) => state.createRequisition.isPostPoReq
    );
    const dispatch = useDispatch();

    const updateHeader = (value, field) => {
        // Update header state
        dispatch(setHeaderField(field, value));
        // Apply change to all requisition line items
        dispatch(setAllLines(field, value));
    };

    return (
        <Grid container spacing={gridSpacing}>
            {isPostPoReq && (
                <Grid item xs={12}>
                    <PostPoBanner
                        onChange={(e) => updateHeader(e.target.value, "REASON")}
                    />
                </Grid>
            )}
            <Grid item xs={12} container spacing={gridSpacing}>
                <Section1General
                    disabled={isPostPoReq}
                    updateHeader={updateHeader}
                />
                <Section2Accounting
                    disabled={isPostPoReq}
                    updateHeader={updateHeader}
                    setIsNextButtonDisabled={setIsNextButtonDisabled}
                />
                <Section3Delivery
                    disabled={isPostPoReq}
                    updateHeader={updateHeader}
                />
            </Grid>
        </Grid>
    );
};

HeaderForm.propTypes = {
    setIsNextButtonDisabled: PropTypes.func,
};

export default HeaderForm;
