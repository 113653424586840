// All redux action types separated by slice.

// Alerts slice action types
export const SET_SNACKBAR_QUEUE = "SET_SNACKBAR_QUEUE";
export const APPEND_TO_SNACKBAR_QUEUE = "APPEND_TO_SNACKBAR_QUEUE";

// General slice action types
export const SET_ADD_TO_NEW_NAMED_CART_ITEM = "SET_ADD_TO_NEW_NAMED_CART_ITEM";
export const SET_CARTS_LOADING = "SET_CARTS_LOADING";
export const SET_HOMEPAGE_CATS_LOADING = "SET_HOMEPAGE_CATS_LOADING";
export const SET_FAVOURITES_ID = "SET_FAVOURITES_ID";
export const SET_DARK_MODE = "SET_DARK_MODE";
export const SET_IS_SCROLLING = "SET_IS_SCROLLING";
export const SET_SEARCH_CATEGORY = "SET_SEARCH_CATEGORY";
export const SET_SHOW_ADD_TO_NEW_NAMED_CART_DIALOGUE =
    "SET_SHOW_ADD_TO_NEW_NAMED_CART_DIALOGUE";
export const SET_SHOW_CREATE_NAME_CART_DIALOGUE =
    "SET_SHOW_CREATE_NAME_CART_DIALOGUE";
export const SET_SHOW_MANAGE_CART_DIALOGUE = "SET_SHOW_MANAGE_CART_DIALOGUE";
export const SET_SHOW_NON_CATALOGUE_DIALOGUE =
    "SET_SHOW_NON_CATALOGUE_DIALOGUE";
export const SET_SHOW_APPROVALS_DIALOGUE = "SET_SHOW_APPROVALS_DIALOGUE";
export const SET_SHOW_CANCEL_REQUISITION_DIALOGUE =
    "SET_SHOW_CANCEL_REQUISITION_DIALOGUE";
export const SET_SHOW_REQUISITION_DIALOGUE = "SET_SHOW_REQUISITION_DIALOGUE";
export const SET_SHOW_BATCH_REQ_OWNER_CHANGE_DIALOGUE =
    "SET_SHOW_BATCH_REQ_OWNER_CHANGE_DIALOGUE";
export const SET_SHOW_SMART_FORM_DIALOGUE = "SET_SHOW_SMART_FORM_DIALOGUE";
export const SET_ACTIVE_SMART_FORM = "SET_ACTIVE_SMART_FORM";
export const SET_SHOW_SHOPPING_CART_SUMMARY_POPPER =
    "SET_SHOW_SHOPPING_CART_SUMMARY_POPPER";
export const SET_SHOW_USER_INFO = "SET_SHOW_USER_INFO";
export const SET_USER_INFO_LOADING = "SET_USER_INFO_LOADING";
export const SET_SHOW_RECEIPTING_DIALOGUE = "SET_SHOW_RECEIPTING_DIALOGUE";
export const SET_SHOW_RECEIPT_SUMMARY_DIALOGUE =
    "SET_SHOW_RECEIPT_SUMMARY_DIALOGUE";
export const TOGGLE_NAVIGATION_BAR_WIDTH = "TOGGLE_NAVIGATION_BAR_WIDTH";
export const UPDATE_ACTIVE_CART = "UPDATE_ACTIVE_CART";
export const SET_SHOW_DISPUTE_DIALOGUE = "SET_SHOW_DISPUTE_DIALOGUE";
export const SET_APPROVALS_COUNT = "SET_APPROVALS_COUNT";
export const DECREMENT_APPROVALS_COUNT = "DECREMENT_APPROVALS_COUNT";
export const SET_RECEIPTS_COUNT = "SET_RECEIPTS_COUNT";
export const TOGGLE_SPEED_DIAL_PLACEMENT = "TOGGLE_SPEED_DIAL_PLACEMENT";
export const SET_SHOW_SMART_FORMS_DIALOGUE = "SET_SHOW_SMART_FORMS_DIALOGUE";

// Auth slice action types
export const SET_AUTH = "SET_AUTH";
export const SET_ACTIVE_OU = "SET_ACTIVE_OU";

// Shopping cart slice action types
export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_NEW_CART = "ADD_NEW_CART";
export const CLEAR_CART = "CLEAR_CART";
export const CREATE_NAMED_CART = "CREATE_NAMED_CART";
export const DELETE_CART = "DELETE_CART";
export const LOAD_NAMED_CARTS = "LOAD_NAMED_CARTS";
export const RENAME_CART = "RENAME_CART";
export const SET_CART_ITEMS = "SET_CART_ITEMS";
export const SET_DEFAULT_CART_USER_ID = "SET_DEFAULT_CART_USER_ID";
export const UPDATE_QUANTITY = "UPDATE_QUANTITY";

// Filter panel lookups slice action types
export const SET_LOOKUPS = "SET_LOOKUPS";

// Filter panel values slice action types
export const SET_CATEGORY_FILTER = "SET_CATEGORY_FILTER";
export const SET_LEAD_TIME_LTE_FILTER = "SET_LEAD_TIME_LTE_FILTER";
export const SET_UNIT_PRICE_RANGE_FILTER = "SET_UNIT_PRICE_RANGE_FILTER";
export const SET_LINE_TYPE_FILTER = "SET_LINE_TYPE_FILTER";
export const SET_UNIT_MEASURE_FILTER = "SET_UNIT_MEASURE_FILTER";
export const SET_SUPPLIER_FILTER = "SET_SUPPLIER_FILTER";
export const SET_CPA_NUMBER = "SET_CPA_NUMBER";
export const SET_LOCAL_BUYER_ONLY = "SET_LOCAL_BUYER_ONLY";

// Homepage categories panel lookups slice action types
export const SET_HOMEPAGE_CATEGORIES = "SET_HOMEPAGE_CATEGORIES";

// Create requisition slice action types
export const SET_ALL_LINES = "SET_ALL_LINES";
export const SET_HEADER_FIELD = "SET_HEADER_FIELD";
export const SET_LINE = "SET_LINE";
export const SET_PAGE_NUM = "SET_PAGE_NUM";
export const SET_REQUISITION = "SET_REQUISITION";
export const SET_VALIDATED = "SET_VALIDATED";
export const UPDATE_ALL_LINES = "UPDATE_ALL_LINES";
export const SET_SUBMIT_LOADING = "SET_SUBMIT_LOADING";
export const SET_WHOLE_LINE = "SET_WHOLE_LINE";
export const SET_COPY_REQ = "SET_COPY_REQ";
export const SET_AMENDED_REQ = "SET_AMENDED_REQ";
export const SET_POST_PO_REQ = "SET_POST_PO_REQ";
export const SET_IS_INTERNAL_REQ = "SET_IS_INTERNAL_REQ";
export const DELETE_REQ_LINE = "DELETE_REQ_LINE";
export const SET_CREATE_REQ_FORM_LOADING = "SET_CREATE_REQ_FORM_LOADING";
export const DUPLICATE_LINE = "DUPLICATE_LINE";
export const SET_SUPPLIER_NAME = "SET_SUPPLIER_NAME";
export const SET_SUPPLIER_SITE = "SET_SUPPLIER_SITE";
export const SET_HR_ORGS_LOADING = "SET_HR_ORGS_LOADING";
export const TOGGLE_REQ_LIST_API_REFRESH = "TOGGLE_REQ_LIST_API_REFRESH";
export const CLEAR_POST_PO_METADATA = "CLEAR_POST_PO_METADATA";
export const UPDATE_POST_PO_METADATA = "UPDATE_POST_PO_METADATA";
export const SET_HEDGING_CHECK_PASSED = "SET_HEDGING_CHECK_PASSED";

// Form look-ups slice action types
export const SET_CHARGE_ACCOUNTS = "SET_CHARGE_ACCOUNTS";
export const SET_TASKS = "SET_TASKS";

// Requisition filter panel slice actions
export const SET_MODE = "SET_MODE";
export const SET_SHOW_CANCELLED = "SET_SHOW_CANCELLED";
export const SET_RECEIPT_STATUS = "SET_RECEIPT_STATUS";
export const SET_OU = "SET_OU";
export const SET_PRICE_RANGE = "SET_PRICE_RANGE";
export const SET_PROJECT_SEARCH = "SET_PROJECT_SEARCH";
export const SET_REQUESTER_SEARCH = "SET_REQUESTER_SEARCH";
export const SET_REQUISITION_NUMBER_SEARCH = "SET_REQUISITION_NUMBER_SEARCH";
export const SET_PO_NUMBER_SEARCH = "SET_PO_NUMBER_SEARCH";
export const SET_STATUS = "SET_STATUS";
export const SET_SUPPLIER_SEARCH = "SET_SUPPLIER_SEARCH";
export const SET_DATE_GTE = "SET_DATE_GTE";
export const SET_DATE_LTE = "SET_DATE_LTE";
export const SET_RECEIPT_REQ_STATUS = "SET_RECEIPT_REQ_STATUS";
export const SET_RECEIPT_YEAR = "SET_RECEIPT_YEAR";

// Non catalogue form slice action types
export const SET_NON_CAT_CONTEXT = "SET_NON_CAT_CONTEXT";
export const SET_NON_CAT_MODE = "SET_NON_CAT_MODE";
export const SET_PAGE = "SET_PAGE";
export const SET_PRODUCT = "SET_PRODUCT";
export const SET_PRODUCT_RATE = "SET_PRODUCT_RATE";
export const SET_REQ_LINE_INDEX = "SET_REQ_LINE_INDEX";
export const SET_SUPPLIER_CONTACT_DETAILS = "SET_SUPPLIER_CONTACT_DETAILS";
export const SET_SUPPLIER_SITES = "SET_SUPPLIER_SITES";
export const SET_SITES_INITIAL_LOADING = "SET_SITES_INITIAL_LOADING";
export const SET_CONTACTS_INITIAL_LOADING = "SET_CONTACTS_INITIAL_LOADING";
export const SET_SMART_FORMS_DATA_LOADING = "SET_SMART_FORMS_DATA_LOADING";
export const SET_INFORMATION_TEMPLATES_LOADING =
    "SET_INFORMATION_TEMPLATES_LOADING";

// Create receipt slice action types
export const SET_PO_SHIPMENTS = "SET_PO_SHIPMENTS";
export const SET_ALL_CHECKED = "SET_ALL_CHECKED";
export const SET_PO_SHIPMENT_CHECK = "SET_PO_SHIPMENT_CHECK";
export const SET_HEADER_INPUT = "SET_HEADER_INPUT";
export const SET_RECEIPT_QUANTITY = "SET_RECEIPT_QUANTITY";
export const SET_RECEIPT_REQ_NUM = "SET_RECEIPT_REQ_NUM";
export const SET_CREATE_RECEIPT_LOADING = "SET_CREATE_RECEIPT_LOADING";
export const SET_REQUESTER = "SET_REQUESTER";
export const SET_RECEIPT_OU = "SET_RECEIPT_OU";
export const SET_SUBMITTED_DATE = "SET_SUBMITTED_DATE";

// Receipt summary slice action types
export const SET_RECEIPT_SUMMARY_ID = "SET_RECEIPT_SUMMARY_ID";
export const SET_RECEIPT = "SET_RECEIPT";
export const SET_RECEIPT_SUMMARY_PAGE_NUM = "SET_RECEIPT_SUMMARY_PAGE_NUM";
export const SET_RECEIPT_LINE_CHECK = "SET_RECEIPT_LINE_CHECK";
export const SET_CORRECT_QUANTITY = "SET_CORRECT_QUANTITY";
export const SET_RECEIPT_SUMMARY_LOADING = "SET_RECEIPT_SUMMARY_LOADING";
export const SET_RECEIPT_REASON = "SET_RECEIPT_REASON";
export const SET_RECEIPT_LINE_RETURN_CHECK = "SET_RECEIPT_LINE_RETURN_CHECK";
export const SET_RETURN_QUANTITY = "SET_RETURN_QUANTITY";
export const SET_RETURN_COMMENT = "SET_RETURN_COMMENT";
export const SET_RETURN_RMA = "SET_RETURN_RMA";
export const SET_RETURN_REASON_ID = "SET_RETURN_REASON_ID";

// Single req summary slice action types
export const ADD_INVOICES = "ADD_INVOICES";
export const ADD_POS = "ADD_POS";
export const CLEAR_INVOICES = "CLEAR_INVOICES";
export const CLEAR_POS = "CLEAR_POS";
export const CLEAR_REQ_LINE_LOADING_STATE = "CLEAR_REQ_LINE_LOADING_STATE";
export const SET_REQ_LINE_LOADING_STATE = "SET_REQ_LINE_LOADING_STATE";
export const SET_DISPLAY_IN_REQ_CURRENCY = "SET_DISPLAY_IN_REQ_CURRENCY";

// Cancel req slice action types
export const SET_CANCEL_FORM_LOADING = "SET_CANCEL_FORM_LOADING";
export const SET_CANCEL_REQ_HEADER_INFO = "SET_CANCEL_REQ_HEADER_INFO";
export const SET_CANCEL_REQ_LINES = "SET_CANCEL_REQ_LINES";
export const SET_CANCEL_REQ_ID = "SET_CANCEL_REQ_ID";
export const SET_CANCEL_HEADER_REASON = "SET_CANCEL_HEADER_REASON";
export const SET_REQ_LINE_CHECKED = "SET_REQ_LINE_CHECKED";
export const SET_REQ_LINE_CANCEL_REASON = "SET_REQ_LINE_CANCEL_REASON";
export const SET_ALL_CANCEL_CHECKED = "SET_ALL_CANCEL_CHECKED";

// Change requisition owner slice action types
export const SET_CHANGE_REQ_OWNER_FORM_REQUISITION =
    "SET_CHANGE_REQ_OWNER_FORM_REQUISITION";
export const SET_TO_OWNER = "SET_TO_OWNER";
