import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { styledDialogChildSX } from "styled/styledSXProperties/styledDialogueSX";

import { MOBILE_MEDIA_QUERY } from "../../constants/mediaQueries";

const CustomDialogTitle = ({
    title,
    subtitle = "",
    onClose,
    tooltip,
    content,
}) => {
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    const getGridTemplate = () => {
        if (isDesktop) {
            return "'dialog-title dialog-title-content dialog-close-button'";
        }

        let gridTemplate = "'dialog-title dialog-close-button'";
        if (content) {
            gridTemplate += " 'dialog-title-content dialog-title-content'";
        }

        return gridTemplate;
    };

    return (
        <DialogTitle
            sx={{
                display: "grid",
                gridTemplate: getGridTemplate(),
                gridTemplateColumns:
                    isDesktop && content ? "1fr 2fr 1fr" : "1fr auto",
                gap: 2,
                ...styledDialogChildSX,
            }}
            component="div"
            data-testid="dialog-title"
        >
            <Box sx={{ gridArea: "dialog-title", alignSelf: "center" }}>
                <Typography variant="h3" marginBottom={"4px"}>
                    {title}
                </Typography>
                {subtitle && (
                    <Typography variant="body1" color="text.secondaryLight">
                        {subtitle}
                    </Typography>
                )}
            </Box>
            <Tooltip
                title={tooltip}
                sx={{
                    gridArea: "dialog-close-button",
                    alignSelf: "flex-start",
                    justifySelf: "right",
                }}
            >
                <IconButton aria-label="closeDialog" onClick={onClose}>
                    <CloseIcon fontSize="medium" />
                </IconButton>
            </Tooltip>
            {content && <Box gridArea="dialog-title-content">{content}</Box>}
        </DialogTitle>
    );
};

CustomDialogTitle.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    tooltip: PropTypes.string.isRequired,
    content: PropTypes.node,
};

export default CustomDialogTitle;
