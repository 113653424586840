// Action Types
import {
    ADD_INVOICES,
    ADD_POS,
    CLEAR_INVOICES,
    CLEAR_POS,
    CLEAR_REQ_LINE_LOADING_STATE,
    SET_DISPLAY_IN_REQ_CURRENCY,
    SET_REQ_LINE_LOADING_STATE,
} from "../actionTypes";
import { initialState } from "../initialState";

const alertsReducer = (state = initialState.singleReqSummary, action) => {
    switch (action.type) {
        case ADD_INVOICES:
            return {
                ...state,
                invoices: [
                    ...new Map(
                        [...state.invoices, ...action.payload].map(
                            (invoiceDetail) => [
                                invoiceDetail["INVOICE_ID"],
                                invoiceDetail,
                            ]
                        )
                    ).values(),
                ],
            };
        case ADD_POS:
            return {
                ...state,
                POs: [
                    ...new Map(
                        [...state.POs, ...action.payload].map((poDetail) => [
                            poDetail["PO_NUMBER"],
                            poDetail,
                        ])
                    ).values(),
                ],
            };
        case CLEAR_INVOICES:
            return {
                ...state,
                invoices: [],
            };
        case CLEAR_POS:
            return {
                ...state,
                POs: [],
            };
        case CLEAR_REQ_LINE_LOADING_STATE:
            return {
                ...state,
                reqLinesLoadingState: {},
            };
        case SET_DISPLAY_IN_REQ_CURRENCY:
            return {
                ...state,
                displayInReqCurrency: action.payload,
            };
        case SET_REQ_LINE_LOADING_STATE:
            return {
                ...state,
                reqLinesLoadingState: {
                    ...state.reqLinesLoadingState,
                    [action.payload.reqLineId]: action.payload.isLoading,
                },
            };
        default:
            return state;
    }
};

export default alertsReducer;
