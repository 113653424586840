// Returns action creators for each requisition filter panel action type
import {
    SET_DATE_GTE,
    SET_DATE_LTE,
    SET_MODE,
    SET_OU,
    SET_PO_NUMBER_SEARCH,
    SET_PRICE_RANGE,
    SET_PROJECT_SEARCH,
    SET_RECEIPT_REQ_STATUS,
    SET_RECEIPT_STATUS,
    SET_RECEIPT_YEAR,
    SET_REQUESTER_SEARCH,
    SET_REQUISITION_NUMBER_SEARCH,
    SET_SHOW_CANCELLED,
    SET_STATUS,
    SET_SUPPLIER_SEARCH,
} from "../actionTypes";

// Action creators for requisition filter panel slice
export const setDateLTE = (date) => {
    return { type: SET_DATE_LTE, payload: date };
};

export const setDateGTE = (date) => {
    return { type: SET_DATE_GTE, payload: date };
};

export const setMode = (mode) => {
    return { type: SET_MODE, payload: mode };
};

export const setOU = (OU) => {
    return { type: SET_OU, payload: OU };
};

export const setReceiptStatus = (receiptStatus) => {
    return { type: SET_RECEIPT_STATUS, payload: receiptStatus };
};

export const setPriceRange = (range) => {
    return { type: SET_PRICE_RANGE, payload: range };
};

export const setProjectSearch = (search) => {
    return { type: SET_PROJECT_SEARCH, payload: search };
};

export const setRequisitionNumberSearch = (search) => {
    return { type: SET_REQUISITION_NUMBER_SEARCH, payload: search };
};

export const setPONumberSearch = (search) => {
    return { type: SET_PO_NUMBER_SEARCH, payload: search };
};

export const setRequesterSearch = (search) => {
    return { type: SET_REQUESTER_SEARCH, payload: search };
};

export const setStatus = (status) => {
    return { type: SET_STATUS, payload: status };
};

export const setSupplierSearch = (search) => {
    return { type: SET_SUPPLIER_SEARCH, payload: search };
};

export const setReqReceiptStatus = (status) => {
    return { type: SET_RECEIPT_REQ_STATUS, payload: status };
};

export const setShowCancelled = (showCancelled) => {
    return { type: SET_SHOW_CANCELLED, payload: showCancelled };
};

export const setReceiptYear = (year) => {
    return { type: SET_RECEIPT_YEAR, payload: year };
};
