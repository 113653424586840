import {
    SET_ALL_CHECKED,
    SET_CREATE_RECEIPT_LOADING,
    SET_HEADER_INPUT,
    SET_PO_SHIPMENT_CHECK,
    SET_PO_SHIPMENTS,
    SET_RECEIPT_OU,
    SET_RECEIPT_QUANTITY,
    SET_RECEIPT_REQ_NUM,
    SET_REQUESTER,
    SET_SUBMITTED_DATE,
} from "../actionTypes";
import { initialState } from "../initialState";

const createReceiptReducer = (state = initialState.createReceipt, action) => {
    switch (action.type) {
        case SET_ALL_CHECKED:
            return {
                ...state,
                poShipments: state.poShipments.reduce(
                    (acc, shipment) => [
                        ...acc,
                        { ...shipment, checked: action.payload },
                    ],
                    []
                ),
            };
        case SET_HEADER_INPUT:
            return {
                ...state,
                headerInput: action.payload,
            };
        case SET_PO_SHIPMENT_CHECK: {
            return {
                ...state,
                poShipments: state.poShipments.reduce(
                    (acc, shipment, index) => [
                        ...acc,
                        index == action.payload.index
                            ? { ...shipment, checked: action.payload.checked }
                            : shipment,
                    ],
                    []
                ),
            };
        }
        case SET_PO_SHIPMENTS:
            return {
                ...state,
                poShipments: action.payload,
            };
        case SET_RECEIPT_QUANTITY: {
            return {
                ...state,
                poShipments: state.poShipments.reduce(
                    (acc, shipment, index) => [
                        ...acc,
                        index == action.payload.index
                            ? {
                                  ...shipment,
                                  receiptQuantity: action.payload.quantity,
                              }
                            : shipment,
                    ],
                    []
                ),
            };
        }
        case SET_RECEIPT_REQ_NUM:
            return {
                ...state,
                iBuyReqNumber: action.payload,
            };
        case SET_CREATE_RECEIPT_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case SET_RECEIPT_OU:
            return {
                ...state,
                operatingUnit: action.payload,
            };
        case SET_REQUESTER:
            return {
                ...state,
                requesterName: action.payload,
            };
        case SET_SUBMITTED_DATE:
            return {
                ...state,
                submittedDate: action.payload,
            };

        default:
            return state;
    }
};

export default createReceiptReducer;
