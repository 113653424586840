import { CardContent, CardHeader, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import IBuyCard from "components/displays/iBuyCard";
import OnDesktop from "components/wrappers/onDesktop";
import OnMobile from "components/wrappers/onMobile";
import {
    INVOICE_HOLDS,
    INVOICE_LINES,
    INVOICE_PAYMENTS,
} from "constants/generalConstants";
import PropTypes from "prop-types";
import React from "react";

import { tableHeaderProps } from "../../styled/props/tableProps";
import CurrencyFigure from "../displays/currencyFigure";
import { contentValues } from "./invoiceTabFields";

/**
 * Returns Cells formatted with currency symbol if needed
 * @param {object} col
 * @param {string} currencyCode
 * @returns
 */
const ValueField = ({ col, currencyCode }) => {
    return (
        <>
            {col.currency ? (
                <TableCell align="center">
                    <CurrencyFigure value={col.value} code={currencyCode} />
                </TableCell>
            ) : (
                <TableCell align="center">{col.value}</TableCell>
            )}
        </>
    );
};
ValueField.propTypes = {
    invoiceData: PropTypes.instanceOf(Array),
    currencyCode: PropTypes.string,
};

/**
 * Desktop Table Header
 * @param {Array[object]} invoiceData
 * @param {Literal[INVOICE_LINES, INVOICE_PAYMENTS, INVOICE_HOLDS]} contentType
 * @returns
 */
const DesktopTableHeader = ({ invoiceData, contentType }) => {
    /* 

    Parameters
    ----------
    invoiceData : Array[object]
    contentType: Literal["Line", "Payment", "Hold"]
    
    */
    return (
        <TableRow>
            {contentValues(contentType, invoiceData[0]).map(
                (data, headerIndex) => {
                    return (
                        <TableCell key={headerIndex}>
                            <Typography {...tableHeaderProps}>
                                {data.header}
                            </Typography>
                        </TableCell>
                    );
                }
            )}
        </TableRow>
    );
};
DesktopTableHeader.propTypes = {
    invoiceData: PropTypes.instanceOf(Array),
    contentType: PropTypes.oneOf([
        INVOICE_LINES,
        INVOICE_PAYMENTS,
        INVOICE_HOLDS,
    ]),
};

/**
 * Desktop Table Row - where each row is a single line/payment/hold.
 * @param {object} row
 * @param {Literal[INVOICE_LINES, INVOICE_PAYMENTS, INVOICE_HOLDS]} contentType
 * @param {string} currencyCode
 * @returns
 */
const DesktopTableRow = ({ row, contentType, currencyCode }) => {
    return (
        <TableRow
            hover
            sx={{
                "& > *": {
                    borderBottom: "unset",
                },
            }}
        >
            {contentValues(contentType, row).map((col, colIndex) => {
                return (
                    <ValueField
                        key={colIndex}
                        col={col}
                        currencyCode={currencyCode}
                    />
                );
            })}
        </TableRow>
    );
};
DesktopTableRow.propTypes = {
    row: PropTypes.object,
    contentType: PropTypes.oneOf([
        INVOICE_LINES,
        INVOICE_PAYMENTS,
        INVOICE_HOLDS,
    ]),
    currencyCode: PropTypes.string,
};

/**
 * Mobile Table - horizontal key-value format.
 * @param {Array} formattedData
 * @param {string} currencyCode
 * @returns
 */
const MobileTable = ({ formattedData, currencyCode }) => {
    return (
        <Table
            size="small"
            sx={{
                [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none",
                },
                display: "block",
            }}
        >
            <colgroup>
                <col style={{ width: "40%" }} />
                <col style={{ width: "60%" }} />
            </colgroup>
            <TableBody>
                {formattedData.slice(1).map((row, index) => (
                    <TableRow key={index}>
                        <TableCell
                            align="left"
                            style={{
                                fontWeight: "bold",
                            }}
                        >
                            {row.header}
                        </TableCell>
                        <ValueField
                            key={index}
                            col={row}
                            currencyCode={currencyCode}
                        />
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
MobileTable.propTypes = {
    formattedData: PropTypes.instanceOf(Array),
    currencyCode: PropTypes.string,
};

/**
 * Content box for each Line/Payment/Hold tab for desktop and mobile
 * @param {Array[object]} invoiceData
 * @param {string} currencyCode
 * @param {Literal[INVOICE_LINES, INVOICE_PAYMENTS, INVOICE_HOLDS]} contentType
 * @returns InvoiceTabContent Component
 */
const InvoiceTabContent = ({ invoiceData, currencyCode, contentType }) => {
    return (
        <Grid item xs={12} data-testid={`invoice${contentType}Tab`}>
            {invoiceData.length > 0 ? (
                <>
                    <OnDesktop>
                        <TableContainer data-testid="InvoiceLinesTable">
                            <Table size="small">
                                <TableHead>
                                    <DesktopTableHeader
                                        invoiceData={invoiceData}
                                        contentType={contentType}
                                    />
                                </TableHead>
                                <TableBody>
                                    {invoiceData.map((row, rowIndex) => {
                                        return (
                                            <DesktopTableRow
                                                key={rowIndex}
                                                row={row}
                                                contentType={contentType}
                                                currencyCode={currencyCode}
                                            />
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </OnDesktop>
                    <OnMobile>
                        {invoiceData.map((card, idx) => {
                            const formattedData = contentValues(
                                contentType,
                                card,
                                true
                            );

                            return (
                                <IBuyCard
                                    key={idx}
                                    additionalSx={{ mb: 0, mt: 2 }}
                                >
                                    <CardHeader
                                        title={`${contentType}: ${formattedData[0].value}`}
                                        titleTypographyProps={{
                                            mb: 0,
                                            textAlign: "center",
                                            variant: "h5",
                                            fontWeight: "bold",
                                            color: "text.subheader",
                                        }}
                                        sx={{ pb: 0 }}
                                    />
                                    <CardContent sx={{ px: 0 }}>
                                        <MobileTable
                                            formattedData={formattedData}
                                            currencyCode={currencyCode}
                                        />
                                    </CardContent>
                                </IBuyCard>
                            );
                        })}
                    </OnMobile>
                </>
            ) : (
                <Typography
                    variant="h6"
                    textAlign={"center"}
                    p={2}
                >{`No Invoice ${contentType}s Available`}</Typography>
            )}
        </Grid>
    );
};
InvoiceTabContent.propTypes = {
    invoiceData: PropTypes.instanceOf(Array),
    currencyCode: PropTypes.string,
    contentType: PropTypes.oneOf([
        INVOICE_LINES,
        INVOICE_PAYMENTS,
        INVOICE_HOLDS,
    ]),
};

export default InvoiceTabContent;
