import PropTypes from "prop-types";
import React from "react";

import AccountSearchAsYouType from "../../../../../inputs/searchAsYouType/chargeAccount/accountSearchAsYouType";
import SegmentHeaderField from "./segmentHeaderField";

const AccountHeaderField = ({ disabled, updateHeader }) => {
    return (
        <SegmentHeaderField
            disabled={disabled}
            updateHeader={updateHeader}
            segmentIdStateKey="SEGMENT3_ID"
            segmentNameStateKey="SEGMENT3_NAME"
            SegmentSearchAsYouType={AccountSearchAsYouType}
        />
    );
};

AccountHeaderField.propTypes = {
    disabled: PropTypes.any,
    updateHeader: PropTypes.func,
};

export default AccountHeaderField;
