import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IBuyPaper from "components/displays/iBuyPaper";
import {
    TOOLTIP_CANNOT_CANCEL_CLOSED_LINE,
    TOOLTIP_CANNOT_CANCEL_CLOSED_SHIPMENT,
    TOOLTIP_CANNOT_CANCEL_GENERIC,
    TOOLTIP_CANNOT_CANCEL_LOCKED_FOR_RECEIVING,
    TOOLTIP_CANNOT_CANCEL_MORE_THAN_ONE_DIST,
    TOOLTIP_CANNOT_CANCEL_MORE_THAN_ONE_SHIPMENT,
    TOOLTIP_CANNOT_CANCEL_PART_INVOICED,
    TOOLTIP_CANNOT_CANCEL_PART_RECEIVED,
    TOOLTIP_CANNOT_CANCEL_UNAPPROVED_PO,
} from "constants/tooltipText";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { APPROVED } from "../../../constants/generalConstants";
import {
    setReqLineCancelReason,
    setReqLineChecked,
} from "../../../redux/actions/cancelRequisitionActions";
import { freeTextFormProps } from "../../../styled/props/textFieldProps";
import {
    cancelReasonRegisterName,
    canReqLineBeCancelled,
} from "../../../utils/cancelRequisitionUtils";
import { ReqLineImage } from "../../displays/reqLineImage";

/**
 * Displays individual requisition line in cancellation
 * form and enables user to select it for cancellation.
 *
 * @component
 */
const CancelRequisitionLine = ({
    hasPo,
    byAmount,
    checked,
    errors,
    index,
    register,
    reqLine,
}) => {
    // Collect states and state dispatchers
    const dispatch = useDispatch();

    /**
     * Generates a tooltip explaining to user why a requisition
     * line is not in scope for cancellation. Note: the check
     * related to `associatedShipments.length` should be applied
     * first to ensure accurate messages are displayed to the user.
     * @returns {string} - tooltip displayed for cancelled lines
     */
    const disabledLineTooltip = () => {
        const associatedShipments = reqLine?.poInfo?.associatedShipments || [];
        const shipment = associatedShipments[0] || {};
        switch (true) {
            case associatedShipments.length != 1:
                return TOOLTIP_CANNOT_CANCEL_MORE_THAN_ONE_SHIPMENT;
            case shipment?.PO_HEADER_AUTHORIZATION_STATUS != APPROVED:
                return TOOLTIP_CANNOT_CANCEL_UNAPPROVED_PO;
            case shipment?.isPOLineClosed:
                return TOOLTIP_CANNOT_CANCEL_CLOSED_LINE;
            case shipment?.isPOShipmentClosed:
                return TOOLTIP_CANNOT_CANCEL_CLOSED_SHIPMENT;
            case shipment?.isPOShipmentLockedForReceiving:
                return TOOLTIP_CANNOT_CANCEL_LOCKED_FOR_RECEIVING;
            case shipment?.numberOfDistributions != 1:
                return TOOLTIP_CANNOT_CANCEL_MORE_THAN_ONE_DIST;
            case shipment?.QUANTITY_RECEIVED != 0:
                return TOOLTIP_CANNOT_CANCEL_PART_RECEIVED;
            case shipment?.QUANTITY_BILLED != 0:
                return TOOLTIP_CANNOT_CANCEL_PART_INVOICED;
            default:
                return TOOLTIP_CANNOT_CANCEL_GENERIC;
        }
    };

    /**
     * Conditional paper styling for cancel line - if a req
     * line is not in scope for cancellation it is grey-ed out
     * @returns {Object} - paper style to be rendered
     */
    const paperProperties = () =>
        canReqLineBeCancelled(hasPo, reqLine)
            ? {}
            : {
                  pointerEvents: "none",
                  opacity: 0.5,
              };

    /**
     * Writes checkbox ticking actions to redux state
     * @param {Object} event - material UI on check event metadata
     */
    const onCheck = (event) =>
        dispatch(
            setReqLineChecked(reqLine?.LINE_ATTRIBUTE5, event.target.checked)
        );

    // Register text input for form validation (applied conditionally based on if the line is checked)
    const { ref: reasonRef, ...reasonProps } = register(
        cancelReasonRegisterName(index),
        {
            onChange: (e) =>
                dispatch(
                    setReqLineCancelReason(
                        reqLine?.LINE_ATTRIBUTE5,
                        e.target.value
                    )
                ),
            required:
                checked && canReqLineBeCancelled(hasPo, reqLine)
                    ? "Required Field"
                    : null,
        }
    );

    /**
     * Determines if the requisition line is associated with
     * a part invoiced purchase order
     * @returns {boolean} - if invoiced amount exists for the line
     */
    const isQtyBilledGreaterThanZero = () => {
        const shipments = reqLine?.poInfo?.associatedShipments || [];
        return shipments.length > 0 ? shipments[0]?.QUANTITY_BILLED > 0 : false;
    };

    return (
        <Tooltip
            placement="top-start"
            title={
                canReqLineBeCancelled(hasPo, reqLine)
                    ? ""
                    : disabledLineTooltip()
            }
        >
            <span>
                <IBuyPaper additionalSx={paperProperties()}>
                    <Grid container spacing={2} columns={20}>
                        <Grid
                            item
                            xs={4}
                            md={0.6}
                            display="flex"
                            alignItems="center"
                            order={{ xs: 3, md: 1 }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={onCheck}
                                            color="secondary"
                                            disabled={
                                                !canReqLineBeCancelled(
                                                    hasPo,
                                                    reqLine
                                                )
                                            }
                                        />
                                    }
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={5}
                            md={1.5}
                            display="flex"
                            alignItems="center"
                            order={{ xs: 2, md: 2 }}
                        >
                            <ReqLineImage
                                ITEM_IMAGE_URL={reqLine?.ITEM_IMAGE_URL}
                            />
                        </Grid>
                        <Grid item xs={15} md={4.5} order={{ xs: 1, md: 3 }}>
                            <Typography variant="body2" color="text.subheader">
                                Item Description
                            </Typography>
                            <Typography variant="body2">
                                {reqLine?.ITEM_DESCRIPTION}
                            </Typography>
                        </Grid>
                        {!byAmount && (
                            <Grid item xs={8} md={2} order={{ xs: 5, md: 4 }}>
                                <Typography
                                    variant="body2"
                                    color="text.subheader"
                                    align="center"
                                >
                                    Unit of Measure
                                </Typography>
                                <Typography variant="body2" align="center">
                                    {reqLine?.UNIT_OF_MEASURE}
                                </Typography>
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={8}
                            md={byAmount ? 4 : 2}
                            order={{ xs: 5, md: 5 }}
                        >
                            <Typography
                                variant="body2"
                                color="text.subheader"
                                align="center"
                            >
                                {byAmount ? "Amount Ordered" : "Ordered"}
                            </Typography>
                            <Typography variant="body2" align="center">
                                {reqLine?.QUANTITY}
                            </Typography>
                        </Grid>
                        <Grid item xs={20} md={9.4} order={{ xs: 6, md: 6 }}>
                            <TextField
                                {...freeTextFormProps}
                                value={
                                    checked
                                        ? reqLine?.REQUISITION_CANCEL_REASON ||
                                          ""
                                        : ""
                                }
                                variant="outlined"
                                label="Line Cancellation Reason"
                                placeholder="Enter line-specific cancellation reason..."
                                multiline
                                disabled={
                                    !(
                                        checked &&
                                        canReqLineBeCancelled(hasPo, reqLine)
                                    )
                                }
                                inputRef={reasonRef}
                                {...reasonProps}
                                sx={{ mt: "6px" }}
                                error={
                                    checked
                                        ? !!errors[
                                              cancelReasonRegisterName(index)
                                          ]
                                        : false
                                }
                                helperText={
                                    checked
                                        ? errors[
                                              cancelReasonRegisterName(index)
                                          ]?.message
                                        : ""
                                }
                            />
                        </Grid>
                        {isQtyBilledGreaterThanZero() && (
                            <Grid item xs={20}>
                                <Typography
                                    variant="body2"
                                    color="error"
                                    marginTop="-12px"
                                    marginBottom="-8px"
                                >
                                    Invoices exist for the purchase order
                                    shipment associated with this line
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </IBuyPaper>
            </span>
        </Tooltip>
    );
};

CancelRequisitionLine.propTypes = {
    hasPo: PropTypes.bool,
    byAmount: PropTypes.bool,
    checked: PropTypes.bool,
    errors: PropTypes.object,
    index: PropTypes.number,
    reqLine: PropTypes.object,
    register: PropTypes.func,
};

export default CancelRequisitionLine;
