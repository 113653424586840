import {
    ADD_INVOICES,
    ADD_POS,
    CLEAR_INVOICES,
    CLEAR_POS,
    CLEAR_REQ_LINE_LOADING_STATE,
    SET_DISPLAY_IN_REQ_CURRENCY,
    SET_REQ_LINE_LOADING_STATE,
} from "../actionTypes";

// Action creators for single requisition summary slice
export const addInvoices = (invoiceArray) => {
    return { type: ADD_INVOICES, payload: invoiceArray };
};

export const addPOs = (POArray) => {
    return { type: ADD_POS, payload: POArray };
};

export const clearInvoices = () => {
    return { type: CLEAR_INVOICES };
};

export const clearPOs = () => {
    return { type: CLEAR_POS };
};

export const clearReqLineLoadingState = () => {
    return { type: CLEAR_REQ_LINE_LOADING_STATE };
};

export const setDisplayInReqCurrency = (displayInReqCurrency) => {
    return { type: SET_DISPLAY_IN_REQ_CURRENCY, payload: displayInReqCurrency };
};

export const setReqLineLoadingState = (reqLineId, isLoading) => {
    return {
        type: SET_REQ_LINE_LOADING_STATE,
        payload: { reqLineId, isLoading },
    };
};
