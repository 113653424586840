import { Grid, Skeleton } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getReceiptByReqId } from "services/receipting/receiptingAPIs";

import RequisitionLine from "./requisitionLine";

const RequisitionLines = ({ req }) => {
    // Collect states and state dispatchers
    const dispatch = useDispatch();

    // Local states
    const [isLoading, setIsLoading] = useState(true);
    const [reqLinesWithReceipts, setReqLinesWithReceipts] = useState([]);

    // TODO - 583516: This runs for everything when the page loads. Do we want it to only run when the accordion is expanded?
    useEffect(() => {
        setIsLoading(true);
        getReceiptByReqId(
            req.id,
            (val) => {
                setIsLoading(val);
            },
            setReqLinesWithReceipts,
            dispatch
        );
    }, []);

    return (
        <Grid container spacing={1}>
            {isLoading ? (
                <Grid item xs={12}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
            ) : (
                reqLinesWithReceipts.map((reqLine) => (
                    <Grid key={reqLine.LINE_ATTRIBUTE5} item xs={12}>
                        <RequisitionLine reqLine={reqLine} />
                    </Grid>
                ))
            )}
        </Grid>
    );
};

RequisitionLines.propTypes = {
    req: PropTypes.object.isRequired,
};

export default RequisitionLines;
