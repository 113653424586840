import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { CANCELLED } from "constants/generalConstants";
import {
    TOOLTIP_ACTIONS,
    TOOLTIP_DATE_CREATED,
    TOOLTIP_HEADER_DESCRIPTION,
    TOOLTIP_IBUY_REQUISITION_ID,
    TOOLTIP_OU,
    TOOLTIP_PREPARER,
    TOOLTIP_RECEIPTING_STATUS,
} from "constants/tooltipText";
import { FADE_TIMEOUT } from "constants/transitions";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RequisitionSummaryType } from "types/propTypes";
import { isReqLineCancelled } from "utils/cancelRequisitionUtils";

import { getReceiptByReqId } from "../../../services/receipting/receiptingAPIs";
import CopyTypography from "../../displays/copyTypography";
import CurrencyFigure from "../../displays/currencyFigure";
import RequisitionStatusBadge from "../../displays/status/requisitionStatusBadge";
import OpenReceiptSummaryButton from "../buttons/openReceiptSummaryButton";
import ReceiptButtonCollection from "./receiptButtonCollection";

const tableHeaderProps = {
    align: "center",
    color: "text.subheader",
    variant: "body1",
    fontWeight: "bold",
};

const subTableHeaderProps = {
    align: "center",
    color: "text.subheader",
    variant: "body1",
    fontWeight: "bold",
};

const ReceiptLineRow = ({ receiptLine }) => {
    return (
        <TableRow hover>
            <TableCell align="center" component="th" scope="row">
                <CopyTypography variant="body2" color="text.primary">
                    {receiptLine?.ERROR ? "-" : receiptLine?.RECEIPT_NUM}
                </CopyTypography>
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                <CopyTypography variant="body2" color="text.primary">
                    {receiptLine?.IBUY_RECEIPT_ID
                        ? receiptLine?.IBUY_RECEIPT_ID
                        : "-"}
                </CopyTypography>
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {receiptLine?.ERROR ? "-" : receiptLine?.LINE_NUM}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {receiptLine?.ERROR ? "-" : receiptLine?.created_by}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {receiptLine?.ERROR
                    ? receiptLine?.LAST_UPDATE_DATE
                    : receiptLine?.CREATION_DATE}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {receiptLine?.ERROR
                    ? "-"
                    : receiptLine?.UNIT_OF_MEASURE_DESCRIPTION}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                {receiptLine?.ERROR ? "-" : receiptLine?.QUANTITY_RECEIVED}
            </TableCell>
            <TableCell align="center" component="th" scope="row">
                <OpenReceiptSummaryButton
                    receiptLine={receiptLine}
                    isDesktop={true}
                />
            </TableCell>
        </TableRow>
    );
};

const RequisitionLineRow = ({ reqLine }) => {
    // Local states
    const [open, setOpen] = useState(false);
    const isExpandDisabled = () =>
        reqLine?.receiptLines ? !reqLine?.receiptLines.length > 0 : false;

    const isCancelled = isReqLineCancelled(reqLine);
    const opacity = isCancelled ? 0.5 : 1;
    return (
        <>
            <TableRow hover sx={{ opacity }}>
                <TableCell>
                    <Tooltip
                        title={
                            isExpandDisabled()
                                ? "No receipt lines associated with this requisition"
                                : "Expand to view receipt lines"
                        }
                    >
                        <span>
                            <IconButton
                                aria-label="expand row"
                                disabled={isExpandDisabled()}
                                size="small"
                                onClick={() => setOpen(!open)}
                            >
                                {open ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {reqLine?.ITEM_DESCRIPTION}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    <CurrencyFigure
                        value={reqLine?.unitPriceBaseCurrency}
                        code={reqLine?.ouBaseCurrency}
                    />
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {reqLine?.UNIT_OF_MEASURE_DESCRIPTION}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {reqLine?.QUANTITY}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {reqLine?.total_received}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {reqLine?.QUANTITY_INVOICED}
                </TableCell>
                <TableCell align="center">
                    <RequisitionStatusBadge
                        status={
                            isCancelled ? CANCELLED : reqLine?.RECEIPTING_STATUS
                        }
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={12}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography
                                fontWeight="bold"
                                variant="body1"
                                color="text.primary"
                                sx={{ mt: "16px" }}
                            >
                                {`Summary of receipt lines within ${reqLine?.ibuy_req_line_ref}`}
                            </Typography>
                            <Table
                                size="small"
                                aria-label="purchases"
                                sx={{ mb: "32px" }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography
                                                {...{
                                                    ...subTableHeaderProps,
                                                }}
                                            >
                                                Receipt Number
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                iBuy Receipt Number
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Line Number
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Created By
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Creation Date
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Unit of Measure
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Total Received
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Actions
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reqLine?.receiptLines.map(
                                        (receiptLine, index) => (
                                            <ReceiptLineRow
                                                key={index}
                                                receiptLine={receiptLine}
                                            />
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

/* This component is required to extract and stage data from the
get receipt lines by requisition ID endpoint, prior to it
being used to render the get requisition line table rows
*/
const RequisitionLineRowLoader = ({ reqId, numLines }) => {
    // Collect states and state dispatchers
    const dispatch = useDispatch();

    const numColumnsPerReqLine = 8;

    // Local states
    const [isLoading, setIsLoading] = useState(true);
    const [reqLinesWithReceipts, setReqLinesWithReceipts] = useState([]);

    // Retrieve data from api
    useEffect(() => {
        setIsLoading(true);
        getReceiptByReqId(
            reqId,
            setIsLoading,
            setReqLinesWithReceipts,
            dispatch
        );
    }, []);

    return (
        <>
            {!isLoading
                ? reqLinesWithReceipts.map((reqLine, index) => (
                      <RequisitionLineRow key={index} reqLine={reqLine} />
                  ))
                : [...Array(numLines).keys()].map((v, index) => (
                      <TableRow key={index}>
                          {[...Array(numColumnsPerReqLine).keys()].map(
                              (v, index) => (
                                  <TableCell
                                      key={index}
                                      align="center"
                                      scope="row"
                                      component="th"
                                  >
                                      <Skeleton height="35px" />
                                  </TableCell>
                              )
                          )}
                      </TableRow>
                  ))}
        </>
    );
};

const RequisitionRow = ({ req }) => {
    // Local states
    const [open, setOpen] = useState(false);

    const header = req.requisitionHeaderForm;
    const lines = req.requisitionLines;
    return (
        <>
            <TableRow
                selected={open}
                hover
                sx={{ "& > *": { borderBottom: "unset" } }}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell align="center">
                    <CopyTypography variant="body2" color="text.primary">
                        {req?.id}
                    </CopyTypography>
                </TableCell>
                <TableCell align="center">
                    {moment(req?.submittedDate).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell align="center">{header.ORG_NAME}</TableCell>
                <TableCell align="center">{req?.submittedByName}</TableCell>
                <TableCell align="center">
                    {header?.HEADER_DESCRIPTION}
                </TableCell>
                <TableCell align="center">
                    <RequisitionStatusBadge status={req?.RECEIPT_STATUS} />
                </TableCell>
                <TableCell align="center">
                    <ReceiptButtonCollection req={req} />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={12}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography
                                fontWeight="bold"
                                variant="body1"
                                color="text.primary"
                                sx={{ mt: "16px" }}
                            >
                                {`Requisition Line Items associated with ${req.id}`}
                            </Typography>
                            <Table
                                size="small"
                                aria-label="purchases"
                                sx={{ mb: "32px" }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>
                                            <Typography
                                                {...{
                                                    ...subTableHeaderProps,
                                                }}
                                            >
                                                Item Description
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Unit Price
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Unit of Measure
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Total Ordered
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Total Received
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Total Invoiced
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Receipting Status
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <RequisitionLineRowLoader
                                        reqId={req.id}
                                        numLines={lines.length}
                                    />
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
const ReceiptSummaryTable = ({ reqs }) => {
    return (
        <Fade in={true} timeout={FADE_TIMEOUT}>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>
                                <Tooltip title={TOOLTIP_IBUY_REQUISITION_ID}>
                                    <Typography
                                        {...{
                                            ...tableHeaderProps,
                                            align: "center",
                                        }}
                                    >
                                        iBuy Requisition ID
                                    </Typography>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <Tooltip title={TOOLTIP_DATE_CREATED}>
                                    <Typography {...tableHeaderProps}>
                                        Date Created
                                    </Typography>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <Tooltip title={TOOLTIP_OU}>
                                    <Typography {...tableHeaderProps}>
                                        OU
                                    </Typography>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <Tooltip title={TOOLTIP_PREPARER}>
                                    <Typography {...tableHeaderProps}>
                                        Preparer
                                    </Typography>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <Tooltip title={TOOLTIP_HEADER_DESCRIPTION}>
                                    <Typography {...tableHeaderProps}>
                                        Header Description
                                    </Typography>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <Tooltip title={TOOLTIP_RECEIPTING_STATUS}>
                                    <Typography
                                        {...tableHeaderProps}
                                        align="center"
                                    >
                                        Receipting Status
                                    </Typography>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <Tooltip title={TOOLTIP_ACTIONS}>
                                    <Typography
                                        {...tableHeaderProps}
                                        align="center"
                                    >
                                        Actions
                                    </Typography>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reqs.map((req, index) => (
                            <RequisitionRow key={index} req={req} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Fade>
    );
};

ReceiptSummaryTable.propTypes = RequisitionSummaryType;

RequisitionRow.propTypes = {
    req: PropTypes.object.isRequired,
};

RequisitionLineRowLoader.propTypes = {
    reqId: PropTypes.string,
    numLines: PropTypes.number,
};

RequisitionLineRow.propTypes = {
    reqLine: PropTypes.object,
};

ReceiptLineRow.propTypes = {
    receiptLine: PropTypes.object,
};

export default ReceiptSummaryTable;
