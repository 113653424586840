import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { canReqLineBeCancelled } from "utils/cancelRequisitionUtils";

import { MOBILE_MEDIA_QUERY } from "../../../constants/mediaQueries";
import {
    submitCancellation,
    tearDownCancelRequisitionDialogue,
} from "../../../utils/cancelRequisitionUtils";
import FormatNameValue from "./../../displays/formatNameValue";
import YesNoPrompt from "./../prompts/yesNoPrompt";
import CancelRequisitionLinesSelection from "./cancelRequisitionLinesSelection";

/**
/**
 * Form for entering a requisition cancellation
 *
 * @component
 */
const CancelRequisitionForm = () => {
    // Collect states and state dispatchers
    const iBuyReqNumber = useSelector(
        (state) => state.cancelRequisition.iBuyReqNumber
    );
    const requisitionHeaderInfo = useSelector(
        (state) => state.cancelRequisition.requisitionHeaderInfo
    );
    const requisitionLines = useSelector(
        (state) => state.cancelRequisition.requisitionLines || []
    );
    const isFormLoading = useSelector(
        (state) => state.cancelRequisition.isLoading
    );
    const dispatch = useDispatch();

    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    const navigate = useNavigate();

    // Yes / no prompt management states
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false);

    /**
     * The submit button should be enabled if at least one valid line is checked
     * @returns {bool} - Boolean stating if submit button is disabled
     */
    const isSubmitButtonDisabled = () =>
        !requisitionLines.some(
            (line) =>
                line.checked &&
                canReqLineBeCancelled(!!line?.poInfo?.hasPo || false, line)
        );

    /**
     * Triggers react hook form validation (via `handleSubmit`). If validation
     * passes, opens the "Are you sure you want to cancel" prompt
     *
     * @params {object} e - Material UI onclick event metadata
     */
    const onClickSubmit = (e) =>
        handleSubmit(() => setConfirmSubmitOpen(true))(e);

    // Instantiate react-hook-form validation
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const submittedDate = requisitionHeaderInfo?.submittedDate;
    return (
        <Grid container spacing={2} marginBottom="16px">
            <Grid item xs={12} md={9} order={{ xs: 3, md: 1 }}>
                <Typography variant="h3" marginBottom={"4px"}>
                    {`Cancel Requisition: ${iBuyReqNumber}`}
                </Typography>
                <Box
                    display="flex"
                    flexDirection={isDesktop ? "row" : "column"}
                >
                    <FormatNameValue
                        name="Operating Unit"
                        value={requisitionHeaderInfo?.operatingUnit}
                        variant="body1"
                        sx={{ mr: "16px" }}
                    />
                    {isDesktop && (
                        <Typography variant="body1" sx={{ mr: "16px" }}>
                            |
                        </Typography>
                    )}
                    <FormatNameValue
                        name="Requester"
                        value={requisitionHeaderInfo?.requesterName}
                        variant="body1"
                        sx={{ mr: "16px" }}
                    />
                    {isDesktop && (
                        <Typography variant="body1" sx={{ mr: "16px" }}>
                            |
                        </Typography>
                    )}
                    <FormatNameValue
                        name="Requisition Raised"
                        value={
                            submittedDate
                                ? submittedDate.replaceAll("-", "/")
                                : "-"
                        }
                        variant="body1"
                        sx={{ mr: "16px" }}
                    />
                </Box>
            </Grid>
            <Grid
                container
                item
                xs={11}
                md={2}
                justifyContent={"flex-end"}
                order={{ xs: 1, md: 2 }}
            >
                <Box width={isDesktop ? null : "80%"}>
                    <LoadingButton
                        color="success"
                        disabled={isSubmitButtonDisabled()}
                        loading={isFormLoading}
                        loadingPosition="end"
                        endIcon={<SendIcon />}
                        onClick={onClickSubmit}
                        variant="contained"
                        sx={!isDesktop ? { width: "80%" } : {}}
                    >
                        Submit
                    </LoadingButton>
                </Box>
            </Grid>
            <Grid
                container
                item
                xs={1}
                justifyContent="flex-end"
                alignItems="start"
                order={{ xs: 2, md: 3 }}
            >
                <Tooltip title="Close cancellation form">
                    <IconButton
                        aria-label="closeCancelDialogue"
                        onClick={() => setConfirmOpen(true)}
                    >
                        <CloseIcon fontSize="medium" />
                    </IconButton>
                </Tooltip>
            </Grid>
            <CancelRequisitionLinesSelection
                errors={errors}
                register={register}
                setValue={setValue}
            />
            <YesNoPrompt
                title="Close cancel requisition form?"
                subtitle="Closing this form prior to submission will result in all entered data being lost. Are you sure you wish to close this form?"
                promptOpen={confirmOpen}
                setPromptOpen={setConfirmOpen}
                onClickYes={() => tearDownCancelRequisitionDialogue(dispatch)}
            />
            {/* Submit creation form prompt */}
            <YesNoPrompt
                title="Are you sure you want to cancel?"
                subtitle="If you submit the cancellation, this action cannot be reversed."
                promptOpen={confirmSubmitOpen}
                setPromptOpen={setConfirmSubmitOpen}
                onClickYes={() =>
                    submitCancellation(requisitionLines, dispatch, navigate)
                }
            />
        </Grid>
    );
};
CancelRequisitionForm.propTypes = {};

export default CancelRequisitionForm;
