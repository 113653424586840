import { tokenRequest } from "../../authConfig";
import { msalInstance } from "./../../authProvider";

const axios = require("axios");
const axiosApiInstance = axios.create();
// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(async (config) => {
    // Use the same publicClientApplication instance provided to MsalProvider
    await msalInstance
        .acquireTokenSilent(tokenRequest)
        .then(function (accessTokenResponse) {
            // Acquire token silent success
            config.headers = {
                Authorization: `Bearer ${accessTokenResponse.accessToken}`,
            };
        })
        .catch(async () => {
            msalInstance.acquireTokenRedirect(tokenRequest);
        });
    return config;
});
export default axiosApiInstance;
