import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import ReceiptLine from "./receiptLine";

const ReceiptLines = ({ receiptLines }) => {
    return (
        <Grid container spacing={2}>
            {receiptLines.map((receiptLine) => (
                <Grid key={receiptLine.INTERFACE_TRANSACTION_ID} item xs={12}>
                    <ReceiptLine receiptLine={receiptLine} />
                </Grid>
            ))}
        </Grid>
    );
};

ReceiptLines.propTypes = {
    receiptLines: PropTypes.array.isRequired,
};

export default ReceiptLines;
