import {
    DEFAULT_CART_ID,
    SHOW_CANCELLED_PARAM,
} from "../../constants/generalConstants";
import {
    SERVER_MESSAGE_CANNOT_GET_FOR_USER,
    SUCCESS_POST_ATTACHMENT,
    SUCCESS_POST_CANCELLATION,
} from "../../constants/snackbarMessage";
import { setCancelFormLoading } from "../../redux/actions/cancelRequisitionActions";
import {
    setHeaderField,
    setLine,
    setSubmitLoading,
    setValidated,
    toggleReqListAPIRefresh,
} from "../../redux/actions/createRequisitionActions";
import {
    setChargeAccounts,
    setTasks,
} from "../../redux/actions/formLookupsActions";
import {
    SetApprovalsCount,
    setReceiptsCount,
    setShowRequisitionDialogue,
} from "../../redux/actions/generalActions";
import { clearCart } from "../../redux/actions/shoppingCartActions";
import alertDispatcher from "../../utils/alertsUtils.js";
import { tearDownCancelRequisitionDialogue } from "../../utils/cancelRequisitionUtils";
import { closeCreateReqForm } from "../../utils/createRequisitionUtils";
import axiosApiInstance from "../middleware/axios";
import { REQUISITIONS } from "./../../constants/routes";
import {
    ATTACHMENTS_URL,
    GET_DELIVERY_LOC_AUTOCOMPLETE,
    GET_HR_ORG_AUTOCOMPLETE,
    GET_LOOKUPS_BY_PROJECT_ID,
    GET_PROJ_AUTOCOMPLETE,
    GET_RECEIPTS_FOR_ATTENTION,
    GET_REQUISITION_BY_ID,
    GET_REQUISITIONS_FOR_APPROVAL,
    POST_CANCELLATION,
    REQUISITION_API_URL,
    UPDATE_REQUISITION_APPROVAL_URL,
} from "./urls";

var Buffer = require("buffer/").Buffer; // note: the trailing slash is important!

export const postRequisition = async (
    requisition,
    dispatch,
    activeCart,
    navigate,
    newValidatedPages,
    isAmendedReq
) => {
    /*
    Arguments
    ---------
    postRequisition : obj
    dispatch : func
    activeCart : str
    navigate : func
    newValidatedPages : obj
    */

    const submitSuccessMsg = `Requisition ${
        isAmendedReq ? "Amended" : "Submitted"
    } - iBuy Requisition Number: `;
    const apiUrl = isAmendedReq
        ? REQUISITION_API_URL + `?amend_req_id=${requisition.id}`
        : REQUISITION_API_URL;

    try {
        dispatch(setSubmitLoading(true));
        const response = await axiosApiInstance.post(apiUrl, requisition);
        const iBuyReqNumber = response.data.id;
        dispatch(setValidated(newValidatedPages));
        dispatch(setShowRequisitionDialogue(false));
        activeCart === DEFAULT_CART_ID && dispatch(clearCart(activeCart));
        navigate(REQUISITIONS);
        dispatch(toggleReqListAPIRefresh());
        alertDispatcher(
            "postRequisition",
            response?.status,
            dispatch,
            submitSuccessMsg + iBuyReqNumber
        );
        closeCreateReqForm(dispatch);
    } catch (error) {
        alertDispatcher(
            "postRequisition",
            error?.response?.status,
            dispatch,
            error?.response?.data?.detail
        );
    }
    dispatch(setSubmitLoading(false));
};

export const getRequisitionById = async (id, dispatch) => {
    /*
    Arguments
    ---------
    id : str
    dispatch: func
    */
    try {
        const response = await axiosApiInstance.get(GET_REQUISITION_BY_ID(id));
        if (response?.status == 404) {
            alertDispatcher("getRequisitionById", response?.status, dispatch);
            return { isLoading: false, notFound: false, req: response.data };
        } else {
            return { isLoading: false, notFound: false, req: response.data };
        }
    } catch (error) {
        alertDispatcher(
            "getRequisitionById",
            error?.response?.status,
            dispatch,
            error?.response?.data?.detail
        );
        return { isLoading: false, notFound: true, req: {} };
    }
};

export const listRequisitions = async (
    dispatch,
    searchParams,
    setIsLoading,
    setTotalResults,
    pagination = false
) => {
    /*
    Arguments
    ---------
    dispatch: func
    searchParams: string
    setIsLoading : func
    setTotalResults : func

    Returns
    -------
    array
    */
    !pagination && setIsLoading(true);
    const params = searchParams ? `?${searchParams}` : "";
    try {
        const response = await axiosApiInstance.get(
            REQUISITION_API_URL + params
        );
        setTotalResults(response.data.total_count);
        !pagination && setIsLoading(false);
        return response.data.data;
    } catch (error) {
        !pagination && setIsLoading(false);
        alertDispatcher(
            "listRequisitions",
            error?.response?.status,
            dispatch,
            error?.response?.data?.detail
        );
        return [];
    }
};

export const getTaskAndChargeAccountsByProject = async (
    projectId,
    dispatch
) => {
    /*
    Arguments
    ---------
    projectId : str
    dispatch: func
    */
    try {
        const response = await axiosApiInstance.get(
            GET_LOOKUPS_BY_PROJECT_ID(projectId)
        );
        // Call API, load results into redux
        dispatch(setChargeAccounts(response.data.CHARGE_ACCOUNT));
        dispatch(setTasks(response.data.PROJECT_TASKS));
    } catch (error) {
        alertDispatcher(
            "getTaskAndChargeAccountsByProject",
            error?.response?.status,
            dispatch,
            error?.response?.data?.detail
        );
    }
};

export const postAttachments = async (formData, dispatch) => {
    try {
        const response = await axiosApiInstance.post(ATTACHMENTS_URL, formData);
        alertDispatcher(
            "postAttachments",
            response?.status,
            dispatch,
            SUCCESS_POST_ATTACHMENT(response)
        );
        return response.data;
    } catch (error) {
        alertDispatcher(
            "postAttachments",
            error?.response?.status,
            dispatch,
            error?.response?.data?.detail
        );
    }
};

export const getAttachment = async (
    id,
    setURL,
    setLoadingAttachment,
    dispatch
) => {
    /*
    Arguments
    ---------
    id : str
    setURL: func
    setLoadingAttachment : func

    */
    try {
        setLoadingAttachment(true);
        const response = await axiosApiInstance.get(ATTACHMENTS_URL + id, {
            responseType: "arraybuffer",
        });
        let base64ImageString = Buffer.from(response.data, "binary").toString(
            "base64"
        );
        setURL("data:image/png;base64," + base64ImageString);
        setLoadingAttachment(false);
    } catch (error) {
        alertDispatcher(
            "getAttachment",
            error?.response?.status,
            dispatch,
            error?.response?.data?.detail
        );
        setLoadingAttachment(false);
    }
};

export const listRequisitionsForApproval = async (
    dispatch,
    searchParams,
    setIsLoading,
    setTotalResults,
    pagination = false,
    samAccountName,
    notifications = false
) => {
    !pagination && setIsLoading(true);
    const params = searchParams ? `?${searchParams}` : "";
    try {
        const response = await axiosApiInstance.get(
            GET_REQUISITIONS_FOR_APPROVAL + params
        );
        setTotalResults(response.data.total_count);
        !pagination && setIsLoading(false);

        let approvalsRequiringAction = 0;
        response.data.data.map((approval) => {
            const approvals_info = approval.approvals_info;
            if (approvals_info) {
                if (
                    approvals_info.currentIndex == approvals_info.sequence &&
                    (!approvals_info.action || approvals_info.action == "")
                ) {
                    approvalsRequiringAction++;
                }
            }
        });

        if (notifications) {
            return approvalsRequiringAction;
        } else {
            return response.data.data;
        }
    } catch (error) {
        !pagination && setIsLoading(false);
        alertDispatcher(
            "listRequisitionsForApproval",
            error?.response?.status,
            dispatch,
            SERVER_MESSAGE_CANNOT_GET_FOR_USER(samAccountName)
        );
        if (notifications) {
            return 0;
        }
        return [];
    }
};

export const listReceiptsForApproval = async (
    dispatch,
    setIsLoading,
    pagination = false,
    samAccountName
) => {
    !pagination && setIsLoading(true);
    try {
        const response = await axiosApiInstance.get(GET_RECEIPTS_FOR_ATTENTION);
        !pagination && setIsLoading(false);

        let receiptsRequiringAttention = response.data.data.length;
        return receiptsRequiringAttention;
    } catch (error) {
        !pagination && setIsLoading(false);
        alertDispatcher(
            "listReceiptsForApproval",
            error?.response?.status,
            dispatch,
            SERVER_MESSAGE_CANNOT_GET_FOR_USER(samAccountName)
        );
    }
};

export const refreshApprovalsCounter = async (dispatch, samAccountName) => {
    if (samAccountName) {
        // Always returns array
        const approvalsRequiringAction = await listRequisitionsForApproval(
            dispatch,
            "n=25&offset=0",
            false,
            () => {},
            true,
            samAccountName,
            true
        );
        dispatch(SetApprovalsCount(approvalsRequiringAction));
        return approvalsRequiringAction;
    }
};

export const refreshReceiptsCounter = async (dispatch, samAccountName) => {
    if (samAccountName) {
        const receiptsRequiringAttention = await listReceiptsForApproval(
            dispatch,
            false,
            true,
            samAccountName
        );
        dispatch(setReceiptsCount(receiptsRequiringAttention));
        return receiptsRequiringAttention;
    }
};

export const postRequisitionApproval = async (reqId, data, dispatch) => {
    try {
        const response = await axiosApiInstance.post(
            UPDATE_REQUISITION_APPROVAL_URL(reqId),
            data
        );
        alertDispatcher(
            "postRequisitionApproval",
            response?.status,
            dispatch,
            "Requisition approval update processed"
        );
        return true;
    } catch (error) {
        alertDispatcher(
            "postRequisitionApproval",
            error?.response?.status,
            dispatch
        );
        return false;
    }
};

/**
 * @typedef {Object} ProjectResponse
 * @property {string} id
 * @property {string} PROJECT_NAME_NUM
 * @property {string} PROJECT_NUMBER
 * @property {string} PROJECT_NAME
 */

/**
 * @param {string} searchTerm
 * @param {string} OUName
 * @returns {Promise<ProjectResponse[]>}
 */
export const projAutocompleteGet = async (searchTerm, OUName) => {
    const requestUrl = GET_PROJ_AUTOCOMPLETE(searchTerm, OUName);
    const response = await axiosApiInstance.get(requestUrl);
    return response.data;
};

export const deliveryLocAutocompleteGet = async (
    dispatch,
    deliveryLocSearchTerm,
    setDeliveryLocOptions,
    selectedDeliveryLoc,
    setDeliveryLocLoading
) => {
    try {
        let newOptions = [];
        // If an input is selected, set it as the option
        if (selectedDeliveryLoc) {
            newOptions = [selectedDeliveryLoc];
        }
        setDeliveryLocLoading(true);
        let response = await axiosApiInstance.get(
            GET_DELIVERY_LOC_AUTOCOMPLETE(deliveryLocSearchTerm)
        );
        const results = response.data;

        if (results) {
            newOptions = [...newOptions, ...results];
        }
        setDeliveryLocOptions(newOptions);
        setDeliveryLocLoading(false);
    } catch (error) {
        alertDispatcher(
            "deliveryLocAutocompleteGet",
            error?.response?.status,
            dispatch
        );
    }
};

export const getDeliveryLocById = async (
    locId,
    dispatch,
    headerLevel = true,
    reqLineIndex = null
) => {
    /*
    Arguments
    ---------
    locId : str
    dispatch: func
    headerLevel : bool
    reqLineIndex : int
        Must be specified if `headerLevel = false`

    */
    if (!locId) {
        return;
    }
    try {
        // Call API, load results into redux
        const response = await axiosApiInstance.get(
            GET_DELIVERY_LOC_AUTOCOMPLETE(locId, true)
        );
        headerLevel
            ? dispatch(
                  setHeaderField("LOCATION_CODE", response.data.LOCATION_CODE)
              )
            : dispatch(
                  setLine(
                      reqLineIndex,
                      "LOCATION_CODE",
                      response.data.LOCATION_CODE
                  )
              );
    } catch (error) {
        alertDispatcher(
            "getDeliveryLocById",
            error?.response?.status,
            dispatch
        );
    }
};

export const hrOrgAutocompleteGet = async (
    dispatch,
    hrOrgSearchTerm,
    setHrOrgOptions,
    selectedHrOrg,
    setHrOrgLoading,
    OUName
) => {
    try {
        let newOptions = [];
        // If an input is selected, set it as the option
        if (selectedHrOrg) {
            newOptions = [selectedHrOrg];
        }
        setHrOrgLoading(true);
        let response = await axiosApiInstance.get(
            GET_HR_ORG_AUTOCOMPLETE(hrOrgSearchTerm, OUName)
        );
        const results = response.data;

        if (results) {
            newOptions = [...newOptions, ...results];
        }
        setHrOrgOptions(newOptions);
        setHrOrgLoading(false);
    } catch (error) {
        alertDispatcher(
            "hrOrgAutocompleteGet",
            error?.response?.status,
            dispatch
        );
    }
};

export const getHrOrgById = async (
    hrOrgId,
    OUName,
    dispatch,
    headerLevel = true,
    reqLineIndex = null
) => {
    /*
    Arguments
    ---------
    hrOrgId : str
    OUName : str
    dispatch: func
    headerLevel : bool
    reqLineIndex : int
        Must be specified if `headerLevel = false`
    */
    if (!hrOrgId) {
        return;
    }
    try {
        // Call API, load results into redux
        const response = await axiosApiInstance.get(
            GET_HR_ORG_AUTOCOMPLETE(hrOrgId, OUName, true)
        );
        headerLevel
            ? dispatch(
                  setHeaderField(
                      "EXPENDITURE_ORGANIZATION_NAME",
                      response.data.EXPENDITURE_ORGANIZATION_NAME
                  )
              )
            : dispatch(
                  setLine(
                      reqLineIndex,
                      "EXPENDITURE_ORGANIZATION_NAME",
                      response.data.EXPENDITURE_ORGANIZATION_NAME
                  )
              );
    } catch (error) {
        alertDispatcher("getHrOrgById", error?.response?.status, dispatch);
    }
};

/**
 * Submits cancellation request to backend api
 * @param {string} reqId -  iBuy id of requisition being cancelled
 * @param {Object} cancelPayload - Requisition cancellation payload
 * @param {func} dispatch - Redux dispatch function
 * @param {func} navigate - React router navigate function
 */
export const postCancellation = async (
    reqId,
    cancelPayload,
    dispatch,
    navigate
) => {
    try {
        dispatch(setCancelFormLoading(true));
        const response = await axiosApiInstance.post(
            POST_CANCELLATION(reqId),
            cancelPayload
        );
        alertDispatcher(
            "postCancellation",
            response?.status,
            dispatch,
            SUCCESS_POST_CANCELLATION(reqId)
        );
        tearDownCancelRequisitionDialogue(dispatch);
        const cancelledReqsListUrl = `${REQUISITIONS}?requisitionId=${reqId}&cancelled=${SHOW_CANCELLED_PARAM}`;
        navigate(cancelledReqsListUrl);
        dispatch(setCancelFormLoading(false));
    } catch (error) {
        alertDispatcher(
            "postCancellation",
            error?.response?.status,
            dispatch,
            error?.response?.data?.detail
        );
        dispatch(setCancelFormLoading(false));
    }
};
