import {
    SET_CONTACTS_INITIAL_LOADING,
    SET_INFORMATION_TEMPLATES_LOADING,
    SET_NON_CAT_CONTEXT,
    SET_NON_CAT_MODE,
    SET_PAGE,
    SET_PRODUCT,
    SET_PRODUCT_RATE,
    SET_REQ_LINE_INDEX,
    SET_SITES_INITIAL_LOADING,
    SET_SMART_FORMS_DATA_LOADING,
    SET_SUPPLIER_CONTACT_DETAILS,
    SET_SUPPLIER_SITES,
} from "./../actionTypes";

// Action creators for non catalogue form slice
export const setNonCatContext = (context) => {
    return { type: SET_NON_CAT_CONTEXT, payload: context };
};

export const setNonCatMode = (mode) => {
    return { type: SET_NON_CAT_MODE, payload: mode };
};

export const setPage = (page) => {
    return { type: SET_PAGE, payload: page };
};

export const setProduct = (product) => {
    return { type: SET_PRODUCT, payload: product };
};

export const setProductRate = (rate) => {
    return { type: SET_PRODUCT_RATE, payload: rate };
};

export const setReqLineIndex = (index) => {
    return { type: SET_REQ_LINE_INDEX, payload: index };
};

export const setSupplierContactDetails = (details) => {
    return { type: SET_SUPPLIER_CONTACT_DETAILS, payload: details };
};

export const setSupplierSites = (sites) => {
    return { type: SET_SUPPLIER_SITES, payload: sites };
};

export const setSitesInitialLoading = (isLoading) => {
    return { type: SET_SITES_INITIAL_LOADING, payload: isLoading };
};

export const setContactsInitialLoading = (isLoading) => {
    return { type: SET_CONTACTS_INITIAL_LOADING, payload: isLoading };
};

export const setSmartFormsDataLoading = (isLoading) => {
    return { type: SET_SMART_FORMS_DATA_LOADING, payload: isLoading };
};

export const setInformationTemplatesLoading = (isLoading) => {
    return { type: SET_INFORMATION_TEMPLATES_LOADING, payload: isLoading };
};
