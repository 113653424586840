import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { setPageNum } from "../../../redux/actions/createRequisitionActions";
import {
    stepIconSX,
    stepLabelSX,
} from "../../../styled/styledSXProperties/styledStepperSX";
import CustomDialogTitle from "../../customDialog/customDialogTitle";
import OnDesktop from "../../wrappers/onDesktop";

const steps = [
    "Header Information",
    "Line Item Information",
    "Review and Submit",
];

const RequisitionDialogTitle = ({ setConfirmOpen }) => {
    // Collect states and state dispatchers
    const darkMode = useSelector((state) => state.general.darkMode);
    const pageNum = useSelector((state) => state.createRequisition.pageNum);
    const validatedPages = useSelector(
        (state) => state.createRequisition.validatedPages
    );
    const isAmendedReq = useSelector(
        (state) => state.createRequisition.amendedReq
    );
    const isPostPoReq = useSelector(
        (state) => state.createRequisition.isPostPoReq
    );
    const dispatch = useDispatch();
    // Modal closing functions
    const onClose = () => {
        setConfirmOpen(true);
    };

    const handleStep = (step) => () => {
        dispatch(setPageNum(step));
    };

    return (
        <CustomDialogTitle
            title={
                isAmendedReq
                    ? `${isPostPoReq ? "Post PO" : "Pre PO"} Amend Requisition`
                    : `Create Requisition`
            }
            content={
                <Stepper
                    activeStep={pageNum}
                    alternativeLabel
                    sx={{ flexGrow: 1, gridArea: "dialog-stepper" }}
                >
                    {steps.map((label, index) => (
                        <Step key={label} completed={validatedPages[index]}>
                            <StepButton
                                color="inherit"
                                onClick={handleStep(index)}
                            >
                                <StepLabel
                                    StepIconProps={{ sx: stepIconSX(darkMode) }}
                                    sx={stepLabelSX}
                                >
                                    <OnDesktop>{label}</OnDesktop>
                                </StepLabel>
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            }
            onClose={onClose}
            tooltip="Close requisition form"
        />
    );
};

RequisitionDialogTitle.propTypes = {
    setConfirmOpen: PropTypes.func,
};

export default RequisitionDialogTitle;
