import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { styledCardSX } from "styled/styledSXProperties/styledCardSX";

/**
 * Material UI card component with bespoke iBuy
 * styling applied. Reusable component used
 * due to easy integration (vs styled component)
 * with box shadow CSS.
 * @component
 */
const IBuyCard = ({ children, additionalSx = null, ...cardProps }) => {
    // Collect redux states and dispatchers
    const darkMode = useSelector((state) => state.general.darkMode);

    const cardSx = additionalSx
        ? { ...styledCardSX(darkMode), ...additionalSx }
        : styledCardSX(darkMode);
    return (
        <Card sx={cardSx} {...cardProps}>
            {children}
        </Card>
    );
};

IBuyCard.propTypes = {
    additionalSx: PropTypes.object,
};

export default IBuyCard;
