import { Table, TableCell, TableRow } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const tableRowSx = {
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    overflowWrap: "anywhere",
};

const DetailRow = ({ header, value }) => {
    const valueDisplay = value
        ? value
        : `No ${header} entered for this requisition`;
    return (
        <TableRow sx={tableRowSx}>
            <TableCell align="left" sx={{ minWidth: 50 }}>
                <Typography color="text.secondary" variant="body1">
                    {header}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography variant="body1">{valueDisplay}</Typography>
            </TableCell>
        </TableRow>
    );
};

DetailRow.propTypes = {
    header: PropTypes.string,
    value: PropTypes.string,
};

const AdditionalDetails = ({ reqLine }) => {
    // const tableHeaderStyle = { color: "text.subheader", fontWeight: "bold" };
    return (
        <Table
            aria-label="simple table"
            size="small"
            data-testid="additionalDetailsTable"
        >
            <TableBody>
                <DetailRow
                    header="Charge Account"
                    value={
                        reqLine?.CHARGE_ACCOUNT_GENERATED
                            ? reqLine?.CONCATENATED_SEGMENTS
                            : "A charge account hasn't been generated for this line yet - check back soon!"
                    }
                />
                <DetailRow
                    header="Project Number"
                    value={reqLine?.PROJECT_NUMBER}
                />
                <DetailRow
                    header="Task Number - Name"
                    value={`${
                        reqLine?.TASK_NUMBER || "No number available"
                    } - ${reqLine?.TASK_NAME || "No task name available"}`}
                />
                <DetailRow
                    header="Expenditure Type"
                    value={reqLine?.EXPENDITURE_TYPE}
                />
                <DetailRow
                    header="Expenditure Item Date"
                    value={reqLine?.EXPENDITURE_ITEM_DATE}
                />
                <DetailRow
                    header="Sourcing Location"
                    value={reqLine?.DESTINATION_ORGANIZATION}
                />
                {reqLine?.RATE_TYPE && (
                    <DetailRow
                        header="Exchange Rate Type"
                        value={reqLine?.RATE_TYPE}
                    />
                )}
                {reqLine?.RATE_DATE && (
                    <DetailRow
                        header="Exchange Rate Date"
                        value={reqLine?.RATE_DATE}
                    />
                )}
                {reqLine?.RATE && (
                    <DetailRow
                        header="Exchange Rate"
                        value={`${reqLine?.RATE}`}
                    />
                )}
            </TableBody>
        </Table>
    );
};

AdditionalDetails.propTypes = {
    reqLine: PropTypes.object,
};

export default AdditionalDetails;
