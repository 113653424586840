import {
    SET_CORRECT_QUANTITY,
    SET_RECEIPT,
    SET_RECEIPT_LINE_CHECK,
    SET_RECEIPT_LINE_RETURN_CHECK,
    SET_RECEIPT_REASON,
    SET_RECEIPT_SUMMARY_ID,
    SET_RECEIPT_SUMMARY_LOADING,
    SET_RECEIPT_SUMMARY_PAGE_NUM,
    SET_RETURN_COMMENT,
    SET_RETURN_QUANTITY,
    SET_RETURN_REASON_ID,
    SET_RETURN_RMA,
} from "../actionTypes";
import { initialState } from "../initialState";

const receiptSummaryReducer = (state = initialState.receiptSummary, action) => {
    switch (action.type) {
        case SET_RECEIPT:
            return {
                ...state,
                receipt: action.payload,
            };
        case SET_RECEIPT_SUMMARY_ID:
            return {
                ...state,
                receiptId: action.payload,
            };
        case SET_RECEIPT_SUMMARY_PAGE_NUM:
            return {
                ...state,
                pageNum: action.payload,
            };
        case SET_RECEIPT_SUMMARY_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case SET_RECEIPT_LINE_CHECK: {
            return {
                ...state,
                receipt: {
                    ...state.receipt,
                    receiptLines: state.receipt.receiptLines.reduce(
                        (acc, line, index) => [
                            ...acc,
                            index == action.payload.index
                                ? { ...line, checked: action.payload.checked }
                                : line,
                        ],
                        []
                    ),
                },
            };
        }
        case SET_CORRECT_QUANTITY: {
            return {
                ...state,
                receipt: {
                    ...state.receipt,
                    receiptLines: state.receipt.receiptLines.reduce(
                        (acc, line, index) => [
                            ...acc,
                            index == action.payload.index
                                ? {
                                      ...line,
                                      updatedQuantity:
                                          action.payload.updatedQuantity,
                                  }
                                : line,
                        ],
                        []
                    ),
                },
            };
        }
        case SET_RECEIPT_REASON: {
            return {
                ...state,
                receipt: {
                    ...state.receipt,
                    receiptLines: state.receipt.receiptLines.reduce(
                        (acc, line, index) => [
                            ...acc,
                            index == action.payload.index
                                ? {
                                      ...line,
                                      reason: action.payload.reason,
                                  }
                                : line,
                        ],
                        []
                    ),
                },
            };
        }
        case SET_RECEIPT_LINE_RETURN_CHECK: {
            return {
                ...state,
                receipt: {
                    ...state.receipt,
                    receiptLines: state.receipt.receiptLines.reduce(
                        (acc, line, index) => [
                            ...acc,
                            index == action.payload.index
                                ? {
                                      ...line,
                                      checkedReturn:
                                          action.payload.checkedReturn,
                                  }
                                : line,
                        ],
                        []
                    ),
                },
            };
        }
        case SET_RETURN_QUANTITY: {
            return {
                ...state,
                receipt: {
                    ...state.receipt,
                    receiptLines: state.receipt.receiptLines.reduce(
                        (acc, line, index) => [
                            ...acc,
                            index == action.payload.index
                                ? {
                                      ...line,
                                      returnQuantity:
                                          action.payload.returnQuantity,
                                  }
                                : line,
                        ],
                        []
                    ),
                },
            };
        }
        case SET_RETURN_COMMENT: {
            return {
                ...state,
                receipt: { ...state.receipt, returnComment: action.payload },
            };
        }
        case SET_RETURN_RMA: {
            return {
                ...state,
                receipt: { ...state.receipt, returnRMA: action.payload },
            };
        }
        case SET_RETURN_REASON_ID: {
            return {
                ...state,
                receipt: { ...state.receipt, returnReasonID: action.payload },
            };
        }
        default:
            return state;
    }
};

export default receiptSummaryReducer;
