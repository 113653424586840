import { useMsal } from "@azure/msal-react";
import LogoutIcon from "@mui/icons-material/Logout";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import GradientDivider from "styled/styledComponents/GradientDivider";

import { setActiveOU } from "../../../redux/actions/authActions";
import { freeTextFormProps } from "../../../styled/props/textFieldProps";
import { getInitialsFromEmail, logOutUser } from "../../../utils/authUtils";

const UserProfileContent = () => {
    const dispatch = useDispatch();

    // Collect states and state dispatchers
    const activeOU = useSelector((state) => state.auth.activeOU);
    const email = useSelector((state) => state.auth.email);
    const role = useSelector((state) => state.auth.role);
    const operatingUnits = useSelector((state) => state.auth.operatingUnits);

    const userName = useSelector((state) => state.auth.userName);
    const { accounts } = useMsal();

    const setOU = (e_, value) => {
        localStorage.setItem("OU", value);
        dispatch(setActiveOU(value));
    };

    return (
        <Box
            display="flex"
            width="100%"
            flexDirection="column"
            alignItems="center"
        >
            <Avatar
                aria-label="userAvatar"
                variant="circle"
                sx={{
                    bgcolor: "text.subheader",
                    height: "56px",
                    width: "56px",
                }}
                // src="/broken-image.jpg"
            >
                {getInitialsFromEmail(email)}
            </Avatar>
            <Box sx={{ marginTop: "8px", marginBottom: "8px", width: "75%" }}>
                <Typography
                    align="center"
                    variant="h5"
                    color="text.primary"
                    mt={2}
                >
                    {userName}
                </Typography>
                <GradientDivider />
                <Typography align="center" variant="h6" color="text.secondary">
                    {email}
                </Typography>
                <Typography align="center" variant="h6" color="text.secondary">
                    {role}
                </Typography>
                <Typography align="center" variant="h6" color="text.secondary">
                    {" "}
                    {operatingUnits ? operatingUnits.join(" ") : ""}
                </Typography>
            </Box>
            {operatingUnits && operatingUnits.length > 0 && (
                <Box my={2} width="75%">
                    <Autocomplete
                        disableClearable
                        options={operatingUnits}
                        size="small"
                        onChange={setOU}
                        value={activeOU}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                {...freeTextFormProps}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label="Operating Unit"
                                helperText="Sets OU for product search"
                            />
                        )}
                    />
                </Box>
            )}
            <GradientDivider />
            <Button
                endIcon={<LogoutIcon />}
                onClick={() => logOutUser(accounts)}
                variant="contained"
            >
                Log Out
            </Button>
        </Box>
    );
};

export default UserProfileContent;
