import {
    setCreateReqFormLoading,
    setIsInternalReq,
} from "redux/actions/createRequisitionActions";
import { GET_INTERNAL_EXTERNAL_ITEM_CHECK } from "services/receipting/urls";
import { closeCreateReqForm } from "utils/createRequisitionUtils";
import { allArrayEntriesEqual } from "utils/general";

import { CART_PREFIX } from "../../constants/routes";
import {
    SERVER_MESSAGE_CART_CHANGED,
    SERVER_MESSAGE_UPDATE_CART,
    SUCCESS_CART_CHANGE,
    SUCCESS_UPDATE_CART,
} from "../../constants/snackbarMessage";
import {
    setCartsLoading,
    setFavouritesId,
    setShowRequisitionDialogue,
    updateActiveCart,
} from "../../redux/actions/generalActions";
import {
    addNewCart,
    createNamedCart,
    deleteCartFromState,
    loadNamedCarts,
    renameCart,
    setCartItems,
} from "../../redux/actions/shoppingCartActions";
import alertDispatcher from "../../utils/alertsUtils";
import { getFavouritesId } from "../../utils/shoppingCartUtils";
import {
    CATALOGUE_ITEM,
    DEFAULT_CART_ID,
    INTERNAL_REQUISITION,
    PURCHASE_REQUISITION,
} from "./../../constants/generalConstants";
import axiosApiInstance from "./../middleware/axios";
import { transformCart } from "./cartTransformers";
import {
    CREATE_NAMED_CART,
    DISABLE_CART,
    GET_CART_BY_USER,
    UPDATE_CART,
} from "./urls";

export const getCarts = async (dispatch) => {
    /* 
    Arguments
    ---------
    dispatch : func
    */
    try {
        dispatch(setCartsLoading(true));
        let request = await axiosApiInstance.get(GET_CART_BY_USER);
        const cartsArray = request.data;
        dispatch(loadNamedCarts(cartsArray));
        dispatch(setFavouritesId(getFavouritesId(cartsArray)));
        dispatch(setCartsLoading(false));
    } catch (error) {
        alertDispatcher("getCarts", error?.response?.status, dispatch);
        dispatch(setCartsLoading(false));
    }
};

export const deleteCart = async (activeCart, dispatch, navigate) => {
    /* 
    Arguments
    ---------
    cart_id : string
    dispatch : func
    navigate : func
    activeCart : string
    */
    try {
        const response = await axiosApiInstance.delete(
            DISABLE_CART.replace("{cart_id}", activeCart)
        );
        dispatch(deleteCartFromState(activeCart));
        dispatch(updateActiveCart(DEFAULT_CART_ID));
        navigate(CART_PREFIX + DEFAULT_CART_ID);
        alertDispatcher(
            "updateCart",
            response?.status,
            dispatch,
            SUCCESS_CART_CHANGE("deleted")
        );
    } catch (error) {
        alertDispatcher(
            "updateCart",
            error?.response?.status,
            dispatch,
            SERVER_MESSAGE_CART_CHANGED("delete")
        );
    }
};

export const patchRenamedCart = async (activeCart, dispatch, inputRename) => {
    /* 
    Arguments
    ---------
    cart_id : string
    update : obj
    dispatch : func
    inputRename : string
    activeCart : string
    */
    try {
        const response = await axiosApiInstance.patch(
            UPDATE_CART.replace("{cart_id}", activeCart),
            {
                name: inputRename,
            }
        );
        dispatch(renameCart(activeCart, inputRename));
        alertDispatcher(
            "updateCart",
            response?.status,
            dispatch,
            SUCCESS_CART_CHANGE("renamed")
        );
    } catch (error) {
        alertDispatcher(
            "updateCart",
            error?.response?.status,
            dispatch,
            SERVER_MESSAGE_CART_CHANGED("rename")
        );
    }
};

export const postNamedCart = async (
    newCart,
    activeCart,
    dispatch,
    navigate,
    inputName
) => {
    /* 
    Arguments
    ---------
    newCart : obj
    activeCart : str
    dispatch :  func
    navigate : func
    inputName : str
    */
    try {
        const request = await axiosApiInstance.post(
            CREATE_NAMED_CART,
            transformCart(newCart)
        );
        const newCartID = request.data.id;
        dispatch(createNamedCart(inputName, newCartID, activeCart));
        dispatch(updateActiveCart(newCartID));
        navigate(CART_PREFIX + newCartID);
        alertDispatcher("namedCart", request?.status, dispatch);
    } catch (error) {
        alertDispatcher("namedCart", error?.response?.status, dispatch);
    }
};

export const postAddToNamedCart = async (newCart, dispatch) => {
    /* 
    Arguments
    ---------
    newCart : obj
    dispatch :  func
    */
    try {
        const request = await axiosApiInstance.post(CREATE_NAMED_CART, newCart);
        const newCartID = request.data.id;
        dispatch(addNewCart(newCartID, newCart));
        dispatch(updateActiveCart(newCartID));
        alertDispatcher("namedCart", request?.status, dispatch);
    } catch (error) {
        alertDispatcher("namedCart", error?.response?.status, dispatch);
    }
};

export const postFavouritesCart = async (favCart, dispatch) => {
    /* 
    Arguments
    ---------
    newCart : obj
    dispatch :  func
    */
    try {
        const request = await axiosApiInstance.post(CREATE_NAMED_CART, favCart);
        alertDispatcher("addToFavourites", request?.status, dispatch);
        const favCartKey = request.data.id;
        dispatch(setFavouritesId(favCartKey));
        dispatch(addNewCart(favCartKey, favCart));
    } catch (error) {
        alertDispatcher("addToFavourites", error?.response?.status, dispatch);
    }
};

export const updateCartItems = async (
    activeCart,
    dispatch,
    newItems,
    add = false,
    favourites = false
) => {
    /* 
    Arguments
    ---------
    activeCart : string
    dispatch :  func
    newItems : array
    add : bool (if this is an add item to cart patch update)
    favourites : bool (if this is an add item to favourites patch update)
    */
    if (activeCart == DEFAULT_CART_ID) {
        // Default cart is not saved ot database
        dispatch(setCartItems(activeCart, newItems));
        add &&
            alertDispatcher(
                "updateCart",
                200,
                dispatch,
                SUCCESS_UPDATE_CART(favourites)
            );
    } else {
        try {
            const request = await axiosApiInstance.patch(
                UPDATE_CART.replace("{cart_id}", activeCart),
                {
                    items: newItems,
                }
            );
            add &&
                alertDispatcher(
                    "updateCart",
                    request?.status,
                    dispatch,
                    SUCCESS_UPDATE_CART(favourites)
                );
            dispatch(setCartItems(activeCart, newItems));
        } catch (error) {
            alertDispatcher(
                "updateCart",
                error?.response?.status,
                dispatch,
                SERVER_MESSAGE_UPDATE_CART(favourites)
            );
        }
    }
};

/**
 * Called when opening the create requisition form to determine if
 * the cart contains internally or externally sourced items. If
 * the cart contains a mixture a warning is raised and the requisition
 * form is not opened.
 *
 * Note - non-catalogue items are always externally sourced
 * and hence are not passed through the internal-external
 * API check.
 *
 * @param {[Object]} cartItems - array of items in cart
 * @param {Function} dispatch - redux dispatch function
 */
export const getCartItemInternalExternalStatus = async (
    cartItems,
    dispatch
) => {
    dispatch(setCreateReqFormLoading(true));
    // Parse cart items into simplified array.
    const cartPoLineIds = cartItems.map((item) => ({
        poLineId: item?.PO_LINE_ID,
        isNonCat: item?.itemType !== CATALOGUE_ITEM,
    }));

    // Extract catalogue cart items to pass to API
    const catalogueCartItemsArray = cartPoLineIds
        .filter((item) => !item?.isNonCat)
        .map((item) => item?.poLineId);

    // Extract non-catalogue cart items
    const nonCatalogueCartItemMapping = cartPoLineIds
        .filter((item) => item?.isNonCat)
        .reduce(
            (acc, item) => ({
                ...acc,
                [item.poLineId]: PURCHASE_REQUISITION,
            }),
            {}
        );
    let poLineIdSourceStatusMapping;
    try {
        if (catalogueCartItemsArray.length > 0) {
            // Query API for all catalogue items and merge
            // with non-catalogue items
            const response = await axiosApiInstance.post(
                GET_INTERNAL_EXTERNAL_ITEM_CHECK,
                { PO_LINE_IDS: catalogueCartItemsArray }
            );

            poLineIdSourceStatusMapping = {
                ...response.data,
                ...nonCatalogueCartItemMapping,
            };
        } else {
            // Do not query API if entire cart is non-catalogue
            poLineIdSourceStatusMapping = {
                ...nonCatalogueCartItemMapping,
            };
        }
        const internalExternalArray = Object.values(
            poLineIdSourceStatusMapping
        );
        const isCartMixed = !allArrayEntriesEqual(internalExternalArray);
        if (isCartMixed) {
            // Do not open form if cart is mixed.
            alertDispatcher("internalExternalCartCheck", 400, dispatch);
            closeCreateReqForm(dispatch);
        } else {
            // Cart is valid - open form
            const isInternal =
                internalExternalArray[0] === INTERNAL_REQUISITION;
            dispatch(setShowRequisitionDialogue(true));
            dispatch(setIsInternalReq(isInternal));
        }
    } catch (error) {
        alertDispatcher("internalExternalCartCheck", 500, dispatch);
        closeCreateReqForm(dispatch);
    } finally {
        dispatch(setCreateReqFormLoading(false));
    }
};
