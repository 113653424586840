import { SET_ACTIVE_OU, SET_AUTH } from "../actionTypes";
import { initialState } from "../initialState";

const authReducer = (state = initialState.auth, action) => {
    switch (action.type) {
        case SET_ACTIVE_OU:
            return {
                ...state,
                activeOU: action.payload,
            };
        case SET_AUTH:
            return action.payload;
        default:
            return state;
    }
};

export default authReducer;
