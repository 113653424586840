// eslint-disable-next-line no-undef
export const BASE_BACKEND_URL = GLOBAL_CONFIG.REACT_APP_APIM_URL;
// eslint-disable-next-line no-undef
export const ENVIRONMENT = GLOBAL_CONFIG.REACT_APP_ENVIRONMENT;

// Base shopping cart URL
export const SHOPPING_CART_URL = `${BASE_BACKEND_URL}/ibuy-${ENVIRONMENT}-shopping-cart`;

export const GET_CART_BY_USER = `${SHOPPING_CART_URL}/shopping-cart`;
export const CREATE_NAMED_CART = `${SHOPPING_CART_URL}/shopping-cart`;
export const DISABLE_CART = `${SHOPPING_CART_URL}/shopping-cart/{cart_id}`;
export const UPDATE_CART = `${SHOPPING_CART_URL}/shopping-cart/{cart_id}`;
