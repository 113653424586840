import { Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import IBuyCard from "components/displays/iBuyCard";

const ProductCardSkeleton = ({ isDesktop }) => {
    return (
        <IBuyCard
            additionalSx={{
                overflow: "visible",
            }}
        >
            <Grid
                container
                spacing={0.5}
                marginTop={isDesktop ? "24px" : "0px"}
                px={2}
                py={1}
                flexDirection={isDesktop ? "column" : "row"}
            >
                <Grid
                    xs={5}
                    md={12}
                    container
                    item
                    justifyContent="center"
                    alignContent="center"
                >
                    <Skeleton
                        variant="rectangular"
                        aria-label="productCardSkeleton"
                        width={isDesktop ? 175 : 330}
                        height={isDesktop ? 170 : 145}
                        sx={{
                            mt: isDesktop && "-40px",
                            borderRadius: "10px",
                            boxSizing: "border-box",
                        }}
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={7}
                    lg={12}
                    alignItems="center"
                    alignContent="flex-end"
                    flexDirection="column"
                    padding={1}
                >
                    <Skeleton
                        variant="text"
                        width="95%"
                        height={isDesktop ? 20 : 65}
                    />
                    <Skeleton variant="text" width="95%" height={20} />
                    <Grid
                        container
                        item
                        justifyContent="space-between"
                        width="95%"
                    >
                        <Skeleton variant="text" width="30%" height={30} />
                        <Skeleton variant="text" width="30%" height={30} />
                    </Grid>
                    <Skeleton
                        variant="rectangular"
                        width="85%"
                        height={30}
                        sx={{
                            mt: "12px",
                            mb: "8px",
                            borderRadius: "10px",
                            boxSizing: "border-box",
                        }}
                    />
                </Grid>
            </Grid>
        </IBuyCard>
    );
};

export default ProductCardSkeleton;
