import { CATALOGUE_ITEM, PROJECT_REQ } from "../constants/generalConstants";

// iBuyRequisition.requisitionLines is an array of iBuyRequisitionLines
export const iBuyRequisitionLineBase = {
    CATEGORY_ID: undefined,
    CURRENCY_CODE: undefined,
    DELIVER_TO_LOCATION_ID: undefined,
    LOCATION_CODE: undefined,
    EXPENDITURE_ITEM_DATE: null,
    EXPENDITURE_ORGANIZATION_ID: undefined,
    EXPENDITURE_ORGANIZATION_NAME: undefined,
    EXPENDITURE_TYPE: undefined,
    HEADER_DESCRIPTION: undefined,
    ITEM_DESCRIPTION: undefined,
    ITEM_ID: undefined,
    JUSTIFICATION: undefined,
    LINE_TYPE: undefined,
    NEED_BY_DATE: null,
    NOTE_TO_APPROVER: undefined,
    NOTE_TO_BUYER: undefined,
    NOTE_TO_RECEIVER: undefined,
    PO_LINE_ID: undefined,
    PROJECT_ACCOUNTING_CONTEXT: undefined,
    QUANTITY: undefined,
    RATE: undefined,
    RATE_DATE: undefined,
    RATE_TYPE: undefined,
    SUGGESTED_BUYER_ID: undefined,
    SUGGESTED_VENDOR_ID: undefined,
    SUGGESTED_VENDOR_CONTACT_ID: undefined,
    SUGGESTED_VENDOR_PHONE: "",
    TASK_ID: undefined,
    UNIT_MEAS_LOOKUP_CODE: undefined,
    UNIT_OF_MEASURE: undefined,
    UNIT_PRICE: undefined,
    ITEM_IMAGE_URL: undefined,
    attachment: [],
    itemType: CATALOGUE_ITEM,

    // Non-catalogue items
    SUPPLIER_NAME: undefined,
    SUPPLIER_NUMBER_NAME: undefined,
    SUPPLIER_ID: undefined,
    SUPPLIER_SITE_CODE: undefined,
    SUPPLIER_SITE_ID: undefined,
    CATEGORY: undefined,
    CATEGORY_DISPLAY_NAME: undefined,
    ITEM_TYPE: undefined,
    CPA_NUMBER: undefined,
    CPA_NUM_NAME: undefined,

    // Charge account
    CHARGE_ACCOUNT_ID: null,
    CONCATENATED_SEGMENTS: null, // 0000.0.0000000.00000000.0000.0.0
    SEGMENT1_ID: null,
    SEGMENT1_NAME: null,
    SEGMENT2_ID: null,
    SEGMENT2_NAME: null,
    SEGMENT3_ID: null,
    SEGMENT3_NAME: null,
    SEGMENT4_ID: null,
    SEGMENT4_NAME: null,
    PROJECT_ID: null,
    IS_VALIDATED: false,

    // Smart forms
    SMART_FORM_ID: undefined,
    INFORMATION_TEMPLATES: [],
};

// Fills the iBuyRequisition.iBuyRequisitionHeaderForm property
export const iBuyRequisitionHeaderForm = {
    ACCOUNTED_AGAINST: PROJECT_REQ,
    DELIVER_TO_LOCATION_ID: undefined,
    LOCATION_CODE: undefined,
    EXPENDITURE_ORGANIZATION_ID: undefined,
    EXPENDITURE_ORGANIZATION_NAME: undefined,
    EXPENDITURE_TYPE: undefined,
    EXPENDITURE_ITEM_DATE: null,
    HEADER_DESCRIPTION: undefined,
    JUSTIFICATION: undefined,
    NOTE_TO_APPROVER: undefined,
    NEED_BY_DATE: null,
    NOTE_TO_BUYER: undefined,
    NOTE_TO_RECEIVER: undefined,
    ORG_NAME: undefined,
    SUGGESTED_VENDOR_PHONE: undefined,
    TASK_ID: undefined,

    // Charge account
    CHARGE_ACCOUNT_ID: null, // 000000
    CONCATENATED_SEGMENTS: null, // 0000.0.0000000.00000000.0000.0.0
    SEGMENT1_ID: null, // 0000
    SEGMENT1_NAME: null, // 0000 - Description
    SEGMENT2_ID: null, // 0
    SEGMENT2_NAME: null, // 0 - Description
    SEGMENT3_ID: null, // 0000000
    SEGMENT3_NAME: null, // 0000000 - Description
    SEGMENT4_ID: null, // 00000000
    SEGMENT4_NAME: null, // 00000000 - Description
    PROJECT_ID: null, // 0000000
    IS_VALIDATED: false,
};

// Schema which iBuy passes to cosmos DB
export const iBuyRequisitionBase = {
    requisitionLines: [],
    requisitionHeaderForm: iBuyRequisitionHeaderForm,
    status: [{}, {}],
    approvalsRef: [],
    orgId: undefined,
    lastUpdatedBy: undefined,
    cartName: undefined,
    supplierName: undefined,
};
