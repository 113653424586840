// eslint-disable-next-line no-undef
export const BASE_BACKEND_URL = GLOBAL_CONFIG.REACT_APP_APIM_URL;
// eslint-disable-next-line no-undef
export const ENVIRONMENT = GLOBAL_CONFIG.REACT_APP_ENVIRONMENT;

// Base supplier URL
export const SUPPLIER_API_URL = `${BASE_BACKEND_URL}/ibuy-${ENVIRONMENT}-Supplier/supplier`;
// export const SUPPLIER_API_URL = `http://localhost:9019/api`;

export const SUPPLIER_NAME_AUTOCOMPLETE = `${SUPPLIER_API_URL}/autocomplete`;
export const SUPPLIER_SITE_DETAILS = `${SUPPLIER_API_URL}/id`;
export const SUPPLIER_SITE_CONTACTS = `${SUPPLIER_API_URL}/site`;

export const SUPPLIER_NAME_AUTOCOMPLETE_GET = (supplier_name, OU) => {
    return `${SUPPLIER_NAME_AUTOCOMPLETE}/${supplier_name}?operating_unit=${OU}`;
};

export const SUPPLIER_NAME_AUTOCOMPLETE_GET_ASL = (
    supplier_name,
    OU,
    categoryId
) => {
    return `${SUPPLIER_NAME_AUTOCOMPLETE_GET(
        supplier_name,
        OU
    )}&category_id=${categoryId}`;
};

export const SUPPLIER_SITE_DETAILS_GET = (id) => {
    return `${SUPPLIER_SITE_DETAILS}/${id}`;
};

export const SUPPLIER_SITE_CONTACTS_GET = (supplier_site_id) => {
    return `${SUPPLIER_SITE_CONTACTS}/${supplier_site_id}`;
};
