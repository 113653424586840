// Returns action creators for each create requisition action type
import {
    CLEAR_POST_PO_METADATA,
    DELETE_REQ_LINE,
    DUPLICATE_LINE,
    SET_ALL_LINES,
    SET_AMENDED_REQ,
    SET_COPY_REQ,
    SET_CREATE_REQ_FORM_LOADING,
    SET_HEADER_FIELD,
    SET_HEDGING_CHECK_PASSED,
    SET_HR_ORGS_LOADING,
    SET_IS_INTERNAL_REQ,
    SET_LINE,
    SET_PAGE_NUM,
    SET_POST_PO_REQ,
    SET_REQUISITION,
    SET_SUBMIT_LOADING,
    SET_SUPPLIER_NAME,
    SET_SUPPLIER_SITE,
    SET_VALIDATED,
    SET_WHOLE_LINE,
    TOGGLE_REQ_LIST_API_REFRESH,
    UPDATE_POST_PO_METADATA,
} from "../actionTypes";

export const clearPostPOMetadata = () => {
    return { type: CLEAR_POST_PO_METADATA };
};

// Action creators for create requisition slice
export const duplicateLine = (index, isAmendedReq) => {
    return {
        type: DUPLICATE_LINE,
        payload: { index: index, isAmendedReq: isAmendedReq },
    };
};

export const setAllLines = (field, value) => {
    return { type: SET_ALL_LINES, payload: { field, value } };
};

export const setCopyReq = (copyReq) => {
    return { type: SET_COPY_REQ, payload: copyReq };
};

export const setAmendedReq = (amendedReq) => {
    return { type: SET_AMENDED_REQ, payload: amendedReq };
};

export const setPostPOReq = (isPostPoReq) => {
    return { type: SET_POST_PO_REQ, payload: isPostPoReq };
};

export const setHeaderField = (field, value) => {
    return { type: SET_HEADER_FIELD, payload: { field, value } };
};

export const setLine = (index, field, value) => {
    return { type: SET_LINE, payload: { index, field, value } };
};

export const setPageNum = (pageNum) => {
    return { type: SET_PAGE_NUM, payload: pageNum };
};

export const setRequisition = (iBuyRequisition) => {
    return { type: SET_REQUISITION, payload: iBuyRequisition };
};

export const setSubmitLoading = (loading) => {
    return { type: SET_SUBMIT_LOADING, payload: loading };
};

export const setValidated = (validated) => {
    return { type: SET_VALIDATED, payload: validated };
};

export const setIsInternalReq = (isInternalReq) => {
    return { type: SET_IS_INTERNAL_REQ, payload: isInternalReq };
};

export const deleteRequisitionLine = (index) => {
    return { type: DELETE_REQ_LINE, payload: index };
};

export const setWholeLine = (index, newLine) => {
    return { type: SET_WHOLE_LINE, payload: { index, newLine } };
};

export const setSupplierName = (name) => {
    return { type: SET_SUPPLIER_NAME, payload: name };
};

export const setSupplierSite = (name) => {
    return { type: SET_SUPPLIER_SITE, payload: name };
};

export const setHrOrgsLoading = (isLoading) => {
    return { type: SET_HR_ORGS_LOADING, payload: isLoading };
};

export const toggleReqListAPIRefresh = () => {
    return { type: TOGGLE_REQ_LIST_API_REFRESH };
};

export const updatePostPOMetadata = (key, value) => {
    return { type: UPDATE_POST_PO_METADATA, payload: { key, value } };
};

export const setCreateReqFormLoading = (isLoading) => {
    return { type: SET_CREATE_REQ_FORM_LOADING, payload: isLoading };
};

export const setHedgingCheckPassed = (hasPassed) => {
    return { type: SET_HEDGING_CHECK_PASSED, payload: hasPassed };
};
