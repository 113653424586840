// Action Types
import {
    SET_ALL_CANCEL_CHECKED,
    SET_CANCEL_FORM_LOADING,
    SET_CANCEL_HEADER_REASON,
    SET_CANCEL_REQ_HEADER_INFO,
    SET_CANCEL_REQ_ID,
    SET_CANCEL_REQ_LINES,
    SET_REQ_LINE_CANCEL_REASON,
    SET_REQ_LINE_CHECKED,
} from "../actionTypes";
import { initialState } from "../initialState";

const cancelRequisitionReducer = (
    state = initialState.cancelRequisition,
    action
) => {
    switch (action.type) {
        case SET_CANCEL_FORM_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case SET_CANCEL_REQ_HEADER_INFO:
            return {
                ...state,
                requisitionHeaderInfo: action.payload,
            };
        case SET_CANCEL_REQ_LINES:
            return {
                ...state,
                requisitionLines: action.payload,
            };
        case SET_CANCEL_REQ_ID:
            return {
                ...state,
                iBuyReqNumber: action.payload,
            };
        case SET_CANCEL_HEADER_REASON:
            return {
                ...state,
                headerReason: action.payload,
                requisitionLines: state.requisitionLines.reduce(
                    (acc, line) => [
                        ...acc,
                        {
                            ...line,
                            REQUISITION_CANCEL_REASON: action.payload,
                        },
                    ],
                    []
                ),
            };
        case SET_ALL_CANCEL_CHECKED:
            return {
                ...state,
                requisitionLines: state.requisitionLines.reduce(
                    (acc, line) => [
                        ...acc,
                        {
                            ...line,
                            checked: action.payload,
                        },
                    ],
                    []
                ),
            };
        case SET_REQ_LINE_CHECKED:
            return {
                ...state,
                requisitionLines: state.requisitionLines.reduce(
                    (acc, line) => [
                        ...acc,
                        line?.LINE_ATTRIBUTE5 == action.payload.reqLineId
                            ? { ...line, checked: action.payload.isChecked }
                            : line,
                    ],
                    []
                ),
            };
        case SET_REQ_LINE_CANCEL_REASON:
            return {
                ...state,
                requisitionLines: state.requisitionLines.reduce(
                    (acc, line) => [
                        ...acc,
                        line?.LINE_ATTRIBUTE5 == action.payload.reqLineId
                            ? {
                                  ...line,
                                  REQUISITION_CANCEL_REASON:
                                      action.payload.reason,
                              }
                            : line,
                    ],
                    []
                ),
            };
        default:
            return state;
    }
};

export default cancelRequisitionReducer;
