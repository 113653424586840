import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { cancelReasonRegisterName } from "utils/cancelRequisitionUtils";

import { SERVICE } from "../../../constants/generalConstants";
import {
    setAllCancelChecked,
    setCancelHeaderReason,
} from "../../../redux/actions/cancelRequisitionActions";
import { freeTextFormProps } from "../../../styled/props/textFieldProps";
import CancelRequisitionLine from "./cancelRequisitionLine";

/**
 * Child of `CancelRequisitionForm` enabling users to select lines
 * for cancellation
 *
 * @component
 */

const CancelRequisitionLinesSelection = ({ errors, register, setValue }) => {
    // Collect states and state dispatchers
    const headerReason = useSelector(
        (state) => state.cancelRequisition.headerReason
    );
    const requisitionLines = useSelector(
        (state) => state.cancelRequisition.requisitionLines || []
    );
    const dispatch = useDispatch();
    /**
     * Writes global cancel header state to redux and
     * to all form validators (via the `setValue`) function.
     * The latter is necessary to ensure the form validator
     * can be correctly manipulated programmatically
     * @param   {event} event MUI Textfield input event
     */
    const updateHeaderReason = (event) => {
        const reason = event.target.value;
        dispatch(setCancelHeaderReason(reason));
        requisitionLines.map((_line, index) =>
            setValue(cancelReasonRegisterName(index), reason)
        );
    };

    /**
     * Sets checked status of all requisition lines
     * @param {Object} event - material UI on check event metadata
     */
    const checkAll = (event) =>
        dispatch(dispatch(setAllCancelChecked(event.target.checked)));

    return (
        <>
            <Grid item xs={12} order={{ xs: 4, md: 4 }}>
                <Typography variant="h5" color="text.subheader">
                    {`Select the requisition line${
                        requisitionLines.length > 1 ? "s" : ""
                    } you wish to cancel`}
                </Typography>
            </Grid>
            <Grid item xs={12} md={7} order={{ xs: 4, md: 4 }}>
                <TextField
                    {...freeTextFormProps}
                    multiline
                    label="Cancellation Reason"
                    placeholder="Type here to apply a cancellation reason for all lines..."
                    onChange={updateHeaderReason}
                    value={headerReason}
                />
            </Grid>
            <Grid item xs={12} md={2} order={{ xs: 5, md: 5 }}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch onChange={checkAll} color="secondary" />
                        }
                        label="Check all Lines"
                    />
                </FormGroup>
            </Grid>
            <Grid item xs={12} container spacing={2} order={{ xs: 6, md: 6 }}>
                {requisitionLines.length > 0 ? (
                    requisitionLines.map((line, index) => {
                        const { checked, LINE_TYPE, ...reqLine } = line;
                        return (
                            <Grid item xs={12} key={index}>
                                <CancelRequisitionLine
                                    hasPo={!!reqLine?.poInfo?.hasPo || false}
                                    reqLine={reqLine}
                                    checked={checked}
                                    errors={errors}
                                    register={register}
                                    index={index}
                                    byAmount={
                                        LINE_TYPE == SERVICE ? true : false
                                    }
                                />
                            </Grid>
                        );
                    })
                ) : (
                    <Typography variant="h6">
                        No requisition lines available to cancel!
                    </Typography>
                )}
            </Grid>
        </>
    );
};

CancelRequisitionLinesSelection.propTypes = {
    errors: PropTypes.object,
    register: PropTypes.func,
    setValue: PropTypes.func,
};

export default CancelRequisitionLinesSelection;
