import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DetailListVertical from "components/displays/detailListVertical";
import React from "react";
import { useSelector } from "react-redux";

import ReceiptTransactionsLines from "./receiptTransactionsLines/receiptTransactionsLines";

const ReceiptSummaryDetail = () => {
    const receipt = useSelector((state) => state.receiptSummary.receipt);
    const receiptLines = receipt?.receiptLines ? receipt?.receiptLines : [];
    const comments = receiptLines.length > 0 ? receiptLines[0]?.COMMENTS : null;
    return (
        <Grid container spacing={3} marginBottom="16px">
            <Grid
                container
                item
                xs={12}
                md={4}
                display="flex"
                alignContent="flex-start"
                alignItems="start"
            >
                <Grid item xs={12}>
                    <Typography variant="h5" color="text.subheader">
                        Receipt Details
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <DetailListVertical
                        condensed={true}
                        details={[
                            {
                                header: "iBuy Receipt Number",
                                content: receipt?.IBUY_RECEIPT_ID,
                            },
                            {
                                header: "Unique Reference Number",
                                content: receipt?.PACKING_SLIP,
                            },
                            {
                                header: "Supplier Name",
                                content: receipt?.SUPPLIER_NAME,
                            },
                            {
                                header: "Supplier Site Code",
                                content: receipt?.SUPPLIER_SITE_CODE,
                            },
                            {
                                header: "Receipt Comments",
                                content: comments,
                            },
                        ]}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} md={8}>
                <Grid item xs={12}>
                    <ReceiptTransactionsLines />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ReceiptSummaryDetail;
