import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

import { autoCompleteProps } from "../../styled/props/autocompleteProps";
import { freeTextFormProps } from "../../styled/props/textFieldProps";

const NO_OPTIONS = "No Options";

const ValidatedAutocomplete = ({
    label,
    placeholder,
    onChange,
    options,
    value,
    disabled = false,
    IDName = null,
    noOptionsText = null,
    helperText = null,
    required = false,
}) => {
    /*
    This component has two configurations, toggled by `IDName`. If
    `displayValue` is not defined, it expects a flat array of options.
    On selection, an entry from that array is returned. If `IDName` is
    defined, it expects the option prop to be an array of name-id pairs.
    Id is stored in backend state, name is displayed to the user.  
    `IDName` is an object: {id, name}
    */
    const setValueIDNameMode = () => {
        return options.find((option) => option[IDName.id] == value);
    };
    return (
        <Autocomplete
            {...autoCompleteProps}
            disabled={disabled}
            onChange={onChange}
            getOptionLabel={(option) => (IDName ? option[IDName.name] : option)} // Value set conditionally based on configuration
            options={options}
            noOptionsText={noOptionsText ? noOptionsText : NO_OPTIONS}
            value={
                value
                    ? IDName
                        ? setValueIDNameMode()
                        : value.toString()
                    : null
            } // Value set conditionally based on configuration
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...freeTextFormProps}
                    label={label}
                    placeholder={placeholder}
                    helperText={helperText}
                    required={required}
                />
            )}
        />
    );
};

ValidatedAutocomplete.defaultProps = {
    disabled: false,
    IDName: null,
    noOptionsText: null,
    helperText: null,
    required: false,
};

ValidatedAutocomplete.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    required: PropTypes.bool,
};

export default ValidatedAutocomplete;
