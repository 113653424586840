import {
    FormControlLabel,
    Radio,
    RadioGroup,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CustomDrawer from "components/displays/customDrawer";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import GradientDivider from "styled/styledComponents/GradientDivider";

import {
    DEFAULT_ORDER_KEY,
    DEFAULT_ORDER_VALUE,
} from "../../constants/generalConstants";
import { freeTextFormProps } from "../../styled/props/textFieldProps";
import styledDialogueSX from "../../styled/styledSXProperties/styledDialogueSX";
import SwipeToClose from "../displays/swipeToClose";
import FilterPanelAutoComplete from "../productList/filterPanelAutocomplete";

export const sortQueryDispatcher = {
    "Sort By: Date Raised - Earliest to Latest": "submittedDate",
    [DEFAULT_ORDER_KEY]: DEFAULT_ORDER_VALUE,
    "Sort By: Price - low to high": "totalPrice",
    "Sort By: Price - high to low": "totalPrice DESC",
};

const ApprovalsFilterPanel = ({
    isOpen,
    closeSidebarCallback,
    setPage,
    getReqs,
    isDesktop,
}) => {
    const [showCompleted, setShowCompleted] = useState(1);
    const [sortOption, setSortOption] = useState("");
    const filterLookups = useSelector((state) => state.filterPanelLookups);
    const [showOnlyDelegated, setShowOnlyDelegated] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    // If valid sort selection entered, update url and re-run search
    const selectSortOption = (event) => {
        if (event.target.value) {
            event.target.value && setSortOption(event.target.value);
            searchParams.set(
                "order_by",
                sortQueryDispatcher[event.target.value]
            );
            setSearchParams(searchParams);
            setPage(1); // Reset page counter
        }
    };

    // Preset sort dropdown list based on URL params
    useEffect(() => {
        // Read URL and set state
        if (showOnlyDelegated) {
            searchParams.set("delegatedFlag", "IS_DELEGATED");
        } else {
            searchParams.delete("delegatedFlag");
        }
        readAndSetSortParams();
        // Call get reqs list API
        getReqs();
        setPage(1);
    }, [searchParams, showOnlyDelegated]);

    const getSortDisplayValue = (param) =>
        Object.keys(sortQueryDispatcher).find(
            (key) => sortQueryDispatcher[key] === param
        );
    const readAndSetSortParams = () => {
        !searchParams.has("order_by") &&
            searchParams.set("order_by", DEFAULT_ORDER_VALUE);
        setSortOption(getSortDisplayValue(searchParams.get("order_by")));
    };

    const selectSupplier = (event, value) => {
        searchParams.set("supplierName", value.join(","));
        setSearchParams(searchParams);
        setPage(1); // Reset page counter
    };

    const selectRequisitionId = (event) => {
        searchParams.set("requisitionId", event.target.value);
        setSearchParams(searchParams);
        setPage(1); // Reset page counter
    };

    const selectCompleted = (event) => {
        event.target.value && setShowCompleted(event.target.value);
        searchParams.set("active", event.target.value);
        setSearchParams(searchParams);
        setPage(1); // Reset page counter
    };

    return (
        <CustomDrawer
            anchor={"right"}
            transitionDuration={200}
            open={isOpen}
            onOpen={() => {}}
            onClose={() => closeSidebarCallback(false)}
        >
            <Box
                display="flex"
                width={isDesktop ? "400px" : "100vw"}
                height="100%"
                flexDirection={"column"}
                justifyContent="space-between"
                flexWrap="wrap"
                justifyItems="flex-start"
                sx={styledDialogueSX}
            >
                <Box>
                    <Typography
                        padding={2}
                        paddingY={3}
                        variant="h4"
                        align="left"
                        fontWeight="bold"
                        color="text.subheader"
                    >
                        Approval Filters and Sorts
                    </Typography>
                    <Typography
                        padding={2}
                        paddingBottom={0}
                        variant="h6"
                        align="left"
                        fontWeight="bold"
                    >
                        Filter
                    </Typography>
                    <RadioGroup
                        value={showCompleted}
                        onChange={selectCompleted}
                        row
                        sx={{
                            ".Mui-checked": {
                                color: "#0097A9",
                            },
                            "&$checked": {
                                color: "#0097A9",
                            },
                            px: 3,
                            py: 2,
                        }}
                    >
                        <FormControlLabel
                            control={<Radio color="secondary" />}
                            label="Active Approvals"
                            value={1}
                        />
                        <FormControlLabel
                            control={<Radio color="secondary" />}
                            label="Completed Approvals"
                            value={0}
                        />
                    </RadioGroup>
                    <Box padding={2}>
                        <TextField
                            label="Requisition ID"
                            onChange={selectRequisitionId}
                            {...freeTextFormProps}
                            variant="outlined"
                            placeholder="Requisition ID"
                        />
                    </Box>
                    <Box padding={2}>
                        <FilterPanelAutoComplete
                            options={filterLookups.SUPPLIER_NAME}
                            onChange={selectSupplier}
                            checkBoxStyle={{
                                marginRight: "8px",
                                padding: "4px 0px",
                            }}
                            placeholder="Supplier"
                        />
                    </Box>
                    <FormControlLabel
                        control={
                            <Switch
                                label="Show only delegated approvals"
                                checked={showOnlyDelegated}
                                color="secondary"
                            />
                        }
                        label={`${
                            showOnlyDelegated
                                ? "Show All Approvals"
                                : "Show Delegated Only"
                        }`}
                        sx={{ px: 3, py: 1 }}
                        onChange={(e) => {
                            setShowOnlyDelegated(e.target.checked);
                        }}
                    />
                    <GradientDivider />
                    <Typography
                        variant="h6"
                        align="left"
                        fontWeight="bold"
                        color="text.primary"
                        marginTop="10px"
                        padding={2}
                        paddingBottom={0}
                    >
                        Sort
                    </Typography>
                    <Box padding={2}>
                        <Select
                            onChange={selectSortOption}
                            label="Select sort by option...."
                            labelId="sort-options"
                            value={sortOption}
                            variant="outlined"
                            size="small"
                        >
                            <MenuItem disabled value="">
                                <em>Search Pending Requisitions</em>
                            </MenuItem>
                            {Object.keys(sortQueryDispatcher).map(
                                (sortOption, index) => {
                                    return (
                                        <MenuItem
                                            key={index}
                                            value={sortOption}
                                        >
                                            {sortOption}
                                        </MenuItem>
                                    );
                                }
                            )}
                        </Select>
                    </Box>
                    <GradientDivider />
                </Box>
                <Box />
                {!isDesktop && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <SwipeToClose direction={"right"} />
                    </Box>
                )}
            </Box>
        </CustomDrawer>
    );
};

export default ApprovalsFilterPanel;
