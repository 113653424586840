import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useCallback, useEffect, useState } from "react";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";

import { ATTACHMENT_CONSTANTS } from "../../constants/generalConstants";
import axiosApiInstance from "../../services/middleware/axios";
import { postAttachments } from "../../services/requisition/requisitionAPIs";
import { GET_ATTACHMENT } from "../../services/requisition/urls";
import alertDispatcher from "../../utils/alertsUtils";

var Buffer = require("buffer/").Buffer; // note: the trailing slash is important!

function AttachmentsUpload({
    initialAttachments,
    setStateCallback,
    requisitionId,
    disableUpload,
}) {
    const dispatch = useDispatch();
    if (!initialAttachments) {
        initialAttachments = [];
    }

    const [attachments, setAttachments] = useState(
        initialAttachments.filter((a) => a?.DATA_TYPE === "File")
    );

    useEffect(() => {
        if (setStateCallback) {
            setStateCallback(
                attachments.map((attachment) => {
                    return {
                        LEVEL_TYPE: "LINE",
                        DATA_TYPE: "File",
                        CATEGORY: "Miscellaneous",
                        DESCRIPTION:
                            attachment?.DESCRIPTION &&
                            attachment.DESCRIPTION.replaceAll(
                                "|",
                                ""
                            ).substring(0, 25),
                        FILENAME: attachment?.FILENAME,
                        ATTACHMENT_TEXT: null,
                    };
                })
            );
        }
    }, [attachments]);

    function attachmentValidator(file) {
        if (!file.name) {
            return null;
        }

        if (file.name.length > ATTACHMENT_CONSTANTS.maxLength) {
            return {
                code: "name-too-long",
                message: `Name is larger than ${ATTACHMENT_CONSTANTS.maxLength} characters`,
            };
        }

        if (file.size > ATTACHMENT_CONSTANTS.maxSize) {
            return {
                code: "file-too-large",
                message: `File size cannot be greater than ${
                    ATTACHMENT_CONSTANTS.maxSize / 1024 / 1000
                }MB`,
            };
        }

        if (file.size < ATTACHMENT_CONSTANTS.minSize) {
            return {
                code: "file-too-small",
                message: `File size must be greater than ${
                    ATTACHMENT_CONSTANTS.minSize / 1024
                }KB for security purposes`,
            };
        }

        if (!file.name.match(ATTACHMENT_CONSTANTS.filenameRegex)) {
            return {
                code: "incorrect-filename",
                message:
                    "Name of file must adhere to 'name.extension' format, and can contain numbers, letters, dashes and underscore. ",
            };
        }

        return null;
    }

    const downloadAttachment = (file) => {
        axiosApiInstance
            .get(GET_ATTACHMENT(file.FILENAME, requisitionId), {
                responseType: "arraybuffer",
            })
            .then((response) => {
                let base64Data = Buffer.from(response.data, "binary").toString(
                    "base64"
                );
                let contentType = response.headers["content-type"];
                var a = document.createElement("a"); //Create <a> - dummy button
                a.href = `data:${contentType};base64,${base64Data}`; //Image Base64 Goes here
                a.download = file.FILENAME;
                a.click(); //Downloaded file
            })
            .catch((e) => {
                alertDispatcher(
                    "downloadAttachment",
                    e?.response?.status,
                    dispatch
                );
            });
    };

    const onDrop = useCallback((acceptedFiles) => {
        if (attachments.length < ATTACHMENT_CONSTANTS.maxAttachments) {
            const index = acceptedFiles.length - 1;
            let newAttachment = Object.assign(acceptedFiles[index], {
                preview: URL.createObjectURL(acceptedFiles[index]),
            });
            let formData = new FormData();
            formData.append("file", acceptedFiles[index]);

            postAttachments(formData).then((attachmentUrl) => {
                if (attachmentUrl) {
                    newAttachment.FILENAME = attachmentUrl[0];
                    newAttachment.DESCRIPTION = newAttachment.name;
                    setAttachments([...attachments, newAttachment]);
                }
            });
        } else {
            alertDispatcher("uploadAttachment", 400, dispatch);
        }
    });

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        accept: {
            "image/png": [".png", ".jpeg", ".jpg"],
            "application/pdf": [".pdf"],
        },
        validator: attachmentValidator,
        onDrop,
    });

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <>
            {errors.map((e) => (
                <Typography color="error" variant="body1" key={e.code}>
                    {file.path}: {e.message}
                </Typography>
            ))}
        </>
    ));

    return (
        /*  requested to hide attachments component */
        <Grid sx={{ display: "none" }}>
            {!disableUpload && (
                <Grid item xs={12}>
                    <Box
                        {...getRootProps({ className: "dropzone" })}
                        bgcolor="#FFFFFF40"
                        border={2}
                        borderColor="#4CB6C2"
                        boxSizing="border-box"
                        padding="16px"
                        borderRadius="10px"
                        sx={{ borderStyle: "dashed" }}
                    >
                        <input {...getInputProps()} />
                        <Typography variant="body1" align="center">
                            Drag and drop max 1 attachment here, or click to
                            open your file explorer (permitted types: PNG, JPEG
                            and PDF)
                        </Typography>
                    </Box>
                    {fileRejectionItems}
                </Grid>
            )}
            <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <Typography variant="body1">
                    {attachments.length > 0
                        ? "Uploaded files:"
                        : "No attachment added"}
                </Typography>
                <ul>
                    {attachments.map((file, index) => (
                        <li key={index}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography variant="body1">
                                        {file.DESCRIPTION}{" "}
                                        {file.size
                                            ? ` - ${(file.size / 1024).toFixed(
                                                  1
                                              )} KB`
                                            : ""}
                                    </Typography>
                                </Grid>

                                {requisitionId && (
                                    // Can only download a req with an ID
                                    <Grid item xs="auto">
                                        <DownloadForOfflineIcon
                                            onClick={() => {
                                                downloadAttachment(file);
                                            }}
                                            sx={{ cursor: "pointer" }}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </li>
                    ))}
                </ul>
            </Grid>
        </Grid>
    );
}

export default AttachmentsUpload;
