import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { ThemeProvider } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import App from "./App";
import { tokenRequest } from "./authConfig";
import { msalInstance } from "./authProvider";
import UnauthenticatedScreen from "./components/unauthenticatedScreen/unauthenticatedScreen";
import { darkTheme } from "./themes/darkTheme";
import { lightTheme } from "./themes/lightTheme";

/**
 * Provides MSAL and theme wrappers for `App`
 * @component
 */

const LoadingComponent = () => <UnauthenticatedScreen loading />;
const ErrorComponent = ({ error }) => {
    console.error(error);
    return <UnauthenticatedScreen loading={false} />;
};

const AppWrappers = () => {
    const selectedTheme = useSelector((state) =>
        state.general.darkMode ? darkTheme : lightTheme
    );
    return (
        <ThemeProvider theme={selectedTheme}>
            <MsalProvider instance={msalInstance}>
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={tokenRequest}
                    loadingComponent={LoadingComponent}
                    errorComponent={ErrorComponent}
                >
                    <React.StrictMode>
                        <App />
                    </React.StrictMode>
                </MsalAuthenticationTemplate>
            </MsalProvider>
        </ThemeProvider>
    );
};

export default AppWrappers;
