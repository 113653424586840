import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

import { nonCatFormGridColumnProps } from "../modals/nonCatalogueForm/nonCatalogueForm";

const NonCatalogueFormSkeleton = () => {
    return (
        <>
            {[...Array(2).keys()].map((v) => {
                return (
                    <Grid {...nonCatFormGridColumnProps} key={v}>
                        <Grid item xs={12}>
                            <Skeleton
                                aria-label="title"
                                height={25}
                                width="25%"
                                sx={{
                                    borderRadius: "10px",
                                    boxSizing: "border-box",
                                }}
                            />
                        </Grid>
                        {[...Array(6).keys()].map((v) => {
                            return (
                                <Grid item xs={12} key={v}>
                                    <Skeleton
                                        aria-label="label"
                                        height={20}
                                        width="200px"
                                        sx={{
                                            borderRadius: "10px",
                                            boxSizing: "border-box",
                                        }}
                                    />
                                    <Skeleton
                                        aria-label="inputBox"
                                        height={50}
                                        sx={{
                                            borderRadius: "10px",
                                            boxSizing: "border-box",
                                        }}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                );
            })}
        </>
    );
};

export default NonCatalogueFormSkeleton;
