import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Chip, Skeleton, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import PurchaseOrderChip from "components/buttons/purchaseOrderChip";
import OutlinedChip from "components/displays/outlinedChip";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
    CANCELLED,
    INTERNAL_REQUISITION,
    INVALID_REQUISITION,
} from "../../../../constants/generalConstants";
import { INVOICE_SUMMARY_PAGE } from "../../../../constants/routes";
import {
    TOOLTIP_ACTIONS,
    TOOLTIP_DATE_CREATED,
    TOOLTIP_HEADER_DESCRIPTION,
    TOOLTIP_IBUY_REQUISITION_ID,
    TOOLTIP_INTERNAL_REQ_PO_COLUMN_REQ_LIST_PAGE,
    TOOLTIP_OU,
    TOOLTIP_PO_NUMBER,
    TOOLTIP_PREPARER,
    TOOLTIP_STATUS,
    TOOLTIP_SUPPLIER,
    TOOLTIP_TOTAL_AMOUNT,
} from "../../../../constants/tooltipText";
import { getInvoicesPOsReceiptsPerReqLine } from "../../../../services/procurement/procurementAPIs";
import { tableHeaderProps } from "../../../../styled/props/tableProps";
import { isReqLineCancelled } from "../../../../utils/cancelRequisitionUtils";
import { dateFormat } from "../../../../utils/general";
import CopyTypography from "../../../displays/copyTypography";
import CurrencyFigure from "../../../displays/currencyFigure";
import RequisitionStatusBadge from "../../../displays/status/requisitionStatusBadge";
import HeaderPOValue from "./headerPOValue";
import RequisitionButtonCollection from "./requisitionButtonCollection";

const subTableHeaderProps = {
    align: "center",
    color: "text.secondary",
    variant: "body1",
    fontWeight: "bold",
};

const invalidMessage =
    "Some or all requisition lines invalid. Open requisition summary page for details";

const RequisitionLineRow = ({
    line,
    reqId,
    showStatusColumn,
    reqCurrencyEqOuBase,
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [invoiceIds, setInvoiceIds] = useState([]);
    const [receiptByReqLine, setReceiptByReqLine] = useState({});
    const [poIds, setPoIds] = useState([]);
    const reqLineId = line?.LINE_ATTRIBUTE5;

    useEffect(() => {
        getInvoicesPOsReceiptsPerReqLine(
            reqLineId,
            reqId,
            setInvoiceIds,
            setIsLoading,
            setPoIds,
            setReceiptByReqLine
        );
    }, []);

    const isCancelled = isReqLineCancelled(line);
    const opacity = isCancelled ? 0.5 : 1;

    const numSkeletons = showStatusColumn ? 9 : 8;

    const StatusColumnValue = () => {
        return isCancelled ? (
            <RequisitionStatusBadge status={CANCELLED} sx={{ ml: "12px" }} />
        ) : (
            " - "
        );
    };

    return (
        <>
            {!isLoading ? (
                <TableRow hover sx={{ opacity }}>
                    <TableCell align="center" component="th" scope="row">
                        {line?.ITEM_DESCRIPTION}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                        <CurrencyFigure
                            value={
                                parseFloat(line?.QUANTITY) *
                                parseFloat(line?.unitPriceBaseCurrency)
                            }
                            code={line?.ouBaseCurrency}
                        />
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                        <CurrencyFigure
                            value={
                                receiptByReqLine[reqLineId]
                                    ?.amountReceivedBaseCurrency || "0"
                            }
                            code={receiptByReqLine[reqLineId]?.ouBaseCurrency}
                        />
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                        <CurrencyFigure
                            value={
                                invoiceIds.length > 0
                                    ? invoiceIds.reduce(
                                          (invoiceAmount, invoice) =>
                                              invoiceAmount +
                                              invoice?.INVOICE_AMOUNT *
                                                  (reqCurrencyEqOuBase
                                                      ? 1
                                                      : line?.RATE),
                                          0
                                      )
                                    : 0
                            }
                            code={line?.ouBaseCurrency}
                        />
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                        <CurrencyFigure
                            value={
                                invoiceIds.length > 0
                                    ? invoiceIds.reduce(
                                          (amountPaid, invoice) =>
                                              amountPaid +
                                              invoice?.AMOUNT_PAID *
                                                  (reqCurrencyEqOuBase
                                                      ? 1
                                                      : line?.RATE),
                                          0
                                      )
                                    : 0
                            }
                            code={line?.ouBaseCurrency}
                        />
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                        {poIds.length ? (
                            <Stack spacing={1}>
                                {poIds.map((poData, index) => {
                                    return (
                                        <PurchaseOrderChip
                                            data={poData}
                                            variant="detailed"
                                            key={index}
                                        />
                                    );
                                })}
                            </Stack>
                        ) : (
                            "-"
                        )}
                    </TableCell>
                    <TableCell align="center">
                        {line?.PROJECT_NUMBER || " - "}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                        {invoiceIds.length
                            ? invoiceIds.map((invoiceData, index) => {
                                  return (
                                      <Link
                                          key={
                                              "invoicechip" +
                                              invoiceData.INVOICE_ID +
                                              index
                                          }
                                          to={INVOICE_SUMMARY_PAGE(
                                              invoiceData.INVOICE_ID
                                          )}
                                          style={{
                                              textDecoration: "none",
                                          }}
                                      >
                                          <Chip
                                              label={invoiceData.INVOICE_ID}
                                              size="small"
                                              sx={{
                                                  color: "text.subheader",
                                                  borderColor: "text.subheader",
                                                  cursor: "pointer",
                                                  mt: "6px",
                                              }}
                                              variant="outlined"
                                          />
                                      </Link>
                                  );
                              })
                            : " - "}
                    </TableCell>
                    {showStatusColumn && (
                        <TableCell align="center" component="th" scope="row">
                            <StatusColumnValue />
                        </TableCell>
                    )}
                </TableRow>
            ) : (
                <TableRow>
                    {[...Array(numSkeletons).keys()].map((v, index) => {
                        return (
                            <TableCell
                                key={"reqlineskeleton" + line?.id + index}
                                align="center"
                                component="th"
                                scope="row"
                            >
                                <Skeleton height="35px" />
                            </TableCell>
                        );
                    })}
                </TableRow>
            )}
        </>
    );
};

const Row = ({ req }) => {
    const header = req.requisitionHeaderForm;
    const lines = req.requisitionLines;
    const [open, setOpen] = useState(false);

    const statusBadgeTooltip = (req) =>
        req.status == INVALID_REQUISITION ? invalidMessage : null;

    const reqContainsCancelledLines = req.requisitionLines.some((line) =>
        isReqLineCancelled(line)
    );

    return (
        <>
            <TableRow
                selected={open}
                hover
                sx={{ "& > *": { borderBottom: "unset" } }}
            >
                <TableCell sx={{ pl: "2px", pr: "0px" }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <CopyTypography variant="body2" color="text.primary">
                        {req.id}
                    </CopyTypography>
                </TableCell>
                <TableCell align="center">{req.submittedByName}</TableCell>
                <TableCell align="center">
                    {dateFormat(req.submittedDate)}
                </TableCell>
                <TableCell align="center">
                    {header.HEADER_DESCRIPTION}
                </TableCell>
                <TableCell align="center">{header.ORG_NAME}</TableCell>
                <TableCell align="center">
                    {req?.SOURCING_TYPE == INTERNAL_REQUISITION ? (
                        <>
                            <Tooltip
                                placement="top"
                                disableInteractive
                                title={
                                    TOOLTIP_INTERNAL_REQ_PO_COLUMN_REQ_LIST_PAGE
                                }
                            >
                                <span>
                                    <OutlinedChip value="Internal" />
                                </span>
                            </Tooltip>
                        </>
                    ) : (
                        <HeaderPOValue
                            req={req}
                            onMultipleClick={() => setOpen(!open)}
                        />
                    )}
                </TableCell>
                <TableCell align="center">
                    <CurrencyFigure
                        value={req?.totalPriceBaseCurrency}
                        code={req?.ouBaseCurrency}
                    />
                </TableCell>
                <TableCell align="center">
                    {req.supplierName
                        ? req.supplierName
                        : "No associated supplier"}
                </TableCell>
                <TableCell align="center">
                    <RequisitionStatusBadge
                        status={req.status}
                        customTooltip={statusBadgeTooltip(req)}
                    />
                </TableCell>
                <TableCell align="center" sx={{ pl: "0px", pr: "4px" }}>
                    <RequisitionButtonCollection req={req} />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={12}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography
                                fontWeight="bold"
                                variant="body1"
                                color="text.subheader"
                                sx={{ mt: "16px" }}
                            >
                                {`Summary of line items within: ${req.id}`}
                            </Typography>
                            <Table
                                size="small"
                                aria-label="purchases"
                                sx={{ mb: "32px" }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography
                                                {...{
                                                    ...subTableHeaderProps,
                                                    align: "center",
                                                }}
                                            >
                                                Item Description
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Amount Ordered
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                whiteSpace: "normal",
                                                wordWrap: "break-word",
                                            }}
                                        >
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Amount Received
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Amount Invoiced
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Amount Paid
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Purchase Order
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Project
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                {...subTableHeaderProps}
                                            >
                                                Invoice
                                            </Typography>
                                        </TableCell>
                                        {reqContainsCancelledLines && (
                                            <TableCell>
                                                <Typography
                                                    {...subTableHeaderProps}
                                                >
                                                    Cancel Status
                                                </Typography>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {lines.map((line, index) => (
                                        <RequisitionLineRow
                                            key={index}
                                            line={line}
                                            reqId={req.id}
                                            reqCurrencyEqOuBase={
                                                req?.reqCurrencyEqOuBase
                                            }
                                            showStatusColumn={
                                                reqContainsCancelledLines
                                            }
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const RequisitionSummaryTable = ({ reqs }) => {
    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>
                            <Tooltip title={TOOLTIP_IBUY_REQUISITION_ID}>
                                <Typography
                                    {...{
                                        ...tableHeaderProps,
                                        align: "center",
                                    }}
                                >
                                    iBuy Requisition ID
                                </Typography>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            <Tooltip title={TOOLTIP_PREPARER}>
                                <Typography {...tableHeaderProps}>
                                    Preparer
                                </Typography>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            <Tooltip title={TOOLTIP_DATE_CREATED}>
                                <Typography {...tableHeaderProps}>
                                    Date Created
                                </Typography>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            <Tooltip title={TOOLTIP_HEADER_DESCRIPTION}>
                                <Typography {...tableHeaderProps}>
                                    Header Description
                                </Typography>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            <Tooltip title={TOOLTIP_OU}>
                                <Typography {...tableHeaderProps}>
                                    OU
                                </Typography>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            <Tooltip title={TOOLTIP_PO_NUMBER}>
                                <Typography {...tableHeaderProps}>
                                    Purchase Order
                                </Typography>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            <Tooltip title={TOOLTIP_TOTAL_AMOUNT}>
                                <Typography {...tableHeaderProps}>
                                    Total Amount
                                </Typography>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            <Tooltip title={TOOLTIP_SUPPLIER}>
                                <Typography {...tableHeaderProps}>
                                    Supplier
                                </Typography>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            <Tooltip title={TOOLTIP_STATUS}>
                                <Typography
                                    {...{
                                        ...tableHeaderProps,
                                        align: "center",
                                    }}
                                >
                                    Status
                                </Typography>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            <Tooltip title={TOOLTIP_ACTIONS}>
                                <Typography
                                    {...tableHeaderProps}
                                    align="center"
                                >
                                    Actions
                                </Typography>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reqs.map((req) => (
                        <Row key={req.id} req={req} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RequisitionSummaryTable;
