import {
    ReactPlugin,
    withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";

// eslint-disable-next-line no-undef
const appInsightKey = GLOBAL_CONFIG.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY;

const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
    config: {
        instrumentationKey: appInsightKey,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
        disableCookiesUsage: true,
        isStorageUseDisabled: true,
    },
});

ai.addTelemetryInitializer((envelope) => {
    envelope.tags = envelope.tags || [];
    envelope.tags.push({ "ai.cloud.role": "iBuy-FE" });
});

ai.loadAppInsights();
export default (Component) => withAITracking(reactPlugin, Component);
export const appInsights = ai.appInsights;
