import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { FROM_DEFAULT } from "../../../constants/generalConstants";
import { INVALID_CART_NAME_MSG } from "../../../constants/generalConstants";
import { setShowCreateNameCartDialogue } from "../../../redux/actions/generalActions";
import {
    postAddToNamedCart,
    postNamedCart,
} from "../../../services/cart/cartAPIs";
import styledDialogueSX from "../../../styled/styledSXProperties/styledDialogueSX";
import { invalidCartNames } from "../../../utils/shoppingCartUtils";

const fromDefaultSubheader =
    "Please enter a name for your named cart below. This saves the cart and its content for future use.";
const fromBlankSubheader =
    "Please enter a name for your named cart below. This saves a new blank cart in the database.";

const CreateNamedCartDialogue = () => {
    // Collect states and state dispatchers
    const activeCart = useSelector((state) => state.general.activeCart);
    const carts = useSelector((state) => state.shoppingCart);
    const config = useSelector(
        (state) => state.general.showCreateNamedCartDialogue
    );
    const dispatch = useDispatch();

    const navigate = useNavigate();

    // Instantiate local states used only within component
    const [inputName, setInputName] = useState("");
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);

    const handleClose = () => {
        dispatch(setShowCreateNameCartDialogue({ open: false, mode: null }));
    };

    // Create new cart from default cart, wipe default, and navigate to new cart
    const createFromDefault = () => {
        /*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
        const { id, ...newCart } = {
            ...carts[activeCart],
            name: inputName,
            protected: false,
        };
        dispatch(setShowCreateNameCartDialogue({ open: false, mode: null }));
        postNamedCart(newCart, activeCart, dispatch, navigate, inputName);
    };

    // Creates new empty cart
    const createBlank = () => {
        const newCart = {
            name: inputName,
            items: [],
            enabled: true,
        };
        dispatch(setShowCreateNameCartDialogue({ open: false, mode: null }));
        postAddToNamedCart(newCart, dispatch);
    };

    // On click, add new cart to state management, update active cart state,
    // close dialogue box and route to cart URL
    const handleClickCreateNamedCart = () => {
        const isInvalid = invalidCartNames(carts).includes(
            inputName.trim().toLowerCase()
        );
        if (isInvalid) {
            setError(true);
            setErrorMsg(INVALID_CART_NAME_MSG);
        } else {
            config.mode == FROM_DEFAULT ? createFromDefault() : createBlank();
            setInputName("");
            setErrorMsg("");
            setError(false);
        }
    };

    return (
        <>
            <Dialog open={config.open} onClose={handleClose} fullWidth>
                <DialogTitle variant="h3" sx={styledDialogueSX}>
                    Create Named Cart
                </DialogTitle>
                <DialogContent sx={styledDialogueSX}>
                    <DialogContentText>
                        {config.mode == FROM_DEFAULT
                            ? fromDefaultSubheader
                            : fromBlankSubheader}
                    </DialogContentText>
                    <TextField
                        id="name"
                        label="Cart Name"
                        color="secondary"
                        required
                        fullWidth
                        variant="standard"
                        value={inputName}
                        helperText={errorMsg}
                        error={error}
                        onChange={(event) => setInputName(event.target.value)}
                    />
                </DialogContent>
                <DialogActions sx={styledDialogueSX}>
                    <Button
                        variant="contained"
                        onClick={handleClose}
                        sx={{ margin: "0px 10px" }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleClickCreateNamedCart}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CreateNamedCartDialogue;
