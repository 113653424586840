import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IBuyPaper from "components/displays/iBuyPaper";

import { subHeaderProps } from "../../../../styled/props/subHeaderProps";
import ApprovalsTree from "./approvalsTree";

const ApprovalsTreeComposition = ({ req }) => {
    return (
        <IBuyPaper>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography {...subHeaderProps}>
                        Requisition Approvals Tree
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        Approvals tree is generated on submission of a
                        requisition. There may be a time delay between
                        submitting an iBuy requisition and an approvals tree
                        being available for viewing here.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ApprovalsTree requisition={req} />
                </Grid>
            </Grid>
        </IBuyPaper>
    );
};

export default ApprovalsTreeComposition;
