import {
    INVOICE_SUMMARY_PAGE,
    PO_SUMMARY_PAGE,
    PRODUCT_RESULTS,
    REQUISITION_SUMMARY_PAGE,
} from "../constants/routes";
import { openReceiptSummaryDialogue } from "./receiptSummaryUtils";

const ALL = "Product List";
const ITEM_NUMBER = "Item Number";
const SUPPLIER_NUMBER = "Supplier Number";
const REQUISITION_ID = "iBuy Requisition ID";
const PO_NUMBER = "PO Number";
const INVOICE_ID = "Invoice ID";
export const RECEIPT_NUMBER = "Receipt Number";

export const searchBarQueryOptions = [
    ALL,
    ITEM_NUMBER,
    SUPPLIER_NUMBER,
    REQUISITION_ID,
    PO_NUMBER,
    INVOICE_ID,
    RECEIPT_NUMBER,
];

export const searchBarQueryEngine = (searchOption, searchInput, dispatch) => {
    /*
    Arguments
    ---------
    searchOption : str
    searchInput : str
    dispatch : func
    */
    switch (searchOption) {
        case ALL: {
            const searchParams = new URLSearchParams();
            searchParams.set("search_query", searchInput);
            return PRODUCT_RESULTS + "?" + searchParams;
        }
        case ITEM_NUMBER: {
            const searchParams = new URLSearchParams();
            searchParams.set("item_number", searchInput);
            return PRODUCT_RESULTS + "?" + searchParams;
        }
        case SUPPLIER_NUMBER: {
            const searchParams = new URLSearchParams();
            searchParams.set("supplier_number", searchInput);
            return PRODUCT_RESULTS + "?" + searchParams;
        }
        case REQUISITION_ID: {
            return REQUISITION_SUMMARY_PAGE(searchInput);
        }
        case PO_NUMBER: {
            return PO_SUMMARY_PAGE(searchInput);
        }
        case INVOICE_ID: {
            return INVOICE_SUMMARY_PAGE(searchInput);
        }
        case RECEIPT_NUMBER: {
            // No url redirect
            openReceiptSummaryDialogue(searchInput, dispatch);
            return "";
        }
        default: {
            console.warn("Invalid search option input to search engine");
        }
    }
};
