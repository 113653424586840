import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import { SERVICE } from "../../../../../constants/generalConstants";
import CreateReceiptLine from "./createReceiptLine";

const CreateReceiptLines = ({ errors, register }) => {
    // Collect states and state dispatchers.
    const poShipments = useSelector((state) => state.createReceipt.poShipments);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5" color="text.subheader">
                    Purchase order shipments associated with requisition
                </Typography>
            </Grid>
            {poShipments.map((shipment, index) => {
                // Destructured to enable prop-type checking on key arguments
                const {
                    checked,
                    PO_AVAILABLE,
                    PO_APPROVED,
                    AVAILABLE_FOR_RECEIPT,
                    receiptQuantity,
                    LINE_TYPE,
                    CLOSED_FOR_RECEIPT,
                    ...poShipment
                } = shipment;
                return (
                    <Grid item xs={12} key={poShipment?.id}>
                        <CreateReceiptLine
                            checked={checked}
                            poAvailable={PO_AVAILABLE}
                            availableForReceipt={AVAILABLE_FOR_RECEIPT}
                            closedForReceipt={
                                CLOSED_FOR_RECEIPT === null
                                    ? false
                                    : CLOSED_FOR_RECEIPT
                            }
                            poApproved={PO_APPROVED}
                            poShipment={poShipment}
                            shipmentIndex={index}
                            receiptQuantity={receiptQuantity}
                            errors={errors}
                            register={register}
                            byAmount={LINE_TYPE == SERVICE ? true : false}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};

CreateReceiptLines.propTypes = {
    errors: PropTypes.object,
    register: PropTypes.func,
};

export default CreateReceiptLines;
