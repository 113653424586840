import { useEffect, useRef } from "react";

/**
 * Hook to determine if a given render is the first
 * render of the component (the mount)
 * @returns {boolean} - if render if first render
 */
const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);
    return isMountRef.current;
};

export default useIsMount;
