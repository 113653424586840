import { Box, Stack, useMediaQuery } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import BlueNameValue from "components/displays/blueNameValue";
import DetailListVertical from "components/displays/detailListVertical";
import IBuyPaper from "components/displays/iBuyPaper";
import OnDesktop from "components/wrappers/onDesktop";
import OnMobile from "components/wrappers/onMobile";
import { MOBILE_MEDIA_QUERY } from "constants/mediaQueries";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { arrayOnCondition } from "utils/general";

import { decimalNumberRegex } from "../../../../../constants/patterns";
import {
    setPoShipmentCheck,
    setReceiptQuantity,
} from "../../../../../redux/actions/createReceiptActions";
import { freeTextFormProps } from "../../../../../styled/props/textFieldProps";
import { receiptLimit } from "../../../../../utils/createReceiptUtils";
import { ReqLineImage } from "../../../../displays/reqLineImage";

const CreateReceiptLine = ({
    availableForReceipt,
    closedForReceipt,
    checked,
    shipmentIndex,
    receiptQuantity,
    poAvailable,
    poApproved,
    poShipment,
    errors,
    register,
    byAmount,
}) => {
    // Collect states and state dispatchers
    const dispatch = useDispatch();
    const paperProperties = () =>
        canBeReceipted()
            ? {}
            : {
                  pointerEvents: "none",
                  opacity: 0.5,
              };

    const [currentLimit, maxLimit] = receiptLimit(
        poShipment?.QTY_RCV_TOLERANCE,
        poShipment?.QUANTITY,
        poShipment?.QUANTITY_RECEIVED
    );
    // Register text input for form validation (applied conditionally based on if the line is checked)
    const registerName = "receiptCreation" + shipmentIndex;
    const { ref: receiptCreationRef, ...creationProps } = register(
        registerName,
        {
            onChange: (e) =>
                dispatch(setReceiptQuantity(shipmentIndex, e.target.value)),
            required: checked ? "Required Field" : null,
            max:
                checked && currentLimit
                    ? {
                          value: currentLimit,
                          message: `Max permitted ${
                              byAmount ? "amount" : "quantity"
                          } exceeded (${maxLimit})`,
                      }
                    : null,
            pattern: checked
                ? {
                      value: decimalNumberRegex,
                      message: "Invalid quantity input",
                  }
                : null,
        }
    );
    const canBeReceipted = () =>
        poAvailable && poApproved && availableForReceipt && !closedForReceipt;

    const onCheck = (event) =>
        dispatch(setPoShipmentCheck(shipmentIndex, event.target.checked));

    const disabledLineTooltip = () => {
        let tooltip;
        switch (true) {
            case !poAvailable:
                tooltip =
                    "This requisition line is not yet available for receipting as a purchase order has not been generated";
                break;
            case !poApproved:
                tooltip =
                    "This shipment is unavailable for receipting as its purchase order has not been approved";
                break;
            case !availableForReceipt:
                tooltip =
                    "This shipment is unavailable for receipting as it is currently undergoing a receipting, correction or return transaction - please try again in a few minutes";
                break;
            case closedForReceipt:
                tooltip =
                    "This shipment is unavailable for receipting as it is closed for receiving";
                break;
            default:
                tooltip =
                    "This shipment is currently unavailable for receipting";
                break;
        }
        return tooltip;
    };

    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    return (
        <Tooltip
            placement="top-start"
            title={canBeReceipted() ? "" : disabledLineTooltip()}
        >
            <span>
                <IBuyPaper
                    additionalSx={{
                        ...paperProperties(),
                    }}
                >
                    <Grid container spacing={2}>
                        {/* Image and checkbox */}
                        <Grid item xs={3} md={1.5}>
                            <Stack
                                direction={isDesktop ? "row" : "column-reverse"}
                                display="flex"
                                alignItems="center"
                            >
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked}
                                                onChange={onCheck}
                                                color="secondary"
                                            />
                                        }
                                    />
                                </FormGroup>
                                <Box>
                                    <ReqLineImage
                                        ITEM_IMAGE_URL={
                                            poShipment?.ITEM_IMAGE_URL
                                        }
                                    />
                                </Box>
                            </Stack>
                        </Grid>

                        {/* Details */}
                        <Grid item xs={8} md={7.5}>
                            <OnMobile>
                                <DetailListVertical
                                    details={[
                                        {
                                            header: "Item Description",
                                            hideHeader: true,
                                            content:
                                                poShipment?.ITEM_DESCRIPTION,
                                        },
                                        {
                                            header: "Need By Date",
                                            content: poShipment?.NEED_BY_DATE,
                                        },
                                        ...arrayOnCondition(!byAmount, {
                                            header: "Unit of Measure",
                                            content:
                                                poShipment?.UNIT_OF_MEASURE_DESCRIPTION,
                                        }),
                                        {
                                            header: byAmount
                                                ? "Amount Ordered"
                                                : "Ordered",
                                            content: poShipment?.QUANTITY,
                                        },
                                        {
                                            header: byAmount
                                                ? "Amount Received to Date"
                                                : "Received to Date",
                                            content:
                                                poShipment?.QUANTITY_RECEIVED
                                                    ? poShipment?.QUANTITY_RECEIVED
                                                    : "0",
                                        },
                                    ]}
                                />
                            </OnMobile>
                            <OnDesktop>
                                <Box
                                    sx={{
                                        display: "grid",
                                        gridTemplateColumns: "repeat(3, 1fr)",
                                        gridTemplateAreas:
                                            "'description needBy measure' 'description ordered received'",
                                        gap: (theme) => theme.spacing(2),
                                    }}
                                >
                                    <Box sx={{ gridArea: "description" }}>
                                        <BlueNameValue
                                            detail={{
                                                header: "Item Description",
                                                content:
                                                    poShipment?.ITEM_DESCRIPTION,
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ gridArea: "needBy" }}>
                                        <BlueNameValue
                                            detail={{
                                                header: "Need By Date",
                                                content:
                                                    poShipment?.NEED_BY_DATE,
                                            }}
                                        />
                                    </Box>
                                    {!byAmount && (
                                        <Box sx={{ gridArea: "measure" }}>
                                            <BlueNameValue
                                                detail={{
                                                    header: "Unit of Measure",
                                                    content:
                                                        poShipment?.UNIT_OF_MEASURE_DESCRIPTION,
                                                }}
                                            />
                                        </Box>
                                    )}
                                    <Box sx={{ gridArea: "ordered" }}>
                                        <BlueNameValue
                                            detail={{
                                                header: byAmount
                                                    ? "Amount Ordered"
                                                    : "Ordered",
                                                content: poShipment?.QUANTITY,
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            gridArea: byAmount
                                                ? "measure"
                                                : "received",
                                        }}
                                    >
                                        <BlueNameValue
                                            detail={{
                                                header: byAmount
                                                    ? "Amount Received to Date"
                                                    : "Received to Date",
                                                content:
                                                    poShipment?.QUANTITY_RECEIVED
                                                        ? poShipment?.QUANTITY_RECEIVED
                                                        : "0",
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </OnDesktop>
                        </Grid>

                        {/* Received field */}
                        <Grid item xs={12} md={3}>
                            <TextField
                                {...freeTextFormProps}
                                value={receiptQuantity}
                                variant="outlined"
                                label={
                                    byAmount
                                        ? "Receipt Amount"
                                        : "Receipt Quantity"
                                }
                                placeholder={`Enter receipt ${
                                    byAmount ? "amount" : "quantity"
                                }...`}
                                inputRef={receiptCreationRef}
                                {...creationProps}
                                error={checked ? !!errors[registerName] : false}
                                helperText={
                                    checked ? errors[registerName]?.message : ""
                                }
                                sx={{ mt: "6px" }}
                            />
                        </Grid>
                    </Grid>
                </IBuyPaper>
            </span>
        </Tooltip>
    );
};

CreateReceiptLine.propTypes = {
    availableForReceipt: PropTypes.bool,
    closedForReceipt: PropTypes.bool,
    checked: PropTypes.bool,
    poAvailable: PropTypes.bool,
    poApproved: PropTypes.bool,
    poShipment: PropTypes.object,
    shipmentIndex: PropTypes.number,
    receiptQuantity: PropTypes.string,
    errors: PropTypes.object,
    register: PropTypes.func,
    byAmount: PropTypes.bool,
};

export default CreateReceiptLine;
