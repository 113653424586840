import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

import { HOMEPAGE } from "../../constants/routes";
import IBuyPaper from "../displays/iBuyPaper";

const PageNotFound = () => {
    // Collect states and state dispatchers
    return (
        <IBuyPaper
            additionalSx={{
                width: "100%",
                overflowY: "auto",
                flexDirection: "column",
            }}
        >
            <Typography variant="h6" fontWeight="bold">
                Page not found
            </Typography>
            <Link to={HOMEPAGE} style={{ textDecoration: "none" }}>
                <Button
                    variant="contained"
                    sx={{
                        margin: "10px 0px",
                    }}
                    color="primary"
                >
                    Go to Homepage
                </Button>
            </Link>
        </IBuyPaper>
    );
};

export default PageNotFound;
