import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import RequisitionStatusBadge from "components/displays/status/requisitionStatusBadge";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import {
    accordionSummarySX,
    accordionSX,
} from "styled/styledSXProperties/styledAccordionSX";

import { dateFormat } from "../../../../utils/general";
import DetailListVertical from "../../../displays/detailListVertical";
import ReceiptButtonCollection from "../receiptButtonCollection";
import RequisitionLines from "./requisitionLines/requisitionLines";

const ReceiptSummaryCard = ({ req }) => {
    const darkMode = useSelector((state) => state.general.darkMode);
    return (
        <Accordion sx={accordionSX}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="receipt-line-item-accordion-controls"
                id="receipt-line-item-accordion"
                sx={accordionSummarySX(darkMode)}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography
                                component="h2"
                                variant="h5"
                                color="text.subheader"
                            >
                                {req.id}
                            </Typography>
                            <RequisitionStatusBadge
                                status={req.RECEIPT_STATUS}
                            />
                        </Stack>
                    </Grid>

                    <Grid item xs={12} container spacing={1}>
                        <Grid item xs>
                            <DetailListVertical
                                condensed={true}
                                details={[
                                    {
                                        header: "Description",
                                        content:
                                            req.requisitionHeaderForm
                                                .HEADER_DESCRIPTION,
                                    },
                                    {
                                        header: "Preparer",
                                        content: req.submittedByName,
                                    },
                                    {
                                        header: "Date Created",
                                        content: dateFormat(req.submittedDate),
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <ReceiptButtonCollection req={req} />
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <RequisitionLines req={req} />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

ReceiptSummaryCard.propTypes = {
    req: PropTypes.object.isRequired,
};

export default ReceiptSummaryCard;
