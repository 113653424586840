export const themeTypography = {
    fontFamily: "Arial",
    fontSize: 14,
    h1: {
        fontSize: "2.5rem", // 40px
        fontWeight: "bold",
    },
    h2: {
        fontSize: "2rem", // 32px
        fontWeight: "bold",
    },
    h3: {
        fontSize: "1.5rem", // 24px
        fontWeight: "bold",
    },
    h4: {
        fontSize: "1.25rem", // 20px
        fontWeight: "bold",
    },
    h5: {
        fontSize: "1.125rem", // 18px
        fontWeight: "bold",
    },
    h6: {
        fontSize: "1rem", // 16px
        fontWeight: "normal",
    },
    body1: {
        fontSize: "0.875rem", // 14px
        fontWeight: "normal",
    },
    body2: {
        fontSize: "0.75rem", // 12px
        fontWeight: "normal",
    },
};
