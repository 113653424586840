const baseAccordionSX = {
    borderRadius: "10px",
};

export const accordionSX = {
    ...baseAccordionSX,
    ":hover": {
        boxShadow: "24",
    },
    "&.MuiAccordion-root:before": {
        backgroundColor: "#00000000",
    },
    width: "100%",
    "&.MuiAccordion-rounded": {
        borderRadius: "10px",
        boxShadow: "24",
    },
};

export const accordionSummarySX = (darkMode) => ({
    ...baseAccordionSX,
    backgroundImage: darkMode && "linear-gradient(to bottom,#3a3c45, #2E3037)",
});
