import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import alertDispatcher from "../../../utils/alertsUtils";
import {
    getApiUserAutocomplete,
    patchBatchReqOwnerUpdate,
    patchSingleReqOwnerUpdate,
} from "../../user/userAPIs";

const useUser = () => {
    const dispatch = useDispatch();

    const getUserAutocomplete = useCallback(
        /**
         * @param {string} searchTerm
         */
        async (searchTerm) => {
            try {
                const response = await getApiUserAutocomplete(searchTerm);

                return response.data;
            } catch (error) {
                alertDispatcher(
                    "getUserAutocomplete",
                    error.response.status,
                    dispatch,
                    error.response.data.detail
                );
                return [];
            }
        },
        [dispatch]
    );

    const [
        isChangeRequisitionOwnerLoading,
        setIsChangeRequisitionOwnerLoading,
    ] = useState(false);

    const changeRequisitionOwner = useCallback(
        /**
         * @param {string} requisitionId
         * @param {string} toOwner
         */
        async (requisitionId, toOwner) => {
            const patchBody = {
                requisition: requisitionId,
                to_owner: toOwner,
            };

            setIsChangeRequisitionOwnerLoading(true);

            try {
                const response = await patchSingleReqOwnerUpdate(patchBody);
                alertDispatcher(
                    "changeRequisitionOwner",
                    200,
                    dispatch,
                    response?.data?.msg || "Owner update successfully processed"
                );
                return response.status;
            } catch (error) {
                alertDispatcher(
                    "changeRequisitionOwner",
                    error.response.status,
                    dispatch,
                    error.response.data.detail
                );
                return error.response.status;
            } finally {
                setIsChangeRequisitionOwnerLoading(false);
            }
        },
        [dispatch]
    );

    const [
        isBatchReqReqOwnerUpdateLoading,
        setIsBatchReqReqOwnerUpdateLoading,
    ] = useState(false);

    const [isPreflightCheckLoading, setIsPreflightCheckLoading] =
        useState(false);

    const batchReqOwnerUpdate = useCallback(
        /**
         * @param {string} fromOwner
         * @param {string} toOwner
         * @param {string} fromDate
         * @param {string} toDate
         * @param {boolean} isPreflight
         */
        async (fromOwner, toOwner, fromDate, toDate, isPreflight) => {
            const patchBody = {
                from_owner: fromOwner,
                to_owner: toOwner,
                from_date: fromDate,
                to_date: toDate,
            };
            isPreflight
                ? setIsPreflightCheckLoading(true)
                : setIsBatchReqReqOwnerUpdateLoading(true);
            try {
                const response = await patchBatchReqOwnerUpdate(
                    isPreflight,
                    patchBody
                );
                !isPreflight &&
                    alertDispatcher(
                        "batchOwnerUpdate",
                        200,
                        dispatch,
                        response?.data?.msg ||
                            "Batch update successfully processed"
                    );
                return isPreflight ? response.data : 200;
            } catch (error) {
                alertDispatcher(
                    "batchOwnerUpdate",
                    error.response.status,
                    dispatch,
                    error?.response?.data?.detail
                );
                return 400;
            } finally {
                isPreflight
                    ? setIsPreflightCheckLoading(false)
                    : setIsBatchReqReqOwnerUpdateLoading(false);
            }
        },
        [dispatch]
    );

    return {
        getUserAutocomplete,
        changeRequisitionOwner,
        batchReqOwnerUpdate,
        isChangeRequisitionOwnerLoading,
        isBatchReqReqOwnerUpdateLoading,
        isPreflightCheckLoading,
    };
};

export default useUser;
