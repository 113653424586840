import PropTypes from "prop-types";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { PROJECT_REQ } from "../../../../constants/generalConstants";
import { LineItemFormSingleLine } from "./lineItemFormSingleLine";

const LineItemForm = ({ register, errors, setIsNextButtonDisabled }) => {
    const reqLines = useSelector(
        (state) => state.createRequisition.iBuyRequisition.requisitionLines
    );
    const headerForm = useSelector(
        (state) => state.createRequisition.iBuyRequisition.requisitionHeaderForm
    );

    useEffect(() => {
        if (headerForm.ACCOUNTED_AGAINST === PROJECT_REQ) {
            setIsNextButtonDisabled(false);
        } else {
            setIsNextButtonDisabled(
                reqLines.some((reqLine) => !reqLine.IS_VALIDATED)
            );
        }
    }, [headerForm.ACCOUNTED_AGAINST, reqLines]);

    return (
        <>
            {reqLines
                .filter((reqLine) => {
                    return reqLine?.CHANGE_ACTION !== "DELETED";
                })
                .map((reqLine, index) => (
                    <LineItemFormSingleLine
                        key={index}
                        reqLines={reqLines}
                        reqLine={reqLine}
                        index={index}
                        errors={errors}
                        register={register}
                    />
                ))}
        </>
    );
};

LineItemForm.propTypes = {
    errors: PropTypes.object,
    register: PropTypes.func,
    setIsNextButtonDisabled: PropTypes.func,
};

export default LineItemForm;
