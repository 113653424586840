import CancelIcon from "@mui/icons-material/Cancel";
import RuleIcon from "@mui/icons-material/Rule";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormControlLabel, FormGroup, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { setShowDisputeDialogue } from "../../../redux/actions/generalActions";
import { postDispute } from "../../../services/receipting/receiptingAPIs";
import { freeTextFormProps } from "../../../styled/props/textFieldProps";
import styledDialogueSX from "../../../styled/styledSXProperties/styledDialogueSX";

const DisputesDialogue = ({ invoiceId, toggleDisputeSubmitKey, isDesktop }) => {
    // Collect states and state dispatchers
    const isOpen = useSelector((state) => state.general.showDisputeDialogue);
    const dispatch = useDispatch();

    // Local states
    const [isLoading, setIsLoading] = useState(false);
    const [reason, setReason] = useState("");
    const [sharepointLink, setSharepointLink] = useState("");
    const [confirmCheckbox, setConfirmCheckbox] = useState(false);

    const confirmSupplierContacted = () => {
        setConfirmCheckbox(!confirmCheckbox);
    };

    // Instantiate react hook form and required registers
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const { ref: reasonRef, ...reasonProps } = register("disputeReason", {
        onChange: (e) => setReason(e.target.value),
        required: "Required Field",
    });

    const { ref: sharepointLinkRef, ...sharepointLinkProps } = register(
        "sharepointLink",
        {
            onChange: (e) => setSharepointLink(e.target.value),
            required: "Required Field",
        }
    );

    // Dialogue methods
    const close = () => dispatch(setShowDisputeDialogue(false));

    const placeInDispute = () =>
        postDispute(
            invoiceId,
            reason,
            sharepointLink,
            setIsLoading,
            dispatch,
            toggleDisputeSubmitKey
        );

    const onClickDispute = (e) => handleSubmit(() => placeInDispute())(e);

    return (
        <Dialog
            open={isOpen}
            onClose={close}
            aria-labelledby="confirm-dialog"
            fullScreen={!isDesktop}
        >
            <DialogTitle id="confirm-dialog" variant="h3" sx={styledDialogueSX}>
                Place Invoice in Dispute?
            </DialogTitle>
            <DialogContent sx={styledDialogueSX}>
                <Grid container spacing={2} color="text.secondary">
                    <Grid item xs={12}>
                        <Typography>
                            Placing this invoice into dispute stops all
                            confirm-receipt workflow notification for this
                            invoice. A note will be sent to Accounts Payable
                            with the below reason and SharePoint link. Please
                            contact Accounts Payable to provide any additional
                            evidence or comments.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                label={
                                    "By checking this box, you confirm that you have contacted the supplier prior to placing this Invoice in dispute."
                                }
                                sx={{ color: "white" }}
                                control={
                                    <Checkbox
                                        checked={confirmCheckbox}
                                        onClick={confirmSupplierContacted}
                                        color="secondary"
                                    />
                                }
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={!confirmCheckbox}
                            {...freeTextFormProps}
                            label="Dispute reason"
                            multiline
                            placeholder="Enter reason for placing this invoice into dispute..."
                            required
                            value={reason}
                            inputRef={reasonRef}
                            {...reasonProps}
                            error={!!errors.disputeReason}
                            helperText={errors?.disputeReason?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={!confirmCheckbox}
                            {...freeTextFormProps}
                            label="Dispute sharepoint link"
                            multiline
                            placeholder="Enter dispute sharepoint link..."
                            required
                            value={sharepointLink}
                            inputRef={sharepointLinkRef}
                            {...sharepointLinkProps}
                            error={!!errors.sharepointLink}
                            helperText={errors?.sharepointLink?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DialogActions sx={styledDialogueSX}>
                            <Button
                                endIcon={<CancelIcon />}
                                variant="outlined"
                                color="secondary"
                                onClick={close}
                                sx={{ mr: "16px" }}
                            >
                                <Typography>Close</Typography>
                            </Button>
                            <LoadingButton
                                loading={isLoading}
                                loadingPosition="end"
                                endIcon={<RuleIcon />}
                                variant="contained"
                                color="error"
                                onClick={onClickDispute}
                            >
                                <Typography>Dispute</Typography>
                            </LoadingButton>
                        </DialogActions>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

DisputesDialogue.propTypes = {
    invoiceId: PropTypes.string,
    toggleDisputeSubmitKey: PropTypes.func,
    isDesktop: PropTypes.bool,
};

export default DisputesDialogue;
