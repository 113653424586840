// import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Chip, Typography, useMediaQuery } from "@mui/material";
import Fab from "@mui/material/Fab";
import CustomDrawer from "components/displays/customDrawer";
import IBuyCard from "components/displays/iBuyCard";
import { parseISO } from "date-fns";
import format from "date-fns/format";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { MAX_DELEGATIONS } from "../../../constants/generalConstants";
import { MOBILE_MEDIA_QUERY } from "../../../constants/mediaQueries";
import axiosApiInstance from "../../../services/middleware/axios";
import {
    USER_PROXY_RULE,
    USER_PROXY_RULE_DELETE,
} from "../../../services/user/urls";
import styledDialogueSX from "../../../styled/styledSXProperties/styledDialogueSX";
import alertDispatcher from "../../../utils/alertsUtils";
import YesNoPrompt from "../../modals/prompts/yesNoPrompt";
import CreateApprovalProxy from "../approvalProxy/createApprovalProxy";

const StatusChip = ({ label, color, disabled }) => (
    <Chip
        size="small"
        disabled={disabled}
        color={color}
        label={label}
        sx={{ fontWeight: "bold", px: 1 }}
    />
);

const ApprovalProxySidebar = ({ closeSidebarCallback, isOpen }) => {
    const dispatch = useDispatch();
    const [proxyRules, setProxyRules] = useState([]);
    const [recallApi, setRecallApi] = useState(false);
    const [createEditProxyOpen, setCreateEditProxyOpen] = useState(false);
    const [deleteDelegationIndex, setDeleteDelegationIndex] = useState(null);
    const [selectedProxyRule, setSelectedProxyRule] = useState(null);

    // Mobile check
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    useEffect(() => {
        axiosApiInstance
            .get(USER_PROXY_RULE)
            .then((response) => {
                setProxyRules(response.data.data);
            })
            .catch((error) => {
                alertDispatcher(
                    "proxyRules",
                    error?.response?.status,
                    dispatch
                );
            });
    }, [recallApi]);

    const getStatus = (startDate, endDate) => {
        const currentDate = Date.now();
        const sd = getStartDate(startDate);
        const ed = getEndDate(endDate);

        if (sd > currentDate) {
            return <StatusChip label="PENDING" color="info" />;
        } else if (ed < currentDate) {
            return <StatusChip label="EXPIRED" disabled />;
        } else {
            return <StatusChip label="ACTIVE" color="success" />;
        }
    };

    const deleteProxyRule = () => {
        const deleteUserName = proxyRules[deleteDelegationIndex].USER_ID;

        axiosApiInstance
            .delete(USER_PROXY_RULE_DELETE(deleteUserName))
            .then((response) => {
                setProxyRules((prevProxyRules) => {
                    return prevProxyRules.filter((p, index) => {
                        return index !== deleteDelegationIndex;
                    });
                });
                alertDispatcher(
                    "proxyRulesDeleted",
                    response?.status,
                    dispatch
                );
            })
            .catch((error) => {
                alertDispatcher(
                    "proxyRulesDeleted",
                    error?.response?.status,
                    dispatch
                );
            });
    };

    const getEndDate = (d) => {
        const returnDate = parseISO(d);
        returnDate.setHours(23);
        returnDate.setMinutes(59);
        returnDate.setSeconds(59);
        return returnDate;
    };

    const getStartDate = (d) => {
        const returnDate = parseISO(d);
        returnDate.setHours(0);
        returnDate.setMinutes(0);
        returnDate.setSeconds(0);
        return returnDate;
    };

    return (
        <CustomDrawer
            anchor={"right"}
            transitionDuration={200}
            open={isOpen}
            onOpen={() => {}}
            onClose={() => {
                closeSidebarCallback(false);
            }}
        >
            <Box
                sx={{
                    height: "100%",
                    width: isDesktop ? "400px" : "100vw",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    ...styledDialogueSX,
                    p: isDesktop ? 2 : 0,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        p: 2,
                    }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography
                            variant="h4"
                            align="left"
                            fontWeight="bold"
                            color="text.subheader"
                        >
                            My Delegation Rules
                        </Typography>
                    </Box>
                    <Box>
                        <Fab
                            size="small"
                            color="secondary"
                            aria-label="add"
                            disabled={proxyRules.length >= MAX_DELEGATIONS}
                            onClick={() => {
                                setCreateEditProxyOpen(true);
                            }}
                            sx={{
                                ":hover": {
                                    transform: "scale(1.2)",
                                    transition: "0.2s ease",
                                },
                            }}
                        >
                            <AddIcon />
                        </Fab>
                    </Box>
                </Box>
                <Box>
                    <Typography
                        align="justify"
                        color="text.primary"
                        sx={{ px: 2 }}
                    >
                        Delegation Rules are used to delegate approval authority
                        for your own approvals. Note that you can create up to{" "}
                        {MAX_DELEGATIONS} users to delegate to. You can edit a
                        user delegation to amend the dates.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "block",
                        overflowY: "auto",
                        px: 2,
                    }}
                >
                    {proxyRules.map((proxyRule, index) => {
                        return (
                            <IBuyCard
                                additionalSx={{
                                    my: "1rem",
                                    padding: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.5rem",
                                }}
                                key={`approvalProxyList-${index}`}
                                data-testid="approvalProxyItem"
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        align="left"
                                        fontWeight="bold"
                                        color="text.subheader"
                                    >
                                        {proxyRule.USER_FULL_NAME}
                                    </Typography>
                                    {getStatus(
                                        proxyRule.START_DATE,
                                        proxyRule.END_DATE
                                    )}
                                </Box>
                                <Typography variant="body1" align="left">
                                    Start Date:{" "}
                                    {proxyRule?.START_DATE
                                        ? format(
                                              getStartDate(
                                                  proxyRule.START_DATE
                                              ),
                                              "dd/MM/yyyy"
                                          )
                                        : "-"}
                                </Typography>
                                <Typography variant="body1" align="left">
                                    End Date:{" "}
                                    {proxyRule?.END_DATE
                                        ? format(
                                              getEndDate(proxyRule.END_DATE),
                                              "dd/MM/yyyy"
                                          )
                                        : "Indefinite"}
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        mt: "1rem",
                                    }}
                                >
                                    <Button
                                        data-testid={`approvalProxyListEdit-${index}`}
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                            setSelectedProxyRule(proxyRule);
                                            setCreateEditProxyOpen(true);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="error"
                                        onClick={() => {
                                            setDeleteDelegationIndex(index);
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </Box>
                            </IBuyCard>
                        );
                    })}
                </Box>
            </Box>

            {createEditProxyOpen && (
                <CreateApprovalProxy
                    closeModal={() => {
                        setCreateEditProxyOpen(false);
                        setSelectedProxyRule(null);
                        setRecallApi(!recallApi);
                    }}
                    isOpen={createEditProxyOpen}
                    selectedProxyRule={selectedProxyRule}
                />
            )}

            <YesNoPrompt
                title="Delete delegation rule?"
                subtitle="You will not be able to undo this action. Are you sure you wish to delete this delegation?"
                promptOpen={Number.isInteger(deleteDelegationIndex)}
                setPromptOpen={setDeleteDelegationIndex}
                onClickYes={deleteProxyRule}
            />
        </CustomDrawer>
    );
};

ApprovalProxySidebar.propTypes = {
    closeSidebar: PropTypes.func,
    isOpen: PropTypes.bool,
};

export default ApprovalProxySidebar;
