import { useCallback } from "react";
import { useDispatch } from "react-redux";

import alertDispatcher from "../utils/alertsUtils";

const useClipboard = () => {
    const dispatch = useDispatch();

    const copyToClipboard = useCallback(
        (value, successMessage = "Copied to Clipboard") => {
            navigator.clipboard
                .writeText(value)
                .then(() => {
                    alertDispatcher(
                        "CopyTypography",
                        200,
                        dispatch,
                        successMessage
                    );
                })
                .catch(() => {
                    alertDispatcher("CopyTypography", 500, dispatch);
                });
        },
        [dispatch]
    );

    return {
        copyToClipboard,
    };
};

export default useClipboard;
