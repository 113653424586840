import LoadingButton from "@mui/lab/LoadingButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import PropTypes from "prop-types";

import { ButtonListType } from "../../../types/propTypes";

const CustomButtonGroup = ({ buttons, orientation }) => {
    return (
        <ButtonGroup
            variant={"outlined"}
            color={"secondary"}
            orientation={orientation}
        >
            {buttons.map((button) => (
                <LoadingButton
                    key={button.text}
                    disabled={button.disabled}
                    loading={button.isLoading}
                    endIcon={button.icon}
                    onClick={button.onClick}
                    aria-label={button.ariaLabel}
                >
                    {button.text}
                </LoadingButton>
            ))}
        </ButtonGroup>
    );
};

CustomButtonGroup.propTypes = {
    buttons: ButtonListType,
    orientation: PropTypes.oneOf(["vertical", "horizontal"]),
};

export default CustomButtonGroup;
