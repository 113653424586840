// eslint-disable-next-line no-undef
export const BASE_BACKEND_URL = GLOBAL_CONFIG.REACT_APP_APIM_URL;
// eslint-disable-next-line no-undef
export const ENVIRONMENT = GLOBAL_CONFIG.REACT_APP_ENVIRONMENT;

// Base requisition URL
// TODO - refactor base URL
export const REQUISITION_API_URL = `${BASE_BACKEND_URL}/ibuy-${ENVIRONMENT}-Requisition/requisitions`;

export const GET_REQUISITION_BY_ID = (id) => {
    return `${REQUISITION_API_URL}/${id}`;
};

export const GET_LOOKUPS_BY_PROJECT_ID = (projectId) => {
    return `${BASE_BACKEND_URL}/ibuy-${ENVIRONMENT}-Requisition/project/${projectId}/lookups`;
};

export const ATTACHMENTS_URL = `${REQUISITION_API_URL}/attachment/`;

export const GET_ATTACHMENT = (attachmentUri, requisitionId) =>
    `${ATTACHMENTS_URL}/${attachmentUri}?requisition_id=${requisitionId.toString()}`;

export const UPDATE_REQUISITION_APPROVAL_URL = (reqId) => {
    return `${BASE_BACKEND_URL}/ibuy-${ENVIRONMENT}-Requisition/approvals/${reqId}/update`;
};

export const GET_REQUISITIONS_FOR_APPROVAL = `${BASE_BACKEND_URL}/ibuy-${ENVIRONMENT}-Requisition/approvals/`;

export const GET_RECEIPTS_FOR_ATTENTION = `${BASE_BACKEND_URL}/ibuy-${ENVIRONMENT}-Requisition/requisitions?mode=MY&receiptStatus=Y&receiptReqStatus=ATTENTION%20REQUIRED`;

export const GET_PROJ_AUTOCOMPLETE = (
    projSearchTerm,
    OUName,
    searchById = false
) =>
    `${BASE_BACKEND_URL}/ibuy-${ENVIRONMENT}-Requisition/projects-list/${projSearchTerm}?OU=${OUName}${
        searchById ? "&search_by_id=Y" : ""
    }`;

export const GET_DELIVERY_LOC_AUTOCOMPLETE = (
    deliveryLocSearchTerm,
    searchById = false
) =>
    `${BASE_BACKEND_URL}/ibuy-${ENVIRONMENT}-Requisition/delivery-location/${deliveryLocSearchTerm}?${
        searchById ? "search_by_id=Y" : ""
    }`;

export const GET_HR_ORG_AUTOCOMPLETE = (
    hrOrgSearchTerm,
    OUName,
    searchById = false
) =>
    `${BASE_BACKEND_URL}/ibuy-${ENVIRONMENT}-Requisition/hr-org/${hrOrgSearchTerm}?OU=${OUName}${
        searchById ? "&search_by_id=Y" : ""
    }`;

export const POST_CANCELLATION = (reqId) =>
    `${REQUISITION_API_URL}/cancel/${reqId}`;
