import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { INVALID_CART_NAME_MSG } from "../../../constants/generalConstants";
import {
    setShowAddToNewNamedCartDialogue,
    setShowNonCatalogueDialogue,
} from "../../../redux/actions/generalActions";
import { postAddToNamedCart } from "../../../services/cart/cartAPIs";
import styledDialogueSX from "../../../styled/styledSXProperties/styledDialogueSX";
import { invalidCartNames } from "../../../utils/shoppingCartUtils";

const AddToNewNamedCartDialogue = () => {
    // Collect states and state dispatchers
    const carts = useSelector((state) => state.shoppingCart);
    const showAddToNewNamedCartDialogue = useSelector(
        (state) => state.general.showAddToNewNamedCartDialogue
    );
    const addToNewNamedCartItem = useSelector(
        (state) => state.general.addToNewNamedCartItem?.product
    );
    const nonCatForm = useSelector(
        (state) => state.general.addToNewNamedCartItem?.nonCatForm
    );
    const userId = useSelector((state) => state.auth.samAccountName);
    const dispatch = useDispatch();

    // Instantiate local states used only within component
    const [inputName, setInputName] = useState("");
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);

    const handleClose = () => dispatch(setShowAddToNewNamedCartDialogue(false));

    // On click, add new cart to state management, update active cart state,
    // close dialogue box and route to cart URL
    const handleClickAddToNamedCart = () => {
        const isInvalid = invalidCartNames(carts).includes(
            inputName.trim().toLowerCase()
        );
        if (isInvalid) {
            setError(true);
            setErrorMsg(INVALID_CART_NAME_MSG);
        } else {
            const newCart = {
                userId: userId,
                name: inputName,
                items: [{ ...addToNewNamedCartItem, QUANTITY: 1 }],
                enabled: true,
            };
            dispatch(setShowAddToNewNamedCartDialogue(false));
            postAddToNamedCart(newCart, dispatch);
            setInputName("");
            setErrorMsg("");
            setError(false);
            nonCatForm && dispatch(setShowNonCatalogueDialogue(false));
        }
    };

    return (
        <>
            <Dialog
                open={showAddToNewNamedCartDialogue}
                onClose={handleClose}
                fullWidth
            >
                <DialogTitle variant="h3" sx={styledDialogueSX}>
                    Add Item to New Named Cart
                </DialogTitle>
                <DialogContent sx={styledDialogueSX}>
                    <DialogContentText>
                        Please enter a name for your new named cart below. The
                        item will be added to this cart.
                    </DialogContentText>
                    <TextField
                        id="name"
                        color="secondary"
                        label="Cart Name"
                        fullWidth
                        variant="standard"
                        value={inputName}
                        helperText={errorMsg}
                        error={error}
                        onChange={(event) => setInputName(event.target.value)}
                        required
                    />
                </DialogContent>
                <DialogActions sx={styledDialogueSX}>
                    <Button
                        variant="contained"
                        onClick={handleClose}
                        sx={{ margin: "0px 10px" }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleClickAddToNamedCart}
                    >
                        Add to Named Cart
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddToNewNamedCartDialogue;
