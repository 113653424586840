// eslint-disable-next-line no-undef
export const BASE_BACKEND_URL = GLOBAL_CONFIG.REACT_APP_APIM_URL;
// eslint-disable-next-line no-undef
export const ENVIRONMENT = GLOBAL_CONFIG.REACT_APP_ENVIRONMENT;

// Base requisition URL
export const RECEIPT_API_URL = `${BASE_BACKEND_URL}/ibuy-${ENVIRONMENT}-Items`;

export const CREATE_RECEIPT_EVENT = `${RECEIPT_API_URL}/receipt_creation`;
export const GET_SHIPMENTS_BY_REQUISITION = (iBuyReqNum) =>
    `${RECEIPT_API_URL}/po_shipments/${iBuyReqNum}`;

export const GET_RECEIPT_BY_NUMBER = (receiptNum, OU) =>
    `${RECEIPT_API_URL}/receipts/${receiptNum}?operating_unit=${OU}`;

export const GET_RECEIPTS_BY_REQUISITION = (requisition_id) =>
    `${RECEIPT_API_URL}/receipts_by_requisition/${requisition_id}`;

export const DISPUTE_INVOICE = (invoiceId) =>
    `${RECEIPT_API_URL}/dispute/${invoiceId}`;

export const GET_INTERNAL_EXTERNAL_ITEM_CHECK = `${RECEIPT_API_URL}/item-internal-external-check`;
