import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { DARK_MODE } from "constants/generalConstants";

import { baseTheme } from "./baseTheme";
import { SSE_GREY, SSE_TEAL } from "./colours";

export const darkTheme = createTheme(
    deepmerge(baseTheme, {
        name: "darkTheme",
        palette: {
            mode: DARK_MODE,
            primary: {
                main: SSE_GREY,
            },
            secondary: {
                main: SSE_TEAL,
            },
            text: {
                primary: "#FFFFFF",
                secondaryLight: "#aeb4b3",
                subheader: "#4CB6C2",
            },
            background: {
                default: "#000000",
                paper: "#2E3037",
                divider: "linear-gradient(to right, #2E3037, #FFFFFF, #2E3037)",
                dialogue: "#2E3037",
                accordion: "#2E3037",
            },
            success: {
                main: "#66BB6A",
                light: "#4caf50",
                dark: "#1b5e20",
            },
            info: {
                main: SSE_TEAL,
            },
            iconButton: {
                main: "#FFFFFF",
            },
        },
    })
);
