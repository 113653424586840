import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const TeamTitle = ({ title, isDesktop }) => {
    // Collect states and dispatchers
    const homepageCategoriesLoading = useSelector(
        (state) => state.general.homepageCategoriesLoading
    );
    return (
        <>
            {homepageCategoriesLoading ? (
                <Skeleton
                    variant="text"
                    width="300px"
                    height="35px"
                    sx={{ margin: isDesktop ? "12px 0px" : "0px 0px 32px" }}
                />
            ) : (
                <Typography
                    sx={{
                        color: "text.subheader",
                        fontSize: 16,
                        margin: "0px 0px 24px",
                        fontWeight: "bold",
                    }}
                >
                    {title}
                </Typography>
            )}
        </>
    );
};

TeamTitle.propTypes = {
    title: PropTypes.string,
};

export default TeamTitle;
