import { ATTACHMENT_CONSTANTS } from "./generalConstants";

// Error Status'
export const error_status = (msg) => ({
    message_status: "error",
    msg,
});
export const warning_status = (msg) => ({
    message_status: "warning",
    msg,
});
export const success_status = (msg) => ({
    message_status: "success",
    msg,
});
export const info_status = (msg) => ({
    message_status: "info",
    msg,
});

// Approvals
export const SUCCESS_APPROVAL_POSTED =
    "Requisition approval successfully processed";
export const UNAUTHORIZED_MESSAGE_APPROVAL_UPDATE =
    "You are not authorized to perform this approval";
export const SERVER_MESSAGE_APPROVAL_UPDATE =
    "Approval update could not be processed";
export const SERVER_GET_APPROVALS_TREE =
    "A server error occurred whilst trying to retrieve the approvals tree. Please contact iBuy administrators for assistance if the issue persists.";

// Approval Delegations
export const SERVER_MESSAGE_APPROVAL_PROXY_CREATED = (detail) =>
    `User Delegation rule could not created${detail ? ": " + detail : null}`;
export const SUCCESS_APPROVAL_PROXY_DELETED =
    "User delegation rule successfully deleted";
export const SERVER_ERROR_APPROVAL_PROXY_DELETED =
    "User delegation rule could not be deleted";
export const SERVER_MESSAGE_APPROVAL_PROXY =
    "Unable to retrieve user delegations - please contact administrator for support";

// Attachments
export const SUCCESS_POST_ATTACHMENT = (response) =>
    `Successfully uploaded attachment ${response}`;
export const UNAUTHORIZED_MESSAGE_POST_ATTACHMENT =
    "Unable to upload attachment due to authorization issue";
export const PAYLOAD_MESSAGE_POST_ATTACHMENT =
    "Unable to upload attachment due to authorization issue";
export const SERVER_MESSAGE_CANNOT_POST_ATTACHMENT =
    "A server error occurred - unable to upload attachment";
export const SERVER_MESSAGE_CANNOT_GET_ATTACHMENT =
    "A server error occurred - unable to retrieve attachment";
export const SERVER_DOWNLOAD_ATTACHMENT =
    "An error occured while retrieving attachment";
export const PAYLOAD_UPLOAD_ATTACHMENT = `Only a maximum of ${ATTACHMENT_CONSTANTS.maxAttachments} attachments can be uploaded`;
export const SERVER_UPLOAD_ATTACHMENT = "Error whilst uploading attachment";

// Auth
export const SERVER_AZURE_AD_TOKEN =
    "Unable to read Azure AD token - please refresh your browser";
export const FAILED_TO_COPY_TO_CLIPBOARD = "Failed to copy to clipboard";

// Cart and Favourites
export const SUCCESS_NAMED_CART_CREATED = "Named Cart Created";
export const SERVER_MESSAGE_NAMED_CART_CREATED = "Unable to create named cart";
export const SUCCESS_ADD_TO_FAVOURITES = "Added to Favourites";
export const SERVER_MESSAGE_ADD_TO_FAVOURITES = "Unable to add to Favourites";
export const SUCCESS_REMOVED_FROM_FAVOURITES = "Removed from Favourites";
export const SERVER_MESSAGE_GET_CARTS =
    "Unable to retrieve named carts from database";
export const SUCCESS_CART_CHANGE = (action) => `Cart ${action}`;
export const SERVER_MESSAGE_CART_CHANGED = (action) =>
    `Unable to ${action} cart`;
export const SUCCESS_UPDATE_CART = (favourites) =>
    favourites ? "Added to Favourites" : "Added to Cart";
export const SERVER_MESSAGE_UPDATE_CART = (favourites) =>
    favourites ? "Unable to update favourites" : "Unable to update Cart";

// Dispute
export const SUCCESS_DISPUTE = "Invoice successfully placed into dispute";
export const PAYLOAD_DISPUTE = "Invoice successfully placed into dispute";
export const NOTFOUND_DISPUTE = "Invoice dispute could not be found.";
export const SERVER_MESSAGE_DISPUTE = "Unable to place invoice into dispute";
export const SERVER_MESSAGE_GET_DISPUTE =
    "A server error occurred whilst retrieving dispute status of invoice - please contact your administrator for support";

// Invoice
export const NOTFOUND_INVOICE_ID =
    "Invoice not found, please check that you have entered the Invoice ID correctly";
export const SERVER_INVOICE_ID =
    "Error whilst retrieving invoices, please contact administrator for support";

// Notifications
export const NOTIFICATIONS = (approvals, receipts) =>
    `You have ${approvals ? approvals + " new approvals " : ""}${
        approvals && receipts ? " and " : ""
    }${receipts ? receipts + " new receipts " : ""} for your review`;

// Purchase Order
export const NOTFOUND_PO_ID =
    "Purchase Order not found, please check that you have entered the PO number correctly";
export const SERVER_PO_ID =
    "Error whilst retrieving Purchase Order, please contact administrator for support";
export const NOTFOUND_PO_PDF =
    "The requested Purchase Order PDF could not be found - it may not have been generated yet.";
export const SERVER_ERROR_PO_PDF =
    "Error whilst requesting Purchase Order PDF, please contact administrator for support";

// Receipting
export const PAYLOAD_RECEIPT_CREATION = "Invalid Input for Receipts";
export const NOTFOUND_RECEIPT_BY_NUMBER = (id) =>
    `Unable to find receipt number ${id}`;
export const SERVER_MESSAGE_RECEIPT_BY_NUMBER = (id) =>
    `Server error whilst retrieving receipt number ${id}`;
export const NOTFOUND_RECEIPT_IN_OU = (id) =>
    `To access Receipt ${id} please change the Operating Unit to match the requisition.`;
export const NOTFOUND_RECEIPT_BY_REQID = (id) =>
    `Unable to find receipt by Req ID: ${id}`;
export const SERVER_MESSAGE_RECEIPT_BY_REQID = (id) =>
    `Server error whilst retrieving receipt by Req ID: ${id}`;
export const SERVER_MESSAGE_RECEIPT_LOAD =
    "Error retrieving receipts associated with this requisition. Please refresh webpage and contact iBuy administrators for support if this issue persists";
export const SERVER_MESSAGE_RECEIPT_REQ_ID =
    "An error occurred whist trying to retrieve receipting data - please contact administrator for support";
export const UNAVAILABLE_RECEIPT_CORRECTION =
    "All receipt lines unavailable for correction as their associated PO shipments are being receipted by another user - try again in a few minutes";
export const UNAVAILABLE_RECEIPT_RETURN =
    "All receipt lines unavailable for return, this may be because their associated PO shipments are being receipted by another user - try again in a few minutes.";
export const NO_RECEIPTED_ITEMS_RETURN =
    "All receipt lines unavailable for return, this is because there are no receipted items to return associated with this receipt.";

// Requisitions
export const UNAUTHORIZED_FIREWALL =
    "The firewall has blocked this request being made, please check that special characters have not been included in text columns and re-submit.";
export const PAYLOAD_MESSAGE_POST_REQUISITION = (validationError) => {
    return `Invalid Requisition Raised: ${validationError}`;
};
export const SERVER_MESSAGE_CREATE_REQ_FORM_LOAD =
    "Server error occurred whilst trying to open the create requisition form. Please try again and contact administrators for support if the issue persists";

export const NOTFOUND_MESSAGE_REQ_ID = "Requisition not found";
export const SERVER_MESSAGE_POST_REQ =
    "Unable to submit requisition due to a server error - please contact administrator for support";
export const PAYLOAD_SUBMIT_REQ_AMENDMENT =
    "You have not amended any requisition lines. Please amend at least one line before submitting.";
export const PAYLOAD_SUBMIT_REQ_DUPLICATE_LINES =
    "Duplicate line error - this requisition contains multiple lines with identical information. Please correct by updating line details or removing duplicate line(s).";
export const PAYLOAD_SUBMIT_REQ_MULTIPLE_SUPPLIERS =
    "Requisitions can only contain items from a single supplier";
export const PAYLOAD_SUBMIT_REQ_NO_SUPPLIERSITE =
    "Select a Supplier Site if Supplier is selected.";
export const PAYLOAD_SUBMIT_REQ_MULTIPLE_CURRENCIES =
    "Requisitions can only contain items with a single currency";
export const PAYLOAD_SUBMIT_REQ_MULTIPLE_CPAS =
    "Requisitions can only contain items from a single contract purchase agreement";
export const INVALID_CART_MIXED_SOURCING_TYPE =
    "You cannot create a requisition from this shopping cart as it contains a mixture of internally and externally sourced items. Please update your cart and try again";

// Requisition Form
export const SERVER_MESSAGE_CANNOT_RETRIEVE_CHARGE_ACCOUNTS =
    "A server error occurred - unable to retrieve task and charge accounts";
export const SERVER_MESSAGE_PROJECT_AUTOCOMPLETE =
    "Error whilst retrieving projects - if this persists, please contact administrators for support";
export const SERVER_MESSAGE_CPA_BY_ID =
    "Error whilst retrieving contract purchase agreement - if this persists, please contact administrators for support";
export const SERVER_MESSAGE_PROJECT_BY_ID =
    "Error whilst retrieving Projects - if this persists, please contact administrators for support";
export const SERVER_MESSAGE_DELIVER_TO_LOCATION =
    "Error whilst retrieving delivery location - if this persists, please contact administrators for support";
export const SERVER_MESSAGE_DELIVER_LOCATION_BY_ID =
    "Unable to locate delivery location by by ID - please contact administrators for support";
export const SERVER_MESSAGE_HR_ORGANIZATION =
    "Error whilst retrieving HR Orgs - if this persists, please contact administrators for support";
export const SERVER_MESSAGE_HR_ORGANIZATION_BY_ID =
    "Error whilst retrieving HR Orgs by ID - if this persists, please contact administrators for support";
export const SERVER_MESSAGE_COMPANY_CODES =
    "Error whilst retrieving company codes - if this persists, please contact administrators for support";
export const BAD_REQUEST_COMPANY_CODES =
    "Invalid search term - please try something different";
export const SERVER_MESSAGE_COST_CENTRES =
    "Error whilst retrieving cost centres - if this persists, please contact administrators for support";
export const BAD_REQUEST_COST_CENTRES =
    "Invalid search term - please try something different";
export const SERVER_MESSAGE_ACCOUNTS =
    "Error whilst retrieving accounts - if this persists, please contact administrators for support";
export const BAD_REQUEST_ACCOUNTS =
    "Invalid search term - please try something different";

// Search
export const SERVER_SEARCH_CATEGORIES = (category) =>
    `Unable to retrieve ${category}`;
export const NOTFOUND_PRODUCT_DETAILS =
    "Product not found - please confirm that this product is available in your selected operating unit";
export const NOTFOUND_SMART_FORM_DETAILS =
    "Smart-form not found - please confirm that this product is available in your selected operating unit";
export const SERVER_SUPPLIER = (detail) =>
    `Error whilst retrieving ${detail} information, if this persists, please contact administrators for support`;

// General or Shared Status
export const GENERIC_ERROR_MSG =
    "Unable to perform action, please contact administrator for support";
export const SERVER_MESSAGE_DATA_RETRIEVAL =
    "A server error occurred while trying to retrieve the data";
export const SERVER_MESSAGE_CANNOT_GET_FOR_USER = (samAccountName) =>
    `User "${samAccountName}" is not a valid Harmony user`;

// Cancellations
export const SUCCESS_POST_CANCELLATION = (reqId) =>
    `Cancellation successfully submitted for requisition ${reqId}`;
export const POST_CANCELLATION_WARNING_MESSAGE = (validationError) =>
    `Unable to submit cancellation: ${validationError}`;

// Copy amend requisition
export const CANNOT_AMEND_AS_CANCELLED =
    "This requisition cannot be amended as it has been fully cancelled";

// Get POs per requisition
export const SEVER_ERROR_GET_POS_PER_REQ =
    "Error whilst determining if a purchase order exists for this requisition - please try again and contact administrators for support if issue persists";

// Charge account validation
export const SUCCESSFUL_VALIDATION = "Validation successful";

export const BAD_REQUEST_EXCHANGE_RATE = "Invalid request";
export const NOT_FOUND_EXCHANGE_RATE =
    "Unable to find the exchange rate to your currency";
export const SERVER_ERROR_EXCHANGE_RATE = "Server error";

export const NOT_FOUND_EXCHANGE_RATE_DURING_COPY = (
    from_currency,
    to_currency
) =>
    `It was not possible to retrieve today's corporate exchange rate from ${from_currency} to ${to_currency}. This data is required to copy this requisition. Please close the form, try again and contact the support team if the issue persists.`;
