import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import { tableHeaderProps } from "../../../../../../styled/props/tableProps";
import { parseReturnReasonsIntoObject } from "./receiptTransactionsList";

const ReceiptTransactionsTable = ({
    receiptTransactions,
    containsReturnTx,
}) => {
    const returnReasons = useSelector((state) =>
        parseReturnReasonsIntoObject(state)
    );
    return (
        <TableContainer data-testid="ReceiptTransactionsTable">
            <Table
                size="small"
                sx={{
                    [`& .${tableCellClasses.root}`]: {
                        borderColor: "#b1c0cb",
                    },
                    "&:nth-of-type(even)": {
                        backgroundColor: "#676c7b",
                    },
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography
                                {...{
                                    ...tableHeaderProps,
                                    align: "center",
                                }}
                            >
                                Line Number
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography {...tableHeaderProps}>
                                Transaction Type
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography {...tableHeaderProps}>
                                Transaction Date
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography {...tableHeaderProps}>
                                Transacted By
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography {...tableHeaderProps}>
                                Unit of Measure
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography {...tableHeaderProps}>
                                Quantity
                            </Typography>
                        </TableCell>
                        {containsReturnTx && (
                            <>
                                <TableCell>
                                    <Typography {...tableHeaderProps}>
                                        Return Reason
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography {...tableHeaderProps}>
                                        RMA Reference
                                    </Typography>
                                </TableCell>
                            </>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {receiptTransactions.map((rcvTx, index) => (
                        <TableRow
                            key={index}
                            hover
                            sx={{ "& > *": { borderBottom: "unset" } }}
                        >
                            <TableCell align="center">
                                {rcvTx?.LINE_NUM}
                            </TableCell>
                            <TableCell align="center">
                                {rcvTx?.TRANSACTION_TYPE}
                            </TableCell>
                            <TableCell align="center">
                                {rcvTx?.TRANSACTION_DATE}
                            </TableCell>
                            <TableCell align="center">
                                {rcvTx?.TRANSACTED_BY}
                            </TableCell>
                            <TableCell align="center">
                                {rcvTx?.UNIT_OF_MEASURE_DESCRIPTION}
                            </TableCell>
                            <TableCell align="center">
                                {rcvTx?.QUANTITY}
                            </TableCell>
                            {containsReturnTx && (
                                <>
                                    <TableCell align="center">
                                        {returnReasons[rcvTx?.REASON_ID] || "-"}
                                    </TableCell>
                                    <TableCell align="center">
                                        {rcvTx?.RMA_REFERENCE || "-"}
                                    </TableCell>
                                </>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

ReceiptTransactionsTable.propTypes = {
    receiptTransactions: PropTypes.array.isRequired,
    containsReturnTx: PropTypes.bool.isRequired,
};

export default ReceiptTransactionsTable;
