import SwipeIcon from "@mui/icons-material/Swipe";
import { Grid, Typography } from "@mui/material";

const SwipeToClose = ({ direction }) => {
    const dir = direction ? direction + " " : "";
    return (
        <Grid container item alignItems="center" justifyContent="center">
            <SwipeIcon />
            <Typography
                padding={2}
                variant="body1"
                align="center"
                color="text.primary"
            >
                {`Swipe ${dir}to close panel`}
            </Typography>
        </Grid>
    );
};

export default SwipeToClose;
