import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Icon, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

const InfoLine = ({ children }) => {
    return (
        <Stack spacing={2} direction="row" data-testid="infoLine">
            <Icon color="secondary" sx={{ mt: 1 }}>
                <InfoOutlinedIcon />
            </Icon>
            <Typography variant="body1">{children}</Typography>
        </Stack>
    );
};

export default InfoLine;
