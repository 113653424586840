import { useMediaQuery } from "@mui/material";
import { MOBILE_MEDIA_QUERY } from "constants/mediaQueries";
import PropTypes from "prop-types";
import React from "react";

import RequisitionSummaryCards from "./mobileSummaryCards/requisitionSummaryCards";
import RequisitionSummaryTable from "./requisitionSummaryTable";

const RequisitionSummaryList = ({ reqs }) => {
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    return isDesktop ? (
        <RequisitionSummaryTable reqs={reqs} />
    ) : (
        <RequisitionSummaryCards reqs={reqs} />
    );
};

RequisitionSummaryList.propTypes = {
    reqs: PropTypes.array,
};

export default RequisitionSummaryList;
