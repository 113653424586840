import { Stack, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { MOBILE_MEDIA_QUERY } from "constants/mediaQueries";
import React from "react";

const ReceiptSummarySkeleton = () => {
    const isDesktop = useMediaQuery(MOBILE_MEDIA_QUERY);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack
                    direction={isDesktop ? "row" : "column"}
                    spacing={isDesktop ? 1 : 0}
                >
                    {[...Array(3)].map((v, index) => (
                        <Skeleton
                            key={index}
                            aria-label="headerTitle"
                            height={30}
                            width={isDesktop ? "12%" : "50%"}
                            variant="text"
                        />
                    ))}
                </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
                <Skeleton
                    aria-label="haeader2"
                    height={40}
                    width="80%"
                    sx={{
                        borderRadius: "10px",
                        boxSizing: "border-box",
                    }}
                />
                {[...Array(5)].map((v, index) => (
                    <Skeleton
                        key={index}
                        aria-label="headerTitle"
                        height={30}
                        width="60%"
                        sx={{
                            borderRadius: "10px",
                            boxSizing: "border-box",
                            mr: "16px",
                        }}
                    />
                ))}
            </Grid>
            <Grid item xs={12} md={8}>
                <Skeleton
                    aria-label="haeader2"
                    height={70}
                    sx={{
                        borderRadius: "10px",
                        boxSizing: "border-box",
                    }}
                />
                {[...Array(3)].map((v, index) => (
                    <Skeleton
                        key={index}
                        aria-label="headerTitle"
                        height={30}
                        sx={{
                            borderRadius: "10px",
                            boxSizing: "border-box",
                            mr: "16px",
                        }}
                    />
                ))}
            </Grid>
        </Grid>
    );
};
export default ReceiptSummarySkeleton;
