// eslint-disable-next-line no-undef
const BASE_BACKEND_URL = GLOBAL_CONFIG.REACT_APP_APIM_URL;
// eslint-disable-next-line no-undef
const ENVIRONMENT = GLOBAL_CONFIG.REACT_APP_ENVIRONMENT;

// Base supplier URL
const USER_API_URL = `${BASE_BACKEND_URL}/ibuy-${ENVIRONMENT}-User/user`;

// Autocomplete
const USER_NAME_AUTOCOMPLETE = `${USER_API_URL}/autocomplete`;
export const USER_NAME_AUTOCOMPLETE_GET = (query) => {
    return `${USER_NAME_AUTOCOMPLETE}/${query}`;
};

// Proxy rules
export const USER_PROXY_RULE = `${USER_API_URL}/proxy-rules`;
export const USER_PROXY_RULE_DELETE = (userName) =>
    `${USER_PROXY_RULE}/${userName}`;

// Batch requisition change
export const BATCH_OWNER_UPDATE = (isPreflight) =>
    `${USER_API_URL}/batch-owner-update${
        isPreflight ? "?preflight_check=Y" : ""
    }`;

export const SINGLE_OWNER_UPDATE = () => `${USER_API_URL}/single-owner-update`;
