import moment from "moment/moment";
import { toggleSpeedDialPlacement } from "redux/actions/generalActions";

export const sortAlphanumericObjectList = (obj, sortKey) => {
    obj.sort((a, b) => {
        let fa = a?.[sortKey].toLowerCase(),
            fb = b?.[sortKey].toLowerCase();

        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
    });
    return obj;
};

/**
 * Returns the suppplied value in an array if the condition is true, otherwise returns an empty array
 * @param {boolean} condition - Test for whether to return the supplied value
 * @param {any} val - A value that may be returned in an array
 * @returns {Array<any>}
 */
export const arrayOnCondition = (condition, val) => {
    return condition ? [val] : [];
};

/**
 * Returns the original object if the condition is true, otherwise returns an empty object
 * @param {boolean} condition  - Test for whether to return the supplied object
 * @param {Object} obj - An object that may be returned
 * @returns {Object}
 */
export const objectOnCondition = (condition, obj) => {
    return condition ? obj : {};
};

/**
 * Reads data from speed dial positioning preference
 * from local storage on app load and writes it to redux
 * @param {Function} dispatch  - redux store dispatcher function
 */
export const readLocalStorageParams = (dispatch) => {
    // Read speed-dial position preference
    const localStorageSpeedDial = localStorage.getItem("speedDial");
    localStorageSpeedDial !== null
        ? dispatch(toggleSpeedDialPlacement(isTrue(localStorageSpeedDial)))
        : dispatch(toggleSpeedDialPlacement(true));
};

/**
 * Reads theme preference from local storage and populates redux
 * initial state based on output. To be used to set initial
 * `general.darkMode` redux theme value. Default value is
 * `darkMode=true`.
 * @param {boolean} - value of `darkMode` theme flag
 */
export const setInitialThemePreference = () => {
    // Read theme preference
    const localStorageSpeedDial = localStorage.getItem("darkMode");
    return localStorageSpeedDial !== null
        ? isTrue(localStorageSpeedDial)
        : true;
};

/**
 * Returns if any value is strictly equal to the string "true"
 * @param {string} val
 * @returns {boolean}
 */
export const isTrue = (val) => val === "true";

/**
 * Converts datetime string of e.g. "2023-03-07T14:08:49.1234567" to "14:08:49 2023-03-07" format
 * @param {string} date
 * @returns {*|string}
 */
export const datetimeFormat = (date) => {
    try {
        const formattedDate = new Date(date);
        const dateTime = formattedDate.toISOString().split(".")[0];
        return `${dateTime.split("T")[1]} ${dateTime.split("T")[0]}`;
    } catch (err) {
        console.error(err);
        return date;
    }
};

/**
 * Converts datetime string of e.g. "2023-03-07T14:08:49.1234567" to "07/03/2023" format
 * @param {string} date
 * @param {string} [format=DD/MM/YYYY]
 * @returns {string}
 */
export const dateFormat = (date, format = "DD/MM/YYYY") => {
    return moment(date).format(format);
};

/**
 * Returns bool denoting if all entries in array are identical
 * @param {Array} arr - array to be tested
 * @returns {Boolean}
 */
export const allArrayEntriesEqual = (arr) => arr.every((v) => v === arr[0]);
